import React from 'react';

export type FeatureFlag = {
  [key: string]: string;
};

type FeatureFlagContextProps = {
  flags: FeatureFlag;
  refetchFlags: () => void;
};

const featureFlagsDefaultValue: FeatureFlagContextProps = {
  flags: {},
  refetchFlags: () => undefined,
};

const FeatureFlagsContext = React.createContext<FeatureFlagContextProps>(
  featureFlagsDefaultValue
);

export default FeatureFlagsContext;
