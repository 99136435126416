import React from 'react';
import { BaseText, theme } from 'aimo-ui';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { getLocale } from 'utils/commonUtils';
import Icon from 'components/icons/Icon';
import { format, isToday } from 'date-fns';

import { CenterRow } from 'styles/ContainerStyles';

const Chevron = styled.View`
  margin-top: 2px;
  margin-left: 5px;
`;

const DatePickerSelector: React.FC<{
  date: Date;
  darkMode?: boolean;
}> = ({ date, darkMode }) => {
  const { t } = useTranslation();

  return (
    <CenterRow>
      <BaseText color={darkMode ? theme.colors.white : theme.colors.black}>
        {isToday(date)
          ? t('time.today')
          : format(date, 'd MMM', { locale: getLocale() })}
      </BaseText>
      <Chevron>
        <Icon
          color={darkMode ? theme.colors.white : theme.colors.black}
          name="Chevron_Down"
          size="s"
        />
      </Chevron>
    </CenterRow>
  );
};

export default DatePickerSelector;
