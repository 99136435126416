import { useLazyQuery } from '@apollo/client';
import { GET_PARK_ZONES_BY_DISTANCE } from 'graphql/queries/getParkZonesByDistance';
import {
  Query,
  QueryParkZonesByDistanceArgs,
  QueryReadCustomerPmcsOrderByDistanceV2Args,
} from 'types/generatedSchemaTypes';
import { READ_CUSTOMER_PMCS_BY_DISTANCE_V2 } from 'graphql/queries/readCustomerPmcsByDistanceV2';

const useParkZonesByDistance = () => {
  const [getParkZones, parkZones] = useLazyQuery<
    Query,
    QueryParkZonesByDistanceArgs
  >(GET_PARK_ZONES_BY_DISTANCE, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const [getLTPParkZones, parkZonesLTP] = useLazyQuery<
    Query,
    QueryReadCustomerPmcsOrderByDistanceV2Args
  >(READ_CUSTOMER_PMCS_BY_DISTANCE_V2, {
    fetchPolicy: 'cache-and-network',
  });
  return {
    getParkZones,
    parkZones,
    getLTPParkZones,
    parkZonesLTP,
  };
};

export default useParkZonesByDistance;
