import Icon from '@components/icons/Icon';
import { StackHeaderProps } from '@react-navigation/stack';
import { Headline2, IconButton, theme, Title } from 'aimo-ui';
import useBackButton from 'hooks/useBackButton';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';
import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import { StackHeaderContainer, TitleWrapper } from 'styles/StackHeaderStyles';
import { isAndroid, isIos, isWeb } from 'utils/deviceUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

type ButtonTypeProps = 'secondary' | 'primary' | 'tertiary';

interface StackHeaderCustomProps extends StackHeaderProps {
  background?: string;
  buttonType?: ButtonTypeProps;
  headerBackIconVisible?: boolean;
  isTitleBig?: boolean;
}

const FixedWidthWrapper = styled.View`
  flex: 0 0 auto;
`;

const BackIcon: React.FC<{ color?: string }> = ({ color }) => (
  <Icon name="back" size="s" color={color} />
);

const StackHeader = ({
  navigation,
  back,
  background = theme.colors.white,
  options,
  buttonType = 'secondary',
  headerBackIconVisible = true,
  isTitleBig = false,
}: StackHeaderCustomProps) => {
  const tabBarHeight = useBottomMenuHeight();
  const {
    headerBackTitleVisible = true,
    headerMode = 'screen',
    headerShown = true,
    headerRight,
  } = options || {};
  const HeaderRight = headerRight as any; // ReactNode and JSX.Element incompatability
  const title = options?.title || '';
  const iconColor =
    buttonType === 'secondary' ? theme.colors.white : theme.colors.black;

  const touchableClickActive = isIos || (headerMode === 'float' && isAndroid);

  const handler = () => {
    navigation.goBack();
    return true;
  };
  useBackButton(handler);

  const handleNavigation = () => {
    back ? navigation.goBack() : navigation.navigate('accountMain');
  };

  const CustomTitle = isTitleBig ? Title : Headline2;

  if (!headerShown) {
    return <></>;
  }
  return (
    <StackHeaderContainer
      tabBarHeight={tabBarHeight}
      background={background}
      headerMode={headerMode}
      extraPadding="all"
      justifyContent="space-between"
      alignItems={isTitleBig ? 'flex-start' : 'center'}
      leftAlignHeader
      headline={
        headerBackTitleVisible && (
          <TitleWrapper
            mr={headerBackIconVisible && isWeb}
            testID="stack-header-press-wrapper"
            onPress={() =>
              back ? navigation.goBack() : navigation.navigate('accountMain')
            }>
            <CustomTitle
              isEllip={true}
              center={isWeb}
              {...testIdentifiers('stack-header-title')}>
              {title}
            </CustomTitle>
          </TitleWrapper>
        )
      }
      leftContent={
        headerBackIconVisible && (
          <TouchableOpacity
            activeOpacity={0.9}
            testID="stack-header-back-touchable"
            onPress={touchableClickActive ? handleNavigation : undefined}>
            <IconButton
              rounded
              buttonType={buttonType}
              fullWidth={false}
              onPress={touchableClickActive ? undefined : handleNavigation}
              {...testIdentifiers('stack-header-back')}
              iconComponent={() => <BackIcon color={iconColor} />}
            />
          </TouchableOpacity>
        )
      }
      rightContent={
        !!HeaderRight && (
          <FixedWidthWrapper>
            <HeaderRight />
          </FixedWidthWrapper>
        )
      }
    />
  );
};

export default StackHeader;
