import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import ParkMap from '@components/map/ParkMap';
import { GET_PARK_ZONES } from '@queries/getParkZones';
import { GET_PARK_ZONES_BY_BOUNDING_BOX } from '@queries/getParkZonesByBoundingBox';
import { RouteProp, useRoute } from '@react-navigation/native';
import { theme } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import OngoingSessionsSheet from 'components/ongoingParking/OngoingSessionsSheet';
import ParkingWizardSheet from 'components/park/ParkingWizardSheet';
import QuickAccessPanel from 'components/park/QuickAccessPanel';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import ParkingContextProvider from 'context/ParkingContextProvider';
import { UNLOCK_URL_LOCKED_SEGMENT } from 'graphql/mutations/unlockLockedUrlSegment';
import { GET_CUSTOMER } from 'graphql/queries/getCustomer';
import {
  handleQuickAccess,
  quickAccessData,
} from 'navigation/ParkScreenQuickAccess';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Dimensions } from 'react-native';
import {
  activeElement,
  selectedParkScreenQuickAccessItem,
  sheetIndex,
} from 'states/common';
import { regionCoordinates } from 'states/map';
import {
  featureCountryCode,
  urlUnlockingSegment,
} from 'states/persistInStorage';
import {
  Mutation,
  MutationUnlockUrlLockedSegmentArgs,
  ParkZone,
  Query,
  QueryParkZonesByBoundingBoxArgs,
} from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';
import {
  filteredItemsByFeatureFlag,
  showFeatureFlag,
} from 'utils/featureFlagUtils';
import { getBoxDimensionsFromRegion } from 'utils/mapUtils';
import {
  parkMapParkzoneFilter,
  quickMenuFilter,
  updateParkZonesPaginationQuery,
} from 'utils/parkUtils';

export type ParamList = {
  Park: {
    mode: string;
    parkingZoneUid?: string;
    ticket?: string;
  };
};
const { height } = Dimensions.get('window');
const GET_PARK_ZONES_SIZE = 500;

const ParkScreen: React.FC<{
  showSheet?: boolean;
  showBeforeMapLoaded?: boolean;
}> = ({ showSheet = true, showBeforeMapLoaded = false }) => {
  const { params } = useRoute<RouteProp<ParamList, 'Park'>>();
  const regionCoords = useReactiveVar(regionCoordinates);
  const { zoom: zoomedToFar } = getBoxDimensionsFromRegion(regionCoords);
  const {
    flags: { stp_type, street_parking },
  } = useContext(FeatureFlagsContext);
  const streetParkingFlag = showFeatureFlag(street_parking);
  const appLocation = useReactiveVar(featureCountryCode);
  const [mergedParkZonesData, setMergedParkZonesData] = useState<Query>();
  const currentParkZonePage = useRef(0);
  const [getParkZones, { refetch: refetchParkZones }] = useLazyQuery(
    GET_PARK_ZONES,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    }
  );
  const parkingSearchSheetIndex = useReactiveVar(sheetIndex);
  const {
    customer: { householdBenefits },
  } = useContext(AccountContext);

  const webUrlUnlockingState = useReactiveVar(urlUnlockingSegment);

  const selectedQuickAccessItem = useReactiveVar(
    selectedParkScreenQuickAccessItem
  );

  const clearUrlUnlocking = () => {
    setTimeout(() => {
      urlUnlockingSegment(null);
      (window as any).open(webUrlUnlockingState?.logoutUrl);
    }, 1500);
  };
  const [unlockUrlSegment] = useMutation<
    Mutation,
    MutationUnlockUrlLockedSegmentArgs
  >(UNLOCK_URL_LOCKED_SEGMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        errorPolicy: 'all',
      },
    ],
    onCompleted: () => {
      clearUrlUnlocking();
      showToast('success.tariffUnlock', 'success');
    },
    onError: async () => {
      clearUrlUnlocking();
      showToast('error.tariffUnlockFailed', 'error');
    },
  });

  const renderAllZones: () => ParkZone[] = useCallback(() => {
    if (!mergedParkZonesData?.parkZones?.content) {
      return [];
    }
    if (appLocation === 'FI') {
      // If your applocation is FI we dont render street zones here. We fetch them in a different call and render them differently
      return filteredItemsByFeatureFlag(
        mergedParkZonesData.parkZones.content,
        'locationType',
        stp_type
      );
    }
    //IF your applocation is sweden, we fetch all parkzones. We filter out Finnish street parking zones.
    // Reason for this is that street parking should act as normal park zone in Sweden, but differently in Finland [CPS-9385]
    return mergedParkZonesData.parkZones.content.filter(
      (item) =>
        item?.countryCode !== 'FI' ||
        (item?.countryCode === 'FI' && item.locationType !== 'STREET')
    );
  }, [mergedParkZonesData, stp_type, appLocation]);

  const [parkZonesByBoundingBox, { data: streetParkingZones }] = useLazyQuery<
    Query,
    QueryParkZonesByBoundingBoxArgs
  >(GET_PARK_ZONES_BY_BOUNDING_BOX);
  const parkingZonesData = React.useMemo(
    () => renderAllZones(),
    [renderAllZones]
  );

  const streetParkingZonesData = React.useMemo(
    () =>
      streetParkingZones?.parkZonesByBoundingBox?.content?.filter(
        (item) =>
          item?.locationType === 'STREET' &&
          !zoomedToFar &&
          appLocation === 'FI'
      ) || [],
    [streetParkingZones, zoomedToFar, appLocation]
  );

  const allParkZones = React.useMemo(
    () =>
      parkMapParkzoneFilter(
        [...parkingZonesData, ...streetParkingZonesData] as ParkZone[],
        householdBenefits
      ).filter((parkZone) =>
        quickMenuFilter(parkZone, selectedQuickAccessItem)
      ),
    [
      parkingZonesData,
      streetParkingZonesData,
      householdBenefits,
      selectedQuickAccessItem,
    ]
  );

  const mapPadding = React.useMemo(
    () => ({
      top: 0,
      bottom:
        theme.normalising.heightPixel(400) +
        (theme.normalising.heightPixel(145) -
          theme.normalising.heightPixel(height) * 0.13) -
        theme.normalising.heightPixel(50),
      left: 0,
      right: 0,
    }),
    []
  );

  useEffect(() => {
    const fetchMoreParkZones = async (data: Query): Promise<Query[]> => {
      const promises = [];
      if (
        data?.parkZones?.totalPages !== undefined &&
        data?.parkZones?.totalPages !== null &&
        currentParkZonePage.current === 0
      ) {
        for (let i = 1; i < data?.parkZones?.totalPages; i++) {
          currentParkZonePage.current++;
          promises.push(
            refetchParkZones({
              input: {
                size: GET_PARK_ZONES_SIZE,
                page: currentParkZonePage.current,
              },
            }).then((res: any) => res?.data)
          );
        }
        return await Promise.all(promises);
      }
      return [];
    };

    const fetchAllParkZones = async () => {
      const firstFetch = await getParkZones({
        variables: {
          input: {
            size: GET_PARK_ZONES_SIZE,
            page: 0,
          },
        },
      });

      const paginatedFetches = await fetchMoreParkZones(firstFetch?.data);

      if (paginatedFetches.length > 0) {
        paginatedFetches.forEach((paginatedFetch, i) => {
          if (firstFetch?.data?.parkZones?.content) {
            const tempMergedZones = updateParkZonesPaginationQuery(
              firstFetch.data || {},
              paginatedFetch
            );
            if (i === paginatedFetches.length - 1) {
              setMergedParkZonesData(tempMergedZones);
            }
          }
        });
      } else {
        setMergedParkZonesData(firstFetch.data);
      }
    };

    // Get all park zones (using pagination) when component loads.
    fetchAllParkZones();
  }, [getParkZones, refetchParkZones]);

  useEffect(() => {
    activeElement(params?.parkingZoneUid || '');
  }, [params?.parkingZoneUid]);

  useEffect(() => {
    const token = params?.ticket;
    if (token && webUrlUnlockingState) {
      unlockUrlSegment({
        variables: {
          input: {
            token,
            pricingSchemeId: webUrlUnlockingState?.pricingScheme,
            zoneCode: webUrlUnlockingState?.zoneCode as string,
            customerSegmentUid:
              webUrlUnlockingState?.customerSegmentUid as string,
            service: webUrlUnlockingState?.service,
            provider: webUrlUnlockingState?.provider,
            hasUnlocked: webUrlUnlockingState?.hasUnlocked as boolean,
          },
        },
      });
    }
  }, [params?.ticket, webUrlUnlockingState, unlockUrlSegment]);

  useEffect(() => {
    // Only fetch street zones with boundingBox if app location is set to FI
    if (!zoomedToFar && streetParkingFlag && appLocation === 'FI') {
      const { outerBox } = getBoxDimensionsFromRegion(regionCoords);
      parkZonesByBoundingBox({
        variables: {
          input: {
            bottomLeftCoordinates: {
              latitude: outerBox.bottomLeftLat,
              longitude: outerBox.bottomLeftLng,
            },
            upperRightCoordinates: {
              latitude: outerBox.upperRightLat,
              longitude: outerBox.upperRightLng,
            },
          },
        },
      });
    }
  }, [
    regionCoords,
    zoomedToFar,
    streetParkingFlag,
    appLocation,
    parkZonesByBoundingBox,
  ]);

  return (
    <ParkingContextProvider>
      <ParkMap
        showBeforeMapLoaded={showBeforeMapLoaded}
        parkingZonesData={allParkZones}
        mapPadding={mapPadding}
        showZoomInfo={zoomedToFar && streetParkingFlag}
      />
      {!isWeb && parkingSearchSheetIndex === 1 && (
        <QuickAccessPanel
          data={quickAccessData}
          onPress={handleQuickAccess}
          selectedIndex={quickAccessData.findIndex(
            (item) => item.type === selectedQuickAccessItem
          )}
        />
      )}
      {showSheet && <ParkingWizardSheet />}
      {showSheet && <OngoingSessionsSheet />}
    </ParkingContextProvider>
  );
};

export default ParkScreen;
