import { useEffect, useState } from 'react';
import { Charger } from 'types/common';
import { ChargingStation, EvseStatus, Maybe } from 'types/generatedSchemaTypes';

type EvseStatusCompare = {
  [key in EvseStatus]: number;
};

const compareChargers = (a: Charger, b: Charger) => {
  const vals: EvseStatusCompare = {
    CHARGING: 1,
    AVAILABLE: 2,
    OCCUPIED: 3,
    BLOCKED: 0,
    INOPERATIVE: 0,
    OFFLINE: 0,
    OUTOFORDER: 0,
    PLANNED: 0,
    REMOVED: 0,
    RESERVED: 0,
    UNKNOWN: 0,
  };
  const statusComparison =
    vals[b.evse.status ?? 'UNKNOWN'] - vals[a.evse.status ?? 'UNKNOWN'];
  return statusComparison === 0
    ? a.publicDisplayId.localeCompare(b.publicDisplayId)
    : statusComparison;
};

const useChargers = (chargingStations?: Maybe<ChargingStation>[]) => {
  const [chargers, setChargers] = useState<Charger[]>([] as Charger[]);

  useEffect(() => {
    /**
     * Now parkZone's parkingZoneChargingStations returns
     * all the charging stations specific to that parkZone, with the Evses' too!
     * So here we are just mapping it for front end use, with no need to call
     * bff to get the evses anymore
     */
    const customChargers =
      chargingStations
        ?.map((chargingStation) => {
          return chargingStation?.evses?.map((evse) => {
            return {
              id: chargingStation.id,
              chargingLocationId: chargingStation.chargingLocationId,
              publicDisplayId: evse.publicDisplayId,
              evse,
            };
          }) as Charger[];
        })
        .flat()
        .sort(compareChargers) || ([] as Charger[]);

    setChargers(customChargers);
  }, [chargingStations]);
  return { chargers };
};

export default useChargers;
