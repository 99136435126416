import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { useContext } from 'react';
import { showFeatureFlag } from 'utils/featureFlagUtils';

const useIsPoolingGroup = ({
  parkingZoneUid,
}: {
  parkingZoneUid?: string | null;
}): string | undefined => {
  const { customer } = useContext(AccountContext);
  const {
    flags: { stp_organisational_user },
  } = useContext(FeatureFlagsContext);
  const showSTPOrganisationalUser = showFeatureFlag(stp_organisational_user);

  if (parkingZoneUid && showSTPOrganisationalUser) {
    const { uid } =
      customer?.poolingGroups?.find(
        (pooling) => pooling.parkingZoneUid === parkingZoneUid
      ) || {};
    return uid ?? undefined;
  }
  return undefined;
};

export default useIsPoolingGroup;
