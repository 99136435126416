import { BottomSheetScrollViewMethods } from '@gorhom/bottom-sheet';
import { Maybe } from 'graphql/jsutils/Maybe';
import { t } from 'i18next';
import React, { MutableRefObject, useState } from 'react';
import { View } from 'react-native';
import { ParkZone } from 'types/generatedSchemaTypes';
import ZoneDetailsRow from '../ZoneDetailsRow';
import SearchParkAndCharger from './SearchParkAndCharger';

type SelectChargerZoneDetailsProps = {
  parkZone?: Maybe<ParkZone>;
  scrollRef?: MutableRefObject<BottomSheetScrollViewMethods>;
};

const SelectChargerZoneDetails: React.FC<SelectChargerZoneDetailsProps> = ({
  parkZone,
  scrollRef,
}) => {
  const [showSearch, setShowSearch] = useState(false);

  return (
    <View testID="select-charger-zone-details">
      <ZoneDetailsRow
        title={t('evCharging.selectCharger')}
        borderBottom={false}
        linkText={t('parkAndCharge.search')}
        chevronStyle="Down"
        onPress={() => {
          setShowSearch(!showSearch);
        }}
        headlineTitle
      />
      <SearchParkAndCharger
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        parkZone={parkZone}
        scrollRef={scrollRef}
      />
    </View>
  );
};

export default SelectChargerZoneDetails;
