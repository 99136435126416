import { useReactiveVar } from '@apollo/client';
import { Headline3, Meta, theme } from 'aimo-ui';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { featureCountryCode } from 'states/persistInStorage';
import styled from 'styled-components/native';
import { CountryCode } from 'types/generatedSchemaTypes';
import { testIdentifiers } from 'utils/testIdentifiers';

const CardContainer = styled.TouchableOpacity<{
  bgColor: string;
  hasImage: boolean;
}>`
  background-color: ${(p) => p.bgColor};
  border-radius: 20px;
  margin-bottom: 20px;
  padding: ${(p) => (p.hasImage ? '0px 0px' : '15px 0px')};
`;

const PopularContainer = styled.View`
  width: 72px;
  height: 28px;
  border-radius: 30px;
  background-color: ${theme.colors.darkBg.grey};
  align-items: center;
  justify-content: center;
  margin-bottom: 27px;
`;

const PopularText = styled.Text`
  font-family: ${theme.fontFamily.bold};
  font-size: ${theme.textStyles.meta.fontSize};
  line-height: ${theme.textStyles.meta.lineHeight};
  letter-spacing: ${theme.textStyles.meta.letterSpacing};
  color: ${theme.colors.white};
`;

const Row = styled.View<{ flexStart: boolean }>`
  flex-direction: row;
  justify-content: ${({ flexStart }) =>
    flexStart ? 'flex-start' : 'space-between'};
  align-items: center;
  padding: 20px;
`;

const Column = styled.View`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const MenuTitle = styled(Headline3)<{ textColor: string; hasImage: boolean }>`
  color: ${(p) => p.textColor};
  margin-bottom: ${(p) => (p.hasImage ? 0 : 5)}px;
`;

const MenuMeta = styled(Meta)<{ textColor: string }>`
  color: ${(p) => p.textColor};
`;

const ImageWrapper = styled.View`
  height: 50px;
  width: 50px;
  margin-right: 20px;
`;

type CardItemTypes = {
  isPopular?: boolean;
  title: string;
  text: string;
  imageContainer?: React.FC;
  onPress: any;
  testID?: string;
  bgColor?: string;
  textColor?: string;
  countryToShow?: CountryCode[];
  leftImageContainer?: ReactNode;
};

const PopularTag = ({ testID }: { testID?: string }) => {
  const { t } = useTranslation();
  return (
    <PopularContainer testID={testID}>
      <PopularText testID={`${testID}-text`}>
        {t('services.popular')}
      </PopularText>
    </PopularContainer>
  );
};

const CardItem = ({
  isPopular = false,
  title,
  text,
  imageContainer: ImageContainer,
  onPress,
  testID,
  bgColor = theme.colors.lightBg.green,
  textColor = theme.colors.black,
  countryToShow,
  leftImageContainer: LeftImageContainer,
}: CardItemTypes) => {
  const featureCountry = useReactiveVar(featureCountryCode);
  const hasImage =
    ImageContainer !== undefined || LeftImageContainer !== undefined;

  const featureCountryNotMatching = countryToShow?.some((country) => {
    if (country !== featureCountry) {
      return false;
    }
    return true;
  });

  if (!featureCountryNotMatching) {
    return null;
  }

  return (
    <CardContainer
      onPress={onPress}
      {...testIdentifiers(testID as string)}
      bgColor={bgColor}
      hasImage={hasImage}>
      <Row flexStart={!!LeftImageContainer}>
        {LeftImageContainer && (
          <ImageWrapper>{LeftImageContainer}</ImageWrapper>
        )}
        <Column>
          {isPopular && <PopularTag testID={`${testID}-popular`} />}
          <MenuTitle
            hasImage={hasImage}
            testID={testID ? `${testID}-title` : undefined}
            textColor={textColor}>
            {title}
          </MenuTitle>
          <MenuMeta
            testID={testID ? `${testID}-text` : undefined}
            textColor={textColor}>
            {text}
          </MenuMeta>
        </Column>
        {ImageContainer && <ImageContainer />}
      </Row>
    </CardContainer>
  );
};

export default CardItem;
