import {
  DdLogs,
  DdRum,
  DdSdkReactNative,
  ErrorSource,
} from '@datadog/mobile-react-native';
import { LogBox } from 'react-native';
import { accessToken } from 'states/persistInStorage';
import { getCustomerUidFromToken } from 'utils/accessTokenUtils';
import { isDatadogAvailable } from './commonUtils';

const IGNORED_LOGS = ['RNReactNativeHapticFeedback is not available'];
LogBox.ignoreLogs(IGNORED_LOGS);
if (__DEV__) {
  const withoutIgnored =
    (logger: any) =>
    (...args: any[]) => {
      const output = args.join(' ');

      if (!IGNORED_LOGS.some((log) => output.includes(log))) {
        logger(...args);
      }
    };
  console.warn = withoutIgnored(console.warn);
}

type LogType = 'error' | 'warn' | 'info';

export default class Logger {
  private static get LoggerImplementation() {
    if (isDatadogAvailable()) {
      return DdLogs;
    }
    return console;
  }
  private static formatSource = (message: string, source?: string) => {
    if (!source) {
      return `Aimo App | ${message}`;
    }
    return `(${source}): ${message}`;
  };

  /**
   * Error out a message to a logging provider, depending on environment.
   *
   * @param {string} message the message to log out
   * @param {string | undefined} source further specify the origin of the message for easier tracing
   * @param {Error | undefined} error the error to track in Datadog RUM
   */
  public static error = (message: string, source?: string, error?: Error) => {
    this.LoggerImplementation.error(Logger.formatSource(message, source));
    if (isDatadogAvailable() && error) {
      this.addTrackingError(error);
    }
  };

  /**
   * Debug out a message to a logging provider, depending on environment.
   *
   * @param {string} message the message to log out
   * @param {string | undefined} source further specify the origin of the message for easier tracing
   */
  public static debug = (message: string, source?: string) => {
    this.LoggerImplementation.debug(Logger.formatSource(message, source));
  };

  /**
   * Info out a message to a logging provider, depending on environment.
   *
   * @param {string} message the message to log out
   * @param {string | undefined} source further specify the origin of the message for easier tracing
   */
  public static info = (message: string, source?: string) => {
    this.LoggerImplementation.info(Logger.formatSource(message, source));
  };

  /**
   * Warn out a message to a logging provider, depending on environment.
   *
   * @param {string} message the message to log out
   * @param {string | undefined} source further specify the origin of the message for easier tracing
   */
  public static warn = (message: string, source?: string) => {
    this.LoggerImplementation.warn(Logger.formatSource(message, source));
  };

  /**
   * Track error in Datadog
   *
   * @param {Error} error the error
   */
  public static addTrackingError = (error: Error) => {
    DdRum.addError(
      error.message,
      ErrorSource.CUSTOM,
      JSON.stringify(error.stack) || '',
      undefined,
      Date.now()
    );
  };

  public static setDatadogUser = async (uid?: string) => {
    if (isDatadogAvailable()) {
      const customerUid = uid || getCustomerUidFromToken(accessToken());
      // Include the user information in Datadog
      await DdSdkReactNative.setUser({
        id: customerUid || '',
      });
    }
  };

  public static clearDatadogUser = async () => {
    if (isDatadogAvailable()) {
      // Clear the user information in Datadog
      await DdSdkReactNative.setUser({});
    }
  };

  public static logMessageWithCustomerUid = (
    logTitle: string,
    logDetails?: string,
    logType?: LogType
  ) => {
    const customerUid = getCustomerUidFromToken(accessToken());
    const logMessage = `${logTitle} | CustomerUid: ${customerUid} ${
      logDetails ? `| ${logDetails}` : ''
    }`;
    switch (logType) {
      case 'error':
        Logger.error(logMessage);
        break;
      case 'warn':
        Logger.warn(logMessage);
        break;
      case 'info':
      default:
        Logger.info(logMessage);
        break;
    }
  };
}
