import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import centroid from '@turf/centroid';
import { theme } from 'aimo-ui';
import React from 'react';
import { SetParamsProps } from 'types/navigation';
import { activeElement, isFullScreen } from '../../states/common';
import { AimoPolygonProps, LatLng } from '../../types/MapTypes';
import {
  handleElPress,
  makePoint,
  mapGeoJSONToPolygonArray,
} from '../../utils/mapUtils';
import Polygon from './RNWeb/Polygon';

const AimoPolygon = ({ id, geoJsonMultiPolygon, testID }: AimoPolygonProps) => {
  const isActive = useReactiveVar(activeElement) === id;
  const { setParams }: SetParamsProps = useNavigation();

  const centerOfPolygon = (index: number) => {
    const selectedPolygon = [...geoJsonMultiPolygon.coordinates[index]];
    const singleGeoJsonObj = {
      type: 'MultiPolygon',
      coordinates: [selectedPolygon],
    };
    const geoJsonCenter = centroid(singleGeoJsonObj);
    return makePoint(geoJsonCenter?.geometry?.coordinates);
  };

  const polygons = React.useMemo(
    () => mapGeoJSONToPolygonArray(geoJsonMultiPolygon.coordinates),
    [geoJsonMultiPolygon.coordinates]
  );

  return (
    <>
      {polygons.map((polygonArray: LatLng[], index: number) => {
        return (
          <Polygon
            key={`${testID}-${index}`}
            paths={polygonArray}
            testid={`${testID}-${index}`}
            strokeColor={theme.colors.primary}
            strokeOpacity={isActive ? 1 : 0}
            fillColor={theme.colors.primary}
            onPress={() => {
              isFullScreen(false);
              handleElPress(id, centerOfPolygon(index), setParams);
            }}
          />
        );
      })}
    </>
  );
};

export default AimoPolygon;
