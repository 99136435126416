import { useReactiveVar } from '@apollo/client';
import { Headline3, theme } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import SheetLayout from 'components/sheets/SheetLayout';
import HslContext from 'context/HslContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { country } from 'states/persistInStorage';
import { StyledWrapper } from 'styles/ContainerStyles';
import { SubscriptionType } from 'types/generatedSchemaTypes';
import { currencyFormatter } from 'utils/commonUtils';

const HslSubscriptionSelection: React.FC = () => {
  const { t } = useTranslation();
  const { setCurrentScreen, setSelectedSubscriptionType, hslPricingResult } =
    useContext(HslContext);
  const countryCode = useReactiveVar(country);

  const handleNavigateBack = () => {
    setCurrentScreen('BUY_TICKET');
  };

  const selectValue = (subscriptionType: SubscriptionType) => {
    setSelectedSubscriptionType(subscriptionType);
    handleNavigateBack();
  };

  const autoSaverPrice = () => {
    return currencyFormatter(
      (hslPricingResult?.data?.readHslTicketPricing?.find(
        (pricing) => pricing?.validityPeriod === 30
      )?.subscriberPrice as number) / 100 || 0,
      countryCode,
      true
    );
  };

  return (
    <SheetLayout
      onHeadlineButtonPress={handleNavigateBack}
      testIDName="hsl-select-subscription-ticket"
      headlineComponent={
        <Headline3 center>{t('hslTicket.subscriptionTitle')}</Headline3>
      }>
      <StyledWrapper mb={5}>
        <MenuItem
          noBorder
          testID={'hsl-subscription-one-off'}
          onPress={() => selectValue('ONEOFF')}
          menuLabel={t('hslTicket.subscriptionTypes.oneoff.title')}
          iconName="Services-Outlined"
          iconFlexStart
          large
          iconMt={5}
          subtitle={t('hslTicket.subscriptionTypes.oneoff.info')}
          background={theme.colors.gray50}
        />
      </StyledWrapper>
      <StyledWrapper mb={5}>
        <MenuItem
          noBorder
          testID={'hsl-subscription-saver'}
          onPress={() => selectValue('AUTOSAVER')}
          menuLabel={t('hslTicket.subscriptionTypes.autosaver.title')}
          iconName="Services-Outlined"
          iconFlexStart
          large
          iconMt={5}
          subtitle={t('hslTicket.subscriptionTypes.autosaver.info', {
            price: autoSaverPrice(),
          })}
          background={theme.colors.gray50}
        />
      </StyledWrapper>
    </SheetLayout>
  );
};

export default HslSubscriptionSelection;
