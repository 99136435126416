import AccountContext from 'context/AccountContext';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import { useContext } from 'react';
import { selectedParkingSpace } from 'states/common';
import {
  ConfirmCreatePermitDataProps,
  PermitDataInputMeta,
} from 'types/common';
import {
  Card,
  CreateSalesOrderInput,
  Customer,
  Pmc,
  Vehicle,
} from 'types/generatedSchemaTypes';
import { isSalesOrderFree } from 'utils/commonUtils';

type FactoryInput = {
  customer: Customer;
  pmcId?: string;
  startDate: Date | undefined;
  selectedVehicle?: Vehicle;
  selectedPaymentMethod?: Card;
  selectedPmc?: Pmc;
  parkingSpaceUid?: string;
  parkingSpaceName?: string;
};

const createPermitInput = ({
  customer,
  pmcId,
  startDate = new Date(),
  selectedVehicle,
  selectedPaymentMethod,
  selectedPmc,
  parkingSpaceUid,
  parkingSpaceName,
}: FactoryInput): [CreateSalesOrderInput, PermitDataInputMeta] => {
  const { name, prices, parkZone, countryCode = 'FI' } = selectedPmc || {};

  const isFreeSalesOrder = isSalesOrderFree(prices);
  const permitInput: CreateSalesOrderInput = {
    customerNumber: customer.customerNumber as string,
    countryCode,
    paymentTerm: 30,
    orderLines: [
      {
        pmcId: pmcId as string,
        quantity: 1,
        validity: {
          validFrom: startDate,
        },
        billingFrequency: 'MONTHLY',
        billingType: 'ANNIVERSARY',
        parkingSpaceUids: parkingSpaceUid ? [parkingSpaceUid] : [],
        registrationPlates: [
          {
            countryCode: selectedVehicle?.licensePlate.countryCode,
            plateText: selectedVehicle?.licensePlate.plateText as string,
          },
        ],
      },
    ],
    aggregateInvoice: true,
    orderStatus: 'DRAFT',
    serviceChannelRef: process.env.SERVICE_CHANNEL_CODE as string,
    paymentMethod: 'CREDIT_CARD',
    creditCardId: isFreeSalesOrder
      ? ''
      : (selectedPaymentMethod?.cardId as string),
  };

  const meta: PermitDataInputMeta = {
    name,
    prices,
    parkZone,
    vehicle: selectedVehicle,
    paymentMethod: selectedPaymentMethod,
    validity: permitInput.orderLines?.find(Boolean)?.validity,
    parkingSpaceName,
    freeSalesOrder: isFreeSalesOrder,
  };
  return [permitInput, meta];
};

const useCreatePermitInputFactory = () => {
  const selectedVehicle = useSelectedParkingVehicle();
  const selectedPaymentMethod = useSelectedPaymentMethod();
  const { customer } = useContext(AccountContext);

  const createInput = (data: ConfirmCreatePermitDataProps) => {
    const { startDate, pmcId, selectedPmc } = data || {};
    const { parkingSpaceUid, name } = selectedParkingSpace() ?? {};
    return createPermitInput({
      customer,
      pmcId,
      startDate,
      selectedVehicle,
      selectedPaymentMethod,
      selectedPmc,
      parkingSpaceUid,
      parkingSpaceName: name,
    });
  };
  return { createInput };
};
export default useCreatePermitInputFactory;
