import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import SearchInput from 'components/SearchInput/SearchInput';
import { Keyboard } from 'react-native';
import useDebounce from 'hooks/useDebounce';
import StreetParkingListSearch from './StreetParkingListSearch';
import { theme } from 'aimo-ui';
import Sheet from 'components/sheets/Sheet';
import { useReactiveVar } from '@apollo/client';
import {
  cityPmcsScreenName,
  searchInputActive,
  selectedCityPmc,
} from 'states/common';
import StreetParkingSheet from './StreetParkingSheet';

const Wrapper = styled.Pressable`
  padding: 0 20px;
  height: 100%;
  width: 100%;
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  flex: 1;
`;

const pressHandler = () => {
  Keyboard.dismiss();
};

const StreetParking: React.FC<{
  isTesting?: boolean;
}> = ({ isTesting = false }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const snappingPoints = ['50%', '85%'];
  const { t } = useTranslation();
  const inputFocus = useReactiveVar(searchInputActive);
  const selectedItem = useReactiveVar(selectedCityPmc);
  const cityPmcsActionScreen = useReactiveVar(cityPmcsScreenName);

  useEffect(() => {
    setSearchTerm(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setSearchTerm('');
  }, [inputFocus]);

  if (selectedItem || cityPmcsActionScreen === 'REIDENTIFY') {
    return <StreetParkingSheet />;
  }

  return (
    <Sheet snappingPoints={snappingPoints} bgColor={theme.colors.white}>
      <Wrapper
        onPress={pressHandler}
        accessible
        testID="street-parking-search-list">
        <SearchInput
          placeholder={t('search.streetWithNumber')}
          testID="city-pmcs-search-input"
          value={searchTerm}
          onChangeText={setSearchTerm}
          isTesting={isTesting}
        />
        <StreetParkingListSearch
          testID="city-pmcs-search-list"
          searchTerm={debouncedSearchTerm}
        />
      </Wrapper>
    </Sheet>
  );
};

export default StreetParking;
