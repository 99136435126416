import { useReactiveVar } from '@apollo/client';
import VehicleAvatar from 'components/common/VehicleAvatar';
import OngoingSessionHeaderContainer from 'components/ongoingParking/OngoingSessionHeaderContainer';
import { OngoingParkingHeaderWrapper } from 'components/ongoingParking/styles';
import useOngoingParkingSession from 'hooks/useOngoingParkingSession';
import useOngoingSessionsSheet from 'hooks/useOngoingSessionsSheet';
import useVehicle from 'hooks/useVehicle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Animated from 'react-native-reanimated';
import {
  ongoingChargingSession,
  ongoingParkingSheetExpanded,
  sheetModal,
} from 'states/common';
import { ParkingSession } from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';
import { getTimeLeft } from 'utils/timeUtils';
import {
  CollapseButton,
  ExtendButton,
  HeaderTexts,
  ListButton,
  OngoingParkingTimeline,
  StopButton,
} from './OngoingParkingComponents';

type ParkingHeaderProps = {
  session: ParkingSession;
  isManual?: boolean;
  showExtendButton: boolean;
};

const OngoingParkingHeader: React.FC<ParkingHeaderProps> = ({
  session,
  isManual = true,
  showExtendButton,
}) => {
  const endTime = useMemo(
    () =>
      session?.predefinedEndDate
        ? new Date(session?.predefinedEndDate as string)
        : null,
    [session?.predefinedEndDate]
  );
  const startTime = new Date(session?.startDate as Date);
  const licensePlate = session?.accessDevice?.value || '';
  const { t } = useTranslation();
  const isExpanded = useReactiveVar(ongoingParkingSheetExpanded);
  const ongoingChargingSessionId = useReactiveVar(ongoingChargingSession);
  const [timeNow, setTimeNow] = useState(new Date());
  const [isMultipleSession, setIsMultipleSession] = useState(false);
  const { parkingZoneResult, allParkingSessions } = useOngoingParkingSession();
  const { color, icon, label } = useVehicle(licensePlate);
  const { collapsedSheetContainerStyle, expandedSheetContainerStyle } =
    useOngoingSessionsSheet();

  const { overdue, timeLeft } = getTimeLeft(endTime, timeNow);
  const timeLeftTrans = t(overdue ? 'time.overdue' : 'time.remaining', {
    timeLeft,
  });

  useEffect(() => {
    const handler = setInterval(() => {
      setTimeNow(new Date());
    }, 1000);

    return () => {
      clearInterval(handler);
    };
  }, [setTimeNow]);

  useEffect(() => {
    setIsMultipleSession(
      !!ongoingChargingSessionId ||
        !!(allParkingSessions && allParkingSessions.length > 1)
    );
  }, [ongoingChargingSessionId, allParkingSessions]);

  return (
    <>
      <OngoingSessionHeaderContainer
        expandedState={ongoingParkingSheetExpanded}
        sessionId={session.sessionId}
        testID="ongoing-parking-container">
        <OngoingParkingHeaderWrapper mr={20} testID="vehicle-avatar-container">
          <VehicleAvatar size="l" color={color} icon={icon} />
        </OngoingParkingHeaderWrapper>
        <OngoingParkingHeaderWrapper mr={20} grow>
          <HeaderTexts
            vehicleLabel={label}
            isOverdue={overdue}
            timeLeft={timeLeftTrans}
            isManual={isManual}
            parkZoneName={parkingZoneResult?.data?.parkZone?.name}
          />
        </OngoingParkingHeaderWrapper>
        <Animated.View
          style={expandedSheetContainerStyle}
          testID="ongoing-parking-header-expanded">
          <>
            {isMultipleSession && (
              <OngoingParkingHeaderWrapper mr={isWeb ? 0 : 20}>
                <ListButton />
              </OngoingParkingHeaderWrapper>
            )}
            {!isWeb && (
              <OngoingParkingHeaderWrapper>
                <CollapseButton
                  isMultipleSession={isMultipleSession}
                  state={ongoingParkingSheetExpanded}
                />
              </OngoingParkingHeaderWrapper>
            )}
          </>
        </Animated.View>
        {isManual && (
          <Animated.View
            style={collapsedSheetContainerStyle}
            testID="ongoing-parking-manual-header-collapsed">
            <>
              <OngoingParkingHeaderWrapper mr={20}>
                <StopButton
                  disabled={overdue}
                  onPress={() => sheetModal('stopOngoingParking')}
                />
              </OngoingParkingHeaderWrapper>
              <OngoingParkingHeaderWrapper>
                {showExtendButton && (
                  <ExtendButton
                    onPress={() => sheetModal('extendOngoingParking')}
                  />
                )}
              </OngoingParkingHeaderWrapper>
            </>
          </Animated.View>
        )}
      </OngoingSessionHeaderContainer>
      <OngoingParkingTimeline
        startTime={startTime}
        endTime={endTime}
        isExpanded={isExpanded}
        currentTime={timeNow}
      />
    </>
  );
};

export default OngoingParkingHeader;
