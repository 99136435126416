import AccountContext from 'context/AccountContext';
import { useContext } from 'react';
import { UnifiedPermit } from 'types/generatedSchemaTypes';

const useGetMatchingAccessPermitForParkingZone = (
  parkingZoneUid: string | undefined
) => {
  const {
    loading,
    customer: { permits },
  } = useContext(AccountContext);

  const matchingPermit: UnifiedPermit | undefined = permits?.find((permit) => {
    return (
      permit.permitType === 'ACCESS' &&
      permit.accessPermitPoolingGroupCombinedInfo?.parkingZoneUids?.find(
        (accessPermitPoolingGroupParkingZoneUids) => {
          return accessPermitPoolingGroupParkingZoneUids === parkingZoneUid;
        }
      )
    );
  });

  const { accessPermitPoolingGroupCombinedInfo } = matchingPermit ?? {};
  const { insidePool, outsidePool } =
    accessPermitPoolingGroupCombinedInfo ?? {};

  return {
    loading: loading,
    matchingPermit: matchingPermit,
    insideCompanyOutsideUserPays:
      insidePool === 'COMPANY_PAYS' && outsidePool === 'USER_PAYS',
    companyPays:
      insidePool === 'COMPANY_PAYS' && outsidePool === 'COMPANY_PAYS',
    userPays: insidePool === 'USER_PAYS' && outsidePool === 'USER_PAYS',
  };
};

export default useGetMatchingAccessPermitForParkingZone;
