import { gql } from '@apollo/client';

export const READ_CORPORATE_ACCOUNT = gql`
  query readCorporateAccount($input: ReadCorporateAccountInput!) {
    readCorporateAccount(input: $input) {
      uid
      pricing {
        activeUserFee
        onboardingFee
        transactionFee
      }
      status
    }
  }
`;
