import { ReactiveVar } from '@apollo/client';
import { selectedSessionIdFromMultiple, tabBarVisible } from 'states/common';
import { theme } from 'aimo-ui';

export const toggleOngoingSessionSheet = (
  state: ReactiveVar<boolean>,
  isExpanded: boolean
) => {
  state(isExpanded);
  tabBarVisible(!isExpanded);
  if (!isExpanded) {
    selectedSessionIdFromMultiple(null);
  }
};

// Minimum height of the white sub-sheet to make first item visible while it is collapsed.
export const mobileSubSheetMinHeight = theme.normalising.heightPixel(210);
