import { gql } from '@apollo/client';

export const CREATE_HSL_TICKET = gql`
  mutation createHslTicket($input: CreateHslTicketInput!) {
    createHslTicket(input: $input) {
      hslDetails {
        ticketType
        validTo
      }
    }
  }
`;
