import styled from 'styled-components/native';

export const ModalStackHeaderContainer = styled.View<{
  background: string;
}>`
  background-color: ${({ background }) => background};
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 52px;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
`;

export const ModalTitleWrapper = styled.Pressable`
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

export const HeaderButtonWrapper = styled.View<{
  position: 'left' | 'right';
}>`
  position: absolute;
  justify-content: center;
  ${(props) => (props.position === 'left' ? 'left: 0px' : '')}
  ${(props) => (props.position === 'right' ? 'right: 0px' : '')}
`;
