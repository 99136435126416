import { useLazyQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  accountRestricted,
  isAppStartupScreenOpen,
  sheetModal,
} from 'states/common';
import { READ_FAILED_SESSIONS } from 'graphql/queries/readFailedSessions';
import { Query, QueryReadFailedSessionsArgs } from 'types/generatedSchemaTypes';
import useIsLoggedIn from './useIsLoggedIn';

const useIsAccountRestricted = (checkRestrictedStateChange = false) => {
  const [modalShown, setModalShown] = useState(false);
  const isUserRestricted = useReactiveVar(accountRestricted);
  const isLoggedIn = useIsLoggedIn();
  const isStartupScreenOpen = useReactiveVar(isAppStartupScreenOpen);

  const [getFailedSessions] = useLazyQuery<Query, QueryReadFailedSessionsArgs>(
    READ_FAILED_SESSIONS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted({ readFailedSessions }) {
        accountRestricted(readFailedSessions?.hasFailedSession);
      },
    }
  );

  //If user becomes restricted open the modal
  useEffect(() => {
    if (
      checkRestrictedStateChange &&
      isUserRestricted &&
      !modalShown &&
      !isStartupScreenOpen
    ) {
      setModalShown(true);
      sheetModal('accountRestricted');
    }
  }, [
    checkRestrictedStateChange,
    isUserRestricted,
    isStartupScreenOpen,
    modalShown,
  ]);

  const refetchFailedSessions = () => {
    if (isLoggedIn) {
      return getFailedSessions();
    }
  };

  return { refetchFailedSessions };
};

export default useIsAccountRestricted;
