import { gql } from '@apollo/client';

export const GET_APPLICATION_OPEN_SCREEN = gql`
  query ReadApplicationOpenScreenV2($countryCode: CountryCode) {
    readApplicationOpenScreenV2(countryCode: $countryCode) {
      hasFailedSession
      approveTerms
    }
  }
`;
