import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Button, IconButton, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import ParkingSignInButton from 'components/park/ParkingSignInButton';
import {
  FooterButtonContainer,
  VerticalDistributedContainer,
} from 'components/sheets/SheetLayout';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import ParkingContext from 'context/ParkingContext';
import { differenceInMinutes } from 'date-fns';
import useAddPaymentMethod from 'hooks/useAddPaymentMethod';
import useCreateSession from 'hooks/useCreateSession';
import useGetMatchingAccessPermitForParkingZone from 'hooks/useGetMatchingAccessPermitForParkingZone';
import useHasParkingSession from 'hooks/useHasParkingSession';
import useIsDesktop from 'hooks/useIsDesktop';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useResumableParkingSession from 'hooks/useResumableParkingSession';
import { groupBy } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  accountRestricted,
  canStartParkingZoneUid,
  resumableSession,
  resumeSessionPossible,
  selectedOngoingSession,
  selectedTariffId,
  sheetModal,
} from 'states/common';
import { country, onboardingCompleted } from 'states/persistInStorage';
import styled from 'styled-components/native';
import { RootNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import { getScheduleFromDate, minutesToHoursAndMinutes } from 'utils/timeUtils';
import PoolingFullInfo from '../pooling/PoolingFullInfo';
import PreloadingWrapper from '../timeSelection/PreloadingWrapper';
import PaymentCardDropDown from './PaymentCardDropDown';
import VehicleSelectDropDown from './VehicleSelectDropDown';

type DefineDisabledProps = {
  parkingZoneUid: string | undefined;
  allowedTimes:
    | {
        parkingAllowedFrom: string;
        parkingAllowedUntil: string;
      }
    | undefined;
  isValidEndTime: boolean | undefined;
};

const IconButtonWrapper = styled.View<{ marginBottom?: number }>`
  flex: 0 1 auto;
  margin-bottom: ${(p) => p.marginBottom ?? 0}px;
`;

const PrimaryButtonWrapper = styled.View<{
  marginLeft?: number;
  marginBottom?: number;
}>`
  flex: 1 1 auto;
  margin-left: ${(p) => p.marginLeft ?? 0}px;
  margin-bottom: ${(p) => p.marginBottom ?? 0}px;
`;

export const defineDisabled = ({
  parkingZoneUid,
  allowedTimes,
  isValidEndTime,
}: DefineDisabledProps) => {
  return (
    !parkingZoneUid || !allowedTimes?.parkingAllowedFrom || !isValidEndTime
  );
};

const CalendarIcon: React.FC = () => <Icon name="Calendar-Outlined" size="l" />;

const StartParkingButton = ({
  setCustomEndTime = false,
  ...props
}: {
  setCustomEndTime?: boolean;
}) => {
  const { navigate } = useNavigation<RootNavProps>();
  const { t } = useTranslation();
  const {
    parkingConditions: {
      endTime,
      vehicle,
      paymentMethod,
      companyBenefit,
      parkingZoneUid,
      allowedTimes,
      isValidEndTime,
    },
    setCurrentScreen,
    parkingZoneResult,
    setEndTime,
  } = useContext(ParkingContext) || {};
  const {
    customer: { vehicles },
  } = useContext(AccountContext);
  useResumableParkingSession();
  const { isLoggedIn, loginLoading } = useIsLoggedIn();
  const { companyPays } =
    useGetMatchingAccessPermitForParkingZone(parkingZoneUid);
  const noPaymentMethodAndCompanyWontPay = !paymentMethod && !companyPays;
  const schedule =
    parkingZoneResult?.data?.parkZone?.parkingFacility?.timeSlots;
  const unmanagedParking =
    parkingZoneResult?.data?.parkZone?.parkingMethods?.unmanagedParking;
  const groupedSchedule = groupBy(schedule, 'weekDay');
  const countryCode = useReactiveVar(country);
  const {
    flags: { anpr, park_schedule },
  } = useContext(FeatureFlagsContext);
  const showCameraRecognition = showFeatureFlag(anpr);
  const selectedTariff = useReactiveVar(selectedTariffId);
  const showParkSchedule = showFeatureFlag(park_schedule);
  const ongoingSessionType = useReactiveVar(selectedOngoingSession);
  const closedZoneUid = useReactiveVar(canStartParkingZoneUid);
  const isDesktop = useIsDesktop();
  const isUserRestricted = useReactiveVar(accountRestricted);
  const resumableSessionInfo = useReactiveVar(resumableSession);
  const canResumeSession = useReactiveVar(resumeSessionPossible);
  const hasVehicles = !!(vehicles && vehicles.length > 0);
  const { addCard, loadingAddCard } = useAddPaymentMethod();

  const {
    createParking,
    createParkingLoading: startParkingLoading,
    parkingSuccess,
  } = useCreateSession({});

  const handleStartParking = async () => {
    // If user restricted show account restricted

    if (isUserRestricted) {
      sheetModal('accountRestricted');
    } else if (
      showCameraRecognition &&
      parkingZoneResult?.data?.parkZone?.parkingMethods?.anpr &&
      !vehicle?.cameraRecognition?.enabled
    ) {
      // Check if camera recognition is required before start parking
      sheetModal('cameraRecognitionStartParking');
    } else if (
      noPaymentMethodAndCompanyWontPay &&
      !companyBenefit &&
      !vehicle &&
      !onboardingCompleted()
    ) {
      // Redirect to onboarding flow
      navigate('onboardingModal', {
        screen: 'welcome',
      });
    } else if (noPaymentMethodAndCompanyWontPay && !companyBenefit) {
      // Redirect to add payment method screen
      addCard();
    } else if (!vehicle && !hasVehicles) {
      // Redirect to add vehicle screen
      navigate('addVehicleParking');
    } else if (!vehicle && hasVehicles) {
      sheetModal('parkingVehicle');
    } else if (!endTime) {
      setEndTime(new Date());
      setCurrentScreen('CHOOSE_END_TIME');
    } else if (resumableSessionInfo?.sessionId && !canResumeSession) {
      setCurrentScreen('RESUME_PARKING');
    } else if (!isOpen && closedZoneUid !== parkingZoneUid) {
      sheetModal('confirmStartParking');
    } else {
      await createParking();
    }
  };

  useEffect(() => {
    if (parkingZoneUid && parkingZoneUid === closedZoneUid) {
      canStartParkingZoneUid('');
      createParking();
    }
    return () => {
      canStartParkingZoneUid('');
    };
  }, [closedZoneUid, createParking, parkingZoneUid]);

  const { isOpen } = getScheduleFromDate(
    groupedSchedule,
    new Date(),
    countryCode
  );

  const minuteDif = useMemo(() => {
    if (endTime) {
      return differenceInMinutes(endTime, new Date()) + 1;
    }
  }, [endTime]);

  const missingDetails = useMemo(
    () => (noPaymentMethodAndCompanyWontPay && !companyBenefit) || !vehicle,
    [noPaymentMethodAndCompanyWontPay, companyBenefit, vehicle]
  );

  const { hasParkingSession } = useHasParkingSession({
    parkingZoneUid,
    vehicle,
  });

  const disabled = missingDetails
    ? false
    : setCustomEndTime ||
      startParkingLoading ||
      defineDisabled({
        parkingZoneUid,
        allowedTimes,
        isValidEndTime,
      }) ||
      !!unmanagedParking ||
      (!!minuteDif && minuteDif < 2) ||
      hasParkingSession;

  const minutesAndHours = useMemo(() => {
    if (minuteDif) {
      return minutesToHoursAndMinutes(minuteDif);
    }
  }, [minuteDif]);

  if (!isLoggedIn) {
    return (
      <ParkingSignInButton
        testID="park-zone-details-login"
        loading={loginLoading}
      />
    );
  }
  const renderMarging = () => {
    if (ongoingSessionType !== '') {
      if (isWeb && !isDesktop) {
        return theme.normalising.heightPixel(30);
      }
      return theme.normalising.heightPixel(70);
    }
    return 0;
  };

  const buttonText = (priceLoading = false) => {
    if (noPaymentMethodAndCompanyWontPay && !companyBenefit) {
      return t('onboarding.addPaymentMethod');
    }
    if (!vehicle && !hasVehicles) {
      return t('onboarding.addVehicle');
    }
    if (!vehicle && hasVehicles) {
      return t('parking.parkingProcess.noVehicleSelected');
    }
    if (!selectedTariff && !parkingSuccess) {
      return t('parking.tariff.selectTariff');
    }
    if (!minutesAndHours) {
      return t('parking.parkingProcess.selectParkingTime');
    }
    if (startParkingLoading) {
      return t('parking.parkingProcess.startParkingLoading');
    }
    if (minutesAndHours && !disabled) {
      const timeOrDots = priceLoading ? '...' : minutesAndHours;
      return resumableSessionInfo?.startDate
        ? t('parking.parkingProcess.resumeParkingButtonLabel', {
            time: timeOrDots,
          })
        : t('parking.parkingProcess.startParkingButtonLabel', {
            time: timeOrDots,
          });
    }
    return t('parking.parkingProcess.startParkingButtonNoDuration');
  };

  return (
    <FooterButtonContainer {...props} pb={0}>
      <VerticalDistributedContainer>
        <PoolingFullInfo parkingZoneUid={parkingZoneUid} />
        <FooterButtonContainer pt={0} pb={0} prPl={10}>
          <VehicleSelectDropDown />
          <PaymentCardDropDown parkingZoneUid={parkingZoneUid} />
        </FooterButtonContainer>
        <FooterButtonContainer>
          {showParkSchedule && (
            <IconButtonWrapper marginBottom={renderMarging()}>
              <IconButton
                fullWidth={false}
                buttonType="tertiary"
                testID="park-zone-details-calendar-button"
                iconComponent={CalendarIcon}
              />
            </IconButtonWrapper>
          )}
          <PrimaryButtonWrapper
            marginLeft={showParkSchedule ? 15 : 0}
            marginBottom={renderMarging()}>
            <PreloadingWrapper
              firstItem={
                <Button
                  buttonType={missingDetails ? 'secondary' : 'primary'}
                  size="l"
                  text={buttonText()}
                  {...testIdentifiers('park-zone-details-start-parking')}
                  onPress={handleStartParking}
                  disabled={disabled}
                  loading={startParkingLoading || loadingAddCard}
                />
              }
              secondItem={
                <Button size="l" disabled loading text={buttonText(true)} />
              }
            />
          </PrimaryButtonWrapper>
        </FooterButtonContainer>
      </VerticalDistributedContainer>
    </FooterButtonContainer>
  );
};

export default StartParkingButton;
