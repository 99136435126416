import { BaseText, Meta, ThreeDotLoader, theme } from 'aimo-ui';
import PriceEstimateContext from 'context/PriceEstimateContext';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import styled from 'styled-components';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { CountryCode } from 'types/generatedSchemaTypes';
import { currencyFormatter } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import PreloadingWrapper from './PreloadingWrapper';

const BoldBaseText = styled(BaseText)`
  font-family: ${theme.fontFamily.bold};
  font-size: ${theme.normalising.fontPixel(16)}px;
`;

interface PriceEstimateProps {
  darkMode?: boolean;
  parkingZoneCountryCode: CountryCode;
  testID?: string;
}

const PriceEstimate: React.FC<PriceEstimateProps> = ({
  darkMode = false,
  parkingZoneCountryCode,
  testID,
}) => {
  const { t } = useTranslation();
  const { loadingPrice, priceEstimate, error, startButtonOpacity } =
    useContext(PriceEstimateContext);

  const estimate = priceEstimate?.totalAmount;
  const serviceFee = priceEstimate?.breakdown?.serviceFee;

  const pricingInfo = useMemo(() => {
    if (error || loadingPrice) {
      return '';
    }
    return typeof estimate === 'number'
      ? `${currencyFormatter(estimate ?? 0, parkingZoneCountryCode)}`
      : t('price.noPrice');
  }, [estimate, parkingZoneCountryCode, loadingPrice, error, t]);

  const serviceFeeInfo = serviceFee?.amount
    ? t('price.serviceFee', {
        price: currencyFormatter(
          serviceFee.amount,
          parkingZoneCountryCode,
          true
        ),
      })
    : '';

  return (
    <View style={styleSheet.container}>
      <PreloadingWrapper
        firstItem={
          <View>
            <BoldBaseText
              center
              color={darkMode ? theme.colors.white : theme.colors.black}
              testID={`${testID}-value`}>
              {pricingInfo}
            </BoldBaseText>
            {!!serviceFeeInfo && (
              <Meta
                center
                testID={`${testID}-service-fee`}
                color={darkMode ? theme.colors.white : theme.colors.gray200}>
                {serviceFeeInfo}
              </Meta>
            )}
          </View>
        }
        secondItem={
          <ColumnWithMargins mt={isWeb ? 40 : theme.normalising.heightPixel(5)}>
            <ThreeDotLoader sharedValue={startButtonOpacity} />
          </ColumnWithMargins>
        }
      />
    </View>
  );
};

export default PriceEstimate;

const styleSheet = StyleSheet.create({
  container: {
    height: theme.normalising.heightPixel(40),
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});
