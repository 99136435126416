import { useReactiveVar } from '@apollo/client';
import { BaseText, BottomButtonsLayout, Button, Spinner } from 'aimo-ui';
import ApproveTermsSelectCountry from 'components/approveTerms/ApproveTermsSelectCountry';
import ApproveTermsText from 'components/approveTerms/ApproveTermsText';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';
import useCreateAcceptanceMutation from 'hooks/useCreateAcceptanceMutation';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  acceptanceError,
  acceptedTermsAfterFeatureCountryChange,
  isSignUpFlow,
} from 'states/common';
import { featureCountryCode } from 'states/persistInStorage';
import {
  CenterFullScreen,
  ScreenContainer,
  ScreenWrapper,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import Logger from 'utils/Logger';
import { testIdentifiers } from 'utils/testIdentifiers';
import { ToggleRowItem } from '../account/ViewTerms';

const contentContainerStyle = { flexGrow: 1 };

/**
 * Screen for approving terms and conditions after sign up or sign in
 */
const ApproveTerms = () => {
  const { t } = useTranslation();
  const {
    customer: { countryCode },
    loading: loadingCustomer,
  } = useContext(AccountContext);
  const tabBarHeight = useBottomMenuHeight();
  const {
    handleAcceptancesAfterSignIn,
    handleAcceptancesAfterSignUp,
    handleAcceptances,
    loading: loadingAcceptanceCreation,
  } = useCreateAcceptanceMutation();
  const {
    flags: { onboarding_v2 },
  } = useContext(FeatureFlagsContext);
  const useOnboardingV2 = showFeatureFlag(onboarding_v2);

  const featureCountry = useReactiveVar(featureCountryCode);
  const [privacyAndTerms, setPrivacyAndTerms] = useState(false);
  const [useOfVehicle, setUseOfVehicle] = useState(false);
  const [ageVerification, setAgeVerification] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [handlingAcceptances, setHandlingAcceptances] = useState(false);

  const errorAcceptingTermsSignup = useReactiveVar(acceptanceError);

  useEffect(() => {
    featureCountryCode(countryCode);
  }, [countryCode]);

  const handleContinue = async () => {
    try {
      setHandlingAcceptances(true);

      if (useOnboardingV2) {
        await handleAcceptances(marketingConsent);
      } else if (isSignUpFlow()) {
        if (!errorAcceptingTermsSignup) {
          setHandlingAcceptances(true);
          await handleAcceptancesAfterSignUp(marketingConsent);
          setHandlingAcceptances(false);
        } else {
          acceptanceError(false);
          await handleAcceptancesAfterSignUp(marketingConsent);
        }
      } else {
        acceptedTermsAfterFeatureCountryChange(true);
        await handleAcceptancesAfterSignIn(marketingConsent);
      }
    } catch (error) {
      Logger.error(`Handle continue | Error: ${error}`);
    } finally {
      setHandlingAcceptances(false);
    }
  };

  const isLoading = useMemo(
    () => loadingCustomer || loadingAcceptanceCreation || handlingAcceptances,
    [handlingAcceptances, loadingAcceptanceCreation, loadingCustomer]
  );

  return (
    <ScreenContainer mb={1}>
      {isLoading ? (
        <CenterFullScreen>
          <Spinner />
        </CenterFullScreen>
      ) : (
        <ScreenWrapper
          contentContainerStyle={contentContainerStyle}
          tabBarHeight={tabBarHeight}>
          <BottomButtonsLayout
            scrollable={true}
            contentBottom={
              <StyledWrapper center={isWeb} maxWidth={isWeb ? 450 : undefined}>
                <Button
                  {...testIdentifiers('approve-terms-continue')}
                  size="l"
                  buttonType="secondary"
                  text={t('button.continue')}
                  disabled={
                    !(privacyAndTerms && useOfVehicle && ageVerification)
                  }
                  onPress={handleContinue}
                  loading={loadingCustomer || loadingAcceptanceCreation}
                />
              </StyledWrapper>
            }>
            <StyledWrapper maxWidth={isWeb ? undefined : 350} center>
              <StyledWrapper mb={20}>
                <BaseText>{t('account.terms.approveTermsInfo')}</BaseText>
              </StyledWrapper>
              {!useOnboardingV2 && (
                <StyledWrapper mb={20}>
                  <ApproveTermsSelectCountry />
                </StyledWrapper>
              )}
              <StyledWrapper mb={10}>
                <ToggleRowItem
                  title="account.terms.privacyPolicyAndTermsConditions"
                  infoComponent={<ApproveTermsText />}
                  value={privacyAndTerms}
                  setValue={setPrivacyAndTerms}
                  testID={'privacy-terms-toggle'}
                  disabled={!featureCountry}
                />
                <ToggleRowItem
                  title="account.terms.useOfVehicle"
                  info="account.terms.useOfVehicleInfo"
                  value={useOfVehicle}
                  setValue={setUseOfVehicle}
                  testID={'use-of-vehicle-toggle'}
                  disabled={!featureCountry}
                />
                <ToggleRowItem
                  title="account.terms.ageVerification"
                  info="account.terms.ageVerificationInfo"
                  value={ageVerification}
                  setValue={setAgeVerification}
                  testID="age-verification-toggle"
                  disabled={!featureCountry}
                />
                <ToggleRowItem
                  title="account.terms.marketingConsentOptional"
                  info="account.terms.marketingConsentOptionalInfo"
                  value={marketingConsent}
                  setValue={setMarketingConsent}
                  separator={false}
                  testID="marketing-consent-toggle"
                />
              </StyledWrapper>
            </StyledWrapper>
          </BottomButtonsLayout>
        </ScreenWrapper>
      )}
    </ScreenContainer>
  );
};

export default ApproveTerms;
