import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldInput, theme } from 'aimo-ui';

type NameInputProps = {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
};

const VehicleNameInput = ({ name, setName }: NameInputProps) => {
  const { t } = useTranslation();

  return (
    <FieldInput
      title={t('onboarding.nickname')}
      value={name}
      placeholder={t('onboarding.giveVehicleNickname')}
      placeholderTextColor={theme.colors.gray200}
      onChangeText={setName}
      testID="name-input"
    />
  );
};

export default React.memo(VehicleNameInput);
