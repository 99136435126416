import { useQuery, useReactiveVar } from '@apollo/client';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { READ_HSL_TICKETS } from 'graphql/queries/readHslTickets';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useReadPurchasedPrepaidTickets from 'hooks/useReadPurchasedPrepaidTickets';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { isSignUpFlow, prepaidPurchaseCompleted } from 'states/common';
import { featureCountryCode } from 'states/persistInStorage';
import {
  HslTicketContent,
  ParkingSession,
  Query,
  QueryReadHslTicketsArgs,
} from 'types/generatedSchemaTypes';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import NetworkConnectionContext from './NetworkConnectionContext';
import ServiceContext from './ServiceContext';

const ServiceContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [myPrepaidSessions, setMyPrepaidSessions] = useState<ParkingSession[]>(
    []
  );
  const [myHslTickets, setMyHslTickets] = useState<HslTicketContent[]>([]);
  const {
    data: prepaidSessions,
    error: prepaidError,
    loading: prepaidLoading,
    refetchPrepaidTickets,
  } = useReadPurchasedPrepaidTickets(true);
  const { isLoggedIn } = useIsLoggedIn();
  const prepaidPurchased = useReactiveVar(prepaidPurchaseCompleted);
  const { hasConnection } = useContext(NetworkConnectionContext);
  const appCountry = useReactiveVar(featureCountryCode);
  const {
    flags: { hsl_ticket },
  } = useContext(FeatureFlagsContext);
  const hslEnabled = showFeatureFlag(hsl_ticket);

  const { data: tickets, error: hslTicketError } = useQuery<
    Query,
    QueryReadHslTicketsArgs
  >(READ_HSL_TICKETS, {
    fetchPolicy: 'cache-and-network',
    skip:
      !isLoggedIn ||
      isSignUpFlow() ||
      !hasConnection ||
      appCountry !== 'FI' ||
      !hslEnabled,
  });

  useEffect(() => {
    if (prepaidPurchased) {
      refetchPrepaidTickets();
    }
    if (prepaidSessions?.readParkingSessions?.content) {
      setMyPrepaidSessions(
        prepaidSessions?.readParkingSessions?.content as ParkingSession[]
      );
    }
    if (prepaidError) {
      setMyPrepaidSessions([]);
    }
  }, [prepaidError, prepaidSessions, prepaidPurchased, refetchPrepaidTickets]);

  useEffect(() => {
    if (tickets) {
      setMyHslTickets(tickets?.readHslTickets?.content as HslTicketContent[]);
    }
    if (hslTicketError) {
      setMyHslTickets([]);
    }
  }, [tickets, hslTicketError]);

  const serviceContext = {
    myPrepaidSessions,
    prepaidLoading,
    prepaidError,
    myHslTickets,
  };

  return (
    <ServiceContext.Provider value={serviceContext}>
      <>{children}</>
    </ServiceContext.Provider>
  );
};
export default ServiceContextProvider;
