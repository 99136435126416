import { IconMap, theme } from 'aimo-ui';
import {
  IndicatorNumber,
  IndicatorShape,
} from 'components/icons/IconIndicator';
import React from 'react';
import { View } from 'react-native';
import { IconTypes, SizeProps } from 'types/IconTypes';

const sizes: SizeProps = theme.iconSizes;

const EmptyIcon = () => <></>;
// @typescript-eslint/explicit-module-boundary-types
const Icon = ({
  name,
  size = 'm',
  color = theme.colors.black,
  strokeColor,
  testID = '',
  indicatorType,
  indicatorNumber,
  ...rest
}: IconTypes) => {
  const numericSize = sizes[size];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  const ReturnedIcon = IconMap[name] || EmptyIcon;
  const IconItem = (
    <ReturnedIcon
      fill={color}
      style={{ width: numericSize, height: numericSize }}
      testID={testID}
      stroke={strokeColor}
      {...rest}
    />
  );

  if (indicatorType) {
    return (
      <View>
        {IconItem}
        <IndicatorShape type={indicatorType}>
          <IndicatorNumber type={indicatorType}>
            {indicatorNumber}
          </IndicatorNumber>
        </IndicatorShape>
      </View>
    );
  }

  return IconItem;
};

export default Icon;
