import { Meta } from 'aimo-ui';
import styled from 'styled-components/native';
import { isWeb } from 'utils/deviceUtils';

export const WebContentWrapper = styled.View`
  padding-top: 40px;
`;

export const PermitContentWrapper = styled.View`
  width: 100%;
  padding: 15px 20px;
`;

export const RowContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const RowMeta = styled(Meta)`
  max-width: ${!isWeb ? '210px' : '450px'};
  margin-right: 10px;
`;
