import React from 'react';
import { AimoMarkerProps } from 'types/MapTypes';
import { handleParkZoneSelected } from 'utils/mapUtils';
import { useNavigation } from '@react-navigation/native';
import WebMarker from './RNWeb/WebMarker';
import { SetParamsProps } from 'types/navigation';
import usePermitParkZone from 'hooks/usePermitParkZone';

const AimoMarker = ({
  markerType,
  coordinate,
  testID,
  id,
  clusterer,
  activeIndex = 0,
  withLTP = false,
  zoneCode,
}: AimoMarkerProps) => {
  const { setParams }: SetParamsProps = useNavigation();
  const permitForParkZone = usePermitParkZone(id);

  const onPressMarker = () => {
    handleParkZoneSelected(
      id,
      coordinate,
      setParams,
      withLTP,
      permitForParkZone?.id
    );
  };

  return (
    <WebMarker
      coordinate={coordinate}
      onPress={onPressMarker}
      testID={testID}
      activeIndex={activeIndex}
      iconString={markerType || 'default'}
      clusterer={clusterer}
      zoneCode={zoneCode}
    />
  );
};

export default AimoMarker;
