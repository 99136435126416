import { useQuery } from '@apollo/client';
import {
  ChargingStation,
  Query,
  QueryGetEvChargingPricingsArgs,
} from 'types/generatedSchemaTypes';
import { GET_EV_CHARGING_PRICINGS } from '@queries/getEVChargingPricings';
import { getLang } from 'utils/commonUtils';

const useEVChargingPricings = ({
  chargingStations,
}: {
  chargingStations: ChargingStation[];
}) => {
  const lang = getLang();

  const { data: EVChargingPricings, loading: EVChargingPricingsLoading } =
    useQuery<Query, QueryGetEvChargingPricingsArgs>(GET_EV_CHARGING_PRICINGS, {
      variables: {
        locationIds: chargingStations.map(
          (station) => station.chargingLocationId
        ),
        lang: lang,
      },
      skip: !chargingStations?.length,
      fetchPolicy: 'cache-and-network',
    });

  return {
    EVChargingPricings: EVChargingPricings?.getEVChargingPricings,
    EVChargingPricingsLoading,
  };
};

export default useEVChargingPricings;
