import { BaseText, Button, theme } from 'aimo-ui';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { StyledWrapper } from 'styles/ContainerStyles';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

interface WelcomeNotSignedProps {
  onSigninPress?: () => void;
  onSignupPress?: () => void;
  onLetsGoPress?: () => void;
  signinLoading: boolean;
  notSignedInText: string;
  setHeight: (h: number) => void;
}
const WelcomeNotSignedContent = ({
  onSigninPress,
  onSignupPress,
  onLetsGoPress,
  signinLoading,
  notSignedInText,
  setHeight,
}: WelcomeNotSignedProps) => {
  const { t } = useTranslation();
  const [isLayoutCalculated, setIsLayoutCalculated] = useState<boolean>(false);
  const componentRef = useRef<View>(null);
  const {
    flags: { onboarding_v2 },
  } = useContext(FeatureFlagsContext);
  const useOnboardingV2 = showFeatureFlag(onboarding_v2);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isLayoutCalculated && componentRef.current) {
        componentRef.current.measure((_, __, w, h) => {
          setHeight(h + theme.normalising.heightPixel(30));
        });
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [setHeight, isLayoutCalculated]);

  const onLayoutHandler = () => {
    setIsLayoutCalculated(true);
  };

  return (
    <View ref={componentRef} onLayout={onLayoutHandler}>
      <StyledWrapper
        maxWidth={useOnboardingV2 ? 320 : 350}
        center
        mb={useOnboardingV2 ? 20 : 30}
        mt={useOnboardingV2 ? 30 : 0}>
        <BaseText center {...testIdentifiers('not-signed-in-text')}>
          {notSignedInText}
        </BaseText>
      </StyledWrapper>
      {!useOnboardingV2 && (
        <StyledWrapper maxWidth={350} center mb={20}>
          <Button
            loading={signinLoading}
            {...testIdentifiers('sign-in')}
            text={t('account.signIn')}
            size="l"
            onPress={onSigninPress}
          />
        </StyledWrapper>
      )}
      <StyledWrapper maxWidth={350} center mb={20}>
        {useOnboardingV2 ? (
          <Button
            {...testIdentifiers('lets-go')}
            text={t('account.letsGo')}
            size="l"
            onPress={onLetsGoPress}
          />
        ) : (
          <Button
            {...testIdentifiers('sign-up')}
            text={t('account.createAccount')}
            size="l"
            buttonType="tertiary"
            onPress={onSignupPress}
          />
        )}
      </StyledWrapper>
    </View>
  );
};

export default WelcomeNotSignedContent;
