import { Meta, Spinner, theme } from 'aimo-ui';
import InfoBox from 'components/common/InfoBox';
import { t } from 'i18next';
import styled from 'styled-components/native';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { SalesOrderExpiration } from 'utils/permitUtils';
import { InfoIcon } from './ActivatePermitButton';
import { SalesOrderReceipt } from './SalesOrderReceiptClass';

const ActivatingContainer = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 1;
`;

type InfoBoxProps = {
  salesOrder: SalesOrderReceipt;
};

const PermitInfoBox: React.FC<{
  children: React.ReactNode;
  showInfoIcon: boolean;
}> = ({ children, showInfoIcon }) => (
  <ColumnWithMargins mt={14}>
    <InfoBox
      bgColor={theme.colors.lightBg.yellow}
      padding={10}
      radius={20}
      leftComponent={showInfoIcon && <InfoIcon />}
      margin={0}>
      {children}
    </InfoBox>
  </ColumnWithMargins>
);

export const PaymentErrorInfoBox: React.FC<InfoBoxProps> = ({
  salesOrder: { isWaiting, isFailedPayment, paymentAttempted },
}) => {
  return (
    <>
      {isFailedPayment && (
        <PermitInfoBox showInfoIcon={isWaiting}>
          <Meta>{`${t('permit.paymentFailed')} ${paymentAttempted}`}</Meta>
        </PermitInfoBox>
      )}
    </>
  );
};

export const ActivateInfoBox: React.FC<
  InfoBoxProps & { expiration: SalesOrderExpiration }
> = ({
  salesOrder: { isActivating, isUpdating, isWaiting, orderStatus },
  expiration: { isExpired, expirationDateString },
}) => {
  return (
    <PermitInfoBox showInfoIcon={isWaiting}>
      <ActivatingContainer>
        <Meta>
          {t(
            `permit.${
              isExpired && isWaiting ? 'expired' : `orderStatus.${orderStatus}`
            }`,
            {
              date: expirationDateString,
            }
          )}
        </Meta>
        {(isActivating || isUpdating) && <Spinner size={24} />}
      </ActivatingContainer>
    </PermitInfoBox>
  );
};
