import { useReactiveVar } from '@apollo/client';
import { BaseText, Button } from 'aimo-ui';
import { addMinutes } from 'date-fns';
import useUpdateParkingMutation from 'hooks/useUpdateParkingMutation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ongoingParkingSession, sheetModal } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import { testIdentifiers } from 'utils/testIdentifiers';

const ExtendParkingConfirm: React.FC = () => {
  const { t } = useTranslation();
  const { sessionId, vehicleLabel, endTime } = useReactiveVar(
    ongoingParkingSession
  );
  const {
    updateParking,
    updateParkingLoading,
    hasUpdateCompletedAndValueChanged,
    updateComplete,
  } = useUpdateParkingMutation({
    sessionId,
    actionType: 'EXTEND',
    isModal: true,
    newEndDate: addMinutes(endTime || new Date(), 30),
  });
  if (!endTime) {
    sheetModal('');
    return <></>;
  }
  const showLoading =
    updateParkingLoading ||
    (updateComplete && !hasUpdateCompletedAndValueChanged);

  return (
    <StyledWrapper accessible={false} mb={0}>
      <StyledWrapper mb={60}>
        <BaseText testID="extend-ongoing-parking-confirm-description">
          {t('parking.extendParkingConfirmDescription', { vehicleLabel })}
        </BaseText>
      </StyledWrapper>
      <StyledWrapper mb={20}>
        <Button
          loading={showLoading}
          size="l"
          {...testIdentifiers('extend-ongoing-parking-confirm')}
          text={t(
            showLoading ? 'parking.extendingParking' : 'parking.extendParking'
          )}
          onPress={() => updateParking()}
        />
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <Button
          size="l"
          {...testIdentifiers('cancel-extend-ongoing-parking')}
          onPress={() => sheetModal('')}
          buttonType="tertiary"
          text={t('parking.extendParkingCancel')}
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default ExtendParkingConfirm;
