import { gql } from '@apollo/client';

export const READ_HSL_AUTHENTICATION_STATUS = gql`
  query ReadHslAuthenticationStatus {
    readHslAuthenticationStatus {
      hslDetails {
        validTo
      }
    }
  }
`;
