import { useContext } from 'react';
import { Maybe } from 'types/generatedSchemaTypes';
import AccountContext from 'context/AccountContext';
import { getVehicleLabel } from 'utils/vehicleUtils';

const useVehicle = (
  licensePlate: string
): {
  color?: Maybe<string>;
  icon?: Maybe<string>;
  label: string;
} => {
  const {
    customer: { vehicles },
  } = useContext(AccountContext) || [];

  const { appSettings, name,  } =
    vehicles?.find((v) => v.licensePlate?.plateText === licensePlate) || {};
  const label = getVehicleLabel(name || '', licensePlate);

  return {
    color:appSettings?.color,
    icon:appSettings?.icon,
    label,
  };
};

export default useVehicle;
