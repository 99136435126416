import { useQuery } from '@apollo/client';
import {
  ChargingStation,
  Query,
  QueryGetEvChargingTariffsV2Args
} from 'types/generatedSchemaTypes';

import { stripTypename } from '@apollo/client/utilities';
import { GET_EV_CHARGING_TARIFFS } from '@queries/getEVChargingTariffs';

const useEVChargingTariff = ({
  chargingStations,
}: {
  chargingStations: ChargingStation[];
}) => {

  const { data: EVChargingTariffs, loading: EVChargingTariffsLoading } =
    useQuery<Query, QueryGetEvChargingTariffsV2Args>(GET_EV_CHARGING_TARIFFS, {
      variables: {
        chargingStations: stripTypename(chargingStations)
      },
      skip: !chargingStations || chargingStations.length === 0,
      fetchPolicy: 'cache-and-network',
    });

  return {
    EVChargingTariffs: EVChargingTariffs?.getEVChargingTariffsV2,
    EVChargingTariffsLoading,
  };
};

export default useEVChargingTariff;
