import { useContext } from 'react';
import AccountContext from 'context/AccountContext';
import { UnifiedPermit } from 'types/generatedSchemaTypes';

const useGetMemberGroupNameForAccessPermitPoolingGroup = (
  accessPermit: UnifiedPermit | undefined
) => {
  const {
    customer: { poolingBenefits },
  } = useContext(AccountContext);

  return poolingBenefits?.find(
    (benefit) =>
      benefit.benefitTargetUid === accessPermit?.accessToPoolingGroupUid
  )?.name;
};

export default useGetMemberGroupNameForAccessPermitPoolingGroup;
