import { gql } from '@apollo/client';

export const READ_HSL_TICKETS = gql`
  query readHslTickets($input: ReadHslTicketInput) {
    readHslTickets(input: $input) {
      content {
        hslDetails {
          ticketId
          ticketType
          validFrom
          validTo
        }
        type
      }
    }
  }
`;
