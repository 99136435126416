import React from 'react';
import ApproveTermsText, {
  ApproveTermsType,
} from 'components/approveTerms/ApproveTermsText';
import { Toggle } from 'aimo-ui';
import {
  CenterRow,
  ColumnWithMargins,
  RowSpaceBetween,
} from 'styles/ContainerStyles';

const ApproveTermsAgain: React.FC<{
  type: ApproveTermsType;
  approvedToggle: boolean;
  setApprovedToggle: (value: boolean) => void;
}> = ({ type, approvedToggle, setApprovedToggle }) => {
  const Row: any = type === 'Location' ? RowSpaceBetween : CenterRow;
  return (
    <Row noFlex isVerticalCenter>
      <ApproveTermsText type={type} />
      <ColumnWithMargins ml={5}>
        <Toggle dark value={approvedToggle} onValueChange={setApprovedToggle} />
      </ColumnWithMargins>
    </Row>
  );
};

export default ApproveTermsAgain;
