import { gql } from '@apollo/client';

export const READ_CARD = gql`
  query readCard($cardId: String) {
    readCard(cardId: $cardId) {
      expMonth
      expYear
      isDefault
      lastNums
      status
      type
    }
  }
`;
