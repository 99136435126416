import React, { useContext, useEffect } from 'react';
import { BaseText, theme } from 'aimo-ui';
import { useTranslation } from 'react-i18next';
import AccountContext from 'context/AccountContext';
import SelectVehicleItem from 'components/park/SelectVehicleItem';
import { LicensePlate, Vehicle } from 'types/generatedSchemaTypes';
import { selectedLicensePlatesPermitRedeem } from 'states/common';

interface SelectVehiclesModalContentProps {
  maxLicensePlates: number;
  handleOnChange: (licensePlates: LicensePlate[]) => void;
}

const SelectVehiclesModalContent: React.FC<SelectVehiclesModalContentProps> = ({
  maxLicensePlates,
  handleOnChange,
}) => {
  const { t } = useTranslation();
  const {
    customer: { vehicles },
  } = useContext(AccountContext);
  const [selectedLicensePlates, setSelectedLicensePlates] = React.useState<
    LicensePlate[]
  >(selectedLicensePlatesPermitRedeem());

  const toggleVehicleSelection = (vehicle: Vehicle) => {
    setSelectedLicensePlates((prevSelectedLicensePlates) => {
      const vehicleIndex = prevSelectedLicensePlates.findIndex(
        (selectedLicensePlate) =>
          selectedLicensePlate.plateText === vehicle.licensePlate?.plateText
      );
      if (vehicleIndex >= 0) {
        return prevSelectedLicensePlates.filter(
          (selectedLicensePlate) =>
            selectedLicensePlate.plateText !== vehicle.licensePlate?.plateText
        );
      }
      return [...prevSelectedLicensePlates, vehicle.licensePlate];
    });
  };

  useEffect(() => {
    handleOnChange(selectedLicensePlates);
  }, [handleOnChange, selectedLicensePlates]);

  const isSelected = (vehicle: Vehicle): boolean => {
    return (
      selectedLicensePlates.findIndex((selectedLicensePlate) => {
        return (
          selectedLicensePlate.plateText === vehicle.licensePlate?.plateText
        );
      }) >= 0
    );
  };

  return (
    <>
      <BaseText color={theme.colors.gray200} center>
        {t('permit.access.maxLicensePlates', {
          count: maxLicensePlates,
        })}
      </BaseText>
      {vehicles?.map((vehicle, index) => {
        return (
          <SelectVehicleItem
            isSelected={isSelected(vehicle)}
            key={`${vehicle.licensePlate?.plateText}-${index}`}
            vehicle={vehicle}
            onPress={toggleVehicleSelection}
            allowMultipleSelection={true}
            disabled={
              !isSelected(vehicle) &&
              selectedLicensePlates.length >= maxLicensePlates
            }
          />
        );
      })}
    </>
  );
};

export default SelectVehiclesModalContent;
