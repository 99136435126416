import { useNavigation } from '@react-navigation/core';
import { Button, Headline3, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ServicesNavProps } from 'types/navigation';

export const InfoIcon = () => (
  <Icon color={theme.colors.aimoUiRed} name="Info-Filled" />
);

const ActivatePermitButton: React.FC<{
  permitId: string;
  isExpired: boolean;
}> = ({ permitId, isExpired }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<ServicesNavProps>();

  const handleNavigation = () => {
    navigate('permitEdit', { permitId });
  };

  if (isExpired) {
    return <></>;
  }
  return (
    <Button
      testID={'activate-permit-button'}
      buttonType="secondary"
      size="l"
      iconComponent={InfoIcon}
      onPress={handleNavigation}>
      <Headline3 color={theme.colors.white}>
        {t('permit.activatePermit')}
      </Headline3>
    </Button>
  );
};

export default ActivatePermitButton;
