import React, { ReactNode } from 'react';
import styled from 'styled-components/native';
import { theme, Headline1, Meta } from 'aimo-ui';
import { ColumnWithMargins } from 'styles/ContainerStyles';

type InfoBoxProps = {
  bgColor?: string;
  headline?: string;
  text?: string;
  padding?: number;
  radius?: number;
  margin?: number;
  children?: React.ReactNode;
  testID?: string;
  leftComponent?: ReactNode;
};
const Box = styled.View<{
  bgColor: string;
  padding: number;
  radius: number;
  margin: number;
  flexRow: boolean;
}>`
  background-color: ${({ bgColor }) => bgColor};
  padding: ${({ padding }) => `${padding}px`};
  border-radius: ${({ radius }) => `${radius}px`};
  margin: ${({ margin }) => ` 0 0 ${margin}px 0`};
  align-items: center;
  flex-shrink: 0;
  flex-direction: ${(p) => (p.flexRow ? 'row' : 'column')};
`;

const InfoBox = ({
  bgColor = theme.colors.gray100,
  headline = '',
  text = '',
  padding = 33,
  margin = 30,
  radius = 20,
  children,
  testID,
  leftComponent,
}: InfoBoxProps) => {
  return (
    <Box
      bgColor={bgColor}
      padding={padding}
      testID={testID}
      radius={radius}
      margin={margin}
      flexRow={!!leftComponent}>
      {leftComponent && (
        <ColumnWithMargins mr={5}>{leftComponent}</ColumnWithMargins>
      )}
      {headline.length > 0 && (
        <Headline1 testID={`${testID}-headline`}>{headline}</Headline1>
      )}
      {text.length > 0 && <Meta testID={`${testID}-text`}>{text}</Meta>}
      {children}
    </Box>
  );
};

export default InfoBox;
