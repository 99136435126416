import React from 'react';
import { Headline2, Headline3, Meta, theme } from 'aimo-ui';
import { ColumnWithMargins, RowNoAlign } from 'styles/ContainerStyles';
import { getTimeFromDate, getTodayOrFullDate } from 'utils/timeUtils';
import { CountryCode, Maybe } from 'types/generatedSchemaTypes';

const ParkingTime: React.FC<{
  startTime: Maybe<Date>;
  endTime: Maybe<Date>;
  countryCode: CountryCode;
}> = ({ startTime, endTime, countryCode }) => {
  const startClock = startTime ? getTimeFromDate(startTime, countryCode) : '';
  const endClock = endTime ? getTimeFromDate(endTime, countryCode) : '';
  const startDate = startTime ? getTodayOrFullDate(startTime) : '';
  const endDate = endTime ? getTodayOrFullDate(endTime) : '';
  return (
    <ColumnWithMargins mt={20} mb={20}>
      <RowNoAlign>
        <ColumnWithMargins>
          <Headline2 center testID="parking-details-start-time">
            {startClock}
          </Headline2>
          <Meta
            center
            color={theme.colors.gray200}
            testID="parking-details-start-date">
            {startDate}
          </Meta>
        </ColumnWithMargins>
        <ColumnWithMargins ml={27} mr={27} mt={4}>
          <Headline3>• • • • •</Headline3>
        </ColumnWithMargins>
        <ColumnWithMargins>
          <Headline2 center testID="parking-details-end-time">
            {endClock}
          </Headline2>
          <Meta
            center
            color={theme.colors.gray200}
            testID="parking-details-end-date">
            {endDate}
          </Meta>
        </ColumnWithMargins>
      </RowNoAlign>
    </ColumnWithMargins>
  );
};

export default ParkingTime;
