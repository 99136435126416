import { useReactiveVar } from '@apollo/client';
import { theme } from 'aimo-ui';
import OngoingParkingWizardSheet from 'components/ongoingParking/OngoingParkingWizardSheet';
import Sheet from 'components/sheets/Sheet';
import useOngoingParkingSession from 'hooks/useOngoingParkingSession';
import React, { useEffect, useState } from 'react';
import {
  expandSheetAfterPurchase,
  ongoingParkingSheetExpanded,
  ongoingParkingStartChargingSheetExpanded,
} from 'states/common';
import { isIos, isWeb } from 'utils/deviceUtils';
import OngoingParkingSession from './OngoingParkingSession';
import OngoingParkingZoneDetails from './OngoingParkingZoneDetails';
import {
  mobileSubSheetMinHeight,
  toggleOngoingSessionSheet,
} from 'utils/ongoingSessionUtils';
import useCustomBottomSheet from 'hooks/useCustomBottomSheet';
const snappingPointsExtra = isWeb
  ? [360]
  : [mobileSubSheetMinHeight, mobileSubSheetMinHeight + 5];
const snappingPointsCollapsed = isWeb ? [0] : [1, 1, 1];
const bottomInset = isIos ? 0 : -1;

export const OngoingParkingSheet: React.FC = () => {
  const ongoingParkingSheetExp = useReactiveVar(ongoingParkingSheetExpanded);
  const ongoingParkingStartChargingSheetExp = useReactiveVar(
    ongoingParkingStartChargingSheetExpanded
  );
  const openSheetAfterPurchase = useReactiveVar(expandSheetAfterPurchase);
  const [showExtendParking, setShowExtendParking] = useState(false);
  const { ongoingParkingSession } = useOngoingParkingSession();
  const parkingZoneUid: string = ongoingParkingSession?.parkingZoneUid || '';
  const { expand } = useCustomBottomSheet();

  useEffect(() => {
    if (ongoingParkingSession && openSheetAfterPurchase) {
      toggleOngoingSessionSheet(ongoingParkingSheetExpanded, true);
      setTimeout(() => {
        expand();
        expandSheetAfterPurchase(false);
      }, 500);
    }
  }, [ongoingParkingSession, openSheetAfterPurchase, expand]);

  useEffect(() => {
    if (!ongoingParkingSession && ongoingParkingSheetExp) {
      toggleOngoingSessionSheet(ongoingParkingSheetExpanded, false);
    }
  }, [ongoingParkingSession, ongoingParkingSheetExp]);

  const getParkingZoneDetailsSheetSnappingPoints = () => {
    return showExtendParking ? snappingPointsCollapsed : snappingPointsExtra;
  };

  if (!ongoingParkingSession) {
    return <></>;
  }

  return (
    <>
      <OngoingParkingSession
        session={ongoingParkingSession}
        showExtendParking={showExtendParking}
        setShowExtendParking={setShowExtendParking}
      />
      {ongoingParkingSheetExp &&
        (ongoingParkingStartChargingSheetExp ? (
          <OngoingParkingWizardSheet />
        ) : (
          !showExtendParking && (
            <Sheet
              snappingPoints={getParkingZoneDetailsSheetSnappingPoints()}
              isDynamicSheet
              bottom
              left={0}
              borderRadius={theme.borderRadius.ongoingSheet}
              bottomInset={bottomInset}
              sheetMinHeight={20}
              sheetMaxHeight={300}
              webSheetOpenDefault={false}>
              <OngoingParkingZoneDetails
                parkingZoneUid={parkingZoneUid}
                licensePlate={ongoingParkingSession.accessDevice?.value}
              />
            </Sheet>
          )
        ))}
    </>
  );
};

export default OngoingParkingSheet;
