import { gql } from '@apollo/client';

export const READ_RECEIPT_BY_TYPE = gql`
  query ReadReceiptsByType($input: ReceiptInput) {
    readReceiptsByType(input: $input) {
      last
      content {
        totalAmountCents
        created
        currency
        orderNumber
        items {
          startTime
          endTime
          zoneUid
          zoneName
          zoneCode
          markerType
          parkingSessionId
          type
          permitId
        }
        creditCard {
          cardId
        }
        chargingSessionIds
        countryCode
        transactionId
      }
    }
  }
`;
