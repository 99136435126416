import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useHandleAuthentication from 'hooks/useHandleAuthentication';
import { useContext, useState } from 'react';
import { isSignUpFlow } from 'states/common';
import {
  afterAuthNavigationStack as setNavigationStack,
  onboardingCompleted,
} from 'states/persistInStorage';
import {
  AccountScreenNavigationProp,
  NestedTabNavProps,
  RootStackParamList,
} from 'types/navigation';
import { checkNotificationsPermissions, isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import useExecuteAfterStateChange from './useExecuteAfterStateChange';

export type AfterAuthNavigationTarget = {
  target:
    | 'BuyShortTermParking'
    | 'BuyPermit'
    | 'Onboarding'
    | 'Account'
    | 'Services'
    | 'MainPage';
  parkingZoneUid?: string;
  parkingSpaceUid?: string;
  pmcId?: string;
};

export type AfterAuthNavigationStack = AfterAuthNavigationTarget[];

const useAfterAuthenticationRedirect = () => {
  const [redirectPushCache, setRedirectPushCache] = useState<
    AfterAuthNavigationTarget[]
  >([]);
  const navigationStack = useReactiveVar(setNavigationStack);
  const isOnboardingCompleted = useReactiveVar(onboardingCompleted);
  const {
    flags: { onboarding_v2, use_email_verification },
  } = useContext(FeatureFlagsContext);
  const useOnboardingV2 = showFeatureFlag(onboarding_v2);
  const useEmailVerification = showFeatureFlag(use_email_verification);
  const { handleAuthMethod } = useHandleAuthentication();

  const {
    customer: { paymentCards },
  } = useContext(AccountContext);
  /*
   * Set parameter to true if redirect stack is popped pushed state had time to execute
   */
  const indirectPopNavigate = (waitForStateUpdate = false) => {
    if (waitForStateUpdate) {
      setWatchActive(true);
      return;
    }
    popNavigate();
  };
  const { setWatchActive } =
    useExecuteAfterStateChange<AfterAuthNavigationStack>(
      indirectPopNavigate,
      navigationStack
    );

  const { navigate: navigateAccount } =
    useNavigation<AccountScreenNavigationProp>();
  const { navigate: navigateMain } = useNavigation<NestedTabNavProps>();
  const { navigate: navigateRoot } =
    useNavigation<StackNavigationProp<RootStackParamList>>();
  const pushStack = (
    target: AfterAuthNavigationTarget,
    commitImmediately = true
  ) => {
    const newStack = commitImmediately ? navigationStack : redirectPushCache;
    newStack.push(target);
    commitImmediately
      ? setNavigationStack(newStack)
      : setRedirectPushCache(newStack);
  };

  const commitPush = () => {
    if (!redirectPushCache) {
      return;
    }
    setNavigationStack(redirectPushCache);
    setRedirectPushCache([]);
  };

  const popStack = () => {
    if (!navigationStack) {
      return undefined;
    }
    const newStack = navigationStack;
    const item = newStack.pop();
    setNavigationStack(newStack ?? []);
    return item;
  };

  const popNavigate = () => {
    const { target, parkingZoneUid, parkingSpaceUid, pmcId } = popStack() || {};
    if (!target) {
      navigateToAccountLogin();
      return;
    }
    switch (target) {
      case 'BuyShortTermParking':
        navigateToBuyShortTermParking(parkingZoneUid);
        break;
      case 'BuyPermit':
        if (!pmcId) {
          throw new Error('no pmcId set for redirect');
        }
        navigateToBuyPermit(pmcId, parkingSpaceUid);
        break;
      case 'Onboarding':
        navigateToOnboarding();
        break;
      case 'Account':
        navigateToAccountLogin();
        break;
      case 'Services':
        navigateToServices();
        break;
      case 'MainPage':
        navigateToParkScreen();
    }
  };

  const navigateToParkScreen = () => {
    navigateRoot('mainTabs', {
      screen: 'parkScreen',
    });
  };

  const navigateToAccountLogin = () => {
    navigateAccount('mainTabs', {
      screen: 'accountScreen',
      params: { screen: 'accountMain' },
    });
  };

  const navigateToServices = () => {
    navigateRoot('mainTabs', {
      screen: 'servicesScreen',
      params: {
        screen: 'servicesMain',
      },
    });
  };

  const navigateToBuyShortTermParking = (parkingZoneUid?: string) => {
    navigateMain('mainTabs', {
      screen: 'parkScreen',
      params: {
        parkingZoneUid,
      },
    });
  };

  const navigateToBuyPermit = (_pmcId: string, _parkingSpace?: string) => {
    navigateRoot('longTermParking', {
      screen: 'mapScreen',
      // TODO go to buyScreen with params
      // params: { pmcId, parkingSpace }, //
      // or make login modal and therefore the navigation obsolete
    });
  };

  const navigateToOnboarding = () => {
    navigateAccount('onboardingModal', {
      screen:
        (paymentCards || []).length > 0 ? 'onboardingContinue' : 'welcome',
    });
  };

  const navigateToOnboardingPushNotificationScreen = () => {
    navigateAccount('onboardingModal', {
      screen: 'pushNotifications',
    });
  };

  const navigateToAuthentication = async (fromWebParkZoneDetails = false) => {
    if (useOnboardingV2) {
      if (fromWebParkZoneDetails) {
        // In web, the Azure redirect does not work for dynamic url, so we need to navigate to the park screen
        navigateToParkScreen();
        // Wait some seconds
        setTimeout(async () => {
          await handleAuthMethod(useEmailVerification);
        }, 500);
      } else {
        await handleAuthMethod(useEmailVerification);
      }
    } else {
      navigateToAccountLogin();
    }
  };

  const setReturnRedirectToBuyPermit = (
    pmcId?: string,
    parkingSpaceUid?: string,
    allowOnboarding = true
  ) => {
    pushStack(
      {
        target: 'BuyPermit',
        parkingSpaceUid,
        pmcId,
      },
      false
    );

    if (allowOnboarding && !isOnboardingCompleted) {
      pushStack(
        {
          target: 'Onboarding',
        },
        false
      );
    }
    commitPush();
  };

  const setReturnRedirectToBuyShortTermParking = (
    parkingZoneUid: string,
    allowOnboarding = true
  ) => {
    pushStack(
      {
        target: 'BuyShortTermParking',
        parkingZoneUid: parkingZoneUid,
      },
      false
    );

    if (allowOnboarding && !isOnboardingCompleted) {
      pushStack(
        {
          target: 'Onboarding',
        },
        false
      );
    }
    commitPush();
  };

  const setReturnRedirectToAccount = (allowOnboarding = true) => {
    pushStack(
      {
        target: 'Account',
      },
      false
    );
    if (allowOnboarding && !isOnboardingCompleted) {
      pushStack(
        {
          target: 'Onboarding',
        },
        false
      );
    }
    commitPush();
  };

  const setReturnToMainPage = (allowOnboarding = true) => {
    pushStack(
      {
        target: 'MainPage',
      },
      false
    );
    if (allowOnboarding && !isOnboardingCompleted) {
      pushStack(
        {
          target: 'Onboarding',
        },
        false
      );
    }
    commitPush();
  };

  const setReturnRedirectToServices = (allowOnboarding = true) => {
    pushStack(
      {
        target: 'Services',
      },
      false
    );
    if (allowOnboarding && !isOnboardingCompleted) {
      pushStack(
        {
          target: 'Onboarding',
        },
        false
      );
    }
    commitPush();
  };

  const skipOnboarding = async () => {
    const notificationsEnabled = await checkNotificationsPermissions();
    if (notificationsEnabled) {
      setOnboardingCompleted();
    } else {
      navigateToOnboardingPushNotificationScreen();
    }
  };

  const setOnboardingCompleted = () => {
    onboardingCompleted(true);
    isSignUpFlow(false);
    if (isWeb) {
      indirectPopNavigate();
    }
  };

  const clearRedirectStack = () => {
    setNavigationStack([]);
    setRedirectPushCache([]);
  };

  const peekNextNavigationTarget = () => {
    return navigationStack.length > 0
      ? navigationStack[navigationStack.length - 1]
      : undefined;
  };

  return {
    setReturnRedirectToBuyShortTermParking,
    setReturnRedirectToBuyPermit,
    setReturnRedirectToAccount,
    setReturnRedirectToServices,
    setReturnToMainPage,
    setOnboardingCompleted,
    skipOnboarding,
    navigateToNext: indirectPopNavigate,
    navigateToAccountLogin,
    navigateToAuthentication,
    navigateToOnboarding,
    navigateToParkScreen,
    clearRedirectStack,
    isInRedirectProcess: () => navigationStack.length > 0,
    currentStack: navigationStack,
    peekNextNavigationTarget,
  };
};
export default useAfterAuthenticationRedirect;
