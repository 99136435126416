import { gql } from '@apollo/client';

export const SEARCH_PARK_ZONES_BY_TEXT = gql`
  query searchParkZonesByText($input: ParkZoneSearchInput!) {
    searchParkZonesByText(input: $input) {
      content {
        uid
        name
        locationType
        address {
          street
          number
          city
          marker
        }
        countryCode
        commercialZoneView {
          zoneCode
          name
        }
        parkingMethods {
          anpr
          unmanagedParking
          evCharging
        }
        visibility
      }
    }
  }
`;
