import { useNavigation } from '@react-navigation/core';
import { BaseText, Button, Headline2, theme } from 'aimo-ui';
import { ButtonTypes } from 'aimo-ui/lib/typescript/src/components/Button/types';
import SvgImage, { SvgName } from 'components/svgImage/SvgImage';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { Shadow } from 'react-native-shadow-2';
import styled from 'styled-components/native';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { RootNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { completeTutorial } from 'utils/tutorialUtils';

export type CarouselTutorialInfo = {
  image?: SvgName;
  translationKey: string;
  imageStyle?: any;
  buttonPress?: 'PAYMENT_METHODS' | 'COMPLETE';
  buttonType?: ButtonTypes;
  imageShadow?: boolean;
};

const Container = styled.View`
  flex: 1;
  padding: 0px ${isWeb ? 40 : 10}px;
`;

const ImageContainer = styled.View`
  padding-top: 10px;
  margin-bottom: 10px;
  flex: 1;
`;

const TextButtonContainer = styled.View`
  flex-shrink: 1;
`;

const styles = StyleSheet.create({
  shadow: {
    flex: 1,
    borderRadius: 50,
    borderBottomEndRadius: 5,
    borderBottomStartRadius: 5,
    marginBottom: 20,
  },
  shadowContainer: {
    flex: 1,
    marginTop: 10,
  },
});

const ImageContainerShadow = ({
  children,
  imageStyle,
  imageShadow,
}: {
  children: React.ReactNode;
  imageStyle: any;
  imageShadow?: boolean;
}) => {
  return (
    <Shadow
      disabled={!imageShadow}
      stretch
      distance={12}
      startColor={theme.colors.gray50}
      sides={{ end: true, bottom: false, top: true, start: true }}
      style={[styles.shadow]}
      containerStyle={[styles.shadowContainer]}>
      <ImageContainer style={imageStyle}>{children}</ImageContainer>
    </Shadow>
  );
};

export const TutorialItem: React.FC<{ item: CarouselTutorialInfo }> = ({
  item: {
    image,
    imageStyle,
    buttonPress,
    translationKey,
    buttonType = 'primary',
    imageShadow,
  },
}) => {
  const { navigate } = useNavigation<RootNavProps>();
  const { t } = useTranslation();

  const navigatePress = () => {
    if (buttonPress === 'PAYMENT_METHODS') {
      navigate('mainTabs', {
        screen: 'accountScreen',
        params: { screen: 'myPaymentMethods' },
      });
    }
    completeTutorial('firstTimer');
  };

  return (
    <Container>
      {image && (
        <ImageContainerShadow imageShadow={imageShadow} imageStyle={imageStyle}>
          <SvgImage
            name={image}
            testID={`first-timer-${image}`}
            preserveAspectRatio="xMidYMin meet"
          />
        </ImageContainerShadow>
      )}
      <TextButtonContainer>
        <ColumnWithMargins mb={20}>
          <ColumnWithMargins mb={10}>
            <Headline2 center>
              {t(`tutorial.${translationKey}.title`)}
            </Headline2>
          </ColumnWithMargins>
          <BaseText center>{t(`tutorial.${translationKey}.info`)}</BaseText>
        </ColumnWithMargins>
        {buttonPress && (
          <Button
            buttonType={buttonType}
            text={t(`tutorial.${translationKey}.button`)}
            size="l"
            onPress={navigatePress}
          />
        )}
      </TextButtonContainer>
    </Container>
  );
};
