import { Headline3, IconButton, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import React from 'react';
import {
  HeaderButtonWrapper,
  ModalStackHeaderContainer,
  ModalTitleWrapper,
} from 'styles/ModalStackHeaderStyles';
import { VoidCallBack } from 'types/common';

type ModalHeaderProps = {
  title: string;
  onBack: VoidCallBack;
  testID?: string;
};

const ModalHeader = ({ title, onBack, testID }: ModalHeaderProps) => (
  <ModalStackHeaderContainer background={'transparent'}>
    <HeaderButtonWrapper position="left">
      <IconButton
        rounded={true}
        buttonType="transparent"
        onPress={onBack}
        testID={`${testID ?? 'modal-header'}-back-button`}
        iconComponent={() => <Icon name="back" color={theme.colors.black} />}
      />
    </HeaderButtonWrapper>

    <ModalTitleWrapper>
      <Headline3 testID={`${testID ?? 'modal-header'}-title`}>
        {title}
      </Headline3>
    </ModalTitleWrapper>

    <HeaderButtonWrapper position="right">
      <IconButton
        testID={`${testID ?? 'modal-header'}-close-button`}
        rounded={true}
        buttonType="tertiary"
        onPress={onBack}
        iconComponent={() => <Icon name="close" color={theme.colors.gray200} />}
      />
    </HeaderButtonWrapper>
  </ModalStackHeaderContainer>
);

export default ModalHeader;
