import { BaseText, Button, theme } from 'aimo-ui';
import InfoBox from 'components/common/InfoBox';
import SvgImage from 'components/svgImage/SvgImage';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { completeTutorial, openTutorial } from 'utils/tutorialUtils';

const Row = styled.View`
  flex-direction: row;
`;

const Column = styled.View`
  flex-direction: column;
  flex: 1;
  padding: 0px 40px;
  gap: 10px;
`;

const ImageContainer = styled.View`
  margin-right: -20px;
`;

const ButtonContainer = styled.View`
  flex-direction: row;
  gap: 10px;
`;

const FirstTimeButton = () => {
  const { t } = useTranslation();
  return (
    <InfoBox padding={15} margin={10} bgColor={theme.colors.lightBg.green}>
      <Row>
        <ImageContainer>
          <SvgImage size="xxl" name="Tutorial_Car" testID={'tutorial-card'} />
        </ImageContainer>
        <Column>
          <BaseText>{t('tutorial.firstTimer.title')}</BaseText>
          <ButtonContainer>
            <Button
              buttonType="primary"
              fullWidth={false}
              text={t('tutorial.firstTimer.accept')}
              size="m"
              onPress={() => openTutorial('firstTimer')}
            />
            <Button
              buttonType="tertiary"
              fullWidth={false}
              text={t('tutorial.firstTimer.decline')}
              size="m"
              onPress={() => completeTutorial('firstTimer')}
            />
          </ButtonContainer>
        </Column>
      </Row>
    </InfoBox>
  );
};

export default FirstTimeButton;
