import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Headline3, Meta, theme } from 'aimo-ui';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { Maybe } from 'graphql/jsutils/Maybe';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useShowPaymentCardStatus from 'hooks/useShowPaymentCardStatus';
import { getLanguage } from 'i18n/init';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { getBrand, getModel, getVersion } from 'react-native-device-info';
import { ScrollView } from 'react-native-gesture-handler';
import { sheetModal } from 'states/common';
import { featureCountryCode } from 'states/persistInStorage';
import { StyledWrapper } from 'styles/ContainerStyles';
import { Card, CardStatus, Lang } from 'types/generatedSchemaTypes';
import { AccountNavProps } from 'types/navigation';
import { isIos, isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { languageLabels } from 'utils/languageUtils';
import {
  getCardLogo,
  getCardMaskedLastNums,
  getExpiringSoonCards,
} from 'utils/paymentUtils';
import { rateApp } from 'utils/rateAppUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import MenuItem from '../common/MenuItem';
import AutomaticVehicleSelectToggleItem from './menuItems/AutomaticVehicleSelectToggleItem';
import SendEmailReceiptsMenuItem from './menuItems/SendEmailReceiptsToggleItem';
import PromotionCardExpiresSoon from './PromotionCardExpiresSoon';

const WelcomeSignedContent: React.FC = () => {
  const { t } = useTranslation();
  const {
    customer: { companyBenefits, paymentCards },
  } = useContext(AccountContext);
  const {
    flags: {
      account_rides,
      account_notification_settings,
      company_benefits,
      rate,
    },
  } = useContext(FeatureFlagsContext);
  const { customerUid } = useIsLoggedIn();
  const showAccountRide = showFeatureFlag(account_rides);
  const showCompanyBenefits = showFeatureFlag(company_benefits);
  const hasCompanybenefits = (companyBenefits?.length ?? 0) > 0;
  const showAccountNotificationSettings = showFeatureFlag(
    account_notification_settings
  );
  const showRateApp = showFeatureFlag(rate);
  const { navigate } = useNavigation<AccountNavProps>();
  const borderColor = isWeb ? theme.colors.black : undefined;
  const borderWidth = isWeb ? 1 : undefined;

  const appCountryCode = useReactiveVar(featureCountryCode);

  const feedbackEmail = {
    FI: process.env.FEEDBACK_EMAIL_ADDRESS_FI,
    SE: process.env.FEEDBACK_EMAIL_ADDRESS_SE,
    NO: process.env.FEEDBACK_EMAIL_ADDRESS_NO,
  };

  const openEmail = async () => {
    const lineBreak = isIos ? '%0D%0A' : '\n';
    const feedback = encodeURIComponent(
      'Please add your feedback and possible screenshot'
    );
    const model = encodeURIComponent(
      `Phone model: ${getModel()} ${getBrand()}`
    );
    const appVersion = encodeURIComponent(`App version: ${getVersion()}`);
    const user = encodeURIComponent(`User: ${customerUid}`);
    const body = `${feedback}${lineBreak}${lineBreak}${lineBreak}${model}${lineBreak}${appVersion}${lineBreak}${user}`;
    const url = `mailto:${
      feedbackEmail[appCountryCode || 'SE']
    }?subject=${encodeURIComponent('Feedback: Aimo App')}&body=${body}`;
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      await Linking.openURL(url);
    }
  };

  const defaultCard: Maybe<Card> = paymentCards?.find((card) => card.isDefault);
  const expiringSoonCards = paymentCards
    ? getExpiringSoonCards(paymentCards)
    : [];
  const amountOfExpiringSoonCards = expiringSoonCards.length;
  const { status, expYear, expMonth } = expiringSoonCards[0] || {};
  const { daysUntilExpiration } = useShowPaymentCardStatus(
    (status || 'Active') as CardStatus,
    expMonth || '',
    expYear || ''
  );

  return (
    <StyledWrapper mb={20}>
      <ScrollView>
        {amountOfExpiringSoonCards > 0 && (
          <StyledWrapper
            mb={20}
            center={isWeb}
            maxWidth={isWeb ? 600 : undefined}>
            <PromotionCardExpiresSoon
              daysUntilExpiration={daysUntilExpiration}
            />
          </StyledWrapper>
        )}
        <StyledWrapper
          mb={20}
          center={isWeb}
          maxWidth={isWeb ? 600 : undefined}>
          <Headline3>{t('account.history')}</Headline3>
          <MenuItem
            menuLabel={t('account.parkAndCharge')}
            iconName="History-Outlined"
            testID="parking-history-link"
            onPress={() => navigate('parkingHistory')}
            borderColor={borderColor}
            borderW={borderWidth}
          />
          {showAccountRide && (
            <MenuItem
              menuLabel={t('account.rides')}
              iconName="History-Outlined"
              borderColor={borderColor}
              borderW={borderWidth}
              testID="rides-history-link"
            />
          )}
          <MenuItem
            menuLabel={t('account.prepaid')}
            iconName="History-Outlined"
            testID="prepaid-history-link"
            noBorder
            onPress={() => navigate('prepaidHistory')}
          />
        </StyledWrapper>
        <StyledWrapper center={isWeb} maxWidth={isWeb ? 600 : undefined}>
          <Headline3>{t('account.account')}</Headline3>
          <MenuItem
            menuLabel={t('account.appLocation')}
            iconName="Pin-Outlined"
            testID="app-location"
            onPress={() => sheetModal('appLocation')}
            rightComponent={
              <Meta
                color={theme.colors.gray200}
                {...testIdentifiers('app-location-selected-country-text')}>
                {t(`account.appLocations.${appCountryCode}`)}
              </Meta>
            }
            borderColor={borderColor}
            borderW={borderWidth}
          />
          <MenuItem
            menuLabel={t('account.myVehicles')}
            iconName="Car-Outlined"
            testID="your-vehicles-link"
            onPress={() => navigate('myVehicles')}
            borderColor={borderColor}
            borderW={borderWidth}
          />
          <AutomaticVehicleSelectToggleItem
            borderColor={borderColor}
            borderW={borderWidth}
          />
          <MenuItem
            menuLabel={t('account.paymentMethods')}
            iconName={getCardLogo(defaultCard ? defaultCard.type : null)}
            indicatorType={
              amountOfExpiringSoonCards > 0 ? 'warning' : undefined
            }
            indicatorNumber={amountOfExpiringSoonCards}
            testID="credit-cards-link"
            onPress={() => navigate('myPaymentMethods')}
            borderColor={borderColor}
            borderW={borderWidth}
            subtitle={
              defaultCard
                ? `${defaultCard.type} ${getCardMaskedLastNums(defaultCard)}`
                : undefined
            }
          />
          {showAccountNotificationSettings && (
            <MenuItem
              menuLabel={t('account.notificationSettings')}
              iconName="Notification-Outlined"
              testID="notifications-link"
              onPress={() => navigate('notifications')}
              borderColor={borderColor}
              borderW={borderWidth}
            />
          )}
          <SendEmailReceiptsMenuItem
            borderColor={borderColor}
            borderW={borderWidth}
          />
          <MenuItem
            menuLabel={t('account.contactInfo')}
            iconName="User-Outlined"
            testID="contact-info-link"
            onPress={() => navigate('contactInfo')}
            borderColor={borderColor}
            borderW={borderWidth}
          />
          {showCompanyBenefits && hasCompanybenefits && (
            <MenuItem
              menuLabel={t('account.companyPermit')}
              iconName="Business-Outlined"
              testID="corporate-permits-link"
              subtitle={
                (companyBenefits?.[0]?.name as string) ||
                t('account.companyPermits.noPermit')
              }
              onPress={() => navigate('companyPermit')}
              borderColor={borderColor}
              withArrow
              borderW={borderWidth}
            />
          )}
          {!isWeb && (
            <>
              {showRateApp && (
                <MenuItem
                  menuLabel={t('account.rateApp')}
                  iconName="Star-Outlined"
                  testID="rate-app-link"
                  onPress={rateApp}
                />
              )}
              <MenuItem
                menuLabel={t('account.feedback')}
                iconName="Email-Outlined"
                testID="feedback-link"
                onPress={() => openEmail()}
                borderColor={borderColor}
                borderW={borderWidth}
              />
            </>
          )}
          <MenuItem
            menuLabel={t('language.title')}
            iconName="Globe-Outlined"
            testID="language-link"
            subtitle={languageLabels[getLanguage() as Lang]}
            onPress={() => sheetModal('language')}
            borderColor={borderColor}
            withArrow
            borderW={borderWidth}
          />
          <MenuItem
            menuLabel={t('account.terms.privacyTermsAndConditions')}
            iconName="Document-Outlined"
            testID="privacy-terms-conditions-link"
            onPress={() => navigate('viewTerms')}
            borderColor={borderColor}
            borderW={borderWidth}
          />
          <MenuItem
            testID="logout-button"
            menuLabel={t('account.signOut')}
            onPress={() => sheetModal('signOut')}
            iconName="Sign_Out"
            noBorder
          />
        </StyledWrapper>
      </ScrollView>
    </StyledWrapper>
  );
};

export default WelcomeSignedContent;
