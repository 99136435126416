import React from 'react';
import { StyleSheet } from 'react-native';
import Animated, { Easing, FadeInRight } from 'react-native-reanimated';
import { Maybe, ParkZone } from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';

export type CommonTabProps = {
  parkZone?: Maybe<ParkZone>;
  isActiveTab: boolean;
  hasImportantNotes?: boolean;
};

const TabContainer = ({
  children,
  isActiveTab,
}: {
  children: React.ReactNode;
  isActiveTab: boolean;
}) => {
  return (
    <Animated.View
      style={parkingTabStyle(isActiveTab).tab}
      entering={!isWeb ? FadeInRight.easing(Easing.cubic) : undefined}>
      {children}
    </Animated.View>
  );
};

export default TabContainer;

const parkingTabStyle = (isActiveTab: boolean) =>
  StyleSheet.create({
    tab: {
      display: !isActiveTab ? 'none' : 'flex',
    },
  });
