import React from 'react';
import { BaseText, Meta, Spinner, theme, Title } from 'aimo-ui';
import {
  Notification,
  CenterFullScreen,
  RowSpaceBetween,
  ScreenContainer,
  ScreenWrapper,
  SheetDetailsWrapper,
  StyledImage,
  StyledWrapper,
  ColumnWithMargins,
} from 'styles/ContainerStyles';
import { RouteProp, useRoute } from '@react-navigation/native';
import { RootStackParamList } from 'types/navigation';
import { useTranslation } from 'react-i18next';
import Sheet from 'components/sheets/Sheet';
import ParkingReceiptContent from 'components/receipt/ParkingReceiptContent';
import { useQuery } from '@apollo/client';
import { READ_PARKING_SESSION } from 'graphql/queries/readParkingSession';
import {
  Maybe,
  ParkingSession,
  Query,
  QueryReadParkingSessionArgs,
} from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';
import { Dimensions } from 'react-native';
import useReadChargingSession from 'hooks/useReadChargingSession';
import { chargingSession } from 'utils/chargingTestData';
import { testIdentifiers } from 'utils/testIdentifiers';
const GetSheetLayout = ({
  sessionData,
  chargingSessionId,
  chargingTransactionId,
  chargingPaymentError,
  chargingSessionPrice,
}: {
  sessionData?: ParkingSession;
  chargingSessionId: string | undefined;
  chargingTransactionId: Maybe<string> | undefined;
  chargingPaymentError: boolean;
  chargingSessionPrice: number;
}) => {
  const { height } = Dimensions.get('screen');
  const biggerHeight = height - theme.normalising.heightPixel(350);
  const sheetHeight = sessionData?.parkZone?.info?.doorCode
    ? [biggerHeight]
    : [400];
  if (isWeb) {
    return (
      <ParkingReceiptContent
        chargingSessionId={chargingSessionId}
        sessionData={sessionData}
        chargingPaymentError={chargingPaymentError}
        chargingTransactionId={chargingTransactionId}
        chargingSessionPrice={chargingSessionPrice}
      />
    );
  }
  return (
    <Sheet snappingPoints={sheetHeight} hideTabBarOnIndex={1} hideHandle>
      <SheetDetailsWrapper pt={20}>
        <ParkingReceiptContent
          sessionData={sessionData}
          chargingSessionId={chargingSessionId}
          chargingPaymentError={chargingPaymentError}
          chargingTransactionId={chargingTransactionId}
          chargingSessionPrice={chargingSessionPrice}
        />
      </SheetDetailsWrapper>
    </Sheet>
  );
};

const ParkingReceiptScreen = () => {
  const { t } = useTranslation();
  const { params } =
    useRoute<RouteProp<RootStackParamList, 'parkingReceipt'>>();

  const sessionId = params?.sessionId || '';
  const chargingSessionId = params?.chargingSessionId;
  let sessionData: ParkingSession | undefined;

  const { data, loading, startPolling, stopPolling } = useQuery<
    Query,
    QueryReadParkingSessionArgs
  >(READ_PARKING_SESSION, {
    variables: { sessionId },
    skip: sessionId.length === 0,
    fetchPolicy: 'cache-and-network',
  });

  if (data) {
    sessionData = data.readParkingSession as ParkingSession;
    sessionData?.paymentState === 'NotInitiated' ||
    sessionData?.paymentState === 'Pending'
      ? startPolling(2000)
      : stopPolling();
  }
  const isLoading =
    loading ||
    sessionData?.paymentState === 'NotInitiated' ||
    sessionData?.paymentState === 'Pending';
  const isPaymentSuccess =
    sessionData?.paymentState !== 'Failed' &&
    sessionData?.paymentState !== 'Retrying';

  const { chargingSessionData, chargingTransactionId, chargingPaymentError } =
    useReadChargingSession(chargingSessionId, !!chargingSession);

  const chargingSessionPrice =
    chargingSessionData?.readChargingSession?.priceInCents ?? 0;

  return (
    <>
      <ScreenContainer background={theme.colors.gray50}>
        {isLoading && (
          <CenterFullScreen {...testIdentifiers('payment-state-loading')}>
            <Spinner />
            <BaseText center>{t('payment.transactionLoading')}</BaseText>
          </CenterFullScreen>
        )}
        {!isLoading && (
          <ScreenWrapper tabBarHeight={10}>
            <StyledWrapper center maxWidth={550}>
              <ColumnWithMargins mb={15}>
                <RowSpaceBetween padding={0}>
                  {/* TODO: add this when sending receipt is possible
                  <GoBack
                    testID="send-receipt-button"
                    onPress={() => undefined}>
                    <MetaMargin>{t('button.sendReceipt')} </MetaMargin>
                    <Icon name="share" size="s" />
                  </GoBack> */}
                </RowSpaceBetween>
              </ColumnWithMargins>
              <StyledImage
                testID="parking-receipt-image"
                width={173}
                height={102}
                center
                source={require('assets/images/parking-receipt-car.png')}
              />
              <StyledWrapper center maxWidth={300}>
                <Title center {...testIdentifiers('parking-receipt-header')}>
                  {isPaymentSuccess && !chargingPaymentError
                    ? t('parking.receipt.header')
                    : t('parking.receipt.paymentFailed')}
                </Title>
              </StyledWrapper>
              {!isPaymentSuccess ||
                (chargingPaymentError && (
                  <Notification>
                    <Meta>{t('parking.receipt.paymentRetrying')}</Meta>
                  </Notification>
                ))}
            </StyledWrapper>
            {!isLoading && isWeb && (
              <GetSheetLayout
                sessionData={sessionData}
                chargingSessionId={chargingSessionId}
                chargingTransactionId={chargingTransactionId}
                chargingPaymentError={chargingPaymentError}
                chargingSessionPrice={chargingSessionPrice}
              />
            )}
          </ScreenWrapper>
        )}
      </ScreenContainer>
      {!isLoading && !isWeb && (
        <GetSheetLayout
          sessionData={sessionData}
          chargingSessionId={chargingSessionId}
          chargingTransactionId={chargingTransactionId}
          chargingPaymentError={chargingPaymentError}
          chargingSessionPrice={chargingSessionPrice}
        />
      )}
    </>
  );
};

export default ParkingReceiptScreen;
