import { format, isFuture, isToday, isTomorrow } from 'date-fns';
import i18n from 'i18next';
import { HslDetails, TicketType } from 'types/generatedSchemaTypes';
import { getLocale } from './commonUtils';

export const titleTypeText = (ticketType?: TicketType, small = false) => {
  if (!ticketType) {
    return '';
  }
  const ticketTypeText = i18n.t(
    `hslTicket.types.${ticketType?.toLowerCase()}.title`
  );
  return `${small ? ticketTypeText.toLowerCase() : ticketTypeText}${i18n.t(
    'hslTicket.types.ticket'
  )}`;
};

export const validTimeText = (hslDetails: HslDetails) => {
  let timeToShow: Date;
  let isTicketStarted: boolean;
  if (!hslDetails.validTo) {
    return '';
  }

  if (hslDetails.validFrom && isFuture(new Date(hslDetails.validFrom))) {
    timeToShow = new Date(hslDetails.validFrom);
    isTicketStarted = false;
  } else {
    timeToShow = new Date(hslDetails.validTo);
    isTicketStarted = true;
  }

  const formatDate = (formatForDate: string) => {
    return format(timeToShow, `${formatForDate}`, {
      locale: getLocale(),
    });
  };
  const until = () => {
    switch (hslDetails.ticketType) {
      case 'SINGLE':
      case 'DAY':
        if (isTomorrow(timeToShow)) {
          return i18n.t('hslTicket.validityTomorrow');
        } else if (isToday(timeToShow)) {
          return formatDate('HH:mm');
        }
        return formatDate('d.M.Y HH:mm');
      case 'SEASON':
        return formatDate('d.M.Y HH:mm');
    }
  };
  try {
    return i18n.t(
      `${
        isTicketStarted ? 'hslTicket.validityUntil' : 'hslTicket.validityFrom'
      }`,
      {
        time: until(),
      }
    );
  } catch {
    return '';
  }
};
