import { useReactiveVar } from '@apollo/client';
import useOngoingChargingSession from 'hooks/useOngoingChargingSession';
import useOngoingParkingSession from 'hooks/useOngoingParkingSession';
import { useEffect, useMemo } from 'react';
import {
  selectedOngoingSession,
  selectedSessionIdFromMultiple,
} from 'states/common';
import { OngoingSessionType } from 'types/common';

const useOngoingMultipleSession = () => {
  const { ongoingParkingSession, allParkingSessions } =
    useOngoingParkingSession();
  const { ongoingChargingSession } = useOngoingChargingSession();
  const selectedSession = useReactiveVar(selectedSessionIdFromMultiple);
  const allData = useMemo((): OngoingSessionType[] => {
    if (!ongoingChargingSession) {
      return allParkingSessions as OngoingSessionType[];
    }
    return [
      ...(allParkingSessions || []),
      ...([ongoingChargingSession] || []),
    ] as OngoingSessionType[];
  }, [allParkingSessions, ongoingChargingSession]);

  useEffect(() => {
    const hasOngoingParkingSession = !!ongoingParkingSession?.sessionId;
    const hasOngoingChargingSession =
      !!ongoingChargingSession?.chargingSessionId;
    const multipleSessions = allData && allData.length > 1;
    const isChargingSession =
      selectedSession === ongoingChargingSession?.chargingSessionId;
    if (multipleSessions && !selectedSession) {
      selectedOngoingSession('MULTIPLE');
    } else if (
      (multipleSessions && !isChargingSession) ||
      (hasOngoingParkingSession && !selectedSession) ||
      ongoingParkingSession?.sessionId === selectedSession
    ) {
      selectedOngoingSession('PARKING');
    } else if (
      (multipleSessions && isChargingSession) ||
      (hasOngoingChargingSession && !selectedSession)
    ) {
      selectedOngoingSession('CHARGING');
    } else {
      selectedOngoingSession('');
    }
  }, [ongoingParkingSession, ongoingChargingSession, allData, selectedSession]);

  return {
    parkAndChargeData: allData,
  };
};

export default useOngoingMultipleSession;
