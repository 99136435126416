import { IconButton, theme } from 'aimo-ui';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import Icon from '../icons/Icon';
import { testIdentifiers } from 'utils/testIdentifiers';

type FilterButtonProps = {
  onPress?: () => void;
  isActive?: boolean;
  testID?: string;
};
const FilterButton = ({
  onPress,
  isActive = false,
  testID = '',
}: FilterButtonProps) => {
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      onPress={onPress}
      {...testIdentifiers(`${testID}-filter-button`)}>
      <IconButton
        rounded
        buttonType={isActive ? 'primary' : 'secondary'}
        fullWidth={false}
        testID="filter-icon"
        onPress={onPress}
        iconComponent={() => (
          <Icon name="Filter-Outlined" size="s" color={theme.colors.white} />
        )}
      />
    </TouchableOpacity>
  );
};

export default FilterButton;
