import { makeVar, ReactiveVar } from '@apollo/client';
import { MMKVStorage } from './MMKVStorage';
import config from '../config/config';
import Logger from './Logger';

const { reactiveVarPrefix } = config;

/**
 * Creates a persisted stored var that does not require to make additional update calls when used like a regular makeVar.
 * @param {T} value - The initial/fallback value.
 * @param {string} storageKey - The value is stored under [prefix]_{storageKey}. This _must_ be carry the same name as the named module it is exported as!
 */
export function makeStoredVar<T>(value: T, storageKey: string): ReactiveVar<T> {
  const reactiveVar = makeVar<T>(value);
  return new Proxy(reactiveVar, {
    apply: function (target, thisArg, argumentsList) {
      if (argumentsList.length > 0) {
        setItemToStorage(storageKey, argumentsList[0]);
      }
      return argumentsList.length === 0
        ? reactiveVar()
        : reactiveVar(argumentsList[0]);
    },
  });
}

// Used for updating reactive variable state and add/change key,value in MMKVStorage
export const updateStateAndStorage = (
  reactiveVar: ReactiveVar<any>,
  key: string,
  value: any
) => {
  try {
    reactiveVar(value);
    try {
      setItemToStorage(key, value);
    } catch (e) {
      Logger.error('could not set MMKVStorage item with key ' + key);
    }
  } catch (e) {
    Logger.error('could not update reactiveVar ' + key);
  }
};

export const getItemFromStorage = (key: string) => {
  try {
    const item = MMKVStorage.getString(reactiveVarPrefix + key);
    return item !== undefined ? JSON.parse(item) : null;
  } catch (e) {
    Logger.error('Could not get item from MMKVStorage with key ' + key);
    return '';
  }
};

export const setItemToStorage = (key: string, value: any) => {
  try {
    MMKVStorage.set(reactiveVarPrefix + key, JSON.stringify(value));
    return true;
  } catch (e) {
    return false;
  }
};

export const clearMMKVStorage = () => {
  try {
    MMKVStorage.clearAll();
  } catch (e) {
    Logger.error(JSON.stringify(e), 'clearMMKVStorage');
  }
};

const hydrateVariables = async (keys: string[], modules: any) => {
  keys.forEach(async (key: string) => {
    const value = getItemFromStorage(key);
    // needs to accept also false value, ignore only null
    if (value !== null) {
      await modules[key](value);
    }
  });
};

export const hydrateReactiveMMKVStorage = async () => {
  await import('../states/persistInStorage')
    .then(async (modules: any) => {
      const keys = Object.keys(modules);
      await hydrateVariables(keys, modules);
    })
    .catch((e) =>
      Logger.error(JSON.stringify(e), 'hydrateReactiveMMKVStorage')
    );
};
