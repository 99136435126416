import { useMutation } from '@apollo/client';
import { UPDATE_CARD } from '@mutations/updateCard';
import { GET_CUSTOMER } from '@queries/getCustomer';
import { BaseText, Button, FieldInput, Meta, theme, Toggle } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import Icon from 'components/icons/Icon';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useCreditCardCount from 'hooks/useCreditCardCount';
import useDebounce from 'hooks/useDebounce';
import useShowPaymentCardStatus from 'hooks/useShowPaymentCardStatus';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectedPaymentCard, sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
import {
  CardStatus,
  Mutation,
  MutationUpdateCardArgs,
} from 'types/generatedSchemaTypes';
import { isIos } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const Row = styled.View`
  flex-direction: row;
  align-items: center;
`;

const RowOption = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  padding-top: 16px;
`;

const IconWrapper = styled.View`
  margin-right: 4px;
`;

const Container = styled.View`
  flex: 1;
  flex-direction: column;
  flex-basis: auto;
`;

const Grow = styled.View`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
`;

const Footer = styled.View`
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  flex-basis: 100px;
`;

const PaymentCardContent: React.FC = () => {
  const { t } = useTranslation();
  const {
    status,
    category,
    isDefault,
    description,
    cardId,
    newCard,
    expMonth,
    expYear,
  } = selectedPaymentCard();
  const hasMultipleCards = useCreditCardCount() > 1;
  const [isBusiness, setIsBusiness] = useState(category === 'business');
  const [_isDefault, _setIsDefault] = useState(isDefault);
  const [name, setName] = useState(description);
  const debouncedName = useDebounce(name, 500);
  const {
    flags: { card_business },
  } = useContext(FeatureFlagsContext);
  const showBusinessCard = showFeatureFlag(card_business);
  const { isErrorStatus, expiresSoon, daysUntilExpiration } =
    useShowPaymentCardStatus(
      (status || 'Active') as CardStatus,
      expMonth,
      expYear
    );

  const [updateCard, { loading }] = useMutation<
    Mutation,
    MutationUpdateCardArgs
  >(UPDATE_CARD, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        errorPolicy: 'all',
      },
    ],
    onError: () => showToast('error.paymentCardUpdate', 'error'),
  });

  const handleIsBusinessChange = async (value: boolean) => {
    setIsBusiness(value);
    updateCard({
      variables: {
        cardId: cardId,
        updateCardActionType: value
          ? 'SET_CATEGORY_AS_BUSINESS'
          : 'SET_CATEGORY_AS_UNKNOWN',
      },
    });
  };

  const handleSetDefaultToggleChange = (value: boolean) => {
    _setIsDefault(value);
  };

  const handleSetDefaultButtonChange = async () => {
    _setIsDefault(true);
    updateCard({
      variables: {
        cardId: cardId,
        updateCardActionType: 'SET_ISDEFAULT_AS_TRUE',
      },
    });
  };

  const handleDoneButtonClick = async () => {
    if (_isDefault) {
      updateCard({
        variables: {
          cardId: cardId,
          updateCardActionType: 'SET_ISDEFAULT_AS_TRUE',
        },
      });
    }
    sheetModal('');
  };

  useEffect(() => {
    if (description !== debouncedName) {
      updateCard({
        variables: {
          cardId: cardId,
          updateCardActionType: 'SET_DESCRIPTION',
          description: debouncedName,
        },
      });
    }
  }, [cardId, debouncedName, description, updateCard]);

  const PaymentCardStatusItem = () => {
    const expiredSoonText =
      daysUntilExpiration === 1
        ? t('paymentMethods.paymentCardStatuses.toBeExpiredOneDay')
        : t('paymentMethods.paymentCardStatuses.toBeExpired', {
            value: daysUntilExpiration,
          });
    return (
      <Row testID="card-status-text">
        <IconWrapper>
          <Icon
            name={`Error-Filled${expiresSoon ? '-Black' : ''}`}
            size="m"
            color={
              expiresSoon ? theme.colors.boost.yellow : theme.colors.boost.red
            }
          />
        </IconWrapper>
        <Meta color={expiresSoon ? theme.colors.black : theme.colors.boost.red}>
          {expiresSoon
            ? expiredSoonText
            : t(`paymentMethods.paymentCardStatuses.${status.toLowerCase()}`)}
        </Meta>
      </Row>
    );
  };

  return (
    <>
      <Container>
        <Grow>
          <StyledWrapper mb={18}>
            {(isErrorStatus || expiresSoon) && <PaymentCardStatusItem />}
            {_isDefault && (
              <Row {...testIdentifiers('card-default-text')}>
                <IconWrapper>
                  <Icon
                    name="Info-Filled"
                    size="m"
                    color={theme.colors.boost.green}
                  />
                </IconWrapper>
                <Meta>{t('paymentMethods.paymentCardDefault')}</Meta>
              </Row>
            )}
          </StyledWrapper>
          <StyledWrapper mb={15}>
            <FieldInput
              value={name}
              title={t('paymentMethods.paymentCardName')}
              onChangeText={setName}
              testID="payment-methods-card-name"
              isSheet={isIos}
            />
          </StyledWrapper>
          <StyledWrapper mb={18}>
            {showBusinessCard && (
              <RowOption>
                <BaseText>{t('paymentMethods.businessCardQuestion')}</BaseText>
                <Toggle
                  value={isBusiness}
                  onValueChange={handleIsBusinessChange}
                  {...testIdentifiers('business-card-toggle')}
                />
              </RowOption>
            )}
            {hasMultipleCards && newCard && (
              <RowOption>
                <BaseText>{t('paymentMethods.isDefaultQuestion')}</BaseText>
                <Toggle
                  value={_isDefault}
                  onValueChange={handleSetDefaultToggleChange}
                  {...testIdentifiers('default-card-toggle')}
                />
              </RowOption>
            )}
          </StyledWrapper>
        </Grow>
      </Container>
      <StyledWrapper mb={40}>
        <Footer>
          {!_isDefault && !isErrorStatus && !newCard && (
            <StyledWrapper mb={20}>
              <Button
                loading={loading}
                {...testIdentifiers('payment-methods-set-default-button')}
                buttonType="tertiary"
                text={t('paymentMethods.setAsDefault')}
                size="l"
                onPress={handleSetDefaultButtonChange}
              />
            </StyledWrapper>
          )}
          {!newCard && (
            <StyledWrapper mb={0}>
              <Button
                {...testIdentifiers('payment-methods-remove')}
                buttonType="tertiary"
                size="l"
                text={t('paymentMethods.removeCard')}
                onPress={() => sheetModal('confirmPaymentRemoval')}
              />
            </StyledWrapper>
          )}
          {newCard && (
            <StyledWrapper mb={0}>
              <Button
                {...testIdentifiers('payment-methods-done')}
                buttonType="secondary"
                text={t('button.done')}
                size="l"
                loading={loading}
                onPress={handleDoneButtonClick}
              />
            </StyledWrapper>
          )}
        </Footer>
      </StyledWrapper>
    </>
  );
};

export default PaymentCardContent;
