import { ErrorPolicy, useMutation, useReactiveVar } from '@apollo/client';
import { CREATE_ACCEPTANCE } from '@mutations/createAcceptance';
import { CREATE_ACCEPTANCES } from '@mutations/createAcceptances';
import { useNavigation } from '@react-navigation/native';
import { showToast } from 'components/common/CustomToast';
import AccountContext from 'context/AccountContext';
import { GET_APPLICATION_OPEN_SCREEN } from 'graphql/queries/getApplicationUpdateInfo';
import { GET_CUSTOMER } from 'graphql/queries/getCustomer';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import { useContext, useMemo, useState } from 'react';
import { acceptanceError, isSignUpFlow } from 'states/common';
import {
  accessToken,
  featureCountryCode,
  onboardingCompleted,
} from 'states/persistInStorage';
import {
  Acceptance,
  AcceptanceType,
  CountryCode,
  Mutation,
  MutationCreateAcceptanceV3Args,
  MutationCreateAcceptancesV3Args,
} from 'types/generatedSchemaTypes';
import { getNewUserFromToken } from 'utils/accessTokenUtils';
import { deviceOs, isWeb } from 'utils/deviceUtils';
import Logger from 'utils/Logger';
import { isOnboardingOpen } from 'utils/navigationUtils';

const useCreateAcceptanceMutation = () => {
  const {
    isInRedirectProcess,
    navigateToNext,
    navigateToOnboarding,
    setReturnRedirectToAccount,
  } = useAfterAuthenticationRedirect();
  const navigation = useNavigation();
  const {
    refetchCustomer: fetchCustomerAfterSignUp,
    customer: { countryCode: customerCountryCode },
  } = useContext(AccountContext);
  const [loadingMultipleAcceptances, setLoadingMultipleAcceptances] =
    useState(false);
  const featureCountry = useReactiveVar(featureCountryCode);
  const isNewUser = getNewUserFromToken(accessToken());

  const [createAcceptanceMutation, { loading: loadingAcceptance }] =
    useMutation<Mutation, MutationCreateAcceptanceV3Args>(CREATE_ACCEPTANCE, {
      errorPolicy: 'all',
    });

  const [
    createAcceptancesMutation,
    { loading: loadingAcceptances, error: createAcceptancesError },
  ] = useMutation<Mutation, MutationCreateAcceptancesV3Args>(
    CREATE_ACCEPTANCES,
    {
      errorPolicy: 'all',
    }
  );

  const loading = useMemo(
    () => loadingAcceptance || loadingMultipleAcceptances || loadingAcceptances,
    [loadingAcceptance, loadingMultipleAcceptances, loadingAcceptances]
  );

  const mutationOptions = (
    refetchCustomer: boolean,
    refetchAppOpenScreen: boolean,
    callback?: VoidFunction
  ) => ({
    awaitRefetchQueries: refetchCustomer,
    refetchQueries: refetchCustomer
      ? [
          {
            query: GET_CUSTOMER,
            errorPolicy: 'all' as ErrorPolicy,
          },
          ...(refetchAppOpenScreen
            ? [
                {
                  query: GET_APPLICATION_OPEN_SCREEN,
                  variables: { deviceOs, countryCode: featureCountry },
                },
              ]
            : []),
        ]
      : [],
    onError: () => {
      if (refetchAppOpenScreen) {
        showToast('error.genericError', 'error');
      }
    },
    onCompleted: callback,
  });

  const createAcceptance = async (
    accepted: boolean,
    type: AcceptanceType,
    countryCode: CountryCode,
    refetchCustomer = true,
    refetchAppOpenScreen = false,
    callback?: VoidFunction
  ) => {
    return await createAcceptanceMutation({
      variables: {
        input: {
          accepted,
          countryCode,
          type,
        },
      },
      ...mutationOptions(refetchCustomer, refetchAppOpenScreen, callback),
    });
  };

  const handleAcceptancesAfterSignUp = async (marketingConsent: boolean) => {
    setLoadingMultipleAcceptances(true);
    onboardingCompleted(false);
    const customerResult = await fetchCustomerAfterSignUp();
    const { countryCode: newCustomerCountryCode } =
      customerResult?.data.readCustomerV3 || {};

    if (newCustomerCountryCode) {
      await createAcceptances(marketingConsent);

      if (createAcceptancesError) {
        acceptanceError(true);
        setLoadingMultipleAcceptances(false);
        showToast('error.genericError', 'error');
        return;
      }
      // Handle navigation
      await fetchCustomerAfterSignUp();
      if (!isInRedirectProcess() && !createAcceptancesError) {
        setReturnRedirectToAccount(false);
      }
      if (!isOnboardingOpen(navigation) && !createAcceptancesError) {
        isSignUpFlow(false);
        navigateToOnboarding();
      }
    }
    setLoadingMultipleAcceptances(false);
  };

  const handleAcceptancesAfterSignIn = async (marketingConsent: boolean) => {
    await createAcceptances(marketingConsent).then(async () => {
      await fetchCustomerAfterSignUp();
    });
    // Handle navigation
    navigateToNext(!isInRedirectProcess());
  };

  const handleAcceptances = async (marketingConsent: boolean) => {
    onboardingCompleted(!isNewUser);
    await createAcceptances(marketingConsent);
    await fetchCustomerAfterSignUp();
    if (isWeb) {
      navigateToOnboarding();
    }
  };

  const createAcceptances = async (
    marketingConsent: boolean,
    refetchCustomer = false,
    refetchAppOpenScreen = false,
    callback?: VoidFunction
  ) => {
    if (!featureCountry) {
      Logger.error(
        'CreateAcceptances can not be executed because feature country is null'
      );
      return;
    }

    const termsAcceptance: Acceptance = {
      accepted: true,
      type: 'TERMS_ACCEPTED',
      countryCode: featureCountry,
    };
    const marketingAcceptance: Acceptance = {
      accepted: marketingConsent,
      type: 'MARKETING_ALLOWED',
      countryCode: customerCountryCode,
    };
    return await createAcceptancesMutation({
      variables: {
        input: [termsAcceptance, marketingAcceptance],
      },
      ...mutationOptions(refetchCustomer, refetchAppOpenScreen, callback),
    });
  };

  return {
    createAcceptance,
    createAcceptances,
    handleAcceptancesAfterSignIn,
    handleAcceptancesAfterSignUp,
    handleAcceptances,
    loading,
  };
};
export default useCreateAcceptanceMutation;
