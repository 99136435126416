import { useReactiveVar } from '@apollo/client';
import { Meta, theme } from 'aimo-ui';
import PoolingBadge from 'components/park/PoolingBadge';
import PrepaidTickets from 'components/park/detail/PrepaidTickets';
import Tariff from 'components/park/tariff/Tariff';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { format } from 'date-fns';
import useGetMatchingAccessPermitForParkingZone from 'hooks/useGetMatchingAccessPermitForParkingZone';
import useReadPurchasedPrepaidTickets from 'hooks/useReadPurchasedPrepaidTickets';
import { ShortTermParkingScreen } from 'hooks/useShortTermParkingWizard';
import useSelectedCompanyBenefit from 'hooks/useSelectedCompanyBenefit';
import { default as React, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { resumableSession } from 'states/common';
import styled from 'styled-components/native';
import { StyledContainer } from 'styles/ContainerStyles';
import {
  ChargingStation,
  Maybe,
  Pmc,
  PredefinedTicket,
  UnifiedPermit,
} from 'types/generatedSchemaTypes';
import { getLocale } from 'utils/commonUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { ImportantZoneNotes } from '../ParkingZoneNotes';
import DurationQuickSelect from '../detail/DurationQuickSelect';
import TabContainer, { CommonTabProps } from './TabContainer';

type ParkingZoneParkTabProps = {
  chipsDisabled: boolean;
  showDefaultChips?: Maybe<boolean>;
  predefinedTicketsOnly?: Maybe<boolean>;
  allowedParkingTimes: {
    predefinedTickets: Maybe<PredefinedTicket>[];
  };
  setCurrentScreen: (screen: ShortTermParkingScreen) => void;
  myPermitsForZone: Maybe<UnifiedPermit>[] | undefined;
  availablePermits: Maybe<Pmc[]> | undefined;
} & CommonTabProps;

const StyledMeta = styled(Meta)`
  padding: 4px;
  font-weight: 500;
`;

const ResumeInfoBox = styled(StyledContainer)`
  border-radius: 20px;
  margin-top: 15px;
  margin-bottom: 0px;
`;

const PoolingBadgeWrapper = styled.View`
  border-bottom-width: 3px;
  border-bottom-color: ${theme.colors.gray50};
  padding-bottom: 10px;
`;

const ParkingZoneParkTab: React.FC<ParkingZoneParkTabProps> = ({
  parkZone,
  chipsDisabled,
  predefinedTicketsOnly,
  showDefaultChips,
  isActiveTab,
  allowedParkingTimes,
  setCurrentScreen,
  hasImportantNotes,
  myPermitsForZone,
  availablePermits,
}: ParkingZoneParkTabProps) => {
  const {
    flags: { stp_tariff },
  } = useContext(FeatureFlagsContext);
  const showSTPTariff = showFeatureFlag(stp_tariff);
  const resumableSessionInfo = useReactiveVar(resumableSession);
  const { t } = useTranslation();

  const { uid = '', parkingZoneChargingStations } = parkZone || {};
  const countryCode = parkZone?.countryCode || 'FI';

  const { purchasedPrepaidTickets } = useReadPurchasedPrepaidTickets();
  const { predefinedTickets } = allowedParkingTimes;
  const prepaidTickets = predefinedTickets.filter(
    (predefinedTicket) =>
      predefinedTicket?.sessionType === 'PREPAID' &&
      predefinedTicket.duration &&
      predefinedTicket.duration !== ''
  );

  const purchasedAndPurchaseableTickets = [
    ...(myPermitsForZone ?? []),
    ...purchasedPrepaidTickets,
    ...prepaidTickets,
    ...(availablePermits ?? []),
  ];

  const handlePrepaidAndPermitListPress = () =>
    setCurrentScreen('PREDEFINED_TICKETS_AND_PERMITS');

  const { matchingPermit } = useGetMatchingAccessPermitForParkingZone(
    parkZone?.uid
  );

  const serviceFee = useSelectedCompanyBenefit()?.transactionFee;

  return (
    <TabContainer isActiveTab={isActiveTab}>
      {showSTPTariff && (
        <Tariff
          headlineTitle
          chargingStations={parkingZoneChargingStations as ChargingStation[]}
          borderBottom={!hasImportantNotes}
        />
      )}
      {resumableSessionInfo && (
        <ResumeInfoBox
          color={theme.colors.infoYellow}
          testID={'resumable-session-info'}>
          <StyledMeta color={theme.colors.black}>
            {t('parking.resumeSession', {
              startTime: format(
                new Date(resumableSessionInfo.startDate || ''),
                'kk:mm',
                { locale: getLocale() }
              ),
            })}
          </StyledMeta>
        </ResumeInfoBox>
      )}
      {matchingPermit && (
        <PoolingBadgeWrapper>
          <PoolingBadge parkingZoneUid={parkZone?.uid} />
        </PoolingBadgeWrapper>
      )}
      <ImportantZoneNotes
        parkZoneNotes={parkZone?.notes}
        marginTop={!!resumableSessionInfo}
      />
      <DurationQuickSelect
        parkingZoneUid={uid}
        countryCode={countryCode}
        disabled={chipsDisabled}
        showDefaultChips={!!showDefaultChips}
        predefinedTicketsOnly={!!predefinedTicketsOnly}
        serviceFee={serviceFee || undefined}
      />
      {purchasedAndPurchaseableTickets.length > 0 && (
        <PrepaidTickets
          tickets={purchasedAndPurchaseableTickets as PredefinedTicket[]}
          countryCode={countryCode}
          onListPress={handlePrepaidAndPermitListPress}
          testID="prepaid-tickets-listing"
        />
      )}
    </TabContainer>
  );
};

export default ParkingZoneParkTab;
