import { gql } from '@apollo/client';

export const UPDATE_PERMIT = gql`
  mutation updatePermit($input: UpdatePermitInput!) {
    updatePermit(input: $input) {
      id
      registrationPlates {
        plateText
        countryCode
      }
    }
  }
`;
