import { useNavigation } from '@react-navigation/native';
import { Headline1, Meta, Spinner, theme } from 'aimo-ui';
import CrickleCutCardBackground from 'components/common/CrickleCutCardBackground';
import ServiceContext from 'context/ServiceContext';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';
import { tabBarVisible } from 'states/common';
import styled from 'styled-components/native';
import {
  ColumnWithMargins,
  SheetDetailsWrapper,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { ParkingSession } from 'types/generatedSchemaTypes';
import { RootNavProps, ServicesNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import PrepaidTicketContent from './PrepaidTicketContent';

const SessionWrapper = styled.TouchableOpacity`
  margin-bottom: 20px;
`;

const SessionItem = ({ session }: { session: ParkingSession }) => {
  const { navigate } = useNavigation<ServicesNavProps>();
  const { t } = useTranslation();
  const { sessionId, parkZone } = session;

  const handleSelectTicket = (uid: string) => {
    navigate('prepaidTicket', { ticketId: uid });
  };

  return (
    <SessionWrapper
      onPress={() => handleSelectTicket(session.sessionId)}
      activeOpacity={0.65}
      testID={`session-${sessionId}-wrapper`}>
      <CrickleCutCardBackground
        aboveCutView={() => (
          <ColumnWithMargins mr={20} ml={20}>
            <StyledWrapper center mb={5}>
              <Headline1 center testID={`session-${sessionId}-name`}>
                {parkZone?.commercialZoneView?.name || parkZone?.name}
              </Headline1>
            </StyledWrapper>
            <StyledWrapper center>
              <Meta color={theme.colors.gray200}>
                {t('services.prepaidTitle')}
              </Meta>
            </StyledWrapper>
          </ColumnWithMargins>
        )}
        belowCutView={() => <PrepaidTicketContent session={session} />}
        backgroundColor={theme.colors.white}
        cutColor={theme.colors.gray100}
      />
    </SessionWrapper>
  );
};

const PrepaidTickets = () => {
  const { navigate, addListener } = useNavigation<RootNavProps>();
  const { myPrepaidSessions, prepaidLoading, prepaidError } =
    useContext(ServiceContext);
  // Hide tab bar
  useEffect(() => {
    tabBarVisible(isWeb);
  }, []);

  useEffect(() => {
    addListener('beforeRemove', handleNavigation);
    return () => {
      addListener('beforeRemove', handleNavigation);
    };
  }, [addListener, navigate]);

  const handleNavigation = (e: any) => {
    // Show tab bar when navigates back
    if (e?.data.action.type === 'GO_BACK') {
      tabBarVisible(true);
    }
  };

  if (prepaidError) {
    return <></>;
  }

  return (
    <ScrollView>
      <SheetDetailsWrapper>
        {prepaidLoading && (
          <StyledWrapper center>
            <Spinner />
          </StyledWrapper>
        )}
        {!prepaidLoading && (
          <StyledWrapper
            center={isWeb}
            mb={10}
            maxWidth={isWeb ? 600 : undefined}>
            {myPrepaidSessions?.map((session: ParkingSession) => (
              <SessionItem session={session} key={session.sessionId} />
            ))}
          </StyledWrapper>
        )}
      </SheetDetailsWrapper>
    </ScrollView>
  );
};

export default PrepaidTickets;
