import { useReactiveVar } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import {
  Button,
  Headline3,
  HorizontalRadioList,
  HorizontalRadioListData,
  Spinner,
} from 'aimo-ui';
import { format, getYear } from 'date-fns';
import useParkingHistoryFilter, {
  HistoryTypeFilter,
} from 'hooks/useParkingHistoryFilter';
import { concat, Dictionary, groupBy, isFunction } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parkingHistoryViewFilter } from 'states/common';
import styled from 'styled-components/native';
import {
  ColumnWithMargins,
  SheetDetailsWrapper,
  StyledWrapper,
} from 'styles/ContainerStyles';
import {
  ChargingSession,
  ParkingSession,
  Receipt,
  ReceiptItemType,
} from 'types/generatedSchemaTypes';
import { getCorrectBillingItem, getLocale } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { chargingTypes, parkingTypes, receiptQuerySort } from 'utils/parkUtils';
import FilteredSessionContent from './FilteredSessionContent';
import {
  ItemChargingHistoryDisplay,
  ItemParkingHistoryDisplay,
  ItemPermitHistoryDisplay,
  NoHistory,
} from './HistoryComponents';
import SectionListCustom from './SectionListCustom';

const TitleElement = styled(Headline3)`
  margin-bottom: 20px;
  padding-bottom: 8px;
  background-color: ${(p) => p.theme.colors.white};
`;

const Separator = styled.View`
  border-bottom-width: 3px;
  margin-top: -3px;
  border-bottom-color: ${(p) => p.theme.colors.gray50};
`;
type HistoryData = {
  title: string;
  data: Receipt[];
  groupIndex: number;
}[];

type RouteParams = {
  filter?: HistoryTypeFilter;
};

export type SessionHistoryData = {
  title: string;
  data: ParkingSession[];
  groupIndex: number;
}[];

const getData = (
  groupedHistory: Dictionary<Receipt[] | [Receipt, ...Receipt[]]>
) => {
  const data: HistoryData = [];
  for (const [key, value] of Object.entries(groupedHistory)) {
    data.push({ title: key, data: value, groupIndex: data.length });
  }
  return data;
};

const getSessionData = (
  groupedHistory: Dictionary<
    ParkingSession[] | [ParkingSession, ...ParkingSession[]]
  >
) => {
  const data: SessionHistoryData = [];
  for (const [key, value] of Object.entries(groupedHistory)) {
    data.push({ title: key, data: value, groupIndex: data.length });
  }
  return data;
};

const WholePageSpinnerWrapper = styled.View`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const WholePageSpinner = () => (
  <WholePageSpinnerWrapper testID="whole-page-spinner-wrapper">
    <Spinner />
  </WholePageSpinnerWrapper>
);

export const ListFooter = ({
  isLast,
  fetchMore,
  loading,
}: {
  isLast: boolean;
  fetchMore?: () => any;
  loading: boolean;
}) => {
  const { t } = useTranslation();
  if (isLast || !isFunction(fetchMore)) {
    return <></>;
  }
  return (
    <StyledWrapper mb={10} center maxWidth={200}>
      <Button
        disabled={loading}
        onPress={fetchMore}
        text={t('button.showMore')}
        testID="show-more"
        loading={loading}
      />
    </StyledWrapper>
  );
};

const HistoryContent: React.FC<{
  receiptData?: Receipt[];
  failedSessions?: ParkingSession[] & ChargingSession[];
  sessionsFetchMore?: () => void;
  sessionsIsLast?: boolean;
  sessionsLoading?: boolean;
  isParkAndCharge?: boolean;
  fetchMore?: () => any;
  isLast: boolean;
  loading: boolean;
}> = ({
  receiptData = [],
  failedSessions = [],
  sessionsFetchMore,
  fetchMore,
  loading,
  isParkAndCharge,
  sessionsLoading,
  sessionsIsLast = true,
  isLast = true,
}) => {
  const { historyViewTypeFilters } = useParkingHistoryFilter();
  const [isInitialLoad, setIsInitialLoad] = useState(loading);
  const historyView = useReactiveVar(parkingHistoryViewFilter) || 'receipts';

  const route = useRoute();
  useEffect(() => {
    const routeParams: RouteParams = route?.params || {};
    const routeParamsFilter = routeParams?.filter;
    if (isInitialLoad && !loading) {
      if (routeParamsFilter) {
        const historyViewTypeFilter = historyViewTypeFilters.find(
          (filter) => filter.value === routeParamsFilter
        );
        if (historyViewTypeFilter) {
          handleOnSelectedParkingType(historyViewTypeFilter);
        }
      }
      setIsInitialLoad(false);
    }
  }, [loading, isInitialLoad, route?.params, historyViewTypeFilters]);

  const groupedHistory = groupBy(receiptQuerySort(receiptData), (historyEl) => {
    const startDate = historyEl?.items?.[0]?.startTime || historyEl?.created;
    const year = getYear(new Date(startDate ?? ''));
    const month = format(new Date(startDate ?? ''), 'LLLL', {
      locale: getLocale(),
    });
    return `${month} ${year}`;
  });

  const startTimeOfFailedSession = (item: any) => {
    if (item.startTime) {
      return item.startTime;
    }
    return item?.startDate ? item?.startDate : item?.created;
  };

  const sortedFailed = concat<any>(failedSessions).sort((a, b) => {
    const dateA = new Date(a.startDate || a.startTime || a.created).getTime();
    const dateB = new Date(b.startDate || b.startTime || b.created).getTime();
    return dateB > dateA ? 1 : -1;
  }) as ParkingSession[] & ChargingSession[];

  const groupedSessions = groupBy(sortedFailed, (historyEl) => {
    const startDate = startTimeOfFailedSession(historyEl);
    const year = getYear(new Date(startDate ?? ''));
    const month = format(new Date(startDate ?? ''), 'LLLL', {
      locale: getLocale(),
    });
    return `${month} ${year}`;
  });

  const data = getData(groupedHistory);
  const sessionData = getSessionData(groupedSessions);
  const ChargeItemComponent = ItemChargingHistoryDisplay;

  const memoizedItem = useCallback(
    ({ section: { groupIndex }, item, index }: any) => {
      const correctItems = getCorrectBillingItem(item.items) || [];
      const type = correctItems[0].type as ReceiptItemType;

      if (parkingTypes.includes(type) || type === 'PREPAID') {
        return (
          <ItemParkingHistoryDisplay
            item={item}
            groupIndex={groupIndex}
            index={index}
          />
        );
      } else if (chargingTypes.includes(type)) {
        return (
          <ChargeItemComponent
            item={item}
            groupIndex={groupIndex}
            index={index}
          />
        );
      } else {
        return (
          <ItemPermitHistoryDisplay
            item={item}
            groupIndex={groupIndex}
            index={index}
          />
        );
      }
    },
    [ChargeItemComponent]
  );

  const isReceipts = historyView === 'receipts' || !isParkAndCharge;
  const isFailed = historyView === 'failed' && isParkAndCharge;
  const isFree = historyView === 'free' && isParkAndCharge;
  const isBilling = historyView === 'billing' && isParkAndCharge;
  const isOther = historyView === 'other' && isParkAndCharge;

  const handleOnSelectedParkingType = (
    newValue: HorizontalRadioListData<HistoryTypeFilter>
  ) => {
    parkingHistoryViewFilter(newValue.value);
  };

  return (
    <SheetDetailsWrapper pt={isWeb ? 50 : 30}>
      {isInitialLoad ? (
        <WholePageSpinner />
      ) : (
        <>
          {isParkAndCharge && (
            <ColumnWithMargins mb={20} mt={20}>
              <HorizontalRadioList
                selectedValue={historyView}
                listData={historyViewTypeFilters}
                onSelected={handleOnSelectedParkingType}
              />
            </ColumnWithMargins>
          )}
          {(isFailed || isFree || isBilling || isOther) && (
            <FilteredSessionContent
              sessions={sessionData}
              sessionsFetchMore={sessionsFetchMore}
              isLast={sessionsIsLast}
              loading={sessionsLoading}
              noData={failedSessions.length === 0}
            />
          )}
          {data.length > 0 && isReceipts && (
            <SectionListCustom
              refreshing={loading}
              sections={data}
              stickySectionHeadersEnabled={true}
              keyExtractor={({ sessionId = '' }, index) => sessionId + index}
              renderItem={memoizedItem}
              renderSectionHeader={({ section: { title, groupIndex } }) => (
                <TitleElement testID={`parking-${groupIndex}`}>
                  {title}
                </TitleElement>
              )}
              ItemSeparatorComponent={() => <Separator />}
              ListFooterComponent={() => (
                <ListFooter
                  isLast={isLast}
                  fetchMore={fetchMore}
                  loading={loading}
                />
              )}
            />
          )}
          {data.length === 0 && !loading && isReceipts && <NoHistory />}
        </>
      )}
    </SheetDetailsWrapper>
  );
};

export default HistoryContent;
