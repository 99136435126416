import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { format } from 'date-fns';
import React, { ChangeEvent, useContext } from 'react';
import { Pressable, View } from 'react-native';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import '../park/styles/datePicker.css';
import ValidFromSelector from './ValidFromSelector';

const ValidFromPicker: React.FC<{
  validFrom: Date;
  onConfirm: (date: Date) => void;
  testID: string;
}> = ({ validFrom, onConfirm, testID }) => {
  const {
    flags: { ltp_buy_schedule },
  } = useContext(FeatureFlagsContext);
  const showDateSelector = showFeatureFlag(ltp_buy_schedule);
  const handleConfirm = (event: ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value);
    const dateAndTime = new Date(newDate.setHours(0, 0, 0, 0));
    onConfirm(dateAndTime);
  };
  const today = new Date();
  const Container = showDateSelector ? Pressable : View;

  return (
    <View>
      <Container testID={`${testID}-day-selection`}>
        <ValidFromSelector
          testID={testID}
          value={validFrom}
          showDateSelector={showDateSelector}
        />
        {showDateSelector && (
          <input
            type="date"
            className="datepicker-input"
            onChange={handleConfirm}
            min={format(today, 'yyyy-MM-dd')}
            required
          />
        )}
      </Container>
    </View>
  );
};

export default ValidFromPicker;
