import { IconWrapper } from 'components/SearchInput/styles';
import Icon from 'components/icons/Icon';
import { Meta, theme } from 'aimo-ui';
import { StyledWrapper } from 'styles/ContainerStyles';
import WarningWrapper from 'components/park/ParkingZoneWarningWrapper';
import React from 'react';
import styled from 'styled-components/native';
import { Row } from 'components/park/styles/styles';
import { useTranslation } from 'react-i18next';
const WrappingRow = styled(Row)`
  align-items: flex-start;
`;

const FailedToLoadChargersWarning = ({marginTop, marginBottom} : {marginTop?: boolean, marginBottom?: boolean}) => {
  const { t } = useTranslation();
  return (
    <WarningWrapper marginTop={marginTop} marginBottom={marginBottom}>
      <WrappingRow>
        <IconWrapper>
          <Icon name="Error-Filled" size="s" color={theme.colors.aimoUiRed} />
        </IconWrapper>
        <StyledWrapper maxWidth={300} mb={0}>
          <Meta>
            { t('error.failedToLoadChargersDetails') }
          </Meta>
        </StyledWrapper>
      </WrappingRow>
    </WarningWrapper>
  );
};

export default FailedToLoadChargersWarning;
