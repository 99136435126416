import { Headline3, theme } from 'aimo-ui';
import TimeSelection from 'components/park/timeSelection';
import PreloadingWrapper from 'components/park/timeSelection/PreloadingWrapper';
import useAllowedParkingTimes from 'hooks/useAllowedParkingTimes';
import useUpdateParkingMutation from 'hooks/useUpdateParkingMutation';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, Platform } from 'react-native';
import styled from 'styled-components/native';
import {
  ParkingSession,
  UpdateParkingSessionActionType,
} from 'types/generatedSchemaTypes';
import { RoundButton } from './OngoingParkingComponents';

const SheetContainer = styled.View`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

const HeadlineContainer = styled.View`
  margin-top: ${theme.normalising.heightPixel(35)}px;
  margin-bottom: ${theme.normalising.heightPixel(20)}px;
`;

const ActionContainer = styled.View`
  flex-direction: row;
  flex-shrink: 0;
  align-self: stretch;
  justify-content: space-evenly;
  margin-bottom: 40px;
`;

const Column = styled.View`
  align-items: center;
`;

const ColumnPlaceholder = styled.View`
  width: 68px;
`;

const ShrinkContainer = styled.View`
  flex-shrink: 1;
`;

type OngoingParkingExtendTimeProps = {
  testID?: string;
  session: ParkingSession;
  onClose: () => void;
};

const OngoingParkingExtendTime: React.FC<OngoingParkingExtendTimeProps> = ({
  testID,
  session,
  onClose,
}) => {
  const { t } = useTranslation();
  const allowedTimes = useAllowedParkingTimes(session.parkingZoneUid || '');
  const startDate = useMemo(
    () => (session.startDate ? new Date(session.startDate) : undefined),
    [session.startDate]
  );
  const maxDate = allowedTimes.parkingAllowedUntil
    ? new Date(allowedTimes.parkingAllowedUntil)
    : undefined;

  const initDate = useMemo(
    () =>
      session.predefinedEndDate
        ? new Date(session.predefinedEndDate)
        : undefined,
    [session.predefinedEndDate]
  );
  const [endDate, setEndDate] = useState(initDate);

  const extendParkingParams: {
    sessionId: string;
    actionType: UpdateParkingSessionActionType;
  } = {
    sessionId: session.sessionId,
    actionType: 'EXTEND',
  };

  const updateParkingCallback = () => {
    onClose();
  };
  const { updateParking, updateParkingLoading } = useUpdateParkingMutation({
    ...extendParkingParams,
    callback: updateParkingCallback,
  });

  const [isEndDateValid, setIsEndDateValid] = useState(false);
  useEffect(() => {
    setIsEndDateValid(!!endDate && endDate > new Date());
  }, [endDate]);

  const onConfirm = () => {
    updateParking({
      variables: {
        input: {
          ...extendParkingParams,
          newEndDate: endDate,
        },
      },
    });
  };

  return (
    <SheetContainer testID="ongoing-parking-extend-time-container">
      <KeyboardAvoidingView
        behavior="position"
        enabled
        keyboardVerticalOffset={Platform.select({ ios: -60, android: -110 })}>
        <ShrinkContainer>
          <HeadlineContainer>
            <Headline3 center color={theme.colors.white}>
              {t('parking.setNewEndTime')}
            </Headline3>
          </HeadlineContainer>
          <TimeSelection
            testID={`${testID}-time-selection`}
            maxDate={maxDate}
            darkMode
            startTime={startDate}
            endTime={endDate}
            initTime={initDate}
            setEndTimeCallback={setEndDate}
            parkingZoneUid={session.parkingZoneUid || ''}
            parkingZoneCountryCode={session?.countryCode || 'FI'}
            sessionId={session.sessionId || ''}
          />
          <ActionContainer>
            <Column>
              <RoundButton
                testID="ongoing-parking-expanded-extend-time-cancel"
                onPress={onClose}
                name="back"
                type="secondary"
                disabled={updateParkingLoading}
                buttonText={t('button.cancel')}
              />
            </Column>
            <Column>
              <PreloadingWrapper
                firstItem={
                  <RoundButton
                    testID="ongoing-parking-expanded-extend-time-confirm"
                    onPress={onConfirm}
                    name="check"
                    type="primary"
                    disabled={updateParkingLoading || !isEndDateValid}
                    buttonText={t('button.confirm')}
                  />
                }
                secondItem={
                  <RoundButton
                    onPress={() => undefined}
                    name="check"
                    type="primary"
                    disabled
                    buttonText={t('button.confirm')}
                  />
                }
              />
            </Column>
            <ColumnPlaceholder />
          </ActionContainer>
        </ShrinkContainer>
      </KeyboardAvoidingView>
    </SheetContainer>
  );
};

export default OngoingParkingExtendTime;
