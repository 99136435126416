import { useReactiveVar } from '@apollo/client';
import { theme } from 'aimo-ui';
import Sheet from 'components/sheets/Sheet';
import StreetParkingPurchaseNavigator from 'navigation/StreetParkingPurchaseNavigator';
import React, { useEffect } from 'react';
import {
  cityPmcsScreenName,
  isFullScreen,
  longTermParkingCompleted,
} from 'states/common';
import { isWeb } from 'utils/deviceUtils';

const getSnappingPoints = (fullScreen: boolean) => {
  if (isWeb) {
    return fullScreen ? ['75vh'] : ['60vh'];
  }
  return fullScreen ? ['95%'] : ['49%', '95%'];
};

const StreetParkingSheet = () => {
  const isFullScreenBottomSheet = useReactiveVar(isFullScreen);
  const isCompleted = useReactiveVar(longTermParkingCompleted);
  const snappingPoints = getSnappingPoints(isFullScreenBottomSheet);
  const cityPmcScreen = useReactiveVar(cityPmcsScreenName);

  useEffect(() => {
    return () => {
      isFullScreen(false);
    };
  }, []);

  const selectBgColour = () => {
    if (isCompleted) {
      return theme.colors.lightBg.green;
    }
    if (cityPmcScreen === 'APPLY_PERMIT' && isFullScreenBottomSheet) {
      return theme.colors.gray100;
    }
    return theme.colors.white;
  };

  return (
    <Sheet
      bgColor={selectBgColour()}
      showBackDropIndex={isFullScreenBottomSheet ? 0 : 1}
      snappingPoints={snappingPoints}>
      <StreetParkingPurchaseNavigator />
    </Sheet>
  );
};

export default StreetParkingSheet;
