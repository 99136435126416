import { Headline3 } from 'aimo-ui';
import TariffItem from 'components/park/tariff/TariffItem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledWrapper } from 'styles/ContainerStyles';
import { ParkingZoneType } from 'types/common';
import {
  AccessMethod,
  AccessType,
  CountryCode,
  Maybe,
  SegmentProps,
  TariffPlanProps,
} from 'types/generatedSchemaTypes';
import { testIdentifiers } from 'utils/testIdentifiers';

const ParkTariffSection = ({
  publishedParkTariffs,
  selectedTariffId,
  zoneCode,
  countryCode,
  onPress,
}: {
  publishedParkTariffs: Maybe<SegmentProps>[] | undefined;
  selectedTariffId: string | undefined;
  countryCode: CountryCode;
  zoneCode?: string;
  onPress: (
    tariffId: string,
    tariffName: string,
    type: ParkingZoneType,
    accessType?: AccessType,
    accessMethod?: AccessMethod,
    isUnlockValid?: boolean
  ) => void;
}) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper mb={30}>
      <Headline3 {...testIdentifiers('tariff-park-title')}>
        {t('parking.tariff.typeParking')}
      </Headline3>
      {publishedParkTariffs
        ?.filter((publishedParkTariff: Maybe<SegmentProps>) => {
          return publishedParkTariff?.accessType !== 'PRIVATE';
        })
        .map((publishedParkTariff: Maybe<SegmentProps>) => {
          const segmentItem = {
            id: publishedParkTariff?.uid,
            name: publishedParkTariff?.title,
            note: publishedParkTariff?.notes?.[0]?.text,
            accessMethod: publishedParkTariff?.accessMethod,
            accessType: publishedParkTariff?.accessType,
          } as TariffPlanProps;
          return (
            <TariffItem
              isSelected={selectedTariffId === publishedParkTariff?.uid}
              key={publishedParkTariff?.uid}
              tariffPlan={segmentItem}
              countryCode={countryCode}
              type="PARKING"
              onPress={onPress}
              zoneCode={zoneCode}
            />
          );
        })}
    </StyledWrapper>
  );
};

export default ParkTariffSection;
