import { useQuery, useReactiveVar } from '@apollo/client';
import InfoBox from '@components/common/InfoBox';
import { ItemWrapper } from '@styles/ContainerStyles';
import { Meta, theme } from 'aimo-ui';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  activeElementType,
  parkingPricingInfo,
  selectedChargingPricingInfo,
  selectedChargingPricingInfoSegment,
  selectedChargingPricingInfoSegmentCountryCode,
} from 'states/common';
import {
  Notes,
  Query,
  QueryGetPricingSchemeWithIdArgs,
} from 'types/generatedSchemaTypes';
import {
  countryCodeToLang,
  currencyFormatter,
  getCurrencyString,
  getLang,
} from 'utils/commonUtils';
import { getPriceInfoColor } from 'utils/priceUtils';
import { GET_PRICING_SCHEME_WITH_ID } from '@queries/getPricingSchemeWithId';

const RatesModalContent = () => {
  const { t } = useTranslation();
  const pricingTypes = parkingPricingInfo();
  const selectedChargingPricingInfoReactiveVar = useReactiveVar(
    selectedChargingPricingInfo
  );
  const selectedChargingPricingInfoSegmentReactiveVar = useReactiveVar(
    selectedChargingPricingInfoSegment
  );
  const countryCode =
    useReactiveVar(selectedChargingPricingInfoSegmentCountryCode) || 'FI';
  const selectedType = useReactiveVar(activeElementType);
  const { chargingPriceKwh } =
    selectedChargingPricingInfoSegmentReactiveVar || {};

  const hasPrice = useMemo(
    () => chargingPriceKwh !== undefined,
    [chargingPriceKwh]
  );

  const { data: chargingPricingScheme } = useQuery<
    Query,
    QueryGetPricingSchemeWithIdArgs
  >(GET_PRICING_SCHEME_WITH_ID, {
    variables: {
      pricingSchemeUid:
        selectedChargingPricingInfoReactiveVar?.pricingSchemeId || '',
    },
  });

  const chargingTariffPlan = useMemo(() => {
    return chargingPricingScheme?.getPricingSchemeWithId?.tariffPlans?.find(
      (tariffPlan) =>
        tariffPlan?.id &&
        tariffPlan?.id ===
          selectedChargingPricingInfoSegmentReactiveVar?.tariffPlanId
    );
  }, [
    chargingPricingScheme?.getPricingSchemeWithId?.tariffPlans,
    selectedChargingPricingInfoSegmentReactiveVar?.tariffPlanId,
  ]);

  const notes = useMemo(() => {
    return chargingTariffPlan?.notes?.map((note) => {
      if (note?.text) {
        const langText =
          note?.text[getLang()] || note?.text[countryCodeToLang(countryCode)];
        return { text: langText };
      }
    }) as Notes[];
  }, [chargingTariffPlan?.notes, countryCode]);

  const priceInfoBoxColor = useMemo(
    () =>
      getPriceInfoColor(
        false,
        hasPrice,
        pricingTypes.parkingType !== 'normal',
        selectedType,
        false
      ),
    [hasPrice, pricingTypes.parkingType, selectedType]
  );

  const serviceFee = useMemo(() => {
    return (
      chargingTariffPlan?.addOns?.filter(
        (addOn) => addOn?.type === 'SERVICE_FEE'
      )?.[0]?.amount || 0
    );
  }, [chargingTariffPlan?.addOns]);

  const serviceFeeInfo = useMemo(() => {
    return serviceFee !== 0 && typeof serviceFee === 'number'
      ? `+ ${currencyFormatter(serviceFee, countryCode)}`
      : '';
  }, [countryCode, serviceFee]);

  const pricingText = useMemo(() => {
    if (pricingTypes.parkingType !== 'normal') {
      return t('parking.organisationalPrice');
    }
    return hasPrice ? t('parking.currentPrice') : t('price.noTariff');
  }, [hasPrice, pricingTypes.parkingType, t]);

  const pricingInfo = useMemo(() => {
    return chargingPriceKwh
      ? `${chargingPriceKwh} ${getCurrencyString(
          countryCode
        )} /kWh ${serviceFeeInfo}`
      : t('price.noPrice');
  }, [chargingPriceKwh, countryCode, serviceFeeInfo, t]);

  return (
    <>
      {pricingTypes?.poolingFull && pricingTypes.parkingType !== 'normal' && (
        <InfoBox
          testID="parking-price-pooling-full"
          bgColor={theme.colors.boost.purple}
          headline={t('parking.noSpotsAvailable')}
          text={t('parking.organisationalPrice')}
        />
      )}

      <InfoBox
        testID="charging-price-infobox"
        bgColor={priceInfoBoxColor}
        headline={pricingInfo}
        text={pricingText}
      />

      {notes &&
        notes.length > 0 &&
        notes.map((note, index) => {
          return (
            <ItemWrapper
              key={`price-note-${index}`}
              testID={`price-note-${index}`}
              noBorder>
              <Meta>{note?.text}</Meta>
            </ItemWrapper>
          );
        })}
    </>
  );
};

export default RatesModalContent;
