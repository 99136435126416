import { gql } from '@apollo/client';

export const READ_VISMA_SIGN_METHODS = gql`
  query ReadVismaSignMethods {
    readVismaSignMethods {
      identifier
      name
      image
    }
  }
`;
