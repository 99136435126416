/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_PRICING_INFO } from 'graphql/queries/getPricingInfo';
import { useEffect, useMemo, useState } from 'react';
import {
  parkingPricingInfo,
  selectedPricingSegment,
  selectedTariffId,
} from 'states/common';
import {
  CountryCode,
  Maybe,
  Query,
  QueryPricingInfoArgs,
  SegmentProps,
} from 'types/generatedSchemaTypes';
import { getLang } from 'utils/commonUtils';
import useParkingZoneWithEVCheck from './useParkingZoneWithEVCheck';

const useAllowedParkingTimes = (parkingZoneUid: string, cacheFirst = false) => {
  const lang = getLang();
  const pricingTypes = parkingPricingInfo();
  const selectedSegment = useReactiveVar(selectedPricingSegment);
  const [pricingSegments, setPricingSegments] = useState<
    Maybe<SegmentProps>[] | undefined
  >(undefined);
  const parkingZoneResult = useParkingZoneWithEVCheck(parkingZoneUid);
  const isUnmanagedParking =
    parkingZoneResult?.data?.parkZone?.parkingMethods?.unmanagedParking ||
    false;

  const {
    data: pricingInfoData,
    loading: pricingInfoLoading,
    error: pricingInfoError,
  } = useQuery<Query, QueryPricingInfoArgs>(GET_PRICING_INFO, {
    variables: { parkingZoneUid, lang },
    fetchPolicy: cacheFirst ? 'cache-first' : 'no-cache',
    skip: !parkingZoneUid || !parkingZoneResult || isUnmanagedParking,
  });

  const activeSegmentId = useReactiveVar(selectedTariffId);

  useEffect(() => {
    if (parkingZoneUid && pricingInfoData?.pricingInfo) {
      let pricingInfoDetails = pricingInfoData?.pricingInfo?.defaultSegment;

      const customerSegmentsInfo =
        pricingInfoData?.pricingInfo?.customerSegments || [];

      // IF Default segment doenst have any UID so we need to add it and set it to Standard
      if (pricingInfoDetails && !pricingInfoDetails.uid) {
        pricingInfoDetails = {
          ...pricingInfoDetails,
          uid: 'Standard',
        };
      }

      const allSegments = pricingInfoDetails
        ? [pricingInfoDetails].concat(customerSegmentsInfo as SegmentProps[])
        : customerSegmentsInfo;

      setPricingSegments(allSegments);
    }
  }, [parkingZoneUid, pricingInfoData]);

  useEffect(() => {
    if (activeSegmentId && pricingSegments) {
      const selectedItem = pricingSegments?.find(
        (item) => item?.uid === activeSegmentId
      );
      if (!selectedItem) {
        const defaultSegment = pricingSegments?.find(
          (item) => item?.uid === 'Standard'
        );
        selectedPricingSegment(defaultSegment);
        return;
      }
      selectedPricingSegment(selectedItem);
    } else {
      selectedPricingSegment(undefined);
    }
  }, [activeSegmentId, pricingSegments]);

  // TODO: when we have some organisational pricing, this could make sense
  // const {
  //   data: pricingInfoPoolingData,
  //   loading: pricingInfoPoolingLoading,
  //   error: pricingInfoPoolingError,
  // } = useQuery<Query, QueryPricingInfoArgs>(GET_PRICING_INFO, {
  //   variables: { parkingZoneUid, lang },
  //   fetchPolicy: cacheFirst ? 'cache-first' : 'cache-and-network',
  //   skip: !parkingZoneUid || pricingTypes?.parkingType === 'normal',
  // });

  const predefinedTicketsMemoized = useMemo(() => {
    return selectedSegment?.predefinedTickets || [];
  }, [selectedSegment?.predefinedTickets]);

  const segmentParkingAllowed =
    selectedSegment?.parkingAllowed ??
    pricingInfoData?.pricingInfo?.defaultSegment?.parkingAllowed;

  const isLegacy =
    pricingInfoData?.pricingInfo?.pricingEngine === 'LEGACY_TICKETAPI';

  return {
    parkingAllowedFrom: selectedSegment?.parkingAllowedFrom || '',
    parkingAllowedUntil: selectedSegment?.parkingAllowedUntil || '',
    countryCode: pricingInfoData?.pricingInfo?.countryCode as CountryCode,
    notes:
      pricingTypes?.parkingType !== 'normal'
        ? undefined
        : selectedSegment?.notes,
    pricingInfoLoading,
    pricingInfoError,
    predefinedTickets: predefinedTicketsMemoized,
    pricingSegments: pricingSegments || [],
    parkingAllowed: segmentParkingAllowed && !isLegacy,
    pricingSchemeId: pricingInfoData?.pricingInfo?.pricingSchemeId,
    showDefaultChips: selectedSegment?.showDefaultChips,
    predefinedTicketsOnly: selectedSegment?.predefinedTicketsOnly,
    isLegacy,
    pricingCountryCode: pricingInfoData?.pricingInfo?.countryCode,
  };
};

export default useAllowedParkingTimes;
