import { useLazyQuery, useQuery } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import {
  BaseText,
  Button,
  Headline1,
  Headline3,
  Meta,
  Spinner,
  theme,
} from 'aimo-ui';
import Icon from 'components/icons/Icon';

import BGView from 'components/common/BGView';
import { format, isBefore } from 'date-fns';
import { READ_PARKING_SESSION } from 'graphql/queries/readParkingSession';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { WebWrapper } from 'screens/account/SheetWrapper';
import { WebContentWrapper } from 'screens/permit/styles';
import styled from 'styled-components/native';
import { Divider } from 'styles/CommonLayoutStyles';
import {
  ColumnWithMargins,
  ScreenContainer,
  StyledWrapper,
} from 'styles/ContainerStyles';
import {
  BillingItem,
  Log,
  Maybe,
  ParkingSession,
  Query,
  QueryReadParkingSessionArgs,
  QueryReadReceiptByTransactionIdArgs,
  Receipt,
} from 'types/generatedSchemaTypes';
import { ServicesNavProps, ServicesStackParamList } from 'types/navigation';
import {
  currencyFormatter,
  getCorrectBillingItem,
  getLocale,
} from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { getTimeLeft } from 'utils/timeUtils';
import PrepaidInfo from './PrepaidInfo';
import PrepaidPaymentInfo from './PrepaidPaymentInfo';
import { READ_RECEIPT_BY_TRANSACTION_ID } from 'graphql/queries/readReceiptByTransactionId';
import ExportPdfButton from 'components/receipt/ExportPdfButton';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';
import { testIdentifiers } from 'utils/testIdentifiers';

type PrepaidContentProps = {
  readSession: Maybe<ParkingSession> | undefined;
  readReceipt: Maybe<Receipt> | undefined;
  isHistory: boolean;
  sessionId: string;
};

const TextRow = styled.View<{
  marginTop?: boolean;
}>`
  flex-direction: row;
  justify-content: space-between;
  margin: ${(p) => (p.marginTop ? '10' : '0')}px 0px;
  align-items: center;
`;

const PrepaidContent = ({
  readSession,
  readReceipt,
  isHistory,
  sessionId,
}: PrepaidContentProps) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<ServicesNavProps>();
  const { parkZone, changelog } = readSession || {};
  const countryCode =
    readSession?.countryCode || readReceipt?.countryCode || 'FI';
  const price =
    readSession?.price?.totalAmount ||
    (readReceipt?.totalAmountCents || 0) / 100;
  const correctBillingItem = getCorrectBillingItem(
    readReceipt?.items as BillingItem[] | undefined
  );
  const zoneName = readSession
    ? parkZone?.commercialZoneView?.name || parkZone?.name
    : correctBillingItem?.[0].zoneName;
  const zoneCode = readSession
    ? parkZone?.commercialZoneView?.zoneCode
    : correctBillingItem?.[0].zoneCode;
  const name =
    zoneName && zoneCode
      ? `${zoneCode} ${zoneName}`
      : t('parking.receipt.prepaidDefaultName');

  const [hasChangelog, setHasChangelog] = useState(false);
  const handleEditPermit = () => {
    navigate('prepaidEdit', { ticketId: sessionId });
  };
  const tabHeight = useBottomMenuHeight();

  const Content = () => (
    <>
      <StyledWrapper center mb={30} maxWidth={400}>
        <BaseText center {...testIdentifiers('prepaid-details-header')}>
          {name}
        </BaseText>
      </StyledWrapper>
      <StyledWrapper center mb={20}>
        <Headline1 center {...testIdentifiers('prepaid-details-price')}>
          {price &&
            countryCode &&
            currencyFormatter(price as number, countryCode)}
        </Headline1>
        <Meta
          center
          color={theme.colors.gray200}
          {...testIdentifiers('prepaid-details-type')}>
          {t('longTermParking.oneTimeFee')}
        </Meta>
      </StyledWrapper>

      <View>
        <StyledWrapper mb={hasChangelog ? 0 : 40}>
          <PrepaidInfo session={readSession} receipt={readReceipt} />
        </StyledWrapper>

        <StyledWrapper mb={10}>
          <PrepaidPaymentInfo
            sessionData={readSession}
            receiptData={readReceipt}
          />
        </StyledWrapper>
      </View>
      {!isHistory && readSession && (
        <StyledWrapper mb={10}>
          <Button
            {...testIdentifiers('edit-prepaid-button')}
            size="l"
            buttonType="tertiary"
            text={t('permit.editPermit')}
            onPress={handleEditPermit}
          />
        </StyledWrapper>
      )}

      <Changelog changeArray={changelog} isVisibleOutput={setHasChangelog} />
      {isHistory && (
        <StyledWrapper>
          <ExportPdfButton isPaymentFailed={false} sessionId={sessionId} />
        </StyledWrapper>
      )}
    </>
  );

  if (isWeb) {
    const WebContent = () => (
      <ScrollView>
        <StyledWrapper center>
          <WebContentWrapper>
            <Content />
          </WebContentWrapper>
        </StyledWrapper>
      </ScrollView>
    );
    return <WebWrapper page={WebContent} mt={0} />;
  }

  return (
    <ScreenContainer mb={1} mt={40} background={theme.colors.transparent}>
      <BGView noMargins paddings={{ bottom: tabHeight > 0 ? 80 : 0, top: 40 }}>
        <ScrollView>
          <ColumnWithMargins ml={20} mr={20}>
            <Content />
          </ColumnWithMargins>
        </ScrollView>
      </BGView>
    </ScreenContainer>
  );
};

const Changelog: React.FC<{
  changeArray?: Maybe<Maybe<Log>[]>;
  isVisibleOutput: (isVisible: boolean) => void;
}> = ({ changeArray, isVisibleOutput }) => {
  const { t } = useTranslation();
  const dateFormat = (date: Date) =>
    format(new Date(date), 'dd.MM.yyyy HH:mm', {
      locale: getLocale(),
    });

  const filteredChangelog = changeArray
    ?.filter((change) => change?.from?.accessDeviceValue)
    .reverse();

  useEffect(() => {
    isVisibleOutput(!!filteredChangelog?.length);
  }, [filteredChangelog, isVisibleOutput]);

  if (!filteredChangelog?.length) {
    return <></>;
  }
  return (
    <>
      <ColumnWithMargins ml={-20} mr={-20} mb={10}>
        <Divider size={10} />
      </ColumnWithMargins>
      <StyledWrapper>
        <StyledWrapper mb={10}>
          <Headline3>{t('parking.prepaidTickets.changeHistory')}</Headline3>
        </StyledWrapper>
        {filteredChangelog.map((change, i, arr) => (
          <View key={`prepaid-change-item-${i}`}>
            <TextRow marginTop>
              <Meta color={theme.colors.gray200}>
                {t('parking.prepaidTickets.dateUpdated')}
              </Meta>
              <Meta testID={`prepaid-changelog-updated-${i}`}>
                {change?.created && dateFormat(change.created)}
              </Meta>
            </TextRow>
            <TextRow marginTop>
              <Meta color={theme.colors.gray200}>
                {t('parking.prepaidTickets.vehicleChanged')}
              </Meta>
              <TextRow>
                <Meta testID={`prepaid-changelog-from-${i}`}>
                  {change?.from?.accessDeviceValue}
                </Meta>
                <Icon name="Arrow_Right" />
                <Meta testID={`prepaid-changelog-to-${i}`}>
                  {change?.to?.accessDeviceValue}
                </Meta>
              </TextRow>
            </TextRow>
            {i < arr.length - 1 && <Divider marginVertical={5} />}
          </View>
        ))}
      </StyledWrapper>
    </>
  );
};

const PrepaidTicket: React.FC<{ isHistory?: boolean }> = ({
  isHistory = false,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<{
    name: string;
    sessionId: string;
    predefinedEndDate: Date;
    startDate: Date;
  }>();
  const { params } =
    useRoute<RouteProp<ServicesStackParamList, 'prepaidTicket'>>();
  const sessionid = params.ticketId || '';
  const transactionId = params.transactionId || '';

  const [
    getReceiptByTransactionId,
    {
      data: prepaidReceiptData,
      loading: prepaidReceiptLoading,
      error: prepaidReceiptError,
    },
  ] = useLazyQuery<Query, QueryReadReceiptByTransactionIdArgs>(
    READ_RECEIPT_BY_TRANSACTION_ID,
    {
      variables: {
        transactionId,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (receiptData) => {
        const correctItems = getCorrectBillingItem(
          receiptData?.readReceiptByTransactionId?.items as
            | [BillingItem]
            | undefined
        );
        const { endTime, startTime, zoneCode, zoneName, parkingSessionId } =
          correctItems?.[0] || {};

        setData({
          name: `${
            zoneCode && zoneName
              ? `${zoneCode} ${zoneName}`
              : t('parking.receipt.prepaidDefaultName')
          }`,
          predefinedEndDate: endTime as Date,
          startDate: startTime as Date,
          sessionId: parkingSessionId as string,
        });
      },
    }
  );

  const {
    data: prepaidSessionData,
    loading: prepaidSessionLoading,
    error: prepaidSessionError,
  } = useQuery<Query, QueryReadParkingSessionArgs>(READ_PARKING_SESSION, {
    variables: {
      sessionId: sessionid,
    },
    skip: !sessionid,
    fetchPolicy: 'cache-and-network',
    onCompleted: (sessionData) => {
      const { endDate, predefinedEndDate, sessionId, startDate, parkZone } =
        sessionData?.readParkingSession || {};

      const zoneCode = parkZone?.commercialZoneView?.zoneCode;
      const zoneName = parkZone?.commercialZoneView?.name || parkZone?.name;

      setData({
        name: `${
          zoneCode && zoneName
            ? `${zoneCode} ${zoneName}`
            : t('parking.receipt.prepaidDefaultName')
        }`,
        predefinedEndDate: new Date(endDate || (predefinedEndDate as string)),
        startDate: startDate as Date,
        sessionId: sessionId as string,
      });
    },
    onError: (err) => {
      if (err?.graphQLErrors[0]?.message === '404: Not Found') {
        getReceiptByTransactionId();
      }
    },
  });

  const isLoading = prepaidReceiptLoading || prepaidSessionLoading;
  const isError = prepaidSessionError && prepaidReceiptError;

  if (isError) {
    return <></>;
  }

  if (isLoading) {
    return (
      <StyledWrapper center>
        <Spinner />
      </StyledWrapper>
    );
  }

  const prepaidSubText = (startingDate: Date, endingDate: Date) => {
    const isExpired = isBefore(endingDate, new Date());
    if (isExpired) {
      return '';
    }
    const startDateInFuture = isBefore(new Date(), startingDate);
    if (startDateInFuture) {
      const formatedDate = format(startingDate, 'd.M HH:mm');
      return `${t('parking.prepaidTickets.validFrom', { date: formatedDate })}`;
    }
    const { timeLeft } = getTimeLeft(endingDate, new Date(), true);
    return `${t('parking.prepaidTickets.validFor')} ${timeLeft}`;
  };

  return (
    <>
      {!isLoading && (
        <>
          <StyledWrapper
            center
            mb={5}
            maxWidth={350}
            {...testIdentifiers('prepaid-details-headline')}>
            <Headline1
              center
              {...testIdentifiers('prepaid-details-headline-text')}>
              {data?.name}
            </Headline1>
          </StyledWrapper>
          <StyledWrapper center>
            <Meta
              color={theme.colors.gray200}
              testID="permit-details-headline-type">
              {data?.startDate && data?.predefinedEndDate
                ? prepaidSubText(
                    new Date(data.startDate),
                    new Date(data.predefinedEndDate)
                  )
                : ''}
            </Meta>
          </StyledWrapper>
          <PrepaidContent
            sessionId={data?.sessionId || ''}
            readSession={prepaidSessionData?.readParkingSession}
            readReceipt={prepaidReceiptData?.readReceiptByTransactionId}
            isHistory={isHistory}
          />
        </>
      )}
    </>
  );
};

export default PrepaidTicket;
