import { useReactiveVar } from '@apollo/client';
import { BaseText, BottomButtonsLayout, Button, theme } from 'aimo-ui';
import { useTranslation } from 'react-i18next';
import { futureFeatureCountryCode } from 'states/persistInStorage';
import {
  ColumnWithMargins,
  SheetDetailsWrapper,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { useState } from 'react';
import ApproveTermsAgain from '@components/approveTerms/ApproveTermsAgain';
import useCreateAcceptanceMutation from 'hooks/useCreateAcceptanceMutation';
import { CountryCode } from 'types/generatedSchemaTypes';
import { StackActions, useNavigation } from '@react-navigation/core';
import { acceptedTermsAfterFeatureCountryChange } from 'states/common';
import { isWeb } from 'utils/deviceUtils';
import { changeFutureAppCountryCode } from 'utils/countryUtils';

const BottomButtons = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const { createAcceptance, loading } = useCreateAcceptanceMutation();
  const { dispatch, goBack } = useNavigation();
  const countryCode = useReactiveVar(futureFeatureCountryCode);

  const handleAgree = async () => {
    await createAcceptance(true, 'TERMS_ACCEPTED', countryCode as CountryCode);
    acceptedTermsAfterFeatureCountryChange(true);
    dispatch(StackActions.pop(1));
  };

  const handleCancel = () => {
    changeFutureAppCountryCode(null);
    goBack();
  };

  return (
    <StyledWrapper center={isWeb} mb={0} maxWidth={isWeb ? 450 : undefined}>
      <StyledWrapper>
        <Button
          text={t('button.agree')}
          buttonType="secondary"
          size="l"
          testID="app-location-agree-button"
          onPress={handleAgree}
          disabled={disabled || loading}
          loading={loading}
        />
      </StyledWrapper>
      <Button
        text={t('button.cancel')}
        buttonType="tertiary"
        size="l"
        testID="app-location-cancel-button"
        onPress={handleCancel}
        disabled={loading}
      />
    </StyledWrapper>
  );
};

const BottomButtonsLayoutWithToggle = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [approvedToggle, setApprovedToggle] = useState(false);
  return (
    <BottomButtonsLayout
      contentBottom={<BottomButtons disabled={!approvedToggle} />}>
      <StyledWrapper center maxWidth={isWeb ? 450 : undefined}>
        {children}
        <ColumnWithMargins mt={20}>
          <ApproveTermsAgain
            type="Location"
            approvedToggle={approvedToggle}
            setApprovedToggle={setApprovedToggle}
          />
        </ColumnWithMargins>
      </StyledWrapper>
    </BottomButtonsLayout>
  );
};

/**
 * Screen for approving terms after changing app location
 */
const ApproveTermsAppLocation: React.FC = () => {
  const { t } = useTranslation();
  const countryCode = useReactiveVar(futureFeatureCountryCode);

  return (
    <SheetDetailsWrapper pt={isWeb ? 60 : 0}>
      <BottomButtonsLayoutWithToggle>
        <StyledWrapper center>
          <BaseText color={theme.colors.gray200} center>
            {t('account.terms.privacyPolicyAndTermsConditionsLocationChange', {
              country: t(`account.appLocations.${countryCode}`),
            })}
          </BaseText>
        </StyledWrapper>
      </BottomButtonsLayoutWithToggle>
    </SheetDetailsWrapper>
  );
};

export default ApproveTermsAppLocation;
