import { BaseText, theme } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import QuickActionDirection from 'components/common/QuickActionDirection';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { format } from 'date-fns';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BillingItem,
  Maybe,
  ParkingSession,
  Receipt,
} from 'types/generatedSchemaTypes';
import { getCorrectBillingItem } from 'utils/commonUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { makePoint } from 'utils/mapUtils';
import { getLocationIconType, locationIcon } from 'utils/parkUtils';

const PrepaidInfo = ({
  session,
  receipt,
}: {
  session?: Maybe<ParkingSession>;
  receipt?: Maybe<Receipt>;
}) => {
  const { t } = useTranslation();
  let licensePlate, cardId, endDate, startDate;
  if (session) {
    licensePlate = session.accessDevice?.value;
    cardId = session.cardId;
    endDate = session.endDate || session.predefinedEndDate;
    startDate = session.startDate;
  } else {
    const correctItem = getCorrectBillingItem(
      receipt?.items as [BillingItem] | undefined
    )?.[0];
    licensePlate = correctItem?.licensePlate;
    cardId = receipt?.creditCard?.cardId;
    endDate = correctItem?.endTime;
    startDate = correctItem?.startTime;
  }
  const parkZone = session?.parkZone;
  const { address, name, commercialZoneView } = parkZone || {};
  const parkZoneName = name || commercialZoneView?.name;
  const markerCoordinate =
    address?.marker && makePoint(address.marker?.coordinates);
  const {
    flags: { anpr },
  } = useContext(FeatureFlagsContext);
  const showCameraRecognition = showFeatureFlag(anpr);
  const paymentCard = useSelectedPaymentMethod(cardId || undefined);
  const markerType = getLocationIconType(
    parkZone?.locationType || 'OTHER',
    showCameraRecognition && parkZone?.parkingMethods?.anpr
  );

  const endDateFormatted = format(
    new Date(endDate || new Date()),
    'd.M.y HH:mm'
  );
  const validFrom = format(new Date(startDate || new Date()), 'd.M.y HH:mm');

  const permitInfoList = [
    session && {
      icon: locationIcon[markerType],
      label: parkZone?.commercialZoneView?.name || parkZone?.name || '',
      value: (
        <QuickActionDirection
          name={parkZoneName}
          showIconLabel={false}
          markerCoordinate={markerCoordinate}
          alignEnd
        />
      ),
    },
    {
      icon: 'Car-Outlined',
      label: t('services.permitVehicle'),
      value: licensePlate,
    },
    {
      icon: 'Calendar-Outlined',
      label: t('permit.starts'),
      value: validFrom,
    },
    {
      icon: 'Calendar-Outlined',
      label: t('permit.expires'),
      value: endDateFormatted,
    },
    {
      icon: 'Credit_Card-Outlined',
      label: t('payment.creditCard'),
      value: `**** ${paymentCard?.lastNums}`,
    },
  ];

  if (parkZone?.info?.doorCode) {
    permitInfoList.unshift({
      icon: 'Unlocked-Outlined',
      label: t('longTermParking.garageEntryCode'),
      value: parkZone?.info?.doorCode,
    });
  }

  return (
    <>
      {permitInfoList.map((item, index) =>
        item?.value ? (
          <MenuItem
            testID={`permit-info-${index}`}
            iconName={item.icon}
            menuLabel={item.label}
            rightComponent={
              <BaseText color={theme.colors.gray200}>{item.value}</BaseText>
            }
            noBorder={index === permitInfoList.length - 1}
            key={`permit-info-${index}`}
          />
        ) : undefined
      )}
    </>
  );
};

export default PrepaidInfo;
