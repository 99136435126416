import InAppReview from 'react-native-in-app-review';
import { getModel, getBrand } from 'react-native-device-info';
import { showToast } from 'components/common/CustomToast';
import Logger from 'utils/Logger';

export const rateApp = async () => {
  const isAvailable = InAppReview.isAvailable();
  if (isAvailable) {
    try {
      await InAppReview.RequestInAppReview();
    } catch (error) {
      Logger.error(`Rate app | Error: ${error}`);
      showToast('error.rateApp.generic', 'error');
    }
  } else {
    Logger.warn(`Rate app | Device not supported: ${getBrand()} ${getModel()}`);
    showToast('error.rateApp.notAvailable', 'error');
  }
};
