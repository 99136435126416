import { useLazyQuery } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Button, Headline2, IconButton, theme, Title } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import Icon from 'components/icons/Icon';
import AccountContext from 'context/AccountContext';
import HslContext from 'context/HslContext';
import ServiceContext from 'context/ServiceContext';
import { AUTHENTICATE_DEVICE_TIME } from 'graphql/queries/authenticateDeviceTime';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import { FooterWrapper, MainWrapper } from 'styles/ModalStackContainerStyles';
import {
  Query,
  QueryAuthenticateDeviceTimeArgs,
} from 'types/generatedSchemaTypes';
import { HslParkingStackParamList } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { Row } from '../styles/styles';
import HslTicketList from './HslTicketList';
import HslWizardSheet from './HslWizardSheet';
import { testIdentifiers } from 'utils/testIdentifiers';

type HslTicketInfoViewProps = {
  mockDateTime?: Date;
};

const Container = styled.View`
  flex: 1;
  background-color: ${(p) => p.theme.colors.gray100};
`;

const HslTicketInfoView: React.FC<HslTicketInfoViewProps> = ({
  mockDateTime,
}) => {
  const { t } = useTranslation();
  const { setCurrentScreen, currentScreen, hasHslAuthentication, setHslToken } =
    useContext(HslContext);
  const { myHslTickets } = useContext(ServiceContext);
  const { customer } = useContext(AccountContext);
  const { mobilePhone } = customer;
  const { isLoggedIn } = useIsLoggedIn();
  const { navigateToAccountLogin } = useAfterAuthenticationRedirect();
  const { goBack } =
    useNavigation<StackNavigationProp<HslParkingStackParamList>>();
  const { params } = useRoute<RouteProp<HslParkingStackParamList, 'info'>>();

  const [authenticateDeviceTime, { loading }] = useLazyQuery<
    Query,
    QueryAuthenticateDeviceTimeArgs
  >(AUTHENTICATE_DEVICE_TIME);

  const onBuyClick = async () => {
    const { data, error } = await authenticateDeviceTime({
      variables: {
        input: {
          deviceTime: mockDateTime?.toISOString() || new Date().toISOString(),
        },
      },
    });

    if (error || !data?.authenticateDeviceTime?.valid) {
      console.log(error);
      console.log(data?.authenticateDeviceTime);
      return setCurrentScreen('INVALID_TIME');
    }
    console.log('pass device validation');
    if (!isLoggedIn) {
      return navigateToAccountLogin();
    }
    if (mobilePhone === '' || !mobilePhone) {
      return setCurrentScreen('PHONE');
    }
    return setCurrentScreen('SELECT_TICKET');
  };

  //After returning from HSL authentication redirect back to authentication screen
  useEffect(() => {
    if (params?.token && !hasHslAuthentication()) {
      setHslToken(params.token);
      setCurrentScreen('AUTHENTICATE');
    }
  }, [params, hasHslAuthentication, setCurrentScreen, setHslToken]);

  return (
    <>
      <Container testID="hsl-ticket-info">
        {!isWeb && (
          <ColumnWithMargins mt={45} ml={20}>
            <Row>
              <IconButton
                rounded={true}
                fullWidth={false}
                buttonType="secondary"
                {...testIdentifiers('hsl-info-backButton')}
                onPress={() => goBack()}
                iconComponent={() => (
                  <Icon name="back" color={theme.colors.white} />
                )}
              />
              <ColumnWithMargins ml={10}>
                <Headline2>{t('hslTicket.title')}</Headline2>
              </ColumnWithMargins>
            </Row>
          </ColumnWithMargins>
        )}

        <MainWrapper vAlignCenter={true}>
          {myHslTickets?.length ? (
            <HslTicketList tickets={myHslTickets} />
          ) : (
            <StyledWrapper maxWidth={350} center mb={20}>
              <Title center testID="hsl-no-tickets">
                {t('hslTicket.noTickets')}
              </Title>
            </StyledWrapper>
          )}
        </MainWrapper>
        <FooterWrapper>
          <StyledWrapper maxWidth={350} center mb={20}>
            <MenuItem
              testID="hsl-privacy-policy"
              menuLabel={t('hslTicket.privacyPolicy')}
              onPress={() => null}
              color={theme.colors.black}
              borderColor={theme.colors.gray100}
              noBorder
              withArrow
              arrowSize="s"
            />
          </StyledWrapper>
          <StyledWrapper maxWidth={350} center mb={20}>
            <MenuItem
              testID="hsl-customer-support"
              menuLabel={t('hslTicket.customerSupport')}
              onPress={() => null}
              color={theme.colors.black}
              borderColor={theme.colors.gray100}
              noBorder
              withArrow
              arrowSize="s"
            />
          </StyledWrapper>
          <StyledWrapper maxWidth={350} center mb={20}>
            <Button
              testID="hsl-buy-ticket"
              text={t('hslTicket.buy')}
              size="l"
              loading={loading}
              onPress={onBuyClick}
            />
          </StyledWrapper>
        </FooterWrapper>
      </Container>
      {currentScreen !== '' && <HslWizardSheet />}
    </>
  );
};

export default HslTicketInfoView;
