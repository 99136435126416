import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { BaseText, Headline3, Meta, theme } from 'aimo-ui';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import usePermitParkZone from 'hooks/usePermitParkZone';
import React, { useContext } from 'react';
import { Pressable } from 'react-native';
import { isFullScreen } from 'states/common';
import { mapCoordinates, userLocation } from 'states/map';
import styled from 'styled-components/native';
import { Pill } from 'styles/CommonLayoutStyles';
import { ColumnWithMargins, SimpleFullContainer } from 'styles/ContainerStyles';
import { LatLng } from 'types/MapTypes';
import { SetParamsProps } from 'types/navigation';
import { getDistanceText } from 'utils/commonUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import {
  getDistanceFromLatLonInMeters,
  handleParkZoneSelected,
  makePoint,
} from 'utils/mapUtils';
import { getLocationIconType, locationIcon } from 'utils/parkUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import Icon from '../icons/Icon';
import AddressAndLocation from './AddressAndLocation';
import { ParkZoneWithVisibility } from './ParkingZoneSearch';
import PoolingBadge from './PoolingBadge';
import { IconWrapper, Row } from './styles/styles';

const ItemRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ItemColumn = styled.View<{ shrink?: boolean; ml?: number }>`
  flex-direction: column;
  flex-shrink: 2;
  gap: 5px;
`;

const AddressWrapper = styled.View`
  flex: 0 1 100%;
`;

const HeadlineShrink = styled(Headline3)`
  flex-shrink: 1;
`;

const SearchItem: React.FC<{
  item: ParkZoneWithVisibility;
  currentLocation: LatLng | null;
  withLTP?: boolean;
}> = ({ item, currentLocation, withLTP = false }) => {
  const {
    uid: parkZoneUid,
    address,
    commercialZoneView,
    name,
    parkingMethods,
    countryCode,
    memberGroupName,
  } = item || {};

  const coordinates = address?.marker?.coordinates;
  const locationType = item?.locationType || 'OTHER';
  const permitForParkZone = usePermitParkZone(parkZoneUid);
  const usersLocation = useReactiveVar(userLocation);
  const { setParams }: SetParamsProps = useNavigation();
  const locationText =
    currentLocation &&
    coordinates &&
    getDistanceText(
      getDistanceFromLatLonInMeters(currentLocation, makePoint(coordinates)) /
        1000
    );

  const {
    flags: { anpr },
  } = useContext(FeatureFlagsContext);
  const showCameraRecognition = showFeatureFlag(anpr);

  const locationIconName = getLocationIconType(
    locationType || 'OTHER',
    showCameraRecognition && parkingMethods?.anpr,
    parkingMethods?.unmanagedParking
  );

  const onPressMarker = () => {
    const isStreet = locationType === 'STREET' && countryCode !== 'SE';
    const mappedCoordinates = usersLocation
      ? mapCoordinates(usersLocation as LatLng)
      : undefined;
    if (parkZoneUid && coordinates) {
      handleParkZoneSelected(
        parkZoneUid,
        !isStreet ? makePoint(coordinates) : mappedCoordinates,
        setParams,
        withLTP,
        permitForParkZone?.id
      );
    }
    if (isStreet) {
      isFullScreen(true);
    }
  };

  const isParkAndCharge =
    !parkingMethods?.unmanagedParking && parkingMethods?.evCharging;

  const rowMargin = 0;

  return (
    <Pressable
      onPress={onPressMarker}
      style={({ pressed }: any) => [{ opacity: pressed ? 0.5 : 1 }]}
      {...testIdentifiers(`search-item-${parkZoneUid}`)}>
      <ItemRow {...testIdentifiers(`search-item-${parkZoneUid}`)}>
        <ItemColumn>
          <Row mt={rowMargin} mb={rowMargin}>
            {commercialZoneView?.zoneCode !== '' && (
              <ColumnWithMargins mr={5}>
                <BaseText
                  color={theme.colors.link}
                  {...testIdentifiers('search-item-zone-code')}>
                  {commercialZoneView?.zoneCode}
                </BaseText>
              </ColumnWithMargins>
            )}
            <HeadlineShrink
              numberOfLines={1}
              {...testIdentifiers('search-item-name')}>
              {commercialZoneView?.name || name}
            </HeadlineShrink>
          </Row>
          <Row mt={rowMargin} mb={rowMargin}>
            <IconWrapper mr={3}>
              <Icon
                name={locationIcon[locationIconName]}
                size="s"
                testID={`location-icon-${locationIconName}`}
              />
            </IconWrapper>
            {isParkAndCharge && (
              <IconWrapper mr={3}>
                <Icon
                  name={locationIcon.evCharging}
                  size="s"
                  testID={'location-icon-parkAndCharge'}
                />
              </IconWrapper>
            )}
            <AddressWrapper>
              <AddressAndLocation
                color={theme.colors.gray200}
                numberOfLines={2}
                name={commercialZoneView?.name || name || ''}
                address={address}
                testID="search-street-location"
                locationText={locationText}
                includeCity={false}
              />
            </AddressWrapper>
          </Row>
          {!withLTP && (
            <Row mt={0} mb={0}>
              <PoolingBadge parkingZoneUid={parkZoneUid} />
            </Row>
          )}
        </ItemColumn>
        <ColumnWithMargins
          ml={10}
          {...testIdentifiers(`park-button-${parkZoneUid}`)}>
          <Icon size={'m'} name="Chevron_Right" />
        </ColumnWithMargins>
      </ItemRow>
      {memberGroupName && (
        <ItemRow>
          <SimpleFullContainer>
            <Pill mt={5}>
              <Meta>{memberGroupName}</Meta>
            </Pill>
          </SimpleFullContainer>
        </ItemRow>
      )}
    </Pressable>
  );
};

export default SearchItem;
