import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Button, Headline3 } from 'aimo-ui';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import { TitleCard } from 'components/longTermParking';
import PaymentCardDropDown from 'components/park/detail/PaymentCardDropDown';
import VehicleSelectDropDown from 'components/park/detail/VehicleSelectDropDown';
import SheetLayout, {
  FooterButtonContainer,
} from 'components/sheets/SheetLayout';

import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  accountRestricted,
  confirmCreatePermitData,
  isFullScreen,
  selectedCityPmc,
  sheetModal,
} from 'states/common';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { Pmc } from 'types/generatedSchemaTypes';
import { StreetParkingStackParamList } from 'types/navigation';
import { currencyFormatter } from 'utils/commonUtils';
import { lastDayOfMonth } from 'utils/timeUtils';

const StreetParkingPurchaseScreen: React.FC = () => {
  const cityPmc = useReactiveVar(selectedCityPmc);
  const { t } = useTranslation();
  const { licensePlate } = useSelectedParkingVehicle() || {};
  const { cardId: creditCardId } = useSelectedPaymentMethod() || {};
  const [startDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [validUntil] = useState(new Date(new Date().setHours(0, 0, 0, 0)));

  const price = (cityPmc?.prices?.[0].monthlyPrice || 0) / 100;
  const endOfMonth = lastDayOfMonth(validUntil);
  const { goBack, canGoBack, navigate } =
    useNavigation<StackNavigationProp<StreetParkingStackParamList>>();
  const isUserRestricted = useReactiveVar(accountRestricted);

  useEffect(() => {
    isFullScreen(true);
    return () => {
      isFullScreen(false);
    };
  }, []);

  const confirmCreatePermit = () => {
    if (isUserRestricted) {
      sheetModal('accountRestricted');
    } else {
      confirmCreatePermitData({
        pmcId: cityPmc?.pmcId as string,
        startDate,
        selectedPmc: cityPmc as Pmc,
      });
      sheetModal('confirmPermit');
    }
  };

  const handleNavigateBack = () => {
    if (canGoBack()) {
      goBack();
    } else {
      navigate('cityPmcs');
    }
    return true;
  };

  return (
    <SheetLayout
      resetPadding
      testIDName="street-parking-purchase-details"
      withBackButton
      onBackPress={handleNavigateBack}
      headlineComponent={
        <Headline3 center>{t('cityPermits.purchase')}</Headline3>
      }
      onHeadlineButtonPress={() => selectedCityPmc(null)}>
      <BottomSheetScrollViewCustom bottomThreshold={0}>
        <TitleCard
          parkingZoneName={cityPmc?.name as string}
          price={currencyFormatter(price, 'FI')}
          permitName={t('cityPermits.renewInfo')}
          isCityPmc={true}
          validUntil={endOfMonth}
          testID="street-parking-title-card"
        />
        <FooterButtonContainer>
          <VehicleSelectDropDown />
          <PaymentCardDropDown />
        </FooterButtonContainer>
      </BottomSheetScrollViewCustom>
      <ColumnWithMargins mb={20}>
        <Button
          disabled={!licensePlate?.plateText || !creditCardId}
          size="l"
          onPress={confirmCreatePermit}
          text={t('button.buyPermit')}
          testID="street-parking-buy-permit-button"
        />
      </ColumnWithMargins>
    </SheetLayout>
  );
};

export default StreetParkingPurchaseScreen;
