import { gql } from '@apollo/client';

export const READ_VISMA_SIGN_DOCUMENT = gql`
  query ReadVismaSignDocument($input: VismaSignDocumentInput) {
    readVismaSignDocument(input: $input) {
      fulfillUrl
      documentId
    }
  }
`;
