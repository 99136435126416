import { useMutation, useReactiveVar } from '@apollo/client';
import { UPDATE_VEHICLE } from '@mutations/updateVehicle';
import { GET_CUSTOMER } from '@queries/getCustomer';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { showToast } from 'components/common/CustomToast';
import AccountContext from 'context/AccountContext';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  editVehicleCameraRecognitionEnabled,
  editVehicleData,
  editVehicleIsDeleted,
  editVehicleIsDiscarded,
  sheetModal,
  vehicleSaved,
} from 'states/common';
import {
  Mutation,
  MutationUpdateVehicleArgs,
  Vehicle,
} from 'types/generatedSchemaTypes';
import { AccountStackParamList, RootNavProps } from 'types/navigation';
import Logger from 'utils/Logger';
import VehicleForm from 'components/account/vehicle/VehicleForm';
import useAfterVehicleRedirect from 'hooks/useAfterVehicleRedirect';

const EditVehicleScreen: React.FC = () => {
  const { navigate, addListener } = useNavigation<RootNavProps>();
  const { params } =
    useRoute<RouteProp<AccountStackParamList, 'editVehicle'>>();
  const isDiscarded = useReactiveVar(editVehicleIsDiscarded);
  const isDeleted = useReactiveVar(editVehicleIsDeleted);
  const originalLicensePlate = params?.licensePlate;
  const editVehicle = useReactiveVar(editVehicleData);
  const openModalName = useReactiveVar(sheetModal);
  const isVehicleSaved = useReactiveVar(vehicleSaved);
  const { navigateAfterVehicleSaved } = useAfterVehicleRedirect();
  const {
    customer: { vehicles },
  } = useContext(AccountContext);

  const [updatedLicensePlate, setUpdatedLicensePlate] = useState<string>();

  const originalSelectedVehicle = useMemo(() => {
    return vehicles?.find((currentVehicle: Vehicle) => {
      return (
        currentVehicle.licensePlate?.plateText ===
        (updatedLicensePlate ?? originalLicensePlate)
      );
    });
  }, [originalLicensePlate, vehicles, updatedLicensePlate]);

  const [updatedVehicle, setUpdatedVehicle] = useState<Vehicle>({} as Vehicle);

  // hasUnsavedChanges is thread as a special state combined with a ref
  // because it needs to be used inside the event listener
  const [hasUnsavedChanges, _setHasUnsavedChanges] = useState(false);
  const hasUnsavedChangesRef = useRef(hasUnsavedChanges);
  const setHasUnsavedChanges = (value: boolean) => {
    hasUnsavedChangesRef.current = value;
    _setHasUnsavedChanges(value);
  };

  const handleNavigation = (e: any) => {
    if (hasUnsavedChangesRef.current) {
      e?.preventDefault();
      sheetModal('unsavedEditVehicle');
    }
  };

  const handleRemoveConfirmation = () => {
    sheetModal('deleteVehicle');
  };

  const hasFormChanges = useCallback((): boolean => {
    const hasLicensePlateChanged =
      !!updatedVehicle.licensePlate?.plateText &&
      updatedVehicle.licensePlate.plateText !==
        originalSelectedVehicle?.licensePlate?.plateText;
    const hasNameChanged =
      !!updatedVehicle.name &&
      updatedVehicle.name !== originalSelectedVehicle?.name;
    const hasColorChanged =
      !!updatedVehicle.appSettings?.color &&
      updatedVehicle.appSettings.color !==
        originalSelectedVehicle?.appSettings?.color;
    const hasIconChanged =
      !!updatedVehicle.appSettings?.icon &&
      updatedVehicle.appSettings.icon !==
        originalSelectedVehicle?.appSettings?.icon;
    const hasCameraRecognitionChanged =
      originalSelectedVehicle?.cameraRecognition?.enabled !== undefined &&
      originalSelectedVehicle?.cameraRecognition?.enabled !== null &&
      updatedVehicle.cameraRecognition?.enabled !==
        originalSelectedVehicle?.cameraRecognition?.enabled;
    const hasTypeChanged =
      !!updatedVehicle.appSettings?.type &&
      updatedVehicle.appSettings.type !==
        originalSelectedVehicle?.appSettings?.type;
    const hasEVPlugTypeChanged =
      !!updatedVehicle.appSettings?.evPlugType &&
      updatedVehicle.appSettings.evPlugType !==
        originalSelectedVehicle?.appSettings?.evPlugType;
    const hasCountryChanged =
      !!updatedVehicle.licensePlate?.countryCode &&
      updatedVehicle.licensePlate.countryCode !==
        originalSelectedVehicle?.licensePlate.countryCode;
    return (
      hasLicensePlateChanged ||
      hasNameChanged ||
      hasColorChanged ||
      hasIconChanged ||
      hasCameraRecognitionChanged ||
      hasTypeChanged ||
      hasEVPlugTypeChanged ||
      hasCountryChanged
    );
  }, [originalSelectedVehicle, updatedVehicle]);

  useEffect(() => {
    // Redirect to my vehicles screen if vehicle is not found
    if (!originalSelectedVehicle) {
      if (!isDeleted) {
        showToast('error.noVehicleSelected', 'error');
      }
      navigateAfterVehicleSaved();
    }
  }, [
    originalSelectedVehicle,
    openModalName,
    originalLicensePlate,
    navigateAfterVehicleSaved,
    isDeleted,
  ]);

  useEffect(() => {
    // Set initial value of vehicle camera recognition
    editVehicleCameraRecognitionEnabled(
      originalSelectedVehicle?.cameraRecognition?.enabled ?? false
    );
  }, [originalSelectedVehicle]);

  // Update form status when a field changes.
  // Used to enable/disable update button.
  useEffect(() => {
    setHasUnsavedChanges(hasFormChanges());
  }, [hasFormChanges]);

  useEffect(() => {
    if (updatedVehicle !== editVehicle) {
      editVehicleData(updatedVehicle);
    }
  }, [updatedVehicle, editVehicle]);

  // Redirect to my vehicles screen if the modal is discarded
  useEffect(() => {
    if (isDiscarded) {
      setHasUnsavedChanges(false);
      navigateAfterVehicleSaved();
    }
  }, [isDiscarded, navigateAfterVehicleSaved]);

  // Prevent navigation if there are unsaved changes
  useEffect(() => {
    addListener('beforeRemove', handleNavigation);
    return () => {
      addListener('beforeRemove', handleNavigation);
    };
  }, [addListener, navigate]);

  const [updateVehicle, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateVehicleArgs
  >(UPDATE_VEHICLE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        errorPolicy: 'all',
      },
    ],
    onCompleted: (data) => {
      setUpdatedLicensePlate(data.updateVehicle.licensePlate.plateText);
      showToast('success.updateVehicle', 'success');
      setHasUnsavedChanges(false);
      editVehicleData({} as Vehicle);
      navigateAfterVehicleSaved();
    },
    onError: (error) => {
      const errorCode = error.graphQLErrors
        .flatMap((e) => e.extensions?.code as string)
        .find((code) => code.startsWith('CANNOT_UPDATE_VEHICLE'))
        ?.split('_')
        ?.pop();
      if (errorCode) {
        showToast(
          `error.updateVehicle${
            errorCode[0] + errorCode.slice(1).toLowerCase()
          }`,
          'error'
        );
      } else {
        showToast('error.updateVehicle', 'error');
      }
    },
  });

  const handleUpdateVehicle = useCallback(async () => {
    if (!editVehicle?.id) {
      Logger.error('Update vehicle, editVehicle has no id');
      showToast('error.updateVehicle', 'error');
      return;
    }
    await updateVehicle({
      variables: {
        input: {
          licensePlate: updatedVehicle.licensePlate,
          id: editVehicle.id,
          name: updatedVehicle.name,
          cameraRecognition: {
            allowed: updatedVehicle.cameraRecognition?.allowed || false,
            enabled: updatedVehicle.cameraRecognition?.enabled || false,
            countryCodes: editVehicle.cameraRecognition?.countryCodes,
          },
          appSettings: {
            icon: updatedVehicle.appSettings?.icon,
            color: updatedVehicle.appSettings?.color,
            type: updatedVehicle.appSettings?.type,
            evPlugType: updatedVehicle.appSettings?.evPlugType,
          },
        },
      },
    });
  }, [editVehicle, updateVehicle, updatedVehicle]);

  useEffect(() => {
    if (isVehicleSaved) {
      handleUpdateVehicle();
      vehicleSaved(false);
      sheetModal('');
    }
  }, [isVehicleSaved, handleUpdateVehicle]);

  return (
    <VehicleForm
      isNew={false}
      initialVehicle={originalSelectedVehicle}
      onSubmit={handleUpdateVehicle}
      onDelete={handleRemoveConfirmation}
      onFormChange={setUpdatedVehicle}
      loading={updating}
      hasUnsavedChanges={hasUnsavedChanges}
    />
  );
};

export default EditVehicleScreen;
