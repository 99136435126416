import { gql } from '@apollo/client';

export const RETRY_CHARGING_PAYMENT = gql`
  mutation RetryChargingPaymentV2($input: RetryFailedPaymentInputV2!) {
    retryFailedPaymentV2(input: $input) {
      chargingSessionId
      transactionId
    }
  }
`;
