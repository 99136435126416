import React from 'react';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import { sheetModal } from 'states/common';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Icon from 'components/icons/Icon';
import { Pill } from 'styles/CommonLayoutStyles';
import styled from 'styled-components/native';
import { theme, Meta } from 'aimo-ui';
import { useTranslation } from 'react-i18next';
import { getCardLogo } from 'utils/paymentUtils';

const Container = styled.View`
  flex-direction: row;
  align-items: center;
`;

const IconWrapper = styled.View`
  margin-right: 4px;
`;

const TextWrapper = styled.View`
  margin-right: 28px;
`;

const PaymentCardSelector = () => {
  const { t } = useTranslation();
  const paymentMethod = useSelectedPaymentMethod();
  const handleOnPress = () => {
    sheetModal('parkingPaymentMethod');
  };

  return (
    <Pill bg={theme.colors.gray50}>
      <TouchableOpacity onPress={handleOnPress} testID="credit-card-touchable">
        <Container>
          <IconWrapper>
            {paymentMethod ? (
              <Icon
                testID={`credit-card-icon-${paymentMethod?.type}`}
                name={`${getCardLogo(paymentMethod?.type)}`}
                size="l"
              />
            ) : (
              <Icon
                testID="Credit_Card-Outlined"
                name="Credit_Card-Outlined"
                size="m"
              />
            )}
          </IconWrapper>
          <TextWrapper>
            <Meta testID="card-text">
              {paymentMethod?.lastNums ??
                t('parking.parkingProcess.noCardSelected')}
            </Meta>
          </TextWrapper>
          <Icon name="Chevron_Right" testID="dropdown-chevron" size="s" />
        </Container>
      </TouchableOpacity>
    </Pill>
  );
};

export default PaymentCardSelector;
