import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { showToast } from 'components/common/CustomToast';
import ParkMap from 'components/map/ParkMap';
import StreetParking from 'components/park/streetParking/StreetParking';
import AccountContext from 'context/AccountContext';
import { GET_PARK_ZONE } from 'graphql/queries/getParkZone';
import { READ_LEGAL_ADDRESS_STATUS } from 'graphql/queries/readLegalAddressStatus';
import LegalAddressAuthStack from 'navigation/LegalAddressAuthNavigator';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  cityPmcsScreenName,
  selectedCityPmc,
  strongAuthCompleted,
  strongAuthFlow,
} from 'states/common';
import { mapCoordinates } from 'states/map';
import {
  Maybe,
  ParkZone,
  Query,
  QueryParkZoneArgs,
  QueryReadLegalAddressStatusArgs,
} from 'types/generatedSchemaTypes';
import { StreetParkingStackParamList } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { makePoint } from 'utils/mapUtils';

const StreetParkingPmcsScreen: React.FC<{ isTesting?: boolean }> = ({
  isTesting = false,
}) => {
  const strongAuthFlowStarted = useReactiveVar(strongAuthFlow);
  const completed = useReactiveVar(strongAuthCompleted);
  const { customer, refetchCustomer: fetchCustomerAfterSuccess } =
    useContext(AccountContext);
  const { t } = useTranslation();
  const { navigate } =
    useNavigation<StackNavigationProp<StreetParkingStackParamList>>();
  const selectedPmc = selectedCityPmc();
  const [parkZone, { data }] = useLazyQuery<Query, QueryParkZoneArgs>(
    GET_PARK_ZONE,
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const parkZoneDetails = React.useMemo(() => [data?.parkZone] || [], [data]);

  const [readLegalAddressStatus] = useLazyQuery<
    Query,
    QueryReadLegalAddressStatusArgs
  >(READ_LEGAL_ADDRESS_STATUS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      fetchCustomerAfterSuccess();
      strongAuthCompleted(true);
    },
    onError: () => showToast(t('error.addressFetchFailed'), 'error'),
  });

  useEffect(() => {
    if (isWeb && window.location.search.includes('ssn')) {
      const number = window.location.search.substring(5);
      readLegalAddressStatus({
        variables: {
          input: {
            ssn: number,
          },
        },
      });
    }
  }, [readLegalAddressStatus]);

  useEffect(() => {
    if (customer?.strongAuthentication?.gedi && completed) {
      const address = customer?.strongAuthentication?.addresses?.[0];
      const addressString = ` ${address?.streetName} ${address?.streetNumber}`;
      showToast(t('cityPermits.addressSet', { addressString }), 'success');
      strongAuthCompleted(false);
    }
    return () => {
      selectedCityPmc(null);
      cityPmcsScreenName('');
      strongAuthFlow(false);
    };
  }, [customer, completed, t, navigate]);

  useEffect(() => {
    if (selectedPmc) {
      const uid = selectedPmc.physicalZoneUid as string;
      parkZone({
        variables: {
          uid,
        },
      });
    }
  }, [parkZone, selectedPmc]);

  useEffect(() => {
    if (parkZoneDetails.length > 0) {
      const { address } = parkZoneDetails[0] || {};
      const { marker } = address || {};
      const markerCoordinate = marker && makePoint(marker?.coordinates);
      if (markerCoordinate) {
        mapCoordinates(markerCoordinate);
      }
    }
  }, [parkZoneDetails]);

  if (strongAuthFlowStarted && !completed) {
    return <LegalAddressAuthStack />;
  }

  return (
    <>
      <ParkMap
        showLogo={false}
        showBeforeMapLoaded
        parkingZonesData={parkZoneDetails as Maybe<ParkZone>[]}
        showBackButton
      />
      <StreetParking isTesting={isTesting} />
    </>
  );
};

export default StreetParkingPmcsScreen;
