/* eslint-disable react-native/no-inline-styles */
/* Inline styles needed (100vh, 100vw) so that map can scale correctly */

import { useReactiveVar } from '@apollo/client';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { Region } from 'react-native-maps';
import { mapCoordinates, userLocation } from 'states/map';
import mapStyles from 'styles/mapStyle.json';
import { WebAndMobileRegion } from '../../../types/MapTypes';
import config from 'config/config';
import useIsDesktop from 'hooks/useIsDesktop';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locationIcon: any = {
  url: '/images/location.svg',
  scaledSize: { height: 25, width: 25 },
  origin: { x: 0, y: 0 },
};

type WebViewGoogleMap = {
  children: React.ReactNode;
  showBeforeMapLoaded: boolean;
  customMapStyle?: any;
  testid?: string;
  defaultZoom?: number;
  onPress: () => void;
  onRegionChangeComplete: (regionProps: WebAndMobileRegion) => void;
  region?: Region;
  initialRegion: Region;
};

const MapView = (props: WebViewGoogleMap) => {
  const {
    onPress,
    initialRegion,
    region,
    defaultZoom = 16,
    children,
  } = props || {};
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.googleMapsApiKey,
  });

  const [mapRef, setMapRef] = useState<any>(null);

  const isDesktop = useIsDesktop();
  const tabHeight = useBottomMenuHeight();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [center, _setCenter] = useState({
    lat: initialRegion.latitude,
    lng: initialRegion.longitude,
  });

  const currentMapCoordinates = useReactiveVar(mapCoordinates);
  const coordinates = useReactiveVar(userLocation);
  const { height, width } = Dimensions.get('window');
  const [zoom, setZoom] = useState(
    defaultZoom ||
      (region && region.latitudeDelta
        ? Math.round(Math.log(360 / region.latitudeDelta) / Math.LN2)
        : initialRegion && initialRegion.latitudeDelta
        ? Math.round(Math.log(360 / initialRegion.latitudeDelta) / Math.LN2)
        : 15)
  );

  const ASPECT_RATIO = width / height;

  const onUnmount = React.useCallback(function callback() {
    setMapRef(null);
  }, []);

  const onDragEnd = () => {
    const { onRegionChangeComplete } = props;

    if (mapRef && onRegionChangeComplete) {
      const newCenter = mapRef?.getCenter();
      const latDelta =
        mapRef.getBounds().getNorthEast().lat() -
        mapRef.getBounds().getSouthWest().lat();
      onRegionChangeComplete({
        latitude: newCenter.lat(),
        longitude: newCenter.lng(),
        latitudeDelta: latDelta,
        longitudeDelta: latDelta * ASPECT_RATIO,
        zoom: mapRef.getZoom(),
      });
    }
  };
  const onRegionChange = () => false;

  const handleRegionChange = React.useCallback(() => {
    if (mapRef) {
      if (!Array.isArray(currentMapCoordinates)) {
        const { latitude: lat, longitude: lng } = currentMapCoordinates;
        mapRef.panTo({ lat, lng });
        setZoom(17);
        return;
      }
    }
  }, [currentMapCoordinates, mapRef]);

  useEffect(() => {
    if (
      Array.isArray(currentMapCoordinates) ||
      (currentMapCoordinates?.latitude !== 0 &&
        currentMapCoordinates?.longitude !== 0)
    ) {
      handleRegionChange();
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [currentMapCoordinates, handleRegionChange, isLoaded]);

  if (!isLoaded) {
    return <></>;
  }
  return (
    <GoogleMap
      center={center}
      zoom={zoom}
      onZoomChanged={() => {
        setZoom(mapRef?.getZoom() || 16);
      }}
      onLoad={(map) => setMapRef(map)}
      onUnmount={onUnmount}
      mapContainerStyle={{
        height: '100vh',
        width: '100vw',
        marginTop: !isDesktop ? tabHeight : 0,
      }}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
        maxZoom: 33,
        minZoom: 8,
        streetViewControl: false,
        styles: mapStyles,
        zoomControlOptions: { position: !isDesktop ? 7 : undefined },
      }}
      onDragStart={onRegionChange}
      onIdle={onDragEnd}
      defaultZoom={zoom}
      onClick={onPress}
      {...props}>
      {coordinates && (
        <Marker
          position={{ lat: coordinates.latitude, lng: coordinates.longitude }}
          icon={locationIcon}
        />
      )}
      {children}
    </GoogleMap>
  );
};

export default MapView;
