import React from 'react';
import MapComponent from '@components/map/MapComponent';
import { useReactiveVar } from '@apollo/client';
import { Maybe, ParkZone } from 'types/generatedSchemaTypes';
import { optimizeMapContent } from 'states/map';
import { MarkerClusterer } from '@react-google-maps/api';
import { clusterStyles } from 'styles/StyleSheetStyles';
import { isWeb } from 'utils/deviceUtils';
import SelectedMarker from 'components/map/SelectedMarker';
import { MapMarkers, MapPolygons } from './MapDataRenderers';

type ParkMapProps = {
  showBeforeMapLoaded?: boolean;
  parkingZonesData: Maybe<ParkZone>[];
  showPolygons?: boolean;
  showBackButton?: boolean;
  showLogo?: boolean;
  mapPadding?: { top: number; left: number; right: number; bottom: number };
  withLTP?: boolean;
  showZoomInfo?: boolean;
};

const ParkMap = ({
  showBeforeMapLoaded,
  parkingZonesData,
  showLogo = true,
  showPolygons = true,
  mapPadding,
  showBackButton = false,
  withLTP = false,
  showZoomInfo = false,
}: ParkMapProps) => {
  const hideContent = useReactiveVar(optimizeMapContent);
  return (
    <MapComponent
      mapPadding={mapPadding}
      showLogo={showLogo}
      showBackButton={showBackButton}
      showBeforeMapLoaded={showBeforeMapLoaded}
      showZoomInfo={showZoomInfo}>
      {isWeb && (
        <>
          <SelectedMarker
            parkingZonesData={parkingZonesData}
            withLTP={withLTP}
          />
          <MarkerClusterer
            styles={clusterStyles}
            enableRetinaIcons
            minimumClusterSize={3}
            imageSizes={[50, 60, 70]}>
            {(clusterer) => (
              <>{MapMarkers(clusterer, parkingZonesData, withLTP)}</>
            )}
          </MarkerClusterer>
        </>
      )}
      {!isWeb && MapMarkers(undefined, parkingZonesData, withLTP)}
      {!isWeb && (
        <SelectedMarker parkingZonesData={parkingZonesData} withLTP={withLTP} />
      )}
      {!hideContent && showPolygons && MapPolygons(parkingZonesData)}
    </MapComponent>
  );
};

export default ParkMap;
