import { gql } from '@apollo/client';

export const UPDATE_VEHICLE = gql`
  mutation updateVehicle($input: UpdateVehicleInput) {
    updateVehicle(input: $input) {
      id
      licensePlate {
        plateText
      }
    }
  }
`;
