import React from 'react';
import { Button, ListItem, theme } from 'aimo-ui';
import { VehicleAddSectionProps } from 'components/account/vehicle/VehicleAddSection';
import Icon from 'components/icons/Icon';
import { selectedVehicleTypeForm } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import { ElementWrapper } from 'styles/ListStyle';
import { VehicleType } from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';

type VehicleDataProps = {
  id: VehicleType;
  label: string;
  icon: string;
  testID: string;
};

interface VehicleAddItemProps extends VehicleAddSectionProps {
  item: VehicleDataProps;
  indexValue: number;
}

type IconComponentProps = {
  name: string;
  withWrapper?: boolean;
};

const ArrowIcon = () => (
  <ElementWrapper>
    <Icon name="Chevron_Right" />
  </ElementWrapper>
);

const IconComponent = ({ name, withWrapper = false }: IconComponentProps) => {
  const renderIcon = () => <Icon name={name} />;
  return withWrapper ? (
    <ElementWrapper>{renderIcon()}</ElementWrapper>
  ) : (
    renderIcon()
  );
};

const VehicleAddItem = ({
  item,
  indexValue,
  style,
  onPress,
}: VehicleAddItemProps) => {
  const { id, label, icon, testID } = item;

  const handlePress = () => {
    selectedVehicleTypeForm(id);
    onPress();
  };

  if (style === 'BUTTON') {
    return (
      <StyledWrapper mb={10} maxWidth={isWeb ? 350 : undefined}>
        <Button
          size="l"
          onPress={handlePress}
          text={label}
          buttonType="tertiary"
          testID={`${testID}-button`}
          iconComponent={() => IconComponent({ name: icon })}
        />
      </StyledWrapper>
    );
  }
  return (
    <ListItem
      item={item}
      indexValue={indexValue}
      title={label}
      onPress={handlePress}
      testID={testID}
      withBackground={theme.colors.gray50}
      iconComponent={() => IconComponent({ name: icon, withWrapper: true })}
      arrowComponent={ArrowIcon}
    />
  );
};

export default VehicleAddItem;
