import { useQuery } from '@apollo/client';
import { GET_FEATURE_FLAGS } from '@queries/getFeatureFlags';
import config from 'config/config';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { setupLanguages } from 'i18n/init';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { Query, QueryGetFeatureFlagsArgs } from 'types/generatedSchemaTypes';
import { getFormattedVersion } from 'utils/commonUtils';

type FeatureFlagsContextProviderProps = {
  children: ReactNode;
};

const FeatureFlagsContextProvider = (
  props: FeatureFlagsContextProviderProps
) => {
  const { children } = props;
  const defaults = config.featureFlagsDefault;
  const [flags, setFlags] = useState(defaults);
  const deviceOS = useMemo(() => {
    if (Platform.OS === 'ios') {
      return 'ios';
    } else if (Platform.OS === 'android') {
      return 'android';
    }
    return 'web';
  }, []);

  const {
    loading,
    error,
    data: queryData,
    refetch,
  } = useQuery<Query, QueryGetFeatureFlagsArgs>(GET_FEATURE_FLAGS, {
    variables: {
      input: {
        deviceOs: deviceOS,
        appVersion: getFormattedVersion(),
      },
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!loading && !error) {
      setFlags(queryData?.getFeatureFlags || {});
      setupLanguages(
        queryData?.getFeatureFlags.lang_fi === 'true',
        queryData?.getFeatureFlags.lang_no === 'true',
        queryData?.getFeatureFlags.load_remote_translations !== 'true'
      );
    }
  }, [loading, error, queryData]);

  const featureFlagsContext = useMemo(
    () => ({
      flags,
      refetchFlags: refetch,
    }),
    [flags, refetch]
  );

  return (
    <FeatureFlagsContext.Provider value={featureFlagsContext}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsContextProvider;
