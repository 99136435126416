import { Button, Meta } from 'aimo-ui';
import AccountContext from 'context/AccountContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { sheetModal } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';

const WelcomeCustomerDataFailed: React.FC = () => {
  const { t } = useTranslation();
  const { refetchCustomer, loading } = useContext(AccountContext);
  return (
    <View>
      <StyledWrapper maxWidth={350} center mb={30}>
        <Meta center testID="customer-details-failed-text">
          {t('account.gettingCustomerDetailsFailedTryAgainOrContactUs')}
        </Meta>
      </StyledWrapper>
      <StyledWrapper maxWidth={350} center mb={20}>
        <Button
          loading={loading}
          testID="try-again-customer-details"
          text={t('button.tryAgain')}
          size="l"
          buttonType="secondary"
          onPress={() => refetchCustomer()}
        />
      </StyledWrapper>
      <StyledWrapper maxWidth={350} center mb={20}>
        <Button
          testID="logout-button"
          text={t('account.signOut')}
          size="l"
          buttonType="tertiary"
          onPress={() => sheetModal('signOut')}
        />
      </StyledWrapper>
    </View>
  );
};

export default WelcomeCustomerDataFailed;
