import ParkingContext from 'context/ParkingContext';
import useChargers from 'hooks/useChargers';
import React, { useContext, useEffect } from 'react';
import { ViewProps } from 'react-native';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { Charger } from 'types/common';
import { Maybe, ParkZone } from 'types/generatedSchemaTypes';
import SelectParkAndChargeItem from './SelectParkAndChargeItem';

type SelectParkAndChargerProps = {
  parkZone?: Maybe<ParkZone>;
  chargerFilter?: (charger: Charger) => boolean;
  updateChargerCount?: (count: number) => void;
  marginTop?: boolean;
  onChargerSelection?: (chargingStationId: string) => void;
};

const SelectParkAndCharger: React.FC<SelectParkAndChargerProps & ViewProps> = ({
  parkZone,
  updateChargerCount,
  chargerFilter = () => true,
  marginTop,
  onChargerSelection,
  ...props
}) => {
  const { setSelectedEvCharger, selectedEvCharger } =
    useContext(ParkingContext);
  const { chargers } = useChargers(parkZone?.parkingZoneChargingStations ?? undefined);

  useEffect(() => {
    updateChargerCount?.(chargers.length);
  }, [chargers, updateChargerCount]);

  const handleSetCharger = (charger: Charger) => {
    setSelectedEvCharger(charger);
    onChargerSelection?.(charger.id);
  };

  return (
    <ColumnWithMargins {...props} mt={marginTop ? 15 : 0}>
      {chargers.filter(chargerFilter).map((item, i, arr) => {
        return (
          <SelectParkAndChargeItem
            key={`charger-item-${item.id}-${item.evse.id}`}
            isSelected={
              selectedEvCharger?.publicDisplayId === item.publicDisplayId
            }
            onPress={handleSetCharger}
            charger={item}
            testID={`charger-item-${item.id}-${item.evse.id}`}
            lastElement={i === arr.length - 1}
          />
        );
      })}
    </ColumnWithMargins>
  );
};

export default SelectParkAndCharger;
