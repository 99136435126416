import { HorizontalList, theme } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import ParkingContext from 'context/ParkingContext';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'styles/CommonLayoutStyles';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import { CountryCode } from 'types/generatedSchemaTypes';
import { Row } from '../styles/styles';
import PrepaidTicketOrPermitItem from './PrepaidTicketOrPermitItem';
import { ParkzonePmcUnion } from 'types/common';
import { isItemOwned, isItemPmc } from 'utils/permitUtils';
import usePermitParkZone from 'hooks/usePermitParkZone';
import { selectedPermit, sheetModal } from 'states/common';

const PrepaidTickets: React.FC<{
  tickets: ParkzonePmcUnion[];
  countryCode: CountryCode;
  testID: string;
  onListPress?: VoidFunction;
}> = ({ tickets, countryCode, testID, onListPress }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useIsLoggedIn();
  const { setSelectedPrepaidTicket, setCurrentScreen, parkingZoneResult } =
    useContext(ParkingContext);
  const { setReturnRedirectToBuyShortTermParking, navigateToAccountLogin } =
    useAfterAuthenticationRedirect();
  const parkZoneUid = parkingZoneResult?.data?.parkZone?.uid as string;
  const permitForParkZone = usePermitParkZone(parkZoneUid);

  const onItemSelect = (listItem: ParkzonePmcUnion) => {
    if (!isLoggedIn) {
      setReturnRedirectToBuyShortTermParking(parkZoneUid);
      navigateToAccountLogin();
      return;
    }
    if (isItemOwned(listItem)) {
      setSelectedPrepaidTicket(listItem);
      setCurrentScreen('EDIT_PREPAID');
      return;
    }
    if (isItemPmc(listItem) && !!permitForParkZone) {
      selectedPermit(permitForParkZone.id);
      sheetModal('duplicatePermitWarning');
      return;
    }
    setSelectedPrepaidTicket(listItem);
    setCurrentScreen('PURCHASE_PREPAID');
  };

  return (
    <>
      <StyledWrapper mb={10}>
        <MenuItem
          onPress={onListPress}
          menuLabel={t('parking.prepaidTickets.prepaidListLabel')}
          smallLabel={t('parking.prepaidTickets.prepaidListSmallLabel')}
          withArrow
          smallLabelColor={theme.colors.boost.green}
          paddingVertical={10}
          testID={`${testID}-list-menuitem`}
          arrowSize="s"
          noBorder
          bold
        />
      </StyledWrapper>

      <HorizontalList
        testID={`${testID}-horizontal`}
        onPress={onItemSelect}
        listData={tickets}
        listElement={({ item, testID: prepaidTestId, onPress }) => (
          <ColumnWithMargins ml={6} mr={6}>
            <PrepaidTicketOrPermitItem
              item={item}
              onPress={onPress}
              testID={prepaidTestId}
              countryCode={countryCode}
              isHorizontalList
            />
          </ColumnWithMargins>
        )}
      />
      <Row mb={4} mt={20}>
        <Divider marginVertical={0} />
      </Row>
    </>
  );
};

export default PrepaidTickets;
