import React, { useContext } from 'react';
import SheetLayout from 'components/sheets/SheetLayout';
import { Headline3, Meta, Spinner, theme } from 'aimo-ui';
import ParkingContext from 'context/ParkingContext';
import { StyledWrapper } from 'styles/ContainerStyles';
import { useTranslation } from 'react-i18next';

export const WaitChargingStart: React.FC = () => {
  const { parkingZoneResult: parkingZone } = useContext(ParkingContext);
  const { name = '', commercialZoneView } = parkingZone?.data?.parkZone || {};
  const { t } = useTranslation();

  return (
    <>
      <SheetLayout
        testIDName="wait-charging-start-layout"
        headlineComponent={
          <Headline3
            center
            color={theme.colors.link}
            testID="parkzone-commercial-zone-code">
            {commercialZoneView?.zoneCode ?? ''}{' '}
            <Headline3 color={theme.colors.black}>{name}</Headline3>
          </Headline3>
        }>
        <StyledWrapper center mb={30}>
          <Meta testID="wait-charging-text">
            {t('evCharging.startChargingLoading')}
          </Meta>
        </StyledWrapper>
        <StyledWrapper center>
          <Spinner />
        </StyledWrapper>
      </SheetLayout>
    </>
  );
};
