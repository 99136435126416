import { gql } from '@apollo/client';

export const READ_CUSTOMER_APPLICATION = gql`
  query readPermitApplication($input: ReadPermitApplicationInput!) {
    readPermitApplication(input: $input) {
      created
      queueCustomerStatus
    }
  }
`;
