export const colors = [
  '#FFFFFF',
  '#000000',
  '#BEBEBE',
  '#5B5B5B',
  '#E6E6E6',
  '#EA0606',
  '#044C8F',
  '#5AA7EE',
  '#6A4029',
  '#0C991B',
  '#D4BE9E',
  '#FC9E0F',
  '#FFDF39',
  '#8903C9',
];

export const vehicleIcons = [
  'vehicle-suv',
  'vehicle-sedan',
  'vehicle-hatchback',
  'vehicle-van',
  'vehicle-truck',
  'vehicle-cab',
  'vehicle-motorcycle',
  'vehicle-trailer',
];
