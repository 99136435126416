import styled from 'styled-components/native';

export const FooterWrapper = styled.View<{
  maxWidth?: number;
}>`
  max-width: ${(props) => props.maxWidth || 350}px;
  align-self: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const MainWrapper = styled.View<{ vAlignCenter?: boolean }>`
  margin-left: 20px;
  margin-right: 20px;
  flex: 1;
  ${(props) => (props.vAlignCenter ? 'justify-content: center;' : '')}
`;
