import React from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import InfoBox from '@components/common/InfoBox';
import {
  ItemContainer,
  ItemWrapper,
  StyledWrapper,
} from '@styles/ContainerStyles';
import { Headline3, Meta, Spinner, theme } from 'aimo-ui';
import { GET_PARK_ZONE } from 'graphql/queries/getParkZone';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { activeElement } from 'states/common';
import { country } from 'states/persistInStorage';
import { Query, QueryParkZoneArgs } from 'types/generatedSchemaTypes';
import {
  getSchedule,
  getScheduleFromDate,
  getWeekDayString,
  removeSecondsFromTimeString,
  weekDayOrder,
} from 'utils/timeUtils';
import { Row } from './styles/styles';

type OpenHoursProps = {
  time?: Date;
};

const timeNow = new Date();

const OpenHoursModalContent = ({ time = timeNow }: OpenHoursProps) => {
  const uid = useReactiveVar(activeElement);
  const countryCode = useReactiveVar(country);
  const { t } = useTranslation();
  const { data, loading, error } = useQuery<Query, QueryParkZoneArgs>(
    GET_PARK_ZONE,
    {
      variables: { uid },
      fetchPolicy: 'cache-and-network',
      skip: !uid,
    }
  );

  if (loading) {
    return (
      <StyledWrapper center testID="schedule-loader">
        <Spinner />
      </StyledWrapper>
    );
  }

  const schedule = data?.parkZone?.parkingFacility?.timeSlots;

  if (error || !schedule) {
    return (
      <View testID="schedule-error">
        <Meta>{t('parking.cannotDisplaySchedule')}</Meta>
      </View>
    );
  }

  const groupedSchedule = groupBy(schedule, 'weekDay');

  const { isOpen, closingTime, openTime } = getScheduleFromDate(
    groupedSchedule,
    time,
    countryCode
  );

  const closingTimeString = closingTime
    ? t('time.openUntil', { endTime: removeSecondsFromTimeString(closingTime) })
    : '';
  const todayString = getWeekDayString(time);
  const todayEntry = getSchedule(groupedSchedule, 'DRIVE_IN', todayString);
  const todayExit = getSchedule(groupedSchedule, 'DRIVE_OUT', todayString);

  return (
    <ScrollView nestedScrollEnabled>
      <InfoBox
        testID="parking-schedule-infobox"
        bgColor={isOpen ? theme.colors.lightBg.green : theme.colors.lightBg.red}
        headline={t(`parking.${isOpen ? 'open' : 'closed'}Now`)}
        text={isOpen ? closingTimeString : openTime}
      />
      <Headline3>{t('time.today')}</Headline3>
      <ItemContainer>
        <ItemWrapper testID="parking-entry-today">
          <Meta color={theme.colors.gray200}>{t('parking.entry')}</Meta>
          <Meta>{todayEntry}</Meta>
        </ItemWrapper>
        <ItemWrapper noBorder testID="parking-exit-today">
          <Meta color={theme.colors.gray200}>{t('parking.exit')}</Meta>
          <Meta>{todayExit}</Meta>
        </ItemWrapper>
      </ItemContainer>
      {weekDayOrder?.map((dayString, index) => {
        const entry = getSchedule(groupedSchedule, 'DRIVE_IN', dayString);
        const exit = getSchedule(groupedSchedule, 'DRIVE_OUT', dayString);
        return (
          <ItemContainer key={`daily-schedule-${index}`}>
            <Headline3>{t(`time.${dayString}`)}</Headline3>
            <ItemWrapper testID={`parking-entry-${dayString.toLowerCase()}`}>
              <Meta color={theme.colors.gray200}>{t('parking.entry')}</Meta>
              <Meta>{entry}</Meta>
            </ItemWrapper>
            <ItemWrapper
              noBorder
              testID={`parking-exit-${dayString.toLowerCase()}`}>
              <Meta color={theme.colors.gray200}>{t('parking.exit')}</Meta>
              <Meta>{exit}</Meta>
            </ItemWrapper>
          </ItemContainer>
        );
      })}
      <Row />
    </ScrollView>
  );
};

export default OpenHoursModalContent;
