import { gql } from '@apollo/client';

export const CREATE_SALES_ORDER = gql`
  mutation createSalesOrder($input: CreateSalesOrderInput!) {
    createSalesOrder(input: $input) {
      uid
      orderLines {
        orderLineId
        version
        quantity
      }
    }
  }
`;
