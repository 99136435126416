import { HslWizard } from 'hooks/useHslWizard';
import * as React from 'react';

const noop = () => undefined;

const HslContext = React.createContext<HslWizard>({
  currentScreen: '',
  ticketTypes: [],
  availableZones: [],
  selections: {},
  hslToken: '',
  selectedSubscriptionType: 'ONEOFF',
  hasHslAuthentication: () => false,
  setHslToken: noop,
  setSelectedTicketType: noop,
  setCurrentScreen: noop,
  setSelections: noop,
  setSelectedSubscriptionType: noop,
});

export default HslContext;
