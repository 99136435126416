import { gql } from '@apollo/client';

export const GET_CUSTOMER = gql`
  query readCustomerV3 {
    readCustomerV3 {
      uid
      azureId
      customerNumber
      firstName
      lastName
      strongAuthentication {
        gedi
        vendor
        addresses {
          streetNumber
          streetName
          postalCode
          city
          countryCode
        }
        validDriversLicense
        authenticationDone
        addressUpdated
      }
      mobilePhone
      contactEmail
      invoiceEmail
      customerType
      customerSubTypes
      countryCode
      benefits {
        uid
        memberGroupUid
        benefitTargetUid
        benefitTargetType
        name
        customerUidB2B
        pmc {
          physicalZoneUid
        }
      }
      vehicles {
        licensePlate {
          plateText
          countryCode
        }
        cameraRecognition {
          allowed
          enabled
          countryCodes
        }
        appSettings {
          icon
          color
          type
          evPlugType
        }
        name
        id
      }
      paymentCards {
        cardId
        expireDateMMYY
        expireDate
        maskedPan
        expMonth
        expYear
        countryCode
        isDefault
        paymentProvider
        type
        status
        lastNums
        category
        description
      }
      poolingGroups {
        uid
      }
      permits {
        id
        permitType
        maxLicensePlates
        parkingFacilityName
        isWaitingActivation
        created
        creditCardId
        paymentState
        renewal
        orderStatus
        countryCode
        pmcName
        vatPercent
        registrationPlates {
          plateText
          countryCode
        }
        salesLocationUid
        parkingZoneUid
        accessToPoolingGroupUid
        paymentMethod
        placeName
        accessPermitPoolingGroupCombinedInfo {
          uid
          name
          parkingZoneUids
          freePoolingSpots
          lastFetch
          insidePool
          outsidePool
        }
        orderLines {
          pmcId
          salesOrderId
          salesOrderStatus
          salesOrderPaymentState
          orderLineId
          lineTotal
          version
          quantity
          lineVatAmount
          billingFrequency
          billingType
          registrationPlates {
            plateText
            countryCode
          }
          parkingSpace {
            name
          }
          parkingSpaceUids
          validity {
            validFrom
            validTo
          }
          charge {
            uid
            salesOrderUid
            orderLineId
            validity {
              validFrom
              validTo
            }
            creditCardEvents {
              content {
                created
              }
            }
          }
          pmc {
            name
            physicalZoneUid
            expirationLimit
            prices {
              thirtyDayPrice
              monthlyPrice
              validity {
                validFrom
              }
            }
            parkZone {
              name
              locationType
              countryCode
              commercialZoneView {
                name
                zoneCode
              }
              address {
                street
                marker
                number
                city
              }
              spotMap
            }
          }
        }
      }
      acceptances {
        type
        countryCode
        accepted
      }
      unlockedCustomerSegments {
        customerSegmentUid
        zoneCode
        expiration
        countryCode
      }
      subsidiary
      serviceChannels {
        code
        status
      }
      language
      preferredLanguage
      applicationPreferences {
        sendEmailReceipts
        alwaysSelectVehicle
      }
      customerNumber
    }
  }
`;
