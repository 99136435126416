import AccountContext from 'context/AccountContext';
import { isAfter } from 'date-fns';
import { useContext } from 'react';
import { CountryCode } from 'types/generatedSchemaTypes';

const useHasUnlockedCustomerSegment = (
  customerSegmentUid: string,
  countryCode: CountryCode,
  zoneCode?: string
) => {
  const {
    customer: { unlockedCustomerSegments },
  } = useContext(AccountContext);

  const unlockedSegment = unlockedCustomerSegments?.find(
    (segment) =>
      (segment?.customerSegmentUid === customerSegmentUid &&
        segment.zoneCode === zoneCode &&
        segment.countryCode === countryCode) ||
      // This checks if customer has unlocked Standard tariff. The tariffs customerSegmentUid is "null" if it is default tariff
      (customerSegmentUid === 'Standard' &&
        segment?.customerSegmentUid === 'null' &&
        segment?.zoneCode === zoneCode &&
        segment.countryCode === countryCode)
  );

  return {
    isUnlockValid: unlockedSegment
      ? isAfter(new Date(unlockedSegment.expiration), new Date())
      : false,
    hasUnlocked: !!unlockedSegment,
  };
};

export default useHasUnlockedCustomerSegment;
