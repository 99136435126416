import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { activeElement } from 'states/common';
import { Maybe, ParkZone } from 'types/generatedSchemaTypes';
import { MarkerTypes } from 'types/MapTypes';
import { makePoint } from 'utils/mapUtils';
import AimoMarker from './AimoMarker';

type SelecterMarkerProps = {
  parkingZonesData: Maybe<ParkZone>[];
  withLTP?: boolean;
};

const SelectedMarker: React.FC<SelecterMarkerProps> = ({
  parkingZonesData,
  withLTP = false,
}) => {
  const selectedUid = useReactiveVar(activeElement);
  if (selectedUid?.length === 0) {
    return <></>;
  }

  const selectedParkingZone = parkingZonesData?.find(
    (p: ParkZone | null) => p?.uid === selectedUid
  );
  const {
    address,
    uid,
    locationType,
    commercialZoneView,
    markerType,
    countryCode,
    parkingMethods,
  } = selectedParkingZone || {};

  if (locationType === 'STREET' && countryCode !== 'SE') {
    return null;
  }

  if (address?.marker?.coordinates && uid) {
    const coordinates = makePoint(address?.marker?.coordinates);
    const zoneCode = commercialZoneView?.zoneCode;
    return (
      <AimoMarker
        key={`selected-icon-${uid}`}
        coordinate={coordinates}
        markerType={
          (markerType === 'default' && parkingMethods?.anpr
            ? 'anpr'
            : markerType) as MarkerTypes
        }
        zoneCode={zoneCode as string}
        activeIndex={1}
        testID={`${markerType}-selected-marker-${uid}`}
        id={uid}
        isSelected={true}
        withLTP={withLTP}
      />
    );
  }
  return <></>;
};

export default SelectedMarker;
