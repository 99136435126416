import { createStackNavigator } from '@react-navigation/stack';
import { theme } from 'aimo-ui';
import {
  OnboardingAddVehicle,
  OnboardingAddPaymentMethod,
  OnboardingWelcome,
  PushNotifications,
} from 'components/onboarding';
import ModalStackHeader, {
  StackHeaderCustomProps,
} from 'navigation/ModalStackHeader';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import VehicleAdd from 'components/account/vehicle/VehicleAdd';
import { getModalOptions } from 'utils/navigationUtils';
import VehicleCameraRecognition from 'components/account/vehicle/VehicleCameraRecognition';
import { useReactiveVar } from '@apollo/client';
import { creditCardUrlPaymentHighway } from 'states/common';

const OnboardingStack = createStackNavigator();

const ModalHeaderCloseComponent = (props: StackHeaderCustomProps) => (
  <ModalStackHeader {...props} closeButton={true} closeButtonType="secondary" />
);

const ModalHeaderBackComponent = (props: StackHeaderCustomProps) => (
  <ModalStackHeader {...props} backButtonType="secondary" />
);

const OnboardingNavigator: React.FC = () => {
  const { t } = useTranslation();
  const modalOptions = useMemo(() => getModalOptions(), []);
  const creditCardUrl = useReactiveVar(creditCardUrlPaymentHighway);

  return (
    <OnboardingStack.Navigator
      screenOptions={{
        cardStyle: { backgroundColor: theme.colors.white },
        header: (props) => <ModalStackHeader {...props} />,
      }}>
      <OnboardingStack.Group
        screenOptions={{
          cardStyle: {
            backgroundColor: theme.colors.lightBg.orange,
          },
        }}>
        <OnboardingStack.Screen
          name="welcome"
          component={OnboardingWelcome}
          options={{
            title: t('onboarding.welcome'),
            headerShown: false,
            cardStyle: {
              backgroundColor: theme.colors.lightBg.pink,
            },
          }}
        />
        <OnboardingStack.Screen
          name="pushNotifications"
          component={PushNotifications}
          options={{
            title: t('onboarding.pushNotifications'),
            headerShown: false,
            cardStyle: { backgroundColor: theme.colors.lightBg.pink },
          }}
        />
      </OnboardingStack.Group>
      <OnboardingStack.Screen
        name="onboardingContinue"
        component={OnboardingAddVehicle}
        options={{
          title: t('onboarding.continue'),
          headerShown: false,
          cardStyle: { backgroundColor: theme.colors.lightBg.pink },
        }}
      />
      <OnboardingStack.Screen
        name="addVehicleOnboarding"
        component={VehicleAdd}
        options={{
          presentation: 'modal',
          title: t('onboarding.addVehicle'),
          header: (props) =>
            ModalHeaderCloseComponent({
              ...props,
              ...modalOptions.headerStyle,
            }),
          cardStyle: modalOptions.cardStyle,
        }}
      />
      <OnboardingStack.Screen
        name="vehicleCameraRecognitionOnboarding"
        component={VehicleCameraRecognition}
        options={{
          presentation: 'modal',
          title: t('vehicle.cameraRecognition.title'),
          header: (props) =>
            ModalHeaderBackComponent({ ...props, ...modalOptions.headerStyle }),
          cardStyle: modalOptions.cardStyle,
        }}
      />
      <OnboardingStack.Screen
        name="addPaymentMethod"
        component={OnboardingAddPaymentMethod}
        options={{
          title: t('onboarding.addPaymentMethod'),
          headerShown: !!creditCardUrl,
          cardStyle: { backgroundColor: theme.colors.lightBg.pink },
        }}
      />
    </OnboardingStack.Navigator>
  );
};

export default OnboardingNavigator;
