import { useNavigation } from '@react-navigation/native';
import { Headline3 } from 'aimo-ui';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import SheetLayout from 'components/sheets/SheetLayout';
import ParkingContext from 'context/ParkingContext';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import useAllowedParkingTimes from 'hooks/useAllowedParkingTimes';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import usePermitParkZone from 'hooks/usePermitParkZone';
import useReadPurchasedPrepaidTickets from 'hooks/useReadPurchasedPrepaidTickets';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import {
  Pmc,
  PredefinedTicket,
  UnifiedPermit,
} from 'types/generatedSchemaTypes';
import { SetParamsProps } from 'types/navigation';
import { handleParkZoneSelected } from 'utils/mapUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import PrepaidTicketOrPermitItem from './PrepaidTicketOrPermitItem';

const HeaderWrapper = styled.View`
  margin-bottom: 12px;
`;

const PredefinedTicketAndPermitList = () => {
  const {
    parkingZoneResult: parkingZone,
    myPermitsForZone,
    setCurrentScreen,
    setSelectedPrepaidTicket,
    availablePermits,
  } = useContext(ParkingContext);

  const { setReturnRedirectToBuyShortTermParking, navigateToAccountLogin } =
    useAfterAuthenticationRedirect();
  const { isLoggedIn } = useIsLoggedIn();

  const { uid = '' } = parkingZone?.data?.parkZone || {};
  const countryCode = parkingZone?.data?.parkZone?.countryCode || 'FI';
  const { predefinedTickets } = useAllowedParkingTimes(uid, true);
  const permitForParkZone = usePermitParkZone(uid);
  const { setParams }: SetParamsProps = useNavigation();
  const { purchasedPrepaidTickets } = useReadPurchasedPrepaidTickets();
  const prepaidTickets = predefinedTickets.filter(
    (predefinedTicket) => predefinedTicket?.sessionType === 'PREPAID'
  );

  const parkzonesPermits = availablePermits || [];

  const { t } = useTranslation();

  const onPrepaidItemSelect = (prepaidTicket: PredefinedTicket) => {
    if (!isLoggedIn) {
      setReturnRedirectToBuyShortTermParking(
        parkingZone?.data?.parkZone?.uid as string
      );
      navigateToAccountLogin();
      return;
    }
    if (prepaidTicket.endTime) {
      setSelectedPrepaidTicket(prepaidTicket);
      setCurrentScreen('EDIT_PREPAID');
      return;
    }
    setSelectedPrepaidTicket(prepaidTicket);
    setCurrentScreen('PURCHASE_PREPAID');
  };

  const onPurchasedPermitSelect = (permit: UnifiedPermit) => {
    setSelectedPrepaidTicket(permit);
    setCurrentScreen('EDIT_PREPAID');
  };

  const onPmcSelect = (pmcItem: Pmc) => {
    if (!isLoggedIn) {
      setReturnRedirectToBuyShortTermParking(
        parkingZone?.data?.parkZone?.uid as string
      );
      navigateToAccountLogin();
      return;
    }
    if (permitForParkZone) {
      return handleParkZoneSelected(
        uid,
        undefined,
        setParams,
        true,
        permitForParkZone?.id
      );
    }
    setSelectedPrepaidTicket(pmcItem);
    setCurrentScreen('PURCHASE_PREPAID');
  };

  const hasPurchasedPermits = myPermitsForZone && myPermitsForZone.length > 0;
  return (
    <>
      <SheetLayout
        testIDName="predefined-tickets-permits"
        headlineComponent={
          <Headline3
            center
            {...testIdentifiers('predefined-tickets-permits-headline-text')}>
            {t('parking.prepaidTickets.prepaidListLabel')}
          </Headline3>
        }
        onHeadlineButtonPress={() => setCurrentScreen('SHOW_DETAILS')}>
        <BottomSheetScrollViewCustom>
          {(purchasedPrepaidTickets.length > 0 || hasPurchasedPermits) && (
            <StyledWrapper mb={25} testID="prepaid-purchased-tickets-container">
              <HeaderWrapper>
                <Headline3>
                  {t('parking.prepaidTickets.prepaidOwnedHeader')}
                </Headline3>
              </HeaderWrapper>
              {purchasedPrepaidTickets.map((item, index) => (
                <ColumnWithMargins key={index} mt={8} mb={8}>
                  <PrepaidTicketOrPermitItem
                    testID={`prepaid-owned-ticket-${index}`}
                    item={item}
                    countryCode={countryCode}
                    onPress={onPrepaidItemSelect}
                  />
                </ColumnWithMargins>
              ))}
              {myPermitsForZone?.map((item, index) => (
                <ColumnWithMargins key={index} mt={8} mb={8}>
                  <PrepaidTicketOrPermitItem
                    testID={`prepaid-owned-ticket-${index}`}
                    item={item as UnifiedPermit}
                    countryCode={countryCode}
                    onPress={onPurchasedPermitSelect}
                  />
                </ColumnWithMargins>
              ))}
            </StyledWrapper>
          )}

          {prepaidTickets.length > 0 && (
            <StyledWrapper
              mb={25}
              {...testIdentifiers('prepaid-tickets-container')}>
              <HeaderWrapper>
                <Headline3>
                  {t('parking.prepaidTickets.prepaidListHeader')}
                </Headline3>
              </HeaderWrapper>
              <View {...testIdentifiers('prepaid-tickets-container-items')}>
                {prepaidTickets.map((item, index) => (
                  <ColumnWithMargins
                    key={index}
                    mt={8}
                    mb={8}
                    {...testIdentifiers('prepaid-ticket-item')}>
                    <PrepaidTicketOrPermitItem
                      testID={`prepaid-ticket-${index}`}
                      item={item as PredefinedTicket}
                      countryCode={countryCode}
                      onPress={onPrepaidItemSelect}
                    />
                  </ColumnWithMargins>
                ))}
              </View>
            </StyledWrapper>
          )}

          {parkzonesPermits.length > 0 && (
            <>
              <HeaderWrapper>
                <Headline3>{t('services.permits')}</Headline3>
              </HeaderWrapper>
              {parkzonesPermits.map((permit, index) => (
                <ColumnWithMargins
                  key={index}
                  mt={8}
                  mb={8}
                  {...testIdentifiers('permit-item')}>
                  <PrepaidTicketOrPermitItem
                    testID={`prepaid-ticket-${index}`}
                    item={permit}
                    countryCode={countryCode}
                    onPress={onPmcSelect}
                  />
                </ColumnWithMargins>
              ))}
            </>
          )}
        </BottomSheetScrollViewCustom>
      </SheetLayout>
    </>
  );
};

export default PredefinedTicketAndPermitList;
