import { Headline3, Meta } from 'aimo-ui';
import useDurationFromDate from 'hooks/useDurationFromDate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { country, deviceLanguage } from 'states/persistInStorage';
import styled from 'styled-components/native';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { ParkingSession } from 'types/generatedSchemaTypes';
import { currencyFormatter } from 'utils/commonUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import {
  getTimeFromDate,
  getTodayTomorrowOrDate,
  getTodayYesterdayOrDate,
} from 'utils/timeUtils';
import { StatusContainer } from './styles';

type ParkingHeaderProps = {
  session: ParkingSession;
  isManual?: boolean;
};

const Time = styled.Text`
  font-family: ${({ theme: t }) => t.fontFamily.bold};
  font-size: ${({ theme: t }) => t.normalising.fontPixel(56)}px;
  color: ${({ theme: t }) => t.colors.white};
`;

const SmallerText = styled(Headline3)`
  margin-top: 4px;
  color: ${({ theme: t }) => t.colors.white};
`;

const StyledMeta = styled(Meta)`
  margin-top: 4px;
  color: ${({ theme: t }) => t.colors.white};
`;

const ManualParking: React.FC<ParkingHeaderProps> = ({ session }) => {
  const { t } = useTranslation();
  const endTime = new Date(session?.predefinedEndDate as string);
  const endDate = getTodayTomorrowOrDate(endTime);
  const parkingCountry = session.countryCode;
  const clocktime = getTimeFromDate(endTime, parkingCountry || country());
  const totalPrice = session.price?.totalAmount || 0;
  const price = currencyFormatter(totalPrice, parkingCountry || country());
  const tariffId = session?.tariffName ?? session.customerSegment?.uid;

  return (
    <StatusContainer>
      <StyledMeta testID="ongoing-parking-ends">
        {t('time.ends', { endDate })}
      </StyledMeta>
      <Time {...testIdentifiers('ongoing-parking-end-time')}>{clocktime}</Time>
      <StyledMeta {...testIdentifiers('ongoing-parking-price')}>
        {price}
      </StyledMeta>
      <StyledMeta {...testIdentifiers('ongoing-parking-tariff')}>
        {tariffId}
      </StyledMeta>
    </StatusContainer>
  );
};

const AnprParking = ({ session }: ParkingHeaderProps) => {
  const { t } = useTranslation();
  const startDate = new Date(session?.startDate as Date);
  const duration = useDurationFromDate(startDate, 30000, [
    'days',
    'hours',
    'minutes',
  ]);
  const parkingCountry = session.countryCode;
  const parkingStartedTime = getTodayYesterdayOrDate(
    startDate,
    deviceLanguage()
  )
    ? getTodayYesterdayOrDate(startDate, deviceLanguage()) +
      ' ' +
      getTimeFromDate(startDate, parkingCountry || country())
    : getTimeFromDate(startDate, parkingCountry || country());

  return (
    <StatusContainer>
      <ColumnWithMargins mt={10} mb={70}>
        <SmallerText center {...testIdentifiers('anpr-description')}>
          {t('parking.anprDescription')}
        </SmallerText>
      </ColumnWithMargins>
      <ColumnWithMargins>
        <SmallerText {...testIdentifiers('anpr-duration-text')}>
          {t('parking.duration')}
        </SmallerText>
      </ColumnWithMargins>
      <Time {...testIdentifiers('anpr-duration')}>{duration}</Time>
      <ColumnWithMargins mt={10} mb={70}>
        <SmallerText center {...testIdentifiers('anpr-startTime')}>
          {t('parking.anprStartedTime')} {parkingStartedTime}
        </SmallerText>
      </ColumnWithMargins>
    </StatusContainer>
  );
};

const OngoingParkingStatus = ({
  session,
  isManual = true,
}: ParkingHeaderProps) => {
  if (isManual) {
    return <ManualParking session={session} />;
  }
  return <AnprParking session={session} />;
};

export default OngoingParkingStatus;
