import React from 'react';
import { theme } from 'aimo-ui';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { LatLng } from 'types/MapTypes';
import { getMapDirections } from 'utils/mapUtils';
import { QuickActionButton } from '@components/common/QuickActionButton';

type QuickActionDirectionProps = {
  name?: string | null;
  markerCoordinate?: LatLng | null;
  showIconLabel: boolean;
  alignEnd?: boolean;
};

const QuickActionDirection = ({
  name,
  markerCoordinate,
  showIconLabel,
  alignEnd,
}: QuickActionDirectionProps) => {
  const { t } = useTranslation();
  const coloring = markerCoordinate ? theme.colors.link : theme.colors.gray100;

  const latLng = `${markerCoordinate?.latitude},${markerCoordinate?.longitude}`;
  const label = encodeURIComponent(name || '');

  const directionsUrl = getMapDirections(label, latLng);

  const openDirections = () => {
    if (!markerCoordinate) {
      return;
    }
    Linking.openURL(directionsUrl);
  };

  return (
    <QuickActionButton
      testID="parkzone-quickaction-directions"
      onPress={openDirections}
      disabled={!markerCoordinate}
      iconName="Directions-Outlined"
      coloring={coloring}
      iconLabel={showIconLabel ? t('quickAction.directions') : undefined}
      alignEnd={alignEnd}
    />
  );
};

export default QuickActionDirection;
