import { useReactiveVar } from '@apollo/client';
import { Meta, theme } from 'aimo-ui';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import ParkingContext from 'context/ParkingContext';
import { isBefore } from 'date-fns';
import { useKeyboardVisible } from 'hooks/useKeyboardVisible';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { activeElementType, selectedParkingZoneTab } from 'states/common';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
import { isAndroid } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { getMaxParkingTime } from 'utils/timeUtils';
import ParkingConditionsContainer from './detail/ParkingConditionsContainer';
import { ParkingSheetProps } from './ParkingWizardSheet';
import Tariff from './tariff/Tariff';
import TimeSelection from './timeSelection';

const CenteredWrapper = styled.View<{ mb: number; center: boolean }>`
  flex: 1;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-end')};
  align-items: center;
  margin-bottom: ${({ mb }) => mb}px;
`;

const ParkingConditionsCustomEndTime: React.FC<ParkingSheetProps> = ({
  footerHeight = 180,
}) => {
  const { setCurrentScreen, parkingConditions, setEndTime, setIsValidEndTime } =
    useContext(ParkingContext);
  const {
    flags: { time_wheel },
  } = useContext(FeatureFlagsContext);
  const showTimeWheel = showFeatureFlag(time_wheel);

  const keyboardVisible = useKeyboardVisible();
  const { t } = useTranslation();
  const times = parkingConditions?.allowedTimes;
  let minDate;
  let maxDate: Date | undefined;
  if (times?.parkingAllowedFrom && times.parkingAllowedUntil) {
    const allowedFromDate = new Date(times.parkingAllowedFrom);
    if (isBefore(allowedFromDate, Date.now())) {
      minDate = allowedFromDate;
    }
    maxDate = new Date(times.parkingAllowedUntil);
  }

  const parkingZoneType = useReactiveVar(activeElementType);
  const selectedTab = useReactiveVar(selectedParkingZoneTab);
  const isParkAndCharge =
    parkingZoneType === 'PARK_AND_CHARGE' && selectedTab === 'PARK_AND_CHARGE';

  const maxParkingTime = getMaxParkingTime(
    times?.parkingAllowedFrom || '',
    times?.parkingAllowedUntil || ''
  );

  return (
    <ParkingConditionsContainer
      onBackPress={() => setCurrentScreen('SHOW_DETAILS')}
      onCancel={() => setCurrentScreen('SHOW_DETAILS')}>
      {parkingConditions.parkingZoneUid && (
        <>
          <StyledWrapper mb={theme.normalising.heightPixel(15)}>
            <Tariff borderTop />
          </StyledWrapper>
          <CenteredWrapper
            center={!showTimeWheel}
            mb={keyboardVisible && isAndroid ? 0 : footerHeight + 20}>
            <TimeSelection
              testID={'time-selection'}
              parkingZoneUid={parkingConditions.parkingZoneUid}
              parkingZoneCountryCode={
                parkingConditions?.parkingZoneCountryCode || 'FI'
              }
              minDate={minDate}
              maxDate={maxDate}
              startTime={new Date(times?.parkingAllowedFrom || new Date())}
              endTime={parkingConditions.endTime}
              setEndTimeCallback={setEndTime}
              setIsValidEndTimeCallback={setIsValidEndTime}
            />
            {maxParkingTime && (
              <StyledWrapper mb={0} center>
                <Meta
                  center
                  testID="max-parking-time"
                  color={theme.colors.gray200}>
                  {isParkAndCharge ? t('evCharging.maximumChargingTime') : ''}
                  {t('parking.maxParkingTimeFromNow', {
                    amount: maxParkingTime,
                  })}
                </Meta>
              </StyledWrapper>
            )}
          </CenteredWrapper>
        </>
      )}
    </ParkingConditionsContainer>
  );
};

export default ParkingConditionsCustomEndTime;
