import PriceEstimateContextProvider from 'context/PriceEstimateContextProvider';
import useAllowedParkingTimes from 'hooks/useAllowedParkingTimes';
import useOngoingParkingSession from 'hooks/useOngoingParkingSession';
import React, { useEffect, useMemo } from 'react';
import { selectedTariffId } from 'states/common';
import { SimpleFullContainer } from 'styles/ContainerStyles';
import { Maybe, ParkingSession } from 'types/generatedSchemaTypes';
import OngoingParkingActions from './OngoingParkingActions';
import OngoingParkingExtendTime from './OngoingParkingExtendTime';
import OngoingParkingHeader from './OngoingParkingHeader';
import OngoingParkingStatus from './OngoingParkingStatus';

export const OngoingParkingSession: React.FC<{
  session: Maybe<ParkingSession>;
  showExtendParking: boolean;
  setShowExtendParking: (visibility: boolean) => void;
}> = ({ session, showExtendParking, setShowExtendParking }) => {
  const { parkingZoneResult } = useOngoingParkingSession();
  const { pricingSegments } = useAllowedParkingTimes(
    session?.parkingZoneUid || ''
  );
  const customerSegmentUid = session?.customerSegment?.uid;

  const predefinedTicketsOnly = useMemo(() => {
    return (
      pricingSegments.find(
        (segment) => segment?.uid === (customerSegmentUid || 'Standard')
      )?.predefinedTicketsOnly ?? false
    );
  }, [pricingSegments, customerSegmentUid]);

  useEffect(() => {
    selectedTariffId(customerSegmentUid ?? undefined);
  }, [customerSegmentUid]);

  if (!session) {
    return <></>;
  }

  const isManual =
    session.type === 'START_STOP' ||
    (session.type === 'ANPR' &&
      (parkingZoneResult?.data?.parkZone?.parkingMethods?.manualStop ?? false));
  return (
    <SimpleFullContainer>
      <OngoingParkingHeader
        session={session}
        isManual={isManual}
        showExtendButton={!predefinedTicketsOnly}
      />
      {showExtendParking ? (
        <PriceEstimateContextProvider>
          <OngoingParkingExtendTime
            testID="extend-ongoing"
            session={session}
            onClose={() => {
              setShowExtendParking(false);
            }}
          />
        </PriceEstimateContextProvider>
      ) : (
        <>
          <OngoingParkingStatus session={session} isManual={isManual} />
          {isManual && (
            <OngoingParkingActions
              onShowExtendTime={() => setShowExtendParking(true)}
              showExtendButton={!predefinedTicketsOnly}
            />
          )}
        </>
      )}
    </SimpleFullContainer>
  );
};

export default OngoingParkingSession;
