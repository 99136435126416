import { useReactiveVar } from '@apollo/client';
import { Dropdown, Headline3 } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { featureCountryCode } from 'states/persistInStorage';
import { StyledWrapper } from 'styles/ContainerStyles';
import { CountryCode } from 'types/generatedSchemaTypes';
import { showFeatureFlag } from 'utils/featureFlagUtils';

type Country = {
  label: string;
  value: CountryCode;
};

const DropdownIcon = () => <Icon name="Chevron_Down" />;
const CheckIcon = () => <Icon name="check" />;

const getCountries = (countryCodes: CountryCode[], t: any): Country[] => {
  return countryCodes.map((countryCode) => {
    return {
      label: t(`account.appLocations.${countryCode}`),
      value: countryCode,
    };
  });
};

const ApproveTermsSelectCountry = () => {
  const featureCountry = useReactiveVar(featureCountryCode);
  const { t } = useTranslation();
  const {
    flags: { show_norway },
  } = useContext(FeatureFlagsContext);
  const showNorway = showFeatureFlag(show_norway);
  const [defaultCountry, setDefaultCountry] = useState<Country | undefined>(
    undefined
  );

  // Create a country list based on feature flags
  const countries = useMemo(() => {
    const countryCodeList: CountryCode[] = ['FI', 'SE'];
    if (showNorway) {
      countryCodeList.push('NO');
    }
    return getCountries(countryCodeList, t);
  }, [showNorway, t]);

  useEffect(() => {
    setDefaultCountry(
      countries.find((country) => country.value === featureCountry)
    );
  }, [countries, featureCountry]);

  return (
    <>
      <StyledWrapper mb={20}>
        <Headline3>{t('account.terms.approveTermsCountryHeader')}</Headline3>
      </StyledWrapper>
      <Dropdown
        testID="approve-terms-country-dropdown"
        items={countries}
        onSelect={() => undefined}
        placeholder={t('account.terms.approveTermsCountryPlaceholder')}
        dropdownIcon={DropdownIcon}
        checkIcon={CheckIcon}
        defaultItem={defaultCountry}
        disabled={true}
      />
    </>
  );
};

export default ApproveTermsSelectCountry;
