import { useReactiveVar } from '@apollo/client';
import { Meta, theme, Title } from 'aimo-ui';
import HslContext from 'context/HslContext';
import React, { useContext } from 'react';
import { selectedHslPricing } from 'states/common';
import { country } from 'states/persistInStorage';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
import { currencyFormatter } from 'utils/commonUtils';
import PaymentCardDropDown from '../detail/PaymentCardDropDown';

const ItemContainer = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
  width: 100%;
`;

const Content = styled.View<{
  width?: string;
  alignRight?: boolean;
  marginRight?: string;
}>`
  width: ${(p) => (p.width ? p.width + 'px' : 'auto')};
  flex: ${(p) => (p.alignRight ? '1 1 auto' : '0 1 auto')};
  flex-direction: row;
  justify-content: flex-end;
  align-items: center
  margin-right: ${(p) => (p.marginRight ? p.marginRight + 'px' : '0px')};
`;

const HslPricingView: React.FC = () => {
  const { selectedSubscriptionType } = useContext(HslContext);
  const pricingSelection = useReactiveVar(selectedHslPricing);
  const countryCode = useReactiveVar(country);
  return (
    <ItemContainer testID="hsl-pricing-view">
      <Content>
        <StyledWrapper>
          <Meta color={theme.colors.gray200}>Total amount</Meta>
          <Title>
            {currencyFormatter(
              ((selectedSubscriptionType === 'AUTOSAVER'
                ? pricingSelection?.subscriberPrice
                : pricingSelection?.currentPrice) as number) / 100 || 0,
              countryCode,
              true
            )}
          </Title>
        </StyledWrapper>
      </Content>
      <Content alignRight>
        <PaymentCardDropDown />
      </Content>
    </ItemContainer>
  );
};

export default HslPricingView;
