import PriceBreakDown from 'components/receipt/PriceBreakDown';
import RetryPaymentSummary from 'components/receipt/RetryPaymentSummary';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import React, { useContext } from 'react';
import { View } from 'react-native';
import {
  BillingItem,
  ChargingSession,
  CountryCode,
  ParkingSession,
  Receipt,
} from 'types/generatedSchemaTypes';
import { showFeatureFlag } from 'utils/featureFlagUtils';

type PaymentDetailsProps = {
  testID: string;
  parkingSession?: ParkingSession;
  receiptData?: Receipt;
  chargingSession?: ChargingSession;
  isPaymentFailed: boolean;
  transactionId?: string;
  showTitle?: boolean;
};

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  testID,
  parkingSession,
  receiptData,
  chargingSession,
  isPaymentFailed,
  transactionId,
  showTitle,
}) => {
  const {
    flags: { retry_payment },
  } = useContext(FeatureFlagsContext);
  const showRetry = showFeatureFlag(retry_payment);

  const checkPaymentDate = () => {
    const receipt = parkingSession?.receipt || receiptData;
    if (!receipt) {
      return undefined;
    }
    return new Date(receipt.created as Date);
  };

  const energyUsed =
    receiptData?.items?.[0]?.energyConsumedWatts ||
    chargingSession?.energyInWatts;
  return (
    <View testID={testID}>
      <PriceBreakDown
        billingItems={receiptData?.items as [BillingItem]}
        paymentDate={checkPaymentDate()}
        price={parkingSession?.price || (receiptData?.items as [BillingItem])}
        countryCode={
          parkingSession?.countryCode ||
          chargingSession?.countryCode ||
          (receiptData?.countryCode as CountryCode)
        }
        energyUsed={energyUsed as number}
        isFailedChargingSession={!!chargingSession}
        failedChargingSessionPrice={chargingSession?.priceInCents || 0}
        isPaymentFailed={isPaymentFailed}
        transactionId={transactionId}
        showTitle={showTitle}
      />
      {isPaymentFailed && showRetry && (
        <RetryPaymentSummary
          totalAmount={
            parkingSession?.price?.totalAmount ??
            (chargingSession?.priceInCents || 0) / 100
          }
          countryCode={
            (parkingSession?.countryCode ||
              chargingSession?.countryCode) as CountryCode
          }
        />
      )}
    </View>
  );
};

export default PaymentDetails;
