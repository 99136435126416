import { BaseText, Meta, theme } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import SelectableModalContentItem from 'components/common/SelectableModalContentItem';
import Icon from 'components/icons/Icon';
import AddPaymentMethod from 'components/payment/AddPaymentMethod';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useSelectedCompanyBenefit from 'hooks/useSelectedCompanyBenefit';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import useShowPaymentCardStatus from 'hooks/useShowPaymentCardStatus';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';
import { parkingPaymentCardId, sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { CenterColumn, CenterRow } from 'styles/ContainerStyles';
import { Tag, TagText } from 'styles/TextStyles';
import { Card, CardStatus, CompanyBenefit } from 'types/generatedSchemaTypes';
import { PaymentDataProps } from 'types/PaymentData';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { getCardLogo } from 'utils/paymentUtils';

const ErrorInfo = ({
  testID,
  expiresSoon,
}: {
  testID: string;
  expiresSoon: boolean;
}) => {
  return (
    <Icon
      name={`Info-Filled${expiresSoon ? '-Black' : ''}`}
      size="s"
      testID={`error-info-icon-${testID}`}
      color={expiresSoon ? theme.colors.boost.yellow : theme.colors.boost.red}
    />
  );
};

const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const paymentData: PaymentDataProps[] = [
  {
    title: 'payment.addPaymentCard',
    icon: 'plus',
    action: 'addCardModal',
    testID: 'add-credit-card-button',
  },
];

const PaymentItem = ({
  paymentCard,
  isSelected,
  companyBenefit,
  isBlocked = false,
}: {
  isSelected: boolean;
  paymentCard?: Card;
  testID?: string;
  companyBenefit?: CompanyBenefit;
  isBlocked?: boolean;
}) => {
  const { t } = useTranslation();
  const {
    flags: { card_business },
  } = useContext(FeatureFlagsContext);
  const showBusinessCard = showFeatureFlag(card_business);
  const {
    cardId,
    status,
    category,
    type,
    description,
    lastNums,
    expMonth,
    expYear,
  } = paymentCard || {};

  const { uid, name } = companyBenefit || {};
  const isBusiness = category === 'business';
  const { isErrorStatus, expiresSoon } = useShowPaymentCardStatus(
    (status || 'Active') as CardStatus,
    expMonth || '',
    expYear || ''
  );

  const paymentMethodUid = paymentCard ? paymentCard.cardId : uid;

  const handleSetPayment = () => {
    if (isErrorStatus && paymentCard) {
      showToast(
        t(`error.paymentCardStatuses.${status?.toLowerCase()}`, 'error')
      );
    } else {
      parkingPaymentCardId(paymentMethodUid || undefined);
      sheetModal('');
    }
  };

  const iconColor = () => {
    return isBlocked ? theme.colors.lightBg.green : theme.colors.boost.green;
  };
  const modalContentDetails = () => {
    if (paymentCard) {
      return (
        <>
          <CenterColumn>
            <BaseText testID={`credit-card-type-${cardId}`}>
              {description || type}
            </BaseText>
            <Meta
              color={theme.colors.gray200}
              testID={`payment-card-trailing-numbers-${cardId}`}>
              **** {lastNums}
            </Meta>
          </CenterColumn>
          <CenterRow>
            {isBusiness && showBusinessCard && (
              <Tag testID={`business-tag-${cardId}`}>
                <TagText>{t('paymentMethods.business')}</TagText>
              </Tag>
            )}
          </CenterRow>
        </>
      );
    }

    return (
      <>
        <BaseText
          color={isBlocked ? theme.colors.gray200 : undefined}
          testID={`company-permit-name-${name}`}>
          {name}
        </BaseText>
      </>
    );
  };

  return (
    <SelectableModalContentItem
      isSelected={isSelected}
      disabled={isBlocked}
      onPress={() => handleSetPayment()}
      testID={`credit-card-wrap-${paymentMethodUid}`}
      iconNode={
        <Icon
          testID={`credit-card-icon-${cardId}`}
          name={`${paymentCard ? getCardLogo(type) : 'Service-Filled'}`}
          color={paymentCard ? undefined : iconColor()}
          size="l"
        />
      }
      iconNodeRight={
        (isErrorStatus || expiresSoon) && (
          <ErrorInfo expiresSoon={expiresSoon} testID={`${paymentMethodUid}`} />
        )
      }>
      <Row>{modalContentDetails()}</Row>
    </SelectableModalContentItem>
  );
};

const SelectPaymentMethodModalContent = () => {
  const {
    customer: { paymentCards, companyBenefits },
  } = useContext(AccountContext);
  const {
    flags: { company_benefits },
  } = useContext(FeatureFlagsContext);
  const showCompanyPermits = showFeatureFlag(company_benefits);
  const selectedPaymentCard = useSelectedPaymentMethod();
  const { companyBenefit, isUseAllowed } = useSelectedCompanyBenefit();

  return (
    <ScrollView>
      {paymentCards?.map((paymentCard, index) => {
        return paymentCard ? (
          <PaymentItem
            isSelected={selectedPaymentCard?.cardId === paymentCard.cardId}
            key={`${paymentCard?.cardId}-${index}`}
            paymentCard={paymentCard}
          />
        ) : (
          <></>
        );
      })}
      {showCompanyPermits &&
        companyBenefits?.map((benefit, i) => (
          <PaymentItem
            isSelected={companyBenefit?.uid === benefit?.uid}
            key={`${benefit?.uid}-${i}`}
            companyBenefit={benefit || undefined}
            isBlocked={isUseAllowed}
          />
        ))}
      <AddPaymentMethod paymentData={paymentData} />
    </ScrollView>
  );
};

export default SelectPaymentMethodModalContent;
