import { gql } from '@apollo/client';

export const READ_PARKING_SESSIONS = gql`
  query readParkingSessions($input: ReadParkingSessionsInput) {
    readParkingSessions(input: $input) {
      last
      content {
        countryCode
        transactionId
        startDate
        endDate
        predefinedEndDate
        sessionId
        parkingZoneUid
        parkZone {
          uid
          name
          address {
            marker
          }
          commercialZoneView {
            name
          }
          locationType
        }
        accessDevice {
          value
        }
        customerSegment {
          uid
        }
        price {
          totalAmount
        }
        customerGroupUid
        type
        paymentState
        cardId
        changelog {
          created
          from {
            accessDeviceValue
          }
          to {
            accessDeviceValue
          }
        }
        reassignments {
          reassignmentsLeft
          waitingHours
        }
        poolingInfo {
          poolingGroupUid
          pooled
          customerGroupUid
          poolParkingType
          b2bCustomerUid
        }
        tariffName
      }
    }
  }
`;
