import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Meta } from 'aimo-ui';
import { RowSpaceBetween } from 'styles/ContainerStyles';
import VehicleSelectDropDown from 'components/park/detail/VehicleSelectDropDown';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { ServicesNavProps, ServicesStackParamList } from 'types/navigation';

interface AccessPermitButtonsProps {
  handleRedeem: () => void;
  isRedeemed: boolean;
  disabled: boolean;
  loading: boolean;
}

const AccessPermitButtons: React.FC<AccessPermitButtonsProps> = ({
  handleRedeem,
  isRedeemed,
  disabled,
  loading,
}) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<ServicesNavProps>();
  const {
    params: { permitId },
  } = useRoute<RouteProp<ServicesStackParamList, 'permitRedeem'>>();

  const handleVehiclesSelect = () => {
    navigate('permitVehicles', { permitId });
  };

  return (
    <>
      <RowSpaceBetween>
        <VehicleSelectDropDown
          showInfoIconIfNotSelected={true}
          allowMultipleSelection={true}
          handleVehiclesSelect={handleVehiclesSelect}
        />
        <Meta>{t('permit.access.paymentNotRequired')}</Meta>
      </RowSpaceBetween>
      <Button
        size="l"
        onPress={handleRedeem}
        disabled={disabled}
        loading={loading}
        text={isRedeemed ? t('button.update') : t('button.redeem')}
        testID="redeem-permit-button"
      />
    </>
  );
};

export default AccessPermitButtons;
