import { useNavigation } from '@react-navigation/native';
import { BaseText, Button, Meta, theme } from 'aimo-ui';
import useOngoingChargingSession from 'hooks/useOngoingChargingSession';
import useOngoingParkingSession from 'hooks/useOngoingParkingSession';
import useStopChargingMutation from 'hooks/useStopChargingMutation';
import useUpdateParkingMutation from 'hooks/useUpdateParkingMutation';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';
import { ongoingParkingSheetExpanded, sheetModal } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import { RootNavProps } from 'types/navigation';
import { chargingAllowedToStop } from 'utils/chargingUtils';
import { toggleOngoingSessionSheet } from 'utils/ongoingSessionUtils';

const { width } = Dimensions.get('window');

const StopCharging: React.FC = () => {
  const { t } = useTranslation();
  const [isANPR, setIsANPR] = useState(false);
  const [allowStopOngoingParkingSession, setAllowStopOngoingParkingSession] =
    useState(false);
  const { ongoingChargingSession } = useOngoingChargingSession();
  const chargingZone = ongoingChargingSession?.parkingZoneUid;
  const { allParkingSessions } = useOngoingParkingSession();
  const { navigate } = useNavigation<RootNavProps>();

  const hasParkingSession = allParkingSessions?.find(
    (parkingSession) => parkingSession?.parkingZoneUid === chargingZone
  );

  const callback = () => {
    navigate('parkingReceipt', {
      sessionId: hasParkingSession?.sessionId as string,
      chargingSessionId: ongoingChargingSession?.chargingSessionId ?? '',
    });
    toggleOngoingSessionSheet(ongoingParkingSheetExpanded, false);
  };

  const { stopChargingSessionV2, loading: stopChargingLoading } =
    useStopChargingMutation();
  const { updateParking, updateParkingLoading } = useUpdateParkingMutation({
    callback,
    sessionId: hasParkingSession?.sessionId as string,
    actionType: 'STOP',
    isModal: true,
  });

  const handleStopParkingAndChargingSessions = () => {
    stopChargingSessionV2();
    updateParking();
    toggleOngoingSessionSheet(ongoingParkingSheetExpanded, false);
  };

  const continueParking = () => {
    stopChargingSessionV2();
  };

  useEffect(() => {
    setIsANPR(hasParkingSession?.type === 'ANPR');
    setAllowStopOngoingParkingSession(!!hasParkingSession && !isANPR);
  }, [isANPR, hasParkingSession]);

  const renderContent = () => {
    if (
      allowStopOngoingParkingSession &&
      chargingAllowedToStop(ongoingChargingSession?.status ?? 'IN_PROGRESS')
    ) {
      return (
        <>
          <StyledWrapper mb={25}>
            <BaseText testID="stop-parking-and-charging-confirm-text">
              {t('parking.stopParkingAndCharging')}
            </BaseText>
          </StyledWrapper>
          <StyledWrapper center maxWidth={width} mb={20}>
            <Button
              size="l"
              testID="parking-and-charging-stop-confirm"
              text={t('button.stopParkingAndCharging')}
              onPress={() => handleStopParkingAndChargingSessions()}
              disabled={updateParkingLoading}
              loading={updateParkingLoading}
            />
          </StyledWrapper>
          <StyledWrapper center maxWidth={width} mb={20}>
            <Button
              size="l"
              testID="parking-and-charging-continue-parking"
              buttonType="tertiary"
              text={t('parking.continueParking')}
              onPress={() => continueParking()}
            />
          </StyledWrapper>
        </>
      );
    }
    return (
      <StyledWrapper center maxWidth={width} mb={20}>
        <Button
          size="l"
          testID="charging-stop-confirm"
          buttonType="secondary"
          text={t('evCharging.stop')}
          onPress={() => stopChargingSessionV2()}
          loading={stopChargingLoading}
        />
      </StyledWrapper>
    );
  };

  return (
    <StyledWrapper mb={0}>
      <StyledWrapper mb={25}>
        {isANPR && (
          <Meta
            color={theme.colors.gray200}
            testID="stop-charging-confirm-anpr-text">
            {t('parking.anprDescriptionNoWrap')}
          </Meta>
        )}
      </StyledWrapper>
      {renderContent()}
      <StyledWrapper center maxWidth={width} mb={0}>
        <Button
          size="l"
          testID="charging-stop-cancel"
          buttonType="tertiary"
          text={t('button.cancel')}
          onPress={() => sheetModal('')}
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default StopCharging;
