import { Button, Meta, theme } from 'aimo-ui';
import { LogoutUri } from 'components/auth/AuthConfig';
import AuthManager from 'components/auth/AuthManager';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WebView, { WebViewNavigation } from 'react-native-webview';
import { sheetModal } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import useHandleLogout from 'hooks/useHandleLogout';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';

const Logout: React.FC<{ testMode?: boolean }> = ({ testMode = false }) => {
  const { t } = useTranslation();
  const { loading, showLogout, handleLogout } = useHandleLogout(testMode);
  const handleState = async ({ loading: loadingScreen }: WebViewNavigation) => {
    if (!loadingScreen) {
      await AuthManager.signOutAsync();
      sheetModal('');
    }
  };
  const { clearRedirectStack } = useAfterAuthenticationRedirect();
  const handleLogoutButtonPress = async () => {
    await handleLogout();
    clearRedirectStack();
  };
  return (
    <StyledWrapper mb={0}>
      <StyledWrapper mb={40}>
        <Meta color={theme.colors.gray200}>
          {t('account.signOutConfirmText')}
        </Meta>
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <Button
          loading={loading}
          testID="logout-button"
          size="l"
          text={t(loading ? 'account.signingOut' : 'account.signOut')}
          onPress={handleLogoutButtonPress}
        />
      </StyledWrapper>
      {showLogout && (
        <>
          <WebView
            testID="logout-webview"
            source={{ uri: LogoutUri }}
            onNavigationStateChange={handleState}
          />
        </>
      )}
    </StyledWrapper>
  );
};

export default Logout;
