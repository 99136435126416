import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { BaseText, Meta, Spinner, theme, Title } from 'aimo-ui';
import VehicleAddSection from 'components/account/vehicle/VehicleAddSection';
import VehicleAvatar from 'components/common/VehicleAvatar';
import VehicleLicensePlate from 'components/common/VehicleLicensePlate';
import Icon from 'components/icons/Icon';
import AccountContext from 'context/AccountContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable } from 'react-native';
import {
  editVehicleData,
  editVehicleIsDeleted,
  editVehicleIsDiscarded,
  selectedVehicleTypeForm,
} from 'states/common';
import { featureCountryCode } from 'states/persistInStorage';
import styled from 'styled-components/native';
import {
  CenterVerticalRow,
  ScreenContainer,
  ScreenWrapper,
  SheetDetailsWrapper,
  StyledImage,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { Vehicle } from 'types/generatedSchemaTypes';
import { RootNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { getPermitsAmount } from 'utils/permitUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import { useHeaderHeight } from '@react-navigation/elements';
import { ScrollView } from 'react-native-gesture-handler';

const VehicleWrapper = styled.View<{ showDivider: boolean }>`
  flex-direction: row;
  align-items: center;
  padding-bottom: 17px;
  margin-bottom: 16px;
  ${(props) =>
    props.showDivider &&
    `border-bottom-width: 3px;
    border-bottom-color: ${theme.colors.gray50};`}
`;

const VehicleInfoWrapper = styled.View`
  flex-direction: column;
  margin-left: 20px;
`;

const IconWrapper = styled.View`
  margin-left: auto;
  margin-right: 10px;
  justify-content: center;
`;

const TrunckatedText = styled(BaseText)`
  flex-shrink: 1;
`;

const NoVehicles: React.FC<{ navigate: () => void; headerHeight: number }> = ({
  navigate,
  headerHeight,
}) => {
  const { t } = useTranslation();

  return (
    <SheetDetailsWrapper
      pt={isWeb ? headerHeight : theme.normalising.heightPixel(30)}
      testID="no-content-container">
      <StyledWrapper center>
        <StyledImage
          testID="vehicles-no-content-image"
          width={183}
          height={152}
          center
          source={require('assets/images/parking-receipt-car.png')}
        />
      </StyledWrapper>
      <StyledWrapper
        center
        mb={theme.normalising.heightPixel(37)}
        maxWidth={240}>
        <Title center testID="vehicles-no-content-title">
          {t('vehicle.noContentTitle')}
        </Title>
      </StyledWrapper>
      <VehicleAddSection onPress={navigate} style="BUTTON" />
    </SheetDetailsWrapper>
  );
};

const MyVehiclesScreen: React.FC = () => {
  const { navigate } = useNavigation<RootNavProps>();
  const headerHeight = useHeaderHeight();
  const featureCountry = useReactiveVar(featureCountryCode);
  const {
    customer: { vehicles, permits },
    loading,
  } = useContext(AccountContext);

  const handleSelectVehicle = (v: Vehicle) => {
    editVehicleData(v);
    editVehicleIsDiscarded(false);
    editVehicleIsDeleted(false);
    selectedVehicleTypeForm(undefined);
    navigate('mainTabs', {
      screen: 'accountScreen',
      params: {
        screen: 'editVehicle',
        params: {
          licensePlate: v.licensePlate?.plateText,
        },
      },
    });
  };

  const navigateToAddVehicle = () => {
    navigate('mainTabs', {
      screen: 'accountScreen',
      params: {
        screen: 'addVehicleAccount',
      },
    });
  };

  if (loading) {
    return (
      <ScreenContainer>
        <ScreenWrapper tabBarHeight={headerHeight}>
          <StyledWrapper center>
            <Spinner testID="my-vehicles-loader" />
          </StyledWrapper>
        </ScreenWrapper>
      </ScreenContainer>
    );
  }

  if (!vehicles || !vehicles.length) {
    return (
      <NoVehicles navigate={navigateToAddVehicle} headerHeight={headerHeight} />
    );
  }

  return (
    <ScrollView>
      <SheetDetailsWrapper
        pt={isWeb ? headerHeight : theme.normalising.heightPixel(30)}>
        <StyledWrapper mb={10}>
          {vehicles &&
            vehicles.map((v, i) => (
              <Pressable
                key={`${v.licensePlate?.plateText}-${i}`}
                onPress={() => handleSelectVehicle(v)}
                {...testIdentifiers(
                  `edit-vehicle-${v.licensePlate?.plateText}`
                )}>
                <VehicleWrapper showDivider={i !== vehicles.length - 1}>
                  <VehicleAvatar
                    color={v.appSettings?.color}
                    icon={v.appSettings?.icon}
                    size="l"
                  />
                  <VehicleInfoWrapper>
                    <StyledWrapper mb={0} maxWidth={230}>
                      <TrunckatedText
                        numberOfLines={1}
                        {...testIdentifiers(
                          `vehicle-name-${v.licensePlate?.plateText}`
                        )}>
                        {v.name || v.licensePlate?.plateText}
                      </TrunckatedText>
                    </StyledWrapper>
                    <CenterVerticalRow>
                      <VehicleLicensePlate licensePlate={v.licensePlate} />
                      <Meta color={theme.colors.gray200}>
                        {featureCountry === 'FI'
                          ? ` ・ ${getPermitsAmount(
                              permits,
                              v.licensePlate?.plateText
                            )} permits`
                          : ''}
                      </Meta>
                    </CenterVerticalRow>
                  </VehicleInfoWrapper>
                  <IconWrapper>
                    <Icon name="Chevron_Right" size="s" />
                  </IconWrapper>
                </VehicleWrapper>
              </Pressable>
            ))}
          <VehicleAddSection onPress={navigateToAddVehicle} style="BUTTON" />
        </StyledWrapper>
      </SheetDetailsWrapper>
    </ScrollView>
  );
};

export default MyVehiclesScreen;
