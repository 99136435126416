import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldInput, Meta, theme } from 'aimo-ui';
import { MAX_LICENSE_PLATE_LENGTH } from 'utils/vehicleUtils';
import styled from 'styled-components/native';
import Icon from 'components/icons/Icon';

type LicensePlateInputProps = {
  licensePlate: string;
  setLicensePlate: React.Dispatch<React.SetStateAction<string>>;
  showError?: boolean;
  disabled?: boolean;
  onBlur?: (licensePlate: string) => void;
  showInvalidFormatInfo?: boolean;
};

const StyledMeta = styled(Meta)`
  margin: 0;
  padding-top: 5px;
  color: ${theme.colors.gray200};
`;

const InfoIcon = () => (
  <Icon name="Info-Filled" size="s" color={theme.colors.lightBg.navyBlue} />
);

const LicensePlateErrorIcon = () => (
  <Icon name="Info-Filled" size="s" color={theme.colors.aimoUiRed} />
);

const disabledStyle = {
  color: theme.colors.gray200,
};

const VehicleLicensePlateInput = ({
  licensePlate,
  setLicensePlate,
  showError,
  disabled = false,
  onBlur,
  showInvalidFormatInfo = false,
}: LicensePlateInputProps) => {
  const { t } = useTranslation();

  const licensePlateErrorMessage = useMemo(
    () =>
      t('onboarding.licensePlateValidationError', {
        maxLicensePlateLength: MAX_LICENSE_PLATE_LENGTH,
      }),
    [t]
  );

  const handleOnBlur = () => {
    const licensePlateTrimmed = licensePlate.replaceAll(/-|\s/g, '');
    setLicensePlate(licensePlateTrimmed);
    onBlur?.(licensePlateTrimmed);
  };

  return (
    <>
      <FieldInput
        title={t('onboarding.licensePlate')}
        value={licensePlate}
        placeholder={t('onboarding.enterLicense')}
        placeholderTextColor={theme.colors.gray200}
        onChangeText={setLicensePlate}
        testID="license-plate"
        autoCapitalize="characters"
        errorMessage={showError ? licensePlateErrorMessage : undefined}
        disabled={disabled}
        errorIcon={<LicensePlateErrorIcon />}
        style={disabled ? disabledStyle : null}
        maxLength={MAX_LICENSE_PLATE_LENGTH}
        onBlur={handleOnBlur}
      />
      {disabled && (
        <StyledMeta>{t('onboarding.licensePlateNotEditable')}</StyledMeta>
      )}
      {showInvalidFormatInfo && (
        <StyledMeta testID="license-plate-regexp">
          <Meta color={theme.colors.gray200} iconComponent={InfoIcon}>
            {t('onboarding.licensePlateNotMatching')}
          </Meta>
        </StyledMeta>
      )}
    </>
  );
};

export default React.memo(VehicleLicensePlateInput);
