import React, { useMemo, useState } from 'react';
import { Headline3, ImageViewer, theme } from 'aimo-ui';
import Sheet from 'components/sheets/Sheet';
import { BackButton } from 'components/map/MapButtons';
import ParkingSpaceSelector from 'components/park/longTermParking/ParkingSpaceSelector';
import { SheetDetailsWrapper, StyledWrapper } from 'styles/ContainerStyles';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { selectedParkingSpace } from 'states/common';
import { RouteProp, useRoute } from '@react-navigation/native';
import { RootStackParamList } from 'types/navigation';
import useIsDesktop from 'hooks/useIsDesktop';
import useSafeContainerDimension from 'hooks/useSafeContainerDimension';
import { isWeb } from 'utils/deviceUtils';
import { useWindowDimensions } from 'react-native';
import { testIdentifiers } from 'utils/testIdentifiers';

const ParkingSpaceMapViewerScreen: React.FC = () => {
  const parkingSpace = useReactiveVar(selectedParkingSpace);
  const [heightRatio, setRatioScale] = useState<number>();
  const { t } = useTranslation();
  const { params } =
    useRoute<RouteProp<RootStackParamList, 'parkingSpaceMapViewer'>>();
  const isDesktop = useIsDesktop();
  const { notchPercentage } = useSafeContainerDimension();
  const { height } = useWindowDimensions();
  const [hideSheet, setHideSheet] = useState(false);

  const spotMapUrl = params?.spotMap;

  const snappingPoints = useMemo(() => {
    if (!spotMapUrl) {
      return ['80%'];
    }
    const calculatedHeightPercentage = heightRatio
      ? 100 - heightRatio * (50 - notchPercentage)
      : 50;
    return [
      `${calculatedHeightPercentage < 25 ? 25 : calculatedHeightPercentage}%`,
    ];
  }, [heightRatio, spotMapUrl, notchPercentage]);

  const webSheetMaxHeight = useMemo(() => {
    if (isWeb && heightRatio && spotMapUrl) {
      const calculatedHeightPercentage = (100 - heightRatio * 50) / 100;
      return height * calculatedHeightPercentage;
    }
  }, [height, heightRatio, spotMapUrl]);

  return (
    <>
      {spotMapUrl && (
        <ImageViewer
          onRatioScaleCalculated={setRatioScale}
          imageUrl={spotMapUrl}
          isDesktop={isDesktop}
          onDoubleTap={() => isWeb && window.open(spotMapUrl, '_blank')}
          fullScreen={!!params.showOnlyMap}
          onPressHold={setHideSheet}
        />
      )}
      <BackButton testID="parking-space-back-button" />
      {!params?.showOnlyMap && !(hideSheet && isDesktop) && (
        <Sheet
          bgColor={theme.colors.white}
          showBackDropIndex={1}
          sheetMaxHeight={webSheetMaxHeight}
          snappingPoints={snappingPoints}>
          <SheetDetailsWrapper>
            <StyledWrapper center>
              <Headline3 center {...testIdentifiers('parking-space-headline')}>
                {parkingSpace?.name ?? t('longTermParking.selectParkingSpace')}
              </Headline3>
            </StyledWrapper>
            <ParkingSpaceSelector />
          </SheetDetailsWrapper>
        </Sheet>
      )}
    </>
  );
};

export default ParkingSpaceMapViewerScreen;
