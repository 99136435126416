import { gql } from '@apollo/client';

export const READ_CITY_PMCS = gql`
  query ReadCityPmcs($input: CityPmcsInput!) {
    readCityPmcs(input: $input) {
      content {
        id
        name
        physicalZoneUid
        customerGroupUids
        reservationType
        countryCode
        prices {
          validity {
            validFrom
          }
          monthlyPrice
          thirtyDayPrice
        }
      }
    }
  }
`;
