import { useReactiveVar } from '@apollo/client';
import {
  BottomButtonsLayout,
  Button,
  Headline3,
  HorizontalRadioList,
  HorizontalRadioListData,
  List,
} from 'aimo-ui';
import VehicleFilterItem from 'components/account/vehicle/VehicleFilterItem';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useParkingHistoryFilter, {
  ParkingHistoryFilters,
  ParkingTypeFilter,
  PaymentFilter,
} from 'hooks/useParkingHistoryFilter';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parkingTypesVisible, sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import { Vehicle } from 'types/generatedSchemaTypes';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const Headline3Styled = styled(Headline3)`
  margin-bottom: 20px;
`;
const StyledList = styled(List)`
  min-height: 150px;
`;
type VehicleSelectListProps = {
  vehicles: Vehicle[];
  filter?: Vehicle;
  onPress?: (item: { item: Vehicle; selected: boolean }) => void;
};
const VehicleSelectList = ({
  vehicles,
  filter,
  onPress,
}: VehicleSelectListProps) => (
  <StyledList
    listData={vehicles}
    onPress={onPress}
    listElement={({ item, indexValue, ...props }) => (
      <VehicleFilterItem
        {...props}
        indexValue={indexValue}
        item={item as any}
        selected={
          filter?.licensePlate?.plateText ===
          (item as Vehicle).licensePlate?.plateText
        }
        onPress={onPress}
        testID="vehicle-list-item"
      />
    )}
  />
);

const ParkingHistoryFilterModalContent = () => {
  const { DEFAULT_OVERFLOW } = HorizontalRadioList;
  const {
    filter,
    clearFilter,
    setFilter,
    parkingTypesFilterOptions,
    paymentFilterOptions,
  } = useParkingHistoryFilter();
  const [filterUiState, setFilterUiState] =
    useState<ParkingHistoryFilters>(filter);
  const { t } = useTranslation();
  const {
    flags: { payment_type_filter },
  } = useContext(FeatureFlagsContext);
  const showPaymentTypeFilter = showFeatureFlag(payment_type_filter);
  const showParkingTypeSelection = useReactiveVar(parkingTypesVisible);
  const {
    customer: { vehicles },
  } = useContext(AccountContext);

  const handleOnSelectedPayment = (
    newValue: HorizontalRadioListData<PaymentFilter>
  ) => {
    setFilterUiState({ ...filterUiState, payment: newValue.value });
  };

  const handleOnSelectedParkingType = (
    newValue: HorizontalRadioListData<ParkingTypeFilter>
  ) => {
    setFilterUiState({ ...filterUiState, parkingType: newValue.value });
  };

  const handleOnSelectedVehicle = ({
    item,
    selected,
  }: {
    item: Vehicle;
    selected: boolean;
  }) => {
    if (!selected) {
      setFilterUiState({ ...filterUiState, vehicle: undefined });
      return;
    }
    // license plate is potentially not unique cross country borders
    setFilterUiState({ ...filterUiState, vehicle: item });
  };
  const handleApplyFilter = () => {
    setFilter(filterUiState);
    sheetModal('');
  };
  const handleResetFilter = () => {
    clearFilter();
    setFilterUiState(undefined);
    sheetModal('');
  };

  return (
    <BottomButtonsLayout
      scrollable={false}
      contentBottom={
        <>
          <Button
            {...testIdentifiers('parking-history-apply-filter')}
            text={t('button.apply')}
            size="l"
            buttonType="secondary"
            onPress={handleApplyFilter}
          />
          <ColumnWithMargins mt={20}>
            <Button
              testID="parking-history-clear-filter"
              text={t('button.clearFilter')}
              size="l"
              buttonType="tertiary"
              onPress={handleResetFilter}
            />
          </ColumnWithMargins>
        </>
      }>
      {showParkingTypeSelection && (
        <StyledWrapper mb={40}>
          <Headline3Styled>
            {t('parkingHistoryFilter.paymentTypes')}
          </Headline3Styled>
          <HorizontalRadioList
            selectedValue={filterUiState?.parkingType}
            listData={parkingTypesFilterOptions}
            overflow={DEFAULT_OVERFLOW}
            onSelected={handleOnSelectedParkingType}
          />
        </StyledWrapper>
      )}

      {showPaymentTypeFilter && (
        <StyledWrapper mb={40}>
          <Headline3Styled>{t('parkingHistoryFilter.payment')}</Headline3Styled>
          <HorizontalRadioList
            selectedValue={filterUiState?.payment}
            listData={paymentFilterOptions}
            overflow={DEFAULT_OVERFLOW}
            onSelected={handleOnSelectedPayment}
          />
        </StyledWrapper>
      )}
      <Headline3Styled>{t('parkingHistoryFilter.cars')}</Headline3Styled>
      <VehicleSelectList
        vehicles={vehicles || []}
        onPress={handleOnSelectedVehicle}
        filter={filterUiState?.vehicle}
      />
    </BottomButtonsLayout>
  );
};

export default ParkingHistoryFilterModalContent;
