import React from 'react';
import { BaseText, Headline1, theme } from 'aimo-ui';
import { StyledImage, StyledWrapper } from 'styles/ContainerStyles';

interface PermitTicketHeaderProps {
  title: string;
  subtitle?: string;
  testID?: string;
}

const PermitTicketHeader: React.FC<PermitTicketHeaderProps> = ({
  title,
  subtitle,
  testID,
}) => {
  return (
    <>
      <StyledWrapper mb={theme.normalising.heightPixel(25)}>
        <StyledImage
          center
          width={114}
          height={98}
          source={require('assets/images/permit.png')}
          testID={`${testID}-image`}
        />
      </StyledWrapper>
      <StyledWrapper center mb={theme.normalising.heightPixel(20)}>
        <Headline1 center testID={`${testID}-title`}>
          {title}
        </Headline1>
        {subtitle && (
          <BaseText
            center
            testID={`${testID}-info`}
            color={theme.colors.gray300}>
            {subtitle}
          </BaseText>
        )}
      </StyledWrapper>
    </>
  );
};

export default PermitTicketHeader;
