import { theme } from 'aimo-ui';
import ContactUsContent from 'components/account/contactUs/ContactUsContent';
import Sheet from 'components/sheets/Sheet';
import React from 'react';
import { Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {
  ColumnWithMargins,
  ScreenContainer,
  SheetDetailsWrapper,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { isWeb, isIosMax } from 'utils/deviceUtils';

const { height } = Dimensions.get('window');

const ContactWrapper = () => {
  return (
    <StyledWrapper mb={20} center={isWeb} maxWidth={isWeb ? 600 : undefined}>
      <ContactUsContent />
    </StyledWrapper>
  );
};

const ContactSheet = () => {
  const snappingPoints = isIosMax ? [height - 180] : [height - 150];

  if (isWeb) {
    return (
      <ScrollView>
        <ColumnWithMargins mt={30}>
          <ContactWrapper />
        </ColumnWithMargins>
      </ScrollView>
    );
  }

  return (
    <Sheet snappingPoints={snappingPoints} hideHandle>
      <SheetDetailsWrapper pt={35} resetPadding>
        <ScrollView>
          <ContactWrapper />
        </ScrollView>
      </SheetDetailsWrapper>
    </Sheet>
  );
};


const ContactUs: React.FC = () => {
  return (
    <ScreenContainer
      background={isWeb ? theme.colors.white : theme.colors.lightBg.orange}
      mt={isWeb ? 190 : 0}>
      <ContactSheet />
    </ScreenContainer>
  );
};

export default ContactUs;
