import { selectedParkScreenQuickAccessItem } from 'states/common';

export type QuickAccessType = 'PARKING' | 'CHARGING';

export const quickAccessData = [
  {
    title: 'quickAction.parking',
    iconName: 'Parking',
    type: 'PARKING',
  },
  {
    title: 'quickAction.charging',
    iconName: 'Charging',
    type: 'CHARGING',
  },
] as { title: string; iconName: string; type: QuickAccessType }[];

export const handleQuickAccess = (indexValue: number) => {
  const accessType = quickAccessData[indexValue].type;
  selectedParkScreenQuickAccessItem(
    selectedParkScreenQuickAccessItem() === accessType ? undefined : accessType
  );
};
