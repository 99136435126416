import { cloneDeep } from 'lodash';
import { sheetModal } from 'states/common';
import { tutorialCompletion } from 'states/persistInStorage';
import { Tutorial, TutorialCompletion } from 'types/states';
import { isWeb } from './deviceUtils';

export const defaultCompletion: TutorialCompletion = {
  firstTimer: { status: 'NOT_COMPLETED' },
};

export const openTutorial = (tutorial: keyof TutorialCompletion) => {
  const tutorialStatus = tutorialCompletion() ?? defaultCompletion;
  if (tutorialStatus[tutorial]?.status) {
    tutorialStatus[tutorial].status = 'OPEN';
  } else {
    tutorialStatus[tutorial] = { status: 'OPEN' };
  }
  tutorialCompletion(cloneDeep(tutorialStatus));
  isWeb && sheetModal(tutorial);
};

export const completeTutorial = (tutorial: keyof TutorialCompletion) => {
  const tutorialStatus = tutorialCompletion() ?? defaultCompletion;
  if (tutorialStatus[tutorial]?.status) {
    tutorialStatus[tutorial].status = 'COMPLETED';
  } else {
    tutorialStatus[tutorial] = { status: 'COMPLETED' };
  }
  tutorialCompletion(cloneDeep(tutorialStatus));
};

export const getTutorials = () => {
  return Object.keys(tutorialCompletion()).map((key) => key as Tutorial);
};
