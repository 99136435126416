import {
  EvseConnectorType,
  VehicleCountryCode,
  VehicleType,
} from 'types/generatedSchemaTypes';

export const MAX_LICENSE_PLATE_LENGTH = 12;

export const getVehicleLabel = (name: string, licensePlate: string): string => {
  if (name) {
    return `${name} (${licensePlate})`;
  }
  return licensePlate;
};
export const validateLicensePlate = (licensePlate?: string): boolean =>
  !!licensePlate &&
  licensePlate.trim().length >= 2 &&
  licensePlate.length <= MAX_LICENSE_PLATE_LENGTH;

export const isLicensePlateFormatValid = (
  licensePlate?: string,
  countryCode?: VehicleCountryCode
) => {
  switch (countryCode) {
    case 'S': {
      const regexp = new RegExp(/^([A-Z]{3})(\s?)(\d{2}[A-Z\d])$/); // 3 uppercase letters, optional space, 2 numbers then 1 number OR letter
      return regexp.test(licensePlate || '');
    }
    case 'FIN':
    case 'N':
    default:
      return true;
  }
};

export const colors = [
  '#FFFFFF',
  '#000000',
  '#BEBEBE',
  '#5B5B5B',
  '#E6E6E6',
  '#EA0606',
  '#044C8F',
  '#5AA7EE',
  '#6A4029',
  '#0C991B',
  '#D4BE9E',
  '#FC9E0F',
  '#FFDF39',
  '#8903C9',
];

export const vehicleIcons = [
  'vehicle-suv',
  'vehicle-sedan',
  'vehicle-hatchback',
  'vehicle-van',
  'vehicle-truck',
  'vehicle-cab',
  'vehicle-motorcycle',
  'vehicle-trailer',
];

export const vehicleCountries: VehicleCountryCode[] = [
  'FIN',
  'S',
  'N',
  'EST',
  'DK',
  'LT',
  'LR',
];

export const vehicleChargerPlugTypes: EvseConnectorType[] = [
  'CHADEMO',
  'IEC_62196_T1',
  'IEC_62196_T1_COMBO',
  'IEC_62196_T2',
  'IEC_62196_T2_COMBO',
];

export const vehicleTypes: { id: VehicleType; icon: string }[] = [
  {
    id: 'REGULAR',
    icon: 'Car-Outlined',
  },
  {
    id: 'ELECTRIC',
    icon: 'Charging',
  },
];
