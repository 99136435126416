import { gql, useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/core';
import { showToast } from 'components/common/CustomToast';
import AccountContext from 'context/AccountContext';
import { RETRY_CHARGING_PAYMENT } from 'graphql/mutations/retryChargingPayment';
import { useContext } from 'react';
import {
  Mutation,
  MutationRetryFailedPaymentV2Args,
} from 'types/generatedSchemaTypes';
import { SetParamsProps } from 'types/navigation';
import { updateCache } from 'utils/cacheUtils';

type RetryChargingPaymentProps = {
  chargingSessionId?: string;
};
const useRetryChargingPaymentMutation = ({
  chargingSessionId,
}: RetryChargingPaymentProps) => {
  const { refetchCustomer } = useContext(AccountContext);
  const { setParams }: SetParamsProps = useNavigation();
  const [retryChargingPayment, { loading: retryPaymentLoading }] = useMutation<
    Mutation,
    MutationRetryFailedPaymentV2Args
  >(RETRY_CHARGING_PAYMENT, {
    update: (cache, { data }) => {
      const { transactionId } = data?.retryFailedPaymentV2 ?? {};
      if (transactionId) {
        setParams({ transactionId });
        updateCache(
          cache,
          { __typename: 'ChargingSession', chargingSessionId },
          {
            fragment: gql`
              fragment ChargingSessionFragment on ChargingSession {
                transactionId
              }
            `,
            data: { transactionId },
          }
        );
      }
    },
    onCompleted: async () => {
      showToast('parking.receipt.retrySuccessfulDescription', 'success');
    },
    onError: (error) => {
      if (
        error.graphQLErrors.some((e) => e.extensions?.code === 'CARD_BLOCKED')
      ) {
        showToast('error.cardBlocked', 'info');
        refetchCustomer();
      } else {
        showToast('parking.receipt.retryUnsuccessfulDescription', 'error');
      }
    },
  });
  return { retryChargingPayment, retryPaymentLoading };
};

export default useRetryChargingPaymentMutation;
