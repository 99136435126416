import { Meta, theme } from 'aimo-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View } from 'react-native';
import styled from 'styled-components/native';
import { AddressProps, Maybe } from 'types/generatedSchemaTypes';
import { navigateWithCoordinates } from 'utils/mapUtils';

const MetaShrink = styled(Meta)<{ isLink?: boolean; color?: string }>`
  flex-shrink: 1;
  color: ${(p) => (p.isLink ? theme.colors.link : p.color)};
  text-decoration: ${(p) => (p.isLink ? 'underline' : 'none')};
  text-decoration-color: ${(p) => (p.isLink ? theme.colors.link : p.color)};
`;

export const printAddress = (
  address: AddressProps,
  defaultName = '',
  includeCity = true
): string => {
  const { street, number, city } = address;
  if (street) {
    const streetPart = `${street} ${number || ''}`;
    const cityPart = city && includeCity ? `, ${city}` : '';
    return `${streetPart}${cityPart}`;
  }
  return defaultName;
};

type AddressAndLocationProps = {
  address?: Maybe<AddressProps>;
  color?: string;
  name?: string;
  locationText?: string | null;
  numberOfLines?: number;
  testID?: string;
  addressAsLink?: boolean;
  includeCity?: boolean;
};
const AddressAndLocation = ({
  address,
  locationText,
  color,
  name,
  numberOfLines,
  testID,
  addressAsLink = false,
  includeCity,
}: AddressAndLocationProps) => {
  const { t } = useTranslation();
  const { marker } = address || {};
  const addressString = printAddress(
    address || {},
    t('parkingZone.noAddress'),
    includeCity
  );

  const onPress = () => {
    addressAsLink && navigateWithCoordinates(marker?.coordinates, name);
  };

  const Wrapper = addressAsLink ? Pressable : View;

  return (
    <Wrapper onPress={onPress}>
      <MetaShrink
        isLink={addressAsLink}
        color={color ?? theme.colors.black}
        numberOfLines={numberOfLines}
        testID={testID}>
        {addressString}
        {locationText && ` • ${locationText}`}
      </MetaShrink>
    </Wrapper>
  );
};

export default AddressAndLocation;
