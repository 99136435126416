import { useLazyQuery } from '@apollo/client';
import {
  Query,
  QueryGetPoolingGroupCapacityArgs,
} from 'types/generatedSchemaTypes';
import { GET_POOLING_GROUP_CAPACITY } from '@queries/getPoolingGroupCapacity';

const useLoadFreePoolingSpots = (poolingGroupId?: string) => {
  const [loadFreePoolingSpots, { loading, data }] = useLazyQuery<
    Query,
    QueryGetPoolingGroupCapacityArgs
  >(GET_POOLING_GROUP_CAPACITY, {
    fetchPolicy: 'network-only',
    variables: poolingGroupId
      ? {
          poolingGroupId: poolingGroupId,
        }
      : undefined,
  });
  return { loadFreePoolingSpots, loading, data };
};

export default useLoadFreePoolingSpots;
