import { gql } from '@apollo/client';

export const GET_LTP_PARK_ZONES = gql`
  query parkZonesWithLTP($countryCode: CountryCode) {
    parkZonesWithLTP(countryCode: $countryCode) {
      content {
        uid
        name
        locationType
        commerciallyActive
        address {
          marker
          street
          number
          city
        }
        commercialZoneView {
          zoneCode
          name
        }
        area
        parkingMethods {
          anpr
        }
        visibility
        serviceChannelCodes
      }
      pmcVisibilities {
        parkingZoneUid
        pmcId
        visibility
        hasCreditCardPayment
      }
    }
  }
`;
