import { useQuery, useReactiveVar } from '@apollo/client';
import { READ_CHARGING_SESSIONS } from 'graphql/queries/readChargingSessions';
import { READ_PARKING_SESSIONS } from 'graphql/queries/readParkingSessions';
import { useEffect, useMemo, useState } from 'react';
import { parkingHistoryViewFilter } from 'states/common';
import {
  Query,
  QueryReadHistoryChargingSessionsV2Args,
  QueryReadParkingSessionsArgs,
  ReadParkingSessionsInput,
  SessionPaymentState
} from 'types/generatedSchemaTypes';
import { updateParkingQuery } from 'utils/parkUtils';
import useParkingHistoryFilter from './useParkingHistoryFilter';

const useReadFilteredSessions = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { vehicle: vehicleFilter } = useParkingHistoryFilter();

  const historyView = useReactiveVar(parkingHistoryViewFilter);
  const { licensePlate } = vehicleFilter ?? {};

  const resetPage = () => setCurrentPage(0);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const isFree = historyView === 'free';
  const isBilling = historyView === 'billing';
  const isOther = historyView === 'other';
  let paymentState: SessionPaymentState[];
  if (isFree) {
    paymentState = ['Success'];
  } else if (isBilling) {
    paymentState = ['Billing', 'Billed'];
  } else if (isOther) {
    paymentState = ['NotApplicable'];
  } else {
    paymentState = ['Failed', 'Retrying'];
  }

  const pagingInput: ReadParkingSessionsInput = {
    parkingState: ['Stopped'],
    accessDeviceValue: licensePlate?.plateText,
    sessionType: ['ANPR', 'SMS', 'START_STOP'],
    paymentState: paymentState,
    page: currentPage,
    size: isFree ? 25 : 10,
  };

  const input: ReadParkingSessionsInput = {
    ...pagingInput,
    page: 0,
  };

  const { data, loading, error, refetch, fetchMore } = useQuery<
    Query,
    QueryReadParkingSessionsArgs
  >(READ_PARKING_SESSIONS, {
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const { data: failedChargingSessions } = useQuery<
    Query,
    QueryReadHistoryChargingSessionsV2Args
  >(READ_CHARGING_SESSIONS, {
    variables: {
      input: {
        licensePlate: licensePlate?.plateText,
        paymentStates: ['Failed', 'Retrying'],
        page: 0,
        size: 100,
      },
    },
    skip: isBilling,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    resetPage();
    refetch({
      input,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, licensePlate]);

  useEffect(() => {
    if (currentPage !== 0) {
      fetchMore({
        variables: {
          input: pagingInput,
        },
        updateQuery: updateParkingQuery,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const fetchMoreCallback = () => {
    if (!data?.readParkingSessions?.last) {
      nextPage();
    }
  };

  const parkingSessions = useMemo(() => {
    // If the history view is 'free', filter out sessions where total amount is not zero
    if (isFree) {
      return data?.readParkingSessions?.content?.filter(
        (item) => item?.price?.totalAmount === 0
      );
    }

    // If the history view is 'other', filter out sessions where price is not null
    if (isOther) {
      return data?.readParkingSessions?.content?.filter(
        (item) => item?.poolingInfo?.pooled
      );
    }

    // If the history view is neither 'free' nor 'other', return all sessions
    return data?.readParkingSessions?.content;
  }, [isFree, isOther, data?.readParkingSessions?.content]);

  const chargingSessions = useMemo(
    () =>
      !isFree
        ? failedChargingSessions?.readHistoryChargingSessionsV2?.content
        : failedChargingSessions?.readHistoryChargingSessionsV2?.content?.filter(
            (item) => item?.priceInCents === 0
          ),
    [isFree, failedChargingSessions?.readHistoryChargingSessionsV2?.content]
  );

  return {
    sessionsData: parkingSessions || [],
    chargingSessions: chargingSessions || [],
    sessionsLoading: loading,
    isLast: data?.readParkingSessions?.last,
    error,
    fetchMoreCallback,
    resetPage,
    sessionsRefetch: refetch,
  };
};

export default useReadFilteredSessions;
