import { Meta, ProgressIndicatorCircle, Spinner, theme } from 'aimo-ui';
import { format, isToday, isTomorrow } from 'date-fns';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components/native';
import { Maybe, SalesOrderStatus } from 'types/generatedSchemaTypes';
import { getRemainingDays } from 'utils/timeUtils';

const ProgressIndicatorWrapper = styled.View`
  margin-left: 10px;
  margin-right: 5px;
`;

const WARNING_DAYS = 3;

type RemainingValidityIndicatorProps = {
  permitStatus: Maybe<SalesOrderStatus> | undefined;
  expirationDate: Maybe<string> | undefined;
  permitId: string;
  isRecurring: boolean;
  isPrepaid?: boolean;
  isWaiting?: boolean;
  predefinedEndDate?: Maybe<string>;
};

const RemainingValidityIndicator = ({
  permitId,
  permitStatus,
  expirationDate,
  isRecurring,
  isPrepaid = false,
  isWaiting,
  predefinedEndDate,
}: RemainingValidityIndicatorProps) => {
  const expirationTime = new Date(expirationDate ?? '');
  const remainingDays = getRemainingDays(expirationTime);
  let imminentRenewal;
  if (isToday(expirationTime)) {
    imminentRenewal = t('time.todaySmall');
  } else if (isTomorrow(expirationTime)) {
    imminentRenewal = t('time.tomorrowSmall');
  }
  let remainingValidity: string;

  const dateFormat = isPrepaid ? 'HH:mm' : '';

  const formatDate = (dateString: Maybe<string> | undefined) =>
    dateString ? format(new Date(dateString), `d.M.y ${dateFormat}`) : '';

  const statusColor = useMemo(() => {
    if (isRecurring) {
      return theme.colors.boost.green;
    }
    if (!isRecurring && remainingDays <= WARNING_DAYS) {
      return theme.colors.boost.red;
    }

    return theme.colors.black;
  }, [isRecurring, remainingDays]);

  switch (permitStatus) {
    case 'TERMINATING':
      remainingValidity = t('permit.terminatesAutomatically', {
        value: imminentRenewal ?? t('permit.inDays', { count: remainingDays }),
      });
      break;
    case 'EXPIRED':
      remainingValidity = t('permit.expired');
      break;
    case 'ACTIVATING':
      remainingValidity = '';
      break;
    default:
      if (predefinedEndDate) {
        remainingValidity = t('time.ends', {
          endDate: formatDate(predefinedEndDate),
        });
        break;
      }
      if (isWaiting) {
        remainingValidity = '';
        break;
      }
      remainingValidity =
        isRecurring && !isPrepaid
          ? t('permit.renewsAutomatically', {
              value:
                imminentRenewal ?? t('permit.inDays', { count: remainingDays }),
            })
          : formatDate(expirationDate);
      break;
  }

  if (permitStatus === 'ACTIVATING' || permitStatus === 'UPDATING') {
    return <Spinner size={20} />;
  }

  return (
    <>
      <Meta
        testID={`${isPrepaid ? 'prepaid' : 'permit'}-${permitId}-renewal`}
        color={statusColor}>
        {remainingValidity}
      </Meta>
      {permitStatus !== 'EXPIRED' && isRecurring && !isWaiting && (
        <ProgressIndicatorWrapper>
          <ProgressIndicatorCircle
            color={statusColor}
            totalAmount={30}
            filledAmount={remainingDays}
            small
          />
        </ProgressIndicatorWrapper>
      )}
    </>
  );
};

export default RemainingValidityIndicator;
