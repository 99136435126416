import { gql } from '@apollo/client';

export const READ_RECEIPT_BY_TRANSACTION_ID = gql`
  query ReadReceiptByTransactionId($transactionId: ID!) {
    readReceiptByTransactionId(transactionId: $transactionId) {
      items {
        amountCents
        endTime
        startTime
        type
        licensePlate
        vatAmount
        vatPercentage
        amountExclVAT
        parkTargetId
        energyConsumedWatts
        parkingSessionId
        zoneName
        zoneCode
        zoneUid
      }
      countryCode
      id
      creditCard {
        cardId
      }
      totalAmountCents
      transactionId
      created
    }
  }
`;
