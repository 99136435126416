import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Headline3, theme } from 'aimo-ui';
import { SheetModalProps } from 'components/sheets/SheetModal';
import SvgImage from 'components/svgImage/SvgImage';
import useIsMobile from 'hooks/useIsMobile';
import useTutorialSheet from 'hooks/useTutorialSheet';
import { GestureResponderEvent, Modal } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { activeElement, sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { SetParamsProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { handleElPress } from 'utils/mapUtils';
import CloseButton from '../common/CloseButton';

const ContentView = styled.Pressable<{
  isMobile: boolean;
  isWeb: boolean;
}>`
  ${(p) => (p.isWeb ? 'cursor: default' : undefined)};
  ${(p) =>
    p.isWeb ? `max-height: ${!p.isMobile ? '80' : '100'}vh` : undefined};
  border-radius: ${({ isMobile }) => (!isMobile ? '20px' : 0)};
  width: ${({ isMobile }) => (!isMobile ? '500px' : '100%')};
  max-width: 600px;
  background-color: ${(p) => p.theme.colors.white};
  ${(p) => (p.isWeb ? 'overflow: auto' : undefined)};
  justify-content: flex-start;
  min-height: 190px;
  padding: 20px 20px 0 20px;
`;

const OverlayView = styled.Pressable<{
  isWeb: boolean;
  backdropOpacity: number;
}>`
  ${(p) => (p.isWeb ? 'cursor: default' : undefined)};
  border-radius: 0;
  background-color: rgba(
    1,
    1,
    1,
    ${(p) => (p.backdropOpacity ? p.backdropOpacity : 1)}
  );
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const TopLineWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-bottom: 30px;
`;

const HeadlineWrapper = styled.View`
  flex: 1;
  align-items: center;
  margin: 0 30px 0 50px;
`;

const ContentWrapper = styled.View`
  margin-bottom: 40px;
`;

const RNWebModal = ({
  headline,
  backDropOpacity = 0.25,
  subtitle,
  children,
  allowScrollableContent,
  forceOpen = false,
  hideCloseButton = false,
}: SheetModalProps) => {
  const modalType = useReactiveVar(sheetModal);
  const isMobile = useIsMobile();
  const zoneId = useReactiveVar(activeElement);
  const { setParams }: SetParamsProps = useNavigation();
  const preventClose = (e: GestureResponderEvent) => {
    e.stopPropagation();
  };

  const { openedTutorial, onDismiss: onTutorialDismiss } = useTutorialSheet({});

  const closeModal = () => {
    sheetModal('');
    onTutorialDismiss();
    if (modalType === 'parkingAppLocation') {
      handleElPress(zoneId, { latitude: 0, longitude: 0 }, setParams);
    }
  };

  return (
    <Modal
      visible={!!modalType}
      animationType="fade"
      transparent={true}
      onDismiss={closeModal}
      statusBarTranslucent={true}
      onRequestClose={closeModal}
      testID="web-modal">
      <OverlayView
        testID="web-modal-overlay"
        onPress={() => {
          if (!forceOpen) {
            closeModal();
          }
        }}
        isWeb={isWeb}
        backdropOpacity={backDropOpacity}>
        <ContentView onPress={preventClose} isMobile={isMobile} isWeb={isWeb}>
          <TopLineWrapper>
            {openedTutorial && (
              <SvgImage name={'Tutorial_Car'} testID={'tutorial-car'} />
            )}
            <HeadlineWrapper>
              {headline && (
                <Headline3 center testID="modal-headline">
                  {headline}
                </Headline3>
              )}
              {!!subtitle && subtitle?.length > 0 && (
                <Headline3 center color={theme.colors.gray200}>
                  {subtitle}
                </Headline3>
              )}
            </HeadlineWrapper>
            {!hideCloseButton && (
              <CloseButton testID="modal-close-button" onPress={closeModal} />
            )}
          </TopLineWrapper>
          <ContentWrapper>
            {allowScrollableContent ? (
              <ScrollView>{children}</ScrollView>
            ) : (
              <>{children}</>
            )}
          </ContentWrapper>
        </ContentView>
      </OverlayView>
    </Modal>
  );
};

export default RNWebModal;
