import React from 'react';
import { Headline3, Meta, RedBeacon, theme } from 'aimo-ui';
import styled from 'styled-components/native';
import { testIdentifiers } from 'utils/testIdentifiers';
import { isWeb } from 'utils/deviceUtils';

type ChipCardItemTypes = {
  title: string;
  subTitle: string;
  backgroundColor?: string;
  circleImageBackgroundColor?: string;
  imageContainer?: () => JSX.Element;
  onPress: () => void;
  testID?: string;
  isWaiting?: boolean;
};

const CARD_HEIGHT = 90;

const ChipCardContainer = styled.TouchableOpacity<{ backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor || theme.colors.white};
  border-radius: 20px;
  margin-right: 20px;
  height: ${CARD_HEIGHT}px;
  ${!isWeb && `max-width: ${theme.normalising.widthPixel(330)}px;`}
  margin-top: 9px;
  justify-content: center;
`;

const Row = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  margin: 20px;
  margin-right: 60px;
  align-items: center;
`;

const ImageWrapper = styled.View<{
  backgroundColor?: string;
  showBorder: boolean;
}>`
  padding: 8px;
  border-radius: 25px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: ${({ showBorder }) => (showBorder ? 2 : 0)}px solid gray;
  ${(p) => p.showBorder && 'border-style: dashed'}
`;

const Column = styled.View`
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
`;

const ChipCardItem = ({
  title,
  subTitle,
  circleImageBackgroundColor = undefined,
  imageContainer: ImageContainer,
  backgroundColor = undefined,
  onPress,
  testID,
  isWaiting,
}: ChipCardItemTypes) => {
  return (
    <ChipCardContainer
      backgroundColor={backgroundColor}
      onPress={onPress}
      {...testIdentifiers(testID as string)}>
      <Row>
        {ImageContainer && (
          <ImageWrapper
            showBorder={!!isWaiting}
            testID={testID ? `${testID}-image-wrapper` : undefined}
            backgroundColor={circleImageBackgroundColor}>
            <ImageContainer />
          </ImageWrapper>
        )}
        <Column>
          <Headline3
            numberOfLines={2}
            testID={testID ? `${testID}-title` : undefined}>
            {title}
          </Headline3>
          <Meta
            color={isWaiting ? theme.colors.aimoUiRed : undefined}
            testID={testID ? `${testID}-subtitle` : undefined}>
            {subTitle}
          </Meta>
        </Column>
      </Row>
      {isWaiting && <RedBeacon translateY={-CARD_HEIGHT / 2 + 8} />}
    </ChipCardContainer>
  );
};

export default ChipCardItem;
