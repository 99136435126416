import React from 'react';
import { Meta, theme } from 'aimo-ui';
import { useTranslation } from 'react-i18next';

const ParkingZoneDetailsInfo = ({
  text,
  testID,
}: {
  text: string;
  testID: string;
}) => {
  const { t } = useTranslation();

  return (
    <Meta color={theme.colors.gray200} testID={testID}>
      {t(text)}
    </Meta>
  );
};

export default ParkingZoneDetailsInfo;
