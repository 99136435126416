import React, { useMemo } from 'react';
import { HorizontalRadioList, HorizontalRadioListData } from 'aimo-ui';
import { StyledWrapper } from 'styles/ContainerStyles';
import { useTranslation } from 'react-i18next';

export type vehicleTabsValue = 'DETAILS' | 'EXTRAS';

type VehicleFormTabsProps = {
  selectedVehicleTab: vehicleTabsValue;
  handleOnSelectedTab: (value: vehicleTabsValue) => void;
};

const VehicleFormTabs = ({
  selectedVehicleTab,
  handleOnSelectedTab,
}: VehicleFormTabsProps) => {
  const { t } = useTranslation();

  const vehicleTabs: HorizontalRadioListData<vehicleTabsValue>[] = useMemo(
    () => [
      {
        title: t('vehicle.form.tab.details'),
        value: 'DETAILS',
      },
      {
        title: t('vehicle.form.tab.extras'),
        value: 'EXTRAS',
      },
    ],
    [t]
  );

  return (
    <StyledWrapper mb={30}>
      <HorizontalRadioList
        selectedValue={selectedVehicleTab}
        listData={vehicleTabs}
        onSelected={(chosen) => handleOnSelectedTab(chosen.value)}
      />
    </StyledWrapper>
  );
};

export default VehicleFormTabs;
