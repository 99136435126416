import * as React from 'react';
import { Pmc } from 'types/generatedSchemaTypes';

export type LongTermParkingContextProps = {
  selectedPmcs?: Pmc[];
  setSelectedPmcs: React.Dispatch<React.SetStateAction<Pmc[] | undefined>>;
};

const LongTermParkingContext = React.createContext<LongTermParkingContextProps>(
  {
    selectedPmcs: undefined,
    setSelectedPmcs: () => undefined,
  }
);

export default LongTermParkingContext;
