import { useQuery } from '@apollo/client';
import { showToast } from 'components/common/CustomToast';
import { READ_SALES_ORDER_STATUS } from 'graphql/queries/readSalesOrderStatus';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { wasPermitTerminated } from 'states/common';
import {
  Query,
  QueryReadSalesOrderArgs,
  SalesOrderStatus,
} from 'types/generatedSchemaTypes';

const usePollSalesOrderStatus = (
  salesOrderId: string,
  orderStatus: SalesOrderStatus
) => {
  const { t } = useTranslation();
  const { startPolling, stopPolling } = useQuery<
    Query,
    QueryReadSalesOrderArgs
  >(READ_SALES_ORDER_STATUS, {
    variables: {
      salesOrderId,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: orderStatus === 'ACTIVE' || orderStatus === 'WAITING_FOR_ACTIVATION',
    onCompleted: (data) => {
      if (data.readSalesOrder?.orderStatus === 'ACTIVE') {
        if (wasPermitTerminated()) {
          wasPermitTerminated(false);
          return showToast(t('success.permitCancelled'), 'success');
        }
        return showToast(t('success.updatePermit'), 'success');
      }
    },
  });

  useEffect(() => {
    if (
      (orderStatus === 'ACTIVATING' || orderStatus === 'UPDATING') &&
      salesOrderId
    ) {
      startPolling(1500);
    } else {
      stopPolling();
    }
    return () => stopPolling();
  }, [orderStatus, salesOrderId, startPolling, stopPolling]);
};

export default usePollSalesOrderStatus;
