import { gql, useMutation } from '@apollo/client';
import {
  RouteProp,
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { Button, Meta, theme } from 'aimo-ui';
import ModalHeader from 'components/account/permit/ModalHeader';
import PermitCard from 'components/account/permit/PermitCard';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import { showToast } from 'components/common/CustomToast';
import InfoBox from 'components/common/InfoBox';
import Icon from 'components/icons/Icon';
import PaymentCardDropDown from 'components/park/detail/PaymentCardDropDown';
import VehicleSelectDropDown from 'components/park/detail/VehicleSelectDropDown';
import { Row } from 'components/park/styles/styles';
import Sheet from 'components/sheets/Sheet';
import { FooterButtonContainer } from 'components/sheets/SheetLayout';
import AccountContext from 'context/AccountContext';
import { UPDATE_SALES_ORDER } from 'graphql/mutations/updateSalesOrder';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  blockCompanybenefitInEdit,
  parkingPaymentCardId,
  parkingVehicleLicensePlate,
} from 'states/common';
import { CenterColumn, ColumnWithMargins } from 'styles/ContainerStyles';
import { VoidCallBack } from 'types/common';
import {
  BillingInterval,
  CountryCode,
  Mutation,
  MutationUpdateSalesOrderArgs,
  ParkZone,
  SalesOrderActionType,
  SalesOrderStatus,
  UpdateSalesOrderInput,
} from 'types/generatedSchemaTypes';
import { AccountStackParamList, RootNavProps } from 'types/navigation';
import { updateCache } from 'utils/cacheUtils';
import { currencyFormatter } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { SalesOrderReceipt } from './SalesOrderReceiptClass';

const expandedStyle = {
  flex: 1,
};

type SaveButtonProps = {
  onPress: VoidCallBack;
  disable?: boolean;
  loading: boolean;
};

const SaveButton = ({ onPress, disable = false, loading }: SaveButtonProps) => {
  const { t } = useTranslation();
  return (
    <ColumnWithMargins ml={20} mr={20} mb={15} style={expandedStyle}>
      <Button
        size="l"
        onPress={onPress}
        disabled={disable}
        loading={loading}
        text={t('permit.savePermit')}
        testID="edit-permit-button"
      />
    </ColumnWithMargins>
  );
};

const StickyFooter = ({
  disable,
  loading,
  confirmEditPermit,
  hasLicensePlate,
  hasCard,
  isWaiting,
  isInvoicing,
  freeSalesOrder,
}: {
  loading: boolean;
  disable: boolean;
  confirmEditPermit: () => void;
  hasLicensePlate: boolean;
  hasCard: boolean;
  isWaiting: boolean;
  isInvoicing: boolean;
  freeSalesOrder: boolean;
}) => {
  const { t } = useTranslation();
  const AddCardVehicleInfo = useCallback(() => {
    if (isWaiting && ((!hasCard && !freeSalesOrder) || !hasLicensePlate)) {
      return (
        <ColumnWithMargins mt={10} mr={20} ml={20}>
          <InfoBox
            text={t(
              `permit.${
                !hasCard && hasLicensePlate
                  ? 'infoAddPaymentMethod'
                  : 'infoAddCardVehicle'
              }`
            )}
            bgColor={theme.colors.infoYellow}
            padding={15}
            margin={0}
          />
        </ColumnWithMargins>
      );
    }
    return <></>;
  }, [hasCard, hasLicensePlate, isWaiting, freeSalesOrder, t]);

  return (
    <CenterColumn style={expandedStyle}>
      <AddCardVehicleInfo />
      <FooterButtonContainer pt={0} pb={0}>
        <VehicleSelectDropDown showInfoIconIfNotSelected />
        {isInvoicing ? (
          <InvoicingItem />
        ) : (
          <PaymentCardDropDown
            showInfoIconIfNotSelected
            isFreeSalesOrder={freeSalesOrder}
          />
        )}
      </FooterButtonContainer>
      <SaveButton
        disable={disable}
        onPress={confirmEditPermit}
        loading={loading}
      />
    </CenterColumn>
  );
};

const InvoicingItem = () => {
  const { t } = useTranslation();
  return (
    <Row>
      <ColumnWithMargins mr={10}>
        <Icon testID={'invoicing-icon'} name="Document-Outlined" size="l" />
      </ColumnWithMargins>
      <Meta>{t('paymentMethods.invoicing')}</Meta>
    </Row>
  );
};

const PermitEdit: React.FC = () => {
  const {
    params: { permitId },
  } = useRoute<RouteProp<AccountStackParamList, 'permitEdit'>>();
  const {
    customer: { permits },
  } = useContext(AccountContext);
  const salesOrder = permits?.find((sOrder) => sOrder?.id === permitId);

  const salesOrderReceipt = new SalesOrderReceipt(salesOrder);

  const {
    orderLines,
    renewal,
    countryCode,
    creditCardId,
    orderStatus,
    isInvoicing,
    isWaiting,
  } = salesOrderReceipt || {};
  const orderLine = orderLines?.[0];
  const {
    pmc,
    pmcId,
    orderLineId,
    billingFrequency,
    lineTotal,
    registrationPlates,
    quantity,
    version,
    parkingSpace,
  } = orderLine || {};
  const { parkZone, name } = pmc || {};
  const [isRecurring, setIsRecurring] = useState(renewal);
  const { goBack } = useNavigation<RootNavProps>();
  const { t } = useTranslation();
  const vehicle = useSelectedParkingVehicle();
  const paymentMethod = useSelectedPaymentMethod();

  const isFreeSalesOrder = lineTotal === 0;
  const [updateSalesOrder, { loading }] = useMutation<
    Mutation,
    MutationUpdateSalesOrderArgs
  >(UPDATE_SALES_ORDER, {
    update: (cache, { data }) => {
      if (permitId) {
        const id = permitId;
        const storeObject = { __typename: 'UnifiedPermit', id };
        updateCache(cache, storeObject, {
          fragment: gql`
            fragment UnifiedPermitFragment on UnifiedPermit {
              creditCardId
              renewal
              orderStatus
              paymentState
            }
          `,
          data: {
            creditCardId: data?.updateSalesOrder?.creditCardId,
            renewal: data?.updateSalesOrder?.renewal,
            orderStatus: data?.updateSalesOrder?.orderStatus,
            paymentState: data?.updateSalesOrder?.paymentState,
          },
        });
      }
    },
    onCompleted: ({ updateSalesOrder: editedSalesOrder }) => {
      if (editedSalesOrder?.paymentState === 'FAILED_PAY') {
        return showToast('permit.paymentFailed', 'error');
      }
      onBack();
    },
    onError: () => {
      showToast(t('error.updatePermit'), 'error');
      onBack();
    },
  });

  useFocusEffect(
    React.useCallback(() => {
      blockCompanybenefitInEdit('permitEdit');
      parkingVehicleLicensePlate(
        registrationPlates?.[0]?.plateText || undefined
      );
      parkingPaymentCardId(creditCardId || undefined);
      return () => {
        blockCompanybenefitInEdit('');
        parkingVehicleLicensePlate(undefined);
        parkingPaymentCardId(undefined);
      };
    }, [creditCardId, registrationPlates])
  );

  const { licensePlate } = vehicle || {};

  const { cardId } = paymentMethod || {};

  let totalAmount = 0;

  if (lineTotal) {
    totalAmount = lineTotal / 100;
  }

  const getSnappingPoints = () => {
    if (isWeb) {
      return ['75vh'];
    }
    return ['95%'];
  };

  const priceString = currencyFormatter(
    totalAmount,
    countryCode as CountryCode
  );

  const confirmEditPermit = () => {
    const updateInput = {
      salesOrderId: permitId,
      creditCardId: !isInvoicing ? cardId : undefined,
      renewal: isRecurring,
      registrationPlate: {
        plateText: licensePlate?.plateText || '',
        countryCode: licensePlate?.countryCode,
      },
      quantity,
      version,
      pmcId,
      orderLineId,
      hasChanges: isWaiting,
      ...(isWaiting && { validFrom: new Date() }),
      ...(isWaiting && { orderStatus: 'ACTIVATING' }),
      updateType: (isWaiting ? 'ACTIVATING' : 'PATCH') as SalesOrderActionType,
    } as UpdateSalesOrderInput;

    updateSalesOrder({
      variables: {
        input: updateInput,
      },
    });
  };

  const toggleRecurring = () => setIsRecurring((prevState) => !prevState);

  const onBack = () => goBack();

  return (
    <Sheet
      showBackDropIndex={0}
      snappingPoints={getSnappingPoints()}
      stickyFooter={
        <StickyFooter
          confirmEditPermit={confirmEditPermit}
          disable={
            loading ||
            !licensePlate?.plateText ||
            (!isInvoicing && !isFreeSalesOrder && !cardId)
          }
          loading={loading}
          hasCard={!!cardId}
          hasLicensePlate={!!licensePlate?.plateText}
          isWaiting={isWaiting}
          isInvoicing={isInvoicing}
          freeSalesOrder={isFreeSalesOrder}
        />
      }
      hideHandle={false}>
      <ColumnWithMargins
        mr={isWeb ? 0 : 20}
        ml={isWeb ? 0 : 20}
        style={expandedStyle}>
        <ModalHeader title={t('permit.editPermit')} onBack={onBack} />
        <BottomSheetScrollViewCustom bottomThreshold={0}>
          <PermitCard
            parkZone={parkZone as ParkZone}
            price={priceString}
            permitName={name || ''}
            setIsRecurring={toggleRecurring}
            testID="permit-edit-title-card"
            isRecurring={isRecurring || false}
            billingInterval={billingFrequency as BillingInterval}
            isInvoicing={isInvoicing}
            status={orderStatus as SalesOrderStatus}
            parkingSpaceName={parkingSpace?.name}
          />
        </BottomSheetScrollViewCustom>
      </ColumnWithMargins>
    </Sheet>
  );
};

export default PermitEdit;
