import React, { useCallback } from 'react';
import { StyledWrapper } from 'styles/ContainerStyles';
import { BaseText, ListItem, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import { CompanyBenefit, Maybe } from 'types/generatedSchemaTypes';
import { noop } from 'lodash';
import { TitleWrapper } from 'components/account/paymentMethods/PaymentMethodItem';

const IconComponent = () => <Icon name="Service-Filled" color={theme.colors.boost.green}/>;

const CompanyBenefitItem = ({
  item,
  indexValue,
}: {
  item: Maybe<CompanyBenefit>;
  indexValue: number;
}) => {

  const titleComponent = useCallback(() => (
    <TitleWrapper>
      <StyledWrapper mb={0} maxWidth={230}>
        <BaseText
          numberOfLines={1}
          testID={`credit-card-title-${indexValue}`}>
          {item?.name}
        </BaseText>
      </StyledWrapper>
    </TitleWrapper>
  ), [item, indexValue]);

  return (
    <ListItem
      item={item}
      indexValue={indexValue}
      onPress={noop}
      iconComponent={IconComponent}
      titleComponent={titleComponent}
    />
  );
};

export default CompanyBenefitItem;
