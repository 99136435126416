import { useReactiveVar } from '@apollo/client';
import AccountContext from 'context/AccountContext';
import { useContext } from 'react';
import { parkingPaymentCardId } from 'states/common';
import { Card, Maybe } from 'types/generatedSchemaTypes';
import { cardErrorStatuses } from 'utils/paymentUtils';
import useSelectedCompanyBenefit from './useSelectedCompanyBenefit';

const useSelectedPaymentMethod = (cardId?: string) => {
  const {
    customer: { paymentCards },
  } = useContext(AccountContext);
  const selectedCreditCardId = useReactiveVar(parkingPaymentCardId);
  const conditionalCreditCardId = cardId ?? selectedCreditCardId;
  const { companyBenefit } = useSelectedCompanyBenefit();
  const paymentCard = (paymentCards || []).find((currentCard: Maybe<Card>) => {
    return (
      currentCard?.cardId === conditionalCreditCardId &&
      !cardErrorStatuses.includes(currentCard.status || 'Active')
    );
  });
  return (
    paymentCard ||
    (!companyBenefit &&
      paymentCards?.find(
        (card) =>
          card?.isDefault &&
          !cardErrorStatuses.includes(card.status || 'Active')
      )) ||
    undefined
  );
};

export default useSelectedPaymentMethod;
