import {
  LinkingOptions,
  NavigationProp,
  ParamListBase,
} from '@react-navigation/native';
import {
  acceptedTermsAfterFeatureCountryChange,
  isApproveTermsOpen,
  isSignUpFlow,
} from 'states/common';
import {
  featureCountryCode,
  futureFeatureCountryCode,
} from 'states/persistInStorage';
import { theme } from 'aimo-ui';
import { ParkingZoneType } from 'types/common';
import { isAndroid, isWeb } from 'utils/deviceUtils';
import { DimensionValue, StyleSheet } from 'react-native';
import { StackNavigationOptions } from '@react-navigation/stack';
import { ButtonTypes } from 'aimo-ui/lib/typescript/src/components/Button/types';

export type ScreenArrayProps = {
  screenName: string;
  tabLocalizationKey: string;
  iconName: string;
  iconNameActive: string;
  initialParams?: InitialParamProps;
  paramsString?: string;
  screenTitle?: string;
};

type InitialParamProps = {
  parkingZoneUid?: string;
  token?: string;
};

export type NavigationSettingsType = {
  initialRoute: string;
  activeTintColor: string;
  inactiveTintColor: string;
  screenArray: ScreenArrayProps[];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore-next-line
  linking?: LinkingOptions;
};

const { colors } = theme;
export const WEB_MENU_BAR_HEIGHT = 80;

export const devScreen: ScreenArrayProps = {
  screenName: 'devScreen',
  screenTitle: 'Dev',
  tabLocalizationKey: 'navigation.dev',
  iconName: 'Stop-Outlined',
  iconNameActive: 'Stop-Filled',
};

export const navTestingSettings: NavigationSettingsType = {
  initialRoute: 'Services',
  inactiveTintColor: colors.gray200,
  activeTintColor: colors.black,
  screenArray: [
    {
      screenName: 'servicesScreen',
      tabLocalizationKey: 'navigation.services',
      iconName: 'Services-Outlined',
      iconNameActive: 'Service-Filled',
    },
    {
      screenName: 'accountScreen',
      tabLocalizationKey: 'navigation.account',
      iconName: 'Account-Outlined',
      iconNameActive: 'Account-Filled',
    },
  ],
};

export const navTestingAccountSettings: NavigationSettingsType = {
  initialRoute: 'Account',
  inactiveTintColor: colors.gray200,
  activeTintColor: colors.black,
  screenArray: [
    {
      screenName: 'accountScreen',
      tabLocalizationKey: 'navigation.account',
      iconName: 'Account-Outlined',
      iconNameActive: 'Account-Filled',
    },
  ],
};

export const commonNavigationSettings = {
  initialRoute: 'parkScreen',
  inactiveTintColor: colors.gray200,
  activeTintColor: colors.black,
  screenArray: [
    {
      screenName: 'parkScreen',
      screenTitle: 'Parking',
      initialParams: { parkingZoneUid: '' },
      paramsString: ':parkZoneUid',
      tabLocalizationKey: 'navigation.park',
      iconName: 'Park-Outlined',
      iconNameActive: 'Park-Filled',
    },
    {
      screenName: 'rideScreen',
      screenTitle: 'Car sharing',
      tabLocalizationKey: 'navigation.ride',
      iconName: 'Car-Outlined',
      iconNameActive: 'Car-Filled',
    },
    {
      screenName: 'servicesScreen',
      screenTitle: 'Services',
      tabLocalizationKey: 'navigation.services',
      iconName: 'Services-Outlined',
      iconNameActive: 'Service-Filled',
    },
    {
      screenName: 'accountScreen',
      screenTitle: 'My account',
      tabLocalizationKey: 'navigation.account',
      iconName: 'Account-Outlined',
      iconNameActive: 'Account-Filled',
    },
  ],
  linking: {
    prefixes: [
      'aimoone://',
      'com.aimoone://',
      'https://aimo-one*.io',
      'https://aimoapp*.io',
      'http://localhost:3000',
    ],
    config: {
      screens: {
        mainTabs: {
          initialRouteName: '/',
          screens: {
            parkScreen: {
              initialRouteName: 'park/',
              path: 'park/:parkingZoneUid?/:type?/:secondaryUid?',
              parse: {
                parkingZoneUid: (parkingZoneUid?: string) =>
                  parkingZoneUid || '',
                type: (type?: ParkingZoneType) => type || '',
                secondaryUid: (secondaryUid?: string) => secondaryUid || '',
              },
              stringify: {
                parkingZoneUid: (parkingZoneUid?: string) =>
                  parkingZoneUid || '',
                type: (type?: ParkingZoneType) => type || '',
                secondaryUid: (secondaryUid?: string) => secondaryUid || '',
              },
            },
            rideScreen: 'ride',
            servicesScreen: {
              screens: {
                servicesMain: 'services',
                permits: 'services/permits/:licensePlate?',
                permit: 'services/permit/:permitId',
                permitEdit: 'services/permit/:permitId/edit',
                permitRedeem: 'services/permit/:permitId/redeem',
                permitVehicles: 'services/permit/:permitId/vehicles',
                addVehiclePermit:
                  'services/permit/:permitId/vehicles/add-vehicle',
                prepaidTickets: 'services/prepaidTickets',
                prepaidTicket: 'services/prepaidTickets/:ticketId',
                prepaidEdit: 'services/prepaidTickets/:ticketId/edit',
                hslTicketRender: 'services/hslTicketRender',
              },
            },
            accountScreen: {
              screens: {
                accountMain: 'account/main',
                contactUs: 'account/contact-us',
                myVehicles: 'account/my-vehicles',
                addVehicleAccount: 'account/add-vehicle',
                companyPermit: 'account/company-benefits',
                editVehicle: 'account/edit-vehicle',
                myPaymentMethods: 'account/my-payment-methods',
                parkingHistory: 'account/parking-history',
                permits: 'account/edit-vehicle/permits',
                permit: 'account/edit-vehicle/permits/:permitId',
                permitEdit: 'account/edit-vehicle/permits/:permitId/edit',
                prepaidHistory: 'account/prepaid-history',
                prepaidHistoryDetails:
                  'account/prepaid-history/:transactionId/:ticketId',
                permitHistoryDetails:
                  'account/prepaid-history/:transactionId/:permitId',
                parkingHistoryDetails: {
                  path: 'account/parking-history/details/:parkingZoneUid/:sessionId',
                  parse: {
                    parkingZoneUid: () => '',
                    sessionId: () => '',
                  },
                  stringify: {
                    parkingZoneUid: () => '',
                    sessionId: () => '',
                  },
                },
                chargingHistoryDetails: {
                  path: 'account/charging-history/details/:parkingZoneUid?/:transactionId/',
                  parse: {
                    parkingZoneUid: (parkingZoneUid?: string) =>
                      parkingZoneUid || '',
                    transactionId: (transactionId?: string) =>
                      transactionId || '',
                  },
                  stringify: {
                    parkingZoneUid: (parkingZoneUid?: string) =>
                      parkingZoneUid || '',
                    transactionId: (transactionId?: string) =>
                      transactionId || '',
                  },
                },
                viewTerms: 'account/view-terms',
              },
            },
            devScreen: 'developer',
          },
        },
        onboardingModal: {
          screens: {
            welcome: 'onboarding/welcome',
            addPaymentMethod: 'onboarding/add-credit-card',
            addVehicleOnboarding: 'onboarding/add-vehicle',
            pushNotifications: 'onboarding/push-notifications',
            onboardingContinue: 'onboarding/continue',
          },
        },
        creditCardRedirectCancel: 'credit-card-redirect/cancel',
        creditCardRedirectSuccess: 'credit-card-redirect/success',
        creditCardRedirectFailure: 'credit-card-redirect/failure',
        parkingReceipt: {
          path: 'parking-receipt/:sessionId',
          parse: {
            sessionId: (sessionId?: string) => sessionId || '',
          },
          stringify: {
            sessionId: (sessionId?: string) => sessionId || '',
          },
        },
        addVehicleParking: 'add-vehicle-parking',
        vehicleCameraRecognitionParking: 'vehicle-camera-recognition-parking',
        approveTermsAppLocation: {
          initialRouteName: 'approve-terms-location',
          path: 'approve-terms-location',
        },
        longTermParking: {
          initialRouteName: 'long-term-parking',
          path: 'long-term-parking',
          screens: {
            searchScreen: {
              initialRouteName: 'search',
              path: '/:parkingZoneUid',
              parse: {
                parkingZoneUid: (parkingZoneUid?: string) =>
                  parkingZoneUid || '',
              },
              stringify: {
                parkingZoneUid: (parkingZoneUid?: string) =>
                  parkingZoneUid || '',
              },
            },
            buyScreen: {
              initialRouteName: 'buy-permit',
              path: '/:parkingZoneUid/buy-permit/:pmcId',
            },
          },
        },
        parkingSpaceMapViewer: {
          initialRouteName: 'reserve-parking-space',
          path: 'reserve-parking-space',
        },
        streetParking: {
          initialRouteName: 'street-parking',
          path: 'street-parking',
          screens: {
            viewScreen: {
              initialRouteName: 'view',
              path: '/viewPermit',
            },
            purchase: {
              initialRouteName: 'purchase',
              path: '/purchase/:pmcId',
            },
            apply: {
              initialRouteName: 'apply',
              path: '/apply',
            },
          },
        },
        hslTicket: {
          initialRouteName: 'hsl-ticket',
          path: 'hsl-ticket',
          screens: {
            info: {
              initialRouteName: 'info',
              path: '/:token/info',
              parse: {
                token: (token?: string) => token || '',
              },
              stringify: {
                token: (token?: string) => token || '',
              },
            },
            hslTicketRender: {
              initialRouteName: 'render',
              path: '/render',
            },
          },
        },
        approveTerms: 'account/approve-terms',
        updateAvailable: 'update-available',
        approveUpdatedTerms: 'approve-updated-terms',
        landingScreen: 'landing',
        loginSuccess: 'loginSuccess',
      },
    },
  },
};

const getRootNavigator = (
  navigation: NavigationProp<ParamListBase>
): NavigationProp<ParamListBase> => {
  const parentState = navigation.getParent();
  if (parentState) {
    return getRootNavigator(parentState);
  }
  return navigation;
};

export const isOnboardingOpen = (navigation: NavigationProp<any>) => {
  const rootNavigationState = getRootNavigator(navigation);
  const routes = rootNavigationState.getState().routes;
  return !!routes?.some((r) => r.name === 'onboardingModal');
};

export const beforeRemoveApproveTerms = () => {
  if (acceptedTermsAfterFeatureCountryChange()) {
    featureCountryCode(futureFeatureCountryCode());
    acceptedTermsAfterFeatureCountryChange(true);
  } else {
    futureFeatureCountryCode(null);
  }
  isSignUpFlow(false);
  isApproveTermsOpen(false);
};

export const onFocusApproveTerms = () => {
  isApproveTermsOpen(true);
};

const defaultPercentage = 98;

export const getModalOptions = (
  modalHeight: `${number}%` = `${defaultPercentage}%`,
  showHandle = false,
  backButtonType: ButtonTypes = 'tertiary'
) => {
  const parsedPercentage = parseInt(modalHeight.replace('%', ''), 10);
  const numberPercentage = Math.min(
    Math.max(
      parsedPercentage,
      theme.normalising.heightPercentage(parsedPercentage)
    ),
    defaultPercentage
  );

  const top: DimensionValue = `${100 - numberPercentage}%`;
  const maxHeight: DimensionValue = `${numberPercentage}%`;

  return {
    ...StyleSheet.create({
      cardStyle: {
        top,
        maxHeight,
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
        backgroundColor: theme.colors.white,
        marginTop: isWeb ? WEB_MENU_BAR_HEIGHT : 0,
      },
    }),
    headerStyle: {
      showHandle,
      backButtonType,
    },
    screenOptions: {
      presentation:
        isAndroid && parsedPercentage !== defaultPercentage
          ? 'transparentModal'
          : 'modal',
      gestureEnabled: true,
      gestureResponseDistance: 1000,
      cardOverlayEnabled: true,
      animationEnabled: true,
    } as StackNavigationOptions,
  };
};
