import { useReactiveVar } from '@apollo/client';
import { Headline3, List, Spinner } from 'aimo-ui';
import PaymentMethodItem from 'components/account/paymentMethods/PaymentMethodItem';
import AddPaymentMethod from 'components/payment/AddPaymentMethod';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { selectedPaymentCard } from 'states/common';
import { SheetDetailsWrapper, StyledWrapper } from 'styles/ContainerStyles';
import { Card, CompanyBenefit, Maybe } from 'types/generatedSchemaTypes';
import { PaymentDataProps } from 'types/PaymentData';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const paymentData: PaymentDataProps[] = [
  {
    title: 'payment.addPaymentCard',
    icon: 'plus',
    action: 'addCardModal',
    testID: 'add-credit-card-button',
  },
];

const AddPaymentMethodButton = () => (
  <AddPaymentMethod paymentData={paymentData} />
);

const MyPaymentMethods: React.FC = () => {
  const { t } = useTranslation();
  const {
    customer: { paymentCards, companyBenefits },
    loading: customerLoading,
  } = useContext(AccountContext);
  const selectedCard = useReactiveVar(selectedPaymentCard);

  const {
    flags: { company_benefits },
  } = useContext(FeatureFlagsContext);
  const showCompanyBenefits = showFeatureFlag(company_benefits);

  let data: (Maybe<Card> | Maybe<CompanyBenefit>)[] = [];
  if (paymentCards) {
    data = paymentCards;
  }
  if (
    selectedCard.status === 'Verifying' &&
    !paymentCards?.find((card) => card?.cardId === selectedCard.cardId)
  ) {
    data = data.concat({ ...selectedCard, __typename: 'Card' } as Card);
  }
  if (showCompanyBenefits && companyBenefits) {
    data = data.concat(companyBenefits);
  }

  return (
    <SheetDetailsWrapper pt={isWeb ? 60 : 30} pb={20}>
      {customerLoading ? (
        <StyledWrapper center>
          <Spinner />
        </StyledWrapper>
      ) : (
        <>
          <StyledWrapper mb={10}>
            <Headline3 {...testIdentifiers('debit-credit-cards-title')}>
              {t('paymentMethods.debitCreditCards')}
            </Headline3>
          </StyledWrapper>
          {data ? (
            <List
              listData={data}
              listElement={PaymentMethodItem}
              listFooter={AddPaymentMethodButton}
            />
          ) : (
            AddPaymentMethodButton
          )}
        </>
      )}
    </SheetDetailsWrapper>
  );
};

export default MyPaymentMethods;
