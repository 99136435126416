import { makeVar, ReactiveVar } from '@apollo/client';
import { AfterAuthNavigationStack } from 'hooks/useAfterAuthenticationRedirect';
import {
  Acceptance,
  CountryCode,
  Lang,
  ParkZone,
} from 'types/generatedSchemaTypes';
import {
  ParkingTariffAndZone,
  TutorialCompletion,
  UrlUnlockWebStateProps,
} from 'types/states';
import { fallbackLanguage } from 'utils/languageUtils';
import { makeStoredVar } from 'utils/MMKVStorageUtils';
import { defaultCompletion } from 'utils/tutorialUtils';

export const accessToken: ReactiveVar<string> = makeVar<string>('');

export const country: ReactiveVar<CountryCode> = makeVar<CountryCode>('FI');

export const favorites: ReactiveVar<string> = makeVar<string>('');

export const recentZonesOrChargers: ReactiveVar<ParkZone[]> = makeStoredVar<
  ParkZone[]
>([], 'recentZonesOrChargers');

export const onboardingCompleted: ReactiveVar<boolean> = makeStoredVar<boolean>(
  false,
  'onboardingCompleted'
);

export const deviceToken: ReactiveVar<string> = makeVar<string>('');

export const deviceTokenRegistered: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const deviceLanguage: ReactiveVar<Lang> =
  makeVar<Lang>(fallbackLanguage);

export const afterAuthNavigationStack: ReactiveVar<AfterAuthNavigationStack> =
  makeStoredVar<AfterAuthNavigationStack>([], 'afterAuthNavigationStack');

export const featureCountryCode: ReactiveVar<CountryCode | null> =
  makeStoredVar<CountryCode | null>(null, 'featureCountryCode');

export const futureFeatureCountryCode: ReactiveVar<CountryCode | null> =
  makeStoredVar<CountryCode | null>(null, 'futureFeatureCountryCode');

export const urlUnlockingSegment: ReactiveVar<UrlUnlockWebStateProps | null> =
  makeStoredVar<UrlUnlockWebStateProps | null>(null, 'urlUnlockingSegment');

export const showLandingScreen: ReactiveVar<boolean> = makeVar<boolean>(true);

export const acceptances: ReactiveVar<Acceptance[] | undefined> = makeVar<
  Acceptance[] | undefined
>(undefined);

export const isTokenRefreshing: ReactiveVar<boolean> = makeVar<boolean>(false);

export const latestVehicleUsed: ReactiveVar<string> = makeStoredVar<string>(
  '',
  'latestVehicleUsed'
);

export const recentParkingsWithSegment: ReactiveVar<ParkingTariffAndZone[]> =
  makeStoredVar<ParkingTariffAndZone[]>([], 'recentParkingsWithSegment');

export const parkingEndingReminder: ReactiveVar<number> = makeStoredVar<number>(
  15,
  'parkingEndingReminder'
);

export const tutorialCompletion: ReactiveVar<TutorialCompletion> =
  makeStoredVar<TutorialCompletion>(defaultCompletion, 'tutorialCompletion');

export const preferredLanguage: ReactiveVar<Lang | null> =
  makeStoredVar<Lang | null>(null, 'preferredLanguage');
