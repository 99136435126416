import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { BaseText, Meta, theme } from 'aimo-ui';
import { QuickActionButton } from 'components/common/QuickActionButton';
import React from 'react';
import { Maybe } from 'types/generatedSchemaTypes';
import { RootStackParamList } from 'types/navigation';

const PermitParkingSpace: React.FC<{
  parkingSpaceName: string;
  spotMap: Maybe<string> | undefined;
  meta?: boolean;
  textColor?: string;
}> = ({ parkingSpaceName, spotMap, textColor, meta }) => {
  const { navigate } = useNavigation<StackNavigationProp<RootStackParamList>>();

  const onPress = () => {
    if (spotMap) {
      return navigate('parkingSpaceMapViewer', {
        spotMap: spotMap,
        showOnlyMap: true,
      });
    }
  };
  if (!parkingSpaceName) {
    return <></>;
  }
  const SubtitleComponent = meta ? Meta : BaseText;
  return (
    <QuickActionButton
      testID="quick-button-parking-space"
      onPress={onPress}
      disabled={!spotMap}
      iconName={spotMap ? 'Pin-Outlined' : ''}
      coloring={theme.colors.primary}
      subtitle={
        <SubtitleComponent color={textColor}>
          {parkingSpaceName}
        </SubtitleComponent>
      }
      flexRow
    />
  );
};

export default PermitParkingSpace;
