import { Meta, theme } from 'aimo-ui';
import ParkingDetailsSummary from 'components/receipt/ParkingDetailsSummary';
import ParkingTime from 'components/receipt/ParkingTime';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { isNil } from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ColumnWithMargins, Notification } from 'styles/ContainerStyles';
import {
  BillingItem,
  ChargingSession,
  Maybe,
  ParkingSession,
  ParkZone,
  Receipt,
} from 'types/generatedSchemaTypes';
import { getCorrectBillingItem } from 'utils/commonUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { getLocationIconType } from 'utils/parkUtils';

type ParkingDetailsProps = {
  parkingSession?: ParkingSession;
  receiptData?: Receipt;
  chargingSession?: ChargingSession;
  parkZone: ParkZone;
  testID: string;
};

const ParkingDetails: React.FC<ParkingDetailsProps> = ({
  parkZone,
  parkingSession,
  receiptData,
  chargingSession,
  testID,
}) => {
  const { t } = useTranslation();
  const {
    flags: { anpr },
  } = useContext(FeatureFlagsContext);
  const showCameraRecognition = showFeatureFlag(anpr);

  const {
    name,
    locationType,
    parkingMethods,
    info,
    address,
    commercialZoneView,
  } = parkZone || {};

  const billingItem = getCorrectBillingItem(
    receiptData?.items as [BillingItem] | undefined
  )?.[0];

  const transactionId =
    parkingSession?.transactionId ||
    receiptData?.transactionId ||
    chargingSession?.transactionId;
  const price =
    parkingSession?.price?.totalAmount ||
    billingItem?.amountCents ||
    chargingSession?.priceInCents;
  const countryCode =
    parkingSession?.countryCode ||
    receiptData?.countryCode ||
    chargingSession?.countryCode ||
    'FI';
  const cardId =
    parkingSession?.cardId ||
    receiptData?.creditCard?.cardId ||
    chargingSession?.cardId;
  const startDate =
    parkingSession?.startDate ||
    billingItem?.startTime ||
    chargingSession?.startTime;
  const endDate =
    parkingSession?.endDate || billingItem?.endTime || chargingSession?.endTime;

  const isRetrying =
    parkingSession?.paymentState === 'Retrying' ||
    chargingSession?.paymentState === 'Retrying';

  const getTime = (
    primaryDate: Maybe<Date> | undefined,
    secondaryDate: Maybe<Date> | undefined
  ) => {
    if (primaryDate) {
      return new Date(primaryDate);
    } else if (secondaryDate) {
      return new Date(secondaryDate);
    }
    return null;
  };
  const startTime = getTime(startDate, undefined);
  const endTime = getTime(endDate, undefined);
  const markerType = getLocationIconType(
    locationType,
    showCameraRecognition && parkingMethods?.anpr,
    !isNil(chargingSession)
  );

  return (
    <View testID={testID}>
      <ParkingTime
        startTime={startTime}
        endTime={endTime}
        countryCode={countryCode}
      />
      {isRetrying && (
        <Notification
          backgroundColor={theme.colors.lightBg.red}
          testID="payment-details-failed-payment">
          <ColumnWithMargins>
            <Meta>{t('parking.receipt.paymentFailedDescription')}</Meta>
          </ColumnWithMargins>
        </Notification>
      )}
      <ParkingDetailsSummary
        startTime={startTime}
        endTime={endTime}
        locationType={locationType}
        name={commercialZoneView?.name || name}
        transactionId={transactionId}
        cardId={cardId}
        corporateAccountUid={parkingSession?.corporateAccountUid}
        totalAmount={price}
        markerType={markerType}
        doorCode={info?.doorCode}
        coordinates={address?.marker?.coordinates}
        poolingInfo={parkingSession?.poolingInfo}
      />
    </View>
  );
};

export default ParkingDetails;
