import { useMutation, useReactiveVar } from '@apollo/client';
import { REMOVE_VEHICLE } from '@mutations/removeVehicle';
import { GET_CUSTOMER } from '@queries/getCustomer';
import { BaseText, Button, Title, theme } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import VehicleAvatar from 'components/common/VehicleAvatar';
import useAfterVehicleRedirect from 'hooks/useAfterVehicleRedirect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  editVehicleData,
  editVehicleIsDeleted,
  sheetModal,
} from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import {
  Mutation,
  MutationDeleteVehicleArgs,
} from 'types/generatedSchemaTypes';
import { testIdentifiers } from 'utils/testIdentifiers';

const ConfirmVehicleRemoval: React.FC = () => {
  const { t } = useTranslation();
  const selectedVehicle = useReactiveVar(editVehicleData);
  const { navigateAfterVehicleSaved } = useAfterVehicleRedirect();

  const [removeVehicle, { loading }] = useMutation<
    Mutation,
    MutationDeleteVehicleArgs
  >(REMOVE_VEHICLE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        errorPolicy: 'all',
      },
    ],
    variables: {
      input: {
        id: selectedVehicle.id || selectedVehicle?.licensePlate?.plateText,
      },
    },
    onCompleted: () => {
      navigateAfterVehicleSaved();
      sheetModal('');
    },
    onError: () => showToast('error.vehicleRemoving', 'error'),
  });

  const handleRemoveButtonPressed = () => {
    editVehicleIsDeleted(true);
    removeVehicle();
  };

  return (
    <StyledWrapper mb={0}>
      <StyledWrapper maxWidth={300} center>
        <StyledWrapper maxWidth={350} center mb={20}>
          <VehicleAvatar
            color={selectedVehicle?.appSettings?.color}
            icon={selectedVehicle?.appSettings?.icon}
            size="xxl"
          />
        </StyledWrapper>
        <StyledWrapper maxWidth={350} center mb={6}>
          <Title center {...testIdentifiers('remove-vehicle-license')}>
            {selectedVehicle?.licensePlate?.plateText}
          </Title>
        </StyledWrapper>
        <StyledWrapper maxWidth={350} center mb={65}>
          <BaseText
            center
            {...testIdentifiers('remove-vehicle-name')}
            color={theme.colors.gray200}>
            {selectedVehicle?.name}
          </BaseText>
        </StyledWrapper>
      </StyledWrapper>
      <StyledWrapper mb={23}>
        <Button
          loading={loading}
          {...testIdentifiers('remove-vehicle-button')}
          size="l"
          buttonType="alert"
          text={loading ? t('button.removing') : t('button.remove')}
          onPress={() => handleRemoveButtonPressed()}
          disabled={loading}
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default ConfirmVehicleRemoval;
