import { useReactiveVar } from '@apollo/client';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import { useEffect, useMemo } from 'react';
import { sheetModal } from 'states/common';
import { tutorialCompletion } from 'states/persistInStorage';
import { Tutorial } from 'types/states';
import { isWeb } from 'utils/deviceUtils';
import { completeTutorial, getTutorials } from 'utils/tutorialUtils';

const useTutorialSheet = ({
  bottomSheetModalRef,
  selectedTutorial,
}: {
  bottomSheetModalRef?: React.RefObject<BottomSheetModalMethods>;
  selectedTutorial?: Tutorial;
}) => {
  const tutorialsState = useReactiveVar(tutorialCompletion);

  const openedTutorial: Tutorial | undefined = useMemo(() => {
    return getTutorials().find(
      (tutorial) => tutorialsState[tutorial]?.status === 'OPEN'
    );
  }, [tutorialsState]);

  const canOpenTutorial = useMemo(() => {
    return (
      selectedTutorial &&
      tutorialsState[selectedTutorial]?.status === 'NOT_COMPLETED'
    );
  }, [selectedTutorial, tutorialsState]);

  const onDismiss = () => {
    if (openedTutorial) {
      completeTutorial(openedTutorial);
    }
  };

  useEffect(() => {
    if (openedTutorial) {
      bottomSheetModalRef?.current?.present();
      isWeb && sheetModal(openedTutorial);
    } else {
      bottomSheetModalRef?.current?.dismiss();
      isWeb && sheetModal('');
    }
  }, [openedTutorial, bottomSheetModalRef]);

  return { openedTutorial, canOpenTutorial, onDismiss };
};

export default useTutorialSheet;
