import { useReactiveVar } from '@apollo/client';
import { BaseText, theme } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import QuickActionDirection from 'components/common/QuickActionDirection';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { format } from 'date-fns';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { featureCountryCode } from 'states/persistInStorage';
import { isSalesOrderFree } from 'utils/commonUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { makePoint } from 'utils/mapUtils';
import { getLocationIconType, locationIcon } from 'utils/parkUtils';
import PermitParkingSpace from './PermitParkingSpace';
import { SalesOrderReceipt } from './SalesOrderReceiptClass';

const PermitInfo = ({
  salesOrder,
  isHistory,
}: {
  salesOrder: SalesOrderReceipt;
  isHistory: boolean;
}) => {
  const { t } = useTranslation();

  const {
    orderLines,
    creditCardId,
    title,
    isInvoicing,
    nextBillingDate: renewalTime,
    isRecurring: recurring,
    isWaiting,
    endDate,
    isEnding,
  } = salesOrder || {};
  const orderLine = orderLines?.[0];
  const { pmc, parkingSpace, registrationPlates } = orderLine || {};
  const { plateText } = registrationPlates?.[0] || {};
  const { address, name, commercialZoneView, spotMap } = pmc?.parkZone || {};
  const { prices } = pmc || {};
  const markerCoordinate =
    address?.marker && makePoint(address?.marker?.coordinates);
  const parkZoneName = name || commercialZoneView?.name;
  const {
    flags: { anpr, account_history_permit_parking_space },
  } = useContext(FeatureFlagsContext);
  const showCameraRecognition = showFeatureFlag(anpr);
  const paymentCard = useSelectedPaymentMethod(creditCardId || undefined);
  const markerType = getLocationIconType(
    pmc?.parkZone?.locationType || 'OTHER',
    showCameraRecognition && pmc?.parkZone?.parkingMethods?.anpr
  );
  const freeSalesOrder = isSalesOrderFree(prices);

  const endDateFormatted = format(
    new Date(endDate || renewalTime || new Date()),
    'd.M.y'
  );
  const appCountryCode = useReactiveVar(featureCountryCode);

  const showPermitParkingSpace = showFeatureFlag(
    account_history_permit_parking_space
  );

  const permitInfoList = [
    {
      icon: locationIcon[markerType],
      label: pmc?.parkZone?.name || title || '',
      value: (
        <QuickActionDirection
          name={parkZoneName}
          showIconLabel={false}
          markerCoordinate={markerCoordinate}
          alignEnd
        />
      ),
    },
    ...(isHistory
      ? []
      : [
          {
            icon: 'Parking_Spot-Outlined',
            label: t('longTermParking.parkingSpace'),
            value: parkingSpace?.name ? (
              <PermitParkingSpace
                parkingSpaceName={parkingSpace.name}
                spotMap={spotMap}
                textColor={theme.colors.gray200}
              />
            ) : (
              t('longTermParking.any')
            ),
          },
        ]),
    ...(plateText
      ? [
          {
            icon: 'Car-Outlined',
            label: t('services.permitVehicle'),
            value: plateText,
          },
        ]
      : []),
    ...(!isWaiting
      ? [
          {
            icon: renewalTime !== undefined ? 'Calendar-Outlined' : undefined,
            label:
              recurring && !isEnding
                ? t('services.nextBillingDate')
                : t('permit.expires'),
            value: endDateFormatted,
          },
        ]
      : []),
    ...(paymentCard?.lastNums && !isInvoicing && !freeSalesOrder
      ? [
          {
            icon: 'Credit_Card-Outlined',
            label: t('payment.creditCard'),
            value: `**** ${paymentCard?.lastNums}`,
          },
        ]
      : []),
    ...(isInvoicing
      ? [
          {
            icon: 'Document-Outlined',
            label: t('paymentMethods.invoicing'),
          },
        ]
      : []),
  ];

  permitInfoList.forEach((e, i) => {
    if (
      !showPermitParkingSpace &&
      appCountryCode === 'SE' &&
      e.label === t('longTermParking.parkingSpace')
    ) {
      permitInfoList.splice(i, 1);
    }
  });

  if (pmc?.parkZone?.info?.doorCode) {
    permitInfoList.unshift({
      icon: 'Unlocked-Outlined',
      label: t('longTermParking.garageEntryCode'),
      value: pmc?.parkZone?.info?.doorCode,
    });
  }

  if (
    paymentCard?.cardId !== creditCardId &&
    paymentCard?.lastNums &&
    !isInvoicing &&
    !isEnding &&
    !freeSalesOrder
  ) {
    permitInfoList.push({
      icon: 'Credit_Card-Outlined',
      label: t('payment.nextCreditCard'),
      value: `**** ${paymentCard?.lastNums}`,
    });
  }

  return (
    <>
      {permitInfoList.map((item, index) =>
        item?.icon ? (
          <MenuItem
            testID={`permit-info-${index}`}
            iconName={item.icon}
            menuLabel={item.label}
            rightComponent={
              <BaseText color={theme.colors.gray200}>{item.value}</BaseText>
            }
            noBorder={index === permitInfoList.length - 1}
            key={`permit-info-${index}`}
          />
        ) : undefined
      )}
    </>
  );
};

export default PermitInfo;
