import React, { useContext } from 'react';
import { ChargingSession, CountryCode } from 'types/generatedSchemaTypes';
import { BaseText, Headline3, Meta, theme, Title } from 'aimo-ui';
import { StatusContainer } from './styles';
import {
  StyledWrapper,
  RowSpaceBetween as RowStyle,
  ColumnWithMargins,
} from 'styles/ContainerStyles';
import { useTranslation } from 'react-i18next';
import { getTimeFromDate } from 'utils/timeUtils';
import {
  StopButton,
  LoadingSpinner,
} from 'components/ongoingParking/OngoingParkingComponents';
import { sheetModal } from 'states/common';
import { currencyFormatter, numberFormatter } from 'utils/commonUtils';
import { country } from 'states/persistInStorage';
import useDurationFromDate from 'hooks/useDurationFromDate';
import styled from 'styled-components/native';
import parkingContext from 'context/ParkingContext';

type ChargingStatusProps = {
  session: ChargingSession;
};

const StyledTitle = styled(Title)`
  font-size: ${(p) => p.theme.normalising.fontPixel(48)}px;
  line-height: ${(p) => p.theme.normalising.fontPixel(52)}px;
`;

const RowSpaceBetween = styled(RowStyle)`
  border-bottom-color: #14353d;
`;

const OngoingChargingStatus: React.FC<ChargingStatusProps> = ({ session }) => {
  const { t } = useTranslation();
  const { energyInWatts, status, stationId, evseId } = session;

  const startTime = session?.startTime
    ? new Date(session?.startTime)
    : new Date();
  const chargingPrice = session.priceInCents || 0;
  const duration = useDurationFromDate(startTime, 5000, [
    'hours',
    'minutes',
    'seconds',
  ]);
  const chargingCountry = session.countryCode as CountryCode;
  const startingTime = getTimeFromDate(startTime, chargingCountry || country());
  const totalPrice = chargingPrice / 100;
  const currentCost = currencyFormatter(
    totalPrice,
    chargingCountry || country()
  );

  const chargingOngoing = status === 'IN_PROGRESS';
  const chargingStarting = status === 'START_IN_QUEUE';
  const chargingEnding = status === 'STOP_IN_QUEUE';

  const energyConsumedKiloWatts = (energyInWatts || 0) / 1000;
  const energyUsed = `${
    chargingCountry
      ? numberFormatter(energyConsumedKiloWatts, chargingCountry)
      : energyConsumedKiloWatts
  } kWh`;

  const getTitleText = () => {
    switch (status) {
      case 'IN_PROGRESS':
        return t('evCharging.charging');
      case 'START_IN_QUEUE':
        return t('evCharging.starting');
      case 'STOP_IN_QUEUE':
        return t('evCharging.stopping');
      default:
        return '';
    }
  };
  const titleText = getTitleText();

  const { parkingZoneResult } = useContext(parkingContext);
  const chargingStations = parkingZoneResult?.data?.parkZone?.parkingZoneChargingStations;
  const stationData = chargingStations?.find(chargingStation => chargingStation?.id === stationId);
  const evsePublicDisplayId = stationData?.evses?.find(
    (evse) => evse.id === evseId
  )?.publicDisplayId;

  return (
    <StatusContainer>
      <ColumnWithMargins mt={200} mb={13}>
        <StyledTitle testID="charging-status-title" color={theme.colors.white}>
          {titleText}
        </StyledTitle>
      </ColumnWithMargins>
      {evsePublicDisplayId && !chargingEnding && (
        <ColumnWithMargins mt={0} mb={20}>
          <BaseText center color={theme.colors.white}>
            {`${t('evCharging.charger')}  ${evsePublicDisplayId}`}
          </BaseText>
        </ColumnWithMargins>
      )}
      {chargingEnding && (
        <ColumnWithMargins mt={0} mb={20}>
          <BaseText
            center
            color={theme.colors.white}
            testID={'ongoing-charging-stopping-info'}>
            {t('evCharging.stoppingInfo')}
          </BaseText>
        </ColumnWithMargins>
      )}
      <StyledWrapper mb={40} maxWidth={550}>
        <ColumnWithMargins ml={20} mr={20}>
          <RowSpaceBetween withBorder>
            <Meta color={theme.colors.white}>
              {chargingEnding
                ? t('evCharging.durationStopped')
                : t('parking.duration')}
            </Meta>
            <Meta testID="ongoing-charging-duration" color={theme.colors.white}>
              {session.startTime ? duration : 0}
            </Meta>
          </RowSpaceBetween>
          {session.startTime && (
            <RowSpaceBetween withBorder>
              <Meta color={theme.colors.white}>{t('evCharging.started')}</Meta>
              <Meta
                testID="ongoing-charging-start-time"
                color={theme.colors.white}>
                {startingTime}
              </Meta>
            </RowSpaceBetween>
          )}
          <RowSpaceBetween withBorder>
            <Meta color={theme.colors.white}>
              {chargingEnding
                ? t('evCharging.energyUsedStopped')
                : t('evCharging.energyUsed')}
            </Meta>
            <Meta
              testID="ongoing-charging-energy-used"
              color={theme.colors.white}>
              {energyUsed}
            </Meta>
          </RowSpaceBetween>
          <RowSpaceBetween>
            <Meta color={theme.colors.white}>
              {chargingEnding
                ? t('evCharging.estimatedCost')
                : t('evCharging.currentCost')}
            </Meta>
            <Headline3
              bold
              testID="ongoing-charging-cost"
              color={theme.colors.white}>
              {currentCost}
            </Headline3>
          </RowSpaceBetween>
        </ColumnWithMargins>
      </StyledWrapper>
      {chargingOngoing && (
        <>
          <StopButton
            testID={'ongoing-charging-stop-button'}
            onPress={() => sheetModal('stopCharging')}
            bigger
          />
          <Meta color={theme.colors.white}>{t('evCharging.stop')}</Meta>
        </>
      )}
      {(chargingEnding || chargingStarting) && <LoadingSpinner />}
    </StatusContainer>
  );
};

export default OngoingChargingStatus;
