import config from 'config/config';
import { Maybe, SegmentProps } from 'types/generatedSchemaTypes';

export const findDefaultTariff = (segments: Maybe<SegmentProps>[]) => {
  return (
    segments &&
    (segments.find((item) => item?.uid === 'Standard') || segments[0])
  );
};

export const isSegmentLocked = (
  segment: Maybe<SegmentProps>,
  unlockingValid: boolean
) => {
  return segment && segment.accessType === 'LOCKED' && !unlockingValid;
};

export const selectedTariffAvailable = (
  selectedTariff?: string,
  segments?: Maybe<SegmentProps>[]
) => {
  return (
    !!selectedTariff &&
    segments &&
    segments.some((pricingSegment) => pricingSegment?.uid === selectedTariff)
  );
};

export const unlockUrlTariffProviders = (url: string | undefined) => {
  if (!url) {
    return null;
  }
  if (
    url.includes('weblogin') &&
    url.includes('.uu') &&
    url.includes('profile')
  ) {
    // URL contains stuff that are for Uppsala University weblogin
    const service = config.REACT_APP_UPPSALA_SERVICE;
    return {
      provider: 'uppsala',
      service,
      loginUrl: `${url}/login?service=${encodeURI(service)}`,
      logoutUrl: `${url}/logout`,
      responseCheck: '?ticket=',
    };
  }
};
