import React from 'react';
import { Text } from 'react-native';
import { ParkingSession } from 'types/generatedSchemaTypes';
import BlockerMenuItem from './BlockerMenuItem';
import { BaseText, Headline3 } from 'aimo-ui';
import { useTranslation } from 'react-i18next';
import { sheetModal } from 'states/common';
import { useNavigation } from '@react-navigation/native';
import { AccountScreenNavigationProp } from 'types/navigation';

const PrepaidBlocker = ({
  activePrepaidParkingSessions,
}: {
  activePrepaidParkingSessions: ParkingSession[];
}) => {
  const { navigate } = useNavigation<AccountScreenNavigationProp>();
  const { t } = useTranslation();
  const numberOfTickets = activePrepaidParkingSessions.length;

  const navigateTo = () => {
    sheetModal('');
    if (numberOfTickets > 1) {
      navigate('accountScreen', { screen: 'prepaidHistory' });
    } else if (activePrepaidParkingSessions && numberOfTickets > 0) {
      navigate('prepaidHistoryDetails', {
        ticketId: activePrepaidParkingSessions[0].sessionId,
      });
    }
  };

  const TextComponent = () => {
    if (numberOfTickets === 1) {
      return (
        <Text>
          <BaseText>{t('account.remove.permits_front_one')}</BaseText>
          <Headline3>{t('account.remove.prepaid_one')}</Headline3>
          <BaseText>{t('account.remove.permits_tail')}</BaseText>
        </Text>
      );
    }
    return (
      <Text>
        <BaseText>{t('account.remove.permits_front_other')}</BaseText>
        <Headline3>
          {t('account.remove.prepaid_other', { count: numberOfTickets })}
        </Headline3>
        <BaseText>{t('account.remove.permits_tail')}</BaseText>
      </Text>
    );
  };

  if (numberOfTickets > 0) {
    return (
      <BlockerMenuItem
        text={TextComponent}
        testID="account-remove-prepaid-blocker"
        onPress={navigateTo}
      />
    );
  }
  return <></>;
};

export default PrepaidBlocker;
