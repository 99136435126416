import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { BaseText, Button, Meta, theme } from 'aimo-ui';
import useOngoingChargingSession from 'hooks/useOngoingChargingSession';
import useStopChargingMutation from 'hooks/useStopChargingMutation';
import useUpdateParkingMutation from 'hooks/useUpdateParkingMutation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';
import {
  ongoingParkingSession,
  ongoingParkingSheetExpanded,
  sheetModal,
} from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import { RootNavProps } from 'types/navigation';
import { chargingAllowedToStop } from 'utils/chargingUtils';
import { toggleOngoingSessionSheet } from 'utils/ongoingSessionUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const { width } = Dimensions.get('window');

const StopParkingConfirm: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<RootNavProps>();
  const { ongoingChargingSession } = useOngoingChargingSession();
  const chargingZone = ongoingChargingSession?.parkingZoneUid;

  const callback = (sessionIdString: string) => {
    navigate('parkingReceipt', {
      sessionId: sessionIdString,
      chargingSessionId: getChargingSessionId(),
    });
    toggleOngoingSessionSheet(ongoingParkingSheetExpanded, false);
  };
  const { sessionId, vehicleLabel, parkZoneUid } = useReactiveVar(
    ongoingParkingSession
  );

  const getChargingSessionId = () => {
    if (chargingCanBeStopped) {
      return ongoingChargingSession?.chargingSessionId ?? '';
    }
  };
  const hasCharging = parkZoneUid === chargingZone;
  const chargingCanBeStopped = chargingAllowedToStop(
    ongoingChargingSession?.status ?? 'IN_PROGRESS'
  );
  const { updateParking, updateParkingLoading } = useUpdateParkingMutation({
    callback,
    sessionId,
    actionType: 'STOP',
    isModal: true,
  });
  const { stopChargingSessionV2, loading: stopChargingLoading } =
    useStopChargingMutation();

  const handleStopParkingAndChargingSessions = () => {
    stopChargingSessionV2();
    updateParking();
    toggleOngoingSessionSheet(ongoingParkingSheetExpanded, false);
  };

  const continueParking = () => {
    stopChargingSessionV2();
  };

  const renderContent = () => {
    if (hasCharging && chargingCanBeStopped) {
      return (
        <>
          <StyledWrapper mb={25}>
            <BaseText testID="stop-parking-and-charging-confirm-text">
              {t('parking.stopParkingAndCharging')}
            </BaseText>
          </StyledWrapper>
          <StyledWrapper center maxWidth={width} mb={20}>
            <Button
              size="l"
              {...testIdentifiers('parking-and-charging-stop-confirm')}
              text={t('button.stopParkingAndCharging')}
              onPress={() => handleStopParkingAndChargingSessions()}
              disabled={updateParkingLoading}
              loading={updateParkingLoading || stopChargingLoading}
            />
          </StyledWrapper>
          <StyledWrapper center maxWidth={width} mb={20}>
            <Button
              size="l"
              testID="parking-and-charging-continue-parking"
              text={t('parking.continueParking')}
              buttonType="tertiary"
              onPress={() => continueParking()}
              disabled={updateParkingLoading}
              loading={updateParkingLoading || stopChargingLoading}
            />
          </StyledWrapper>
        </>
      );
    }
    return (
      <>
        <StyledWrapper mb={60}>
          <StyledWrapper mb={25}>
            <Meta
              color={theme.colors.gray200}
              testID="stop-ongoing-parking-confirm-description">
              {t('parking.stopParkingConfirmDescription', { vehicleLabel })}
            </Meta>
          </StyledWrapper>
        </StyledWrapper>

        <StyledWrapper center maxWidth={width} mb={20}>
          <Button
            size="l"
            {...testIdentifiers('stop-ongoing-parking-button')}
            text={t(
              updateParkingLoading
                ? 'parking.stoppingParking'
                : 'parking.stopParking'
            )}
            onPress={() => updateParking()}
            loading={updateParkingLoading}
          />
        </StyledWrapper>
      </>
    );
  };

  return (
    <StyledWrapper mb={0}>
      {renderContent()}
      <StyledWrapper center maxWidth={width} mb={0}>
        <Button
          size="l"
          testID="cancel-stop-ongoing-parking"
          onPress={() => sheetModal('')}
          buttonType="tertiary"
          text={t('button.cancel')}
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default StopParkingConfirm;
