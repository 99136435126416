import { useMutation, useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { BaseText, BottomButtonsLayout, Button, Meta, theme } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import MenuItem from 'components/common/MenuItem';
import AccountContext from 'context/AccountContext';
import { format } from 'date-fns';
import { UPDATE_SALES_ORDER } from 'graphql/mutations/updateSalesOrder';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  confirmCreatePermitData,
  prepaidPurchaseCompleted,
  selectedParkingSpace,
  sheetModal,
} from 'states/common';
import { latestVehicleUsed } from 'states/persistInStorage';
import { StyledWrapper } from 'styles/ContainerStyles';
import {
  LicensePlateInput,
  Mutation,
  MutationUpdateSalesOrderArgs,
  UpdateSalesOrderInput,
} from 'types/generatedSchemaTypes';
import { LongTermStackParamList } from 'types/navigation';
import { updateStateAndStorage } from 'utils/MMKVStorageUtils';
import { getParkingZoneName } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

export const Validity = ({ date, testID }: { date: Date; testID?: string }) => {
  const { t } = useTranslation();
  const today = new Date();
  const getValidDate = (selectedDate: Date) => {
    if (today.toDateString() === selectedDate.toDateString()) {
      return t('longTermParking.validImmediately');
    }
    return format(new Date(selectedDate), 'd.M.Y HH:mm');
  };
  return (
    <BaseText {...testIdentifiers(testID as string)}>
      {date && getValidDate(date)}
    </BaseText>
  );
};

const ConfirmPurchasePermitModalContent: React.FC = () => {
  const { t } = useTranslation();
  const { refetchCustomer: refetchPermitWithCustomer } =
    useContext(AccountContext);
  const { navigate, goBack, canGoBack } =
    useNavigation<StackNavigationProp<LongTermStackParamList>>();
  const {
    fromParkZoneDetails,
    salesOrderId = '',
    orderLineId = '',
    version = 1,
    pmcId,
    quantity = 1,
    selectedPmc: { billingInterval = '' } = {},
    meta: {
      parkZone = undefined,
      name = '',
      vehicle = undefined,
      paymentMethod = undefined,
      validity = undefined,
      parkingSpaceName = '',
      freeSalesOrder = false,
    } = {},
  } = confirmCreatePermitData() || {};

  const { cardId: savedCardId, lastNums: savedLastNums } =
    useSelectedPaymentMethod() ?? {};
  const { licensePlate: savedLicensePlate, name: savedVehicleName } =
    useSelectedParkingVehicle() ?? {};
  const { name: parkingSpaceSavedName, parkingSpaceUid } =
    useReactiveVar(selectedParkingSpace) ?? {};

  const [updateSalesOrder, { loading }] = useMutation<
    Mutation,
    MutationUpdateSalesOrderArgs
  >(UPDATE_SALES_ORDER, {
    onCompleted: ({ updateSalesOrder: editedSalesOrder }) => {
      refetchPermitWithCustomer();
      if (editedSalesOrder?.paymentState === 'FAILED_PAY') {
        return showToast('permit.paymentFailed', 'error');
      }
      sheetModal('');
      confirmCreatePermitData(undefined);
      selectedParkingSpace(undefined);
      if (fromParkZoneDetails) {
        prepaidPurchaseCompleted(true);
        return showToast(t('success.permitPurchase'), 'success');
      }
      updateStateAndStorage(
        latestVehicleUsed,
        'latestVehicleUsed',
        vehicle?.licensePlate?.plateText
      );
      navigate('completed');
    },
    onError: (error) => {
      showToast('error.permitCreationFailed', 'error');
      if (
        error.graphQLErrors.some(
          (e) => e.extensions?.code === 'SALES_ORDER_EXPIRED'
        )
      ) {
        confirmCreatePermitData(undefined);
        sheetModal('');
        if (fromParkZoneDetails) {
          prepaidPurchaseCompleted(true);
        } else if (canGoBack()) {
          goBack();
        }
      }
    },
  });

  const handleConfirmPurchase = () => {
    // If user changes any of these values we need to update them when activating the sales order
    const hasChanges =
      savedCardId !== paymentMethod?.cardId ||
      savedLicensePlate?.plateText !== vehicle?.licensePlate.plateText ||
      (!!parkingSpaceName && parkingSpaceSavedName !== parkingSpaceName);

    const licensePlateInput: LicensePlateInput = {
      plateText: savedLicensePlate?.plateText as string,
      countryCode: savedLicensePlate?.countryCode,
    };

    const updateInput: UpdateSalesOrderInput = {
      salesOrderId,
      orderLineId,
      version,
      pmcId,
      quantity,
      creditCardId: savedCardId,
      registrationPlate: licensePlateInput,
      parkingSpaceUid: parkingSpaceUid,
      hasChanges,
    };

    updateSalesOrder({
      variables: {
        input: {
          updateType: 'ACTIVATING',
          orderStatus: 'ACTIVATING',
          ...updateInput,
        },
      },
    });
  };

  const parkingZoneName = getParkingZoneName(parkZone);

  const vehicleName = () => {
    if (savedVehicleName || savedLicensePlate?.plateText) {
      return savedVehicleName
        ? `${savedVehicleName} (${savedLicensePlate?.plateText})`
        : savedLicensePlate?.plateText;
    }
    return vehicle?.name
      ? `${vehicle.name} (${vehicle?.licensePlate?.plateText})`
      : vehicle?.licensePlate?.plateText;
  };
  return (
    <BottomButtonsLayout
      scrollable={false}
      contentBottom={
        <StyledWrapper mb={30}>
          <Button
            loading={loading}
            {...testIdentifiers('permit-confirm-buy-button')}
            text={freeSalesOrder ? t('button.redeem') : t('button.buy')}
            size="l"
            buttonType="secondary"
            onPress={handleConfirmPurchase}
            disabled={loading}
          />
        </StyledWrapper>
      }>
      <StyledWrapper center mb={theme.normalising.heightPixel(30)}>
        <Meta
          center
          color={theme.colors.gray200}
          {...testIdentifiers('permit-info-meta')}>
          {t('services.confirmPermitDescription')}
        </Meta>
      </StyledWrapper>
      <StyledWrapper
        mb={theme.normalising.heightPixel(20)}
        center={isWeb}
        maxWidth={isWeb ? 600 : undefined}>
        <MenuItem
          color={theme.colors.gray200}
          testID="permit-info-location"
          menuLabel={t('services.location')}
          rightComponent={
            <BaseText {...testIdentifiers('permit-info-location-text')}>
              {parkingZoneName}
            </BaseText>
          }
          noBorder={false}
          key="permit-info-location"
        />
        <MenuItem
          color={theme.colors.gray200}
          testID="permit-info-type"
          menuLabel={t('services.permitType')}
          rightComponent={
            <BaseText
              {...testIdentifiers('permit-info-type-text')}
              numberOfLines={2}>
              {name}
            </BaseText>
          }
          noBorder={false}
          key="permit-info-type"
        />
        <MenuItem
          color={theme.colors.gray200}
          testID="permit-info-vehicle"
          menuLabel={t('services.permitVehicle')}
          rightComponent={
            <BaseText {...testIdentifiers('permit-info-vehicle-text')}>
              {vehicleName()}
            </BaseText>
          }
          noBorder={false}
          key="permit-info-vehicle"
        />
        {parkingSpaceName && (
          <MenuItem
            color={theme.colors.gray200}
            testID="permit-info-space"
            menuLabel={t('longTermParking.parkingSpace')}
            rightComponent={
              <BaseText {...testIdentifiers('permit-info-space-text')}>
                {parkingSpaceSavedName ?? parkingSpaceName}
              </BaseText>
            }
            noBorder={false}
            key="permit-info-space"
          />
        )}
        <MenuItem
          color={theme.colors.gray200}
          testID="permit-info-billing"
          menuLabel={t('services.billing')}
          rightComponent={
            <BaseText {...testIdentifiers('permit-info-billing-text')}>
              {freeSalesOrder
                ? t('cityPermits.renewInfo')
                : t(`longTermParking.recurring.${billingInterval}`)}
            </BaseText>
          }
          noBorder={false}
          key="permit-info-billing"
        />
        {paymentMethod && !freeSalesOrder && (
          <MenuItem
            color={theme.colors.gray200}
            testID="permit-info-payment"
            menuLabel={t('services.paymentMethod')}
            rightComponent={
              <BaseText {...testIdentifiers('permit-info-payment-text')}>
                {t('payment.creditCardOnly')} ****{' '}
                {savedLastNums ?? paymentMethod?.lastNums}
              </BaseText>
            }
            noBorder={false}
            key="permit-info-payment"
          />
        )}

        <MenuItem
          color={theme.colors.gray200}
          testID="permit-info-valid-from"
          menuLabel={t('services.validity')}
          rightComponent={
            validity?.validFrom ? (
              <Validity
                testID="permit-info-valid-from-text"
                date={validity.validFrom}
              />
            ) : undefined
          }
          noBorder={true}
          key="permit-info-valid-from"
        />
      </StyledWrapper>
    </BottomButtonsLayout>
  );
};
export default ConfirmPurchasePermitModalContent;
