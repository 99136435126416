import React from 'react';
import { Meta, theme } from 'aimo-ui';
import { t } from 'i18next';
import { Divider } from 'styles/CommonLayoutStyles';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import { Vehicle } from 'types/generatedSchemaTypes';

const getVehicleName = (selectedVehicle?: Vehicle) => {
  if (selectedVehicle) {
    return selectedVehicle?.name || selectedVehicle?.licensePlate?.plateText;
  }
  return '';
};

const AnprDetails: React.FC<{
  withBorder?: boolean;
}> = ({ withBorder }) => {
  const selectedVehicle = useSelectedParkingVehicle();
  const vehicleName = getVehicleName(selectedVehicle);
  const hasActivatedAnpr = selectedVehicle?.cameraRecognition?.enabled === true;
  return (
    <>
      <ColumnWithMargins mt={-10}>
        <Divider color={withBorder ? undefined : theme.colors.white} />
        <Meta testID="parkzone-anpr-text">
          {hasActivatedAnpr && t('parking.anpr.allowed')}
          {!hasActivatedAnpr && t('parking.anpr.notAllowed', { vehicleName })}
        </Meta>
      </ColumnWithMargins>
      <ColumnWithMargins ml={-20} mr={-20}>
        <Divider size={10} />
      </ColumnWithMargins>
    </>
  );
};

export default AnprDetails;
