import { useQuery, useReactiveVar } from '@apollo/client';
import { READ_POOLING_GROUP } from 'graphql/queries/readPoolingGroup';
import useEVChargingTariff from 'hooks/useEVChargingTariff';
import useIsPoolingGroup from 'hooks/useIsPoolingGroup';
import React, { useMemo } from 'react';
import {
  selectedChargingPricingInfoSegmentTariffPlanId,
  selectedTariffId,
} from 'states/common';
import {
  ChargingStation,
  CountryCode,
  PoolingGroup,
  Query,
  QueryReadPoolingGroupArgs,
} from 'types/generatedSchemaTypes';
import ParkingPriceEstimate from './ParkingPriceEstimate';

interface OneHourParkingPriceEstimateProps {
  parkingZoneUid: string;
  parkingZoneCountryCode: CountryCode;
  withLinkText?: string;
  onPricePress: () => void;
  showLeftItem?: boolean;
  chargingStations?: ChargingStation[];
}

export const OneHourParkingPriceEstimate: React.FC<
  OneHourParkingPriceEstimateProps
> = ({
  parkingZoneUid,
  parkingZoneCountryCode,
  onPricePress,
  withLinkText = '',
  showLeftItem = true,
  chargingStations,
}: OneHourParkingPriceEstimateProps) => {
  const startDate = useMemo(() => new Date(), []);
  const poolingGroupId = useIsPoolingGroup({ parkingZoneUid });
  const selectedTariffID = useReactiveVar(selectedTariffId);
  const selectedTariffChargingID = useReactiveVar(
    selectedChargingPricingInfoSegmentTariffPlanId
  );
  const { data: poolingData } = useQuery<Query, QueryReadPoolingGroupArgs>(
    READ_POOLING_GROUP,
    {
      variables: { poolingGroupId: poolingGroupId || '' },
      skip: !poolingGroupId,
      fetchPolicy: 'cache-and-network',
    }
  );
  const poolingDataObject = poolingData?.readPoolingGroup as PoolingGroup;

  const { EVChargingTariffs } = useEVChargingTariff({
    chargingStations: chargingStations || [],
  });

  const selectedChargingStation = useMemo(() => {
    return EVChargingTariffs?.find(
      (EVChargingTariff) =>
        EVChargingTariff.tariffPlan?.id === selectedTariffChargingID ||
        selectedTariffID
    );
  }, [EVChargingTariffs, selectedTariffID, selectedTariffChargingID]);

  return (
    <ParkingPriceEstimate
      parkingZoneUid={parkingZoneUid}
      parkingZoneCountryCode={parkingZoneCountryCode}
      chargingStation={selectedChargingStation}
      pooling={poolingDataObject}
      withLinkText={withLinkText}
      onPricePress={onPricePress}
      showLeftItem={showLeftItem}
      startDate={startDate}
    />
  );
};
