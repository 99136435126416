import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import { theme } from 'aimo-ui';

type WrapperProps = {
  small: boolean;
  marginBottom?: number;
};

const Wrapper = styled.Pressable<WrapperProps>`
  flex-direction: row;
  height: ${(props) => (props.small ? '45px' : '60px')};
  border-radius: 60px;
  padding: ${(props) =>
    props.small ? '5px 15px 5px 15px' : ' 16px 24.67px 16px 24.67px'};
  background-color: ${(props) => props.theme.colors.gray50};
  align-items: center;
  margin-bottom: ${(p) => p.marginBottom ?? 15}px;
  overflow: hidden;
`;

const searchInputStyles = StyleSheet.create({
  input: {
    fontFamily: theme.fontFamily.medium,
    fontSize: parseInt(theme.textStyles.text.fontSize, 10),
    letterSpacing: parseInt(theme.textStyles.text.letterSpacing, 10),
    color: theme.colors.black,
    padding: 0,
    flex: 1,
    overflow: 'hidden',
    paddingRight: 10,
  },
});

const IconWrapper = styled.View`
  margin-right: 10px;
`;

const CloseWrapper = styled.Pressable<WrapperProps>``;

export { Wrapper, IconWrapper, CloseWrapper, searchInputStyles };
