import React from 'react';
import { BaseText, Spinner, theme } from 'aimo-ui';
import { useTranslation } from 'react-i18next';
import { ColumnWithMargins, RowSpaceBetween } from 'styles/ContainerStyles';
import { PermitContentWrapper } from 'screens/permit/styles';

const AccessPermitAvailability = ({
  availability = 0,
  loading,
}: {
  availability: number;
  loading: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <PermitContentWrapper>
      <RowSpaceBetween padding={5}>
        <ColumnWithMargins>
          <BaseText color={theme.colors.gray300}>
            {t('permit.access.availability')}
          </BaseText>
        </ColumnWithMargins>
        <ColumnWithMargins>
          {loading ? (
            <Spinner small={true} />
          ) : (
            <BaseText>
              {t('permit.access.spot', {
                count: availability,
              })}
            </BaseText>
          )}
        </ColumnWithMargins>
      </RowSpaceBetween>
    </PermitContentWrapper>
  );
};

export default AccessPermitAvailability;
