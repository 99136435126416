import { useNavigation } from '@react-navigation/native';
import { Headline1, HorizontalList, theme, Title } from 'aimo-ui';
import LoginStates from 'components/account/LoginStates';
import BGView from 'components/common/BGView';
import MenuItem from 'components/common/MenuItem';
import Icon from 'components/icons/Icon';
import CardItem from 'components/services/CardItem';
import ChipCardItem from 'components/services/ChipCardItem';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import ServiceContext from 'context/ServiceContext';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';
import useHasAcceptance from 'hooks/useHasAcceptance';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useReadPurchasedPrepaidTickets from 'hooks/useReadPurchasedPrepaidTickets';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectedHslTicket, sheetModal } from 'states/common';
import styled from 'styled-components/native';
import {
  ColumnWithMargins,
  ScreenContainer,
  ScreenWrapper,
  StyledIllustrationImage,
  StyledImage,
  StyledWrapper,
} from 'styles/ContainerStyles';
import {
  CountryCode,
  HslTicketContent,
  Maybe,
  PredefinedTicket,
  UnifiedPermit,
} from 'types/generatedSchemaTypes';
import {
  AccountScreenNavigationProp,
  NestedTabNavProps,
  RootNavProps,
  ServicesNavProps,
} from 'types/navigation';
import { isAndroid, isWeb } from 'utils/deviceUtils';
import {
  parseFeatureFlagObject,
  showFeatureFlag,
  TypeDictionary,
} from 'utils/featureFlagUtils';
import { titleTypeText, validTimeText } from 'utils/hslUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import CustomChips from './CustomChips';

const PurchasedServicesWrapper = styled.View`
  padding-horizontal: 20px;
`;

const ImageWrapper = styled.View`
  justify-content: flex-end;
  flex: 1;
`;

const ImageWrapperForOnboardingV2 = styled.View`
  justify-content: flex-end;
  flex: 1;
  transform: translate(0, 40px);
  z-index: 999;
  margin-top: ${isAndroid ? '-20px' : '10px'};
`;

const contentContainerStyle = { flexGrow: 1 };

const PermitImage: React.FC<{ testID?: string }> = ({ testID }) => (
  <StyledImage
    source={require('assets/images/permit.png')}
    width={128}
    height={110}
    center
    testID={testID}
  />
);

const TurkuCityImage = ({ testID }: { testID?: string }) => (
  <StyledImage
    source={require('assets/images/turku.png')}
    center
    width={150}
    height={80}
    testID={testID}
  />
);

const HSLImage = ({ testID }: { testID?: string }) => (
  <StyledImage
    source={require('assets/images/hsl.png')}
    center
    width={150}
    height={80}
    testID={testID}
  />
);

const ServiceImage = ({
  testID,
  turkuPmc,
  showGrayedOutIcon,
}: {
  testID?: string;
  turkuPmc: boolean;
  showGrayedOutIcon?: boolean;
}) => {
  const color = () => {
    if (showGrayedOutIcon) {
      return theme.colors.gray200;
    }
    return !turkuPmc ? theme.colors.black : theme.colors.white;
  };
  return (
    <Icon
      name={
        !turkuPmc && !showGrayedOutIcon ? 'Service-Filled' : 'Services-Outlined'
      }
      color={color()}
      size="l"
      testID={testID}
    />
  );
};
const HslTicketImage = ({ testID }: { testID?: string }) => (
  <Icon
    name={'HSL-Outlined'}
    color={theme.colors.white}
    size="m"
    testID={testID}
  />
);

const permitImage = () => <PermitImage testID="permit-card-image" />;

const ServicesContent = () => {
  const { t } = useTranslation();
  const {
    flags: { ride, permit_turku, hsl_ticket, sales_order_permits },
  } = useContext(FeatureFlagsContext);
  const showRide = showFeatureFlag(ride);
  const cityPermitsTurku = showFeatureFlag(permit_turku);
  const hslTickets = showFeatureFlag(hsl_ticket);
  const { navigate } = useNavigation<RootNavProps>();
  const { navigateToAccountLogin } = useAfterAuthenticationRedirect();
  const { isLoggedIn } = useIsLoggedIn();
  const countryObject = parseFeatureFlagObject<
    TypeDictionary<CountryCode, boolean>
  >(sales_order_permits, 'sales_order_permits');
  const permitCountries = Object.keys(countryObject)
    .filter((key) => countryObject[key as CountryCode])
    .map((key) => key as CountryCode);

  const Content = () => {
    return (
      <>
        <StyledWrapper
          mb={20}
          center={isWeb}
          maxWidth={isWeb ? 600 : undefined}>
          <>
            <StyledWrapper
              mb={20}
              center={isWeb}
              maxWidth={isWeb ? 600 : undefined}>
              <Headline1 {...testIdentifiers('services-subtitle')}>
                {t('services.availableTitle')}
              </Headline1>
            </StyledWrapper>
            <CardItem
              title={t('services.permitTitle')}
              text={t('services.permitText')}
              isPopular={true}
              imageContainer={permitImage}
              onPress={() =>
                navigate('longTermParking', { screen: 'mapScreen' })
              }
              testID="permit-card"
              countryToShow={permitCountries}
            />
            {showRide && (
              <CardItem
                title={t('services.bookRideTitle')}
                text={t('services.bookRideText')}
                onPress={() => navigate('mainTabs', { screen: 'rideScreen' })}
                testID="book-ride-card"
                countryToShow={['FI']}
              />
            )}
            {cityPermitsTurku && (
              <CardItem
                bgColor={theme.colors.lightBg.blue}
                textColor={theme.colors.white}
                title={t('services.turkuPermitsTitle')}
                text={t('services.turkuPermitsText')}
                imageContainer={() => (
                  <TurkuCityImage testID="turku-permit-image" />
                )}
                onPress={() =>
                  isLoggedIn
                    ? navigate('streetParking', {
                        screen: 'cityPmcs',
                      })
                    : navigateToAccountLogin()
                }
                testID="city-permit-card"
                countryToShow={['FI']}
              />
            )}
            {hslTickets && !isWeb && (
              <CardItem
                bgColor={theme.colors.lightBg.hslBlue}
                textColor={theme.colors.white}
                title={t('services.hslTicketTitle')}
                text={t('services.hslTicketText')}
                imageContainer={() => <HSLImage testID="hsl-ticket-image" />}
                onPress={() =>
                  navigate('hslTicket', {
                    screen: 'info',
                    params: {},
                  })
                }
                testID="hsl-ticket-card"
                countryToShow={['FI']}
              />
            )}
            <CardItem
              title={t('services.prepaidAndPermits')}
              text={t('services.prepaidAndPermitsDesc')}
              onPress={() => navigate('mainTabs', { screen: 'parkScreen' })}
              {...testIdentifiers('prepaid-and-day-tickets')}
              countryToShow={['SE', 'NO']}
            />
            <CustomChips />
          </>
        </StyledWrapper>
      </>
    );
  };

  if (isWeb) {
    return <Content />;
  }

  return (
    <BGView noMargins>
      <Content />
    </BGView>
  );
};

const MyPermitCard = (item: UnifiedPermit) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<ServicesNavProps>();
  const {
    id,
    isTurkuPmc,
    isWaitingActivation,
    orderLines,
    permitType,
    placeName,
    parkingFacilityName,
  } = item;
  const title = useMemo(() => {
    return permitType === 'ACCESS'
      ? placeName || parkingFacilityName || ' - '
      : orderLines?.[0]?.pmc?.name;
  }, [orderLines, parkingFacilityName, permitType, placeName]);

  const subTitle = useMemo(() => {
    if (permitType === 'ACCESS') {
      return t('permit.access.name');
    }
    return t(
      `permit.${
        isWaitingActivation
          ? 'activatePermit'
          : `type.${orderLines?.[0]?.billingFrequency}`
      }`
    );
  }, [permitType, isWaitingActivation, orderLines, t]);

  const renderImageContainer = () => {
    return (
      <ServiceImage
        testID="image-container"
        turkuPmc={isTurkuPmc || false}
        showGrayedOutIcon={isWaitingActivation}
      />
    );
  };

  const handleNavigation = () => {
    if (permitType === 'ACCESS') {
      navigate('permitRedeem', { permitId: id });
    } else {
      navigate('permit', { permitId: id });
    }
  };

  return (
    <ChipCardItem
      circleImageBackgroundColor={
        item.permitType === 'ACCESS'
          ? theme.colors.lightBg.lilac
          : theme.colors.lightBg.green
      }
      title={title ?? ''}
      subTitle={subTitle ?? ''}
      imageContainer={() => renderImageContainer()}
      onPress={handleNavigation}
      testID="my-permit-chip-card"
      isWaiting={item.permitType === 'ACCESS' ? false : isWaitingActivation}
    />
  );
};

const MyPrepaidCard = (item: Maybe<PredefinedTicket>) => {
  const { navigate } = useNavigation<AccountScreenNavigationProp>();
  return (
    <ChipCardItem
      circleImageBackgroundColor={theme.colors.lightBg.green}
      title={item?.parkZoneName || ''}
      subTitle={item?.name || ''}
      imageContainer={() => (
        <ServiceImage testID="image-container" turkuPmc={false} />
      )}
      onPress={() =>
        navigate('servicesScreen', {
          screen: 'prepaidTicket',
          params: { ticketId: item?.sessionId as string },
        })
      }
      testID="my-prepaid-chip-card"
    />
  );
};

const MyHslTicketCard = (item: HslTicketContent) => {
  return (
    <ChipCardItem
      circleImageBackgroundColor={theme.colors.lightBg.hslBlue}
      title={titleTypeText(item.hslDetails.ticketType)}
      subTitle={validTimeText(item.hslDetails) ?? ''}
      imageContainer={() => <HslTicketImage testID="image-container" />}
      onPress={() => {
        selectedHslTicket({
          ticketId: item.hslDetails.ticketId,
          ticketType: item.hslDetails.ticketType,
        });
        sheetModal('hslTicketRenderView');
      }}
      testID="my-hsl-ticket-chip-card"
    />
  );
};

const MyPermitContents = ({ permits }: { permits?: UnifiedPermit[] }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<NestedTabNavProps>();

  return (
    <>
      <MenuItem
        testID="services-my-permits-title"
        menuLabel={t('services.myPermits')}
        onPress={() =>
          navigate('mainTabs', {
            screen: 'servicesScreen',
            params: {
              screen: 'permits',
              params: {},
            },
          })
        }
        color={theme.colors.black}
        borderColor={theme.colors.gray100}
        noBorder
        withArrow
        arrowSize="s"
        bold
      />
      {permits && permits?.length >= 1 && (
        <HorizontalList
          listData={permits}
          listElement={({ item }) => MyPermitCard(item)}
          withBackground={theme.colors.white}
          {...testIdentifiers('my-permits-list')}
        />
      )}
    </>
  );
};

const MyPrepaidSessionsContents = ({
  prepaidSessions,
}: {
  prepaidSessions?: PredefinedTicket[];
}) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<NestedTabNavProps>();
  return (
    <>
      <MenuItem
        testID="services-my-prepaids-title"
        menuLabel={t('services.myPrepaids')}
        color={theme.colors.black}
        borderColor={theme.colors.gray100}
        noBorder
        withArrow
        arrowSize="s"
        bold
        onPress={() =>
          navigate('mainTabs', {
            screen: 'servicesScreen',
            params: {
              screen: 'prepaidTickets',
            },
          })
        }
      />
      {prepaidSessions && prepaidSessions.length > 0 && (
        <HorizontalList
          listData={prepaidSessions}
          listElement={({ item }) => MyPrepaidCard(item)}
          withBackground={theme.colors.white}
          {...testIdentifiers('my-prepaids-list')}
        />
      )}
    </>
  );
};

const MyHslTicketsContent = ({
  hslTickets,
}: {
  hslTickets: HslTicketContent[];
}) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<NestedTabNavProps>();
  return (
    <>
      <MenuItem
        testID="services-my-hsl-tickets-title"
        menuLabel={t('services.myHslTickets')}
        onPress={() =>
          navigate('hslTicket', {
            screen: 'info',
            params: {},
          })
        }
        color={theme.colors.black}
        borderColor={theme.colors.gray100}
        noBorder
        withArrow
        arrowSize="s"
        bold
      />
      {hslTickets?.length && (
        <HorizontalList
          listData={hslTickets}
          listElement={({ item }) => MyHslTicketCard(item)}
          withBackground={theme.colors.white}
          testID="my-hsl-tickets-list"
        />
      )}
    </>
  );
};

const MyPermits = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const {
    customer: { permits },
  } = useContext(AccountContext);

  if ((permits?.length ?? 0) === 0) {
    return <></>;
  }

  return (
    <StyledWrapper center={isWeb} maxWidth={isWeb ? 600 : undefined}>
      {isLoggedIn && <MyPermitContents permits={permits as UnifiedPermit[]} />}
    </StyledWrapper>
  );
};

const MyPrepaidSessions = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { purchasedPrepaidTickets } = useReadPurchasedPrepaidTickets(true);

  if (purchasedPrepaidTickets.length === 0) {
    return <></>;
  }

  return (
    <StyledWrapper center={isWeb} maxWidth={isWeb ? 600 : undefined}>
      {isLoggedIn && (
        <>
          <MyPrepaidSessionsContents
            prepaidSessions={purchasedPrepaidTickets as PredefinedTicket[]}
          />
        </>
      )}
    </StyledWrapper>
  );
};

const MyHslTickets = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { myHslTickets } = useContext(ServiceContext);

  if (myHslTickets?.length === 0) {
    return <></>;
  }

  return (
    <StyledWrapper center={isWeb} maxWidth={isWeb ? 600 : undefined}>
      {isLoggedIn && (
        <>
          <MyHslTicketsContent hslTickets={myHslTickets} />
        </>
      )}
    </StyledWrapper>
  );
};

const ServiceContent = () => {
  const tabBarHeight = useBottomMenuHeight();
  return (
    <ScreenWrapper
      contentContainerStyle={contentContainerStyle}
      padding={0}
      tabBarHeight={tabBarHeight}>
      <PurchasedServicesWrapper>
        <MyPermits />
        <MyPrepaidSessions />
        {!isWeb && <MyHslTickets />}
      </PurchasedServicesWrapper>
      <ServicesContent />
    </ScreenWrapper>
  );
};

const ServicesDefault: React.FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useIsLoggedIn();
  const tabBarHeight = useBottomMenuHeight();
  const {
    flags: { onboarding_v2 },
  } = useContext(FeatureFlagsContext);
  const useOnboardingV2 = showFeatureFlag(onboarding_v2);
  const useHasAcceptedTerm = useHasAcceptance({ type: 'TERMS_ACCEPTED' });

  const mbForStyledWrapper = () => {
    return useOnboardingV2 ? 70 : 140;
  };

  const renderServiceScreenInWeb = () => {
    return (
      <>
        {useOnboardingV2 ? (
          <StyledImage
            testID="services-main-image"
            width={309}
            height={300}
            center
            source={require('assets/images/illustration-service.png')}
          />
        ) : (
          <StyledImage
            testID="services-main-image"
            width={90}
            height={150}
            center
            source={require('assets/images/contact-us.png')}
          />
        )}
        <LoginStates
          fixedHeader={true}
          loginText={t('services.notSignedInDescription')}
        />
        ;
      </>
    );
  };

  const renderServiceScreenInNative = () => {
    return (
      <>
        {useOnboardingV2 ? (
          <ImageWrapperForOnboardingV2>
            <StyledIllustrationImage
              testID="services-main-image"
              center
              resizeMode="contain"
              source={require('assets/images/illustration-service.png')}
            />
          </ImageWrapperForOnboardingV2>
        ) : (
          <StyledImage
            testID="services-main-image"
            width={90}
            height={150}
            center
            source={require('assets/images/contact-us.png')}
          />
        )}
        <LoginStates
          fixedHeader={false}
          loginText={t('services.notSISUDescription')}
        />
      </>
    );
  };

  const renderer = () =>
    isWeb ? (
      <ScreenWrapper tabBarHeight={tabBarHeight}>
        <StyledWrapper
          maxWidth={350}
          center
          mb={isLoggedIn && useHasAcceptedTerm ? 0 : mbForStyledWrapper()}>
          <Title center={isWeb} {...testIdentifiers('services-main-title')}>
            {t('services.mainTitle')}
          </Title>
        </StyledWrapper>
        {isLoggedIn ? <ServiceContent /> : renderServiceScreenInWeb()}
      </ScreenWrapper>
    ) : (
      <ScreenWrapper
        contentContainerStyle={contentContainerStyle}
        padding={0}
        tabBarHeight={tabBarHeight}>
        <StyledWrapper maxWidth={350} mb={0}>
          <ColumnWithMargins mt={5} ml={20}>
            <Title center={isWeb} {...testIdentifiers('services-main-title')}>
              {t('services.mainTitle')}
            </Title>
          </ColumnWithMargins>
        </StyledWrapper>
        {isLoggedIn ? <ServiceContent /> : renderServiceScreenInNative()}
      </ScreenWrapper>
    );

  return (
    <ScreenContainer
      background={isWeb ? theme.colors.white : theme.colors.gray100}>
      {renderer()}
    </ScreenContainer>
  );
};

export default ServicesDefault;
