import { useReactiveVar } from '@apollo/client';
import Animated from 'react-native-reanimated';
import VehicleAvatar from 'components/common/VehicleAvatar';
import {
  ChargingHeaderTexts,
  CollapseButton,
  ListButton,
  StopButton,
  LoadingSpinner,
} from 'components/ongoingParking/OngoingParkingComponents';
import { OngoingParkingHeaderWrapper } from 'components/ongoingParking/styles';
import useOngoingParkingSession from 'hooks/useOngoingParkingSession';
import useVehicle from 'hooks/useVehicle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ongoingChargingSheetExpanded,
  ongoingParkingSession,
  sheetModal,
} from 'states/common';
import { ChargingSession, ChargingStatus } from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';
import OngoingSessionHeaderContainer from 'components/ongoingParking/OngoingSessionHeaderContainer';
import useOngoingSessionsSheet from 'hooks/useOngoingSessionsSheet';

type ChargingHeaderProps = {
  session: ChargingSession;
};

const getChargingModalTitle = (
  status: ChargingStatus,
  sheetExpanded: boolean
) => {
  if (status === 'IN_PROGRESS') {
    return sheetExpanded
      ? 'evCharging.modal.titleSheetExpanded'
      : 'evCharging.modal.title';
  }
  if (status === 'START_IN_QUEUE') {
    return 'evCharging.modal.startingTitle';
  }
  return 'evCharging.modal.stoppingTitle';
};

const OngoingChargingHeader: React.FC<ChargingHeaderProps> = ({ session }) => {
  const { t } = useTranslation();
  const licensePlate = session?.licensePlate || '';
  const { status, chargingSessionId } = session || {};
  const chargingOngoing = status === 'IN_PROGRESS';
  const chargingStopping = status === 'STOP_IN_QUEUE';
  const { sessionId: ongoingParkingSessionId } = useReactiveVar(
    ongoingParkingSession
  );
  const ongoingChargingSheetExp = useReactiveVar(ongoingChargingSheetExpanded);
  const titleText = t(
    getChargingModalTitle(status || 'IN_PROGRESS', ongoingChargingSheetExp)
  );
  const { color, icon, label } = useVehicle(licensePlate);
  const { allParkingSessions } = useOngoingParkingSession();
  const [isMultipleSession, setIsMultipleSession] = useState(false);
  const { collapsedSheetContainerStyle, expandedSheetContainerStyle } =
    useOngoingSessionsSheet();

  useEffect(() => {
    setIsMultipleSession(
      !!ongoingParkingSessionId ||
        !!(allParkingSessions && allParkingSessions.length > 1)
    );
  }, [ongoingParkingSessionId, allParkingSessions]);

  if (!chargingSessionId) {
    return <></>;
  }

  return (
    <>
      <OngoingSessionHeaderContainer
        expandedState={ongoingChargingSheetExpanded}
        sessionId={chargingSessionId}
        testID="ongoing-charging-container">
        <OngoingParkingHeaderWrapper mr={20} testID="vehicle-avatar-container">
          <VehicleAvatar size="l" color={color} icon={icon} />
        </OngoingParkingHeaderWrapper>
        <OngoingParkingHeaderWrapper mr={20} grow>
          <ChargingHeaderTexts title={titleText} vehicleLabel={label} />
        </OngoingParkingHeaderWrapper>
        <Animated.View
          style={expandedSheetContainerStyle}
          testID="ongoing-charging-header-expanded">
          <>
            {isMultipleSession && (
              <OngoingParkingHeaderWrapper mr={20}>
                <ListButton />
              </OngoingParkingHeaderWrapper>
            )}
            {!isWeb && (
              <OngoingParkingHeaderWrapper>
                <CollapseButton
                  isMultipleSession={isMultipleSession}
                  state={ongoingChargingSheetExpanded}
                />
              </OngoingParkingHeaderWrapper>
            )}
          </>
        </Animated.View>
        {chargingOngoing && (
          <Animated.View
            style={collapsedSheetContainerStyle}
            testID="ongoing-charging-header-collapsed">
            <OngoingParkingHeaderWrapper mr={20}>
              <StopButton
                testID="ongoing-charging-stop-button"
                onPress={() => sheetModal('stopCharging')}
              />
            </OngoingParkingHeaderWrapper>
          </Animated.View>
        )}
        {chargingStopping && (
          <Animated.View
            style={collapsedSheetContainerStyle}
            testID="ongoing-charging-stopping">
            <LoadingSpinner isHeader={true} />
          </Animated.View>
        )}
      </OngoingSessionHeaderContainer>
    </>
  );
};

export default OngoingChargingHeader;
