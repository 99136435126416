import { useReactiveVar } from '@apollo/client';
import { HorizontalRadioListData } from 'aimo-ui';
import { parkingHistoryFilter } from 'states/common';
import { Vehicle } from 'types/generatedSchemaTypes';
import { t } from 'i18n/init';
export type ParkingTypeFilter = 'parking' | 'charging' | 'permits' | undefined;
export type HistoryTypeFilter =
  | 'receipts'
  | 'free'
  | 'failed'
  | 'billing'
  | 'other'
  | undefined;
export type PaymentFilter = 'private' | 'business' | 'invoice' | undefined;
export type ParkingHistoryFilters =
  | {
      parkingType?: ParkingTypeFilter;
      payment?: PaymentFilter;
      vehicle?: Vehicle;
    }
  | undefined;

const useParkingHistoryFilter = () => {
  const filter = useReactiveVar(parkingHistoryFilter);
  const setFilter = (newValue: ParkingHistoryFilters) => {
    parkingHistoryFilter(newValue);
  };
  const clearFilter = () => {
    parkingHistoryFilter(undefined);
  };
  const parkingTypesFilterOptions: HorizontalRadioListData<ParkingTypeFilter>[] =
    [
      { title: t('parkingHistoryFilter.filterOptions.all'), value: undefined },
      {
        title: t('parkingHistoryFilter.filterOptions.parking'),
        value: 'parking',
      },
      {
        title: t('parkingHistoryFilter.filterOptions.charging'),
        value: 'charging',
      },
    ];
  const paymentFilterOptions: HorizontalRadioListData<PaymentFilter>[] = [
    { title: t('parkingHistoryFilter.filterOptions.all'), value: undefined },
    {
      title: t('parkingHistoryFilter.filterOptions.private'),
      value: 'private',
    },
    {
      title: t('parkingHistoryFilter.filterOptions.business'),
      value: 'business',
    },
    {
      title: t('parkingHistoryFilter.filterOptions.invoice'),
      value: 'invoice',
    },
  ];
  const historyViewTypeFilters: HorizontalRadioListData<HistoryTypeFilter>[] = [
    {
      title: t('parkingHistoryFilter.historyViewTypeOptions.receipts'),
      value: 'receipts',
    },
    {
      title: t('parkingHistoryFilter.historyViewTypeOptions.free'),
      value: 'free',
    },
    {
      title: t('parkingHistoryFilter.historyViewTypeOptions.failed'),
      value: 'failed',
    },
    {
      title: t('parkingHistoryFilter.historyViewTypeOptions.billing'),
      value: 'billing',
    },
    {
      title: t('parkingHistoryFilter.historyViewTypeOptions.other'),
      value: 'other',
    },
  ];
  return {
    parkingType: filter?.parkingType,
    payment: filter?.payment,
    vehicle: filter?.vehicle,
    filter,
    filterActive:
      filter !== undefined &&
      (!!filter.parkingType || !!filter.payment || !!filter.vehicle),
    clearFilter,
    setFilter,
    parkingTypesFilterOptions,
    paymentFilterOptions,
    historyViewTypeFilters,
  };
};
export default useParkingHistoryFilter;
