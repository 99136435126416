import { useReactiveVar } from '@apollo/client';
import { snappingPointsWebMobile } from 'components/park/ParkingWizardSheet';
import ConnectCharger from 'components/park/evcharge/ConnectCharger';
import SelectCharger from 'components/park/evcharge/SelectCharger';
import SelectTariff from 'components/park/evcharge/SelectTariff';
import { WaitChargingStart } from 'components/park/evcharge/WaitChargingStart';
import ParkingWizardFooterSwitch from 'components/park/shortTermParkingWizard/ParkingWizardFooterSwitch';
import Sheet from 'components/sheets/Sheet';
import ParkingContext from 'context/ParkingContext';
import React, { useContext, useEffect } from 'react';
import { isFullScreen, selectedTariffId } from 'states/common';
import { isWeb } from 'utils/deviceUtils';

const OngoingParkingWizardSheet = () => {
  const { currentScreen } = useContext(ParkingContext);
  let snappingPoints;
  const fullScreen = useReactiveVar(isFullScreen);
  let Component;

  useEffect(() => {
    return () => {
      selectedTariffId('');
    };
  });

  switch (currentScreen) {
    case 'SELECT_TARIFF':
      snappingPoints = snappingPointsWebMobile(['80%'], ['70%', '100%']);
      Component = SelectTariff;
      break;
    case 'SELECT_CHARGER':
      snappingPoints = snappingPointsWebMobile(['80%'], ['70%', '100%']);
      Component = SelectCharger;
      break;
    case 'CONNECT_CHARGER':
      snappingPoints = snappingPointsWebMobile(['80%'], ['95%']);
      Component = ConnectCharger;
      break;
    default:
      snappingPoints = snappingPointsWebMobile(['80%'], ['40%', '100%']);
      Component = WaitChargingStart;
      break;
  }

  return (
    <Sheet
      snappingPoints={snappingPoints[isWeb ? 'web' : 'mobile']}
      showBackDropIndex={fullScreen ? 0 : 1}
      stickyFooter={<ParkingWizardFooterSwitch />}>
      <Component />
    </Sheet>
  );
};
export default OngoingParkingWizardSheet;
