import { useReactiveVar } from '@apollo/client';
import GoToSignIn from 'components/park/detail/GoToSignIn';
import FirstTimeButton from 'components/tutorial/FirstTimerButton';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useTutorialSheet from 'hooks/useTutorialSheet';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';
import { parkzoneSearchText, searchInputActive } from 'states/common';
import styled from 'styled-components/native';
import { ParkZone } from 'types/generatedSchemaTypes';
import useDebounce from '../../hooks/useDebounce';
import SearchInput from '../SearchInput/SearchInput';
import ParkingZoneSearchList from './ParkingZoneSearchList';
import ParkingZonesByDistance from './ParkingZonesByDistance';

const Wrapper = styled.Pressable`
  padding: 0 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
`;
const pressHandler = () => {
  Keyboard.dismiss();
};

export type ParkZoneWithVisibility = ParkZone & ParkZoneMemberGroup;

export type ParkZoneMemberGroup = {
  memberGroupName?: string;
};

const ParkingZoneSearch: React.FC<{
  withLTP?: boolean;
  isTesting?: boolean;
}> = ({ withLTP = false, isTesting = false }) => {
  const { t } = useTranslation();
  const searchFocus = useReactiveVar(searchInputActive);
  const persistedSearch = useReactiveVar(parkzoneSearchText);
  const debouncedSearchTerm = useDebounce(persistedSearch, 300);
  const { isLoggedIn } = useIsLoggedIn();
  const { canOpenTutorial } = useTutorialSheet({
    selectedTutorial: 'firstTimer',
  });

  useEffect(() => {
    parkzoneSearchText(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <>
      <Wrapper onPress={pressHandler} accessible>
        <>
          <SearchInput
            placeholder={t('search.placeholder')}
            value={persistedSearch}
            clearable={true}
            onChangeText={parkzoneSearchText}
            testID="park-search-input"
            isTesting={isTesting}
          />
          {!isLoggedIn && <GoToSignIn />}
          {isLoggedIn && canOpenTutorial && <FirstTimeButton />}
          {searchFocus ? (
            <ParkingZoneSearchList
              testID="search-results"
              searchTerm={debouncedSearchTerm}
              withLTP={withLTP}
            />
          ) : (
            <ParkingZonesByDistance
              testID="nearest-parking-zones"
              withLTP={withLTP}
            />
          )}
        </>
      </Wrapper>
    </>
  );
};

export default ParkingZoneSearch;
