import { ApolloError, useQuery, useReactiveVar } from '@apollo/client';
import {
  addMinutes,
  Duration,
  intervalToDuration,
  startOfMinute,
} from 'date-fns';
import { READ_PARK_ZONE_PRICE_ESTIMATES } from 'graphql/queries/readParkZonePriceEstimates';
import useAllowedParkingTimes from 'hooks/useAllowedParkingTimes';
import { parse } from 'iso8601-duration';
import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { selectedTariffId } from 'states/common';
import {
  Maybe,
  ParkZonePriceEstimateInput,
  PredefinedTicket,
  PriceEstimate,
  Query,
  QueryReadParkZonePriceEstimatesArgs,
} from 'types/generatedSchemaTypes';
import { addHoursToDate, handlePrefixedStartorEndtime } from 'utils/timeUtils';
import { getCustomerSegmentUid } from 'utils/parkUtils';

export type DurationSuggestion = {
  duration: number;
  index: number;
  estimatedPrice?: number | 'loading' | 'error';
  serviceFee?: number;
  name?: string;
  type?: string;
  durationType?: string;
  endTime?: string;
  startTime?: string;
  timeUnit?: 'MINUTES' | 'HOURS';
};

export type PricingSuggestion = {
  duration: number;
  type: string | undefined;
  name?: string;
  durationType?: string;
  endTime?: string;
  startTime?: string;
  timeUnit?: 'MINUTES' | 'HOURS';
};

const estimatesInput = (
  parkingZoneUid: string,
  startDate: Date,
  pricingSuggestions?: PricingSuggestion[],
  customerSegmentUid?: string,
  serviceFee?: number
): ParkZonePriceEstimateInput[] => {
  return (pricingSuggestions || []).map((item) => ({
    parkingZoneUid,
    startDate,
    endDate: setEndDateByType(startDate, item),
    customerSegmentUid,
    serviceFee,
  }));
};

const setEndDateByType = (startDate: Date, item: PricingSuggestion) => {
  if (item?.durationType === 'FIXED_TIME') {
    let endDate = handlePrefixedStartorEndtime(item.endTime as string);
    if (endDate < startDate || endDate === startDate) {
      endDate = new Date(endDate.setDate(endDate.getDate() + 1));
    }
    return endDate;
  }
  if (item.timeUnit === 'MINUTES') {
    return addMinutes(startDate, item.duration);
  }
  if (item.duration < 1) {
    return addMinutes(startDate, item.duration * 60);
  }
  return addHoursToDate(startDate, item.duration);
};

type DurationToHourSuggestion = {
  duration: number;
  type: string;
  timeUnit?: 'MINUTES' | 'HOURS';
};
const durationToHourSuggestions = ({
  days,
  hours,
  minutes,
}: Duration): DurationToHourSuggestion[] => {
  if ((days && days >= 1) || (hours && hours >= 8)) {
    return [
      { duration: 1, type: 'DEFAULT', timeUnit: 'HOURS' },
      { duration: 2, type: 'DEFAULT', timeUnit: 'HOURS' },
      { duration: 4, type: 'DEFAULT', timeUnit: 'HOURS' },
      { duration: 8, type: 'DEFAULT', timeUnit: 'HOURS' },
    ];
  }
  if (!days && !hours && minutes && minutes > 0) {
    return [{ duration: minutes, type: 'DEFAULT', timeUnit: 'MINUTES' }];
  }
  return [{ duration: 1, type: 'DEFAULT', timeUnit: 'HOURS' }];
};

const predefinedDuration = (value: string): number => {
  if (value === null) {
    return 0;
  }
  const { hours, minutes } = parse(value);
  if (hours && !minutes) {
    return hours;
  }
  if (!hours && minutes) {
    return minutes / 60;
  }
  if (hours && minutes) {
    const minutesToHours = minutes / 60;
    const totalHours = hours + minutesToHours;
    return totalHours;
  }
  return 0;
};

const suggestDurations = (
  parkingAllowedFrom: string,
  parkingAllowedUntil: string,
  showDefaultChips: boolean,
  error?: ApolloError,
  predefinedTickets?: Maybe<PredefinedTicket>[]
): PricingSuggestion[] => {
  if (!parkingAllowedFrom || !parkingAllowedUntil || error) {
    return [];
  }
  const defaultDurations = durationToHourSuggestions(
    intervalToDuration({
      start: startOfMinute(new Date(parkingAllowedFrom)),
      end: startOfMinute(new Date(parkingAllowedUntil)),
    })
  );

  const predefinedDurations =
    predefinedTickets?.map((ticket) => ({
      duration: predefinedDuration(ticket?.duration as string),
      name: ticket?.name as string,
      type: 'PREDEFINED',
      durationType: ticket?.type,
      endTime: ticket?.endTime as string,
      startTime: ticket?.startTime as string,
    })) || [];

  if (predefinedDurations.length > 0) {
    return predefinedDurations;
  }
  return showDefaultChips ? defaultDurations : [];
};

const buildEstimates = (
  durations: PricingSuggestion[],
  estimates: Maybe<Maybe<PriceEstimate>[]> = [],
  estimatesLoading: boolean,
  estimatesError?: ApolloError
): DurationSuggestion[] => {
  const getPrice = (index: number) => {
    if (estimatesLoading) {
      return 'loading';
    }
    if (estimatesError) {
      return 'error';
    }
    const totalAmount = estimates?.[index]?.totalAmount;
    return isNumber(totalAmount) ? totalAmount : undefined;
  };

  const getServiceFee = (index: number) => {
    const serviceFee = estimates?.[index]?.breakdown?.serviceFee?.amount;
    return isNumber(serviceFee) ? serviceFee : undefined;
  };

  return durations.map((item, index) => ({
    duration: item.duration,
    index,
    estimatedPrice: getPrice(index),
    serviceFee: getServiceFee(index),
    type: item.type,
    name: item.name,
    durationType: item.durationType,
    endTime: item.endTime,
    startTime: item.startTime,
    timeUnit: item.timeUnit,
  }));
};

const useSuggestedPricingEstimates = (
  parkingZoneUid: string,
  startDate: Date,
  showDefaultChips = true,
  serviceFee?: number
) => {
  const {
    parkingAllowedFrom,
    parkingAllowedUntil,
    pricingInfoError,
    predefinedTickets,
  } = useAllowedParkingTimes(parkingZoneUid, true);

  const durations = useMemo(
    () =>
      suggestDurations(
        parkingAllowedFrom,
        parkingAllowedUntil,
        showDefaultChips,
        pricingInfoError,
        predefinedTickets.filter((ticket) => ticket?.sessionType !== 'PREPAID')
      ),
    [
      parkingAllowedFrom,
      parkingAllowedUntil,
      showDefaultChips,
      pricingInfoError,
      predefinedTickets,
    ]
  );

  const selectedCustomerSegment = useReactiveVar(selectedTariffId);

  const customerSegmentUid = getCustomerSegmentUid(selectedCustomerSegment);

  const {
    data: estimatesData,
    loading: estimatesLoading,
    error: estimatesError,
  } = useQuery<Query, QueryReadParkZonePriceEstimatesArgs>(
    READ_PARK_ZONE_PRICE_ESTIMATES,
    {
      variables: {
        input: estimatesInput(
          parkingZoneUid,
          startDate,
          durations,
          customerSegmentUid,
          serviceFee
        ),
      },
      skip: !parkingZoneUid && !!durations,
    }
  );

  return useMemo(() => {
    if (durations === undefined) {
      return [];
    }
    return buildEstimates(
      durations,
      estimatesData?.readParkZonePriceEstimates,
      estimatesLoading,
      estimatesError
    );
  }, [
    estimatesData?.readParkZonePriceEstimates,
    durations,
    estimatesLoading,
    estimatesError,
  ]);
};

export default useSuggestedPricingEstimates;
