import { Meta, theme } from 'aimo-ui';
import QuickActionDirection from 'components/common/QuickActionDirection';
import Icon from 'components/icons/Icon';
import usePaymentDetails from 'hooks/usePaymentDetails';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TicketRow } from 'screens/permit/PermitTicketContent';
import RemainingValidityIndicator from 'screens/permit/RemainingValidityIndicator';
import {
  PermitContentWrapper,
  RowContainer,
  RowMeta,
} from 'screens/permit/styles';
import {
  AccessDevice,
  ParkZone,
  ParkingSession,
} from 'types/generatedSchemaTypes';
import { makePoint } from 'utils/mapUtils';
const PrepaidTicketContent = ({ session }: { session: ParkingSession }) => {
  const { t } = useTranslation();
  const { accessDevice, predefinedEndDate, parkZone, cardId, sessionId } =
    session;
  const { value } = accessDevice as AccessDevice;
  const { address, commercialZoneView } = parkZone as ParkZone;
  const marker = address?.marker;
  const markerCoordinate = marker && makePoint(marker?.coordinates);
  const cardString = usePaymentDetails({ cardId });

  return (
    <PermitContentWrapper>
      <TicketRow title={t('services.location')}>
        <RowContainer>
          <RowMeta testID={`prepaid-${sessionId}-location`}>
            {`${commercialZoneView?.name || parkZone?.name}`}
          </RowMeta>
          <QuickActionDirection
            showIconLabel={false}
            name={commercialZoneView?.name || parkZone?.name}
            markerCoordinate={markerCoordinate}
            alignEnd
          />
        </RowContainer>
      </TicketRow>
      <TicketRow
        title={t('services.permitVehicle')}
        testID={`prepaid-${sessionId}-vehicle`}>
        <Meta testID={`prepaid-${sessionId}-vehicle`}>{value}</Meta>
      </TicketRow>
      <TicketRow
        title={t('longTermParking.parkingSpace')}
        content={t('longTermParking.any')}
        testID={`prepaid-${sessionId}-parking-space`}
      />
      <TicketRow
        title={t('services.paymentMethod')}
        content={typeof cardString === 'string' ? cardString : undefined}
        testID={`prepaid-${sessionId}-payment-method`}
      />
      <TicketRow
        title={t('services.validity')}
        testID={`permit-${sessionId}-validity`}>
        <RowContainer>
          <RemainingValidityIndicator
            isRecurring={false}
            expirationDate={predefinedEndDate}
            permitStatus={undefined}
            permitId={sessionId}
            isPrepaid
          />
        </RowContainer>
      </TicketRow>
      <TicketRow
        titleColor={theme.colors.black}
        title={t('services.viewDetails')}
        divider={false}>
        <Icon
          name="Chevron_Right"
          size="s"
          testID={`perpaid-${sessionId}-view-details`}
        />
      </TicketRow>
    </PermitContentWrapper>
  );
};

export default PrepaidTicketContent;
