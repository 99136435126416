import { ApolloError } from '@apollo/client';
import * as React from 'react';
import {
  HslTicketContent,
  ParkingSession,
  PermitsProps,
} from 'types/generatedSchemaTypes';

export const emptyMyPermits: PermitsProps = {
  content: [],
};

export type ServiceContextProps = {
  myPrepaidSessions?: ParkingSession[];
  myHslTickets: HslTicketContent[];
  loading?: boolean;
  error?: ApolloError;
  prepaidLoading?: boolean;
  prepaidError?: ApolloError;
};

const ServiceContext = React.createContext<ServiceContextProps>({
  myPrepaidSessions: [],
  myHslTickets: [],
  loading: false,
  error: undefined,
  prepaidLoading: false,
  prepaidError: undefined,
});

export default ServiceContext;
