import { useReactiveVar } from '@apollo/client';
import { theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import useBottomSheet from 'hooks/useCustomBottomSheet';
import React, { useEffect, useRef } from 'react';
import { TextInput, TextInputProps } from 'react-native';
import { searchInputActive } from 'states/common';
import {
  CloseWrapper,
  IconWrapper,
  searchInputStyles,
  Wrapper,
} from './styles';
import { BottomSheetTextInput } from '@gorhom/bottom-sheet';
import { isWeb } from 'utils/deviceUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

type SearchInputProps = {
  testID: string;
  small?: boolean;
  clearable?: boolean;
  isTesting?: boolean;
  marginBottom?: number;
} & TextInputProps;

const SearchInput = ({
  testID,
  value,
  placeholder,
  small = true,
  clearable = false,
  isTesting = false,
  marginBottom,
  onChangeText,
  ...props
}: SearchInputProps) => {
  const inputRef = useRef<TextInput | any>(null);
  const { expand, collapse } = useBottomSheet();
  const searchFocus = useReactiveVar(searchInputActive);
  const CustomInput = isWeb || isTesting ? TextInput : BottomSheetTextInput;

  useEffect(() => {
    if (value) {
      setTimeout(expand);
    }
    if (!value && !searchFocus) {
      setTimeout(collapse);
    }
  }, [value, searchFocus, expand, collapse]);

  useEffect(() => {
    if (!searchFocus) {
      inputRef.current?.blur();
    }
  }, [searchFocus]);

  const handleChangeText = (changedText: string) => {
    onChangeText?.(changedText);
  };

  const handlePress = () => {
    inputRef.current?.focus();
    searchInputActive(true);
    expand();
  };

  const handleFocus = () => {
    searchInputActive(true);
    expand();
  };

  const clearInput = () => {
    handleChangeText('');
    inputRef.current?.blur();
  };

  return (
    <Wrapper
      onPress={handlePress}
      {...testIdentifiers(testID)}
      accessible={false}
      small={small}
      marginBottom={marginBottom}>
      <IconWrapper>
        <Icon
          name="search"
          size="s"
          color={theme.colors.gray200}
          testID={`${testID}-icon`}
        />
      </IconWrapper>
      <CustomInput
        ref={inputRef}
        value={value}
        placeholder={placeholder}
        placeholderTextColor={theme.colors.gray200}
        onChangeText={handleChangeText}
        onFocus={handleFocus}
        {...testIdentifiers(`${testID}-input`)}
        style={searchInputStyles.input}
        {...props}
      />
      {clearable && value !== '' && (
        <CloseWrapper
          onPress={clearInput}
          testID={`${testID}-clear`}
          accessible
          small={small}>
          <Icon
            name="close"
            size="s"
            color={theme.colors.gray200}
            testID={`${testID}-close-icon`}
          />
        </CloseWrapper>
      )}
    </Wrapper>
  );
};

export default SearchInput;
