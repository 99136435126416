import Icon from 'components/icons/Icon';
import React from 'react';
import styled from 'styled-components/native';

const BadgeContainer = styled.View<{ backgroundColor?: string }>`
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  background-color: ${(p) => p.backgroundColor || p.theme.colors.gray100};
`;

const IconBadge: React.FC<{ name: string; backgroundColor?: string }> = ({
  name,
  backgroundColor,
}) => {
  return (
    <BadgeContainer
      backgroundColor={backgroundColor}
      testID={`icon-badge-${name}`}
      >
      <Icon name={name}/>
     </BadgeContainer>
  );
};

export default IconBadge;
