const useCustomBottomSheet = () => {
  return {
    expand: () => undefined,
    collapse: () => undefined,
    snapToIndex: () => undefined,
    close: () => undefined,
    forceClose: () => undefined,
    animatedIndex: () => undefined,
  };
};

export default useCustomBottomSheet;
