import HslTicketInfoView from 'components/park/hslTicket/HslTicketInfoView';
import HslContextProvider from 'context/HslContextProvider';
import React from 'react';

type HslTicketScreenProps = {
  mockDateTime?: Date;
};

const HslTicketScreen: React.FC<HslTicketScreenProps> = ({ mockDateTime }) => {
  return (
    <HslContextProvider>
      <HslTicketInfoView mockDateTime={mockDateTime} />
    </HslContextProvider>
  );
};

export default HslTicketScreen;
