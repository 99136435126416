import React, { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHeaderHeight } from '@react-navigation/elements';
import { BottomButtonsLayout, Button, theme } from 'aimo-ui';
import { isWeb } from 'utils/deviceUtils';
import { ColumnWithMargins, ScreenWrapper } from 'styles/ContainerStyles';
import SelectVehiclesModalContent from 'components/park/SelectVehiclesModalContent';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { ServicesNavProps, ServicesStackParamList } from 'types/navigation';
import { showToast } from 'components/common/CustomToast';
import { LicensePlate, Permit } from 'types/generatedSchemaTypes';
import { selectedLicensePlatesPermitRedeem } from 'states/common';
import { testIdentifiers } from 'utils/testIdentifiers';
import VehicleAddSection from 'components/account/vehicle/VehicleAddSection';
import Shadow from 'components/common/Shadow';
import AccountContext from 'context/AccountContext';

const ModalContentWrapper = ({ children }: { children: ReactNode }) => {
  const headerHeight = useHeaderHeight();

  return isWeb ? (
    <ScreenWrapper tabBarHeight={headerHeight}>{children}</ScreenWrapper>
  ) : (
    <ColumnWithMargins ml={20} mr={20}>
      {children}
    </ColumnWithMargins>
  );
};

const AccessPermitSelectVehicle: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<ServicesNavProps>();
  const {
    params: { permitId },
  } = useRoute<RouteProp<ServicesStackParamList, 'permitVehicles'>>();
  const [selectedLicensePlates, setSelectedLicensePlates] = React.useState<
    LicensePlate[]
  >([]);
  const {
    customer: { permits },
  } = useContext(AccountContext);
  const permit = permits?.find((p) => p?.id === permitId) as Permit;

  const handleSave = () => {
    selectedLicensePlatesPermitRedeem(
      selectedLicensePlates.map(({ __typename, ...rest }) => ({ ...rest }))
    );
    navigate('permitRedeem', { permitId });
  };

  const handleAddVehicle = () => {
    navigate('addVehiclePermit', { permitId });
  };

  if (!permit?.maxLicensePlates) {
    showToast(t('error.readPermit'), 'error');
    navigate('servicesMain');
    return <></>;
  }

  return (
    <BottomButtonsLayout
      contentBottom={
        <Shadow>
          <ColumnWithMargins
            mr={20}
            ml={20}
            mt={20}
            mb={theme.normalising.heightPixel(30)}>
            <Button
              size="l"
              onPress={handleSave}
              text={t('button.save')}
              testID="redeem-permit-button"
            />
          </ColumnWithMargins>
        </Shadow>
      }>
      <ModalContentWrapper>
        <SelectVehiclesModalContent
          maxLicensePlates={permit.maxLicensePlates}
          handleOnChange={setSelectedLicensePlates}
        />
        <ColumnWithMargins {...testIdentifiers('add-vehicle-list-item-button')}>
          <VehicleAddSection onPress={handleAddVehicle} style="LIST" />
        </ColumnWithMargins>
      </ModalContentWrapper>
    </BottomButtonsLayout>
  );
};

export default AccessPermitSelectVehicle;
