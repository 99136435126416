import { useReactiveVar } from '@apollo/client';
import AccountRemovalErrors from 'components/account/AccountRemovalErrors';
import AccountRemovalFailed from 'components/account/AccountRemovalFailed';
import AccountRemovalPrevented from 'components/account/AccountRemovalPrevented';
import AccountRemovalPreventedLinkedService from 'components/account/AccountRemovalPreventedLinkedService';
import AccountRemovalSuccess from 'components/account/AccountRemovalSuccess';
import AccountRestrictedModalContent from 'components/account/AccountRestrictedModalContent';
import AppLocation from 'components/account/AppLocation';
import ConfirmVehicleRemoval from 'components/account/ConfirmVehicleRemoval';
import Logout from 'components/account/Logout';
import RemoveAccount from 'components/account/RemoveAccount';
import UnsavedEditVehicleConfirm from 'components/account/UnsavedEditVehicleConfirm';
import SetParkingReminderModalContent from 'components/account/notifications/SetParkingReminderModalContent';
import ConfirmPaymentMethodRemoval from 'components/account/paymentMethods/ConfirmPaymentMethodRemoval';
import PaymentCardContent from 'components/account/paymentMethods/PaymentCardContent';
import LanguageList from 'components/language/LanguageList';
import ExtendOngoingParkingConfirm from 'components/ongoingParking/ExtendParkingConfirm';
import OngoingMultipleSessionsModalContent from 'components/ongoingParking/OngoingMultipleSessionsModalContent';
import StopOngoingParkingConfirm from 'components/ongoingParking/StopParkingConfirm';
import ConfirmParkingClosedZoneModalContent from 'components/park/ConfirmParkingClosedZoneModalContent';
import SelectPaymentMethodModalContent from 'components/park/SelectPaymentMethodModalContent';
import SelectVehicleModalContent from 'components/park/SelectVehicleModalContent';
import StreetParkingLocationConfirmModal from 'components/park/StreetParkingLocationConfirmModal';
import UpdateFeatureCountryModal from 'components/park/UpdateFeatureCountryModal';
import PredefinedTicketAndPermitList from 'components/park/detail/PredefinedTicketAndPermitList';
import StopCharging from 'components/park/evcharge/StopCharging';
import DefaultPaymentMethodModalContent from 'components/park/pooling/DefaultPaymentMethodModalContent';
import NoPaymentRequiredModalContent from 'components/park/pooling/NoPaymentRequiredModalContent';
import SelectTariffModalContent from 'components/park/tariff/SelectTariffModalContent';
import UnlockTariffModalContent from 'components/park/tariff/UnlockTariffModalContent';
import CreditCardForm from 'components/payment/CreditCardForm';
import FirstTimer from 'components/tutorial/FirstTimer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactUsSelectCountry from 'screens/account/ContactUsSelectCountry';
import ParkingHistoryFilterModalContent from 'screens/account/ParkingHistoryFilterModalContent';
import RetryPaymentModalContent from 'screens/account/RetryPaymentModalContent';
import TermsPdfModalContent from 'screens/account/TermsPdfModalContent';
import HslTicketRenderModalContent from 'screens/hslTicket/HslTicketRenderModalContent';
import { UnavailableModalContent } from 'screens/longTermParking';
import ConfirmCancelPermitModalContent from 'screens/permit/ConfirmCancelPermitModalContent';
import ConfirmPurchasePermitModalContent from 'screens/permit/ConfirmPurchasePermitModalContent';
import ItemUnavailableModalContent from 'screens/permit/ItemUnavailableModalContent';
import PrepaidTicketConfirmModalContent from 'screens/prepaidTickets/PrepaidTicketConfirmModalContent';
import {
  accountRemoveWithWarnings,
  activeElement,
  confirmCreatePermitData,
  selectedHslTicket,
  selectedPaymentCard,
  sheetModal,
} from 'states/common';
import { titleTypeText } from 'utils/hslUtils';
import RNWebModal from '../common/RNWebModal';
import OpenHoursModalContent from '../park/OpenHoursModalContent';
import RatesModalContent from '../park/RatesModalContent';

const Empty = () => <></>;

const Modal: React.FC = () => {
  const { t } = useTranslation();
  const modalType = useReactiveVar(sheetModal);
  const paymentCard = useReactiveVar(selectedPaymentCard);
  const hslTicket = useReactiveVar(selectedHslTicket);
  const activeZoneId = useReactiveVar(activeElement);
  const canContinueRemoval = useReactiveVar(accountRemoveWithWarnings);
  const { meta: { freeSalesOrder = false } = {} } =
    confirmCreatePermitData() || {};
  let Component: React.FC<any> = Empty;
  let headline;
  let subtitle;
  let allowScrollableContent = true;
  //Right now these 3 are not used in any specific modalContent, but the modal
  // has the capacity to use them, when needed again change the corresponding
  // variable to let
  const backDropOpacity = 0.25;
  const forceOpen = false;
  const hideCloseButton = false;

  switch (modalType) {
    case 'parkingRates':
      Component = RatesModalContent;
      headline = t('price.rates');
      break;
    case 'parkingHours':
      Component = OpenHoursModalContent;
      headline = t('parking.openHours');
      break;
    case 'predefinedTicketsAndPermits':
      Component = PredefinedTicketAndPermitList;
      headline = t('parking.prepaidTickets.prepaidListLabel');
      break;
    case 'parkingTariffs':
      Component = SelectTariffModalContent;
      headline = t('parking.tariff.selectTariff');
      break;
    case 'signOut':
      Component = Logout;
      headline = t('account.signOutConfirmHeader');
      break;
    case 'addPaymentMethod':
      Component = CreditCardForm;
      headline =
        paymentCard.status === 'Verifying'
          ? t('paymentMethods.verifying')
          : t('payment.addPaymentCard');
      allowScrollableContent = false;
      break;
    case 'parkingVehicle':
      Component = SelectVehicleModalContent;
      headline = t('parking.parkingProcess.selectVehicle');
      break;
    case 'stopOngoingParking':
      Component = StopOngoingParkingConfirm;
      headline = t('parking.stopParkingConfirm');
      break;
    case 'extendOngoingParking':
      Component = ExtendOngoingParkingConfirm;
      headline = t('parking.extendParkingConfirm');
      break;
    case 'showOngoingMultipleSessions':
      Component = OngoingMultipleSessionsModalContent;
      headline = t('parking.ongoingActiveServices');
      break;
    case 'parkingPaymentMethod':
      Component = SelectPaymentMethodModalContent;
      headline = t('parking.parkingProcess.selectPaymentMethod');
      break;
    case 'unsavedEditVehicle':
      Component = UnsavedEditVehicleConfirm;
      headline = t('vehicle.unsavedChanges.title');
      break;
    case 'deleteVehicle':
      Component = ConfirmVehicleRemoval;
      headline = t('vehicle.removeVehicle');
      break;
    case 'paymentCard':
      Component = PaymentCardContent;
      headline = paymentCard.title;
      subtitle = paymentCard.subtitle;
      break;
    case 'confirmPaymentRemoval':
      Component = ConfirmPaymentMethodRemoval;
      headline = paymentCard.title;
      subtitle = paymentCard.subtitle;
      break;
    case 'productUnavailable':
      Component = UnavailableModalContent;
      headline = t('longTermParking.unavailableHeadline');
      break;
    case 'parkingHistoryFilter':
      Component = ParkingHistoryFilterModalContent;
      headline = t('parkingHistoryFilter.headline');
      allowScrollableContent = false;
      break;
    case 'stopCharging':
      Component = StopCharging;
      headline = t('evCharging.stop');
      break;
    case 'confirmPermit':
      Component = ConfirmPurchasePermitModalContent;
      headline = freeSalesOrder
        ? t('button.redeem')
        : t('services.confirmPermit');
      break;
    case 'terminatePermit':
      Component = ConfirmCancelPermitModalContent;
      headline = t('services.cancelPermit');
      break;
    case 'retryPaymentResult':
      headline = t('parking.receipt.retryPaymentTitle');
      Component = RetryPaymentModalContent;
      break;
    case 'duplicatePermitWarning':
      Component = ItemUnavailableModalContent;
      headline = t('longTermParking.unavailableHeadline');
      break;
    case 'privacyPolicy':
    case 'termsAndConditions':
      Component = () => <TermsPdfModalContent document={modalType} />;
      headline = t(
        modalType === 'privacyPolicy'
          ? 'account.terms.privacyPolicy'
          : 'account.terms.termsAndConditions'
      );
      break;
    case 'streetConfirmation':
      Component = StreetParkingLocationConfirmModal;
      headline = t('parking.streetParkingConfirmTitle');
      break;
    case 'confirmPrepaidPurchase':
      Component = PrepaidTicketConfirmModalContent;
      headline = t('parking.streetParkingConfirmTitle');
      break;
    case 'hslTicketRenderView':
      Component = HslTicketRenderModalContent;
      headline = titleTypeText(hslTicket?.ticketType);
      break;
    case 'setParkingReminder':
      Component = SetParkingReminderModalContent;
      headline = t('account.setReminder.title');
      break;
    case 'unlockTariff':
      Component = UnlockTariffModalContent;
      headline = t('parking.tariff.unlockTariff');
      break;
    case 'appLocation':
      Component = AppLocation;
      headline = t('account.appLocation');
      break;
    case 'parkingAppLocation':
      Component = () => (
        <UpdateFeatureCountryModal isAutomatic={activeZoneId === ''} />
      );
      headline = t('parking.updateLocation.title');
      break;
    case 'removeAccount':
      Component = RemoveAccount;
      headline = t('account.removeConfirm');
      break;
    case 'accountRemovalFailed':
      Component = AccountRemovalFailed;
      headline = t('account.removeFailed');
      break;
    case 'preventAccountRemoval':
      Component = AccountRemovalPrevented;
      headline = canContinueRemoval
        ? t('account.removeAccount')
        : t('account.remove.prevented');
      break;
    case 'accountRemovalSuccess':
      Component = AccountRemovalSuccess;
      headline = t('account.removeSuccess');
      break;
    case 'accountRemovalErrors':
      Component = AccountRemovalErrors;
      headline = t('account.removeFailed');
      break;
    case 'accountRemovalErrorLinkedService':
      Component = AccountRemovalPreventedLinkedService;
      headline = t('account.removeFailed');
      break;
    case 'confirmStartParking':
      Component = ConfirmParkingClosedZoneModalContent;
      headline = t('parking.closedZoneConfirm');
      break;
    case 'accountRestricted':
      Component = AccountRestrictedModalContent;
      break;
    case 'selectCountryForSupport':
      Component = ContactUsSelectCountry;
      headline = t('account.contactUs.support');
      break;
    case 'language':
      Component = LanguageList;
      headline = t('language.title');
      break;
    case 'noPaymentRequired':
      Component = NoPaymentRequiredModalContent;
      headline = t('permit.access.paymentNotRequired');
      break;
    case 'defaultPaymentMethod':
      Component = DefaultPaymentMethodModalContent;
      headline = t('paymentMethods.defaultMethod');
      break;
    case 'firstTimer':
      Component = FirstTimer;
      headline = undefined;
      break;
    default:
      break;
  }

  return (
    <RNWebModal
      showBackDrop={true}
      backDropOpacity={backDropOpacity}
      headline={headline}
      subtitle={subtitle}
      allowScrollableContent={allowScrollableContent}
      forceOpen={forceOpen}
      hideCloseButton={hideCloseButton}>
      <Component />
    </RNWebModal>
  );
};

export default Modal;
