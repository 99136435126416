import React, { useEffect } from 'react';
import { Button, Meta, Spinner, theme } from 'aimo-ui';
import { sheetModal } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import { useTranslation } from 'react-i18next';
import useRetryPaymentResult from 'hooks/useRetryPaymentResult';

const PaymentCardContent = () => {
  const { t } = useTranslation();
  const {
    parkingSessionData,
    parkingSessionLoading,
    parkingSessionError,
    parkingSessionStartPolling,
    parkingSessionStopPolling,
  } = useRetryPaymentResult();
  //TODO: It is currently unclear how the account lock feature would be,
  // so the content of this modal is subject to future change.
  // Now the content will be similar to the title

  const paymentState = parkingSessionData?.readParkingSession?.paymentState;
  const pendingOrRetrying =
    paymentState === 'Pending' || paymentState === 'Retrying';

  useEffect(() => {
    pendingOrRetrying
      ? parkingSessionStartPolling(300)
      : parkingSessionStopPolling();
    return () => {
      parkingSessionStopPolling();
    };
  }, [
    parkingSessionStopPolling,
    parkingSessionStartPolling,
    pendingOrRetrying,
  ]);

  const retryPaymentContent = () => {
    if (parkingSessionLoading) {
      return t('parking.receipt.waitingForRetry');
    } else if (parkingSessionError) {
      return t('parking.receipt.retryError');
    } else {
      if (pendingOrRetrying) {
        return '';
      }
      return paymentState === 'Success'
        ? t('parking.receipt.retrySuccessfulDescription')
        : t('parking.receipt.retryUnsuccessfulDescription');
    }
  };

  const handlePressed = () => sheetModal('');

  return (
    <StyledWrapper mb={0} testID="retry-payment-modal">
      <StyledWrapper mb={40}>
        {pendingOrRetrying && (
          <StyledWrapper center>
            <Spinner testID="retry-payment-modal-loading" />
          </StyledWrapper>
        )}
        {!pendingOrRetrying && (
          <Meta testID="retry-payment-modal-text" color={theme.colors.gray200}>
            {retryPaymentContent()}
          </Meta>
        )}
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <Button
          text={t('button.done')}
          onPress={handlePressed}
          size="l"
          testID="retry-payment-modal-button"
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default PaymentCardContent;
