import { tabBarIndicators } from 'states/common';
import { Card } from 'types/generatedSchemaTypes';
import { TabBarIndicator } from 'types/states';
import { getAmountOfExpiresSoonCards } from 'utils/paymentUtils';

export const getTabBarIndicator = (
  tabBarIndicatorsList: TabBarIndicator[],
  tabLocalizationKey: string
) => {
  return tabBarIndicatorsList.filter(
    (indicator) => indicator.tabLocalizationKey === tabLocalizationKey
  )[0];
};

export const handleCustomerCardIndicator = (cards?: Card[] | null) => {
  const amountOfExpiringSoonCards = cards
    ? getAmountOfExpiresSoonCards(cards)
    : 0;

  if (amountOfExpiringSoonCards > 0) {
    tabBarIndicators([
      {
        tabLocalizationKey: 'navigation.account',
        indicatorType: 'warning',
        indicatorNumber: amountOfExpiringSoonCards,
        indicatorRef: 'CARD_EXPIRING_SOON',
      },
    ]);
  } else {
    tabBarIndicators(
      tabBarIndicators().filter(
        (indicator) => indicator.indicatorRef !== 'CARD_EXPIRING_SOON'
      )
    );
  }
};

export const resetTabBarIndicators = () => {
  tabBarIndicators([]);
};
