import { useNavigation } from '@react-navigation/native';
import { BaseText, ListItem, Meta, theme } from 'aimo-ui';
import {
  ArrowIcon,
  VEHICLE_INPUT_ICON_SIZE,
} from 'components/account/vehicle/VehicleIcons';
import Icon from 'components/icons/Icon';
import useCreditCardCount from 'hooks/useCreditCardCount';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { RootNavProps } from 'types/navigation';

type VehicleCameraRecognitionSelectorProps = {
  selectedCameraRecognition: boolean;
  isOnboarding?: boolean;
  isParkingPermitFlow?: boolean;
};

const IconWrapper = styled.View`
  margin-left: 20px;
`;

const CameraRecognitionIcon = () => (
  <IconWrapper>
    <Icon
      name="CCTV-Outlined"
      size={VEHICLE_INPUT_ICON_SIZE}
      color={theme.colors.black}
    />
  </IconWrapper>
);

const VehicleCameraRecognitionSelector = ({
  selectedCameraRecognition,
  isOnboarding = false,
  isParkingPermitFlow = false,
}: VehicleCameraRecognitionSelectorProps) => {
  const { t } = useTranslation();
  const hasCards = useCreditCardCount() > 0;
  const { navigate } = useNavigation<RootNavProps>();

  const handlePress = () => {
    if (isOnboarding) {
      navigate('onboardingModal', {
        screen: 'vehicleCameraRecognitionOnboarding',
      });
    } else if (isParkingPermitFlow) {
      navigate('vehicleCameraRecognitionParking');
    } else {
      navigate('mainTabs', {
        screen: 'accountScreen',
        params: {
          screen: 'vehicleCameraRecognitionAccount',
        },
      });
    }
  };

  const titleComponent = () => (
    <>
      <BaseText>{t('vehicle.cameraRecognition.title')}</BaseText>
      <Meta color={theme.colors.gray200} testID="vehicle-cameraRecognition">
        {selectedCameraRecognition
          ? t('vehicle.cameraRecognition.allowed')
          : t('vehicle.cameraRecognition.notAllowed')}{' '}
      </Meta>
      {!hasCards && !selectedCameraRecognition && (
        <Meta
          color={theme.colors.gray200}
          testID="vehicle-cameraRecognition-add-payment">
          {t('vehicle.cameraRecognition.cannotActivate')}
        </Meta>
      )}
    </>
  );

  return (
    <ListItem
      testID="edit-vehicle-cameraRecognition"
      onPress={hasCards || selectedCameraRecognition ? handlePress : undefined}
      withBackground={hasCards ? undefined : theme.colors.gray50}
      withBorderRadius={true}
      borderColor={hasCards ? theme.colors.gray100 : undefined}
      item={null}
      titleComponent={titleComponent}
      indexValue={0}
      rightContentComponent={ArrowIcon}
      iconComponent={CameraRecognitionIcon}
    />
  );
};

export default VehicleCameraRecognitionSelector;
