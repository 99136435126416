import { BaseText, Headline3, theme } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import MenuItem from 'components/common/MenuItem';
import SheetLayout from 'components/sheets/SheetLayout';
import HslContext from 'context/HslContext';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateTimePicker from 'react-native-modal-datetime-picker';
import { StyledWrapper } from 'styles/ContainerStyles';
import { getLocale } from 'utils/commonUtils';
import { isAndroid, isIos } from 'utils/deviceUtils';

const HslValidFromSelection = () => {
  const { setCurrentScreen, setSelections, selections } =
    useContext(HslContext);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const handleNavigateBack = () => {
    setCurrentScreen('BUY_TICKET');
  };
  const { t } = useTranslation();

  const onItemPress = (validFrom: string) => {
    if (validFrom === 'now') {
      setSelections({ ...selections, validFrom: 'Now' });
      setCurrentScreen('BUY_TICKET');
      return;
    }
    setCalendarVisible(true);
  };

  const selectedDate =
    selections.validFrom !== 'Now'
      ? (selections.validFrom as Date)
      : new Date();

  const maxDate = new Date(selectedDate?.getTime() + 24 * 60 * 60 * 1000);
  const onConfirm = (newDate: Date) => {
    const isOverMaximum = newDate > maxDate;
    if (isAndroid && isOverMaximum) {
      showToast(t('error.overMaxDate'), 'info');
      setSelections({ ...selections, validFrom: maxDate });
      handleNavigateBack();
      return;
    }
    setSelections({ ...selections, validFrom: newDate });
    handleNavigateBack();
  };
  return (
    <SheetLayout
      onHeadlineButtonPress={handleNavigateBack}
      testIDName="hsl-select-valid-from"
      headlineComponent={
        <Headline3 center>{t('hslTicket.validityTitle')}</Headline3>
      }>
      <StyledWrapper center maxWidth={250} mb={20}>
        <BaseText center>{t('hslTicket.validityInfo')}</BaseText>
      </StyledWrapper>
      <StyledWrapper>
        <StyledWrapper mb={10}>
          <MenuItem
            onPress={() => onItemPress('now')}
            menuLabel={t('hslTicket.validity.now')}
            noBorder
            testID="hsl-select-valid-from-now"
            iconName="Time-Outlined"
            background={theme.colors.gray50}
          />
        </StyledWrapper>

        <MenuItem
          onPress={() => onItemPress('select')}
          menuLabel={t('hslTicket.validity.set')}
          noBorder
          testID="hsl-select-valid-from-setValue"
          iconName="Calendar-Outlined"
          background={theme.colors.gray50}
        />
      </StyledWrapper>
      <DateTimePicker
        isVisible={calendarVisible}
        onConfirm={onConfirm}
        mode="datetime"
        date={selectedDate}
        minimumDate={selectedDate}
        maximumDate={maxDate}
        is24Hour={true}
        testID="hsl-select-valid-from-datePicker"
        locale={getLocale().toString()}
        cancelTextIOS={t('button.cancel')}
        confirmTextIOS={t('button.confirm')}
        cancelButtonTestID="hsl-valid-calendar-cancel"
        confirmButtonTestID="hsl-valid-calendar-confirm"
        onCancel={() => setCalendarVisible(false)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /*
        // @ts-ignore */
        display={isIos ? 'inline' : 'default'}
      />
    </SheetLayout>
  );
};

export default HslValidFromSelection;
