import { useContext } from 'react';
import AccountContext from 'context/AccountContext';
import { hasAcceptedTerms } from 'hooks/useHasAcceptance';

const useIsLoggedIn = () => {
  const { customer, loading } = useContext(AccountContext);

  return {
    loginLoading: loading || false,
    isLoggedIn: !!customer.uid,
    customerUid: customer.uid,
    hasAcceptedTermsCustomerCountry:
      !!customer.uid &&
      hasAcceptedTerms(
        customer.countryCode,
        'TERMS_ACCEPTED',
        customer.acceptances
      ),
  };
};

export default useIsLoggedIn;
