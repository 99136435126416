import { useReactiveVar } from '@apollo/client';
import { Headline3, Meta, theme } from 'aimo-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { getVersion } from 'react-native-device-info';
import { featureCountryCode } from 'states/persistInStorage';
import {
  ColumnWithMargins,
  ScreenContainer,
  SheetSection,
} from 'styles/ContainerStyles';
import OpenHours from './OpenHours';
import { version as packageJsonVersion } from '/../package.json';
import { isWeb } from 'utils/deviceUtils';
import MenuItem from 'components/common/MenuItem';
import CopyText from 'components/common/CopyText';

interface CountryRegionDynamicInfo {
  phoneNumber?: string;
  callPrice?: string;
  emailAddress?: string;
  openHours?: { mondayToFriday: string; saturday: string; sunday: string };
}

const ContactUsContent: React.FC = () => {
  const { t } = useTranslation();
  const currentCountry = useReactiveVar(featureCountryCode);
  const version = isWeb ? packageJsonVersion : getVersion();
  let countryRegionDynamicInfo: CountryRegionDynamicInfo = {};

  switch (currentCountry) {
    default:
    case 'SE':
      countryRegionDynamicInfo = {
        phoneNumber: '+46 771-96 90 07',
        callPrice: undefined,
        emailAddress: 'digitalaprodukter@aimopark.se',
        openHours: {
          mondayToFriday: '6:30 - 21:00',
          saturday: '7:30 - 20:00',
          sunday: '10:00 - 20:00',
        },
      };
      break;
    case 'FI':
      countryRegionDynamicInfo = {
        phoneNumber: '+358 207 812 461',
        callPrice: t('account.contactUs.getInTouch.callPrice', {
          perCall: '8.35',
          perMin: '16.69',
        }),
        emailAddress: 'aimoapp@aimopark.fi',
        openHours: {
          mondayToFriday: '7:00 - 21:30',
          saturday: '9:30 - 19:30',
          sunday: '11:30 - 19:30',
        },
      };
      break;
    case 'NO':
      countryRegionDynamicInfo = {
        phoneNumber: '+47 21 00 76 70',
        callPrice: undefined,
        emailAddress: 'kundeservice@aimopark.no',
        openHours: {
          mondayToFriday: '07:00 - 00:00',
          saturday: '08:00 - 00:00',
          sunday: '08:00 - 00:00',
        },
      };
      break;
  }

  const openPhone = async () => {
    const url = `tel:${encodeURIComponent(
      countryRegionDynamicInfo?.phoneNumber?.replace(/\s/g, '') ?? ''
    )}`;
    await Linking.openURL(url);
  };

  const openEmail = async () => {
    const url = `mailto:${countryRegionDynamicInfo?.emailAddress}`;
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      await Linking.openURL(url);
    }
  };

  return (
    <>
      <ScreenContainer background={theme.colors.gray50} mb={20}>
        <SheetSection pt={0}>
          <OpenHours openHours={countryRegionDynamicInfo?.openHours} />
        </SheetSection>
        <SheetSection pt={30}>
          <Headline3>{t('account.contactUs.getInTouch.title')}</Headline3>
          <ColumnWithMargins mt={8}>
            <MenuItem
              menuLabel={`${countryRegionDynamicInfo?.phoneNumber}`}
              withArrow
              subtitle={countryRegionDynamicInfo?.callPrice}
              onPress={openPhone}
              testID={'contact-card-phone'}
              iconName="Phone-Filled"
            />
            <MenuItem
              iconName="Email-Filled"
              withArrow
              menuLabel={`${countryRegionDynamicInfo?.emailAddress}`}
              onPress={openEmail}
              testID={'contact-card-email'}
              noBorder
            />
          </ColumnWithMargins>
        </SheetSection>
        <SheetSection mb={0} pt={30}>
          <Headline3>{t('account.contactUs.appInfo.appVersion')}</Headline3>
          <ColumnWithMargins mt={10}>
            <CopyText copyText={version}>
              <Meta color={theme.colors.gray300}>{version}</Meta>
            </CopyText>
          </ColumnWithMargins>
        </SheetSection>
      </ScreenContainer>
    </>
  );
};

export default ContactUsContent;
