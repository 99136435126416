import { BaseText } from 'aimo-ui';
import SelectableModalContentItem from 'components/common/SelectableModalContentItem';
import Icon from 'components/icons/Icon';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useUpdateCustomerMutation from 'hooks/useUpdateCustomerMutation';
import { allowedLanguages, getLanguage, setLanguage } from 'i18n/init';
import React from 'react';
import { sheetModal } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import { Lang } from 'types/generatedSchemaTypes';
import { logCustomEvent } from 'utils/analytics';
import { languageLabels } from 'utils/languageUtils';

const LanguageList = () => {
  const currentLanguage = getLanguage();
  const { isLoggedIn } = useIsLoggedIn();

  const { updateCustomer } = useUpdateCustomerMutation({});

  // Sort languages where the last language is the selected language
  // and the rest are sorted alphabetically
  allowedLanguages.sort((a, b) => {
    if (a === currentLanguage) {
      return 1;
    }
    if (b === currentLanguage) {
      return -1;
    }
    return a.localeCompare(b);
  });

  const handleAppLanguageChange = (language: Lang) => async () => {
    await logCustomEvent('language_change', {
      from: currentLanguage,
      to: language,
    });
    sheetModal('');
    setLanguage(language);
    // Save preferred language into customer data if user is logged in
    if (language !== currentLanguage && isLoggedIn) {
      await updateCustomer({
        variables: {
          input: {
            preferredLanguage: language,
          },
        },
      });
    }
  };

  return (
    <StyledWrapper mb={30}>
      {allowedLanguages.map((language) => (
        <SelectableModalContentItem
          key={`language-${language}`}
          isSelected={language === currentLanguage}
          disabled={false}
          iconNode={undefined}
          onPress={handleAppLanguageChange(language)}
          iconNodeRight={language === currentLanguage && <Icon name="check" />}>
          <BaseText>{languageLabels[language]}</BaseText>
        </SelectableModalContentItem>
      ))}
    </StyledWrapper>
  );
};

export default LanguageList;
