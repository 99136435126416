import { gql } from '@apollo/client';

export const READ_ONGOING_CHARGING_SESSION = gql`
  query readOngoingChargingSessionV3 {
    readOngoingChargingSessionV3 {
      chargingSessionId
      startTime
      endTime
      status
      licensePlate
      parkingZoneUid
      transactionId
      priceInCents
      energyInWatts
      cardId
      countryCode
      stationId
      evseId
      parkZone {
        uid
        name
      }
    }
  }
`;
