import { createStackNavigator } from '@react-navigation/stack';
import { theme } from 'aimo-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PermitAndParkZoneScreen from 'screens/longTermParking/PermitAndParkZoneScreen';
import { BuyScreen, CompletedScreen } from 'screens/longTermParking/index';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';
import ModalStackHeader from './ModalStackHeader';

const styles = {
  flex: {
    flex: 1,
    borderTopLeftRadius: 140,
  },
  cardStyle: {
    backgroundColor: theme.colors.white,
  },
  thankYouStyle: {
    backgroundColor: theme.colors.lightBg.green,
  },
};
const LongTermParkingStack = createStackNavigator();

const LongTermParkingFlowNavigator: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ColumnWithMargins
      mt={isWeb ? 0 : 15}
      mr={isWeb ? 0 : 20}
      ml={isWeb ? 0 : 20}
      style={styles.flex}>
      <LongTermParkingStack.Navigator
        screenOptions={{
          animationEnabled: true,
          cardStyle: styles.cardStyle,
          header: (props) => (
            <ModalStackHeader
              {...props}
              background="transparent"
              closeButton
              noSafePadding
            />
          ),
        }}>
        <LongTermParkingStack.Screen
          name="searchScreen"
          component={PermitAndParkZoneScreen}
          options={{
            headerShown: false,
            headerBackTitleVisible: false,
            title: t('longTermParking.search'),
          }}
        />
        <LongTermParkingStack.Screen
          name="buyScreen"
          component={BuyScreen}
          options={{
            title: t('longTermParking.buyScreenTitle'),
          }}
        />
        <LongTermParkingStack.Group
          screenOptions={{
            cardStyle: styles.thankYouStyle,
          }}>
          <LongTermParkingStack.Screen
            name="completed"
            component={CompletedScreen}
            options={{
              headerShown: false,
              title: t('longTermParking.complete.thankYou'),
            }}
          />
        </LongTermParkingStack.Group>
      </LongTermParkingStack.Navigator>
    </ColumnWithMargins>
  );
};

export default LongTermParkingFlowNavigator;
