import {
  BaseText,
  BottomButtonsLayout,
  Button,
  Meta,
  theme,
  Toggle,
} from 'aimo-ui';
import AccountContext from 'context/AccountContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { CenterRow, StyledWrapper } from 'styles/ContainerStyles';
import { Maybe } from 'types/generatedSchemaTypes';
import MinuteSelection from './MinuteSelection';
import { ScrollView } from 'react-native-gesture-handler';
import { parkingEndingReminder } from 'states/persistInStorage';
import { updateStateAndStorage } from 'utils/MMKVStorageUtils';
import { isIos, isWeb } from 'utils/deviceUtils';
import { isNumber } from 'lodash';

const ToggleText = styled.View`
  flex: 1 1 auto;
  margin-right: 20px;
`;

const ToggleInput = styled.View`
  flex: 0 0 auto;
`;

const Separator = styled.View`
  border-bottom-width: 3px;
  margin: 8px 8px;
  border-bottom-color: ${(p) => p.theme.colors.gray50};
`;

const StyledTitle = styled(BaseText)`
  margin-bottom: 5px;
`;

type ScrollViewRef = ScrollView & {
  flashScrollIndicators: () => void;
};

type ToggleRowProps = {
  title: string;
  info?: Maybe<string>;
  value: boolean;
  setValue: (value: boolean) => void;
  separator?: boolean;
  testID: string;
};

const ToggleRowItem = ({
  title,
  info,
  value,
  setValue,
  separator = true,
  testID,
}: ToggleRowProps) => {
  const { t } = useTranslation();
  return (
    <>
      <CenterRow isVerticalCenter={true}>
        <ToggleText>
          <StyledTitle>{t(title)}</StyledTitle>
          {info && <Meta color={theme.colors.gray200}>{t(info)}</Meta>}
        </ToggleText>
        <ToggleInput>
          <Toggle
            testID={testID}
            value={value}
            onValueChange={() => setValue(!value)}
          />
        </ToggleInput>
      </CenterRow>
      {separator && <Separator />}
    </>
  );
};

export const SetParkingReminderModalContent: React.FC = () => {
  const { t } = useTranslation();
  const {
    customer: { mobilePhone, contactEmail },
  } = useContext(AccountContext);

  const [minutes, setMinutes] = useState<number>();
  const [pushNotification, setPushNotification] = useState(true);
  const [email, setEmail] = useState(false);
  const [textMessage, setTextMessage] = useState(false);

  const handleSetReminder = () => {
    if (isNumber(minutes)) {
      updateStateAndStorage(
        parkingEndingReminder,
        'parkingEndingReminder',
        minutes
      );
    }
    sheetModal('');
  };

  const scrollViewRef = React.useRef<ScrollViewRef | null>(null);

  // Flash scroll to indicate scrolling content
  useEffect(() => {
    setTimeout(() => scrollViewRef.current?.flashScrollIndicators(), 1000);
  }, []);

  return (
    <BottomButtonsLayout
      scrollable={false}
      contentBottom={
        <StyledWrapper mb={isWeb || isIos ? 0 : 30}>
          <Button
            testID="set-reminder"
            text={t('button.done')}
            size="l"
            buttonType="secondary"
            onPress={handleSetReminder}
          />
        </StyledWrapper>
      }>
      <>
        <StyledWrapper mb={10}>
          <Meta testID={'set-reminder-meta'} color={theme.colors.gray200}>
            {t('account.setReminder.info')}
          </Meta>
        </StyledWrapper>
        <MinuteSelection setMinutes={setMinutes} />
        <ScrollView nestedScrollEnabled ref={scrollViewRef}>
          {!isWeb && (
            <ToggleRowItem
              title="account.setReminder.pushNotification"
              value={pushNotification}
              setValue={setPushNotification}
              testID={'push-notification-toggle'}
            />
          )}
          <ToggleRowItem
            title="account.setReminder.email"
            value={email}
            info={contactEmail}
            setValue={setEmail}
            testID={'email-toggle'}
          />
          <ToggleRowItem
            title="account.setReminder.textMessage"
            value={textMessage}
            info={mobilePhone}
            setValue={setTextMessage}
            testID={'text-message-toggle'}
            separator={false}
          />
        </ScrollView>
      </>
    </BottomButtonsLayout>
  );
};

export default SetParkingReminderModalContent;
