import { theme } from 'aimo-ui';
import SvgImage, { SvgName } from 'components/svgImage/SvgImage';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { StyledImage } from 'styles/ContainerStyles';
import { logCustomEvent } from 'utils/analytics';
import { parseFeatureFlagObject, ServiceChip } from 'utils/featureFlagUtils';
import CardItem from './CardItem';

const CustomChips = () => {
  const {
    flags: { service_chips },
  } = useContext(FeatureFlagsContext);
  const chips = parseFeatureFlagObject<ServiceChip[]>(
    service_chips,
    'service_chips'
  );
  const { t } = useTranslation();

  const handleOnPress = async (url: string, serviceChipId: string) => {
    await Linking.openURL(url);
    await logCustomEvent('custom_service_chip', {
      service_chip_id: serviceChipId,
    });
  };

  return (
    <>
      {chips.map((chip) => (
        <CardItem
          key={chip.serviceChipId}
          title={t(`${chip.text.title ?? ''}`)}
          text={t(`${chip.text.description ?? ''}`)}
          testID="service-chip"
          onPress={() => handleOnPress(chip.redirectUrl, chip.serviceChipId)}
          countryToShow={chip.countriesActive ?? ['FI', 'SE', 'NO']}
          bgColor={chip.style?.backgroundColor}
          leftImageContainer={
            !!chip.imageSource && <ChipImage imageSource={chip.imageSource} />
          }
        />
      ))}
    </>
  );
};

const ChipImage = ({ imageSource }: { imageSource: string }) => {
  const isUrl = imageSource.includes('https');
  const size = 'xxl';

  return (
    <>
      {isUrl ? (
        <StyledImage
          height={theme.iconSizes[size]}
          width={theme.iconSizes[size]}
          source={{ uri: imageSource }}
          center
        />
      ) : (
        <SvgImage
          name={imageSource as SvgName}
          size={size}
          testID={`chip-image-${imageSource}`}
        />
      )}
    </>
  );
};

export default CustomChips;
