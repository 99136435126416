import {
  ChargingSession,
  StopChargingSessionResponse,
} from 'types/generatedSchemaTypes';

export const stopChargingSessionResponse: StopChargingSessionResponse = {
  chargingSessionId: '844a498c-5efc-4bb9-8422-7b4ee65c8f72',
  startTime: new Date('2022-03-18T07:55:40.918Z'),
  endTime: new Date('2022-03-18T07:56:15.187Z'),
  status: 'STOP_IN_QUEUE',
  token: 'LIMNPA5SKWWEDS5HRF37',
  licensePlate: 'ABC123',
  parkingZoneUid: 'FI-CORCAV2',
  chargingLocationId: 'EVB-P1639086',
  evseId: '1729281',
  customerUid: '59b7ac53c9e77c001143a61e',
  stationId: 'b4a463a2-54c3-4611-a529-983f825cdf75',
  transactionId: null,
  stopInQueue: new Date('2022-03-18T07:56:15.187Z'),
  paymentOk: false,
  failedPaymentTime: null,
  paymentRetries: null,
  transactionReverted: null,
  errorMessage: null,
  stopToQueueRetries: 1,
  priceInCents: 200,
  energyInWatts: 0,
  energyMeterStart: null,
  energyMeterEnd: null,
  cdrId: null,
  customerEmail: 'FirstNameMock.LastNameMock@mailinator.com',
  cardId: '4f426f82-321e-48da-aa67-655552731bc2',
  countryCode: 'FI',
  retryPaymentAutomatically: true,
  provider: 'EVERON',
  createdBy: {
    clientId: 'aimo-one-backend',
    name: null,
    email: null,
    userId: null,
  },
  created: new Date('2022-03-18T07:55:31.791Z'),
  updatedBy: {
    clientId: 'aimo-one-backend',
    name: null,
    email: null,
    userId: null,
  },
  updated: new Date('2022-03-18T07:56:15.189Z'),
};

const oneHourAgo = () => {
  const now = new Date();
  now.setHours(now.getHours() - 1);
  return now;
};

export const chargingSession: { readActiveChargingSession: ChargingSession } = {
  readActiveChargingSession: {
    chargingSessionId: 'e61f192b-c7ee-4018-bf0b-e8405d409d18',
    startTime: oneHourAgo(),
    endTime: null,
    status: 'IN_PROGRESS',
    token: 'KDTKBLU7Y0DNPC6SQ5ZL',
    licensePlate: 'ABC123',
    parkingZoneUid: '00d777ae-4d5c-4777-b891-d1c75fa35a90',
    chargingLocationId: 'EVB-P1639086',
    evseId: '1729281',
    customerUid: '59b7ac53c9e77c001143a61e',
    stationId: 'b4a463a2-54c3-4611-a529-983f825cdf75',
    transactionId: null,
    stopInQueue: null,
    paymentOk: false,
    failedPaymentTime: null,
    paymentRetries: 5,
    transactionReverted: null,
    errorMessage: null,
    stopToQueueRetries: null,
    priceInCents: 215,
    energyInWatts: 20000,
    energyMeterStart: 0,
    energyMeterEnd: 20000,
    cdrId: null,
    customerEmail: 'FirstNameMock.LastNameMock@mailinator.com',
    cardId: 'c66ff184-76f9-4c80-b25b-98ca4621a557',
    countryCode: 'FI',
    retryPaymentAutomatically: true,
    provider: 'EVERON',
    createdBy: {
      clientId: 'aimo-one-backend',
      name: null,
      email: null,
      userId: null,
    },
    created: new Date('2022-04-07T12:51:07.100Z'),
    updatedBy: {
      clientId: 'microservice-backend',
      name: 'Session-service scheduler',
      email: null,
      userId: null,
    },
    updated: new Date('2022-04-07T13:18:10.153Z'),
  },
};

export const ongoingChargingSession: {
  readOngoingChargingSessionV3: ChargingSession;
} = {
  readOngoingChargingSessionV3: {
    chargingSessionId: 'e61f192b-c7ee-4018-bf0b-e8405d409d18',
    startTime: oneHourAgo(),
    endTime: null,
    status: 'IN_PROGRESS',
    token: 'KDTKBLU7Y0DNPC6SQ5ZL',
    licensePlate: 'ABC123',
    parkingZoneUid: 'FI-G7RCBR3',
    chargingLocationId: 'EVB-P1639086',
    evseId: '1729281',
    customerUid: '59b7ac53c9e77c001143a61e',
    stationId: 'b4a463a2-54c3-4611-a529-983f825cdf75',
    transactionId: null,
    stopInQueue: null,
    paymentOk: false,
    failedPaymentTime: null,
    paymentRetries: 5,
    transactionReverted: null,
    errorMessage: null,
    stopToQueueRetries: null,
    priceInCents: 215,
    energyInWatts: 20000,
    energyMeterStart: 0,
    energyMeterEnd: 20000,
    cdrId: null,
    customerEmail: 'FirstNameMock.LastNameMock@mailinator.com',
    cardId: 'c66ff184-76f9-4c80-b25b-98ca4621a557',
    countryCode: 'FI',
    retryPaymentAutomatically: true,
    provider: 'EVERON',
    createdBy: {
      clientId: 'aimo-one-backend',
      name: null,
      email: null,
      userId: null,
    },
    created: new Date('2022-04-07T12:51:07.100Z'),
    updatedBy: {
      clientId: 'microservice-backend',
      name: 'Session-service scheduler',
      email: null,
      userId: null,
    },
    updated: new Date('2022-04-07T13:18:10.153Z'),
  },
};

export const endingSession: { readActiveChargingSession: ChargingSession } = {
  readActiveChargingSession: {
    chargingSessionId: 'e61f192b-c7ee-4018-bf0b-e8405d409d18',
    startTime: oneHourAgo(),
    endTime: null,
    status: 'STOP_IN_QUEUE',
    token: 'KDTKBLU7Y0DNPC6SQ5ZL',
    licensePlate: 'ABC123',
    parkingZoneUid: '00d777ae-4d5c-4777-b891-d1c75fa35a90',
    chargingLocationId: 'EVB-P1639086',
    evseId: '1729281',
    customerUid: '59b7ac53c9e77c001143a61e',
    stationId: 'b4a463a2-54c3-4611-a529-983f825cdf75',
    transactionId: null,
    stopInQueue: null,
    paymentOk: false,
    failedPaymentTime: null,
    paymentRetries: 5,
    transactionReverted: null,
    errorMessage: null,
    stopToQueueRetries: null,
    priceInCents: 215,
    energyInWatts: 44160,
    energyMeterStart: 0,
    energyMeterEnd: 20000,
    cdrId: null,
    customerEmail: 'FirstNameMock.LastNameMock@mailinator.com',
    cardId: 'c66ff184-76f9-4c80-b25b-98ca4621a557',
    countryCode: 'FI',
    retryPaymentAutomatically: true,
    provider: 'EVERON',
    createdBy: {
      clientId: 'aimo-one-backend',
      name: null,
      email: null,
      userId: null,
    },
    created: new Date('2022-04-07T12:51:07.100Z'),
    updatedBy: {
      clientId: 'microservice-backend',
      name: 'Session-service scheduler',
      email: null,
      userId: null,
    },
    updated: new Date('2022-04-07T13:18:10.153Z'),
  },
};

export const chargingSessionPaymentFail = {
  readHistoryChargingSessions: {
    totalPages: 1,
    last: true,
    number: 0,
    content: [
      {
        chargingSessionId: 'b14b943d-8719-4922-8408-927318c6b9f2',
        transactionId: null,
        startTime: '2022-03-17T11:06:51.101Z',
        endTime: '2022-03-17T11:07:29.246Z',
        countryCode: 'FI',
        parkingZoneUid: 'FI-G7RCBR3',
        cardId: '1a591fad-df83-48ba-bfc4-6b7109a1ad38',
        licensePlate: 'ABC123',
        created: '2022-03-17T11:06:51.101Z',
        receipt: null,
      },
    ],
  },
};

export const chargingSessions = {
  readHistoryChargingSessions: {
    totalPages: 1,
    last: true,
    number: 0,
    content: [
      {
        chargingSessionId: 'b14b943d-8719-4922-8408-927318c6b9f2',
        transactionId: '0aaeead2-6300-4aa1-9880-28aa25dcb3ee',
        startTime: '2022-03-17T11:06:51.101Z',
        endTime: '2022-03-17T11:07:29.246Z',
        countryCode: 'FI',
        parkingZoneUid: 'FI-G7RCBR3',
        cardId: '1a591fad-df83-48ba-bfc4-6b7109a1ad38',
        licensePlate: 'ABC123',
        created: '2022-03-17T11:06:51.101Z',
        receipt: {
          id: '6233167d9c0d1d7ea30ab2a6',
          created: '2022-03-17T11:06:51.101Z',
          items: [
            {
              __typename: 'BillingItem',
              vatPercentage: 24,
              amountExclVAT: 161.29,
              vatAmount: 38.71,
              energyConsumedWatts: 0,
              amountCents: 200,
            },
          ],
        },
      },
    ],
  },
};

export const chargingSessionMock: ChargingSession = {
  chargingSessionId: 'b3e87e0c-88e8-426c-8ee8-b1f7f280525c',
  transactionId: 'trans-4321',
  endTime: new Date('2023-02-24T08:49:06.799Z'),
  startTime: new Date('2023-02-24T08:37:19.660Z'),
};
