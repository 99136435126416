import { useLazyQuery } from '@apollo/client';
import {
  READ_PARKINGS_SESSION_PRICE_ESTIMATE,
  READ_PARK_ZONE_PRICE_ESTIMATE,
} from 'graphql/queries/getParkZonePriceEstimate';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { Easing, useSharedValue, withTiming } from 'react-native-reanimated';
import {
  Query,
  QueryReadParkZonePriceEstimateArgs,
  QueryReadParkingSessionPriceEstimateArgs,
} from 'types/generatedSchemaTypes';
import PriceEstimateContext from './PriceEstimateContext';

export type PriceEstimateParams = {
  parkingZoneUid: string;
  sessionId?: string;
  startDate: Date;
  endDate: Date;
  signal?: AbortSignal;
};

const TIMING_CONFIG = {
  duration: 500,
  easing: Easing.bezier(0.25, 0.1, 0.68, -0.03),
};

const PriceEstimateContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const startButtonOpacity = useSharedValue(1);
  const setStartButtonOpacity = useCallback(
    (value: 0 | 1 = 0) => {
      startButtonOpacity.value = withTiming(value, TIMING_CONFIG);
    },
    [startButtonOpacity]
  );

  const [getPricingEstimateWithoutId, { error, loading, data }] = useLazyQuery<
    Query,
    QueryReadParkZonePriceEstimateArgs
  >(READ_PARK_ZONE_PRICE_ESTIMATE, {
    errorPolicy: 'all',
    onCompleted: () => setStartButtonOpacity(1),
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [
    getPricingEstimateWithId,
    { error: errorWithId, loading: loadingWithId, data: dataWithId },
  ] = useLazyQuery<Query, QueryReadParkingSessionPriceEstimateArgs>(
    READ_PARKINGS_SESSION_PRICE_ESTIMATE,
    {
      errorPolicy: 'all',
      onCompleted: () => setStartButtonOpacity(1),
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const contextValue = useMemo(() => {
    return {
      loadingPrice: loading || loadingWithId,
      priceEstimate:
        data?.readParkZonePriceEstimate ||
        dataWithId?.readParkingSessionPriceEstimate,
      error: error || errorWithId,
      startButtonOpacity,
      setStartButtonOpacity,
      getPricingEstimateWithoutId,
      getPricingEstimateWithId,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataWithId, error, errorWithId, loading, loadingWithId]);

  return (
    <PriceEstimateContext.Provider value={contextValue}>
      {children}
    </PriceEstimateContext.Provider>
  );
};

export default PriceEstimateContextProvider;
