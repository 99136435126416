import { useMutation, useReactiveVar } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { BaseText, Headline3, Meta, Toggle } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import AccountContext from 'context/AccountContext';
import { UPDATE_VEHICLE } from 'graphql/mutations/updateVehicle';
import { GET_CUSTOMER } from 'graphql/queries/getCustomer';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { editVehicleCameraRecognitionEnabled, sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { Divider } from 'styles/CommonLayoutStyles';
import {
  CenterRow,
  SheetDetailsWrapper,
  StyledWrapper,
} from 'styles/ContainerStyles';
import {
  Mutation,
  MutationUpdateVehicleArgs,
} from 'types/generatedSchemaTypes';
import { RootNavProps, RootStackParamList } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { WEB_MENU_BAR_HEIGHT } from 'utils/navigationUtils';

const ToggleText = styled.View`
  flex: 1 1 auto;
  margin-right: 34px;
`;

const ToggleInput = styled.View`
  flex: 0 1 auto;
`;

const VehicleCameraRecognition = () => {
  const { t } = useTranslation();
  const { goBack } = useNavigation<RootNavProps>();
  const { params: { saveOnModalConfirm } = { saveOnModalConfirm: false } } =
    useRoute<
      RouteProp<RootStackParamList, 'vehicleCameraRecognitionParking'>
    >();

  const selectedVehicleCameraRecognitionEnabled = useReactiveVar(
    editVehicleCameraRecognitionEnabled
  );
  const [isOwner, setIsOwner] = useState(
    selectedVehicleCameraRecognitionEnabled
  );
  const [isAllowed, setIsAllowed] = useState(
    selectedVehicleCameraRecognitionEnabled
  );

  const [updateVehicle] = useMutation<Mutation, MutationUpdateVehicleArgs>(
    UPDATE_VEHICLE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_CUSTOMER,
          errorPolicy: 'all',
        },
      ],
      onCompleted: () => {
        showToast('success.updateVehicle', 'success');
        sheetModal('');
      },
      onError: () => showToast('error.updateVehicle', 'error'),
    }
  );

  const { name, licensePlate, cameraRecognition, appSettings, id } =
    useSelectedParkingVehicle() || {};

  const { customer } = useContext(AccountContext);

  const callbackAction = async (updatedAllowedValue: boolean) => {
    if (!updatedAllowedValue) {
      goBack();
    }

    if (updatedAllowedValue && licensePlate?.plateText && id) {
      await updateVehicle({
        variables: {
          input: {
            id: id,
            licensePlate: {
              plateText: licensePlate?.plateText,
              countryCode: licensePlate?.countryCode,
            },
            name,
            cameraRecognition: {
              allowed: updatedAllowedValue,
              enabled: updatedAllowedValue,
              countryCodes: cameraRecognition?.countryCodes || [
                customer.countryCode,
              ],
            },
            appSettings: {
              icon: appSettings?.icon,
              color: appSettings?.color,
              type: appSettings?.type,
            },
          },
        },
      });
    }
  };

  const handleOwnerChange = () => {
    const updatedOwnerValue = !isOwner;
    setIsOwner(updatedOwnerValue);
    if (!updatedOwnerValue) {
      setIsAllowed(false);
      editVehicleCameraRecognitionEnabled(false);
    }
  };

  const handleAllowChange = () => {
    const updatedAllowedValue = !isAllowed;
    setIsAllowed(updatedAllowedValue);
    if (saveOnModalConfirm) {
      callbackAction(updatedAllowedValue);
    } else {
      editVehicleCameraRecognitionEnabled(updatedAllowedValue);
    }
  };

  return (
    <SheetDetailsWrapper pt={isWeb ? WEB_MENU_BAR_HEIGHT : 20}>
      <StyledWrapper mb={20}>
        <BaseText testID="cameraRecognition-description1">
          {t('vehicle.cameraRecognition.description1')}
        </BaseText>
      </StyledWrapper>
      <StyledWrapper mb={30}>
        <BaseText testID="cameraRecognition-description2">
          {t('vehicle.cameraRecognition.description2')}
        </BaseText>
      </StyledWrapper>
      <StyledWrapper mb={40}>
        <StyledWrapper mb={17}>
          <Headline3>{t('vehicle.cameraRecognition.headline')}</Headline3>
        </StyledWrapper>
        <CenterRow isVerticalCenter={true}>
          <ToggleText>
            <Meta>{t('vehicle.cameraRecognition.ownerQuestion')}</Meta>
          </ToggleText>
          <ToggleInput>
            <Toggle
              value={isOwner}
              onValueChange={handleOwnerChange}
              testID="cameraRecognition-toggle-owner"
            />
          </ToggleInput>
        </CenterRow>
        <Divider />
        <CenterRow isVerticalCenter={true}>
          <ToggleText>
            <Meta>{t('vehicle.cameraRecognition.allowQuestion')}</Meta>
          </ToggleText>
          <ToggleInput>
            <Toggle
              value={isAllowed}
              onValueChange={handleAllowChange}
              testID="cameraRecognition-toggle-allow"
              disabled={!isOwner}
            />
          </ToggleInput>
        </CenterRow>
      </StyledWrapper>
    </SheetDetailsWrapper>
  );
};

export default VehicleCameraRecognition;
