import { Spinner, Toggle } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import useMinimumLoadingTime from 'hooks/useMinimumLoadingTime';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { testIdentifiers } from 'utils/testIdentifiers';

type MenuToggleItemProps = {
  value: boolean;
  onValueChange: (value: boolean) => void;
  borderColor?: string;
  borderWidth?: number;
  testID: string;
  subtitle?: string;
  menuLabel: string;
  iconName: string;
  loading?: boolean;
  disabled?: boolean;
  color?: string;
};

const MenuToggleItem: React.FC<MenuToggleItemProps> = ({
  value,
  onValueChange,
  borderColor,
  borderWidth,
  testID,
  subtitle,
  menuLabel,
  iconName,
  loading,
  disabled,
  color,
}: MenuToggleItemProps) => {
  const { t } = useTranslation();
  const { showLoading } = useMinimumLoadingTime(loading ?? false, 1500, 200);

  return (
    <MenuItem
      menuLabel={t(menuLabel)}
      iconName={iconName}
      testID={`${testID}-menu-item`}
      subtitle={subtitle}
      color={color}
      rightComponent={
        showLoading ? (
          <Spinner size={35} />
        ) : (
          <Toggle
            disabled={disabled}
            {...testIdentifiers(`${testID}-toggle`)}
            value={value}
            onValueChange={onValueChange}
          />
        )
      }
      borderColor={borderColor}
      borderW={borderWidth}
    />
  );
};

export default MenuToggleItem;
