import React from 'react';
import { ChargingSession, Maybe } from 'types/generatedSchemaTypes';
import { SimpleFullContainer } from 'styles/ContainerStyles';
import OngoingChargingHeader from 'components/ongoingParking/OngoingChargingHeader';
import OngoingChargingStatus from 'components/ongoingParking/OngoingChargingStatus';

export const OngoingChargingSession: React.FC<{
  session: Maybe<ChargingSession>;
}> = ({ session }) => {
  if (!session) {
    return <></>;
  }
  return (
    <SimpleFullContainer>
      <OngoingChargingHeader session={session} />
      <OngoingChargingStatus session={session} />
    </SimpleFullContainer>
  );
};
