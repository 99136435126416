import Icon from '@components/icons/Icon';
import { StackHeaderProps } from '@react-navigation/stack';
import { Headline3, IconButton, theme } from 'aimo-ui';
import { ButtonTypes } from 'aimo-ui/lib/typescript/src/components/Button/types';
import { HandleComponent } from 'components/sheets/CustomSheetElements';
import useBackButton from 'hooks/useBackButton';
import React from 'react';
import { CenterColumn } from 'styles/ContainerStyles';
import {
  HeaderButtonWrapper,
  ModalStackHeaderContainer,
  ModalTitleWrapper,
} from 'styles/ModalStackHeaderStyles';
import { testIdentifiers } from 'utils/testIdentifiers';
import SafeAreaHeaderContainer from './SafeAreaHeaderContainer';

export interface StackHeaderCustomProps extends StackHeaderProps {
  background?: string;
  noSafePadding?: boolean;
  closeButton?: boolean;
  forceBackButton?: boolean;
  backButtonType?: ButtonTypes;
  closeButtonType?: ButtonTypes;
  showHandle?: boolean;
}

const ModalStackHeaderContainerWithHandle = ({
  background,
  children,
  showHandle,
  noSafePadding,
}: {
  background: string;
  children: React.ReactNode;
  showHandle: boolean;
  noSafePadding: boolean;
}) => {
  const padding = () => {
    if (showHandle) {
      return 'horizontal';
    }
    return noSafePadding ? undefined : 'all';
  };
  return (
    <CenterColumn>
      {showHandle && <HandleComponent />}
      <SafeAreaHeaderContainer extraPadding={padding()}>
        <ModalStackHeaderContainer background={background}>
          {children}
        </ModalStackHeaderContainer>
      </SafeAreaHeaderContainer>
    </CenterColumn>
  );
};

const ModalStackHeader = ({
  navigation,
  back,
  background = 'transparent',
  options,
  closeButton = false,
  forceBackButton = false,
  backButtonType = 'tertiary',
  closeButtonType = 'tertiary',
  showHandle = false,
  noSafePadding = false,
}: StackHeaderCustomProps) => {
  const title = options?.title || '';
  const { headerBackTitleVisible = true } = options || {};
  const showCloseButton =
    closeButton || back?.title === 'mainTabs' || !back?.title;
  const handler = () => {
    navigation.goBack();
    return true;
  };
  useBackButton(handler);

  return (
    <ModalStackHeaderContainerWithHandle
      background={background}
      showHandle={showHandle}
      noSafePadding={noSafePadding}>
      {(!showCloseButton || forceBackButton) && (
        <HeaderButtonWrapper position="left">
          <IconButton
            rounded={true}
            buttonType={backButtonType}
            onPress={() => navigation.goBack()}
            testID="modal-stack-header-back-button"
            iconComponent={() => (
              <Icon
                name="back"
                color={
                  backButtonType === 'secondary'
                    ? theme.colors.white
                    : theme.colors.black
                }
              />
            )}
          />
        </HeaderButtonWrapper>
      )}
      {headerBackTitleVisible && (
        <ModalTitleWrapper>
          <Headline3 testID="modal-stack-header-title">{title}</Headline3>
        </ModalTitleWrapper>
      )}
      {showCloseButton && (
        <HeaderButtonWrapper position="right">
          <IconButton
            {...testIdentifiers('modal-stack-header-close-button')}
            rounded={true}
            buttonType={closeButtonType}
            onPress={() =>
              back?.title ? navigation.goBack() : navigation.replace('mainTabs')
            }
            iconComponent={() => (
              <Icon
                name="close"
                color={
                  closeButtonType === 'secondary'
                    ? theme.colors.white
                    : theme.colors.black
                }
              />
            )}
          />
        </HeaderButtonWrapper>
      )}
    </ModalStackHeaderContainerWithHandle>
  );
};

export default ModalStackHeader;
