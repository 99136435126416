import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseText, theme } from 'aimo-ui';
import CrickleCutCardBackground from 'components/common/CrickleCutCardBackground';
import AccessPermitAvailability from 'screens/permit/access/AccessPermitAvailability';
import PermitTicketHeader from 'screens/permit/PermitTicketHeader';
import { UnifiedPermit } from 'types/generatedSchemaTypes';
import { StyledWrapper } from 'styles/ContainerStyles';
import useLoadFreePoolingSpots from 'hooks/useLoadFreePoolingSpots';

interface AccessPermitTicketProps {
  permit: UnifiedPermit;
}

const AccessPermitTicket: React.FC<AccessPermitTicketProps> = ({ permit }) => {
  const { t } = useTranslation();
  const locationName = permit.placeName;
  const { loadFreePoolingSpots, loading, data } = useLoadFreePoolingSpots(
    permit.accessToPoolingGroupUid || undefined
  );

  useEffect(() => {
    loadFreePoolingSpots();
  }, [loadFreePoolingSpots]);

  return (
    <CrickleCutCardBackground
      aboveCutView={
        <>
          <PermitTicketHeader
            title={t('permit.access.name')}
            subtitle={permit?.parkingFacilityName || undefined}
            testID="access-permit"
          />

          {locationName && (
            <StyledWrapper mb={50} mt={30}>
              <BaseText center>{locationName}</BaseText>
            </StyledWrapper>
          )}
        </>
      }
      belowCutView={
        <AccessPermitAvailability
          loading={loading}
          availability={data?.getPoolingGroupCapacity?.free || 0}
        />
      }
      backgroundColor={theme.colors.lightBg.lilac}
    />
  );
};

export default AccessPermitTicket;
