import React from 'react';
import useParkingHistoryFilter from 'hooks/useParkingHistoryFilter';
import { sheetModal, parkingTypesVisible } from 'states/common';
import FilterButton from './FilterButton';

const HistoryFilterButton = ({
  showParkingTypes = false,
}: {
  showParkingTypes?: boolean;
}) => {
  const { filterActive } = useParkingHistoryFilter();
  return (
    <FilterButton
      testID="history"
      isActive={filterActive}
      onPress={() => {
        parkingTypesVisible(showParkingTypes);
        sheetModal('parkingHistoryFilter');
      }}
    />
  );
};

export default HistoryFilterButton;
