import styled from 'styled-components/native';
import { countMarginFromTop, isWeb } from 'utils/deviceUtils';

export const SheetDetailsWrapper = styled.View<{
  pt?: number;
  pb?: number;
  resetPadding?: boolean;
}>`
  padding: 0 ${({ resetPadding }) => (resetPadding ? 0 : 20)}px;
  padding-top: ${({ pt }) => pt ?? 0}px;
  padding-bottom: ${({ pb }) => pb ?? 0}px;
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 20px;
  flex: 1;
`;

export const ButtonContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-width: 320px;
  flex: 1 0 auto;
`;

export const SimpleFullContainer = styled.View`
  flex: 1;
`;

export const NotificationContainer = styled.View`
  border-radius: 20px;
  padding: 18px;
  margin: 20px 0;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.gray50};
`;

export const ItemContainer = styled.View`
  flex-shrink: 0;
  margin-bottom: 20px;
`;

export const ItemWrapper = styled.View<{ noBorder?: boolean }>`
  border-color: ${(p) => p.theme.colors.gray50};
  padding: 12px 0;
  flex-shrink: 0;
  flex-grow: 1;
  flex-wrap: wrap;
  border-bottom-width: ${(p) => (p.noBorder ? '0px' : '3px')};
  flex-direction: row;
  justify-content: space-between;
`;

export const MenuWrapper = styled.TouchableOpacity<{
  borderWidth?: number;
  background?: string;
  borderColor: string;
  paddingVertical?: number;
}>`
  background-color: ${({ background }) => background || 'transparent'};
  border-color: ${(p) => p.borderColor};
  padding: ${({ paddingVertical }) =>
      paddingVertical ? paddingVertical : 18}px
    ${({ background }) => (background ? 20 : 0)}px;
  border-radius: ${isWeb ? 0 : 8}px;
  border-bottom-width: ${(p) => p.borderWidth}px;
  flex-direction: column;
`;

export const MenuRowWrapper = styled.View<{
  flexStart?: boolean;
}>`
  flex-shrink: 1;
  flex-grow: 1;
  align-items: ${(p) => (p.flexStart ? 'flex-start' : 'center')};
  flex-direction: row;
`;

export const StyledImage = styled.Image<{
  height: number;
  width: number;
  center?: boolean;
}>`
  height: ${({ height, theme }) => theme.normalising.fontPixel(height)}px;
  width: ${({ width, theme }) => theme.normalising.fontPixel(width)}px;
  align-self: ${({ center }) => (center ? 'center' : 'auto')};
`;

export const StyledIllustrationImage = styled.Image<{
  center?: boolean;
}>`
  height: 90%;
  width: 90%;
  align-self: ${({ center }) => (center ? 'center' : 'auto')};
`;

export const ScreenContainer = styled.View<{
  background?: string;
  mt?: number;
  mb?: number;
  center?: boolean;
}>`
  flex: 1;
  align-items: ${(p) => (p.center ? 'center' : 'stretch')};
  background-color: ${(p) => p.background || p.theme.colors.white};
  margin-bottom: ${({ mb, theme }) =>
    mb ?? isWeb ? 0 : theme.normalising.heightPixel(90)}px;
  margin-top: ${({ mt }) => mt ?? 0}px;
`;

export const ScreenWrapper = styled.ScrollView<{
  tabBarHeight: number;
  padding?: number;
}>`
  padding: ${(p) => p.padding ?? 20}px;
  padding-top: ${(p) =>
    isWeb ? p.tabBarHeight + 20 : countMarginFromTop(20)}px;
`;

/**
 * @typedef maxWidth defines elements width
 * @type {number | undefined}
 * @typedef center defines whether element is centered or default left
 * @type {boolean | undefined}
 * @typedef mb - margin-bottom
 * @typedef mt - margin-top
 * @type {number | undefined}
 **/
export const StyledWrapper = styled.View<{
  maxWidth?: number;
  center?: boolean;
  mb?: number;
  mt?: number;
}>`
  width: 100%;
  align-self: ${(p) => (p.center ? 'center' : 'auto')};
  margin-bottom: ${(p) => p.mb ?? 20}px;
  margin-top: ${(p) => p.mt ?? 0}px;
  width: ${(p) => (p.maxWidth ? p.maxWidth + 'px' : 'auto')};
  max-width: 100%;
`;

export const CenterColumn = styled.View`
  flex-direction: column;
  justify-content: center;
`;

export const CenterFullScreen = styled.View`
  flex: 0 1 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const CenterRow = styled.View<{
  isVerticalCenter?: boolean;
}>`
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: ${(p) => (p.isVerticalCenter ? 'center' : 'stretch')};
`;

export const RowSpaceBetween = styled.View<{
  withBorder?: boolean;
  padding?: number;
  noFlex?: boolean;
  center?: boolean;
}>`
  flex-direction: row;
  justify-content: ${(p) => (p.center ? 'center' : 'space-between')};
  align-items: center;
  border-bottom-width: ${(p) => (p.withBorder ? 3 : 0)}px;
  ${(p) => !p.noFlex && 'flex: 1 1 auto'};
  overflow: hidden;
  border-bottom-color: ${(p) =>
    p.withBorder ? p.theme.colors.gray50 : p.theme.colors.white};
  padding: ${(p) => p.padding ?? 12}px 0px;
`;

export const RowNoAlign = styled.View`
  flex-direction: row;
  justify-content: center;
`;

export const CenterVerticalRow = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ColumnWithMargins = styled.View<{
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
}>`
  margin-top: ${({ mt }) => mt ?? 0}px;
  margin-bottom: ${({ mb }) => mb ?? 0}px;
  margin-right: ${({ mr }) => mr ?? 0}px;
  margin-left: ${({ ml }) => ml ?? 0}px;
`;

export const Notification = styled.View<{
  margin?: number;
  backgroundColor?: string;
}>`
  margin: ${({ margin }) => margin ?? 0}px;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.colors.gray100};
  border-radius: 12px;
  padding: 20px;
  width: 100%;
`;

export const StyledContainer = styled(StyledWrapper)<{ color: string }>`
  background-color: ${(p) => p.color};
  border-radius: 8px;
  padding: 16px;
`;

export const SheetSection = styled.View<{
  mb?: number;
  pt?: number;
  pl?: number;
  pb?: number;
  pr?: number;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ mb }) => mb ?? 10}px;
  padding-top: ${({ pt }) => pt ?? 20}px;
  padding-left: ${({ pl }) => pl ?? 20}px;
  padding-bottom: ${({ pb }) => pb ?? 20}px;
  padding-right: ${({ pr }) => pr ?? 20}px;
`;
