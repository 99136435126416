import { useReactiveVar } from '@apollo/client';
import { Button } from 'aimo-ui';
import { FooterButtonContainer } from 'components/sheets/SheetLayout';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { activeElement } from 'states/common';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

type ParkingSignInButtonProps = {
  loading?: boolean;
  testID: string;
};

const ParkingSignInButton = ({ loading, testID }: ParkingSignInButtonProps) => {
  const { t } = useTranslation();
  const {
    flags: { onboarding_v2 },
  } = useContext(FeatureFlagsContext);
  const useOnboardingV2 = showFeatureFlag(onboarding_v2);
  const { navigateToAuthentication, setReturnRedirectToBuyShortTermParking } =
    useAfterAuthenticationRedirect();
  const parkingZoneUid = useReactiveVar(activeElement);

  const handleOnPress = async () => {
    setReturnRedirectToBuyShortTermParking(parkingZoneUid);
    await navigateToAuthentication(isWeb);
  };

  return (
    <FooterButtonContainer pb={10}>
      <Button
        size="l"
        text={
          useOnboardingV2
            ? t('parking.notSignedIn.button.long')
            : t('account.signIn')
        }
        {...testIdentifiers(testID)}
        onPress={handleOnPress}
        loading={loading}
        disabled={loading}
      />
    </FooterButtonContainer>
  );
};

export default ParkingSignInButton;
