import { BaseText, Button, theme } from 'aimo-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { sheetModal } from 'states/common';
import { CenterRow, StyledWrapper } from 'styles/ContainerStyles';

const NoPaymentRequiredModalContent = () => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledWrapper>
        <BaseText color={theme.colors.gray200} center>
          {t('permit.access.paymentNotRequiredInfo')}
        </BaseText>
      </StyledWrapper>
      <CenterRow>
        <Button
          size="l"
          onPress={() => sheetModal('')}
          text={t('button.close')}
          testID="close-button"
        />
      </CenterRow>
    </StyledWrapper>
  );
};

export default NoPaymentRequiredModalContent;
