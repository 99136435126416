import { Dimensions } from 'react-native';
import useBottomMenuHeight from './useBottomMenuHeight';
import { hasNotchOrDynamicIsland } from 'utils/deviceUtils';
const NOTCH_DEFAULT_HEIGHT = 40;
const useSafeContainerDimension = () => {
  const { height } = Dimensions.get('window');
  const bottomMenuHeight = useBottomMenuHeight();
  const containerHeight = height - bottomMenuHeight;
  return {
    containerHeight,
    containerHeightSafe:
      containerHeight - (hasNotchOrDynamicIsland() ? NOTCH_DEFAULT_HEIGHT : 0),
    notchPercentage: hasNotchOrDynamicIsland()
      ? (NOTCH_DEFAULT_HEIGHT / height) * 100
      : 0,
  };
};

export default useSafeContainerDimension;
