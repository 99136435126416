import { Button, ListItem, Spinner, theme } from 'aimo-ui';
import { itemProps } from 'aimo-ui/lib/typescript/src/components/ListItem/types';
import useAddPaymentMethod from 'hooks/useAddPaymentMethod';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledWrapper } from 'styles/ContainerStyles';
import { ElementWrapper, ListContainer } from 'styles/ListStyle';
import { AddPaymentMethodProps, PaymentDataProps } from 'types/PaymentData';
import { testIdentifiers } from 'utils/testIdentifiers';
import Icon from '../icons/Icon';

const AddPaymentMethod = ({
  paymentData,
  isFlex = false,
}: AddPaymentMethodProps) => {
  const { addCard: onPress, loadingAddCard } = useAddPaymentMethod();

  const PaymentListItem = ({
    item,
    indexValue,
  }: itemProps<PaymentDataProps>) => {
    const { t } = useTranslation();
    const { layout = 'link', title, testID, iconRight, icon } = item;

    if (loadingAddCard) {
      return (
        <StyledWrapper center testID="add-payment-card-form-loading">
          <Spinner />
        </StyledWrapper>
      );
    }

    if (layout === 'button') {
      return (
        <Button
          size="l"
          onPress={() => onPress(item)}
          text={t(title)}
          testID={testID}
        />
      );
    }
    return (
      <ListItem
        item={item}
        indexValue={indexValue}
        title={t(title)}
        onPress={onPress}
        testID={testID}
        withBackground={theme.colors.gray50}
        iconComponent={() => (
          <ElementWrapper>
            <Icon name={icon} size="m" />
          </ElementWrapper>
        )}
        arrowComponent={() =>
          iconRight ? (
            <ElementWrapper>
              <Icon name={iconRight} size="m" />
            </ElementWrapper>
          ) : null
        }
      />
    );
  };
  return (
    <ListContainer
      isFlex={isFlex}
      {...testIdentifiers('add-credit-card-button-container')}>
      {paymentData &&
        paymentData.map((paymentCard, index) => {
          return paymentCard ? (
            <PaymentListItem
              key={`payment-methods-${index}`}
              item={paymentCard}
              indexValue={index}
            />
          ) : (
            <></>
          );
        })}
    </ListContainer>
  );
};

export default AddPaymentMethod;
