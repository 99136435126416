import { useMutation } from '@apollo/client';
import { STOP_CHARGING_SESSION } from '@mutations/stopChargingSession';
import { useNavigation } from '@react-navigation/native';
import { showToast } from 'components/common/CustomToast';
import { expectingChargingReceipt, sheetModal } from 'states/common';
import { Mutation } from 'types/generatedSchemaTypes';
import { RootNavProps } from 'types/navigation';
import useOngoingChargingSession from './useOngoingChargingSession';
import useOngoingParkingSession from './useOngoingParkingSession';

const useStopChargingMutation = () => {
  const { navigate } = useNavigation<RootNavProps>();
  const { allParkingSessions } = useOngoingParkingSession();
  const { ongoingChargingSession, ongoingChargingSessionRefetch } =
    useOngoingChargingSession();
  const chargingZone = ongoingChargingSession?.parkingZoneUid;
  const hasParkingSession = allParkingSessions?.find(
    (parkingSession) => parkingSession?.parkingZoneUid === chargingZone
  );
  const [stopChargingSessionV2, { loading }] = useMutation<Mutation>(
    STOP_CHARGING_SESSION,
    {
      onCompleted: async (data) => {
        await ongoingChargingSessionRefetch();
        showToast(
          hasParkingSession
            ? 'success.chargingStopContinueParking'
            : 'success.chargingStop',
          'success'
        );
        sheetModal('');
        if (!hasParkingSession) {
          expectingChargingReceipt(true);
          const receiptData = data?.stopChargingSessionV2 || {};
          navigate('chargingReport', { receiptData });
        }
      },
      onError: (error) => {
        showToast('error.chargingStop', 'error');
      },
    }
  );
  return { stopChargingSessionV2, loading };
};
export default useStopChargingMutation;
