import { useQuery } from '@apollo/client';
import { RouteProp, useRoute } from '@react-navigation/native';
import { Headline3, Spinner } from 'aimo-ui';
import ParkingDetails from 'components/account/parking/ParkingDetails';
import PaymentDetails from 'components/account/parking/PaymentDetails';
import Sheet from 'components/sheets/Sheet';
import { READ_PARKING_SESSION } from 'graphql/queries/readParkingSession';
import useReadChargingSession from 'hooks/useReadChargingSession';
import useReadReceiptByTransactionId from 'hooks/useReadReceiptByTransactionId';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';
import { Divider } from 'styles/CommonLayoutStyles';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import {
  ParkZone,
  ParkingSession,
  Query,
  QueryReadParkingSessionArgs,
  Receipt,
} from 'types/generatedSchemaTypes';
import { RootStackParamList } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';

const ParkingAndChargingHistoryDetailsScreen: React.FC = () => {
  const { t } = useTranslation();
  const {
    params: { parkingSessionId, chargingSessionId },
  } = useRoute<RouteProp<RootStackParamList, 'parkAndChargeDetails'>>();

  const { data: parkingSessionData } = useQuery<
    Query,
    QueryReadParkingSessionArgs
  >(READ_PARKING_SESSION, {
    variables: { sessionId: parkingSessionId ?? '' },
    skip: !parkingSessionId,
    fetchPolicy: 'cache-and-network',
  });

  const {
    parkZone,
    transactionId: parkingTransactionId,
    paymentState: parkingPaymentState,
  } = parkingSessionData?.readParkingSession || {};

  const { data: parkingReceiptData } = useReadReceiptByTransactionId(
    parkingTransactionId ?? ''
  );

  const { chargingSessionData, chargingTransactionId, chargingPaymentError } =
    useReadChargingSession(chargingSessionId, true);

  const { data: chargingReceiptData } = useReadReceiptByTransactionId(
    chargingTransactionId ?? ''
  );

  const isParkingPaymentFailed =
    parkingPaymentState === 'Failed' || parkingPaymentState === 'Retrying';

  return (
    <Sheet snappingPoints={[isWeb ? '80vh' : '100%']} hideHandle>
      <>
        <ColumnWithMargins mt={35} mb={20}>
          <Headline3 center>{t('parking.receipt.title')}</Headline3>
        </ColumnWithMargins>
        <ScrollView>
          <ColumnWithMargins ml={20} mr={20}>
            <ParkingDetails
              testID="parking-summary"
              receiptData={
                parkingReceiptData?.readReceiptByTransactionId as Receipt
              }
              parkingSession={
                parkingSessionData?.readParkingSession as ParkingSession
              }
              parkZone={parkZone as ParkZone}
            />
          </ColumnWithMargins>
          <Divider size={10} marginVertical={30} />
          <ColumnWithMargins ml={20} mr={20}>
            <ColumnWithMargins mb={10}>
              <Headline3>{t('parking.receipt.parking')}</Headline3>
            </ColumnWithMargins>
            <PaymentDetails
              testID="payment-summary-parking"
              receiptData={
                parkingReceiptData?.readReceiptByTransactionId as Receipt
              }
              parkingSession={
                parkingSessionData?.readParkingSession as ParkingSession
              }
              isPaymentFailed={isParkingPaymentFailed}
              transactionId={parkingTransactionId ?? ''}
              showTitle={false}
            />
          </ColumnWithMargins>
          <Divider size={10} marginVertical={30} />
          <ColumnWithMargins ml={20} mr={20}>
            <ColumnWithMargins mb={10}>
              <Headline3>{t('parking.receipt.charging')}</Headline3>
            </ColumnWithMargins>
            {chargingReceiptData?.readReceiptByTransactionId ||
            chargingPaymentError ? (
              <PaymentDetails
                testID="payment-summary-charging"
                isPaymentFailed={!chargingTransactionId}
                receiptData={
                  chargingReceiptData?.readReceiptByTransactionId as Receipt
                }
                transactionId={chargingTransactionId ?? ''}
                showTitle={false}
                chargingSession={
                  chargingTransactionId
                    ? undefined
                    : chargingSessionData?.readChargingSession || {}
                }
              />
            ) : (
              <StyledWrapper center>
                <Spinner />
              </StyledWrapper>
            )}
          </ColumnWithMargins>
        </ScrollView>
      </>
    </Sheet>
  );
};

export default ParkingAndChargingHistoryDetailsScreen;
