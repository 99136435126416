/** to get dynamic variables from kube to static react app build, we need to place variables from config.js file to window.X.
 * This file only exists if server.js is running so we need fallback to normal process.env variables on local **/
import { config as dotenvConfig } from 'dotenv';

dotenvConfig();

const config = {
  dotEnvFilenameVar: process.env.DOTENV_FILENAME_VAR,
  buildId: process.env.CI_PIPELINE_ID,
  graphqlUrl:
    window?.REACT_APP_GRAPHQL_URL || process.env.REACT_APP_GRAPHQL_URL,
  reactiveVarPrefix: '@aimodevRV_',
  siteName: window?.REACT_APP_SITE_NAME || process.env.REACT_APP_SITE_NAME,
  devScreen: process.env.DOTENV_FILENAME_VAR === 'env' ? 'true' : 'false',
  isE2ETest: window?.REACT_APP_IS_E2E_TEST || process.env.REACT_APP_IS_E2E_TEST,
  REACT_APP_AZURE_DOMAIN:
    window?.REACT_APP_AZURE_DOMAIN || process.env.REACT_APP_AZURE_DOMAIN,
  REACT_APP_AZURE_CLIENT_ID:
    window?.REACT_APP_AZURE_CLIENT_ID || process.env.REACT_APP_AZURE_CLIENT_ID,
  REACT_APP_SIGNIN_FLOW:
    window?.REACT_APP_SIGNIN_FLOW || process.env.REACT_APP_SIGNIN_FLOW,
  REACT_APP_SIGNUP_SIGNIN_FLOW:
    window?.REACT_APP_SIGNUP_SIGNIN_FLOW ||
    process.env.REACT_APP_SIGNUP_SIGNIN_FLOW,
  REACT_APP_SUSI_FLOW:
    window?.REACT_APP_SUSI_FLOW || process.env.REACT_APP_SUSI_FLOW,
  REACT_APP_SUSI_TEST_FLOW:
    window?.REACT_APP_SUSI_TEST_FLOW || process.env.REACT_APP_SUSI_TEST_FLOW,
  REACT_APP_REDIRECT_URI_WEB:
    window?.REACT_APP_REDIRECT_URI_WEB ||
    process.env.REACT_APP_REDIRECT_URI_WEB,
  REACT_APP_REDIRECT_URI_LOGOUT:
    window?.REACT_APP_REDIRECT_URI_LOGOUT ||
    process.env.REACT_APP_REDIRECT_URI_LOGOUT,
  PRIVACY_POLICY_URL:
    window?.PRIVACY_POLICY_URL || process.env.PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL:
    window?.TERMS_AND_CONDITIONS_URL || process.env.TERMS_AND_CONDITIONS_URL,
  REACT_APP_UPPSALA_SERVICE:
    window?.REACT_APP_UPPSALA_SERVICE || process.env.REACT_APP_UPPSALA_SERVICE,
  REACT_APP_HTTP_BACKEND_TRANSLATIONS_URL:
    window?.REACT_APP_HTTP_BACKEND_TRANSLATIONS_URL ||
    process.env.REACT_APP_HTTP_BACKEND_TRANSLATIONS_URL,
  googleMapsApiKey:
    window?.REACT_APP_GOOGLE_MAPS_API_KEY ||
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  serviceChannelCode:
    window?.SERVICE_CHANNEL_CODE || process.env.SERVICE_CHANNEL_CODE,
  featureFlagsDefault: {
    account_contact_edit:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_CONTACT_EDIT ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_CONTACT_EDIT,
    account_contact_invoice_email:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_CONTACT_INVOICE_EMAIL ||
      process.env
        .REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_CONTACT_INVOICE_EMAIL,
    account_contact_phone_number:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_CONTACT_PHONE_NUMBER ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_CONTACT_PHONE_NUMBER,
    account_create:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_CREATE ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_CREATE,
    account_history_permit_parking_space:
      window.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_HISTORY_PERMIT_PARKING_SPACE ||
      process.env
        .REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_HISTORY_PERMIT_PARKING_SPACE,
    account_notification_settings:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_NOTIFICATION_SETTINGS ||
      process.env
        .REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_NOTIFICATION_SETTINGS,
    account_notification_settings_parking_ending_reminders:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_NOTIFICATION_SETTINGS_PARKING_ENDING_REMINDERS ||
      process.env
        .REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_NOTIFICATION_SETTINGS_PARKING_ENDING_REMINDERS,
    account_rides:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_RIDE ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_ACCOUNT_RIDE,
    anpr:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_ANPR ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_ANPR,
    app_version_mandatory:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_APP_VERSION_MANDATORY ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_APP_VERSION_MANDATORY,
    app_version_optional:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_APP_VERSION_OPTIONAL ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_APP_VERSION_OPTIONAL,
    cancel_sales_order:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_CANCEL_SALES_ORDER ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_CANCEL_SALES_ORDER,
    card_business:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_CARD_BUSINESS ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_CARD_BUSINESS,
    company_benefits:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_COMPANY_BENEFITS ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_COMPANY_BENEFITS,
    ev_charging_public_ongoingParking:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_EV_CHARGING_PUBLIC_ONGOING_PARKING ||
      process.env
        .REACT_APP_FIREBASE_REMOTE_CONFIG_EV_CHARGING_PUBLIC_ONGOING_PARKING,
    ev_charging_public_anpr_manualStopAllowed:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_EV_CHARGING_PUBLIC_ANPR_MANUAL_STOP_ALLOWED ||
      process.env
        .REACT_APP_FIREBASE_REMOTE_CONFIG_EV_CHARGING_PUBLIC_ANPR_MANUAL_STOP_ALLOWED,
    ev_charging_public_anpr_manualStopDisabled:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_EV_CHARGING_PUBLIC_ANPR_MANUAL_STOP_DISABLED ||
      process.env
        .REACT_APP_FIREBASE_REMOTE_CONFIG_EV_CHARGING_PUBLIC_ANPR_MANUAL_STOP_DISABLED,
    ev_charging_public_startAndStopParking:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_EV_CHARGING_PUBLIC_START_STOP_PARKING ||
      process.env
        .REACT_APP_FIREBASE_REMOTE_CONFIG_EV_CHARGING_PUBLIC_START_STOP_PARKING,
    hsl_ticket:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_HSL_TICKET ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_HSL_TICKET,
    lang_fi:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_LANG_FI ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_LANG_FI,
    lang_no:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_LANG_NO ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_LANG_NO,
    load_remote_translations:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_LOAD_REMOTE_TRANSLATIONS ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_LOAD_REMOTE_TRANSLATIONS,
    ltp_buy_schedule:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_LTP_BUY_SCHEDULE ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_LTP_BUY_SCHEDULE,
    onboarding_v2:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_ONBOARDING_V2 ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_ONBOARDING_V2,
    park_and_charge_mentions:
      window?.REACT_APP_FIREBASE_REMOTE_PARK_AND_CHARGE_MENTIONS ||
      process.env.REACT_APP_FIREBASE_REMOTE_PARK_AND_CHARGE_MENTIONS,
    park_schedule:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_PARK_SCHEDULE ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_PARK_SCHEDULE,
    payment_type_filter:
      window?.REACT_APP_FIREBASE_REMOTE_PAYMENT_TYPE_FILTER ||
      process.env.REACT_APP_FIREBASE_REMOTE_PAYMENT_TYPE_FILTER,
    permit_turku:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_PERMIT_TURKU ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_PERMIT_TURKU,
    rate:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_RATE ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_RATE,
    remote_tutorial:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_REMOTE_TUTORIAL ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_REMOTE_TUTORIAL,
    retry_payment:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_RETRY_PAYMENT ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_RETRY_PAYMENT,
    ride:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_RIDE ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_RIDE,
    sales_order_permits:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_SALES_ORDER_PERMITS ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_SALES_ORDER_PERMITS,
    service_chips:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_SERVICE_CHIPS ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_SERVICE_CHIPS,
    show_norway:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_SHOW_NORWAY ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_SHOW_NORWAY,
    show_pooling_spots_refresh_button_delay_milliseconds:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_SHOW_POOLING_SPOTS_REFRESH_BUTTON_DELAY_MILLISECONDS ||
      process.env
        .REACT_APP_FIREBASE_REMOTE_CONFIG_SHOW_POOLING_SPOTS_REFRESH_BUTTON_DELAY_MILLISECONDS,
    stp_organisational_user:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_STP_ORGANISATIONAL_USER ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_STP_ORGANISATIONAL_USER,
    stp_tariff:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_STP_TARIFF ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_STP_TARIFF,
    stp_type:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_STP_TYPE ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_STP_TYPE,
    street_parking:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_STREET_PARKING ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_STREET_PARKING,
    time_wheel:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_TIME_WHEEL ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_TIME_WHEEL,
    time_wheel_config:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_TIME_WHEEL_CONFIG ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_TIME_WHEEL_CONFIG,
    use_email_verification:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_USE_EMAIL_VERIFICATION ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_USE_EMAIL_VERIFICATION,
    vehicle_registration_country:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_VEHICLE_REGISTRATION_COUNTRY ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_VEHICLE_REGISTRATION_COUNTRY,
    vehicle_type:
      window?.REACT_APP_FIREBASE_REMOTE_CONFIG_VEHICLE_TYPE ||
      process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_VEHICLE_TYPE,
  },
};

export default config;
