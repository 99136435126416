import { useEffect } from 'react';
import { BackHandler } from 'react-native';
import { isWeb } from 'utils/deviceUtils';

const useBackButton = (handler: () => boolean | null | undefined) => {
  useEffect(() => {
    if (!isWeb) {
      BackHandler.addEventListener('hardwareBackPress', handler);
    }
    return () => {
      if (!isWeb) {
        BackHandler.removeEventListener('hardwareBackPress', handler);
      }
    };
  }, [handler]);
};

export default useBackButton;
