import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { mapStyles } from '@styles/StyleSheetStyles';
import { IconMap, Meta, theme } from 'aimo-ui';
import useBackButton from 'hooks/useBackButton';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';
import useIsDesktop from 'hooks/useIsDesktop';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { userLocation } from 'states/map';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
import { RootNavProps } from 'types/navigation';
import { getLang } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { checkLocationPermissions } from 'utils/mapUtils';
import { mapCoordinates } from '../../states/map';
import Icon from '../icons/Icon';
import { testIdentifiers } from 'utils/testIdentifiers';
import { selectedParkingSpace } from 'states/common';

const AbsoluteView = styled.View<{
  top: number;
  left?: boolean;
  forceTop?: boolean;
}>`
  position: absolute;
  ${({ top, forceTop }) =>
    isWeb && !forceTop ? 'bottom: 120px' : 'top: ' + top + 'px'};
  ${({ left }) => (left ? 'left: 20px' : 'right: 7px')};
`;

const AbsoluteCenterView = styled(StyledWrapper)<{ top: number }>`
  position: absolute;
  top: ${(p) => p.top}px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 60px;
  padding: 5px 15px;
  max-height: ${(p) => p.theme.normalising.heightPixel(48)}px;
`;

const LogoView = styled.View<{ top: number }>`
  z-index: ${isWeb ? 1 : 0};
  position: absolute;
  top: ${(p) => p.top}px;
  margin-top: -5px;
  left: 24px;
`;

const MapButton = styled.TouchableOpacity`
  background: ${theme.colors.white};
  padding: 12px;
  border-radius: 60px;
  max-width: 48px
  max-height: 48px;
  box-shadow: 0px 8px 16px rgba(1, 1, 1, 0.3);
`;

export const UserLocationButton = ({ testID }: { testID?: string }) => {
  const { top } = useSafeAreaInsets();
  const currentLocation = useReactiveVar(userLocation);
  const tabHeight = useBottomMenuHeight();
  const isDesktop = useIsDesktop();
  const handleNavigateToUserLocation = async () => {
    if (currentLocation) {
      mapCoordinates(currentLocation);
    }
    checkLocationPermissions();
  };

  const topMargin = () => {
    if (!isDesktop && isWeb) {
      return tabHeight + 100;
    }
    if (top < 15) {
      return 15;
    }
    return top;
  };

  return (
    <AbsoluteView
      forceTop={!isDesktop && isWeb}
      top={topMargin()}
      {...testIdentifiers((testID as string) && `${testID}-container`)}>
      <MapButton
        onPress={handleNavigateToUserLocation}
        {...testIdentifiers(testID as string)}
        style={mapStyles().mapButton}
        activeOpacity={0.8}>
        <Icon
          name="Location-Filled-01"
          color={currentLocation ? theme.colors.black : theme.colors.boost.red}
        />
      </MapButton>
    </AbsoluteView>
  );
};

export const BackButton = ({ testID }: { testID?: string }) => {
  const { top } = useSafeAreaInsets();

  const { navigate, goBack, canGoBack } = useNavigation<RootNavProps>();
  const handleNavigateBack = () => {
    if (canGoBack()) {
      selectedParkingSpace(undefined);
      goBack();
    } else {
      navigate('mainTabs', { screen: 'parkScreen' });
    }
    return true;
  };

  useBackButton(handleNavigateBack);

  return (
    <AbsoluteView
      top={top < 15 ? 15 : top}
      forceTop
      left
      testID={testID && `${testID}-container`}>
      <MapButton
        onPress={handleNavigateBack}
        {...testIdentifiers(testID as string)}
        style={mapStyles().mapButton}
        activeOpacity={0.8}>
        <Icon name="back" size="m" color={theme.colors.black} />
      </MapButton>
    </AbsoluteView>
  );
};

export const Logo = ({ testID }: { testID?: string }) => {
  const { top } = useSafeAreaInsets();
  const topNumber = () => {
    if (isWeb) {
      return 21;
    }
    return top < 15 ? 15 : top;
  };
  const LogoComponent = IconMap?.Logo_black;

  if (!LogoComponent) {
    return <></>;
  }
  return (
    <LogoView
      top={topNumber()}
      {...testIdentifiers((testID as string) && `${testID}-container`)}>
      <LogoComponent
        width="55"
        height="55"
        {...testIdentifiers(testID as string)}
      />
    </LogoView>
  );
};

const marginTop = (top: number) => {
  if (isWeb) {
    return 100;
  }
  return top < 15 ? 15 : top;
};

export const ZoomedTooFar = () => {
  const { t } = useTranslation();
  const { top } = useSafeAreaInsets();
  const lang = getLang();
  return (
    <AbsoluteCenterView
      maxWidth={isWeb && lang === 'sv' ? 180 : 165}
      center
      top={marginTop(top)}>
      <Meta numberOfLines={2} color={theme.colors.gray200} center>
        {t('parking.streetParkingNotification')}
      </Meta>
    </AbsoluteCenterView>
  );
};
