import { createStackNavigator } from '@react-navigation/stack';
import LegalAddressInfoScreen from 'screens/account/LegalAddressInfoScreen';
import LegalAddressAuthScreen from 'screens/account/LegalAddressAuthScreen';
import React from 'react';
import { theme } from 'aimo-ui';
import { useTranslation } from 'react-i18next';

const LegalAddressAuthStack = createStackNavigator();

const LegalAddressAuthNavigator = () => {
  const { t } = useTranslation();
  return (
    <LegalAddressAuthStack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        animationEnabled: true,
      }}>
      <LegalAddressAuthStack.Screen
        name="info"
        component={LegalAddressInfoScreen}
        options={{
          title: t('services.permits'),
          headerBackTitleVisible: false,
          headerTransparent: false,
          headerShown: false,
          cardStyle: {
            flex: 1,
            backgroundColor: theme.colors.gray100,
          },
        }}
      />
      <LegalAddressAuthStack.Screen
        name="authenticate"
        component={LegalAddressAuthScreen}
        options={{
          headerBackTitleVisible: false,
          headerTransparent: false,
          headerShown: false,
          cardStyle: {
            flex: 1,
            backgroundColor: theme.colors.gray100,
          },
        }}
      />
    </LegalAddressAuthStack.Navigator>
  );
};

export default LegalAddressAuthNavigator;
