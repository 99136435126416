import { gql } from '@apollo/client';

export const READ_PARKING_SESSION = gql`
  query ReadParkingSession($sessionId: ID!) {
    readParkingSession(sessionId: $sessionId) {
      sessionId
      transactionId
      startDate
      endDate
      corporateAccountUid
      predefinedTicketUid
      predefinedEndDate
      countryCode
      parkingZoneUid
      parkZone {
        uid
        name
        locationType
        commercialZoneView {
          name
          zoneCode
        }
        address {
          marker
        }
        info {
          doorCode
        }
        parkingMethods {
          anpr
        }
      }
      cardId
      accessDevice {
        value
      }
      customerSegment {
        uid
      }
      paymentState
      price {
        totalAmount
        vatAmount
        amountExclVAT
        vatPercentage
        breakdown {
          parkingFee {
            amountExclVAT
          }
          serviceFee {
            amountExclVAT
          }
        }
      }
      receipt {
        created
      }
      changelog {
        created
        from {
          accessDeviceValue
        }
        to {
          accessDeviceValue
        }
      }
      poolingInfo {
        poolingGroupUid
        pooled
        customerGroupUid
        poolParkingType
        b2bCustomerUid
      }
    }
  }
`;
