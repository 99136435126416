import { theme } from 'aimo-ui';
import styled from 'styled-components/native';
import { isWeb } from 'utils/deviceUtils';

export const Container = styled.View`
  flex: 1;
`;

export const MarginView = styled.View`
  margin-bottom: ${isWeb ? 0 : 90}px;
`;

export const Center = styled.View`
  flex: 1;
  align-items: center;
  padding: 60px;
`;

export const Row = styled.View<{
  mt?: number;
  mb?: number;
  minHeight?: number;
}>`
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 'auto')};
  overflow: hidden;
  margin: ${({ mt }) => mt ?? 10}px 0 ${({ mb }) => mb ?? 10}px 0;
`;

export const IconWrapper = styled.View<{ mr?: number }>`
  margin-right: ${({ mr }) => mr ?? 16}px;
`;

export const AccessPanel = styled.View`
  position: absolute;
  bottom: ${theme.normalising.heightPixel(390)}px;
  flex: 1;
  width: 100%;
  margin-right: -10px;
  padding-top: 10px;
  padding-left: 10px;
`;
