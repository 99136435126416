import { Customer } from 'types/generatedSchemaTypes';

// Check if customerSubTypes only contains AIMO_APP and serviceChannels only contains code AIMO_ONE
export const isOnlyAimoAppCustomer = (customer: Customer): boolean => {
  const { customerSubTypes, serviceChannels } = customer;
  return (
    customerSubTypes?.length === 1 &&
    customerSubTypes[0] === 'AIMO_APP' &&
    serviceChannels?.length === 1 &&
    serviceChannels[0]?.code === 'AIMO_ONE'
  );
};
