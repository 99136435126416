import React from 'react';
import styled from 'styled-components/native';
import Icon from 'components/icons/Icon';
import tinycolor from 'tinycolor2';
import { theme } from 'aimo-ui';
import { SizeProps } from 'types/IconTypes';
import { colors, vehicleIcons } from '../onboarding/vehicleSettings';

const defaultIcon = vehicleIcons[0];
const defaultColor = colors[0];

const VehicleIconWrapper = styled.View<{
  color: string;
  numericSize: number;
  showBackground: boolean;
  showBorder: boolean;
}>`
  height: ${({ numericSize }) => numericSize + numericSize / 3}px;
  width: ${({ numericSize }) => numericSize + numericSize / 3}px;
  ${(props) =>
    props.showBackground ? `background-color: ${props.color};` : undefined}
  border-radius: 40px;
  align-self: center;
  align-items: center;
  justify-content: center;
  ${(props) => border(props.color, props.showBorder)}
`;

const border = (color: string, showBorder: boolean) => {
  if (!showBorder) {
    return undefined;
  }
  return `border: 2px solid
  ${color === '#FFFFFF' ? '#D6DFE3' : '#FFFFFF'};`;
};

type VehicleAvatarProps = {
  color?: string | null;
  icon?: string | null;
  size?: string;
  showBackground?: boolean;
  showBorder?: boolean;
};

const VehicleAvatar = ({
  color,
  icon,
  size = 'm',
  showBackground = true,
  showBorder = true,
}: VehicleAvatarProps) => {
  const sizes: SizeProps = theme.iconSizes;
  const numericSize = sizes[size];

  return (
    <VehicleIconWrapper
      numericSize={numericSize}
      color={color || defaultColor}
      showBackground={showBackground}
      showBorder={showBorder}>
      <Icon
        name={icon || defaultIcon}
        size={size}
        color={
          tinycolor(color || defaultColor).isLight()
            ? theme.colors.black
            : theme.colors.white
        }
        testID={`vehicle-avatar-${color || defaultColor}-${
          icon || defaultIcon
        }`}
      />
    </VehicleIconWrapper>
  );
};

export default VehicleAvatar;
