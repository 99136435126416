import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import {
  BaseText,
  Button,
  Headline2,
  Headline3,
  Meta,
  Spinner,
  theme as styleTheme,
  theme,
} from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import AccountContext from 'context/AccountContext';
import { add, isWithinInterval } from 'date-fns';
import { GET_PARK_ZONE } from 'graphql/queries/getParkZone';
import usePaymentDetails from 'hooks/usePaymentDetails';
import usePriceExtraction from 'hooks/usePriceExtraction';
import useReadReceiptByTransactionId from 'hooks/useReadReceiptByTransactionId';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {
  ColumnWithMargins,
  RowSpaceBetween,
  StyledWrapper,
} from 'styles/ContainerStyles';
import {
  BillingItem,
  Maybe,
  ParkingSession,
  Query,
  QueryParkZoneArgs,
} from 'types/generatedSchemaTypes';
import { RootNavProps } from 'types/navigation';
import { currencyFormatter, getCurrencyString } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import { getDurationFromDates } from 'utils/timeUtils';
import Icon from 'components/icons/Icon';
import styled from 'styled-components';

type ParkingReceiptContentProps = {
  sessionData?: ParkingSession;
  chargingSessionId: string | undefined;
  chargingTransactionId: Maybe<string> | undefined;
  chargingPaymentError: boolean;
  chargingSessionPrice: number;
};

const width = isWeb ? 550 : Dimensions.get('window').width;

const ParkingReceiptContent: React.FC<ParkingReceiptContentProps> = ({
  sessionData,
  chargingSessionId,
  chargingTransactionId,
  chargingPaymentError,
  chargingSessionPrice,
}) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<RootNavProps>();

  const {
    customer: { vehicles },
  } = useContext(AccountContext);
  const {
    paymentState,
    accessDevice,
    startDate,
    endDate,
    price,
    countryCode,
    cardId,
    parkingZoneUid,
    transactionId,
    sessionId,
    customerSegment,
    corporateAccountUid,
  } = sessionData || {};
  const { data, error, loading } = useQuery<Query, QueryParkZoneArgs>(
    GET_PARK_ZONE,
    {
      variables: { uid: parkingZoneUid || '' },
      skip: !parkingZoneUid,
    }
  );

  const parkAndCharge = !!chargingSessionId;

  const { data: chargingReceiptData } = useReadReceiptByTransactionId(
    chargingTransactionId ?? ''
  );

  const { totalChargingNumeric } = usePriceExtraction(
    chargingReceiptData?.readReceiptByTransactionId?.items as [BillingItem],
    countryCode ?? 'FI',
    !chargingTransactionId,
    chargingSessionPrice
  );

  const paymentDetails = usePaymentDetails({
    transactionId,
    cardId,
    corporateAccountUid,
    totalAmount: price?.totalAmount,
  });

  const vehicleInfo = vehicles?.find(
    (vehicle) => vehicle.licensePlate.plateText === accessDevice?.value
  );

  const vehicleText =
    vehicleInfo && vehicleInfo.name
      ? `${vehicleInfo.name} (${vehicleInfo.licensePlate.plateText})`
      : accessDevice?.value;
  if (!data || error || loading) {
    return <></>;
  }

  const isPaymentSuccess =
    paymentState !== 'Failed' &&
    paymentState !== 'Retrying' &&
    !chargingPaymentError;
  const { name, commercialZoneView, uid } = data.parkZone || {};
  const duration = getDurationFromDates(
    new Date(startDate || 0),
    new Date(endDate || 0)
  );

  const totalPrice = currencyFormatter(
    (price?.totalAmount ?? 0) +
      (totalChargingNumeric ? totalChargingNumeric : 0),
    countryCode ?? 'FI'
  );
  const currencyString = getCurrencyString(countryCode);
  const formattedEndDate = endDate ? new Date(endDate) : null;
  const { doorCode } = data.parkZone?.info || {};
  const showDoorCode =
    doorCode !== null &&
    doorCode !== '' &&
    formattedEndDate !== null &&
    isWithinInterval(new Date(), {
      start: formattedEndDate,
      end: add(formattedEndDate, { minutes: 15 }),
    });

  const navigateToDetails = () => {
    if (parkAndCharge) {
      navigate('parkAndChargeDetails', {
        parkingSessionId: sessionId,
        chargingSessionId: chargingSessionId,
      });
    } else {
      navigate('parkingDetails', {
        sessionId: sessionId || '',
        parkingZoneUid: uid || '',
        closeButton: true,
        navigateToParkScreen: true,
      });
    }
  };

  const MetaBaseTextFix = styled(BaseText)`
    font-size: 16px;
  `;

  return (
    <ScrollView>
      <StyledWrapper mb={15} center maxWidth={width}>
        <Headline3 center>
          {t(
            `parking.receipt.sheetHeader${parkAndCharge ? 'ParkAndCharge' : ''}`
          )}
        </Headline3>
      </StyledWrapper>
      <StyledWrapper mb={10} center maxWidth={width}>
        <RowSpaceBetween>
          <Headline2 testID="parking-receipt-duration">{duration}</Headline2>
          <Headline2
            {...testIdentifiers('parking-receipt-total-price')}
            color={
              isPaymentSuccess
                ? styleTheme.colors.black
                : styleTheme.colors.boost.red
            }>
            {parkAndCharge &&
            !chargingTransactionId &&
            !chargingPaymentError ? (
              <>
                <Spinner small />
                {currencyString}
              </>
            ) : (
              totalPrice
            )}
            {!isPaymentSuccess && ` (${t('parking.receipt.paymentFailed')})`}
          </Headline2>
        </RowSpaceBetween>
      </StyledWrapper>
      {showDoorCode && (
        <StyledWrapper mb={10} center maxWidth={width}>
          <MenuItem
            menuLabel={t('parking.garageEntryCode')}
            iconName="Unlocked-Outlined"
            noBorder
            testID="parking-receipt-garage-entry-code"
            background={theme.colors.gray100}
            large
            rightComponent={<Headline2>{doorCode}</Headline2>}
          />
        </StyledWrapper>
      )}
      <StyledWrapper mb={isWeb ? 30 : 10} center maxWidth={width}>
        <RowSpaceBetween withBorder>
          <ColumnWithMargins>
            <Meta color={styleTheme.colors.gray200}>
              {t('parking.receipt.location')}
            </Meta>
          </ColumnWithMargins>
          <ColumnWithMargins ml={15}>
            <Meta numberOfLines={1} testID="parking-receipt-location">
              <MetaBaseTextFix color={styleTheme.colors.primary}>
                {commercialZoneView?.zoneCode
                  ? commercialZoneView?.zoneCode + ' '
                  : ''}
              </MetaBaseTextFix>
              {commercialZoneView?.name || name}
            </Meta>
          </ColumnWithMargins>
        </RowSpaceBetween>
        <RowSpaceBetween withBorder>
          <Meta color={styleTheme.colors.gray200}>
            {t('parking.receipt.car')}
          </Meta>
          <Meta {...testIdentifiers('parking-receipt-license-plate')}>
            {vehicleText}
          </Meta>
        </RowSpaceBetween>
        {sessionData?.poolingInfo &&
          !!Object.keys(sessionData?.poolingInfo)?.length && (
            <RowSpaceBetween withBorder>
              <Meta color={styleTheme.colors.gray200}>
                {t('parking.receipt.pooling')}
              </Meta>
              <Icon
                {...testIdentifiers('parking-receipt-pooling-icon')}
                name={sessionData.poolingInfo.pooled ? 'check' : 'close'}
                color={theme.colors.gray200}
              />
            </RowSpaceBetween>
          )}
        {customerSegment && (
          <RowSpaceBetween withBorder>
            <Meta color={styleTheme.colors.gray200}>
              {t('parking.receipt.tariff')}
            </Meta>
            <Meta {...testIdentifiers('parking-receipt-tariff')}>
              {customerSegment?.uid}
            </Meta>
          </RowSpaceBetween>
        )}

        <RowSpaceBetween withBorder>
          <Meta color={styleTheme.colors.gray200}>
            {t('parking.receipt.payment')}
          </Meta>
          <Meta {...testIdentifiers('parking-receipt-payment-details')}>
            {typeof paymentDetails === 'object'
              ? paymentDetails.title
              : paymentDetails}
          </Meta>
        </RowSpaceBetween>
        <StyledWrapper mb={10}>
          <MenuItem
            menuLabel={
              parkAndCharge
                ? t('parking.receipt.title')
                : t('parking.receipt.paymentDetails')
            }
            testID="parking-receipt-navigate-payment-details"
            color={theme.colors.black}
            onPress={navigateToDetails}
            noBorder
            withArrow
            arrowSize="s"
          />
        </StyledWrapper>
      </StyledWrapper>
      <StyledWrapper mb={30} center maxWidth={width}>
        <Button
          {...testIdentifiers('parking-receipt-confirm-button')}
          size="l"
          fullWidth={true}
          text={t('button.close')}
          onPress={() => navigate('mainTabs', { screen: 'parkScreen' })}
        />
      </StyledWrapper>
    </ScrollView>
  );
};

export default ParkingReceiptContent;
