import { useReactiveVar } from '@apollo/client';
import { BaseText, Button, Meta, theme, Title } from 'aimo-ui';
import VehicleAvatar from 'components/common/VehicleAvatar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  editVehicleData,
  editVehicleIsDiscarded,
  sheetModal,
  vehicleSaved,
} from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';

const UnsavedEditVehicleConfirm: React.FC = () => {
  const { t } = useTranslation();
  const selectedVehicle = useReactiveVar(editVehicleData);

  const handleDiscard = () => {
    sheetModal('');
    editVehicleIsDiscarded(true);
  };

  const handleUpdateVehicle = async () => {
    sheetModal('');
    vehicleSaved(true);
  };

  return (
    <StyledWrapper mb={0}>
      <StyledWrapper mb={20}>
        <VehicleAvatar
          color={selectedVehicle?.appSettings?.color}
          icon={selectedVehicle?.appSettings?.icon}
          size="xxl"
        />
      </StyledWrapper>
      <StyledWrapper mb={6}>
        <Title center testID="remove-vehicle-license">
          {selectedVehicle?.licensePlate?.plateText}
        </Title>
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <BaseText
          center
          testID="remove-vehicle-name"
          color={theme.colors.gray200}>
          {selectedVehicle?.name}
        </BaseText>
      </StyledWrapper>
      <StyledWrapper mb={60}>
        <Meta color={theme.colors.gray200} testID="remove-vehicle-confirm-text">
          {t('vehicle.unsavedChanges.confirmText')}
        </Meta>
      </StyledWrapper>
      <StyledWrapper mb={20}>
        <Button
          testID="edit-vehicle-confirm"
          size="l"
          buttonType="secondary"
          text={t('button.saveChanges')}
          onPress={handleUpdateVehicle}
        />
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <Button
          testID="edit-vehicle-discard"
          size="l"
          buttonType="tertiary"
          text={t('button.discard')}
          onPress={handleDiscard}
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default UnsavedEditVehicleConfirm;
