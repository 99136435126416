import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Button, Title } from 'aimo-ui';
import AddPaymentMethod from 'components/payment/AddPaymentMethod';
import CreditCardForm from 'components/payment/CreditCardForm';
import AccountContext from 'context/AccountContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { activeElement, creditCardUrlPaymentHighway } from 'states/common';
import styled from 'styled-components/native';
import { StyledImage, StyledWrapper } from 'styles/ContainerStyles';
import { FooterWrapper, MainWrapper } from 'styles/ModalStackContainerStyles';
import { OnboardingNavProps } from 'types/navigation';
import { PaymentDataProps } from 'types/PaymentData';
import { testIdentifiers } from 'utils/testIdentifiers';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';

const PictureContainer = styled.View`
  width: 100%;
  padding: 80px 0 90px;
`;

const paymentData: PaymentDataProps[] = [
  {
    title: 'payment.creditCard',
    icon: 'Credit_Card-Outlined',
    action: 'addCard',
    testID: 'add-payment-card-button',
    iconRight: 'Chevron_Right',
  },
];

const OnBoardingAddPaymentMethod: React.FC = () => {
  const { navigate } = useNavigation<OnboardingNavProps>();
  const creditCardUrl = useReactiveVar(creditCardUrlPaymentHighway);
  const parkingZoneUid = useReactiveVar(activeElement);
  const { t } = useTranslation();
  const {
    customer: { vehicles },
  } = useContext(AccountContext);
  const { skipOnboarding } = useAfterAuthenticationRedirect();

  const handleSuccessNavigation = async () => {
    const hasVehicles = vehicles && vehicles.length > 0;
    if (hasVehicles) {
      await skipOnboarding();
    } else {
      return navigate('onboardingContinue');
    }
  };
  const handleFailureNavigation = () => {
    creditCardUrlPaymentHighway(undefined);
  };

  return (
    <>
      {!creditCardUrl ? (
        <MainWrapper vAlignCenter={true}>
          <View>
            <PictureContainer>
              <StyledImage
                testID="onboarding-payment-method-image"
                width={123}
                height={102}
                center
                source={require('assets/images/onboarding-payment-method.png')}
              />
            </PictureContainer>
            <StyledWrapper center mb={30}>
              <Title center testID="onboarding-payment-method-title">
                {t('onboarding.addPaymentMethod')}
              </Title>
            </StyledWrapper>
            <AddPaymentMethod paymentData={paymentData} />
          </View>
          {parkingZoneUid === '' && (
            <FooterWrapper>
              <Button
                {...testIdentifiers('skip-button')}
                text={t('onboarding.skipForNow')}
                size="l"
                buttonType="link"
                onPress={handleSuccessNavigation}
              />
            </FooterWrapper>
          )}
        </MainWrapper>
      ) : (
        <CreditCardForm
          afterSuccess={handleSuccessNavigation}
          afterFailure={handleFailureNavigation}
        />
      )}
    </>
  );
};

export default OnBoardingAddPaymentMethod;
