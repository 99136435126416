import { differenceInDays, endOfMonth, getMonth, getYear } from 'date-fns';
import { Card, CardStatus, Maybe } from 'types/generatedSchemaTypes';

const CARD_LOGO_DEFAULT = 'Credit_Card-Outlined';
const CARD_LOGO_VISA = 'Visa_Logo';
const CARD_LOGO_MASTERCARD = 'MasterCard_Logo';

const BLOCK_COMPANY_BENEFIT_USAGE_SCREENS = [
  'PURCHASE_PREPAID',
  'EDIT_PREPAID',
  'permitEdit',
  'prepaidEdit',
];

export const getCardLogo = (cardType?: string | null): string => {
  let cardLogo = CARD_LOGO_DEFAULT;
  if (cardType?.toLowerCase().includes('visa')) {
    cardLogo = CARD_LOGO_VISA;
  } else if (cardType?.toLowerCase().includes('mastercard')) {
    cardLogo = CARD_LOGO_MASTERCARD;
  }
  return cardLogo;
};

export const getCardMaskedLastNums = (paymentCard: Maybe<Card>) =>
  paymentCard?.lastNums ? ` **** ${paymentCard?.lastNums}` : '';

export const blockCompanyBenefitUsage = (
  parkingTab?: string,
  route?: Maybe<string>
) => {
  const isBlocked =
    BLOCK_COMPANY_BENEFIT_USAGE_SCREENS.includes(parkingTab ?? '') ||
    BLOCK_COMPANY_BENEFIT_USAGE_SCREENS.includes(route ?? '');
  return isBlocked;
};

export const cardErrorStatuses = [
  'Blocked',
  'Expired',
  'Terminated',
] as CardStatus[];

export const isCardErrorStatus = (cardStatus: CardStatus) => {
  return cardErrorStatuses.includes(cardStatus);
};

export const getExpiringSoonCards = (cards: Card[]) => {
  return cards.filter((card) =>
    isCardExpiresSoon(
      getDaysUntilExpiration(
        card.status as CardStatus,
        card.expMonth,
        card.expYear
      )
    )
  );
};

export const getAmountOfExpiresSoonCards = (cards: Card[]) => {
  return getExpiringSoonCards(cards).length;
};

export const isCardExpiresSoon = (daysUntilExpiration: number) => {
  return daysUntilExpiration >= 0;
};

export const getDaysUntilExpiration = (
  cardStatus: CardStatus,
  expMonth?: string | null,
  expYear?: string | null
) => {
  let daysUntilExpiration = -1;
  const isErrorStatus = isCardErrorStatus(cardStatus);
  const dateNow = new Date();

  if (
    !!expMonth &&
    !!expYear &&
    getYear(dateNow) === parseInt(expYear, 10) &&
    getMonth(dateNow) + 1 === parseInt(expMonth, 10) &&
    !isErrorStatus
  ) {
    daysUntilExpiration = differenceInDays(endOfMonth(dateNow), dateNow);
  }

  return daysUntilExpiration;
};
