import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { showToast } from 'components/common/CustomToast';
import { OngoingChargingSession } from 'components/ongoingParking/OngoingChargingSession';
import OngoingParkingZoneDetails from 'components/ongoingParking/OngoingParkingZoneDetails';
import Sheet from 'components/sheets/Sheet';
import useOngoingChargingSession from 'hooks/useOngoingChargingSession';
import useReadReceiptByTransactionId from 'hooks/useReadReceiptByTransactionId';
import React, { useEffect } from 'react';
import {
  expectingChargingReceipt,
  ongoingChargingSheetExpanded,
} from 'states/common';
import { RootNavProps } from 'types/navigation';
import { isIos, isWeb } from 'utils/deviceUtils';
import {
  mobileSubSheetMinHeight,
  toggleOngoingSessionSheet,
} from 'utils/ongoingSessionUtils';

const snappingPointsExtra = isWeb
  ? [360]
  : [mobileSubSheetMinHeight, mobileSubSheetMinHeight + 5];

export const OngoingChargingSheet: React.FC = () => {
  const ongoingChargingSheetExp = useReactiveVar(ongoingChargingSheetExpanded);
  const { ongoingChargingSession } = useOngoingChargingSession();

  const parkingZoneUid = ongoingChargingSession?.parkingZoneUid || '';
  const status = ongoingChargingSession?.status;
  const transactionId = ongoingChargingSession?.transactionId;
  const isChargingOngoing =
    status === 'IN_PROGRESS' ||
    status === 'STOP_IN_QUEUE' ||
    status === 'START_IN_QUEUE';
  const isReceiptComing = useReactiveVar(expectingChargingReceipt);
  const { navigate } = useNavigation<RootNavProps>();

  const {
    data: receiptData,
    startPolling: startReceiptPolling,
    stopPolling: stopReceiptPolling,
  } = useReadReceiptByTransactionId(transactionId || '');

  useEffect(() => {
    if (!ongoingChargingSession && ongoingChargingSheetExp) {
      toggleOngoingSessionSheet(ongoingChargingSheetExpanded, false);
    }
  }, [ongoingChargingSession, ongoingChargingSheetExp]);

  useEffect(() => {
    isReceiptComing && transactionId
      ? startReceiptPolling(5000)
      : stopReceiptPolling();
  }, [isReceiptComing, transactionId, startReceiptPolling, stopReceiptPolling]);

  useEffect(() => {
    const goToReceipt = () => {
      expectingChargingReceipt(false);
      if (transactionId) {
        navigate('mainTabs', {
          screen: 'accountScreen',
          params: {
            screen: 'chargingHistoryDetails',
            params: {
              transactionId,
              parkingZoneUid: parkingZoneUid,
            },
          },
        });
      }
    };

    if (receiptData && isReceiptComing) {
      showToast('evCharging.receipt.receiptAvailable', 'success', 10000, {
        link: {
          onPressCallback: goToReceipt,
          translationKey: 'button.receipt',
        },
      });
    }
  }, [transactionId, receiptData, isReceiptComing, navigate, parkingZoneUid]);

  if (!isChargingOngoing) {
    return <></>;
  }

  return (
    <>
      <OngoingChargingSession session={ongoingChargingSession || {}} />
      <Sheet
        snappingPoints={snappingPointsExtra}
        isDynamicSheet
        bottom
        left={0}
        bottomInset={isIos ? 0 : -1}
        sheetMinHeight={20}
        sheetMaxHeight={300}
        webSheetOpenDefault={false}>
        <OngoingParkingZoneDetails
          parkingZoneUid={parkingZoneUid}
          licensePlate={ongoingChargingSession?.licensePlate}
        />
      </Sheet>
    </>
  );
};
