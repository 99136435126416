import { useApolloClient } from '@apollo/client';
import AuthManager from 'components/auth/AuthManager';
import AccountContext from 'context/AccountContext';
import { useContext, useMemo, useState } from 'react';
import { sheetModal } from 'states/common';
import { CompanyBenefit } from 'types/generatedSchemaTypes';
import { getCompanyBenefitsUids } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { resetTabBarIndicators } from 'utils/indicatorUtils';
import { MMKVStorage } from 'utils/MMKVStorage';
import { getItemFromStorage, setItemToStorage } from 'utils/MMKVStorageUtils';

const useHandleLogout = (testMode: boolean) => {
  const [loading, setLoading] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const client = useApolloClient();
  const {
    customer: { companyBenefits },
  } = useContext(AccountContext);

  const companyBenefitsUids = useMemo(
    () => getCompanyBenefitsUids(companyBenefits as CompanyBenefit[]),
    [companyBenefits]
  );

  const updateCompanyBenefitNotificationSent = () => {
    const allCompanyBenefitNotificationSentUids =
      getItemFromStorage('allCompanyBenefitNotificationSentUids') || [];

    allCompanyBenefitNotificationSentUids?.forEach(
      (uid: string, index: number) => {
        if (!companyBenefitsUids?.includes(uid) && index > -1) {
          allCompanyBenefitNotificationSentUids.splice(index, 1);
          setItemToStorage(`companyBenefitNotificationSent_${uid}`, false);
        }
      }
    );

    setItemToStorage(
      'allCompanyBenefitNotificationSentUids',
      allCompanyBenefitNotificationSentUids
    );
  };

  const handleLogout = async () => {
    updateCompanyBenefitNotificationSent();
    setLoading(true);
    sheetModal('');
    await AuthManager.signOutAsync();
    resetTabBarIndicators();
    setShowLogout(true);
    if (!isWeb || testMode) {
      AuthManager.setDeviceToken('');
    }
    MMKVStorage.delete('apollo-cache-persist');
    await client.clearStore();
    setLoading(false);
  };
  return { handleLogout, loading, showLogout };
};

export default useHandleLogout;
