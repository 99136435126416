import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { Title, Button } from 'aimo-ui';
import { StyledImage, StyledWrapper } from 'styles/ContainerStyles';
import { FooterWrapper, MainWrapper } from 'styles/ModalStackContainerStyles';
import { OnboardingNavProps } from 'types/navigation';
import { testIdentifiers } from 'utils/testIdentifiers';
import VehicleAddSection from 'components/account/vehicle/VehicleAddSection';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';

const OnboardingAddVehicle: React.FC = () => {
  const { navigate } = useNavigation<OnboardingNavProps>();
  const { t } = useTranslation();
  const { skipOnboarding } = useAfterAuthenticationRedirect();

  const handleSkip = async () => {
    await skipOnboarding();
  };

  return (
    <>
      <MainWrapper vAlignCenter={true}>
        <StyledWrapper center mb={25}>
          <StyledImage
            testID="onboarding-add-vehicle-image"
            width={180}
            height={112}
            center
            source={require('assets/images/anpr.png')}
          />
        </StyledWrapper>
        <StyledWrapper center mb={30}>
          <Title center testID="onboarding-continue-title">
            {t('onboarding.addVehicleScreenTitle')}
          </Title>
        </StyledWrapper>
        <VehicleAddSection
          style="LIST"
          onPress={() => navigate('addVehicleOnboarding')}
        />
      </MainWrapper>
      <FooterWrapper>
        <Button
          {...testIdentifiers('onboarding-skip-button')}
          text={t('onboarding.skipForNow')}
          size="l"
          buttonType="link"
          onPress={handleSkip}
        />
      </FooterWrapper>
    </>
  );
};

export default OnboardingAddVehicle;
