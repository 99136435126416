import { gql } from '@apollo/client';

export const READ_CHARGING_STATION = gql`
  query readChargingStationV2($input: ReadChargingStationInputV2!) {
    readChargingStationV2(input: $input) {
      id
      chargingLocationId
      evses {
        id
        status
        publicDisplayId
        connectors {
          status
          type
          power
        }
      }
      address {
        country
      }
      status
      chargingPricePerKwh
    }
  }
`;
