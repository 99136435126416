import styled from 'styled-components/native';
import { Pressable, StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import { theme } from 'aimo-ui';

export const AnimatedTouchable = Animated.createAnimatedComponent(Pressable);

export const OngoingParkingHeaderStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 4,
    paddingHorizontal: 20,
    height: 73,
  },
  ongoingParkingSlide: {
    backgroundColor: theme.colors.gray300,
    height: 6,
    flexDirection: 'row',
    position: 'relative',
  },
});

export const OngoingParkingHeaderWrapper = styled.View<{
  mr?: number;
  grow?: boolean;
}>`
  margin-right: ${({ mr }) => mr ?? 0}px;
  flex-grow: ${({ grow }) => (grow ? 1 : 0)};
  flex-shrink: 1;
`;

export const StatusContainer = styled.View`
  height: 40%;
  justify-content: center;
  align-items: center;
  position: relative;
`;
