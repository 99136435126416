import { gql } from '@apollo/client';

export const GET_EV_CHARGING_TARIFFS = gql`
  query getEVChargingTariffs($chargingStations: [ChargingStationInput]!) {
    getEVChargingTariffsV2(chargingStations: $chargingStations) {
      supportedTypes
      availableSpots
      tariffPlan {
        id
        name
        chargingPricePerKwh
      }
      locationIds
    }
  }
`;
