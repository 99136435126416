import React from 'react';
import { Polygon as GooglePolygon } from '@react-google-maps/api';
import { LatLng } from 'react-native-maps';

type WebPolygonProps = {
  paths: LatLng[];
  onPress: () => void;
  strokeColor: string;
  strokeOpacity: number;
  fillColor: string;
  testid?: string;
};

const Polygon = (props: WebPolygonProps) => {
  const { onPress, paths, strokeOpacity, strokeColor, fillColor } = props;
  const googlePaths = paths?.map(({ latitude, longitude }: LatLng) => ({
    lat: latitude,
    lng: longitude,
  }));
  const options = { strokeOpacity, strokeColor, fillColor };

  return (
    <GooglePolygon
      options={options}
      {...props}
      paths={googlePaths}
      onClick={onPress}
    />
  );
};

export default Polygon;
