import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_CUSTOMER } from '@queries/getCustomer';
import { emptyCustomer } from 'components/auth/authConstants';
import { showToast } from 'components/common/CustomToast';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useCache from 'hooks/useCache';
import { getLanguage, setLanguage } from 'i18n/init';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isSignUpFlow } from 'states/common';
import {
  accessToken,
  country,
  featureCountryCode,
} from 'states/persistInStorage';
import { CountryCode, Query } from 'types/generatedSchemaTypes';
import {
  DecodedAccessTokenType,
  getDecodedToken,
  getNewUserFromToken,
} from 'utils/accessTokenUtils';
import { parseFeatureFlagObject, TypeDictionary } from 'utils/featureFlagUtils';
import { handleCustomerCardIndicator } from 'utils/indicatorUtils';
import Logger from 'utils/Logger';
import { updateStateAndStorage } from 'utils/MMKVStorageUtils';
import AccountContext from './AccountContext';

const handleCustomer = async ({
  data,
  appCountry,
}: {
  data?: Query;
  appCountry: CountryCode | null;
}) => {
  if (data?.readCustomerV3) {
    updateStateAndStorage(country, 'country', data.readCustomerV3.countryCode);
    if (!appCountry) {
      updateStateAndStorage(
        featureCountryCode,
        'featureCountryCode',
        data.readCustomerV3.countryCode
      );
    }
    // Adding this back, it was removed in CPS-16354 and resulted in an issue
    // that we only got UID on the RUM sessions, if user continued to use the app
    // If the user killed the app -> opened again, there was no customer info in the rum sessions
    Logger.setDatadogUser(data.readCustomerV3.uid);

    // Set customer preferred language
    if (
      data.readCustomerV3.preferredLanguage &&
      data.readCustomerV3.preferredLanguage !== getLanguage()
    ) {
      setLanguage(data.readCustomerV3.preferredLanguage);
    }

    // Get indicator related to cards
    handleCustomerCardIndicator(data.readCustomerV3.paymentCards);
  }
};

const AccountDetailsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [decodedToken, setDecodedToken] = useState<DecodedAccessTokenType>();
  const token = useReactiveVar(accessToken);
  const appCountry = useReactiveVar(featureCountryCode);
  const {
    flags: { sales_order_permits },
  } = useContext(FeatureFlagsContext);
  const { apolloCache } = useCache();
  const {
    data,
    loading,
    error,
    refetch: refetchCustomer,
  } = useQuery<Query>(GET_CUSTOMER, {
    fetchPolicy: 'cache-and-network',
    skip: token?.length === 0,
    errorPolicy: 'all',
    onCompleted: () => {
      apolloCache?.gc();
    },
  });
  const isSignUp = getNewUserFromToken(accessToken());
  isSignUpFlow(isSignUp);

  useEffect(() => {
    if (!loading) {
      handleCustomer({
        data: data,
        appCountry: appCountry,
      });
    }
  }, [appCountry, data, loading]);

  useEffect(() => {
    if (token) {
      setDecodedToken(getDecodedToken(accessToken()) as DecodedAccessTokenType);
    } else {
      setDecodedToken(undefined);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      setDecodedToken(getDecodedToken(accessToken()) as DecodedAccessTokenType);
    } else {
      setDecodedToken(undefined);
    }
  }, [token]);

  useEffect(() => {
    if (token && error?.message) {
      if (data?.readCustomerV3) {
        showToast('error.readCustomerInfo', 'info');
      } else {
        showToast('error.readCustomerError', 'error');
      }
    }
  }, [error, error?.message, token, data?.readCustomerV3]);

  const filteredPermits = useMemo(() => {
    const countryObject = parseFeatureFlagObject<
      TypeDictionary<CountryCode, boolean>
    >(sales_order_permits, 'sales_order_permits');
    if (appCountry && countryObject[appCountry]) {
      return data?.readCustomerV3?.permits;
    }
    return data?.readCustomerV3?.permits?.filter(
      (permit) => permit?.permitType === 'ACCESS'
    );
  }, [data?.readCustomerV3?.permits, appCountry, sales_order_permits]);

  const accountContext = useMemo(() => {
    return {
      customer: {
        ...(data?.readCustomerV3 || emptyCustomer),
        companyBenefits: data?.readCustomerV3?.benefits?.filter(
          (benefit) => benefit?.benefitTargetType === 'USE_CORPORATE_ACCOUNT'
        ),
        householdBenefits: data?.readCustomerV3?.benefits?.filter(
          (benefit) => benefit?.benefitTargetType === 'USE_PMC'
        ),
        poolingBenefits: data?.readCustomerV3?.benefits?.filter(
          (benefit) => benefit.benefitTargetType === 'USE_POOL'
        ),
        permits: filteredPermits,
      },
      decodedToken,
      error,
      loading,
      refetchCustomer,
    };
  }, [
    data?.readCustomerV3,
    error,
    filteredPermits,
    loading,
    refetchCustomer,
    decodedToken,
  ]);

  return (
    <AccountContext.Provider value={accountContext}>
      <>{children}</>
    </AccountContext.Provider>
  );
};
export default AccountDetailsProvider;
