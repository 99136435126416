import { Meta, theme } from 'aimo-ui';
import React from 'react';
import { TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import styled from 'styled-components/native';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { testIdentifiers } from 'utils/testIdentifiers';
import Icon from '../../components/icons/Icon';

const OpacityContainer = styled.View`
  flex-direction: row;
  align-items: center;
  padding: ${theme.normalising.heightPixel(10)}px 0;
  min-height: ${theme.normalising.heightPixel(55)}px;
`;

const Wrapper = styled.View<{ disabled: boolean }>`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: auto;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
`;
const LinkWrapper = styled.View`
  margin-left: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ExtraLinkWrapper = styled.View`
  margin-top: 4px;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1 1;
  align-items: center;
`;

const CenterWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;
type DropDownButtonProps = {
  onPress: () => void;
  disabled: boolean;
  testID?: string;
  text?: string;
  leftItem: React.ReactNode;
  textItem?: React.ReactNode;
  withLinkText?: string;
  showInfoIcon?: boolean;
  infoIconColor?: string;
  hideDownArrow?: boolean;
};

const DropDownButton = ({
  onPress,
  disabled,
  testID,
  text,
  leftItem,
  textItem,
  withLinkText = '',
  showInfoIcon,
  infoIconColor,
  hideDownArrow = false,
}: DropDownButtonProps) => {
  const Container = !disabled ? TouchableOpacity : TouchableWithoutFeedback;

  return (
    <View {...testIdentifiers(testID as string)}>
      <Container
        onPress={() => disabled || onPress()}
        testID={testID || 'dropdown-pressable'}>
        <OpacityContainer>
          <Wrapper disabled={disabled} testID="dropdown-left-item">
            {leftItem}
            <LinkWrapper>
              {text ? (
                <Meta numberOfLines={1} testID="dropdown-text">
                  {text}
                </Meta>
              ) : (
                <CenterWrapper>{textItem}</CenterWrapper>
              )}
              {showInfoIcon && (
                <ColumnWithMargins ml={5}>
                  <Icon
                    color={infoIconColor ?? theme.colors.aimoUiRed}
                    name="Info-Outlined"
                  />
                </ColumnWithMargins>
              )}
              {!withLinkText && !hideDownArrow && (
                <Icon name="Chevron_Down" testID="dropdown-chevron" size="s" />
              )}
            </LinkWrapper>
          </Wrapper>
          <Wrapper disabled={disabled}>
            {withLinkText.length > 0 && (
              <ExtraLinkWrapper>
                <Meta color={theme.colors.boost.green}>{withLinkText}</Meta>
                <Icon
                  name="Chevron_Right"
                  testID="dropdown-chevron"
                  size="s"
                  color={theme.colors.boost.green}
                />
              </ExtraLinkWrapper>
            )}
          </Wrapper>
        </OpacityContainer>
      </Container>
    </View>
  );
};

export default DropDownButton;
