import styled from 'styled-components/native';
import { theme } from 'aimo-ui';

export const ListItemWrapper = styled.View<{ noBorder?: boolean }>`
  width: 100%;
  height: 60px;
  border-top-width: ${(p) => (p.noBorder ? '0px' : '3px')};
  border-color: #add6bb;
  justify-content: center;
`;

export const Row = styled.View<{ justify?: string }>`
  flex-direction: row;
  align-items: center;
  justify-content: ${(p) => (p.justify ? p.justify : 'center')};
`;

export const BlackDot = styled.View`
  background-color: ${theme.colors.black};
  height: 5px;
  width: 5px;
  border-radius: 6px;
  margin: 0px 10px 0px 10px;
`;
