import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { showToast } from 'components/common/CustomToast';
import ParkingContext from 'context/ParkingContext';
import { isAfter } from 'date-fns';
import { READ_RESUMABLE_PARKING_SESSION } from 'graphql/queries/readResumableParkingSession';
import { useContext, useEffect, useMemo } from 'react';
import {
  activeElement,
  resumableSession,
  selectedTariffId,
} from 'states/common';
import {
  Query,
  QueryReadResumableParkingSessionArgs,
  ResumableParkingInfo,
} from 'types/generatedSchemaTypes';
import { getCustomerSegmentUid } from 'utils/parkUtils';
import useSelectedParkingVehicle from './useSelectedParkingVehicle';

const useResumableParkingSession = () => {
  const selectedVehicle = useSelectedParkingVehicle();
  const { licensePlate } = selectedVehicle || {};
  const currentParkingZone = useReactiveVar(activeElement);
  const { parkingZoneResult } = useContext(ParkingContext) || {};
  const hasResumableSession = useReactiveVar(resumableSession);
  const selectedCustomerSegment = useReactiveVar(selectedTariffId);
  const currentSegment = getCustomerSegmentUid(selectedCustomerSegment);

  const parkingAbuseFixEnabled =
    parkingZoneResult?.data?.parkZone?.parkingMethods?.parkingAbuseFix;

  const [readResumableParkingSession, { data }] = useLazyQuery<
    Query,
    QueryReadResumableParkingSessionArgs
  >(READ_RESUMABLE_PARKING_SESSION, {
    fetchPolicy: 'no-cache',
  });

  // Hook for interval to reset the resumableSession IF user has resumableSession AND has set valid until in onComplete
  useEffect(() => {
    let interval: any = null;
    if (hasResumableSession) {
      const expireTime = new Date(
        hasResumableSession?.resumeAllowedUntil as Date
      );
      interval = setInterval(() => {
        const dateNow = new Date();
        if (isAfter(dateNow, expireTime)) {
          resumableSession(undefined);
          showToast('parking.resumeParkingSession.sessionExpired', 'info');
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
      return () => clearInterval(interval);
    }
  }, [hasResumableSession]);

  useEffect(() => {
    if (
      currentParkingZone &&
      licensePlate?.plateText &&
      parkingAbuseFixEnabled
    ) {
      readResumableParkingSession({
        variables: {
          input: {
            parkingZoneUid: currentParkingZone,
            licensePlate: licensePlate.plateText,
            customerSegmentUid: currentSegment,
          },
        },
      });
    }
  }, [
    readResumableParkingSession,
    currentParkingZone,
    licensePlate?.plateText,
    parkingAbuseFixEnabled,
    currentSegment,
  ]);

  const memoizedSession = useMemo(() => {
    return data?.readResumableParkingSession?.resumableSession;
  }, [data?.readResumableParkingSession?.resumableSession]);

  useEffect(() => {
    resumableSession(memoizedSession as ResumableParkingInfo);
  }, [memoizedSession]);
};

export default useResumableParkingSession;
