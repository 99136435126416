import { StackActions, useNavigation } from '@react-navigation/native';
import { useReactiveVar } from '@apollo/client';
import { activeElement, vehicleFromParkzone } from 'states/common';
import { RootNavProps } from 'types/navigation';

const useAfterVehicleRedirect = () => {
  const { dispatch } = useNavigation<RootNavProps>();
  const parkZoneId = useReactiveVar(activeElement);
  const fromParkZone = useReactiveVar(vehicleFromParkzone);

  const navigateToParkZoneDetailsScreen = () => {
    dispatch(
      StackActions.replace('mainTabs', {
        screen: 'parkScreen',
        params: { parkingZoneUid: parkZoneId },
      })
    );
  };

  const navigateToMyVehiclesScreen = () => {
    dispatch(
      StackActions.replace('mainTabs', {
        screen: 'accountScreen',
        params: { screen: 'myVehicles' },
      })
    );
  };

  const navigateAfterVehicleSaved = () => {
    if (fromParkZone && parkZoneId) {
      vehicleFromParkzone(false);
      navigateToParkZoneDetailsScreen();
    } else {
      navigateToMyVehiclesScreen();
    }
  };

  return { navigateAfterVehicleSaved };
};

export default useAfterVehicleRedirect;
