import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { BaseText, Button, Title } from 'aimo-ui';
import CloseButton from 'components/common/CloseButton';
import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledImage, StyledWrapper } from 'styles/ContainerStyles';
import { FooterWrapper } from 'styles/ModalStackContainerStyles';
import { RootStackParamList } from 'types/navigation';
import styled from 'styled-components/native';
import { longTermParkingCompleted } from 'states/common';
import { testIdentifiers } from '../../utils/testIdentifiers';

const ContainerRight = styled.View`
  align-items: flex-end;
`;
const CompletedScreen = () => {
  const { t } = useTranslation();
  const { replace } = useNavigation<StackNavigationProp<RootStackParamList>>();
  const handleCompleted = () => {
    replace('mainTabs', {
      screen: 'servicesScreen',
      params: {
        screen: 'permits',
        params: {},
      },
    });
  };

  useLayoutEffect(() => {
    longTermParkingCompleted(true);
    return () => {
      longTermParkingCompleted(false);
    };
  }, []);

  return (
    <>
      <StyledWrapper center maxWidth={500} mb={10}>
        <ContainerRight>
          <CloseButton
            testID="long-term-parking-complete-close-button"
            onPress={handleCompleted}
          />
        </ContainerRight>
        <StyledImage
          testID="longTermParking-complete-image"
          width={206}
          height={206}
          center
          source={require('assets/images/onboarding-complete.png')}
        />
      </StyledWrapper>
      <StyledWrapper maxWidth={350} center mb={30}>
        <Title center {...testIdentifiers('long-term-parking-complete-title')}>
          {t('longTermParking.complete.thankYou')}
        </Title>
      </StyledWrapper>
      <StyledWrapper maxWidth={350} center mb={10}>
        <BaseText
          center
          {...testIdentifiers('long-term-parking-complete-description')}>
          {t('longTermParking.complete.completedText')}
        </BaseText>
      </StyledWrapper>
      <FooterWrapper>
        <StyledWrapper center maxWidth={300} mb={0}>
          <Button
            {...testIdentifiers('long-term-parking-complete-button')}
            text={t('button.done')}
            size="l"
            buttonType="secondary"
            onPress={handleCompleted}
          />
        </StyledWrapper>
      </FooterWrapper>
    </>
  );
};

export default CompletedScreen;
