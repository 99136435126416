import { QueryResult, useQuery } from '@apollo/client';
import { isAfter } from 'date-fns';
import { READ_HSL_AUTHENTICATION_STATUS } from 'graphql/queries/readHslAuthenticationStatus';
import { READ_HSL_TICKET_POLICY } from 'graphql/queries/readHslTicketPolicy';
import { READ_HSL_TICKET_PRICING } from 'graphql/queries/readHslTicketPricing';
import { useState } from 'react';
import {
  AccountType,
  HslTicketPolicy,
  HslZone,
  Maybe,
  Query,
  QueryReadHslTicketPricingArgs,
  SubscriptionType,
  TicketType,
} from 'types/generatedSchemaTypes';
import useIsLoggedIn from './useIsLoggedIn';

export type HslWizardScreen =
  | ''
  | 'SELECT_TICKET'
  | 'SELECT_ZONE'
  | 'SELECT_VALID_FROM'
  | 'SELECT_DURATION'
  | 'SELECT_SUBSCRIPTION'
  | 'BUY_TICKET'
  | 'AUTHENTICATE'
  | 'SINGLE_TICKET'
  | 'PRIVACY'
  | 'PHONE'
  | 'INVALID_TIME';

const FIRST_SCREEN: HslWizardScreen = '';

export type HslTicketType = {
  type: string;
  icon: string;
  translationKey: string;
};

export type HslWizard = {
  currentScreen: HslWizardScreen;
  setCurrentScreen: (screen: HslWizardScreen) => void;
  ticketTypes: HslTicketType[];
  availableZones: string[];
  selections: HslTicketSelections;
  hslPricingResult?: QueryResult<Query, QueryReadHslTicketPricingArgs>;
  hslToken?: string;
  hasHslAuthentication: () => boolean;
  setHslToken: (hslToken: string) => void;
  setSelections: (selections: HslTicketSelections) => void;
  selectedTicketType?: HslTicketType;
  setSelectedTicketType: (ticketType: HslTicketType | undefined) => void;
  selectedSubscriptionType: SubscriptionType;
  setSelectedSubscriptionType: (subscriptionType: SubscriptionType) => void;
  hslTicketPolicy?: Maybe<HslTicketPolicy>;
};

export type CustomerType = {
  value: string;
  key: string;
};

export type HslTicketSelections = {
  selectedZone?: string;
  validFrom?: string | Date;
  validityPeriod?: number;
  customerType?: CustomerType;
};

const ticketTypes: HslTicketType[] = [
  {
    type: 'SINGLE',
    icon: 'HSL-Outlined',
    translationKey: 'single',
  },
  {
    type: 'DAY',
    icon: 'HSL-Outlined',
    translationKey: 'day',
  },
  {
    type: 'SEASON',
    icon: 'Calendar-Outlined',
    translationKey: 'season',
  },
];
const availableZones: string[] = ['AB', 'ABC', 'ABCD', 'BC', 'BCD'];

export const defaultSelections = {
  selectedZone: availableZones[0],
  validFrom: 'Now',
  validityPeriod: 0,
  customerType: {
    value: 'Adult',
    key: 'ADULT',
  },
};

const useHslWizard = (): HslWizard => {
  const [currentScreen, setCurrentScreen] =
    useState<HslWizardScreen>(FIRST_SCREEN);
  const [selectedTicketType, setSelectedTicketType] = useState<HslTicketType>();
  const [selections, setSelections] =
    useState<HslTicketSelections>(defaultSelections);
  const { isLoggedIn } = useIsLoggedIn();
  const [hslToken, setHslToken] = useState<string>();
  const [selectedSubscriptionType, setSelectedSubscriptionType] =
    useState<SubscriptionType>('ONEOFF');

  const allValuesSelected =
    selections.selectedZone !== '' && selectedTicketType?.type;

  const hslPricingResult = useQuery<Query, QueryReadHslTicketPricingArgs>(
    READ_HSL_TICKET_PRICING,
    {
      variables: {
        input: {
          zones: selections.selectedZone as HslZone,
          accountType: selections.customerType?.key as AccountType,
          ticketType: selectedTicketType?.type as TicketType,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !allValuesSelected,
      errorPolicy: 'all',
    }
  );

  const hslAuthenticationResult = useQuery<Query>(
    READ_HSL_AUTHENTICATION_STATUS,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      skip: !isLoggedIn,
    }
  );

  const hasHslAuthentication = () => {
    const validTo =
      hslAuthenticationResult.data?.readHslAuthenticationStatus?.hslDetails
        ?.validTo;
    return !!validTo && isAfter(new Date(validTo), new Date());
  };

  const hslTicketPolicyResult = useQuery<Query>(READ_HSL_TICKET_POLICY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const hslTicketPolicy = hslTicketPolicyResult.data?.readHslTicketPolicy;
  return {
    currentScreen,
    selectedTicketType,
    selections,
    ticketTypes,
    availableZones,
    hslPricingResult,
    hslToken,
    selectedSubscriptionType,
    hslTicketPolicy,
    hasHslAuthentication,
    setHslToken,
    setSelections,
    setSelectedTicketType,
    setCurrentScreen,
    setSelectedSubscriptionType,
  };
};

export default useHslWizard;
