import Sheet from 'components/sheets/Sheet';
import HslContext from 'context/HslContext';
import React, { useContext } from 'react';
import HslAuthenticateScreen from 'screens/hslTicket/HslAuthenticateScreen';
import HslDurationSelection from 'screens/hslTicket/HslDurationSelection';
import HslSubscriptionSelection from 'screens/hslTicket/HslSubscriptionSelection';
import HslUpdatePhonenumberScreen from 'screens/hslTicket/HslUpdatePhonenumberScreen';
import HslValidFromSelection from 'screens/hslTicket/HslValidFromSelection';
import HslZoneSelectModalContent from 'screens/hslTicket/HslZoneSelectModalContent';
import { isWeb } from 'utils/deviceUtils';
import BuyTicket from './BuyTicket';
import InvalidDeviceTime from './InvalidDeviceTime';
import SelectTicketType from './SelectTicketType';

const Empty: React.FC = () => <></>;

export const snappingPointsWebMobile = (
  web: (string | number)[],
  mobile: (string | number)[]
): SnappingPointSet => {
  return { web, mobile };
};

export type SnappingPointSet = {
  web: (string | number)[];
  mobile: (string | number)[];
};

const HslWizardSheet = () => {
  const { currentScreen } = useContext(HslContext);
  let Component = Empty;
  let snappingPoints = snappingPointsWebMobile([350], [350]);

  switch (currentScreen) {
    case 'SELECT_TICKET':
      snappingPoints = snappingPointsWebMobile([385], ['50%', '90%']);
      Component = SelectTicketType;
      break;
    case 'BUY_TICKET':
      snappingPoints = snappingPointsWebMobile([520], ['70%', '90%']);
      Component = BuyTicket;
      break;
    case 'SELECT_ZONE':
      snappingPoints = snappingPointsWebMobile([780], ['89%', '95%']);
      Component = HslZoneSelectModalContent;
      break;
    case 'SELECT_VALID_FROM':
      snappingPoints = snappingPointsWebMobile([350], ['43%', '90%']);
      Component = HslValidFromSelection;
      break;
    case 'SELECT_DURATION':
      snappingPoints = snappingPointsWebMobile([780], ['90%', '95%']);
      Component = HslDurationSelection;
      break;
    case 'SELECT_SUBSCRIPTION':
      snappingPoints = snappingPointsWebMobile([800], ['90%']);
      Component = HslSubscriptionSelection;
      break;
    case 'PHONE':
      snappingPoints = snappingPointsWebMobile([400], ['50%', '60%']);
      Component = HslUpdatePhonenumberScreen;
      break;
    case 'AUTHENTICATE':
      snappingPoints = snappingPointsWebMobile([800], ['90%']);
      Component = HslAuthenticateScreen;
      break;
    case 'INVALID_TIME':
      snappingPoints = snappingPointsWebMobile(['40%'], [300]);
      Component = InvalidDeviceTime;
      break;
    default:
  }
  return (
    <Sheet
      snappingPoints={snappingPoints[isWeb ? 'web' : 'mobile']}
      showBackDropIndex={0}>
      <Component />
    </Sheet>
  );
};

export default HslWizardSheet;
