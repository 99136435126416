import { useReactiveVar } from '@apollo/client';
import { HorizontalList, Meta, theme } from 'aimo-ui';
import ParkingContext from 'context/ParkingContext';
import { addMinutes, hoursToMinutes } from 'date-fns';
import useSuggestedPricingEstimates, {
  DurationSuggestion,
} from 'hooks/useSuggestedPricingEstimates';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { accountRestricted, sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { Divider } from 'styles/CommonLayoutStyles';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { CountryCode } from 'types/generatedSchemaTypes';
import { currencyFormatter } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { handlePrefixedStartorEndtime } from 'utils/timeUtils';
import { Row } from '../styles/styles';
import DurationQuickSelectItem from './DurationQuickSelectItem';
import ParkingSummaryHeadline from './ParkingSummaryHeadline';

export const SET_BUTTON = 'SET_BUTTON';
export type DurationLeafData = DurationSuggestion | typeof SET_BUTTON;

type DurationQuickSelectProps = {
  parkingZoneUid: string;
  countryCode: CountryCode;
  disabled: boolean;
  showDefaultChips: boolean;
  predefinedTicketsOnly: boolean;
  serviceFee?: number;
};

const MarginfreeView = styled.View`
  margin-right: -5px;
  margin-left: -5px;
`;

const TextWrapper = styled.View`
  margin-left: 5px;
  padding: 18px 0;
`;

const GridContainer = styled.View`
  display: grid;
  grid-template-columns: auto auto auto auto;
`;

const DurationQuickSelect: React.FC<DurationQuickSelectProps> = ({
  parkingZoneUid,
  countryCode,
  disabled,
  showDefaultChips,
  predefinedTicketsOnly,
  serviceFee,
}: DurationQuickSelectProps) => {
  const [startDate] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const { setCurrentScreen, setEndTime, parkingConditions } =
    useContext(ParkingContext);
  const suggestedDurations = useSuggestedPricingEstimates(
    parkingZoneUid,
    startDate,
    showDefaultChips,
    serviceFee
  );
  const { endTime } = parkingConditions;
  const [filteredItems, setFilteredItems] = useState<DurationLeafData[]>([]);
  const { t } = useTranslation();
  const isUserRestricted = useReactiveVar(accountRestricted);

  useEffect(() => {
    const items: DurationLeafData[] = [
      SET_BUTTON,
      ...(suggestedDurations || []),
    ];
    setFilteredItems(
      items.filter((item) => (disabled && item !== 'SET_BUTTON') || !disabled)
    );
  }, [suggestedDurations, disabled]);

  const handleDurationQuickSelect = (chosen: DurationLeafData) => {
    if (isUserRestricted) {
      return sheetModal('accountRestricted');
    } else if (chosen === 'SET_BUTTON') {
      setEndTime(new Date());
      setCurrentScreen('CHOOSE_END_TIME');
    } else {
      setSelectedIndex(chosen?.index || 0);
      setEndTime(handleEndTime(chosen));
    }
  };
  const handleEndTime = useCallback(
    (item: DurationSuggestion) => {
      if (item?.durationType === 'FIXED_TIME') {
        let endDate = handlePrefixedStartorEndtime(item.endTime as string);
        if (endDate < startDate || endDate === startDate) {
          endDate = new Date(endDate.setDate(endDate.getDate() + 1));
        }
        return endDate;
      }
      if (item.timeUnit === 'MINUTES') {
        return addMinutes(new Date(), item?.duration || 0);
      }
      const convertToMinutes = hoursToMinutes(item?.duration);
      return addMinutes(new Date(), convertToMinutes || 0);
    },
    [startDate]
  );

  useEffect(() => {
    setEndTime(undefined);
  }, [setEndTime]);

  const priceHasServiceFee = filteredItems.find(
    (item) =>
      item !== 'SET_BUTTON' &&
      item?.serviceFee !== 0 &&
      item?.serviceFee !== undefined
  );

  const serviceFeeInfo =
    priceHasServiceFee !== 'SET_BUTTON' && priceHasServiceFee?.serviceFee
      ? t('price.timeChipServiceFee', {
          serviceFee: currencyFormatter(
            priceHasServiceFee?.serviceFee,
            countryCode
          ),
        })
      : '';
  if (isWeb) {
    return (
      <MarginfreeView>
        {filteredItems.length > 0 && (
          <TextWrapper>
            <ParkingSummaryHeadline />
          </TextWrapper>
        )}
        <GridContainer>
          {filteredItems.map((leaf) =>
            DurationQuickSelectItem(
              { item: leaf, indexValue: 1, onPress: handleDurationQuickSelect },
              selectedIndex,
              countryCode,
              disabled,
              predefinedTicketsOnly,
              endTime
            )
          )}
        </GridContainer>
        <ColumnWithMargins mt={10}>
          <Meta color={theme.colors.gray200}>{serviceFeeInfo}</Meta>
        </ColumnWithMargins>
        <Row>
          <Divider marginVertical={0} />
        </Row>
      </MarginfreeView>
    );
  }

  return (
    <MarginfreeView>
      {filteredItems.length > 0 && (
        <TextWrapper>
          <ParkingSummaryHeadline />
        </TextWrapper>
      )}
      <HorizontalList
        listData={filteredItems}
        listElement={(item) =>
          DurationQuickSelectItem(
            item,
            selectedIndex,
            countryCode,
            disabled,
            predefinedTicketsOnly,
            endTime
          )
        }
        onPress={handleDurationQuickSelect}
        testID="quick-select-time"
      />
      <ColumnWithMargins mt={5}>
        <Meta color={theme.colors.gray200}>{serviceFeeInfo}</Meta>
      </ColumnWithMargins>
      {filteredItems.length > 0 && (
        <Row>
          <Divider marginVertical={0} />
        </Row>
      )}
    </MarginfreeView>
  );
};

export default DurationQuickSelect;
