import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_VEHICLE } from '@mutations/createVehicle';
import { GET_CUSTOMER } from '@queries/getCustomer';
import { useNavigation, useRoute } from '@react-navigation/native';
import VehicleForm from 'components/account/vehicle/VehicleForm';
import { showToast } from 'components/common/CustomToast';
import AccountContext from 'context/AccountContext';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import useAfterVehicleRedirect from 'hooks/useAfterVehicleRedirect';
import React, { useContext, useEffect } from 'react';
import {
  editVehicleCameraRecognitionEnabled,
  parkingVehicleLicensePlate,
} from 'states/common';
import {
  Mutation,
  MutationCreateVehicleArgs,
  Vehicle,
} from 'types/generatedSchemaTypes';
import { RootNavProps } from 'types/navigation';

const VehicleAdd: React.FC = () => {
  const { goBack } = useNavigation<RootNavProps>();
  const { customer } = useContext(AccountContext);
  const { name: routeName } = useRoute();
  const isOnboarding = routeName === 'addVehicleOnboarding';
  const selectCreatedVehicle =
    routeName === 'addVehicleParking' || routeName === 'addVehiclePermit';
  const { navigateAfterVehicleSaved } = useAfterVehicleRedirect();
  const { skipOnboarding } = useAfterAuthenticationRedirect();

  useEffect(() => {
    editVehicleCameraRecognitionEnabled(false);
  }, []);

  const proceedToNextStep = async () => {
    if (isOnboarding) {
      await skipOnboarding();
    } else {
      if (!goBack) {
        navigateAfterVehicleSaved();
      }
      goBack();
    }
  };

  const [createVehicle, { loading }] = useMutation<
    Mutation,
    MutationCreateVehicleArgs
  >(CREATE_VEHICLE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        errorPolicy: 'all',
      },
    ],
    onCompleted: async ({
      createVehicle: {
        licensePlate: { plateText },
      },
    }) => {
      showToast('success.vehicleAdded', 'success');
      await proceedToNextStep();
      if (selectCreatedVehicle && plateText) {
        parkingVehicleLicensePlate(plateText);
      }
    },
    onError: async (error: ApolloError) => {
      if (
        error.graphQLErrors.some(
          (e) => e.extensions?.code === 'VEHICLE_ALREADY_ADDED'
        )
      ) {
        showToast('error.vehicleAlreadyAdded', 'error');
        await proceedToNextStep();
        return;
      }
      showToast('error.addVehicle', 'error');
    },
  });

  const handleSubmit = async (newVehicle: Vehicle) => {
    await createVehicle({
      variables: {
        input: {
          licensePlate: newVehicle.licensePlate,
          name: newVehicle.name,
          cameraRecognition: {
            allowed: newVehicle.cameraRecognition?.allowed || false,
            enabled: newVehicle.cameraRecognition?.enabled || false,
            countryCodes: [customer.countryCode],
          },
          appSettings: {
            icon: newVehicle.appSettings?.icon,
            color: newVehicle.appSettings?.color,
            type: newVehicle.appSettings?.type,
            evPlugType: newVehicle.appSettings?.evPlugType,
          },
        },
      },
    });
  };
  return (
    <VehicleForm
      isNew={true}
      isOnboarding={isOnboarding}
      isParkingPermitFlow={selectCreatedVehicle}
      onSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default VehicleAdd;
