import { Headline2, Meta, theme } from 'aimo-ui';
import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components/native';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { getLocale } from 'utils/commonUtils';
import { Row } from './styles/styles';

interface ResumeTimeDisplayProps {
  spacerCount?: number;
  startTime?: Date;
  endTime?: Date;
}

const SpaceBetweenRow = styled(Row)`
  justify-content: space-evenly;
  align-items: flex-start;
`;

const SpacerDot = styled(Meta)`
  margin: 0px 5px;
  font-size: 4px;
  color: ${theme.colors.gray200};
`;

const RowFlexStart = styled(Row)``;

const ResumeTimeDisplay: React.FC<ResumeTimeDisplayProps> = ({
  spacerCount = 5,
  startTime,
  endTime,
}) => {
  const startTimeText = format(new Date(startTime || ''), 'kk:mm', {
    locale: getLocale(),
  });
  const startDateText = format(new Date(startTime || ''), 'EEE dd MMM', {
    locale: getLocale(),
  });
  const endTimeText = format(new Date(endTime || ''), 'kk:mm', {
    locale: getLocale(),
  });
  const endDateText = format(new Date(endTime || ''), 'EEE dd MMM', {
    locale: getLocale(),
  });

  const dotArray = Array.from({ length: spacerCount }, (value, index) => index);

  return (
    <SpaceBetweenRow testID={'resume-parking-times'}>
      <ColumnWithMargins testID={'resume-start'}>
        <Headline2 testID={'resume-start-time'} center>
          {startTimeText}
        </Headline2>
        <Meta testID={'resume-start-date'} center>
          {startDateText}
        </Meta>
      </ColumnWithMargins>

      <RowFlexStart mt={6} testID={'resume-spacer'}>
        {dotArray.map((v) => (
          <SpacerDot key={`spacer-dot-${v}`} numberOfLines={1}>
            {'\u2B24'}
          </SpacerDot>
        ))}
      </RowFlexStart>
      <ColumnWithMargins testID={'resume-end'}>
        <Headline2 testID={'resume-end-time'} center>
          {endTimeText}
        </Headline2>
        <Meta testID={'resume-end-date'} center>
          {endDateText}
        </Meta>
      </ColumnWithMargins>
    </SpaceBetweenRow>
  );
};

export default ResumeTimeDisplay;
