import AccountContext from 'context/AccountContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe } from 'types/generatedSchemaTypes';
import { toTitleCase } from 'utils/stringUtils';

type UsePaymentDetailsProps = {
  transactionId?: Maybe<string>;
  cardId?: Maybe<string>;
  totalAmount?: Maybe<number>;
  corporateAccountUid?: Maybe<string>;
};
//There are 2 types of transaction in charging: payment transaction and
//internal provider (Everon, Emabler,...) "transaction" which basically
//what they call a charging session. The transctionId used here refer to
//payment transaction
const usePaymentDetails = ({
  transactionId: paymentTransactionId,
  cardId,
  totalAmount,
  corporateAccountUid,
}: UsePaymentDetailsProps) => {
  const {
    customer: { paymentCards, companyBenefits },
  } = useContext(AccountContext);
  const { t } = useTranslation();
  const { type, lastNums } =
    paymentCards?.find((card) => cardId === card?.cardId) || {};

  if (!paymentTransactionId && totalAmount === null) {
    return '';
  }
  if (!paymentTransactionId && totalAmount === 0) {
    return t('paymentMethods.freeParking');
  }

  if (corporateAccountUid && !cardId) {
    const companybenefit = companyBenefits?.find(
      (benefit) => benefit?.benefitTargetUid === corporateAccountUid
    );
    return {
      title: t('account.companyPermit'),
      companyName: companybenefit?.name,
    };
  }

  return type && lastNums
    ? `${toTitleCase(type)} **** ${lastNums}`
    : t('paymentMethods.paymentCard');
};

export default usePaymentDetails;
