import { useMutation, useReactiveVar } from '@apollo/client';
import { REMOVE_CARD } from '@mutations/removeCard';
import { GET_CUSTOMER } from '@queries/getCustomer';
import { Button, Meta, theme } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import useCreditCardCount from 'hooks/useCreditCardCount';
import useHasAnprEnabled from 'hooks/useHasAnprEnabled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectedPaymentCard, sheetModal } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import { Mutation, MutationDeleteCardArgs } from 'types/generatedSchemaTypes';
import { testIdentifiers } from 'utils/testIdentifiers';

const ConfirmPaymentMethodRemoval: React.FC = () => {
  const { t } = useTranslation();
  const selectedCard = useReactiveVar(selectedPaymentCard);
  const isLastActiveCard =
    useCreditCardCount() === 1 && selectedCard.status === 'Active';
  const hasAnprEnabled = useHasAnprEnabled();

  const [removeCard, { loading }] = useMutation<
    Mutation,
    MutationDeleteCardArgs
  >(REMOVE_CARD, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        errorPolicy: 'all',
      },
    ],
    variables: {
      cardId: selectedCard?.cardId,
      setAnprFalse: isLastActiveCard && hasAnprEnabled,
    },
    onCompleted: () => {
      sheetModal('');
      showToast('success.paymentCardRemoving', 'success');
    },
    onError: (error) => {
      if (
        error.graphQLErrors.some(
          (e) => e.extensions?.code === 'CANNOT_DELETE_CARD_ONGOING_SESSION'
        )
      ) {
        showToast('error.paymentCardRemovingOngoingSession', 'error');
        return;
      }
      showToast('error.paymentCardRemoving', 'error');
    },
  });

  return (
    <StyledWrapper mb={0}>
      <StyledWrapper mb={20}>
        <StyledWrapper mb={70}>
          <StyledWrapper mb={15}>
            <Meta
              testID="payment-methods-confirm-text"
              center
              color={theme.colors.gray200}>
              {t('paymentMethods.confirmText')}
            </Meta>
          </StyledWrapper>
          {isLastActiveCard && (
            <Meta
              testID="payment-methods-resets-anpr"
              center
              color={theme.colors.gray200}>
              {t('paymentMethods.setAnprFalse')}
            </Meta>
          )}
        </StyledWrapper>
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <Button
          {...testIdentifiers('payment-methods-confirm')}
          size="l"
          text={t('button.remove')}
          loading={loading}
          onPress={() => (!loading ? removeCard() : undefined)}
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default ConfirmPaymentMethodRemoval;
