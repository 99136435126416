import { BaseText, ListItem, Meta, theme } from 'aimo-ui';
import { TitleWrapper } from 'components/account/paymentMethods/PaymentMethodItem';
import Icon from 'components/icons/Icon';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useShowPaymentCardStatus from 'hooks/useShowPaymentCardStatus';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { selectedPaymentCard, sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { CenterRow, StyledWrapper } from 'styles/ContainerStyles';
import { Tag, TagText } from 'styles/TextStyles';
import { Card, CardStatus, Maybe } from 'types/generatedSchemaTypes';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { getCardLogo, getCardMaskedLastNums } from 'utils/paymentUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

type CardItemProps = {
  item: Maybe<Card>;
  indexValue: number;
};

const TitleCheckWrapper = styled.View`
  margin-right: 4px;
  margin-top: 14px;
  margin-left: 8px;
`;

const IconWrapper = styled.View`
  margin-right: 4px;
  margin-top: 4px;
`;

const ArrowWrapper = styled.View`
  margin-right: -4px;
  margin-top: 4px;
`;

const ArrowContainer = ({
  item,
  testID,
}: {
  item: Maybe<Card>;
  testID: string;
}) => {
  const { isErrorStatus, expiresSoon } = useShowPaymentCardStatus(
    (item?.status || 'Active') as CardStatus,
    item?.expMonth || '',
    item?.expYear || ''
  );
  const isBusiness = item?.category === 'business';
  const isVerifying = item?.status === 'Verifying';
  const { t } = useTranslation();
  const {
    flags: { card_business },
  } = useContext(FeatureFlagsContext);
  const showBusinessCard = showFeatureFlag(card_business);

  return (
    <CenterRow>
      {(isErrorStatus || expiresSoon) && (
        <IconWrapper>
          <Icon
            name={`Error-Filled${expiresSoon ? '-Black' : ''}`}
            size="m"
            testID={`${testID}-error-status-icon`}
            color={
              expiresSoon ? theme.colors.boost.yellow : theme.colors.boost.red
            }
          />
        </IconWrapper>
      )}
      {isBusiness && showBusinessCard && (
        <Tag {...testIdentifiers(`${testID}-business-tag`)}>
          <TagText>{t('paymentMethods.business')}</TagText>
        </Tag>
      )}
      {isVerifying && (
        <Tag {...testIdentifiers(`${testID}-verifying-tag`)}>
          <TagText>{t('paymentMethods.verifying')}</TagText>
        </Tag>
      )}
      <ArrowWrapper>
        <Icon
          name="Chevron_Right"
          {...testIdentifiers(`${testID}-chevron-right`)}
          size="s"
        />
      </ArrowWrapper>
    </CenterRow>
  );
};

const IconComponent = ({ item, indexValue }: CardItemProps) => (
  <Icon
    {...testIdentifiers(`credit-card-icon-${indexValue}`)}
    name={`${getCardLogo(item?.type)}`}
  />
);

const TitleComponent = ({ item, indexValue }: CardItemProps) => (
  <TitleWrapper>
    <StyledWrapper mb={0} maxWidth={230}>
      <BaseText
        numberOfLines={1}
        {...testIdentifiers(`credit-card-title-${indexValue}`)}>
        {item?.description ? item.description : item?.type}
      </BaseText>
    </StyledWrapper>
    {item?.isDefault && (
      <TitleCheckWrapper>
        <Icon
          name="check"
          size="xs"
          {...testIdentifiers(`credit-card-is-default-${indexValue}`)}
          color={theme.colors.black}
        />
      </TitleCheckWrapper>
    )}
  </TitleWrapper>
);

const SubtitleComponent = ({ item, indexValue }: CardItemProps) => (
  <Meta
    {...testIdentifiers(`credit-card-number-${indexValue}`)}
    color={theme.colors.gray200}>
    {`**** ${item?.lastNums ? item.lastNums : ''}`}
  </Meta>
);

const RightContentComponent = ({ item, indexValue }: CardItemProps) => (
  <ArrowContainer item={item} testID={`credit-card-${indexValue}`} />
);

const CardItem = ({ item, indexValue }: CardItemProps) => {
  const selectCard = () => {
    selectedPaymentCard({
      cardId: item?.cardId || '',
      title: item?.description ? item.description : item?.type || '',
      subtitle: getCardMaskedLastNums(item),
      category: `${item?.category || ''}`,
      status: `${item?.status || ''}`,
      isDefault: item?.isDefault === true,
      description: item?.description || '',
      newCard: false,
      expMonth: item?.expMonth || '',
      expYear: item?.expYear || '',
    });
    sheetModal('paymentCard');
  };

  return (
    <ListItem
      item={item}
      indexValue={indexValue}
      onPress={selectCard}
      iconComponent={() => IconComponent({ item, indexValue })}
      titleComponent={() => TitleComponent({ item, indexValue })}
      subtitleComponent={() => SubtitleComponent({ item, indexValue })}
      rightContentComponent={() => RightContentComponent({ item, indexValue })}
    />
  );
};

export default CardItem;
