import { ApolloError, useMutation, useReactiveVar } from '@apollo/client';
import { showToast } from 'components/common/CustomToast';
import AccountContext from 'context/AccountContext';
import { UPDATE_PARKING_SESSION } from 'graphql/mutations/updateParkingSession';
import { READ_PARKING_SESSION } from 'graphql/queries/readParkingSession';
import { READ_PARKING_SESSIONS } from 'graphql/queries/readParkingSessions';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  ongoingParkingSession,
  selectedSessionIdFromMultiple,
  sheetModal,
} from 'states/common';
import {
  Mutation,
  MutationUpdateParkingSessionArgs,
  ParkingSession,
  UpdateParkingSessionActionType,
} from 'types/generatedSchemaTypes';
import { checkNotificationsPermissions } from 'utils/deviceUtils';

type UpdateParkingProps = {
  sessionId: string;
  isModal?: boolean;
  actionType: UpdateParkingSessionActionType;
  newEndDate?: Date;
  cardId?: string;
  licensePlate?: string;
  callback?: (
    data: string,
    action: UpdateParkingSessionActionType,
    updatedParking: ParkingSession
  ) => any;
  errorCallback?: (error: ApolloError) => any;
  skipRefetch?: boolean;
};
const useUpdateParkingMutation = ({
  sessionId,
  isModal = false,
  actionType,
  newEndDate,
  cardId,
  licensePlate,
  callback,
  errorCallback,
  skipRefetch = false,
}: UpdateParkingProps) => {
  const [updateComplete, setUpdateComplete] = useState<boolean>(false);
  const { refetchCustomer } = useContext(AccountContext);
  const parkingSession = useReactiveVar(ongoingParkingSession);
  const memoizeEndTime = useMemo(() => {
    return parkingSession?.endTime;
  }, [parkingSession?.endTime]);
  const prevValueRef = useRef(memoizeEndTime);
  const hasUpdateCompletedAndValueChanged =
    prevValueRef.current !== memoizeEndTime;
  const [updateParking, { loading: updateParkingLoading }] = useMutation<
    Mutation,
    MutationUpdateParkingSessionArgs
  >(UPDATE_PARKING_SESSION, {
    awaitRefetchQueries:
      actionType === 'EXTEND' || actionType === 'RETRY_PAYMENT',
    refetchQueries: skipRefetch
      ? []
      : [
          {
            query: READ_PARKING_SESSIONS,
            variables: {
              input: {
                parkingState: ['Started'],
                sessionType: ['ANPR', 'START_STOP'],
              },
            },
          },
          {
            query: READ_PARKING_SESSION,
            variables: { sessionId },
          },
        ],
    variables: {
      input: {
        sessionId,
        actionType,
        newEndDate,
        cardId,
        licensePlate,
      },
    },
    onCompleted: (data) => {
      // no need to show toast when stopping parking
      if (callback) {
        callback(
          sessionId,
          actionType,
          data.updateParkingSession as ParkingSession
        );
      }
      if (actionType === 'EXTEND') {
        setUpdateComplete(true);
      }
      if (isModal && actionType !== 'EXTEND') {
        sheetModal('');
        selectedSessionIdFromMultiple(null);
      }
    },
    onError: (error) => {
      if (actionType === 'STOP') {
        showToast('error.parkingStopped', 'error');
      } else if (actionType === 'EXTEND') {
        if (
          error.graphQLErrors.some(
            (e) => e.extensions?.code === 'PARKING_EXTENDED_FORBIDDEN'
          )
        ) {
          showToast('error.timePicker.invalidTimeInFuture', 'info');
        } else {
          showToast('error.parkingExtended', 'error');
        }
      } else if (actionType === 'RETRY_PAYMENT') {
        if (
          error.graphQLErrors.some((e) => e.extensions?.code === 'CARD_BLOCKED')
        ) {
          showToast('error.cardBlocked', 'info');
          refetchCustomer();
        } else {
          showToast('error.parkingRetry', 'error');
        }
      } else if (actionType === 'UPDATE') {
        if (errorCallback) {
          errorCallback(error);
        }
      }
    },
  });

  useEffect(() => {
    const checkUpdatedValue = async () => {
      const notificationPermission = checkNotificationsPermissions('granted');
      if (
        isModal &&
        actionType === 'EXTEND' &&
        hasUpdateCompletedAndValueChanged
      ) {
        if (!notificationPermission) {
          showToast('success.parkingExtended', 'success');
        }

        sheetModal('');
        setUpdateComplete(false);
        selectedSessionIdFromMultiple(null);
      }
    };
    checkUpdatedValue();
  }, [isModal, actionType, hasUpdateCompletedAndValueChanged]);
  return {
    updateParking,
    updateParkingLoading,
    callback,
    hasUpdateCompletedAndValueChanged,
    updateComplete,
  };
};

export default useUpdateParkingMutation;
