import { BaseText, Meta, Spinner, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import styled from 'styled-components/native';

const BadgeContainer = styled.View<{ bgColor?: string }>`
  background-color: ${(p) => p.bgColor || p.theme.colors.boost.purple};
  padding: 6px 6px 6px 12px;
  margin-top: 10px;
  border-radius: 60px;
  flex-direction: row;
  align-items: center;
`;

const LeftContent = styled(BaseText)`
  font-size: ${theme.textStyles.meta.fontSize};
  flex-shrink: 1;
  flex-grow: 1;
`;

const RightContent = styled.View`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 60px;
  margin-left: 7.5px;
  padding: 5.5px 7.5px;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
`;

const RightContentText = styled(Meta)<{ hasIcon: boolean }>`
  margin-left: ${(p) => (p.hasIcon ? 4 : 0)}px;
`;

type DoubleBadgeProps = {
  bgColor?: string;
  innerBadgeTextColor?: string;
  testID?: string;
  textLeft?: string;
  iconName?: string;
  textRight?: string;
  exludeRightContent?: boolean;
  loading?: boolean;
  loadingInnerBadge?: boolean;
  showRefreshButton?: boolean;
  reloadOnPress?: () => void;
};

const StyledRefreshIconWrapper = styled.View`
  margin: 0;
`;
const RefreshButton = ({ onPress }: { onPress: () => void }) => (
  <TouchableWithoutFeedback onPress={onPress}>
    <StyledRefreshIconWrapper>
      <Icon name="Refresh" size="s" color={theme.colors.transparent} />
    </StyledRefreshIconWrapper>
  </TouchableWithoutFeedback>
);

const RightIcon = ({
  loadingInnerBadge,
  reloadOnPress,
  showRefreshButton,
  iconName,
  innerBadgeTextColor,
}: {
  loadingInnerBadge: boolean;
  reloadOnPress?: () => void;
  showRefreshButton: boolean;
  iconName?: string;
  innerBadgeTextColor: string;
}) => {
  if (loadingInnerBadge) {
    return <Spinner size={20} />;
  }
  if (showRefreshButton && reloadOnPress) {
    return <RefreshButton onPress={reloadOnPress} />;
  }
  if (iconName) {
    return <Icon name={iconName} size="s" color={innerBadgeTextColor} />;
  }

  return <></>;
};

const DoubleBadge: React.FC<DoubleBadgeProps> = ({
  bgColor,
  innerBadgeTextColor = theme.colors.primary,
  testID,
  textLeft,
  iconName,
  textRight,
  exludeRightContent = false,
  loading,
  loadingInnerBadge = false,
  showRefreshButton = false,
  reloadOnPress,
}) => {
  return (
    <BadgeContainer testID={testID} bgColor={bgColor}>
      <LeftContent /* This is really a styled(Meta) */
        numberOfLines={1}
        testID={testID ? `${testID}-left-content` : 'left-content'}>
        {!loading ? textLeft : <Spinner small testID="double-badge-loader" />}
      </LeftContent>

      {!exludeRightContent && (
        <RightContent
          testID={testID ? `${testID}-right-content` : 'right-content'}>
          {!loading && (
            <RightIcon
              loadingInnerBadge={loadingInnerBadge}
              reloadOnPress={reloadOnPress}
              showRefreshButton={showRefreshButton}
              iconName={iconName}
              innerBadgeTextColor={innerBadgeTextColor}
            />
          )}
          <RightContentText
            testID={
              testID ? `${testID}-right-content-text` : 'right-content-text'
            }
            color={innerBadgeTextColor}
            hasIcon={!!iconName}>
            {textRight}
          </RightContentText>
        </RightContent>
      )}
    </BadgeContainer>
  );
};

export default DoubleBadge;
