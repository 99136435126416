import { Headline3, Meta, Spinner, theme } from 'aimo-ui';
import SelectableModalContentItem from 'components/common/SelectableModalContentItem';
import Icon from 'components/icons/Icon';
import useHasUnlockedCustomerSegment from 'hooks/useHasUnlockedCustomerSegment';
import { t } from 'i18next';
import React from 'react';
import styled from 'styled-components/native';
import { CenterRow, StyledWrapper } from 'styles/ContainerStyles';
import { ParkingZoneType } from 'types/common';
import {
  AccessMethod,
  AccessType,
  CountryCode,
} from 'types/generatedSchemaTypes';
import { testIdentifiers } from 'utils/testIdentifiers';

const Column = styled.View`
  flex-direction: column;
  flex: 1;
`;

const BoldMeta = styled(Meta)`
  font-family: ${theme.fontFamily.bold};
`;

const Row = styled(CenterRow)`
  flex-grow: 1;
`;

const TariffItem = ({
  tariffPlan,
  isTariffPlanLoading,
  type,
  isSelected,
  zoneCode,
  countryCode,
  onPress,
}: {
  tariffPlan: any;
  isTariffPlanLoading?: boolean;
  type: ParkingZoneType;
  isSelected: boolean;
  countryCode: CountryCode;
  zoneCode?: string;
  onPress: (
    tariffId: string,
    tariffName: string,
    parkingZoneType: ParkingZoneType,
    accessType?: AccessType,
    accessMethod?: AccessMethod,
    isUnlockValid?: boolean
  ) => void;
}) => {
  const { id, name, note, accessType, accessMethod } = tariffPlan;
  const { isUnlockValid } = useHasUnlockedCustomerSegment(
    id,
    countryCode || 'SE',
    zoneCode
  );

  const isLockType = accessType === 'LOCKED';
  const isLocked = isLockType && !isUnlockValid;

  const LockIcon = (
    <Icon
      {...testIdentifiers(
        isLocked ? 'tariff-locked-icon' : 'tariff-unlocked-icon'
      )}
      color={isLocked ? theme.colors.black : theme.colors.boost.green}
      name={isLocked ? 'Locked-Filled' : 'Unlocked-Filled'}
    />
  );

  const CheckboxIcon = (
    <Icon testID="Checkbox" name={isSelected ? 'Checkbox' : 'CheckboxEmpty'} />
  );

  return (
    <SelectableModalContentItem
      isSelected={isSelected}
      disabled={false}
      onPress={() =>
        onPress(id, name, type, accessType, accessMethod, isUnlockValid)
      }
      testID={`tariff-wrap-${id}`}
      alignCenter={false}
      notSelectedBorder
      isLocked={isLocked}
      iconNode={isLockType ? LockIcon : CheckboxIcon}
      paddingRight={20}>
      {isTariffPlanLoading ? (
        <StyledWrapper center>
          <Spinner small />
        </StyledWrapper>
      ) : (
        <>
          <Row isVerticalCenter>
            <Column>
              <StyledWrapper mb={theme.normalising.heightPixel(10)}>
                <Headline3 testID={`tariff-name-${name}`}>
                  {name || '-'}
                </Headline3>
              </StyledWrapper>
              {note && <Meta testID={`tariff-note-${note}`}>{note}</Meta>}
            </Column>
            {isLocked && (
              <BoldMeta color={theme.colors.boost.green}>
                {t('parking.tariff.redeem')}
              </BoldMeta>
            )}
          </Row>
        </>
      )}
    </SelectableModalContentItem>
  );
};

export default TariffItem;
