import { BaseText, ListItem, Meta, theme } from 'aimo-ui';
import { ListItemContainerProps } from 'aimo-ui/lib/typescript/src/components/ListItem/types';
import VehicleAvatar from 'components/common/VehicleAvatar';
import Icon from 'components/icons/Icon';
import React from 'react';
import styled from 'styled-components/native';
import { Vehicle } from 'types/generatedSchemaTypes';
import { testIdentifiers } from 'utils/testIdentifiers';
const TitleWrapper = styled.View`
  flex-direction: row;
`;

const ArrowWrapper = styled.View`
  margin-right: -4px;
  margin-top: 4px;
`;

const VehicleFilterItem = ({
  item: vehicle,
  indexValue,
  testID,
  selected,
  onPress,
}: { selected: boolean } & ListItemContainerProps<Vehicle>) => {
  return (
    <ListItem
      item={vehicle}
      testID={testID}
      indexValue={indexValue}
      onPress={(item) => onPress?.({ item, selected: !selected })}
      iconComponent={() => (
        <VehicleAvatar
          color={vehicle?.appSettings?.color}
          icon={vehicle?.appSettings?.icon}
          size="l"
        />
      )}
      titleComponent={() => (
        <TitleWrapper>
          <BaseText {...testIdentifiers(`vehicle-title-${indexValue}`)}>
            {vehicle.name || vehicle.licensePlate?.plateText}
          </BaseText>
        </TitleWrapper>
      )}
      subtitleComponent={() => (
        <Meta
          color={theme.colors.gray200}
          testID={`vehicle-license-${vehicle.licensePlate?.plateText}`}>
          {vehicle.licensePlate?.plateText}
        </Meta>
      )}
      rightContentComponent={() => (
        <>
          {selected && (
            <ArrowWrapper>
              <Icon
                name="check"
                testID={`${testID}-${indexValue}-check`}
                size="s"
              />
            </ArrowWrapper>
          )}
        </>
      )}
    />
  );
};

export default VehicleFilterItem;
