import AccountContext from 'context/AccountContext';
import useUpdateCustomerMutation from 'hooks/useUpdateCustomerMutation';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuToggleItem from './MenuToggleItem';
import { parkingVehicleLicensePlate } from 'states/common';

const AutomaticVehicleSelectToggleItem: React.FC<{
  borderColor?: string;
  borderW?: number;
}> = ({ borderColor, borderW }) => {
  const {
    customer: { applicationPreferences },
  } = useContext(AccountContext);

  const [selectVehicleAutomatically, setSelectVehicleAutomatically] = useState(
    applicationPreferences?.alwaysSelectVehicle ?? false
  );
  const { t } = useTranslation();

  const errorCallback = () => {
    setSelectVehicleAutomatically(!selectVehicleAutomatically);
  };
  const { updateCustomer, loading } = useUpdateCustomerMutation({
    errorCallback,
  });

  const onChange = (value: boolean) => {
    if (loading) {
      return;
    }

    updateCustomer({
      variables: {
        input: {
          applicationPreferences: {
            alwaysSelectVehicle:
              applicationPreferences?.alwaysSelectVehicle === undefined
                ? true
                : value,
          },
        },
      },
    });
    setSelectVehicleAutomatically(value);
    if (!value){
      parkingVehicleLicensePlate(undefined);
    }
  };

  return (
    <MenuToggleItem
      value={selectVehicleAutomatically}
      onValueChange={onChange}
      menuLabel={'account.automaticVehicleSelect.title'}
      testID={'automatic-vehicle-select'}
      subtitle={
        selectVehicleAutomatically
          ? t('account.automaticVehicleSelect.descriptionOn')
          : t('account.automaticVehicleSelect.descriptionOff')
      }
      iconName={'Car-Outlined'}
      borderColor={borderColor}
      borderWidth={borderW}
      loading={loading}
    />
  );
};

export default AutomaticVehicleSelectToggleItem;
