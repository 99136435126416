import { useNavigation } from '@react-navigation/native';
import { BaseText, ListItem, Meta, theme, Title } from 'aimo-ui';
import IconBadge from 'components/common/IconBadge';
import Icon from 'components/icons/Icon';
import { Row } from 'components/park/styles/styles';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { format } from 'date-fns';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { Pill } from 'styles/CommonLayoutStyles';
import { StyledImage, StyledWrapper } from 'styles/ContainerStyles';
import {
  BillingItem,
  Card,
  ChargingSession,
  Maybe,
  ParkingPaymentState,
  ParkingSession,
  Receipt,
} from 'types/generatedSchemaTypes';
import { AccountNavProps } from 'types/navigation';
import {
  currencyFormatter,
  getCorrectBillingItem,
  getCountryFromCurrency,
  getLocale,
} from 'utils/commonUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { locationIcon } from 'utils/parkUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import { getDurationFromDates } from 'utils/timeUtils';

const StyledView = styled.View`
  margin-right: 4px;
`;

type ItemHistoryDisplayProps = {
  groupIndex: number;
  index: number;
};

type ItemReceiptDisplayProps = {
  item: Receipt;
} & ItemHistoryDisplayProps;

type ItemParkingDisplayProps = {
  item: ParkingSession;
} & ItemHistoryDisplayProps;

type ItemChargingDisplayProps = {
  item: ChargingSession;
} & ItemHistoryDisplayProps;

type PaymentRowProps = {
  testID: string;
  secondLine: string;
  isPaymentFailed: boolean;
  firstLine: string;
  iconName?: string;
  date?: Date;
};

export const ticketName = (
  zoneName: Maybe<string> | undefined,
  zoneCode?: Maybe<string>,
  defaultText?: string
) => {
  if (!zoneName) {
    return defaultText ?? ' ';
  }
  const zoneCodePrefix = zoneCode ? `${zoneCode} ` : '';
  return `${zoneCodePrefix}${zoneName}`;
};

const ArrowRight = () => <Icon name="Chevron_Right" size="s" />;

const ChargingIcon = () => (
  <IconBadge name="Charging" backgroundColor={theme.colors.lightBg.green} />
);

const PaymentRow = ({
  testID,
  secondLine,
  isPaymentFailed,
  firstLine,
  iconName,
  date,
}: PaymentRowProps) => {
  const { t } = useTranslation();
  return (
    <Row mt={0} mb={0} testID={`${testID}-payment-details`}>
      {iconName && (
        <StyledView>
          <Icon name={locationIcon[iconName]} size={'s'} />
        </StyledView>
      )}
      {date && (
        <Meta color={theme.colors.gray200}>{`${format(date, 'dd.MM', {
          locale: getLocale(),
        })}.   •   `}</Meta>
      )}
      <Meta color={theme.colors.gray200}>{`${firstLine}   •   `}</Meta>
      <Meta
        color={
          !isPaymentFailed ? theme.colors.gray200 : theme.colors.boost.red
        }>
        {!isPaymentFailed ? secondLine : t('parking.receipt.paymentFailed')}
      </Meta>
    </Row>
  );
};

export const ItemPermitHistoryDisplay: React.FC<ItemReceiptDisplayProps> = ({
  item,
  groupIndex,
  index,
}) => {
  const {
    created,
    countryCode,
    items,
    totalAmountCents,
    currency,
    orderNumber,
    transactionId,
  } = item;
  const { t } = useTranslation();
  const { navigate } = useNavigation<AccountNavProps>();
  const id = items?.[0]?.permitId;
  const name = ticketName(
    items?.[0]?.zoneName,
    items?.[0]?.zoneCode,
    t('parking.receipt.permitDefaultName')
  );
  const isClickable = !!id || !!orderNumber;
  const handleOnPress = () => {
    if (id || transactionId) {
      navigate('permitHistoryDetails', {
        permitId: id ?? '',
        transactionId,
      });
    }
  };

  const countryCodeCheck =
    countryCode || getCountryFromCurrency(currency as string) || 'SE';
  const price = totalAmountCents ?? 0;
  const country = countryCodeCheck;
  const pricing =
    totalAmountCents !== 0
      ? currencyFormatter(price / 100, country)
      : t('price.free');

  return (
    <ListItem
      item={item}
      title={name}
      onPress={handleOnPress}
      testID={`permit-item-${groupIndex}-${index}`}
      iconComponent={IconBadge}
      iconName="Parking"
      indexValue={index}
      subtitleComponent={() => (
        <PaymentRow
          testID={`permit-item-${groupIndex}-${index}`}
          firstLine={t('longTermParking.30DayPermits')}
          secondLine={pricing}
          isPaymentFailed={false}
          date={created ? new Date(created) : undefined}
        />
      )}
      arrowComponent={() =>
        isClickable ? <Icon name="Chevron_Right" size="s" /> : null
      }
    />
  );
};

const RightContent = ({
  paymentCard,
  paymentState,
  groupIndex,
  index,
}: {
  paymentCard: Card;
  paymentState?: ParkingPaymentState;
  groupIndex: number;
  index: number;
}) => {
  const { t } = useTranslation();
  const category = paymentCard?.category;
  const padgeText =
    paymentState && paymentState === 'Billing'
      ? t('paymentMethods.billing')
      : t('paymentMethods.business');
  if ((paymentState && paymentState === 'Billing') || category === 'business') {
    return (
      <Pill>
        <Meta testID={`parking-item-business-chip-${groupIndex}-${index}`}>
          {padgeText}
        </Meta>
      </Pill>
    );
  }
  return <></>;
};

export const ParkingSessionHistoryItem: React.FC<ItemParkingDisplayProps> = ({
  item,
  groupIndex,
  index,
}) => {
  const {
    price,
    countryCode,
    startDate,
    endDate,
    parkingZoneUid,
    sessionId,
    paymentState,
    cardId,
    parkZone,
  } = item || {};
  const { t } = useTranslation();
  const {
    flags: { retry_payment },
  } = useContext(FeatureFlagsContext);
  const showRetry = showFeatureFlag(retry_payment);
  const paymentCard = useSelectedPaymentMethod(cardId ?? '');

  const { navigate } = useNavigation<AccountNavProps>();
  const { name: parkingZoneName, commercialZoneView } = parkZone || {};

  const handleOnPress = () => {
    navigate('parkingHistoryDetails', {
      sessionId,
      parkingZoneUid: parkingZoneUid as string,
    });
  };

  const duration =
    startDate && endDate
      ? getDurationFromDates(new Date(startDate), new Date(endDate))
      : '';

  const pricing =
    price?.totalAmount !== 0
      ? currencyFormatter(price?.totalAmount || 0, countryCode)
      : t('price.free');

  const isPaymentFailed =
    (paymentState === 'Failed' || paymentState === 'Retrying') && showRetry;

  const priceString = !isPaymentFailed
    ? pricing
    : t('parking.receipt.paymentFailed');

  return (
    <ListItem
      item={item}
      title={(commercialZoneView?.name || parkingZoneName) as string}
      onPress={handleOnPress}
      testID={`parking-item-${groupIndex}-${index}`}
      iconComponent={IconBadge}
      iconName="Parking"
      indexValue={index}
      subtitleComponent={() => (
        <PaymentRow
          testID={`parking-item-${groupIndex}-${index}`}
          firstLine={duration}
          secondLine={priceString}
          isPaymentFailed={isPaymentFailed}
          date={startDate ? new Date(startDate) : undefined}
        />
      )}
      rightContentComponent={() => (
        <RightContent
          paymentCard={paymentCard || {}}
          paymentState={paymentState || undefined}
          groupIndex={groupIndex}
          index={index}
        />
      )}
      rightContentAlignCenter
      arrowComponent={ArrowRight}
    />
  );
};

export const ChargingSessionHistoryItem: React.FC<ItemChargingDisplayProps> = ({
  item,
  groupIndex,
  index,
}) => {
  const {
    startTime,
    endTime,
    parkingZoneUid,
    chargingSessionId,
    cardId,
    created,
    paymentState,
    parkZone,
  } = item || {};
  const { t } = useTranslation();
  const { navigate } = useNavigation<AccountNavProps>();
  const { commercialZoneView } = parkZone || {};
  const paymentCard = useSelectedPaymentMethod(cardId ?? '');

  const handleOnPress = () => {
    navigate('chargingHistoryDetails', {
      chargingSessionId: chargingSessionId as string,
      parkingZoneUid: parkingZoneUid as string,
    });
  };

  const duration =
    startTime && endTime
      ? getDurationFromDates(new Date(startTime), new Date(endTime))
      : '';

  const isPaymentFailed =
    paymentState === 'Failed' || paymentState === 'Retrying';
  const badgeDate = startTime || created;

  return (
    <ListItem
      item={item}
      title={
        commercialZoneView?.name
          ? commercialZoneView?.name
          : t('parking.receipt.chargingDefaultName')
      }
      onPress={handleOnPress}
      testID={`charging-item-${groupIndex}-${index}`}
      iconComponent={ChargingIcon}
      iconName="Charging"
      indexValue={index}
      subtitleComponent={() => (
        <PaymentRow
          testID={`charging-item-${groupIndex}-${index}`}
          firstLine={duration}
          secondLine={t('parking.receipt.paymentFailed')}
          isPaymentFailed={isPaymentFailed}
          date={badgeDate ? new Date(badgeDate) : undefined}
        />
      )}
      rightContentComponent={() =>
        paymentCard?.category === 'business' && (
          <Pill>
            <Meta testID={`parking-item-business-chip-${groupIndex}-${index}`}>
              {t('paymentMethods.business')}
            </Meta>
          </Pill>
        )
      }
      rightContentAlignCenter
      arrowComponent={ArrowRight}
    />
  );
};

export const ItemParkingHistoryDisplay: React.FC<ItemReceiptDisplayProps> = ({
  item,
  groupIndex,
  index,
}) => {
  const {
    items,
    totalAmountCents,
    countryCode,
    creditCard,
    currency,
    transactionId,
  } = item || {};
  const { t } = useTranslation();
  // const { flags: { retry_payment } } = useContext(FeatureFlagsContext); Not use for now. Retry feature implement on different ticket
  // const showRetry = showFeatureFlag(retry_payment); Not use for now. Retry feature implement on different ticket
  const paymentCard = useSelectedPaymentMethod(creditCard?.cardId ?? '');

  const countryCodeCheck =
    countryCode || getCountryFromCurrency(currency as string) || 'SE';
  const correctItems = getCorrectBillingItem(
    items as BillingItem[] | undefined
  );

  const startDate = correctItems?.[0]?.startTime;
  const endDate = correctItems?.[0]?.endTime;
  const parkingZoneUid = correctItems?.[0]?.zoneUid;
  const sessionId = correctItems?.[0]?.parkingSessionId;
  const type = correctItems?.[0]?.type;
  const zoneCode = correctItems?.[0]?.zoneCode;
  const name = correctItems?.[0]?.zoneName;

  const title = ticketName(
    name,
    zoneCode,
    type === 'PREPAID'
      ? t('parking.receipt.prepaidDefaultName')
      : t('parking.receipt.parkingDefaultName')
  );

  const { navigate } = useNavigation<AccountNavProps>();

  const isClickable =
    type === 'PREPAID'
      ? transactionId || sessionId
      : transactionId && parkingZoneUid;

  const handleOnPress = () => {
    if (type === 'PREPAID') {
      navigate('prepaidHistoryDetails', {
        ticketId: sessionId || '',
        transactionId,
      });
    } else {
      navigate('parkingHistoryDetails', {
        transactionId,
        parkingZoneUid: parkingZoneUid as string,
      });
    }
  };

  const duration =
    startDate && endDate
      ? getDurationFromDates(new Date(startDate), new Date(endDate))
      : '';

  const price = totalAmountCents ?? 0;
  const country = countryCodeCheck;

  const pricing =
    totalAmountCents !== 0
      ? currencyFormatter(price / 100, country)
      : t('price.free');

  const isPaymentFailed = false; //For now not handling payment fail

  const priceString = !isPaymentFailed
    ? pricing
    : t('parking.receipt.paymentFailed');

  return (
    <ListItem
      item={item}
      title={title}
      onPress={isClickable ? handleOnPress : undefined}
      testID={`parking-item-${groupIndex}-${index}`}
      iconComponent={useCallback(
        () => (
          <IconBadge
            backgroundColor={
              type === 'PREPAID' ? theme.colors.lightBg.green : undefined
            }
            name="Parking"
          />
        ),
        [type]
      )}
      indexValue={index}
      subtitleComponent={() => (
        <PaymentRow
          testID={`parking-item-${groupIndex}-${index}`}
          firstLine={type === 'PREPAID' ? t('services.prepaidTitle') : duration}
          secondLine={priceString}
          isPaymentFailed={isPaymentFailed}
          date={startDate ? new Date(startDate) : undefined}
        />
      )}
      rightContentComponent={() =>
        paymentCard?.category === 'business' && (
          <Pill>
            <Meta testID={`parking-item-business-chip-${groupIndex}-${index}`}>
              {t('paymentMethods.business')}
            </Meta>
          </Pill>
        )
      }
      rightContentAlignCenter
      arrowComponent={() =>
        isClickable ? (
          <Icon
            name="Chevron_Right"
            {...testIdentifiers(`parking-item-${groupIndex}-${index}-chevron`)}
            size="s"
          />
        ) : null
      }
    />
  );
};

export const ItemChargingHistoryDisplay: React.FC<ItemReceiptDisplayProps> = ({
  item,
  groupIndex,
  index,
}) => {
  const {
    items,
    totalAmountCents,
    countryCode,
    creditCard,
    currency,
    transactionId,
  } = item || {};
  const { t } = useTranslation();

  const countryCodeCheck =
    countryCode || getCountryFromCurrency(currency as string) || 'SE';
  const correctItems = getCorrectBillingItem(
    items as BillingItem[] | undefined
  );

  const startTime = correctItems?.[0]?.startTime;
  const endTime = correctItems?.[0]?.endTime;
  const parkingZoneUid = correctItems?.[0]?.zoneUid;
  const name = ticketName(
    correctItems?.[0]?.zoneName,
    correctItems?.[0]?.zoneCode,
    t('parking.receipt.chargingDefaultName')
  );

  const isClickable = transactionId;

  const { navigate } = useNavigation<AccountNavProps>();
  const paymentCard = useSelectedPaymentMethod(creditCard?.cardId ?? '');

  const handleOnPress = () => {
    if (isClickable) {
      navigate('chargingHistoryDetails', {
        transactionId,
        parkingZoneUid: parkingZoneUid as string,
      });
    }
  };

  const duration =
    startTime && endTime
      ? getDurationFromDates(new Date(startTime), new Date(endTime))
      : '';

  const price = totalAmountCents ?? 0;
  const country = countryCodeCheck;

  const pricing =
    totalAmountCents !== 0
      ? currencyFormatter(price / 100, country)
      : t('price.free');

  const isPaymentFailed = false; // Not handling payment fail right now

  const priceString = !isPaymentFailed
    ? pricing
    : t('parking.receipt.paymentFailed');

  return (
    <ListItem
      item={item}
      title={name}
      onPress={handleOnPress}
      testID={`charging-item-${groupIndex}-${index}`}
      iconComponent={ChargingIcon}
      iconName="Charging"
      indexValue={index}
      subtitleComponent={() => (
        <PaymentRow
          testID={`charging-item-${groupIndex}-${index}`}
          firstLine={duration}
          secondLine={priceString}
          isPaymentFailed={isPaymentFailed}
          date={startTime ? new Date(startTime) : undefined}
        />
      )}
      rightContentComponent={() =>
        paymentCard?.category === 'business' && (
          <Pill>
            <Meta testID={`parking-item-business-chip-${groupIndex}-${index}`}>
              {t('paymentMethods.business')}
            </Meta>
          </Pill>
        )
      }
      rightContentAlignCenter
      arrowComponent={() =>
        isClickable ? <Icon name="Chevron_Right" size="s" /> : null
      }
    />
  );
};

export const NoHistory = ({
  isFailed,
  isFree,
  isBilling,
  isOther,
}: {
  isFailed?: boolean;
  isFree?: boolean;
  isBilling?: boolean;
  isOther?: boolean;
}) => {
  const { t } = useTranslation();

  const renderTitle = () => {
    if (isFailed) {
      return t('parking.noFailedPayments');
    }
    if (isFree) {
      return t('parking.noFreeHistory');
    }
    if (isBilling) {
      return t('parking.noBillingHistory');
    }
    if (isOther) {
      return t('parking.noOtherHistory');
    }
    return t('parking.noHistory');
  };
  return (
    <>
      <StyledWrapper center mb={0}>
        <StyledImage
          testID="parking-history-no-content-image"
          width={183}
          height={152}
          center
          source={require('assets/images/parking-receipt-car.png')}
        />
      </StyledWrapper>
      <StyledWrapper center mb={37}>
        <Title center testID="parking-history-no-content-title">
          {renderTitle()}
        </Title>
      </StyledWrapper>
      {!isFailed && (
        <StyledWrapper center maxWidth={300}>
          <BaseText center testID="parking-history-no-content-description">
            {t('parking.noHistoryDescription')}
          </BaseText>
        </StyledWrapper>
      )}
      {isFailed && (
        <StyledWrapper center maxWidth={300}>
          <BaseText center testID="parking-history-no-content-description">
            {t('parking.noHistoryDescFailed')}
          </BaseText>
        </StyledWrapper>
      )}
    </>
  );
};
