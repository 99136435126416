import { useReactiveVar } from '@apollo/client';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { Spinner, TabSelectItem, TabSelector, theme } from 'aimo-ui';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import { showToast } from 'components/common/CustomToast';
import SheetLayout from 'components/sheets/SheetLayout';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import ParkingContext from 'context/ParkingContext';
import useAllowedParkingTimes from 'hooks/useAllowedParkingTimes';
import useBackButton from 'hooks/useBackButton';
import useBottomSheet from 'hooks/useCustomBottomSheet';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {
  activeElementSecondaryUid,
  activeElementType,
  currentCustomerCountry,
  isFullScreen,
  locationModalShown,
  selectedOngoingSession,
  selectedParkingZoneTab,
  sheetModal,
} from 'states/common';
import { mapCoordinates } from 'states/map';
import { featureCountryCode } from 'states/persistInStorage';
import styled from 'styled-components/native';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import { ParkingZoneTab } from 'types/common';
import { Maybe } from 'types/generatedSchemaTypes';
import { IsFocusedProps, SetParamsProps } from 'types/navigation';
import { changeFutureAppCountryCode } from 'utils/countryUtils';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { handleElPress, makePoint } from 'utils/mapUtils';
import { isOnboardingOpen } from 'utils/navigationUtils';
import {
  addToRecentZonesOrChargers,
  isValidNote,
  removeFromRecentZonesOrChargers,
} from 'utils/parkUtils';
import ParkingZoneDetailsHeadline from './ParkingZoneDetailsHeadline';
import ParkingZoneChargeTab from './tabs/ParkingZoneChargeTab';
import ParkingZoneDetailsTab from './tabs/ParkingZoneDetailsTab';
import ParkingZoneParkAndChargeTab from './tabs/ParkingZoneParkAndChargeTab';
import ParkingZoneParkTab from './tabs/ParkingZoneParkTab';

const Spacer = styled(View)`
  margin-vertical: 85px;
`;

type DefineChipsDisabledProps = {
  isLoggedIn: boolean;
  parkingAllowed: boolean | undefined;
  isAnpr: boolean;
  manualStart: Maybe<boolean> | undefined;
  isUnmanagedParking: boolean;
};

const defineChipsDisabled = ({
  isLoggedIn,
  parkingAllowed,
  isAnpr,
  manualStart,
  isUnmanagedParking,
}: DefineChipsDisabledProps) =>
  !isLoggedIn ||
  !parkingAllowed ||
  (isAnpr && !manualStart) ||
  isUnmanagedParking;

export const ParkingZoneDetails: React.FC = () => {
  const navigation = useNavigation();
  const {
    parkingZoneResult: parkingZone,
    parkingConditions: { parkingZoneUid },
    myPermitsForZone,
    setCurrentScreen,
    availablePermits,
  } = useContext(ParkingContext);

  const {
    address,
    uid = '',
    parkingMethods,
    locationType,
    parkingZoneChargingStations,
  } = parkingZone?.data?.parkZone || {};
  const { marker } = address || {};
  const isAnpr = parkingMethods?.anpr === true;
  const isUnmanagedParking = parkingMethods?.unmanagedParking === true;
  const hasEvCharging = parkingMethods?.evCharging;
  const { collapse, expand } = useBottomSheet();
  const { setParams, isFocused }: SetParamsProps & IsFocusedProps =
    useNavigation();
  const isSheetModal = !!useReactiveVar(sheetModal);
  const isFullScreenView = useReactiveVar(isFullScreen);
  const appCountryCode = useReactiveVar(featureCountryCode);
  const { isLoggedIn } = useIsLoggedIn();
  const focus = useIsFocused();
  const ongoingSessionType = useReactiveVar(selectedOngoingSession);
  const { t } = useTranslation();
  const selectedTab = useReactiveVar(selectedParkingZoneTab);

  const isCharge = isUnmanagedParking && hasEvCharging;
  const isParkAndCharge = !isUnmanagedParking && hasEvCharging;

  const scrollRef = useRef<any>(null);

  useEffect(() => {
    const zoneCountry = parkingZone?.data?.parkZone?.countryCode || null;
    if (
      zoneCountry &&
      appCountryCode !== zoneCountry &&
      isLoggedIn &&
      focus &&
      !isOnboardingOpen(navigation)
    ) {
      sheetModal('parkingAppLocation');
      locationModalShown(true);
      currentCustomerCountry(zoneCountry);
      changeFutureAppCountryCode(zoneCountry);
    }
  }, [
    appCountryCode,
    isLoggedIn,
    parkingZone?.data?.parkZone?.countryCode,
    focus,
    navigation,
  ]);

  const {
    flags: { stp_tariff },
  } = useContext(FeatureFlagsContext);
  const showSTPTariff = showFeatureFlag(stp_tariff);
  const showEVChargingPublicUnmanagedParking =
    !!parkingMethods?.unmanagedParking;

  const allowedParkingTimes = useAllowedParkingTimes(uid, true);

  const { parkingAllowed, showDefaultChips, predefinedTicketsOnly } =
    allowedParkingTimes;

  const chipsDisabled = defineChipsDisabled({
    isLoggedIn,
    parkingAllowed: parkingAllowed,
    isAnpr,
    manualStart: parkingMethods?.manualStart,
    isUnmanagedParking,
  });

  useEffect(() => {
    const zoneCountry = parkingZone?.data?.parkZone?.countryCode || null;
    if (
      locationType === 'STREET' &&
      zoneCountry === 'FI' &&
      !isFullScreenView
    ) {
      sheetModal('streetConfirmation');
    }
  }, [
    locationType,
    isFullScreenView,
    parkingZone?.data?.parkZone?.countryCode,
  ]);

  // Temporal solution while tariff selector and ev charging are managed by feature flags.
  // Remove when stp_tariff feature flag is removed.
  if (
    !showSTPTariff &&
    showEVChargingPublicUnmanagedParking &&
    isUnmanagedParking
  ) {
    activeElementType('CHARGING');
    activeElementSecondaryUid(parkingZoneChargingStations?.[0]?.id);
  }

  const onBackPress = () => {
    // Make sure onBackPress is not overwritten if modal is open
    if (!isFocused()) {
      return false;
    }
    if (isSheetModal) {
      sheetModal('');
    } else {
      setParams({ parkingZoneUid: '' });
    }
    return true;
  };
  useBackButton(onBackPress);

  if (
    parkingZone?.data?.parkZone &&
    parkingZone.data.parkZone.serviceChannelCodes &&
    process.env.SERVICE_CHANNEL_CODE
  ) {
    if (
      parkingZone.data.parkZone.serviceChannelCodes?.includes(
        process.env.SERVICE_CHANNEL_CODE
      ) &&
      parkingZone.data.parkZone.commerciallyActive
    ) {
      addToRecentZonesOrChargers(parkingZone?.data?.parkZone);
    } else {
      showToast(t('parking.parkzoneNotAvailable'), 'info');
      removeFromRecentZonesOrChargers(parkingZone?.data?.parkZone);
      setParams({ parkingZoneUid: '' });
    }
  }

  useEffect(() => {
    if (isFullScreenView) {
      expand();
    } else {
      collapse();
    }
  }, [parkingZoneUid, expand, collapse, isFullScreenView]);

  const markerCoordinate = marker && makePoint(marker?.coordinates);

  const handleNamePress = () => {
    if (markerCoordinate) {
      mapCoordinates(markerCoordinate);
    }
  };

  const tabSelectItems = useMemo(
    () =>
      [
        ...(!isCharge
          ? [
              {
                title: t('parking.tabSelector.park.title'),
                tab: 'PARK',
              },
            ]
          : []),
        ...(isCharge || isParkAndCharge
          ? [
              {
                title: t('parking.tabSelector.charge.title'),
                tab: 'CHARGE',
              },
            ]
          : []),
        ...(isParkAndCharge
          ? [
              {
                title: t('parking.tabSelector.parkAndCharge.title'),
                tab: 'PARK_AND_CHARGE',
              },
            ]
          : []),
        { title: t('parking.tabSelector.details.title'), tab: 'DETAILS' },
      ] as (TabSelectItem & { tab: ParkingZoneTab })[],
    [isCharge, isParkAndCharge, t]
  );

  const handleTabSelection = (i: number) => {
    selectedParkingZoneTab(tabSelectItems[i].tab);
  };

  const hasImportantNotes = parkingZone?.data?.parkZone?.notes?.some((note) =>
    isValidNote(note, 3)
  );

  const ScrollWrapperComponent = isWeb
    ? ScrollView
    : BottomSheetScrollViewCustom;

  if (!parkingZone?.data?.parkZone?.uid) {
    return <></>;
  }

  return (
    <ScrollWrapperComponent
      ref={scrollRef}
      bottomThreshold={theme.normalising.heightPixel(
        ongoingSessionType ? 150 : 80
      )}>
      <SheetLayout
        onHeadlinePress={handleNamePress}
        leftAlignHeader
        testIDName="park-zone-details"
        onHeadlineButtonPress={() =>
          handleElPress(uid, { latitude: 0, longitude: 0 }, setParams)
        }
        headlineComponent={
          <ParkingZoneDetailsHeadline
            parkZone={parkingZone?.data?.parkZone}
            markerCoordinate={markerCoordinate}
          />
        }>
        <TabSelector
          onTabChange={handleTabSelection}
          items={tabSelectItems}
          testID={'parking-zone-details'}
        />
        {!selectedTab && (
          <ColumnWithMargins mt={20}>
            <StyledWrapper center>
              <Spinner />
            </StyledWrapper>
          </ColumnWithMargins>
        )}
        {isParkAndCharge && (
          <ParkingZoneParkAndChargeTab
            isActiveTab={selectedTab === 'PARK_AND_CHARGE'}
            parkZone={parkingZone?.data?.parkZone}
            scrollRef={scrollRef}
            hasImportantNotes={hasImportantNotes}
          />
        )}
        {!isCharge && (
          <ParkingZoneParkTab
            isActiveTab={selectedTab === 'PARK'}
            parkZone={parkingZone?.data?.parkZone}
            chipsDisabled={chipsDisabled}
            showDefaultChips={showDefaultChips}
            predefinedTicketsOnly={predefinedTicketsOnly}
            allowedParkingTimes={allowedParkingTimes}
            setCurrentScreen={setCurrentScreen}
            hasImportantNotes={hasImportantNotes}
            myPermitsForZone={myPermitsForZone}
            availablePermits={availablePermits}
          />
        )}
        {(isCharge || isParkAndCharge) && (
          <ParkingZoneChargeTab
            isActiveTab={selectedTab === 'CHARGE'}
            parkZone={parkingZone?.data?.parkZone}
            scrollRef={scrollRef}
            hasImportantNotes={hasImportantNotes}
          />
        )}
        <ParkingZoneDetailsTab
          isActiveTab={selectedTab === 'DETAILS'}
          parkZone={parkingZone?.data?.parkZone}
          allowedParkingTimes={allowedParkingTimes}
          showEVChargingPublicUnmanagedParking={
            showEVChargingPublicUnmanagedParking
          }
        />
        <Spacer />
        {/* <QuickActionButtons //Unused for now
          uid={uid}
          name={name}
          markerCoordinate={markerCoordinate}
        /> */}
      </SheetLayout>
    </ScrollWrapperComponent>
  );
};

export default ParkingZoneDetails;
