import {
  SpeechBubble as SpeechBubbleBase,
  SpeechBubbleProps,
  theme,
} from 'aimo-ui';
import SvgImage from './SvgImage';
import React from 'react';
import { StyleSheet } from 'react-native';

const SpeechBubble: React.FC<Omit<SpeechBubbleProps, 'bubblePart'>> = (
  props
) => {
  return (
    <SpeechBubbleBase
      {...props}
      bubblePart={
        <SvgImage
          name="SpeechBubblePart"
          height={30}
          width={30}
          testID="bubble-part"
          color={theme.colors.white}
          style={styles.bubblePart}
        />
      }
    />
  );
};

const styles = StyleSheet.create({
  bubblePart: { position: 'relative', top: 8, left: '-30%', marginTop: -15 },
});

export default SpeechBubble;
