import { useNavigation } from '@react-navigation/native';
import VehicleAddSection from 'components/account/vehicle/VehicleAddSection';
import SelectVehicleItem from 'components/park/SelectVehicleItem';
import AccountContext from 'context/AccountContext';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import React, { useContext } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import {
  editVehicleData,
  editVehicleIsDeleted,
  editVehicleIsDiscarded,
  parkingVehicleLicensePlate,
  sheetModal,
  vehicleFromParkzone,
} from 'states/common';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { Vehicle } from 'types/generatedSchemaTypes';
import { RootNavProps } from 'types/navigation';
import { testIdentifiers } from 'utils/testIdentifiers';

const SelectVehicleModalContent = () => {
  const { navigate } = useNavigation<RootNavProps>();
  const {
    customer: { vehicles },
  } = useContext(AccountContext);
  const selectedVehicle = useSelectedParkingVehicle();
  const handleSetVehicle = (vehicle: Vehicle) => {
    parkingVehicleLicensePlate(vehicle?.licensePlate?.plateText);
    sheetModal('');
  };
  const handleOnPress = () => {
    sheetModal('');
    vehicleFromParkzone(true);
    navigate('addVehicleParking');
  };
  const handleEditVehicle = (v: Vehicle) => {
    sheetModal('');
    parkingVehicleLicensePlate(v.licensePlate?.plateText);
    editVehicleData(v);
    editVehicleIsDiscarded(false);
    editVehicleIsDeleted(false);
    vehicleFromParkzone(true);
    navigate('mainTabs', {
      screen: 'accountScreen',
      params: {
        screen: 'editVehicle',
        params: {
          licensePlate: v.licensePlate?.plateText,
        },
        initial: false,
      },
    });
  };
  return (
    <ScrollView nestedScrollEnabled>
      {vehicles?.map((vehicle, index) => {
        return (
          <SelectVehicleItem
            editVehicle={handleEditVehicle}
            isSelected={
              selectedVehicle?.licensePlate?.plateText ===
              vehicle.licensePlate?.plateText
            }
            key={`${vehicle.licensePlate?.plateText}-${index}`}
            vehicle={vehicle}
            onPress={handleSetVehicle}
          />
        );
      })}
      <ColumnWithMargins
        mt={40}
        {...testIdentifiers('add-vehicle-list-item-button')}>
        <VehicleAddSection onPress={handleOnPress} style="BUTTON" />
      </ColumnWithMargins>
    </ScrollView>
  );
};

export default SelectVehicleModalContent;
