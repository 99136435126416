import { gql } from '@apollo/client';

export const READ_PARK_ZONE_PRICE_ESTIMATE = gql`
  query readParkZonePriceEstimate($input: ParkZonePriceEstimateInput!) {
    readParkZonePriceEstimate(input: $input) {
      totalAmount
      breakdown {
        serviceFee {
          amount
        }
      }
    }
  }
`;

export const READ_PARKINGS_SESSION_PRICE_ESTIMATE = gql`
  query readParkingSessionPriceEstimate(
    $input: ParkingSessionPriceEstimateInput!
  ) {
    readParkingSessionPriceEstimate(input: $input) {
      totalAmount
      breakdown {
        serviceFee {
          amount
        }
        parkingFee {
          amount
        }
      }
    }
  }
`;
