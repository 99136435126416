import { BaseText, Meta, Spinner, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import React from 'react';
import styled from 'styled-components/native';

const SelectionContainer = styled.Pressable<{ noBorder?: boolean }>`
  flex-direction: row;
  align-items: center;
  border-bottom-width: ${(p) => (p.noBorder ? '0px' : '3px')};
  padding: 15px 0;
  width: 100%;
  border-bottom-color: ${(p) => p.theme.colors.gray50};
`;

const SelectionWrapper = styled.View<{
  width?: string;
  alignRight?: boolean;
  marginRight?: string;
  justifyFlexStart?: boolean;
}>`
  width: ${(p) => (p.width ? p.width + 'px' : 'auto')};
  flex: ${(p) => (p.alignRight ? '1 1 auto' : '0 1 auto')};
  flex-direction: row;
  justify-content: ${(p) => (p.justifyFlexStart ? 'flex-start' : 'flex-end')};
  align-items: center
  margin-right: ${(p) => (p.marginRight ? p.marginRight + 'px' : '0px')};
`;

const StylesBaseText = styled(BaseText)`
  font-size: ${theme.textStyles.text.fontSize};
  line-height: ${theme.textStyles.text.lineHeight};
  font-weight: 500;
`;

const ValueSelectionItem: React.FC<{
  title?: string;
  value?: string;
  onPress?: () => void;
  selectable?: boolean;
  loading?: boolean;
  testId?: string;
  noBorder?: boolean;
}> = ({ title, value, onPress, selectable, loading, noBorder, testId }) => {
  return (
    <SelectionContainer
      onPress={selectable ? onPress : null}
      noBorder={noBorder}
      testID={testId}>
      <SelectionWrapper justifyFlexStart>
        <StylesBaseText testID={`${testId}-title`}>{title}</StylesBaseText>
      </SelectionWrapper>
      <SelectionWrapper alignRight>
        {loading ? (
          <Spinner small />
        ) : (
          <>
            <Meta color={theme.colors.gray200} testID={`${testId}-value`}>
              {value}
            </Meta>
            {selectable && (
              <Icon
                testID={`${testId}-selectable`}
                name="Chevron_Right"
                size="m"
              />
            )}
          </>
        )}
      </SelectionWrapper>
    </SelectionContainer>
  );
};

export default ValueSelectionItem;
