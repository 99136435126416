import { useReactiveVar } from '@apollo/client';
import { Button, Meta, theme } from 'aimo-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  activeElement,
  canStartParkingZoneUid,
  sheetModal,
} from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';

const ConfirmParkingClosedZoneModalContent: React.FC = () => {
  const { t } = useTranslation();
  const uid = useReactiveVar(activeElement);

  const confirmStartParking = () => {
    canStartParkingZoneUid(uid);
    sheetModal('');
  };
  const onBackPress = () => {
    sheetModal('');
  };

  return (
    <StyledWrapper mb={0}>
      <StyledWrapper maxWidth={335} center mb={40}>
        <Meta
          center
          color={theme.colors.gray200}
          testID="confirm-closed-parking-description">
          {t('parking.closedZoneConfirmDescription')}
        </Meta>
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <StyledWrapper>
          <Button
            testID="confirm-closed-parking-confirm-button"
            size="l"
            text={t('button.confirm')}
            onPress={confirmStartParking}
          />
        </StyledWrapper>
        <StyledWrapper mb={0}>
          <Button
            testID="confirm-closed-parking-cancel-button"
            size="l"
            buttonType="tertiary"
            text={t('button.cancel')}
            onPress={onBackPress}
          />
        </StyledWrapper>
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default ConfirmParkingClosedZoneModalContent;
