import { gql, useApolloClient, useQuery } from '@apollo/client';
import { GET_POOLING_GROUP_CAPACITY } from '@queries/getPoolingGroupCapacity';
import { theme } from 'aimo-ui';
import DoubleBadge from 'components/common/DoubleBadge';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useGetMatchingAccessPermitForParkingZone from 'hooks/useGetMatchingAccessPermitForParkingZone';
import useGetMemberGroupNameForAccessPermitPoolingGroup from 'hooks/useGetMemberGroupNameForAccessPermitPoolingGroup';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Query,
  QueryGetPoolingGroupCapacityArgs,
} from 'types/generatedSchemaTypes';
import { updateCache } from 'utils/cacheUtils';

const PoolingBadge: React.FC<{
  parkingZoneUid: string | undefined;
}> = ({ parkingZoneUid }) => {
  const [showRefreshButton, setShowRefreshButton] = useState<boolean>(false);
  const { t } = useTranslation();
  const client = useApolloClient();

  const { matchingPermit, loading } =
    useGetMatchingAccessPermitForParkingZone(parkingZoneUid);

  const memberGroupName =
    useGetMemberGroupNameForAccessPermitPoolingGroup(matchingPermit);

  const {
    flags: { show_pooling_spots_refresh_button_delay_milliseconds },
  } = useContext(FeatureFlagsContext);

  const resetRefresh = useCallback(() => {
    setShowRefreshButton(false);

    return setTimeout(
      () => {
        setShowRefreshButton(true);
      },
      parseInt(show_pooling_spots_refresh_button_delay_milliseconds, 10)
    );
  }, [show_pooling_spots_refresh_button_delay_milliseconds]);

  const afterLoad = useCallback(
    (result: Query) => {
      updateCache(
        client.cache,
        {
          __typename: 'AccessPermitPoolingGroupCombinedInfo',
          uid: matchingPermit?.accessToPoolingGroupUid,
        },
        {
          fragment: gql`
            fragment AccessPermitPoolingGroupCombinedInfoFragment on AccessPermitPoolingGroupCombinedInfo {
              uid
              freePoolingSpots
              lastFetch
            }
          `,
          data: {
            uid: matchingPermit?.accessToPoolingGroupUid,
            freePoolingSpots: result.getPoolingGroupCapacity?.free,
            lastFetch: Date.now(), //Used mainly to trigger the hiding of refresh button, we need some data change so the other pooling badge knows data was fetched even if values didn't change
          },
        }
      );
      resetRefresh();
    },
    [client.cache, matchingPermit?.accessToPoolingGroupUid, resetRefresh]
  );

  const {
    loading: loadingPoolingGroupCapacity,
    data: freePoolingSpots,
    refetch,
  } = useQuery<Query, QueryGetPoolingGroupCapacityArgs>(
    GET_POOLING_GROUP_CAPACITY,
    {
      skip: !matchingPermit?.accessToPoolingGroupUid,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: matchingPermit?.accessToPoolingGroupUid
        ? {
            poolingGroupId: matchingPermit?.accessToPoolingGroupUid,
          }
        : undefined,
      onCompleted: afterLoad,
    }
  );

  useEffect(() => {
    const timeout = resetRefresh();
    return () => {
      clearTimeout(timeout);
    };
  }, [
    matchingPermit?.accessPermitPoolingGroupCombinedInfo?.lastFetch,
    resetRefresh,
  ]);

  if (!matchingPermit || matchingPermit.isWaitingActivation) {
    return <></>;
  }

  return (
    <DoubleBadge
      loading={loading}
      testID={`pooling-${matchingPermit.id}`}
      iconName="Park-Filled"
      innerBadgeTextColor={
        freePoolingSpots && !loadingPoolingGroupCapacity
          ? undefined
          : theme.colors.gray200
      }
      exludeRightContent={false}
      textLeft={memberGroupName || ' - '}
      textRight={t('parking.parkingSpotsAvailable', {
        spots:
          matchingPermit?.accessPermitPoolingGroupCombinedInfo
            ?.freePoolingSpots || 0,
      })}
      reloadOnPress={() =>
        refetch({
          poolingGroupId: matchingPermit?.accessToPoolingGroupUid || undefined,
        })
      }
      showRefreshButton={showRefreshButton}
      loadingInnerBadge={loadingPoolingGroupCapacity}
    />
  );
};

export default PoolingBadge;
