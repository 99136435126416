import { gql } from '@apollo/client';

export const READ_CUSTOMER_PMCS_DETAILS = gql`
  query ReadCustomerPmcsDetails($input: CustomerPmcsInput!) {
    readCustomerPmcs(input: $input) {
      pmcId
      name
      reservationType
      physicalZoneUid
      parkingSpaceType
      countryCode
      visibility
      prices {
        thirtyDayPrice
        monthlyPrice
        validity {
          validFrom
        }
      }
      billingInterval
      availability {
        availability
        details {
          pmc
        }
      }
      parkZone {
        name
        uid
        spotMap
      }
      paymentMethods
      parkingSpacesV2 {
        content {
          pmcIdRef
          parkingSpaceStatus
          parkingSpace {
            reserved
            name
            type
            uid
            externalNote
          }
        }
      }
    }
  }
`;
