import PriceEstimateContext from 'context/PriceEstimateContext';
import React, { ReactNode, useContext } from 'react';
import Animated, {
  useAnimatedReaction,
  useAnimatedStyle,
} from 'react-native-reanimated';

const PreloadingWrapper = ({
  firstItem,
  secondItem,
}: {
  firstItem: ReactNode;
  secondItem: ReactNode;
}) => {
  const { startButtonOpacity } = useContext(PriceEstimateContext);

  useAnimatedReaction(
    () => undefined,
    () => {
      if (startButtonOpacity?.value) {
        startButtonOpacity.value = 1;
      }
    },
    []
  );

  const firstItemStyle = useAnimatedStyle(() => {
    const value = startButtonOpacity?.value ?? 1;
    return {
      opacity: value,
      display: value > 0.5 ? 'flex' : 'none',
    };
  }, [startButtonOpacity]);

  const secondItemStyle = useAnimatedStyle(() => {
    const value = startButtonOpacity?.value ?? 1;
    return {
      opacity: 1 - value,
      display: value < 0.5 ? 'flex' : 'none',
    };
  }, [startButtonOpacity]);
  return (
    <>
      <Animated.View style={[firstItemStyle]}>{firstItem}</Animated.View>
      <Animated.View style={[secondItemStyle]}>{secondItem}</Animated.View>
    </>
  );
};

export default PreloadingWrapper;
