import AccountContext from 'context/AccountContext';
import { useContext } from 'react';

const useHasAnprEnabled = () => {
  const {
    customer: { vehicles },
  } = useContext(AccountContext);
  if (vehicles?.[0]) {
    return vehicles.some((vehicle) => vehicle.cameraRecognition?.enabled);
  }
  return false;
};

export default useHasAnprEnabled;
