import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_PARK_ZONE_WITH_CHARGING_STATIONS } from '@queries/getParkZoneWithChargingStations';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { useContext } from 'react';
import { activeElement } from 'states/common';
import { Query, QueryParkZoneArgs } from 'types/generatedSchemaTypes';
import { showFeatureFlag } from 'utils/featureFlagUtils';

const useFeatureFlagEVCharging = () => {
  const parkingZoneUid = useReactiveVar(activeElement);
  const { data: parkingZone } = useQuery<Query, QueryParkZoneArgs>(
    GET_PARK_ZONE_WITH_CHARGING_STATIONS,
    {
      variables: { uid: parkingZoneUid },
      skip: !parkingZoneUid,
    }
  );
  const { parkingMethods } = parkingZone?.parkZone || {};
  const {
    flags: {
      ev_charging_public_anpr_manualStopAllowed,
      ev_charging_public_anpr_manualStopDisabled,
      ev_charging_public_startAndStopParking,
    },
  } = useContext(FeatureFlagsContext);
  const showEVChargingPublicUnmanagedParking =
    !!parkingMethods?.unmanagedParking;
  const showEVChargingPublicANPRManualStopAllowed = showFeatureFlag(
    ev_charging_public_anpr_manualStopAllowed
  );
  const showEVChargingPublicANPRManualStopDisabled = showFeatureFlag(
    ev_charging_public_anpr_manualStopDisabled
  );
  const showEVChargingPublicStartAndStopParking = showFeatureFlag(
    ev_charging_public_startAndStopParking
  );
  return (
    (showEVChargingPublicUnmanagedParking &&
      parkingMethods?.unmanagedParking) ||
    (showEVChargingPublicANPRManualStopAllowed &&
      parkingMethods?.anpr &&
      parkingMethods?.manualStop) ||
    (showEVChargingPublicANPRManualStopDisabled &&
      parkingMethods?.anpr &&
      !parkingMethods?.manualStop) ||
    (showEVChargingPublicStartAndStopParking && !parkingMethods?.anpr)
  );
};
export default useFeatureFlagEVCharging;
