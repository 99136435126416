import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  formatDuration,
  getDay,
  intervalToDuration,
  isToday,
  isTomorrow,
  isYesterday,
  startOfMinute,
} from 'date-fns';
import { format } from 'date-fns-tz';
import i18n, { t } from 'i18next';
import { parse } from 'iso8601-duration';
import {
  CountryCode,
  Lang,
  Maybe,
  TimeSlotsProps,
  WeekDays,
} from 'types/generatedSchemaTypes';
import { getLang, getLocale } from './commonUtils';

interface weekDayMapProps {
  [index: number]: WeekDays;
}

interface Dictionary<T> {
  [Key: string]: T;
}

interface TimeZoneProps {
  [key: string]: string;
}

export type ParkingFacilityOpen = {
  text?: string;
  key?: string;
};

export const exludedTime = [{}];

export const timeZoneStrings: TimeZoneProps = {
  FI: 'Europe/Helsinki',
  NO: 'Europe/Oslo',
  SE: 'Europe/Stockholm',
  EN: 'Europe/Helsinki',
};

const compareIsOpen = (
  currentTime: string,
  startTime: string,
  endTime: string
) => {
  return (
    currentTime >= startTime &&
    currentTime < (endTime === '00:00' ? '24:00' : endTime)
  );
};

export const shortenTimeUnits = (timeString: string) => {
  let newString: string = timeString?.replace(/(^|\s)month[a-z]?/, 'm');
  newString = newString?.replace(/(^|\s)day[a-z]?/, ' d');
  newString = newString?.replace(/(^|\s)hour[a-z]?/, ' h');
  newString = newString?.replace(/(^|\s)minute[a-z]?/, ' min');
  newString = newString?.replace(/(^|\s)second[a-z]?/, ' s');
  return newString;
};

export const weekDayMap: weekDayMapProps = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const weekDayOrder: WeekDays[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export const getWeekDayString = (date: Date = new Date()) => {
  return weekDayMap[getDay(date)];
};

export const timeProgress = (
  startTime: Date,
  endTime: Date | null,
  currentTime: Date
) => {
  if (!endTime) {
    return 100;
  }
  if (currentTime.getTime() > endTime.getTime()) {
    return 101;
  }
  const durationProgress = currentTime.getTime() - startTime.getTime();
  if (durationProgress < 0) {
    return 0;
  }
  const fullDuration = endTime.getTime() - startTime.getTime();
  const progressPercentage = (durationProgress / fullDuration) * 100;
  return +progressPercentage.toFixed(1);
};

export const getTimeLeft = (
  endTime: Date | null,
  currentTime: Date,
  hideMinutes = false
) => {
  let overdue = false;
  if (!endTime) {
    return {
      overdue: false,
      timeLeft: '',
    };
  }

  if (endTime < currentTime) {
    overdue = true;
  }

  const daysValid = differenceInDays(endTime, currentTime);
  let timeLeft = '';
  if (daysValid > 10) {
    timeLeft = shortenTimeUnits(
      formatDuration({ days: daysValid }, { format: ['days'] })
    );
  }

  const duration = intervalToDuration({
    start: !overdue ? currentTime : endTime,
    end: !overdue ? endTime : currentTime,
  });

  if (daysValid < 10 || !hideMinutes) {
    const durationString = formatDuration(duration, {
      format: ['days', 'hours', daysValid === 0 ? 'minutes' : ''],
    });
    timeLeft = shortenTimeUnits(durationString);
  }

  if (timeLeft === '') {
    const secs = formatDuration(duration, {
      format: ['seconds'],
    });
    return { overdue, timeLeft: shortenTimeUnits(secs) };
  }
  return { overdue, timeLeft };
};

export const getOpeningEndingTimeToday = (
  startTime: string,
  endTime: string,
  schedule: Dictionary<Maybe<TimeSlotsProps>[]>,
  date: Date,
  country: CountryCode
): ParkingFacilityOpen => {
  if (!startTime || !endTime) {
    return { text: i18n.t('time.closedToday'), key: 'CLOSED' };
  }
  if (startTime === endTime) {
    return { text: i18n.t('time.open24Today'), key: 'OPEN' };
  }
  const { isOpen, openTime } = getScheduleFromDate(schedule, date, country);
  if (!isOpen && openTime) {
    return { text: openTime, key: 'CLOSED' };
  }
  return { text: i18n.t('time.openUntil', { endTime }), key: 'OPEN' };
};
/**
 * Counts duration and parses result in selected units string
 * @param  {Date} start Date of start time
 * @param  {Date} end Date of end time
 * @param  {string[]} formatArray format array can defined what is shown (days,hours,minutes,seconds by default)
 * @return {string} Returns duration in selected units
 */
export const getDurationFromDates = (
  start: Date,
  end: Date,
  formatArray?: string[]
): string => {
  const duration = intervalToDuration({
    start,
    end,
  });
  const formatArr = formatArray ?? ['days', 'hours', 'minutes', 'seconds'];
  const durationString = formatDuration(duration, {
    format: formatArr,
  });
  return shortenTimeUnits(durationString);
};
/**
 * parse minutes to hours and minutes
 * @param  {number} minutes minutes
 * @return {string} either minutes and hours and minutes string. decimals stripped
 */
export const minutesToHoursAndMinutes = (minutes: number): string => {
  const evenMinutes = Math.floor(minutes);
  const hours = evenMinutes / 60;
  if (hours >= 1) {
    const minutesLeft = evenMinutes % 60;
    return `${Math.floor(hours)} h${
      minutesLeft > 0 ? ` ${minutesLeft} min` : ''
    }`;
  }
  return evenMinutes + ' min';
};

export const lastDayOfMonth = (date: Date): string => {
  const month = date.getMonth();
  date.setFullYear(date.getFullYear(), month + 1, 0);
  date.setHours(0, 0, 0, 0);
  return format(date, 'dd.MM.yyyy', { locale: getLocale() });
};

export const getTodayTomorrowOrDate = (date: Date) => {
  if (isToday(date)) {
    return i18n.t('time.todaySmall');
  }
  if (isTomorrow(date)) {
    return i18n.t('time.tomorrowSmall');
  }
  return format(date, 'do MMM', { locale: getLocale() });
};

export const getTodayYesterdayOrDate = (date: Date, deviceLanguage: Lang) => {
  if (isToday(date)) {
    return null;
  }

  if (isYesterday(date)) {
    return i18n.t('time.yesterdaySmall');
  }

  let dateFormat: string;
  switch (deviceLanguage) {
    case 'fi':
      dateFormat = 'do MMMM';
      break;
    case 'sv':
      dateFormat = 'd MMMM';
      break;
    default:
      dateFormat = 'd MMM';
      break;
  }

  return format(date, dateFormat, { locale: getLocale() });
};

export const getTodayOrFullDate = (date: Date) => {
  if (isToday(date)) {
    return i18n.t('time.todaySmall');
  }
  return format(date, 'ccc do MMM', { locale: getLocale() });
};

export const addHoursToDate = (date: Date, hours = 0) => {
  let newDate;
  // Check if the hours are full hours given (1,2,3 etc). If its not (example 1.5) we convert them to minutes
  // since date-fns is only adding the full hours.
  if (Number.isInteger(hours)) {
    newDate = addHours(date, hours);
  } else {
    const minutes = hours * 60;
    newDate = addMinutes(date, minutes);
  }
  return newDate;
};

export const getTimeFromDate = (date: Date, country: CountryCode) => {
  return format(date, 'HH:mm', {
    timeZone: timeZoneStrings[country] || 'Europe/Helsinki',
  });
};

export const getMaxParkingTime = (startParking: string, endParking: string) => {
  if (!startParking || !endParking) {
    return;
  }
  const start = startOfMinute(new Date(startParking));
  const end = startOfMinute(new Date(endParking));
  const { days, hours, minutes } = intervalToDuration({
    start,
    end,
  });

  const customSvLocale = {
    formatDistance: (token: 'xDays' | 'xHours' | 'xMinutes', count: string) =>
      t(`time.customLocale.${token}.${+count === 1 ? 'single' : 'multi'}`, {
        value: count,
      }),
  };

  return formatDuration(
    { days, hours, minutes },
    {
      format: ['days', 'hours', 'minutes'],
      locale: getLang() === 'sv' ? customSvLocale : getLocale(),
    }
  );
};

/**
 * returns object
 * @param  {Dictionary<Maybe<TimeSlotsProps>[]>} schedule minutes as int
 * @param {CountryCode} country - Current country data to get correct timezone
 * @param {Date} date
 * @return {boolean} isOpen - whether parking zone is open or closed on given date & time
 * @return {string} closingTime - if parking zone is open, translated closed today or tomorrow or empty string
 * @return {string} openTime - if parking zone is closed, translated when it opens today or tomorrow or empty string
 */
export const getScheduleFromDate = (
  schedule: Dictionary<Maybe<TimeSlotsProps>[]>,
  date: Date,
  country: CountryCode
) => {
  const clock = getTimeFromDate(date, country);
  const todayString = getWeekDayString(date);
  const tomorrowString = getWeekDayString(addDays(date, 1));
  const { startTime: startTimeToday, endTime: endTimeToday } =
    schedule[todayString]?.find((sch) => sch?.type === 'DRIVE_IN') || {};
  const { startTime: startTimeTomorrow } =
    schedule[tomorrowString]?.find((sch) => sch?.type === 'DRIVE_IN') || {};
  const isOpen = Boolean(
    startTimeToday &&
      endTimeToday &&
      compareIsOpen(clock, startTimeToday, endTimeToday)
  );

  return {
    isOpen,
    closingTime:
      endTimeToday === '00:00' && startTimeTomorrow === '00:00'
        ? ''
        : endTimeToday || '',
    openTime: isOpen ? '' : getNextOpenTime(schedule, date, country),
  };
};

export const getSchedule = (
  schedule: Dictionary<Maybe<TimeSlotsProps>[]>,
  type: 'DRIVE_IN' | 'DRIVE_OUT',
  dateString: WeekDays = 'MONDAY'
) => {
  const { startTime, endTime } =
    schedule[dateString]?.find((sch) => sch?.type === type) || {};
  if (!startTime || !endTime) {
    return i18n.t('time.closed');
  }
  if (startTime === '00:00' && endTime === '00:00') {
    return '24h';
  }
  return `${removeSecondsFromTimeString(
    startTime
  )} - ${removeSecondsFromTimeString(endTime)}`;
};

/**
 * This function will parse next opening time from schedule
 * from days today or tomorrow and return translated opening time if opens today or tomorrow. If false, return empty string
 * @param  {Dictionary<Maybe<TimeSlotsProps>[]>} schedule contains dictionary type schedule of opening and closing times
 * @param  {Date} date given date and time. default current date and time.
 * @return {string} either empty or string or translated opening time in hours and mins
 */

export const getNextOpenTime = (
  schedule: Dictionary<Maybe<TimeSlotsProps>[]>,
  date: Date = new Date(),
  country: CountryCode = 'FI'
) => {
  const clock = getTimeFromDate(date, country);
  const todayString = getWeekDayString(date);
  const tomorrowString = getWeekDayString(addDays(date, 1));
  const { startTime, endTime } =
    schedule[todayString]?.find((sch) => sch?.type === 'DRIVE_IN') || {};
  const { startTime: startTimeTomorrow } =
    schedule[tomorrowString]?.find((sch) => sch?.type === 'DRIVE_IN') || {};
  if (startTime && clock < startTime) {
    const minutesTillOpen = differenceInMinutes(
      new Date(
        2022,
        1,
        1,
        parseInt(startTime.slice(0, 2), 10),
        parseInt(startTime.slice(3, 5), 10)
      ),
      new Date(
        2022,
        1,
        1,
        parseInt(clock.slice(0, 2), 10),
        parseInt(clock.slice(3, 5), 10)
      )
    );
    return i18n.t('parking.opensIn', {
      in: minutesToHoursAndMinutes(minutesTillOpen),
    });
  }
  if ((endTime && clock > endTime) || startTimeTomorrow) {
    const minutesTillOpen = differenceInMinutes(
      new Date(
        2022,
        1,
        2,
        parseInt((startTimeTomorrow || '').slice(0, 2), 10),
        parseInt((startTimeTomorrow || '').slice(3, 5), 10)
      ),
      new Date(
        2022,
        1,
        1,
        parseInt(clock.slice(0, 2), 10),
        parseInt(clock.slice(3, 5), 10)
      )
    );
    return i18n.t('parking.opensIn', {
      in: minutesToHoursAndMinutes(minutesTillOpen),
    });
  }
  return '';
};

/**
 * Get opening and closing times as string
 * @param  {<Maybe<TimeSlotsProps>[]>} schedule schedule of opening and closing times
 * @param  {Date} date given date and time. default current date and time.
 * @returns {string, string} Opening and closing time as HH:mm
 */
export const getOpenAndClosingTime = (
  schedule?: Maybe<Maybe<TimeSlotsProps>[]>,
  date?: Date
) => {
  if (!schedule) {
    return {};
  }
  const weekDay = getWeekDayString(date);
  const driveInObj = schedule.find(
    (timeSlots) =>
      timeSlots?.type === 'DRIVE_IN' && timeSlots?.weekDay === weekDay
  );
  const { startTime, endTime } = driveInObj || {};
  return { startTime, endTime };
};

/**
 * Returns date based on HH:mm string and date
 * @param {string} textString Time as HH:mm
 * @param {date }date date
 * @returns {date }date based on HH:mm string and date
 */
export const getDateFromTextString = (textString: string, date: Date) => {
  const getDaysAndMinutes = (time: string) => {
    const hoursAndMins = time.split(':');
    return {
      hours: Number(hoursAndMins[0]),
      minutes: Number(hoursAndMins[1]),
    };
  };

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    getDaysAndMinutes(textString).hours,
    getDaysAndMinutes(textString).minutes
  );
};

export const getRemainingDays = (
  endDate: Date,
  startDate: Date = new Date()
): number => {
  return differenceInDays(endDate, startDate);
};

export const handlePrefixedStartorEndtime = (
  timeString: string,
  date: Date | null = null
): Date => {
  const splittedTime = timeString.split(':');
  const isNewDateOrGiven = date ? date : new Date();
  return new Date(
    isNewDateOrGiven.setHours(
      Number(splittedTime[0]),
      Number(splittedTime[1]),
      Number(splittedTime[2]),
      0
    )
  );
};

export const removeSecondsFromTimeString = (timeText: string) => {
  return timeText.slice(0, 5) || '';
};

export const parseISO8601Duration = (duration: string) => {
  if (!duration) {
    return null;
  }
  try {
    const { hours } = parse(duration);
    if (hours) {
      return Math.round(hours / 24);
    }
    return null;
  } catch {
    return null;
  }
};
