import config from 'config/config';
import AuthManager from 'components/auth/AuthManager';

const useHandleAuthentication = () => {
  const handleAuthMethod = async (useEmailVerification: boolean) => {
    if (config.isE2ETest === 'true') {
      // For e2e tests we want to skip email verification
      return await AuthManager.suSiAsync(false);
    }
    // For normal execution we want to use the email verification feature flag
    // to determine if we should use the email verification flow or not
    return await AuthManager.suSiAsync(useEmailVerification);
  };

  return {
    handleAuthMethod,
  };
};

export default useHandleAuthentication;
