import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, theme } from 'aimo-ui';
import { vehicleChargerPlugTypes } from 'utils/vehicleUtils';
import Icon from 'components/icons/Icon';
import { getConnectorIcon } from 'utils/chargingUtils';
import { EvseConnectorType } from 'types/generatedSchemaTypes';
import {
  CheckIcon,
  DropdownIcon,
  EmptyIcon,
  VEHICLE_INPUT_ICON_SIZE,
} from 'components/account/vehicle/VehicleIcons';

type VehicleChargerPlugTypeSelectorProps = {
  selectedChargerPlugType?: string | null;
  setSelectedChargerPlugType: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
};

type IconComponentProps = {
  vehicleChargerPlugType: EvseConnectorType;
};

const IconComponent = ({ vehicleChargerPlugType }: IconComponentProps) => {
  const iconName = getConnectorIcon(vehicleChargerPlugType);
  if (iconName === '') {
    return <EmptyIcon />;
  }
  return (
    <Icon
      name={getConnectorIcon(vehicleChargerPlugType)}
      size={VEHICLE_INPUT_ICON_SIZE}
      strokeColor={theme.colors.black}
      color={theme.colors.white}
      testID={`${vehicleChargerPlugType}-icon`}
    />
  );
};

const VehicleChargerPlugTypeSelector = ({
  selectedChargerPlugType,
  setSelectedChargerPlugType,
}: VehicleChargerPlugTypeSelectorProps) => {
  const { t } = useTranslation();
  const vehicleChargerPlugTypesDropdownItems = useMemo(
    () =>
      vehicleChargerPlugTypes
        .map((vehicleChargerPlugType) => {
          return {
            label: t(
              `evCharging.connectorSupportedTypes.${vehicleChargerPlugType}`
            ),
            value: vehicleChargerPlugType,
            icon: () => IconComponent({ vehicleChargerPlugType }),
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    [t]
  );

  const selectedItem = useMemo(
    () =>
      vehicleChargerPlugTypesDropdownItems.find(
        (item) => item.value === selectedChargerPlugType
      ),
    [selectedChargerPlugType, vehicleChargerPlugTypesDropdownItems]
  );

  return (
    <Dropdown
      items={vehicleChargerPlugTypesDropdownItems}
      defaultItem={selectedItem}
      onSelect={(item) => setSelectedChargerPlugType(item.value)}
      placeholder={t('vehicle.form.chargerPlugType.label')}
      dropdownIcon={DropdownIcon}
      checkIcon={CheckIcon}
    />
  );
};

export default React.memo(VehicleChargerPlugTypeSelector);
