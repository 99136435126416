import AccountContext from 'context/AccountContext';
import { useContext } from 'react';

const useHasVehicleAndCreditCard = () => {
  const {
    customer: { paymentCards, vehicles },
  } = useContext(AccountContext);
  return !!paymentCards?.[0] && !!vehicles?.[0];
};

export default useHasVehicleAndCreditCard;
