import {
  ChargingSession,
  Maybe,
  ParkingSession,
} from 'types/generatedSchemaTypes';
import {
  ongoingChargingSheetExpanded,
  ongoingParkingSheetExpanded,
  sheetModal,
} from 'states/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { RootNavProps } from 'types/navigation';
import BlockerMenuItem from './BlockerMenuItem';
import { Text } from 'react-native';
import {BaseText, Headline3} from 'aimo-ui';

const OngoingSessionBlocker = ({
  parkingSession,
  chargingSession,
}: {
  parkingSession?: Maybe<ParkingSession>;
  chargingSession?: Maybe<ChargingSession>;
}) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<RootNavProps>();

  const goParking = () => {
    sheetModal('');
    navigate('mainTabs', { screen: 'parkScreen' });
    ongoingParkingSheetExpanded(true);
  };
  const goCharging = () => {
    sheetModal('');
    navigate('mainTabs', { screen: 'parkScreen' });
    ongoingChargingSheetExpanded(true);
  };

  const ParkingTextComponent = (
    <Text>
      <BaseText>{t('account.remove.sessionFront')}</BaseText>
      <Headline3>{t('account.remove.parkingMiddle')}</Headline3>
      <BaseText>{t('account.remove.sessionTail')}</BaseText>
    </Text>
  );

  const ChargingTextComponent = (
    <Text>
      <BaseText>{t('account.remove.sessionFront')}</BaseText>
      <Headline3>{t('account.remove.chargingMiddle')}</Headline3>
      <BaseText>{t('account.remove.sessionTail')}</BaseText>
    </Text>
  );

  return (
    <>
      {parkingSession && (
        <BlockerMenuItem
          text={ParkingTextComponent}
          testID="account-remove-reason-parking"
          onPress={goParking}
        />
      )}
      {chargingSession && (
        <BlockerMenuItem
          text={ChargingTextComponent}
          testID="account-remove-reason-charging"
          onPress={goCharging}
        />
      )}
    </>
  );
};

export default OngoingSessionBlocker;
