import { useReactiveVar } from '@apollo/client';
import ApproveTermsAgain from '@components/approveTerms/ApproveTermsAgain';
import { useNavigation } from '@react-navigation/native';
import {
  BottomButtonsLayout,
  Button,
  Headline2,
  Spinner,
  theme,
  Title,
} from 'aimo-ui';
import AccountContext from 'context/AccountContext';
import useCreateAcceptanceMutation from 'hooks/useCreateAcceptanceMutation';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleRowItem } from 'screens/account/ViewTerms';
import { sheetModal } from 'states/common';
import { featureCountryCode } from 'states/persistInStorage';
import { Divider } from 'styles/CommonLayoutStyles';
import {
  CenterFullScreen,
  ColumnWithMargins,
  ScreenContainer,
  ScreenWrapper,
  SimpleFullContainer,
  StyledImage,
  StyledWrapper,
} from 'styles/ContainerStyles';
import tinycolor from 'tinycolor2';
import { RootNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';

const contentContainerStyle = { flexGrow: 1 };

const Container = isWeb ? ScreenContainer : SimpleFullContainer;

/**
 * Screen for approving updated terms and conditions when a new version has been released
 */
const ApproveUpdatedTermsScreen: React.FC = () => {
  const { t } = useTranslation();
  const { addListener, canGoBack, navigate, goBack } =
    useNavigation<RootNavProps>();
  const {
    customer: { countryCode: customerCountryCode, acceptances },
  } = useContext(AccountContext);
  const hasAcceptedMarketing = useMemo(
    () =>
      acceptances?.find(
        (acceptance) =>
          acceptance?.type === 'MARKETING_ALLOWED' &&
          acceptance?.countryCode === customerCountryCode &&
          acceptance?.accepted
      ),
    [acceptances, customerCountryCode]
  );
  const dividerColor = tinycolor(theme.colors.gray200).lighten(35).toString();
  const [approvedToggle, setApprovedToggle] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [userHasApproved, setUserHasApproved] = useState(false);
  const { createAcceptance, createAcceptances, loading } =
    useCreateAcceptanceMutation();
  const featureCountry = useReactiveVar(featureCountryCode);

  const handleNavigate = () => {
    if (canGoBack()) {
      goBack();
    } else {
      navigate('mainTabs', { screen: 'parkScreen' });
    }
  };

  const handleContinue = async () => {
    setUserHasApproved(true);
    if (hasAcceptedMarketing) {
      // If user has accepted the marketing consent before, we only need to accept the terms
      await createAcceptance(
        true,
        'TERMS_ACCEPTED',
        featureCountry ?? customerCountryCode,
        true,
        true,
        handleNavigate
      );
    } else {
      // If user has not accepted the marketing consent before, we need to accept both terms and marketing consent
      await createAcceptances(marketingConsent, true, true, handleNavigate);
    }
  };

  useEffect(() => {
    const unsubscribe = addListener('beforeRemove', (e) => {
      // Prevent default behavior of leaving the screen if user has not approved terms
      if (!userHasApproved) {
        e.preventDefault();
      }
    });
    return () => {
      unsubscribe();
    };
  }, [addListener, userHasApproved]);

  useEffect(() => {
    sheetModal('');
  }, []);

  return (
    <Container>
      {loading ? (
        <CenterFullScreen>
          <Spinner />
        </CenterFullScreen>
      ) : (
        <ScreenWrapper
          scrollEnabled={false}
          contentContainerStyle={contentContainerStyle}
          tabBarHeight={10}>
          <BottomButtonsLayout
            scrollable={true}
            contentBottom={
              <StyledWrapper center mb={0} maxWidth={450}>
                <Button
                  light
                  disabled={!approvedToggle || loading}
                  size="l"
                  text={t('button.continue')}
                  onPress={handleContinue}
                  loading={loading}
                />
              </StyledWrapper>
            }>
            <CenterFullScreen>
              <ColumnWithMargins mt={theme.normalising.heightPixel(70)}>
                <StyledImage
                  testID="approve-updated-terms-image"
                  width={98}
                  height={164}
                  center
                  source={require('assets/images/contact-us.png')}
                />
              </ColumnWithMargins>
              <StyledWrapper>
                <Title>{t('account.terms.termsUpdated')}</Title>
              </StyledWrapper>
              <Headline2 center>{t('account.terms.approveAgain')}</Headline2>
              <Divider marginVertical={20} color={dividerColor} />
              <ApproveTermsAgain
                approvedToggle={approvedToggle}
                setApprovedToggle={setApprovedToggle}
                type="Updated"
              />
              <Divider marginVertical={20} color={dividerColor} />
              {!hasAcceptedMarketing && (
                <ToggleRowItem
                  title="account.terms.marketingConsentOptional"
                  info="account.terms.marketingConsentOptionalInfo"
                  value={marketingConsent}
                  setValue={setMarketingConsent}
                  separator={false}
                  testID="marketing-consent-toggle"
                  isDarkToggle={true}
                />
              )}
            </CenterFullScreen>
          </BottomButtonsLayout>
        </ScreenWrapper>
      )}
    </Container>
  );
};

export default ApproveUpdatedTermsScreen;
