import MenuItem from 'components/common/MenuItem';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ParkingSummaryHeadline = () => {
  const { t } = useTranslation();
  return (
    <>
      <MenuItem
        menuLabel={t('parking.parkingProcess.howLongStayingHere')}
        paddingVertical={1}
        testID="parkzone-details-commercial-zone"
        noBorder
        bold
      />
    </>
  );
};

export default ParkingSummaryHeadline;
