import React from 'react';
import styled from 'styled-components/native';
import { Meta, theme, Button } from 'aimo-ui';
import { NotificationContainer } from '@styles/ContainerStyles';

const TextWrapper = styled.View`
  flex: 1 1 auto;
  margin-right: 10px;
`;

const ButtonWrapper = styled.View``;
export const NotificationGray = ({
  label,
  buttonText,
  action,
  text,
}: {
  label?: string;
  text?: string;
  buttonText?: string;
  action?: () => void;
}) => {
  return (
    <>
      {label && <Meta color={theme.colors.gray200}>{label}</Meta>}
      <NotificationContainer>
        <TextWrapper>
          <Meta>{text}</Meta>
        </TextWrapper>
        <ButtonWrapper>
          {action && buttonText && (
            <Button
              size="m"
              fullWidth={false}
              buttonType="primary"
              text={buttonText}
              onPress={action}
            />
          )}
        </ButtonWrapper>
      </NotificationContainer>
    </>
  );
};
