import { useReactiveVar } from '@apollo/client';
import { NotificationGray } from '@components/common/Notifications';
import { useNavigation } from '@react-navigation/native';
import { BaseText, Meta, Spinner, theme } from 'aimo-ui';
import FlatListCustom from 'components/sheets/FlatListCustom';
import AccountContext from 'context/AccountContext';
import useCustomBottomSheet from 'hooks/useCustomBottomSheet';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useParkZonesByDistance from 'hooks/useParkZonesByDistance';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LatLng } from 'react-native-maps';
import {
  accountRestricted,
  currentCustomerCountry,
  isAppStartupScreenOpen,
  isApproveTermsOpen,
  locationModalShown,
  selectedParkScreenQuickAccessItem,
  sheetModal,
} from 'states/common';
import { userLocation } from 'states/map';
import { featureCountryCode } from 'states/persistInStorage';
import { Divider } from 'styles/CommonLayoutStyles';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { ParkZone } from 'types/generatedSchemaTypes';
import {
  checkLocationPermissions,
  getDistanceFromLatLonInMeters,
} from 'utils/mapUtils';
import { isOnboardingOpen } from 'utils/navigationUtils';
import {
  getZonesWithVisibility,
  parkMapParkzoneFilter,
  quickMenuSort,
  sortInMainMapViewByZoneAndBenefit,
} from 'utils/parkUtils';
import SearchItem from './SearchItem';
import { Center, Container, MarginView } from './styles/styles';
import { testIdentifiers } from 'utils/testIdentifiers';

type ParkingZoneListProps = {
  testID?: string;
  withLTP?: boolean;
};

const ParkingZonesByDistance = ({
  testID,
  withLTP = false,
}: ParkingZoneListProps) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const currentLocation = useReactiveVar(userLocation);
  const appCountry = useReactiveVar(featureCountryCode);
  const { getParkZones, parkZones, getLTPParkZones, parkZonesLTP } =
    useParkZonesByDistance();
  const {
    customer: { householdBenefits },
  } = useContext(AccountContext);
  const { snapToIndex, animatedIndex } = useCustomBottomSheet();
  const [hasSnapped, setHasSnapped] = useState(false);
  const { isLoggedIn } = useIsLoggedIn();
  const modalShown = useReactiveVar(locationModalShown);
  const updateScreenOpen = useReactiveVar(isAppStartupScreenOpen);
  const currentAppCountry = useReactiveVar(currentCustomerCountry);
  const isAccountRestricted = useReactiveVar(accountRestricted);
  const previousLocation = React.useRef<LatLng | null>(null);
  const distanceChanged =
    !previousLocation.current ||
    (previousLocation.current &&
      currentLocation &&
      getDistanceFromLatLonInMeters(currentLocation, previousLocation.current) >
        100);
  const selectedQuickAccessItem = useReactiveVar(
    selectedParkScreenQuickAccessItem
  );

  if (distanceChanged) {
    previousLocation.current = currentLocation;
  }

  const results = useMemo(() => {
    const parkZonesData = parkZones?.data?.parkZonesByDistance?.content || [];
    const pmcsByDistance =
      parkZonesLTP?.data?.readCustomerPmcsOrderByDistanceV2;

    const parkZonesLTPData =
      getZonesWithVisibility(
        (pmcsByDistance?.content
          ?.filter((pmc1) =>
            pmc1?.parkZone?.serviceChannelCodes?.includes(
              process.env.SERVICE_CHANNEL_CODE as string
            )
          )
          .map((pmc) => pmc.parkZone) ?? []) as ParkZone[],
        pmcsByDistance?.pmcVisibilities,
        householdBenefits,
        currentLocation,
        appCountry
      ) || [];

    const filteredParkzones = parkMapParkzoneFilter(
      parkZonesData as ParkZone[],
      householdBenefits
    );

    return withLTP
      ? parkZonesLTPData
      : [...filteredParkzones]
          .sort((a, b) =>
            quickMenuSort(
              a,
              b,
              selectedQuickAccessItem,
              currentLocation,
              withLTP
            )
          )
          .sort((a, b) => {
            return sortInMainMapViewByZoneAndBenefit(a, b, householdBenefits);
          });
  }, [
    parkZones?.data?.parkZonesByDistance?.content,
    parkZonesLTP?.data?.readCustomerPmcsOrderByDistanceV2,
    selectedQuickAccessItem,
    withLTP,
    currentLocation,
    householdBenefits,
    appCountry,
  ]);

  const updateCurrentCountryCode = useCallback((zones: ParkZone[]) => {
    const { countryCode } = zones[0] || {};
    if (countryCode) {
      currentCustomerCountry(countryCode);
    }
    return countryCode;
  }, []);
  const checkAppCountry = useCallback(
    (zones: ParkZone[]) => {
      const currentCountry = updateCurrentCountryCode(zones);
      if (
        currentCountry &&
        currentCountry !== appCountry &&
        !modalShown &&
        !isOnboardingOpen(navigation) &&
        !isAccountRestricted &&
        !isApproveTermsOpen()
      ) {
        sheetModal('parkingAppLocation');
        locationModalShown(true);
      }
    },
    [
      updateCurrentCountryCode,
      appCountry,
      modalShown,
      navigation,
      isAccountRestricted,
    ]
  );

  useEffect(() => {
    if (isLoggedIn && !updateScreenOpen) {
      const timer = setTimeout(() => {
        checkAppCountry(results as ParkZone[]);
      }, 500);
      return () => clearTimeout(timer);
    } else if (!currentAppCountry) {
      updateCurrentCountryCode(results as ParkZone[]);
    }
  }, [
    results,
    isLoggedIn,
    updateScreenOpen,
    checkAppCountry,
    currentAppCountry,
    updateCurrentCountryCode,
  ]);

  useEffect(() => {
    if (!hasSnapped && animatedIndex.value === 0 && !withLTP) {
      // Make sure ParkingWizardSheet have time to switch to correct currentScreen before snap
      setTimeout(() => {
        snapToIndex(1);
      }, 500);
      setHasSnapped(true);
    }
  }, [snapToIndex, animatedIndex.value, hasSnapped, withLTP]);

  useEffect(() => {
    if (distanceChanged && currentLocation) {
      if (withLTP) {
        getLTPParkZones({
          variables: {
            input: {
              permitType: 'SALES_ORDER',
              latitude: currentLocation.latitude,
              longitude: currentLocation.longitude,
            },
          },
        });
      } else {
        getParkZones({
          variables: {
            coordinates: currentLocation,
            size: 10,
          },
        });
      }
    }
  }, [
    withLTP,
    currentLocation,
    getLTPParkZones,
    getParkZones,
    distanceChanged,
  ]);

  const error = withLTP ? parkZonesLTP?.error : parkZones?.error;
  const loading = withLTP ? parkZonesLTP?.loading : parkZones?.loading;

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (error && !parkZones) {
    return (
      <Container testID={testID}>
        <Center>
          <Meta>{t('parkingZone.errorInLoading')}</Meta>
        </Center>
      </Container>
    );
  }

  if (!loading && !currentLocation) {
    return (
      <Container testID={testID}>
        <NotificationGray
          label={t('parkingZone.enableLocationServices')}
          action={checkLocationPermissions}
          text={t('parkingZone.seeWhereYouAreOnTheMap')}
          buttonText={t('button.enable')}
        />
      </Container>
    );
  }

  return (
    <Container testID={testID}>
      <ColumnWithMargins mb={16}>
        <Meta
          {...testIdentifiers(`${testID}-subtitle`)}
          color={theme.colors.gray200}>
          {t(
            withLTP
              ? 'parkingZone.closestLTPsToYou'
              : 'parkingZone.closestToYou'
          )}
        </Meta>
      </ColumnWithMargins>

      {results &&
        (results.length > 0 ? (
          <FlatListCustom
            ItemSeparatorComponent={() => <Divider marginVertical={10} />}
            data={results as ParkZone[]}
            removeClippedSubviews={false}
            {...testIdentifiers(`${testID}-flatlist`)}
            renderItem={({ item }) => (
              <SearchItem
                item={item}
                currentLocation={currentLocation}
                withLTP={withLTP}
              />
            )}
            ListFooterComponent={() => <MarginView />}
            keyExtractor={(item, index: number) => `${item?.uid}-${index}`}
          />
        ) : (
          <Center>
            <BaseText>{t('parkingZone.noNearbyFound')}</BaseText>
          </Center>
        ))}
    </Container>
  );
};

export default ParkingZonesByDistance;
