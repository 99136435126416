import { gql } from '@apollo/client';

export const READ_RESUMABLE_PARKING_SESSION = gql`
  query ReadResumableParkingSession($input: ResumableParkingSessionInput!) {
    readResumableParkingSession(input: $input) {
      resumableSession {
        resumeAllowedUntil
        sessionId
        startDate
      }
    }
  }
`;
