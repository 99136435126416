import { BaseText, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import { getLanguage } from 'i18n/init';
import { sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { logCustomEvent } from 'utils/analytics';

const Chip = styled.TouchableOpacity`
  background-color: ${theme.colors.white};
  padding: 8px 12px;
  border-radius: 40px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

const LanguageButton = () => {
  const currentLanguage = getLanguage();

  const openLanguageModal = async () => {
    await logCustomEvent('language_selector_open', {
      currentLanguage: currentLanguage,
    });
    sheetModal('language');
  };

  return (
    <Chip onPress={openLanguageModal}>
      <Icon name={'Globe-Outlined'} />
      <BaseText>{currentLanguage.toUpperCase()}</BaseText>
      <Icon name={'Chevron_Down'} />
    </Chip>
  );
};

export default LanguageButton;
