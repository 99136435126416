import { Headline3, Meta, theme } from 'aimo-ui';
import ParkAndChargeTariffItem from 'components/park/tariff/ParkAndChargeTariffItem';
import SheetLayout from 'components/sheets/SheetLayout';
import ParkingContext from 'context/ParkingContext';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  activeElementType,
  selectedChargingPricingInfo,
  selectedChargingPricingInfoSegment,
  selectedChargingPricingInfoSegmentCountryCode,
  selectedChargingPricingInfoSegmentNameBasedOnNotes,
  selectedTariffId,
  selectedTariffName,
  sheetModal,
} from 'states/common';
import { mapCoordinates } from 'states/map';
import styled from 'styled-components/native';
import { Divider } from 'styles/CommonLayoutStyles';
import { ParkingZoneType } from 'types/common';
import {
  ChargingStation,
  PricingProps,
  SegmentProps,
} from 'types/generatedSchemaTypes';
import { handleCloseOngoingParkingStartChargingSheet } from 'utils/chargingUtils';
import { makePoint } from 'utils/mapUtils';
import { OneHourParkingPriceEstimate } from '../OneHourParkingPriceEstimate';
import { Row } from '../styles/styles';
import useEVChargingPricing from 'hooks/useEVChargingPricing';

const TitleWrapper = styled.View`
  margin-top: 15px;
  margin-bottom: 20px;
`;

export const SelectTariff: React.FC = () => {
  const { t } = useTranslation();

  const { parkingZoneResult: parkingZone, setCurrentScreen } =
    useContext(ParkingContext);

  const {
    name = '',
    address,
    uid = '',
    commercialZoneView,
    parkingZoneChargingStations,
  } = parkingZone?.data?.parkZone || {};
  const countryCode = parkingZone?.data?.parkZone?.countryCode || 'FI';

  const { EVChargingPricings } = useEVChargingPricing({
    chargingStations: (parkingZoneChargingStations || []) as ChargingStation[],
  });

  const { marker } = address || {};

  const markerCoordinate = marker && makePoint(marker?.coordinates);

  const handleNamePress = () => {
    if (markerCoordinate) {
      mapCoordinates(markerCoordinate);
    }
  };

  const handlePricePress = () => {
    sheetModal('parkingRates');
  };

  const handleSetTariff = (
    tariffId: string,
    tariffName: string,
    type: ParkingZoneType,
    chargingTariff?: SegmentProps,
    chargingPricingInfo?: PricingProps
  ) => {
    selectedTariffId(tariffId);
    selectedTariffName(tariffName);
    selectedChargingPricingInfo(chargingPricingInfo);
    selectedChargingPricingInfoSegment(chargingTariff);
    selectedChargingPricingInfoSegmentCountryCode(countryCode);
    activeElementType(type);
    setCurrentScreen('SELECT_CHARGER');
  };

  const handleClose = () => {
    handleCloseOngoingParkingStartChargingSheet();
    setCurrentScreen('SEARCH');
  };

  // Preselect tariff for RatesModalContent to show correct prices
  // Also if coming from another zone with different charging tariff this should reset it
  useEffect(() => {
    if (EVChargingPricings) {
      const segment = [
        EVChargingPricings[0].defaultSegment,
        ...(EVChargingPricings[0].customerSegments || []),
      ][0];
      selectedTariffId(segment?.tariffPlanId);
      selectedChargingPricingInfoSegment(segment || undefined);
      selectedChargingPricingInfoSegmentNameBasedOnNotes(
        segment?.notes?.map((note) => note?.text).join(' ') ||
          segment?.title ||
          ''
      );
    }
  }, [EVChargingPricings]);

  return (
    <>
      <SheetLayout
        onHeadlinePress={handleNamePress}
        testIDName="select-charger-layout"
        withBackButton={true}
        onBackPress={handleClose}
        onHeadlineButtonPress={handleClose}
        headlineComponent={
          <Headline3
            center
            color={theme.colors.link}
            testID="parkzone-commercial-zone-code">
            {commercialZoneView?.zoneCode ?? ''}{' '}
            <Headline3 color={theme.colors.black}>{name}</Headline3>
          </Headline3>
        }>
        <OneHourParkingPriceEstimate
          parkingZoneUid={uid}
          parkingZoneCountryCode={countryCode}
          withLinkText="price.rates"
          onPricePress={handlePricePress}
        />
        <Row mb={4} mt={0}>
          <Divider marginVertical={0} />
        </Row>
        <>
          <TitleWrapper>
            <Meta color={theme.colors.gray200}>
              {t('parking.tariff.selectTariff')}
            </Meta>
          </TitleWrapper>
          {EVChargingPricings?.map((EVChargingPricing) => {
            const segments = [
              EVChargingPricing.defaultSegment,
              ...(EVChargingPricing?.customerSegments || []),
            ];
            return segments.map((segment) => (
              <ParkAndChargeTariffItem
                segment={segment}
                countryCode={countryCode}
                isSelected={false}
                key={segment?.tariffPlanId}
                onPress={(tariffId, tariffName, type) =>
                  handleSetTariff(
                    tariffId,
                    tariffName,
                    type,
                    segment as SegmentProps,
                    EVChargingPricing
                  )
                }
              />
            ));
          })}
        </>
      </SheetLayout>
    </>
  );
};

export default SelectTariff;
