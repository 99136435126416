import { Card, CompanyBenefit, Maybe } from 'types/generatedSchemaTypes';
import CardItem from 'components/account/paymentMethods/CardItem';
import CompanyBenefitItem from 'components/account/paymentMethods/CompanyBenefitItem';
import styled from 'styled-components/native';

const TitleWrapper = styled.View`
  flex-direction: row;
`;

//"Abstract" class to choose corresponding Item with correct data
const PaymentMethodItem = ({
  item,
  indexValue,
}: {
  item: Maybe<Card> | Maybe<CompanyBenefit>;
  indexValue: number;
}) => {
  switch (item?.__typename){
    case 'Card':
      return CardItem({item, indexValue});
    case 'CompanyBenefit':
      return CompanyBenefitItem({item, indexValue});
    default:
      return null;
  }
};

export default PaymentMethodItem;
export { TitleWrapper };
