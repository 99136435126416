import { useNavigation } from '@react-navigation/native';
import { Button, Meta, theme } from 'aimo-ui';
import { LogoutUri } from 'components/auth/AuthConfig';
import AuthManager from 'components/auth/AuthManager';
import AccountContext from 'context/AccountContext';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import useHandleLogout from 'hooks/useHandleLogout';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WebView, { WebViewNavigation } from 'react-native-webview';
import { sheetModal } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import { AccountScreenNavigationProp } from 'types/navigation';
import { isOnlyAimoAppCustomer } from 'utils/customerUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const AccountRemovalSuccess = () => {
  const { t } = useTranslation();
  const { handleLogout, showLogout, loading } = useHandleLogout(false);
  const { navigate } = useNavigation<AccountScreenNavigationProp>();
  const { customer } = useContext(AccountContext);
  const [isAimoAppCustomer, setIsAimoAppCustomer] = useState(false);

  const handleState = async ({ loading: loadingScreen }: WebViewNavigation) => {
    if (!loadingScreen) {
      await AuthManager.signOutAsync();
      sheetModal('');
    }
  };

  const { clearRedirectStack } = useAfterAuthenticationRedirect();
  const onPress = async () => {
    navigate('mainTabs', { screen: 'accountScreen' });
    await handleLogout();
    clearRedirectStack();
  };

  useEffect(() => {
    // This code will run only once, on the initial render
    setIsAimoAppCustomer(isOnlyAimoAppCustomer(customer));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledWrapper mb={0}>
      <StyledWrapper center>
        <Meta
          center
          {...testIdentifiers('remove-success-text')}
          color={theme.colors.gray200}>
          {isAimoAppCustomer
            ? t('account.removeSuccessText')
            : t('account.removeSuccessTextOtherServices')}
        </Meta>
        {showLogout && (
          <>
            <WebView
              testID="logout-webview"
              source={{ uri: LogoutUri }}
              onNavigationStateChange={handleState}
            />
          </>
        )}
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <Button
          testID="close-button"
          size="l"
          text={t('button.close')}
          loading={loading}
          onPress={onPress}
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default AccountRemovalSuccess;
