import useHslWizard from 'hooks/useHslWizard';
import React, { ReactNode } from 'react';
import HslContext from './HslContext';

const HslContextProvider = ({ children }: { children: ReactNode }) => {
  const wizard = useHslWizard();
  return <HslContext.Provider value={wizard}>{children}</HslContext.Provider>;
};

export default HslContextProvider;
