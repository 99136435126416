import { gql } from '@apollo/client';

export const GET_PRICING_INFO = gql`
  query pricingInfo($parkingZoneUid: String!, $lang: Lang) {
    pricingInfo(parkingZoneUid: $parkingZoneUid, lang: $lang) {
      pricingSchemeId
      countryCode
      pricingEngine
      defaultSegment {
        uid
        parkingAllowed
        parkingAllowedFrom
        parkingAllowedUntil
        notes {
          text
        }
        accessType
        accessMethod {
          instructions {
            en
            fi
            sv
            no
          }
          type
          url
        }
        predefinedTickets {
          sessionType
          uid
          type
          name
          startTime
          endTime
          duration
          price
          serviceFee
        }
        title
        showDefaultChips
        predefinedTicketsOnly
      }
      customerSegments {
        uid
        parkingAllowed
        parkingAllowedFrom
        parkingAllowedUntil
        notes {
          text
        }
        accessType
        accessMethod {
          instructions {
            en
            fi
            sv
            no
          }
          type
          url
        }
        predefinedTickets {
          sessionType
          uid
          type
          name
          startTime
          endTime
          duration
          price
          serviceFee
        }
        title
        showDefaultChips
        predefinedTicketsOnly
      }
    }
  }
`;
