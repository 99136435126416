import { useReactiveVar } from '@apollo/client';
import { theme } from 'aimo-ui';
import Sheet from 'components/sheets/Sheet';
import LongTermParkingFlowNavigator from 'navigation/LongTermParkingFlowNavigator';
import React from 'react';
import { isFullScreen, longTermParkingCompleted } from 'states/common';
import { isWeb } from 'utils/deviceUtils';

const getSnappingPoints = (fullScreen: boolean) => {
  if (isWeb) {
    return fullScreen ? ['75vh'] : ['60vh'];
  }
  return fullScreen ? ['95%'] : ['60%', '85%'];
};

const ParkDetailsSheet = () => {
  const isFullScreenBottomSheet = useReactiveVar(isFullScreen);
  const isCompleted = useReactiveVar(longTermParkingCompleted);
  const snappingPoints = getSnappingPoints(isFullScreenBottomSheet);
  return (
    <Sheet
      bgColor={isCompleted ? theme.colors.lightBg.green : theme.colors.white}
      showBackDropIndex={isFullScreenBottomSheet ? 0 : 1}
      snappingPoints={snappingPoints}
      hideHandle={isFullScreenBottomSheet}>
      <LongTermParkingFlowNavigator />
    </Sheet>
  );
};

export default ParkDetailsSheet;
