import { theme } from 'aimo-ui';
import DropDownButton from 'components/common/DropDownButton';
import VehicleAvatar from 'components/common/VehicleAvatar';
import ParkingContext from 'context/ParkingContext';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectedLicensePlatesPermitRedeem, sheetModal } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';

interface VehicleSelectDropDownProps {
  isDisabled?: boolean;
  showInfoIconIfNotSelected?: boolean;
  allowMultipleSelection?: boolean;
  handleVehiclesSelect?: () => void;
}

const VehicleSelectDropDown = ({
  isDisabled = false,
  showInfoIconIfNotSelected = false,
  allowMultipleSelection = false,
  handleVehiclesSelect,
}: VehicleSelectDropDownProps) => {
  const { t } = useTranslation();
  const { currentScreen } = useContext(ParkingContext);
  const vehicle = useSelectedParkingVehicle();
  const { isLoggedIn } = useIsLoggedIn();
  const isResume = currentScreen === 'RESUME_PARKING';
  const selectedLicensePlates = selectedLicensePlatesPermitRedeem();

  const handleOnPress = () => {
    if (allowMultipleSelection) {
      handleVehiclesSelect && handleVehiclesSelect();
    } else {
      sheetModal('parkingVehicle');
    }
  };

  const { name, licensePlate, appSettings } = vehicle || {};

  const text = useMemo(() => {
    if (allowMultipleSelection) {
      switch (selectedLicensePlates.length) {
        case 0:
          return t('parking.parkingProcess.selectVehicle');
        case 1:
          return selectedLicensePlates[0].plateText;
        default:
          return t('permit.access.vehicles', {
            count: selectedLicensePlates.length,
          });
      }
    } else {
      return (
        name ||
        licensePlate?.plateText ||
        t('parking.parkingProcess.noVehicleSelected')
      );
    }
  }, [
    allowMultipleSelection,
    licensePlate?.plateText,
    name,
    selectedLicensePlates,
    t,
  ]);

  const showInfoIcon = useMemo(() => {
    if (allowMultipleSelection) {
      return showInfoIconIfNotSelected && selectedLicensePlates.length === 0;
    } else {
      return showInfoIconIfNotSelected && !licensePlate;
    }
  }, [
    allowMultipleSelection,
    licensePlate,
    selectedLicensePlates.length,
    showInfoIconIfNotSelected,
  ]);

  return (
    <StyledWrapper mb={0} maxWidth={theme.normalising.widthPixel(135)}>
      <DropDownButton
        testID="vehicle-select-dropdown"
        disabled={!isLoggedIn || isResume || isDisabled}
        onPress={handleOnPress}
        text={text}
        showInfoIcon={showInfoIcon}
        leftItem={
          !allowMultipleSelection && (
            <VehicleAvatar
              color={appSettings?.color || theme.colors.boost.green}
              icon={appSettings?.icon || ' '}
              size="s"
            />
          )
        }
      />
    </StyledWrapper>
  );
};

export default VehicleSelectDropDown;
