import React, { ReactNode, useState } from 'react';
import { Pmc } from 'types/generatedSchemaTypes';
import LongTermParkingContext from './LongTermParkingContext';

const LongTermParkingProvider = ({ children }: { children: ReactNode }) => {
  const [selectedPmcs, setSelectedPmcs] = useState<Pmc[] | undefined>(
    undefined
  );
  const values = {
    selectedPmcs,
    setSelectedPmcs,
  };
  return (
    <LongTermParkingContext.Provider value={values}>
      {children}
    </LongTermParkingContext.Provider>
  );
};

export default LongTermParkingProvider;
