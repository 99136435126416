import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Button, Meta, theme } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import AccountContext from 'context/AccountContext';
import { addMonths, format, isValid } from 'date-fns';
import { UPDATE_SALES_ORDER } from 'graphql/mutations/updateSalesOrder';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectedPermit, sheetModal, wasPermitTerminated } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import {
  Mutation,
  MutationUpdateSalesOrderArgs,
} from 'types/generatedSchemaTypes';
import { ServicesNavProps } from 'types/navigation';
import { evictFromCache, updateCache } from 'utils/cacheUtils';
import { isSalesOrderWaiting } from 'utils/permitUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import { getRemainingDays } from 'utils/timeUtils';

const PAYMENT_TRANSACTION_THRESHOLD = 3;
const ConfirmCancelPermitModalContent: React.FC = () => {
  const { t } = useTranslation();
  const permitId = useReactiveVar(selectedPermit);
  const {
    customer: { permits },
  } = useContext(AccountContext);
  const { goBack, canGoBack } = useNavigation<ServicesNavProps>();

  const selectedSOrder = permits?.find((sOrder) => sOrder?.id === permitId);

  const { orderLines } = selectedSOrder || {};
  const orderLine = orderLines?.[0];

  const { validity } = orderLine || {};

  const isWaiting = isSalesOrderWaiting(selectedSOrder);

  const validUntil = useMemo(
    () => new Date(addMonths(new Date(validity?.validFrom || ''), 1)),
    [validity?.validFrom]
  );
  const remainingDays = getRemainingDays(validUntil);
  const endDateFormatted = useMemo(() => {
    const date = isValid(validUntil) ? new Date(validUntil) : new Date();
    return format(date, 'd.M.y');
  }, [validUntil]);
  const showAdditionalInfo = remainingDays <= PAYMENT_TRANSACTION_THRESHOLD;

  const [updateSalesOrder, { loading }] = useMutation<
    Mutation,
    MutationUpdateSalesOrderArgs
  >(UPDATE_SALES_ORDER, {
    onCompleted: () => {
      sheetModal('');
    },
    update(cache, { data: updateData }) {
      if (updateData?.updateSalesOrder?.orderStatus === 'EXPIRED') {
        evictFromCache(cache, {
          __typename: 'UnifiedPermit',
          id: permitId,
        });
        if (canGoBack()) {
          goBack();
        }
        showToast(`${t('success.permitCancelled')}`, 'success');
      } else {
        updateCache(
          cache,
          { __typename: 'UnifiedPermit', id: permitId },
          {
            fragment: gql`
              fragment UnifiedPermitCancelFragment on UnifiedPermit {
                renewal
                orderStatus
              }
            `,
            data: {
              renewal: updateData?.updateSalesOrder?.renewal,
              orderStatus: updateData?.updateSalesOrder?.orderStatus,
            },
          }
        );
      }
    },
    onError: () => showToast('error.permitCancelled', 'error'),
  });

  useEffect(() => {
    return () => {
      selectedPermit(undefined);
    };
  }, []);

  const handleConfirmCancel = () => {
    const action = isWaiting ? 'CANCEL' : 'TERMINATE';
    permitId &&
      updateSalesOrder({
        variables: {
          input: {
            salesOrderId: permitId,
            updateType: action,
            renewal: false,
          },
        },
      });
    wasPermitTerminated(action === 'TERMINATE');
  };
  return (
    <StyledWrapper mb={0}>
      <StyledWrapper center mb={theme.normalising.heightPixel(20)}>
        <Meta center color={theme.colors.gray200}>
          {t('services.cancelPermitDescription')}
          {!isWaiting &&
            ` ${t('services.cancelPermitDescriptionRemaining', {
              date: endDateFormatted,
            })}`}
        </Meta>
      </StyledWrapper>
      <StyledWrapper center>
        {showAdditionalInfo && (
          <Meta center color={theme.colors.gray200}>
            {t('services.cancelPermitAdditionalDescription')}
          </Meta>
        )}
      </StyledWrapper>
      <Button
        {...testIdentifiers('parking-history-apply-filter')}
        text={t('button.cancel')}
        size="l"
        buttonType="secondary"
        onPress={handleConfirmCancel}
        disabled={loading}
        loading={loading}
      />
    </StyledWrapper>
  );
};

export default ConfirmCancelPermitModalContent;
