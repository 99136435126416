import Icon from '@components/icons/Icon';
import { IconButton, theme } from 'aimo-ui';
import { ButtonTypes } from 'aimo-ui/lib/typescript/src/components/Button/types';
import React from 'react';
import { ViewProps } from 'react-native-svg/lib/typescript/fabric/utils';
import { testIdentifiers } from 'utils/testIdentifiers';

type CloseButtonProps = {
  onPress: () => void;
  testID?: string;
  buttonType?: ButtonTypes;
  size?: string;
} & ViewProps;

const CloseIcon = ({ color, size }: { color: string; size: string }) => (
  <Icon name="close" size={size} color={color} />
);

const CloseButton = ({
  onPress,
  testID,
  buttonType = 'tertiary',
  size = 's',
  ...props
}: CloseButtonProps) => {
  return (
    <IconButton
      onLayout={props.onLayout}
      fullWidth={false}
      buttonType={buttonType}
      onPress={onPress}
      rounded
      {...testIdentifiers(testID as string)}
      iconComponent={() => (
        <CloseIcon
          size={size}
          color={
            buttonType === 'secondary'
              ? theme.colors.white
              : theme.colors.gray200
          }
        />
      )}
    />
  );
};

export default CloseButton;
