import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItemProps } from 'aimo-ui/lib/typescript/src/components/Dropdown/types';
import { vehicleIcons } from 'utils/vehicleUtils';
import Icon from 'components/icons/Icon';
import { Dropdown, theme } from 'aimo-ui';
import {
  CheckIcon,
  DropdownIcon,
  VEHICLE_INPUT_ICON_SIZE,
} from 'components/account/vehicle/VehicleIcons';

type VehicleIconSelectorProps = {
  selectedIcon: string;
  setSelectedIcon: React.Dispatch<React.SetStateAction<string>>;
};

type IconComponentProps = {
  iconName: string;
};

const IconComponent = ({ iconName }: IconComponentProps) => (
  <Icon
    name={iconName}
    size={VEHICLE_INPUT_ICON_SIZE}
    color={theme.colors.black}
    testID={`${iconName}-icon-button`}
  />
);

const VehicleIconSelector = ({
  selectedIcon,
  setSelectedIcon,
}: VehicleIconSelectorProps) => {
  const { t } = useTranslation();

  const vehicleIconsDropdownItems: DropdownItemProps[] = vehicleIcons
    .map((iconName) => {
      return {
        label: t(`vehicle.form.icon.${iconName}`),
        value: iconName,
        icon: () => IconComponent({ iconName }),
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const selectedItem = useMemo(
    () => vehicleIconsDropdownItems.find((item) => item.value === selectedIcon),
    [selectedIcon, vehicleIconsDropdownItems]
  );

  return (
    <Dropdown
      items={vehicleIconsDropdownItems}
      defaultItem={selectedItem}
      onSelect={(item) => setSelectedIcon(item.value)}
      placeholder={t('vehicle.form.icon.label')}
      dropdownIcon={DropdownIcon}
      checkIcon={CheckIcon}
    />
  );
};

export default React.memo(VehicleIconSelector);
