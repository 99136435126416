import { gql } from '@apollo/client';

export const READ_CHARGING_SESSIONS = gql`
  query readHistoryChargingSessionsV2(
    $input: ReadHistoryChargingSessionsInputV2
  ) {
    readHistoryChargingSessionsV2(input: $input) {
      totalPages
      last
      number
      content {
        startTime
        endTime
        paymentState
        cardId
        parkingZoneUid
        created
        chargingSessionId
        parkZone {
          uid
          name
          address {
            marker
          }
          commercialZoneView {
            name
          }
          locationType
        }
      }
    }
  }
`;
