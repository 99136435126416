import AccountContext from 'context/AccountContext';
import { useContext } from 'react';
import { cardErrorStatuses } from 'utils/paymentUtils';

/**
 * Hook for returning the count of credit cards that are valid
 * @returns {number} of credit cards that are valid
 */
const useCreditCardCount = (): number => {
  const {
    customer: { paymentCards },
  } = useContext(AccountContext);
  return (
    paymentCards?.filter(
      (card) => !cardErrorStatuses.includes(card.status || 'Active')
    ).length ?? 0
  );
};

export default useCreditCardCount;
