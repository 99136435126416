import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Headline3, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import { useNavigation } from '@react-navigation/native';
import { ServicesNavProps } from 'types/navigation';

export const InfoIcon = () => (
  <Icon color={theme.colors.aimoUiRed} name="Info-Filled" />
);

interface RedeemPermitButtonProps {
  permitId: string;
}

const RedeemPermitButton: React.FC<RedeemPermitButtonProps> = ({
  permitId,
}) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<ServicesNavProps>();

  const handleNavigation = () => navigate('permitRedeem', { permitId });

  return (
    <Button
      testID={'redeem-permit-button'}
      buttonType="secondary"
      size="l"
      iconComponent={InfoIcon}
      onPress={handleNavigation}>
      <Headline3 color={theme.colors.white}>
        {t('permit.access.redeemPermit')}
      </Headline3>
    </Button>
  );
};

export default RedeemPermitButton;
