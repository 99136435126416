import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { Headline3, IconButton, Meta, Spinner, theme } from 'aimo-ui';
import { ButtonTypes } from 'aimo-ui/lib/typescript/src/components/Button/types';
import Icon from 'components/icons/Icon';
import { OngoingParkingHeaderStyles } from 'components/ongoingParking/styles';
import { IconWrapper } from 'components/park/styles/styles';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import ParkingContext from 'context/ParkingContext';
import useCustomBottomSheet from 'hooks/useCustomBottomSheet';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated';
import {
  ongoingParkingStartChargingSheetExpanded,
  selectedOngoingSession,
  sheetModal,
} from 'states/common';
import styled from 'styled-components/native';
import { OngoingSessionType } from 'types/common';
import { ParkZone } from 'types/generatedSchemaTypes';
import { handleCloseOngoingParkingStartChargingSheet } from 'utils/chargingUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { toggleOngoingSessionSheet } from 'utils/ongoingSessionUtils';
import { getLocationIconType, locationIcon } from 'utils/parkUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import { timeProgress } from 'utils/timeUtils';

type TimeSliderProps = {
  startTime: Date;
  endTime: Date | null;
  currentTime: Date;
  isExpanded: boolean;
};
const OngoingButton = styled.TouchableOpacity`
  background-color: ${({ theme: sTheme }) => sTheme.colors.gray300};
  width: 40px;
  height: 40px;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
`;

const LoadingBackground = styled.View<{ isHeader?: boolean }>`
  width: ${({ isHeader }) => (isHeader ? 36 : 68)}px;
  height: ${({ isHeader }) => (isHeader ? 36 : 68)}px;
  background-color: ${theme.colors.white};
  align-items: center;
  border-radius: ${({ isHeader }) => (isHeader ? 36 : 68)}px;
  justify-content: center;
`;

const ExtendPressable = styled.TouchableOpacity`
  padding-top: 5px;
`;

const ProgressSlide = styled.View<{
  progress: number;
  isExpanded: boolean;
  overdue?: boolean;
}>`
  width: ${({ progress }) => progress}%;
  height: 6px;
  background-color: ${({ theme: bTheme, overdue }) =>
    overdue ? bTheme.colors.boost.red : bTheme.colors.boost.green};
  border-radius: ${({ isExpanded }) => (isExpanded ? 6 : 0)}px;
`;

const ButtonText = styled(Meta)`
  margin-top: ${theme.normalising.heightPixel(20)}px;
  color: ${({ theme: styledTheme }) => styledTheme.colors.gray100};
`;

const ButtonWrapper = styled.View`
  align-items: center;
`;

export const CollapseButton = ({
  isMultipleSession,
  state,
}: {
  isMultipleSession?: boolean;
  state: ReactiveVar<boolean>;
}) => {
  const { collapse } = useCustomBottomSheet();
  const { isOngoingParking, setCurrentScreen } = useContext(ParkingContext);
  const chargingSheetExp = useReactiveVar(
    ongoingParkingStartChargingSheetExpanded
  );
  const handleClose = () => {
    if (isMultipleSession) {
      selectedOngoingSession('MULTIPLE');
    }
    if (isOngoingParking && chargingSheetExp) {
      handleCloseOngoingParkingStartChargingSheet();
      setCurrentScreen('SEARCH');
    }
    toggleOngoingSessionSheet(state, false);
    collapse();
    Keyboard.dismiss();
  };

  return (
    <OngoingButton testID="collapse-ongoing-parking" onPress={handleClose}>
      <Icon name="Chevron_Down" color={theme.colors.white} />
    </OngoingButton>
  );
};

export const ListButton = () => {
  return (
    <OngoingButton
      testID="list-ongoing-sessions"
      onPress={() => sheetModal('showOngoingMultipleSessions')}>
      <Icon name="List" color={theme.colors.white} />
    </OngoingButton>
  );
};

export const StopButton = ({
  onPress,
  bigger = false,
  testID,
  disabled = false,
}: {
  onPress: () => void;
  bigger?: boolean;
  testID?: string;
  disabled?: boolean;
}) => {
  return (
    <>
      <IconButton
        buttonType="alert"
        onPress={onPress}
        disabled={disabled}
        bigger={bigger}
        {...testIdentifiers(testID ?? 'stop-ongoing-parking')}
        iconComponent={() => (
          <Icon
            name="Stop-Filled"
            size={bigger ? 's' : 'xs'}
            color={theme.colors.white}
          />
        )}
        rounded
        fullWidth={false}
      />
    </>
  );
};

export const ExtendButton = ({
  onPress,
  set = false,
  disabled = false,
}: {
  onPress: () => void;
  disabled?: boolean;
  set?: boolean;
}) => {
  return (
    <ExtendPressable
      onPress={disabled ? undefined : onPress}
      disabled={disabled}
      {...testIdentifiers(`${set ? 'set' : 'extend'}-ongoing-parking`)}>
      <Icon
        name={set ? 'Time-Outlined' : 'plus30'}
        size="xl"
        color={disabled ? theme.colors.gray50 : theme.colors.white}
      />
    </ExtendPressable>
  );
};

export const LoadingSpinner = ({
  isHeader = false,
}: {
  isHeader?: boolean;
}) => {
  return (
    <>
      <LoadingBackground isHeader={isHeader}>
        <Spinner size={isHeader ? 25 : 36} />
      </LoadingBackground>
    </>
  );
};

export const OngoingParkingTimeline: React.FC<TimeSliderProps> = ({
  startTime,
  endTime,
  isExpanded,
  currentTime,
}) => {
  const { animatedIndex } = useCustomBottomSheet();
  const progress = timeProgress(startTime, endTime, currentTime);
  const overdue = progress > 100;

  const containerAnimatedStyle = useAnimatedStyle(() => ({
    marginHorizontal: interpolate(
      animatedIndex.value,
      [0, 1],
      [0, 20],
      Extrapolation.CLAMP
    ),
    borderRadius: interpolate(
      animatedIndex.value,
      [0, 1],
      [0, 6],
      Extrapolation.CLAMP
    ),
  }));

  const containerStyle = useMemo(
    () => [
      OngoingParkingHeaderStyles.ongoingParkingSlide,
      containerAnimatedStyle,
    ],
    [containerAnimatedStyle]
  );

  return (
    <>
      <Animated.View style={containerStyle}>
        <ProgressSlide
          progress={progress}
          isExpanded={isExpanded}
          overdue={overdue}
          testID="parking-progress-slide"
        />
      </Animated.View>
    </>
  );
};

export const HeaderTexts = ({
  vehicleLabel,
  timeLeft,
  isManual = true,
  parkZoneName = '',
  isOverdue = false,
}: {
  vehicleLabel?: string;
  timeLeft: string;
  isManual?: boolean;
  parkZoneName?: string | null;
  isOverdue?: boolean;
}) => {
  const { t } = useTranslation();

  const renderTexts = () => {
    if (isManual && timeLeft && !isOverdue) {
      return t('parking.ongoingTitle') + ' ' + timeLeft;
    }
    if (isManual && isOverdue) {
      return t('parking.parkingEnding');
    }
    return parkZoneName;
  };
  return (
    <>
      <Headline3
        numberOfLines={1}
        color={theme.colors.white}
        {...testIdentifiers('parking-time-left')}>
        {renderTexts()}
      </Headline3>
      <Meta
        color={theme.colors.white}
        numberOfLines={1}
        {...testIdentifiers('parking-time-vehicle')}>
        {vehicleLabel}
      </Meta>
    </>
  );
};

export const ChargingHeaderTexts = ({
  title,
  vehicleLabel,
}: {
  title: string;
  vehicleLabel: string;
}) => {
  return (
    <>
      <Headline3
        numberOfLines={1}
        color={theme.colors.white}
        testID="charging-modal-header-text">
        {title}
      </Headline3>
      <Meta
        color={theme.colors.white}
        numberOfLines={1}
        testID="parking-time-vehicle">
        {vehicleLabel}
      </Meta>
    </>
  );
};

type RoundButtonProps = {
  onPress: () => void;
  disabled?: boolean;
  testID?: string;
  name: string;
  type: ButtonTypes;
  buttonText?: string;
};

export const RoundButton = ({
  onPress,
  disabled = false,
  testID,
  name,
  type,
  buttonText,
}: RoundButtonProps) => {
  return (
    <ButtonWrapper>
      <IconButton
        buttonType={type}
        onPress={onPress}
        disabled={disabled}
        bigger
        {...testIdentifiers(`${testID}-button`)}
        iconComponent={() => (
          <Icon name={name} size="m" color={theme.colors.white} />
        )}
        rounded
        fullWidth={false}
      />
      {buttonText && (
        <ButtonText testID={`${testID}-text`}>{buttonText}</ButtonText>
      )}
    </ButtonWrapper>
  );
};

export const OngoingSessionsIcons = ({
  sessionsByParkZone,
}: {
  sessionsByParkZone: OngoingSessionType[];
}) => {
  const {
    flags: { anpr },
  } = useContext(FeatureFlagsContext);
  const showCameraRecognition = showFeatureFlag(anpr);

  const hasChargingSession =
    sessionsByParkZone?.filter(
      (session: OngoingSessionType) => session?.chargingSessionId
    )?.length > 0;

  const getLocationIconName = (parkZone: ParkZone) => {
    return getLocationIconType(
      parkZone.locationType || 'OTHER',
      showCameraRecognition && parkZone.parkingMethods?.anpr
    );
  };

  return (
    <>
      {sessionsByParkZone[0].parkZone && (
        <IconWrapper mr={hasChargingSession ? 0 : 3}>
          <Icon
            name={
              locationIcon[getLocationIconName(sessionsByParkZone[0].parkZone)]
            }
            size="s"
            testID={`location-icon-${getLocationIconName(
              sessionsByParkZone[0].parkZone
            )}`}
            color={theme.colors.boost.green}
          />
        </IconWrapper>
      )}
      {hasChargingSession && (
        <IconWrapper mr={3}>
          <Icon
            name={locationIcon.evCharging}
            size="s"
            testID="location-icon-Charging"
            color={theme.colors.boost.green}
          />
        </IconWrapper>
      )}
    </>
  );
};
