import { useMutation, useReactiveVar } from '@apollo/client';
import { Button, FieldInput, Meta, Spinner } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import { UNLOCK_LOCKED_SEGMENT } from 'graphql/mutations/unlockLockedSerment';
import { UNLOCK_URL_LOCKED_SEGMENT } from 'graphql/mutations/unlockLockedUrlSegment';
import { GET_CUSTOMER } from 'graphql/queries/getCustomer';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import useAllowedParkingTimes from 'hooks/useAllowedParkingTimes';
import useHasUnlockedCustomerSegment from 'hooks/useHasUnlockedCustomerSegment';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeviceInfo from 'react-native-device-info';
import WebView, { WebViewNavigation } from 'react-native-webview';
import {
  activeElement,
  activeElementType,
  selectedTariffId,
  selectedTariffName,
  sheetModal,
  unlockTariffItem,
} from 'states/common';
import { urlUnlockingSegment } from 'states/persistInStorage';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
import {
  Mutation,
  MutationUnlockLockedSegmentArgs,
  MutationUnlockUrlLockedSegmentArgs,
} from 'types/generatedSchemaTypes';
import { getLang } from 'utils/commonUtils';
import { isAndroid, isIos, isWeb } from 'utils/deviceUtils';
import { unlockUrlTariffProviders } from 'utils/tariffUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import { Center, Row } from '../styles/styles';
import { debounce } from 'lodash';

const Container = styled.View`
  height: 100%;
  padding-bottom: 40px;
`;

const InputWrapper = styled(StyledWrapper)<{ mt: number }>`
  margin-top: ${(p) => p.mt}px;
`;

const UnlockTariffModalContent: React.FC = () => {
  const [code, setCode] = useState('');
  const parkingZoneUid = useReactiveVar(activeElement);
  const unlockTariff = useReactiveVar(unlockTariffItem);
  const instructions = unlockTariff?.accessMethod?.instructions;
  const [logoutUser, setLogoutUser] = useState<boolean>(false);
  const { t } = useTranslation();
  const { pricingSchemeId, pricingCountryCode } =
    useAllowedParkingTimes(parkingZoneUid);
  const { hasUnlocked } = useHasUnlockedCustomerSegment(
    unlockTariff?.tariffId || '',
    pricingCountryCode || 'SE',
    unlockTariff?.zoneCode
  );
  const [manufacturer, setManufacturer] = useState('');
  const urlUnlockingProvider = unlockUrlTariffProviders(
    unlockTariff?.accessMethod?.url as string
  );
  const { isLoggedIn } = useIsLoggedIn();
  const { navigateToAuthentication, setReturnRedirectToBuyShortTermParking } =
    useAfterAuthenticationRedirect();

  const setUnlockedTariff = () => {
    selectedTariffId(unlockTariff?.tariffId);
    selectedTariffName(unlockTariff?.tariffName);
    activeElementType(unlockTariff?.activeElementType);
  };

  const [unlockSegement, { loading }] = useMutation<
    Mutation,
    MutationUnlockLockedSegmentArgs
  >(UNLOCK_LOCKED_SEGMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        errorPolicy: 'all',
      },
    ],
    onCompleted: () => {
      showToast('success.tariffUnlock', 'success');
      setUnlockedTariff();
      sheetModal('');
    },
    onError: () => showToast('error.tariffUnlockFailed', 'error'),
  });

  const [unlockUrlSegment, { loading: urlUnlockLoading }] = useMutation<
    Mutation,
    MutationUnlockUrlLockedSegmentArgs
  >(UNLOCK_URL_LOCKED_SEGMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        errorPolicy: 'all',
      },
    ],
    onCompleted: () => {
      setLogoutUser(true);
      showToast('success.tariffUnlock', 'success');
      setUnlockedTariff();
    },
    onError: () => {
      setLogoutUser(true);
      showToast('error.tariffUnlockFailed', 'error');
    },
  });

  const handleUnlock = debounce(
    () => {
      unlockSegement({
        variables: {
          input: {
            code,
            customerSegmentUid: unlockTariff?.tariffId,
            pricingSchemeId,
            zoneCode: unlockTariff?.zoneCode,
            hasUnlocked,
            countryCode: pricingCountryCode,
          },
        },
      });
    },
    1,
    { leading: !isWeb }
  );

  const handleSignIn = async () => {
    sheetModal('');
    setReturnRedirectToBuyShortTermParking(parkingZoneUid);
    await navigateToAuthentication(isWeb);
  };

  const handleUrlChange = async (url: string) => {
    if (url.includes(urlUnlockingProvider?.logoutUrl as string)) {
      setTimeout(() => {
        sheetModal('');
      }, 500);
    }
    if (
      url.includes(urlUnlockingProvider?.responseCheck as string) &&
      !logoutUser
    ) {
      const token = url.substring(url.indexOf('=') + 1, url.length);
      if (token) {
        return unlockUrlSegment({
          variables: {
            input: {
              token,
              pricingSchemeId,
              zoneCode: unlockTariff?.zoneCode as string,
              customerSegmentUid: unlockTariff?.tariffId as string,
              service: urlUnlockingProvider?.service,
              provider: urlUnlockingProvider?.provider,
              hasUnlocked,
            },
          },
        });
      }
    }
  };

  const onNavigationStateChange = async ({ url }: WebViewNavigation) => {
    await handleUrlChange(url);
  };

  const getAuthUrl = () => {
    if (
      unlockTariff?.accessMethod?.type === 'URL' &&
      unlockTariff.accessMethod.url !== ''
    ) {
      return !logoutUser
        ? (urlUnlockingProvider?.loginUrl as string)
        : (urlUnlockingProvider?.logoutUrl as string);
    }
    return '';
  };

  const hasInstructions = !!(instructions && instructions[getLang()]);

  DeviceInfo.getManufacturer().then(setManufacturer);

  const extraHeight = useMemo(() => {
    if (
      isAndroid &&
      manufacturer.toLowerCase() === 'samsung' &&
      !hasInstructions
    ) {
      return 25;
    }
    return 0;
  }, [hasInstructions, manufacturer]);

  if (loading || urlUnlockLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const debouncedHandleChange = debounce(
    (text) => {
      setCode(text);
    },
    1,
    { leading: !isWeb }
  );

  const handleChangeText = (text: string) => {
    debouncedHandleChange(text);
  };

  const webviewRenderer = () => {
    if (!isWeb) {
      return (
        <WebView
          useWebKit
          height={'100%'}
          testID="url-unlocked-segment-login"
          nestedScrollEnabled={true}
          source={{
            uri: getAuthUrl(),
          }}
          onNavigationStateChange={onNavigationStateChange}
        />
      );
    }
    if (pricingSchemeId) {
      urlUnlockingSegment({
        hasUnlocked,
        pricingScheme: pricingSchemeId as string,
        zoneCode: unlockTariff?.zoneCode,
        customerSegmentUid: unlockTariff?.tariffId,
        service: urlUnlockingProvider?.service,
        provider: urlUnlockingProvider?.provider,
        logoutUrl: urlUnlockingProvider?.logoutUrl,
      });
      (window as any).open(getAuthUrl(), '_self');
    }
  };

  if (!isLoggedIn) {
    return (
      <StyledWrapper center={isWeb} maxWidth={isWeb ? 450 : undefined}>
        <Button
          {...testIdentifiers('unlock-tariff-code-login')}
          text={t('parking.notSignedIn.button.long')}
          size="l"
          buttonType="secondary"
          onPress={handleSignIn}
        />
      </StyledWrapper>
    );
  }

  if (unlockTariff?.accessMethod?.type === 'URL') {
    return <Container>{webviewRenderer()}</Container>;
  }

  return (
    <StyledWrapper>
      {hasInstructions && (
        <Row mb={50} mt={20}>
          <Meta testID="unlock-tariff-instructions">
            {instructions[getLang()]}
          </Meta>
        </Row>
      )}
      <InputWrapper
        mb={30 + extraHeight}
        center={isWeb}
        mt={extraHeight}
        maxWidth={isWeb ? 450 : undefined}>
        <FieldInput
          title={t('parking.tariff.unlockCode')}
          value={code}
          keyboardType="default"
          onChangeText={handleChangeText}
          isSheet={isIos}
          testID="unlock-tariff-code-input"
        />
      </InputWrapper>
      <StyledWrapper center={isWeb} maxWidth={isWeb ? 450 : undefined}>
        <Button
          loading={loading}
          {...testIdentifiers('unlock-tariff-code-button')}
          text={t('parking.tariff.unlock')}
          size="l"
          buttonType="secondary"
          onPress={handleUnlock}
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default UnlockTariffModalContent;
