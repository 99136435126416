import React from 'react';
import { ScrollView } from 'react-native-gesture-handler';

const BottomSheetScrollViewCustom = ({
  children,
}: {
  children: React.ReactNode;
}) => <ScrollView>{children}</ScrollView>;

export default BottomSheetScrollViewCustom;
