import { Vehicle } from 'types/generatedSchemaTypes';
import useOngoingParkingSession from './useOngoingParkingSession';

/**
 * Checks if a parking session exists for a given parking zone and vehicle.
 *
 * @param {Object} options - The options object.
 * @param {string} [options.parkingZoneUid] - The unique identifier of the parking zone.
 * @param {Vehicle} [options.vehicle] - The vehicle information.
 *
 * @returns {Boolean} An object with a boolean indicating whether a parking session exists.
 */
const useHasParkingSession = ({
  parkingZoneUid,
  vehicle,
}: {
  parkingZoneUid?: string;
  vehicle?: Vehicle;
}) => {
  const { allParkingSessions } = useOngoingParkingSession();

  const hasParkingSession = !!allParkingSessions?.some(
    (parkingSession) =>
      parkingSession?.parkingZoneUid === parkingZoneUid &&
      parkingSession?.accessDevice?.value === vehicle?.licensePlate.plateText
  );
  return { hasParkingSession };
};

export default useHasParkingSession;
