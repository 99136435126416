import { useReactiveVar } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import { Spinner, theme } from 'aimo-ui';
import AuthManager from 'components/auth/AuthManager';
import BGView from 'components/common/BGView';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import useHandleAuthentication from 'hooks/useHandleAuthentication';
import useHasVehicleAndCreditCard from 'hooks/useHasVehicleAndCreditCard';
import useIsAccountRestricted from 'hooks/useIsAccountRestricted';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dimensions, InteractionManager, StyleSheet, View } from 'react-native';
import { isSignUpFlow } from 'states/common';
import { accessToken } from 'states/persistInStorage';
import { StyledWrapper } from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import WelcomeCustomerDataFailed from './WelcomeCustomerDataFailed';
import WelcomeNotSignedContent from './WelcomeNotSignedContent';

interface WelcomeRendererProps {
  onSigninPress?: () => void;
  onSignupPress?: () => void;
  onLetsGoPress?: () => void;
  signinLoading: boolean;
  loginText: string;
  setHeight: (h: number) => void;
}

const { height } = Dimensions.get('window');

const WelcomeRenderer = ({
  onSigninPress,
  onSignupPress,
  onLetsGoPress,
  signinLoading,
  loginText,
  setHeight,
}: WelcomeRendererProps) => {
  const { loading, error } = useContext(AccountContext);
  const { isLoggedIn } = useIsLoggedIn();
  let content;
  if (!loading && error) {
    // has token, but customer data didnt load. show error page:
    content = <WelcomeCustomerDataFailed />;
  } else if (accessToken() === '' || !isLoggedIn) {
    content = (
      <WelcomeNotSignedContent
        notSignedInText={loginText}
        signinLoading={signinLoading}
        onSigninPress={onSigninPress}
        onSignupPress={onSignupPress}
        onLetsGoPress={onLetsGoPress}
        setHeight={setHeight}
      />
    );
  }

  return (
    <BGView noMargins paddings={{ top: 30, left: 20, right: 20 }}>
      {content}
    </BGView>
  );
};

const setRedirectAfterSuccess = (
  isInRedirectProcess: () => boolean,
  setReturnRedirect: (allowOnboarding?: boolean) => void,
  setRedirectCallback?: (allowOnboarding?: boolean) => void,
  allowOnboarding?: boolean
) => {
  if (setRedirectCallback) {
    setRedirectCallback(allowOnboarding);
  } else if (!isInRedirectProcess()) {
    setReturnRedirect(allowOnboarding);
  }
};

const calculateWrapperHeight = (
  isLoggedIn: boolean,
  fixedHeader: boolean,
  welcomeScreenContentHeight: number,
  useOnboardingV2?: boolean
) => {
  const heightCalculation = isLoggedIn
    ? theme.normalising.heightPixel(220)
    : theme.normalising.heightPixel(useOnboardingV2 ? 600 : 500);
  const headerHeight =
    isLoggedIn && !fixedHeader ? theme.normalising.heightPixel(500) : 0;
  let wrapperHeight = height - heightCalculation + headerHeight;
  if (!isLoggedIn && welcomeScreenContentHeight > wrapperHeight) {
    wrapperHeight = welcomeScreenContentHeight;
  }
  return wrapperHeight;
};

const loginActions = ({
  isLoggedIn,
  isSignUpFlowOngoing,
  redirectAfterAuthentication,
  hasData,
  navigateToNext,
  isInRedirectProcess,
  setRedirectAfterAuthentication,
}: {
  isLoggedIn: boolean;
  isSignUpFlowOngoing: boolean;
  redirectAfterAuthentication: boolean;
  hasData: boolean;
  navigateToNext: (waitForStateUpdate: boolean) => void;
  isInRedirectProcess: () => boolean;
  setRedirectAfterAuthentication: (state: boolean) => void;
}) => {
  if (isLoggedIn && !isSignUpFlowOngoing) {
    if (redirectAfterAuthentication) {
      setRedirectAfterAuthentication(false);
      if (!hasData) {
        navigateToNext(!isInRedirectProcess());
      }
    }
  }
};

const LoginStates = ({
  fixedHeader,
  loginText,
  setRedirectCallback,
}: {
  fixedHeader: boolean;
  loginText: string;
  setRedirectCallback?: (allowOnboarding?: boolean) => void;
}) => {
  // Note: on web execution signIn and signUp never resolve the promise, due to msal library
  // and we therefore use another approach in AuthManager.web.tsx
  const [welcomeScreenContentHeight, setWelcomeScreenContentHeight] =
    useState(0);
  const [redirectAfterAuthentication, setRedirectAfterAuthentication] =
    useState(false);
  const [signinLoading, setSigninLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { isLoggedIn, loginLoading } = useIsLoggedIn();
  const { setReturnRedirectToAccount, navigateToNext, isInRedirectProcess } =
    useAfterAuthenticationRedirect();
  const hasData = useHasVehicleAndCreditCard();
  const isSignUpFlowOngoing = useReactiveVar(isSignUpFlow);
  const { refetchFailedSessions } = useIsAccountRestricted();
  const { handleAuthMethod } = useHandleAuthentication();
  const accessTokenValue = useReactiveVar(accessToken);

  const {
    flags: { onboarding_v2, use_email_verification },
  } = useContext(FeatureFlagsContext);
  const useOnboardingV2 = showFeatureFlag(onboarding_v2);
  const useEmailVerification = showFeatureFlag(use_email_verification);

  const wrapperHeight = calculateWrapperHeight(
    isLoggedIn,
    fixedHeader,
    welcomeScreenContentHeight,
    useOnboardingV2
  );

  const styles = StyleSheet.create({
    onboardingV2: {
      height: wrapperHeight,
      borderRadius: 0,
      backgroundColor: '#FFF',
    },
    onboarding: {
      height: wrapperHeight,
    },
  });

  const handleRedirectsAfterSuccess = useCallback(
    (skipRedirectCheck = false) => {
      setRedirectAfterSuccess(
        skipRedirectCheck ? () => false : isInRedirectProcess,
        navigateToNext,
        setRedirectCallback,
        true
      );
      setRedirectAfterAuthentication(true);
    },
    [setRedirectCallback, isInRedirectProcess, navigateToNext]
  );

  const callback = useCallback(() => {
    handleRedirectsAfterSuccess(true);
  }, [handleRedirectsAfterSuccess]);

  const handleSignIn = useCallback(async () => {
    setSigninLoading(true);
    isSignUpFlow(false);
    await AuthManager.signInAsync(callback);
    setSigninLoading(false);
    await refetchFailedSessions();
  }, [callback, refetchFailedSessions]);

  const handleSignUp = useCallback(() => {
    isSignUpFlow(true);
    setRedirectAfterSuccess(
      isInRedirectProcess,
      setReturnRedirectToAccount,
      setRedirectCallback,
      false
    );
  }, [isInRedirectProcess, setRedirectCallback, setReturnRedirectToAccount]);

  const handleLetsGo = useCallback(async () => {
    await handleAuthMethod(useEmailVerification);
  }, [handleAuthMethod, useEmailVerification]);

  useEffect(() => {
    setLoading(loginLoading);
  }, [loginLoading]);

  useEffect(() => {
    if (accessTokenValue && isLoggedIn) {
      handleRedirectsAfterSuccess();
    }
  }, [accessTokenValue, isLoggedIn, handleRedirectsAfterSuccess]);

  // If terms are not accepted redirect to approve terms
  // Or redirect to next screen after authentication
  useFocusEffect(
    React.useCallback(() => {
      const task = InteractionManager.runAfterInteractions(() => {
        loginActions({
          isLoggedIn: isLoggedIn,
          isSignUpFlowOngoing: isSignUpFlowOngoing,
          redirectAfterAuthentication: redirectAfterAuthentication,
          hasData: hasData,
          navigateToNext: navigateToNext,
          isInRedirectProcess: isInRedirectProcess,
          setRedirectAfterAuthentication: setRedirectAfterAuthentication,
        });
        return () => task.cancel();
      });
    }, [
      isLoggedIn,
      isSignUpFlowOngoing,
      redirectAfterAuthentication,
      hasData,
      navigateToNext,
      isInRedirectProcess,
      setRedirectAfterAuthentication,
    ])
  );

  const renderer = () => {
    if (loading) {
      return (
        <StyledWrapper center mt={useOnboardingV2 ? 50 : 0}>
          <Spinner testID="login-states-test-id" />
        </StyledWrapper>
      );
    }
    return (
      <WelcomeRenderer
        signinLoading={signinLoading}
        onSigninPress={handleSignIn}
        onSignupPress={handleSignUp}
        onLetsGoPress={handleLetsGo}
        loginText={loginText}
        setHeight={setWelcomeScreenContentHeight}
      />
    );
  };

  return isWeb ? (
    renderer()
  ) : useOnboardingV2 ? (
    <View style={styles.onboardingV2}>{renderer()}</View>
  ) : (
    <View style={styles.onboarding}>{renderer()}</View>
  );
};

export default LoginStates;
