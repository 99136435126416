import React from 'react';
import Permit from '../permit/Permit';
import { ScreenContainer, SimpleFullContainer } from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';

const Container: any = isWeb ? ScreenContainer : SimpleFullContainer;

const PermitHistoryDetailsScreen: React.FC = () => (
  <Container mt={200}>
    <Permit isHistory={true} />
  </Container>
);

export default PermitHistoryDetailsScreen;
