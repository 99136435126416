import { BottomSheetScrollViewMethods } from '@gorhom/bottom-sheet';
import FailedToLoadChargersWarning from 'components/park/FailedToLoadChargersWarning';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import React, { MutableRefObject, useContext } from 'react';
import { ChargingStation } from 'types/generatedSchemaTypes';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import SelectChargerZoneDetails from '../evcharge/SelectChargerZoneDetails';
import { ImportantZoneNotes } from '../ParkingZoneNotes';
import Tariff from '../tariff/Tariff';
import TabContainer, { CommonTabProps } from './TabContainer';

type ParkingZoneParkAndChargeTabProps = {
  scrollRef: MutableRefObject<BottomSheetScrollViewMethods>;
} & CommonTabProps;

const ParkingZoneParkAndChargeTab: React.FC<
  ParkingZoneParkAndChargeTabProps
> = ({ parkZone, isActiveTab, scrollRef, hasImportantNotes }) => {
  const {
    flags: { stp_tariff },
  } = useContext(FeatureFlagsContext);
  const showSTPTariff = showFeatureFlag(stp_tariff);
  const { parkingZoneChargingStations } = parkZone || {};

  return (
    <TabContainer isActiveTab={isActiveTab}>
      {showSTPTariff && (
        <Tariff
          headlineTitle
          chargingStations={parkingZoneChargingStations as ChargingStation[]}
          borderBottom={!hasImportantNotes}
        />
      )}
      <ImportantZoneNotes parkZoneNotes={parkZone?.notes} />
      {parkingZoneChargingStations ? (
        <SelectChargerZoneDetails parkZone={parkZone} scrollRef={scrollRef} />
      ) : (
        <FailedToLoadChargersWarning marginTop={true} />
      )}
    </TabContainer>
  );
};

export default ParkingZoneParkAndChargeTab;
