import { useReactiveVar } from '@apollo/client';
import { Spinner } from 'aimo-ui';
import Sheet from 'components/sheets/Sheet';
import React from 'react';
import { Dimensions } from 'react-native';
import { hasNotch } from 'react-native-device-info';
import { accountTheme } from 'states/common';
import { ScreenContainer, StyledWrapper } from 'styles/ContainerStyles';

const { height } = Dimensions.get('window');
const sheetHeight = height - (hasNotch() ? 200 : 170);

export const WebWrapper: React.FC<{ page: any; mt?: number }> = ({
  page: Page,
  mt,
}) => {
  const PageComponent = Page();
  return (
    <ScreenContainer mt={mt ?? 140} mb={50}>
      {PageComponent}
    </ScreenContainer>
  );
};

const SheetWrapper: React.FC<{
  page: React.FC;
  loading?: boolean;
  mb?: number;
}> = ({ page: Component, loading, mb }) => {
  const usedTheme = useReactiveVar(accountTheme);
  return (
    <ScreenContainer background={usedTheme} mb={mb}>
      {loading ? (
        <StyledWrapper center>
          <Spinner />
        </StyledWrapper>
      ) : (
        <Sheet snappingPoints={[sheetHeight]} hideHandle>
          <Component />
        </Sheet>
      )}
    </ScreenContainer>
  );
};

export default SheetWrapper;
