import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Button, Meta, theme } from 'aimo-ui';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  activeElement,
  currentCustomerCountry,
  sheetModal,
} from 'states/common';
import {
  featureCountryCode,
  futureFeatureCountryCode,
} from 'states/persistInStorage';
import { StyledWrapper } from 'styles/ContainerStyles';
import { RootNavProps, SetParamsProps } from 'types/navigation';
import { handleElPress } from 'utils/mapUtils';
import { changeFutureAppCountryCode } from 'utils/countryUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import { hasAcceptedTerms } from 'hooks/useHasAcceptance';
import AccountContext from 'context/AccountContext';

const UpdateFeatureCountryModal: React.FC<{ isAutomatic?: boolean }> = ({
  isAutomatic,
}) => {
  const { t } = useTranslation();
  const currentCountry = useReactiveVar(currentCustomerCountry);
  const appCountry = useReactiveVar(featureCountryCode);
  const futureAppCountry = useReactiveVar(futureFeatureCountryCode);
  const { setParams }: SetParamsProps = useNavigation();
  const uid = useReactiveVar(activeElement);
  const {
    customer: { acceptances },
  } = useContext(AccountContext);
  const { navigate } = useNavigation<RootNavProps>();

  const countryUpdate = () => {
    if (!isAutomatic) {
      const hasAcceptedTermsAppCountry =
        futureAppCountry &&
        hasAcceptedTerms(futureAppCountry, 'TERMS_ACCEPTED', acceptances);

      if (hasAcceptedTermsAppCountry) {
        featureCountryCode(futureAppCountry);
      } else {
        changeFutureAppCountryCode(futureAppCountry);
        navigate('approveTermsAppLocation');
      }
      return sheetModal('');
    }
    sheetModal('appLocation');
  };

  const onBackPress = () => {
    sheetModal('');
    handleElPress(uid, { latitude: 0, longitude: 0 }, setParams);
  };

  const renderDescription = () => {
    return !isAutomatic
      ? t('parking.updateLocation.description', {
          targetCountry: t(`account.appLocations.${currentCountry}`),
        })
      : t('parking.updateLocation.autoChangeDesc', {
          currentCountry: t(`account.appLocations.${currentCountry}`),
          appCountry: t(`account.appLocations.${appCountry}`),
        });
  };
  return (
    <StyledWrapper mb={0}>
      <StyledWrapper maxWidth={335} center mb={40}>
        <Meta
          center
          color={theme.colors.gray200}
          testID="app-location-description">
          {renderDescription()}
        </Meta>
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <StyledWrapper>
          <Button
            testID="app-update-button"
            size="l"
            text={t('parking.updateLocation.updateLocation')}
            onPress={countryUpdate}
          />
        </StyledWrapper>
        <StyledWrapper mb={0}>
          <Button
            {...testIdentifiers('app-update-cancel-button')}
            size="l"
            buttonType="tertiary"
            text={t('button.cancel')}
            onPress={onBackPress}
          />
        </StyledWrapper>
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default UpdateFeatureCountryModal;
