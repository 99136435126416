import { VehicleType } from 'types/generatedSchemaTypes';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { vehicleTypes } from 'utils/vehicleUtils';
import Icon from 'components/icons/Icon';
import { Dropdown, theme } from 'aimo-ui';
import {
  CheckIcon,
  DropdownIcon,
  VEHICLE_INPUT_ICON_SIZE,
} from 'components/account/vehicle/VehicleIcons';

type VehicleTypeSelectorProps = {
  selectedType?: VehicleType;
  setSelectedType: React.Dispatch<
    React.SetStateAction<VehicleType | undefined>
  >;
};

type IconComponentProps = {
  icon: string;
  id: string;
};

const IconComponent = ({ icon, id }: IconComponentProps) => (
  <Icon
    name={icon}
    size={VEHICLE_INPUT_ICON_SIZE}
    color={theme.colors.black}
    testID={`${id}-icon-button`}
  />
);

const VehicleTypeSelector = ({
  selectedType,
  setSelectedType,
}: VehicleTypeSelectorProps) => {
  const { t } = useTranslation();

  const vehicleTypesDropdownItems = vehicleTypes.map((vehicleType) => {
    return {
      label: t(`vehicle.form.type.${vehicleType.id}`),
      value: vehicleType.id,
      icon: () => IconComponent(vehicleType),
    };
  });

  const selectedItem = useMemo(
    () => vehicleTypesDropdownItems.find((item) => item.value === selectedType),
    [selectedType, vehicleTypesDropdownItems]
  );

  return (
    <Dropdown
      items={vehicleTypesDropdownItems}
      defaultItem={selectedItem}
      onSelect={(item) => setSelectedType(item.value as VehicleType)}
      placeholder={t('vehicle.form.type.label')}
      dropdownIcon={DropdownIcon}
      checkIcon={CheckIcon}
    />
  );
};

export default React.memo(VehicleTypeSelector);
