import AimoMarker from '@components/map/AimoMarker';
import AimoPolygon from 'components/map/AimoPolygon';
import React from 'react';
import { Maybe, ParkZone } from 'types/generatedSchemaTypes';
import { MarkerTypes } from 'types/MapTypes';
import { makePoint } from 'utils/mapUtils';

const Empty = () => <></>;

export const MapMarkers = (
  clusterer: any | undefined,
  parkingZonesArray: Maybe<ParkZone>[],
  withLTP?: boolean
) => {
  if (parkingZonesArray?.length > 0) {
    const parkingZonesFiltered = parkingZonesArray?.filter(
      (p: ParkZone | null) =>
        Boolean(p?.address?.marker?.coordinates) && Boolean(p?.uid)
    );
    return parkingZonesFiltered?.map((p: ParkZone | null, index: number) => {
      const {
        address,
        uid,
        locationType,
        countryCode,
        commerciallyActive,
        markerType,
      } = p || {};
      // In Sweden, displaying markers are based on park zone's commerciallyActive value, locationType isn't taken into account.
      if (locationType === 'STREET' && countryCode !== 'SE') {
        return null;
      }
      if (
        address?.marker?.coordinates &&
        uid &&
        (countryCode !== 'SE' || (countryCode === 'SE' && commerciallyActive))
      ) {
        const coordinates = makePoint(address.marker.coordinates);
        const zoneCode = p?.commercialZoneView?.zoneCode;
        return (
          <AimoMarker
            key={`fragment-${uid}-${index}`}
            coordinate={coordinates}
            markerType={markerType as MarkerTypes}
            testID={`${markerType}-marker-${uid}`}
            id={uid}
            zoneCode={zoneCode as string}
            clusterer={clusterer}
            withLTP={withLTP}
          />
        );
      }
      return <Empty key={`fragment-${uid}-${index}`} />;
    });
  }
  return null;
};

export const MapPolygons = (parkingZonesData: Maybe<ParkZone>[]) => {
  const parkingZonesFiltered = parkingZonesData?.filter((p: ParkZone | null) =>
    Boolean(p?.area?.coordinates)
  );
  if (parkingZonesFiltered?.length > 0) {
    return parkingZonesFiltered?.map((p: ParkZone | null, index: number) => {
      const { uid, area } = p || {};
      if (area?.coordinates && uid) {
        return (
          <AimoPolygon
            key={`polygon-${uid}-${index}`}
            id={uid}
            testID={`polygon-${uid}`}
            geoJsonMultiPolygon={area}
          />
        );
      }
    });
  }
  return <></>;
};
