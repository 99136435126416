import { BottomSheetScrollViewMethods } from '@gorhom/bottom-sheet';
import SearchInput from 'components/SearchInput/SearchInput';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Charger } from 'types/common';
import { ParkZone } from 'types/generatedSchemaTypes';
import { getSupportedTypes } from 'utils/chargingUtils';
import SelectParkAndCharger from './SelectParkAndCharger';

type SearchParkAndChargerProps = {
  parkZone?: Maybe<ParkZone>;
  showSearch: boolean;
  setShowSearch: (value: boolean) => void;
  scrollRef?: MutableRefObject<BottomSheetScrollViewMethods>;
  isTesting?: boolean;
};

const PADDING = 40;

const SearchParkAndCharger: React.FC<SearchParkAndChargerProps> = ({
  parkZone,
  showSearch,
  setShowSearch,
  scrollRef,
  isTesting = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchHeight, setSearchHeight] = useState(0);
  const [chargersHeight, setChargersHeight] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    setSearchTerm('');
  }, [showSearch]);

  useEffect(() => {
    if (showSearch && searchHeight) {
      setTimeout(() => {
        scrollRef?.current?.scrollTo({
          x: 0,
          y: chargersHeight + searchHeight + PADDING,
          animated: true,
        });
      }, 400);
    }
  }, [searchHeight, showSearch, scrollRef, chargersHeight]);

  const chargerFilter = (charger: Charger) => {
    if (!searchTerm) {
      return true;
    }
    const searchTermLc = searchTerm.toLowerCase();

    const publicIdPredicate = charger.publicDisplayId
      .toLowerCase()
      .includes(searchTermLc);

    const chargerTypePredicate = getSupportedTypes([charger.evse])
      .map((type) => t(`evCharging.connectorSupportedTypes.${type}`, type))
      .some((typeText) => typeText.toLowerCase().includes(searchTermLc));

    return publicIdPredicate || chargerTypePredicate;
  };

  return (
    <>
      {showSearch && (
        <SearchInput
          onLayout={(e) => {
            setSearchHeight(e.nativeEvent.layout.height);
          }}
          onBlur={() => !searchTerm && setShowSearch(false)}
          onChangeText={setSearchTerm}
          placeholder={t('parkAndCharge.searchPlaceholder')}
          autoFocus
          testID={'charger-search'}
          isTesting={isTesting}
          marginBottom={0}
        />
      )}
      <SelectParkAndCharger
        onLayout={(e) => {
          setChargersHeight(e.nativeEvent.layout.y);
        }}
        chargerFilter={chargerFilter}
        parkZone={parkZone}
        marginTop={showSearch}
      />
    </>
  );
};

export default SearchParkAndCharger;
