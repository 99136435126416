import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { MMKVWrapper, persistCache } from 'apollo3-cache-persist';
import AuthManager from 'components/auth/AuthManager';
import React from 'react';
import { DeviceEventEmitter } from 'react-native';
import { accessToken, isTokenRefreshing } from 'states/persistInStorage';
import { cacheSettings } from 'utils/cacheUtils';
import Logger from 'utils/Logger';
import { MMKVStorage } from 'utils/MMKVStorage';
import config from '../config/config';

const MAX_RETRY = 5;

const getDelay = (
  _count: number,
  operation: Operation,
  _error: any
): number => {
  /**
   * Charging session no longer depends on just Everon provider, there will
   * be other provider in used as well. However when Everon is used,
   * it could take more time to start the session. Therefore, a longer
   * delay time is set for the related operations.
   *
   */
  return operation.operationName === 'readOngoingChargingSessionV3'
    ? 5000
    : 1000;
};

const useCreateApolloClient = () => {
  const [client, setClient] =
    React.useState<ApolloClient<NormalizedCacheObject>>();

  React.useEffect(() => {
    const createApolloClient = async () => {
      const cache = new InMemoryCache(cacheSettings);
      await persistCache({
        cache,
        storage: new MMKVWrapper(MMKVStorage),
        debug: process.env.NODE_ENV !== 'production',
        maxSize: false,
      });

      const retrylink = new RetryLink({
        delay: getDelay,
        attempts: {
          max: MAX_RETRY,
          retryIf: (error, operation) =>
            !!error &&
            !error.message.includes(401) &&
            (operation.operationName === 'readCustomerV3' ||
              operation.operationName === 'readParkingSessions' ||
              operation.operationName === 'readOngoingChargingSessionV3'),
        },
      });

      const httpLink = new HttpLink({
        uri: config.graphqlUrl,
      });

      const authMiddleware = setContext(async (_, { headers }) => {
        const currentToken = await AuthManager.getAccessTokenAsync();
        return {
          headers: {
            ...headers,
            'X-Auth-Token': currentToken,
          },
        };
      });

      const handleErrors = onError(
        ({ graphQLErrors, networkError, operation, forward }) => {
          if (graphQLErrors) {
            graphQLErrors.map(async (graphQLError) => {
              if (
                graphQLError.message.includes('UNAUTHORIZED_ACCESS_TOKEN') &&
                graphQLError.extensions?.code === 'UNAUTHENTICATED'
              ) {
                Logger.logMessageWithCustomerUid(
                  `GraphQL returns an UNAUTHORIZED_ACCESS_TOKEN error with code UNAUTHENTICATED in the operation ${operation.operationName}`,
                  'Trying to get the access token async and retry the request'
                );
                const currentToken = await AuthManager.getAccessTokenAsync();

                // Apollo is using zen-observable behind the hood https://github.com/zenparsing/zen-observable
                return new Observable((observer) => {
                  // Take the operation that we had and add new headers
                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      ...headers,
                      'X-Auth-Token': currentToken,
                    },
                  }));

                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  // Retry last failed request
                  const subscription = forward(operation).subscribe(subscriber);
                  return () => {
                    subscription.unsubscribe();
                  };
                });
              } else {
                let message = `GraphQL error | Path: ${graphQLError.path} | Message: ${graphQLError.message}`;
                const customResponse: any = graphQLError.extensions?.response;
                if (graphQLError.locations) {
                  message += ` | Location: ${graphQLError.locations}`;
                }
                if (customResponse?.body?.path) {
                  message += ` | Details: at ${customResponse?.body?.path}`;
                }
                if (customResponse?.body?.message) {
                  message += ` | Reason: ${customResponse?.body?.message}`;
                }
                if (graphQLError.extensions?.level === 'warn') {
                  Logger.warn(message, undefined);
                } else {
                  Logger.error(message, undefined, {
                    ...graphQLError,
                    stack: graphQLError.extensions.stacktrace as string,
                  });
                }
              }
            });
          }

          if (networkError) {
            Logger.warn(
              `Network error | Error: ${networkError.message}`,
              JSON.stringify({
                networkError: networkError,
              })
            );
          }
        }
      );

      const skipQueriesLink = new ApolloLink((operation, forward) => {
        // Determine if the operation requires authentication
        const requiresAuth =
          operation.operationName !== 'parkZones' &&
          operation.operationName !== 'getParkZonesByDistance';

        if (requiresAuth && isTokenRefreshing()) {
          Logger.logMessageWithCustomerUid(
            `Pause ${operation.operationName} while token is being refreshed`
          );
          // Skip queries if token is being refreshed
          return new Observable((observer) => {
            const onTokenRefreshComplete = async () => {
              // When the token refresh is completed, resume the query with the updated token.
              Logger.logMessageWithCustomerUid(
                `Resuming ${operation.operationName} after token refresh was completed`
              );
              if (accessToken()) {
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    'X-Auth-Token': accessToken(),
                  },
                }));
                const subscription = forward(operation).subscribe({
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                });
                return () => {
                  Logger.logMessageWithCustomerUid(
                    `Removing subscription in ${operation.operationName} after token refresh was completed`
                  );
                  subscription.unsubscribe();
                };
              } else {
                Logger.error(
                  'Token refresh was completed but the access token is not available'
                );
                observer.complete();
              }
            };

            // Wait for a custom event (onTokenRefreshComplete) to indicate that token refresh is complete
            const eventListener = DeviceEventEmitter.addListener(
              'onTokenRefreshComplete',
              onTokenRefreshComplete
            );

            return () => {
              eventListener.remove();
            };
          });
        }
        // If not refreshing, proceed with the query
        return forward(operation);
      });

      const persistedClient = new ApolloClient({
        link: from([
          handleErrors,
          authMiddleware,
          retrylink,
          skipQueriesLink,
          httpLink,
        ]),
        cache,
      });

      setClient(persistedClient);
    };
    createApolloClient();

    return () => {
      setClient(undefined);
    };
  }, []);

  return client;
};

export default useCreateApolloClient;
