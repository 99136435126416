import { ListItem, Meta, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import React from 'react';
import { View } from 'react-native';
import { selectedHslTicket, sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { HslTicketContent } from 'types/generatedSchemaTypes';
import { titleTypeText, validTimeText } from 'utils/hslUtils';

const IconWrapper = styled(View)`
  margin: 0px 14px;
`;

type HslTicketListItemProps = {
  ticket: HslTicketContent;
  index: number;
};

const HslTicketListItem = ({ ticket, index }: HslTicketListItemProps) => {
  const handleTicketSelect = () => {
    selectedHslTicket({
      ticketId: ticket.hslDetails.ticketId,
      ticketType: ticket.hslDetails.ticketType,
    });
    sheetModal('hslTicketRenderView');
  };

  return (
    <ListItem
      item={ticket}
      withBackground={theme.colors.white}
      title={titleTypeText(ticket.hslDetails.ticketType)}
      onPress={handleTicketSelect}
      testID={`hsl-ticket-item-${index}`}
      iconComponent={() => (
        <IconWrapper>
          <Icon name="HSL-Outlined" color={theme.colors.gray400} />
        </IconWrapper>
      )}
      indexValue={index}
      subtitleComponent={() => (
        <Meta color={theme.colors.gray200} testID={`hsl-ticket-valid-${index}`}>
          {validTimeText(ticket.hslDetails)}
        </Meta>
      )}
      arrowComponent={() => (
        <IconWrapper>
          <Icon name="Chevron_Right" size="s" />
        </IconWrapper>
      )}
    />
  );
};

export default HslTicketListItem;
