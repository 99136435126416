import { useQuery, useReactiveVar } from '@apollo/client';
import { READ_CHARGING_STATION } from '@queries/readChargingStation';
import { activeElementSecondaryUid, activeElementType } from 'states/common';
import {
  Query,
  QueryReadChargingStationV2Args,
} from 'types/generatedSchemaTypes';

const useChargingStation = (chargingStationId?: string) => {
  const currentParkingZoneType = useReactiveVar(activeElementType);
  const currentChargingStationId = useReactiveVar(activeElementSecondaryUid);
  const isChargingTariffSelected =
    chargingStationId ||
    ((currentParkingZoneType === 'CHARGING' ||
      currentParkingZoneType === 'PARK_AND_CHARGE') &&
      currentChargingStationId);

  return useQuery<Query, QueryReadChargingStationV2Args>(
    READ_CHARGING_STATION,
    {
      variables: {
        input: {
          stationId: chargingStationId
            ? chargingStationId
            : currentChargingStationId,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !isChargingTariffSelected,
      errorPolicy: 'all',
    }
  );
};
export default useChargingStation;
