import React from 'react';
import Icon from '@components/icons/Icon';
import { theme, IconButton } from 'aimo-ui';
import { ButtonTypes } from 'aimo-ui/lib/typescript/src/components/Button/types';
import { testIdentifiers } from 'utils/testIdentifiers';

type RemoveButtonProps = {
  onPress?: () => void;
  testID?: string;
  buttonType?: ButtonTypes;
};

const BinIcon = () => (
  <Icon
    name="Bin"
    size="m"
    strokeColor={theme.colors.white}
    color={theme.colors.white}
  />
);

const RemoveButton = ({
  onPress,
  testID,
  buttonType = 'tertiary',
}: RemoveButtonProps) => {
  return (
    <IconButton
      fullWidth={false}
      buttonType={buttonType}
      onPress={onPress}
      {...testIdentifiers(testID as string)}
      iconComponent={BinIcon}
    />
  );
};

export default RemoveButton;
