import { useReactiveVar } from '@apollo/client';
import { Headline2, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useFeatureFlagEVCharging from 'hooks/useFeatureFlagEVCharging';
import React, { useContext } from 'react';
import { Text } from 'react-native';
import { userLocation } from 'states/map';
import { ParkZone } from 'types/generatedSchemaTypes';
import { getDistanceText } from 'utils/commonUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { getDistanceFromLatLonInMeters } from 'utils/mapUtils';
import { getLocationIconType, locationIcon } from 'utils/parkUtils';
import AddressAndLocation from './AddressAndLocation';
import { IconWrapper, Row } from './styles/styles';

type ParkingZoneDetailsHeadlineProps = {
  parkZone: ParkZone;
  markerCoordinate?: {
    latitude: number;
    longitude: number;
  } | null;
};

const IconComponent = ({ locationIconName }: { locationIconName: string }) => {
  return (
    <IconWrapper mr={3}>
      <Icon
        name={locationIcon[locationIconName]}
        size="s"
        testID={`location-icon-${locationIconName}`}
      />
    </IconWrapper>
  );
};

const ParkingZoneDetailsHeadline: React.FC<ParkingZoneDetailsHeadlineProps> = ({
  parkZone,
  markerCoordinate,
}) => {
  const {
    commercialZoneView,
    name,
    locationType,
    parkingMethods,
    address,
    parkingZoneChargingStations,
  } = parkZone || {};
  let distance: string | undefined;
  const currentLocation = useReactiveVar(userLocation);
  const {
    flags: { anpr },
  } = useContext(FeatureFlagsContext);
  const showEVCharging = useFeatureFlagEVCharging();
  const showCameraRecognition = showFeatureFlag(anpr);
  const locationIconName = getLocationIconType(
    locationType || 'OTHER',
    undefined
  );
  if (currentLocation && markerCoordinate) {
    const distanceNumber = getDistanceFromLatLonInMeters(
      currentLocation,
      markerCoordinate
    );
    distance = getDistanceText(distanceNumber / 1000);
  }
  return (
    <>
      <Row minHeight={60} mb={0} mt={0}>
        <Text numberOfLines={2}>
          <Headline2
            color={theme.colors.primary}
            testID="parkzone-details-commercial-zone">
            {commercialZoneView?.zoneCode
              ? `${commercialZoneView?.zoneCode} `
              : ''}
          </Headline2>
          <Headline2 testID="parkzone-details-name">
            {commercialZoneView?.name || name}
          </Headline2>
        </Text>
      </Row>
      <Row mb={0} mt={2}>
        {!parkingMethods?.unmanagedParking && (
          <IconComponent locationIconName={locationIconName} />
        )}
        {showCameraRecognition && parkingMethods?.anpr && (
          <IconComponent locationIconName="anpr" />
        )}
        {showEVCharging &&
          parkingZoneChargingStations &&
          parkingZoneChargingStations.length > 0 && (
            <IconComponent locationIconName="evCharging" />
          )}
        <AddressAndLocation
          color={theme.colors.gray200}
          address={address}
          name={commercialZoneView?.name || name || ''}
          locationText={distance}
          numberOfLines={2}
          addressAsLink={true}
          testID="parkzone-details-type-address"
        />
      </Row>
    </>
  );
};

export default ParkingZoneDetailsHeadline;
