import Icon from 'components/icons/Icon';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Maybe, AddressProps } from 'types/generatedSchemaTypes';
import { navigateWithCoordinates } from 'utils/mapUtils';
import { printAddress } from './AddressAndLocation';
import ZoneDetailsRow from './ZoneDetailsRow';

type DirectionsProps = {
    address?: Maybe<AddressProps>,
    name?: string
}

const Directions = ({
    address,
    name
} : DirectionsProps) => {
  const { t } = useTranslation();

  if (!address || isEmpty(address) || !address.marker || isEmpty(address.marker.coordinates)) {
    return <></>;
  }

  const onPress = () => {
    navigateWithCoordinates(address?.marker?.coordinates, name);
  };

  return (
    <ZoneDetailsRow
      icon={<Icon
        name="Location"
        size="s"
      />}
      title={printAddress(address || {}, name)}
      linkText={t('parkingZone.map')}
      onPress={onPress}
    />
  );
};

export default Directions;
