import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { CompletedScreen } from 'screens/longTermParking/index';
import { useTranslation } from 'react-i18next';
import { theme } from 'aimo-ui';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';
import StreetParkingPurchaseScreen from 'screens/streetParking/StreetParkingPurchaseScreen';
import StreetPermitActionScreen from 'screens/streetParking/StreetPermitActionScreen';
import StreetParkingApplyScreen from 'screens/streetParking/StreetParkingApplyScreen';

const styles = {
  flex: {
    flex: 1,
    borderTopLeftRadius: 140,
  },
  cardStyle: {
    backgroundColor: theme.colors.white,
  },
  applyStyle: {
    backgroundColor: theme.colors.gray100,
  },
  thankYouStyle: {
    backgroundColor: theme.colors.lightBg.green,
  },
};
const StreetParkingStack = createStackNavigator();

const StreetParkingPurchaseNavigator: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ColumnWithMargins
      mt={isWeb ? 0 : 5}
      mr={isWeb ? 0 : 20}
      ml={isWeb ? 0 : 20}
      style={styles.flex}>
      <StreetParkingStack.Navigator
        screenOptions={{
          animationEnabled: true,
          cardStyle: styles.cardStyle,
        }}>
        <StreetParkingStack.Screen
          name="viewScreen"
          component={StreetPermitActionScreen}
          options={{
            headerShown: false,
            headerBackTitleVisible: false,
          }}
        />
        <StreetParkingStack.Screen
          name="purchase"
          component={StreetParkingPurchaseScreen}
          options={{
            headerShown: false,
            headerBackTitleVisible: false,
            title: t('longTermParking.buyScreenTitle'),
          }}
        />
        <StreetParkingStack.Group
          screenOptions={{
            cardStyle: styles.applyStyle,
          }}>
          <StreetParkingStack.Screen
            name="apply"
            component={StreetParkingApplyScreen}
            options={{
              headerShown: false,
            }}
          />
        </StreetParkingStack.Group>
        <StreetParkingStack.Group
          screenOptions={{
            cardStyle: styles.thankYouStyle,
          }}>
          <StreetParkingStack.Screen
            name="completed"
            component={CompletedScreen}
            options={{
              headerShown: false,
              title: t('longTermParking.complete.thankYou'),
            }}
          />
        </StreetParkingStack.Group>
      </StreetParkingStack.Navigator>
    </ColumnWithMargins>
  );
};

export default StreetParkingPurchaseNavigator;
