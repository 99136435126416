import { useTranslation } from 'react-i18next';
import { BaseText, ListItem, theme } from 'aimo-ui';
import React from 'react';
import { ArrowIcon } from 'components/account/vehicle/VehicleIcons';
import styled from 'styled-components/native';

type VehiclePermitSelectorProps = {
  selectedPermit: number;
  onPress: () => void;
};

const TitleWrapper = styled.View`
  margin-left: 20px;
`;

const VehiclePermitSelector = ({
  selectedPermit,
  onPress,
}: VehiclePermitSelectorProps) => {
  const { t } = useTranslation();

  const titleComponent = () => (
    <TitleWrapper>
      <BaseText testID="vehicle-permit">
        {t('vehicle.permit', { count: selectedPermit })}
      </BaseText>
    </TitleWrapper>
  );

  return (
    <ListItem
      testID="edit-vehicle-permit"
      onPress={onPress}
      withBackground={theme.colors.gray50}
      item={null}
      titleComponent={titleComponent}
      indexValue={0}
      rightContentComponent={ArrowIcon}
    />
  );
};

export default VehiclePermitSelector;
