import { Headline3, theme } from 'aimo-ui';
import React from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { SafeAreaViewProps } from 'react-native-safe-area-context';
import { StyledWrapper } from 'styles/ContainerStyles';
import { testIdentifiers } from 'utils/testIdentifiers';

type ExtraPadding = 'all' | 'horizontal' | 'bottom' | undefined;

const SafeAreaHeaderContainer: React.FC<
  {
    headline?: string | React.ReactNode;
    subtitle?: string;
    extraPadding?: ExtraPadding;
    leftContent?: React.ReactNode;
    rightContent?: React.ReactNode;
    extraPaddingValue?: number;
    leftAlignHeader?: boolean;
  } & SafeAreaViewProps
> = ({
  headline,
  subtitle,
  leftContent,
  rightContent,
  extraPadding,
  extraPaddingValue = 20,
  children,
  leftAlignHeader = false,
  ...props
}) => {
  const rightContentSize = useSharedValue(48);

  const isHeadlineString = typeof headline === 'string';
  const headlineLength = isHeadlineString ? headline.length : 0;

  const animatedStyle = useAnimatedStyle(() => {
    return {
      marginRight:
        rightContentSize.value - Math.max(0, headlineLength - 20) * 2.5,
    };
  }, [rightContentSize]);

  return (
    <SafeAreaView
      {...props}
      style={[
        props.style,
        styles(
          extraPaddingValue,
          extraPadding,
          !!headline,
          !!rightContent,
          !!leftContent
        ).safeView,
      ]}>
      <View
        style={[
          styles(
            extraPaddingValue,
            extraPadding,
            !!headline,
            !!rightContent,
            !!leftContent
          ).container,
        ]}>
        {!leftAlignHeader && rightContent && !leftContent && (
          <Animated.View style={animatedStyle} />
        )}
        {leftContent}
        {isHeadlineString ? (
          <StyledWrapper center mb={0}>
            <Headline3 {...testIdentifiers(headline)} center>
              {headline}
            </Headline3>
          </StyledWrapper>
        ) : (
          headline
        )}
        {children}
        {!!subtitle && subtitle?.length > 0 && (
          <Headline3
            {...testIdentifiers(subtitle)}
            color={theme.colors.gray200}>
            {subtitle}
          </Headline3>
        )}
        {rightContent && (
          <View
            onLayout={(e) =>
              (rightContentSize.value = withTiming(e.nativeEvent.layout.width, {
                duration: 500,
              }))
            }>
            {rightContent}
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

export default SafeAreaHeaderContainer;

const shouldApplyPadding = (
  extraPaddingValue: number,
  extraPadding: ExtraPadding
) => {
  if (!extraPadding) {
    return {};
  }
  if (extraPadding === 'bottom') {
    return {
      paddingBottom: extraPaddingValue,
    };
  }
  return {
    paddingHorizontal: extraPaddingValue,
    paddingVertical: extraPadding === 'all' ? extraPaddingValue : 0,
  };
};

const contentJustify = (
  headline: boolean,
  rightContent: boolean,
  leftContent: boolean
) => {
  const onlyLeftContent = !headline && !rightContent && leftContent;
  return rightContent || onlyLeftContent ? 'space-between' : 'center';
};

const styles = (
  extraPaddingValue: number,
  extraPadding: ExtraPadding,
  headline: boolean,
  rightContent: boolean,
  leftContent: boolean
) => {
  return StyleSheet.create({
    safeView: { justifyContent: 'center' },
    container: {
      ...shouldApplyPadding(extraPaddingValue, extraPadding),
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: contentJustify(headline, rightContent, leftContent),
      flexGrow: 1,
    },
  });
};
