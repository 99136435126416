import styled from 'styled-components/native';

export const Divider = styled.View<{
  marginVertical?: number;
  size?: number;
  color?: string;
  marginHorizontal?: number;
}>`
  height: ${(p) => p.size ?? 3}px;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  margin-left: ${(p) => p.marginHorizontal ?? 0}px;
  margin-right: ${(p) => p.marginHorizontal ?? 0}px;
  margin-top: ${(p) => p.marginVertical ?? 12}px;
  margin-bottom: ${(p) => p.marginVertical ?? 12}px;
  background-color: ${(p) => p.color ?? p.theme.colors.gray50};
`;

export const Pill = styled.View<{
  bg?: string;
  alignLeft?: boolean;
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  px?: number;
  py?: number;
}>`
  background: ${(p) => p.bg ?? p.theme.colors.lightBg.green};
  display: flex;
  margin-bottom: ${(p) => p.mb ?? 0}px;
  margin-top: ${(p) => p.mt ?? 0}px;
  margin-left: ${(p) => p.ml ?? 0}px;
  margin-right: ${(p) => p.mr ?? 0}px;
  flex-direction: row;
  justify-content: ${(p) => (p.alignLeft ? 'flex-start' : 'center')};
  border-radius: 60px;
  align-items: center;
  padding: ${(p) => p.py ?? 10}px ${(p) => p.px ?? 10}px;
`;
