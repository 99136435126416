import { gql } from '@apollo/client';

export const UPDATE_PARKING_SESSION = gql`
  mutation UpdateParkingSession($input: UpdateParkingSessionInput!) {
    updateParkingSession(input: $input) {
      sessionId
      type
      changelog {
        created
        from {
          accessDeviceValue
        }
        to {
          accessDeviceValue
        }
      }
      reassignments {
        reassignmentsLeft
        waitingHours
      }
    }
  }
`;
