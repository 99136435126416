import { theme, TimePicker, useTimeWheel } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import PriceEstimateContext from 'context/PriceEstimateContext';
import { format, isAfter, isBefore, isSameDay, isSameMinute } from 'date-fns';
import useFetchPriceEstimate from 'hooks/useFetchPriceEstimate';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
import { getDateFromTextString } from 'utils/timeUtils';
import DatePicker from './DatePicker';
import {
  DEBOUNCE_ENDTIME_VALUE,
  PriceEstimateWrapper,
  TimeSelectionProps,
} from './TimeSelectionCommon';

const Wrapper = styled.View`
  align-items: center;
`;

const ButtonIcon: React.FC<{ icon: string; darkMode?: boolean }> = ({
  icon,
  darkMode = false,
}) => (
  <Icon
    name={icon}
    size="xl"
    color={darkMode ? theme.colors.white : theme.colors.black}
  />
);

const TIME_FORMAT = 'HH:mm';

const TimeSelection: React.FC<TimeSelectionProps> = ({
  parkingZoneUid,
  parkingZoneCountryCode,
  sessionId,
  darkMode = false,
  testID,
  minDate = new Date(),
  maxDate,
  startTime,
  endTime = new Date(),
  initTime,
  setEndTimeCallback,
  setIsValidEndTimeCallback,
}) => {
  const { setStartButtonOpacity } = useContext(PriceEstimateContext);

  const controllerRef = useRef<AbortController>();

  useFetchPriceEstimate({
    controllerRef,
    endTime,
    startTime,
    parkingZoneUid,
    sessionId,
  });

  const debounceEndTimeCallback = useRef(
    debounce((endDate: Date) => {
      setEndTimeCallback(endDate);
    }, DEBOUNCE_ENDTIME_VALUE)
  );

  useEffect(() => {
    if (initTime) {
      setEndTimeCallback(initTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTime]);

  const timeConverter = (convertedDate: Date | undefined) =>
    convertedDate ? format(convertedDate, TIME_FORMAT) : undefined;

  const startLimit = useMemo(() => timeConverter(new Date()), []);

  const endLimit = useMemo(() => {
    return maxDate && isSameDay(endTime, maxDate)
      ? timeConverter(maxDate)
      : '23:59';
  }, [endTime, maxDate]);

  const { translateY, currentStepText, minuteCount, config } = useTimeWheel();

  const updateValue = (value?: string, isValid?: boolean) => {
    setStartButtonOpacity(0);
    const dateFromString = getDateFromTextString(value ?? '', endTime);
    const newDate = new Date(dateFromString);
    if (!endTime || !isSameMinute(endTime, dateFromString)) {
      debounceEndTimeCallback.current(newDate);
      if (setIsValidEndTimeCallback) {
        const validity =
          !!isValid &&
          !(
            startLimit &&
            isBefore(
              dateFromString,
              getDateFromTextString(startLimit, new Date())
            )
          ) &&
          !(endLimit && maxDate && isAfter(dateFromString, maxDate));
        setIsValidEndTimeCallback(validity);
      }
    } else {
      setStartButtonOpacity(1);
    }
  };

  const handleDateChange = (newDate: Date) => {
    const dateFromString = getDateFromTextString(
      timeConverter(endTime) ?? '',
      newDate
    );
    setEndTimeCallback(dateFromString);
  };

  const BackIcon = useCallback(
    () => <ButtonIcon darkMode={darkMode} icon="minus30" />,
    [darkMode]
  );
  const ForwardIcon = useCallback(
    () => <ButtonIcon darkMode={darkMode} icon="plus30" />,
    [darkMode]
  );

  return (
    <Wrapper testID={testID}>
      <StyledWrapper center mb={20}>
        <DatePicker
          darkMode={darkMode}
          currentDate={endTime}
          animatedDate={currentStepText}
          setDate={handleDateChange}
          testID={testID}
          minDate={minDate}
          maxDate={maxDate}
        />
      </StyledWrapper>
      <TimePicker
        config={config}
        minuteCount={minuteCount}
        translateY={translateY}
        timeChangeCallback={updateValue}
        startLimit={startLimit}
        endLimit={endLimit}
        backIcon={BackIcon}
        forwardIcon={ForwardIcon}
        jumpTime={30}
        testID={`${testID}-time-picker`}
        initTime={initTime}
      />
      <PriceEstimateWrapper
        darkMode={darkMode}
        parkingZoneCountryCode={parkingZoneCountryCode}
        testID={testID}
      />
    </Wrapper>
  );
};

export default TimeSelection;
