import { theme } from 'aimo-ui';
import WelcomeSignedContent from 'components/account/WelcomeSignedContent';
import BGView from 'components/common/BGView';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';
import useFeatureFlagEVCharging from 'hooks/useFeatureFlagEVCharging';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import {
  ScreenContainer,
  ScreenWrapper,
  StyledIllustrationImage,
  StyledImage,
} from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import LoginStates from './LoginStates';

const ImageWrapperForOnboardingV2 = styled.View`
  justify-content: flex-end;
  flex: 1;
  transform: translate(0, 52px);
  z-index: 999;
  margin-top: -60px;
`;

const contentContainerStyle = { flexGrow: 1 };

const AccountDefault: React.FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useIsLoggedIn();
  const tabBarHeight = useBottomMenuHeight();
  const {
    flags: { account_create, onboarding_v2 },
  } = useContext(FeatureFlagsContext);
  const showAccountCreate = showFeatureFlag(account_create);
  const showEVCharging = useFeatureFlagEVCharging();
  const useOnboardingV2 = showFeatureFlag(onboarding_v2);

  let loginText = '';

  if (useOnboardingV2) {
    loginText = t('account.notSISUDescription');
  } else {
    if (showAccountCreate) {
      loginText = showEVCharging
        ? t('account.notSignedInDescription')
        : t('account.notSignedInDescriptionBetaWithoutEV');
    } else {
      if (!showEVCharging) {
        loginText = t(
          'account.notSignedInDescriptionBetaWithoutAccountWithoutEV'
        );
      }
    }
  }

  const renderServiceScreenInWeb = () => {
    return (
      <>
        <StyledImage
          testID="account-main-image"
          width={309}
          height={useOnboardingV2 ? 300 : 200}
          center
          source={
            useOnboardingV2
              ? require('assets/images/illustration-account.png')
              : require('assets/images/camera.png')
          }
        />
        <LoginStates fixedHeader={true} loginText={loginText} />
      </>
    );
  };

  const renderAccountScreenInNative = () => {
    return useOnboardingV2 ? (
      <ScreenWrapper
        contentContainerStyle={contentContainerStyle}
        padding={0}
        tabBarHeight={tabBarHeight}>
        <ImageWrapperForOnboardingV2>
          <StyledIllustrationImage
            testID="account-main-image"
            center
            resizeMode="contain"
            source={require('assets/images/illustration-account.png')}
          />
        </ImageWrapperForOnboardingV2>
        <LoginStates fixedHeader loginText={loginText} />
      </ScreenWrapper>
    ) : (
      <>
        <ScreenWrapper tabBarHeight={tabBarHeight}>
          <StyledImage
            testID="account-main-image"
            width={309}
            height={200}
            center
            source={require('assets/images/camera.png')}
          />
        </ScreenWrapper>
        <LoginStates fixedHeader loginText={loginText} />
      </>
    );
  };

  const renderer = () =>
    isWeb ? (
      <ScreenWrapper tabBarHeight={tabBarHeight}>
        {isLoggedIn ? <WelcomeSignedContent /> : renderServiceScreenInWeb()}
      </ScreenWrapper>
    ) : (
      <>
        {isLoggedIn ? (
          <BGView noMargins paddings={{ top: 30, left: 20, right: 20 }}>
            <WelcomeSignedContent />
          </BGView>
        ) : (
          renderAccountScreenInNative()
        )}
      </>
    );

  return (
    <ScreenContainer
      background={isWeb ? theme.colors.white : theme.colors.lightBg.pink}
      mt={isWeb ? 170 : 0}>
      {renderer()}
    </ScreenContainer>
  );
};

export default AccountDefault;
