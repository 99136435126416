import { gql } from '@apollo/client';

export const GET_PARK_ZONES = gql`
  query parkZones($input: ParkZonesQueryInput!) {
    parkZones(input: $input) {
      content
      totalPages
    }
  }
`;
