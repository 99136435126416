import { theme } from 'aimo-ui';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';
import React from 'react';
import styled from 'styled-components/native';
import { isWeb } from 'utils/deviceUtils';

const ParkNowButtonContainer = styled.View<{
  bgColor?: string;
  tabBarHeight: number;
}>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: ${(p) => `${p.tabBarHeight && !isWeb ? p.tabBarHeight : 0}px`};
  background-color: ${(p) => p.bgColor || theme.colors.white};
`;

const WebContainer = styled.View`
  flex-direction: row;
  width: 100%;
  background-color: ${theme.colors.white};
`;

type StickySheetFooterProps = {
  bgColor?: string;
  children?: React.ReactNode;
  visible: boolean;
};

const StickySheetFooter = ({
  bgColor,
  children,
  visible,
}: StickySheetFooterProps) => {
  const tabBarHeight = useBottomMenuHeight();

  if (!visible || !children) {
    return <></>;
  }

  if (isWeb) {
    return <WebContainer>{children}</WebContainer>;
  }

  return (
    <ParkNowButtonContainer bgColor={bgColor} tabBarHeight={tabBarHeight}>
      {children}
    </ParkNowButtonContainer>
  );
};

export default StickySheetFooter;
