import { useQuery, useReactiveVar } from '@apollo/client';
import { READ_CUSTOMER_PMCS_DETAILS } from '@queries/readCustomerPmcsDetails';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Spinner } from 'aimo-ui';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import LongTermParkingContext from 'context/LongTermParkingContext';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import useHasVehicleAndCreditCard from 'hooks/useHasVehicleAndCreditCard';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import React, { useContext, useEffect, useMemo } from 'react';
import { onboardingCompleted } from 'states/persistInStorage';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import {
  Pmc,
  Query,
  QueryReadCustomerPmcsArgs,
  ReservedParkingSpace,
} from 'types/generatedSchemaTypes';
import { LongTermStackParamList } from 'types/navigation';
import { getPriceForSalesOrder } from 'utils/commonUtils';
import NonReservedPermits from './NonReservedPermits';
import ReservedPermits from './ReservedPermits';
import AccountContext from 'context/AccountContext';
import { isPmcVisible, sortPmcsByVisibility } from 'utils/permitUtils';

const ParkingZonePermits = ({ parkingZoneUid }: { parkingZoneUid: string }) => {
  const { isLoggedIn } = useIsLoggedIn();
  const isOnboardingCompleted = useReactiveVar(onboardingCompleted);
  const hasVehicleAndCard = useHasVehicleAndCreditCard();
  const { setSelectedPmcs } = useContext(LongTermParkingContext);
  const { navigate } =
    useNavigation<StackNavigationProp<LongTermStackParamList>>();
  const {
    customer: { householdBenefits },
  } = useContext(AccountContext);
  const { data, loading, error } = useQuery<Query, QueryReadCustomerPmcsArgs>(
    READ_CUSTOMER_PMCS_DETAILS,
    {
      variables: {
        input: {
          parkingZoneUids: [parkingZoneUid],
          permitType: 'SALES_ORDER',
        },
      },
      skip: !parkingZoneUid,
      fetchPolicy: 'cache-and-network',
    }
  );
  const {
    setReturnRedirectToBuyPermit,
    navigateToAccountLogin,
    navigateToOnboarding,
  } = useAfterAuthenticationRedirect();

  const permits = useMemo(() => {
    return sortPmcsByVisibility(data?.readCustomerPmcs);
  }, [data?.readCustomerPmcs]);

  const handleNavigate = (pmcId: string) => {
    if (!isLoggedIn) {
      setReturnRedirectToBuyPermit(pmcId);
      navigateToAccountLogin();
      return;
    }
    if (!isOnboardingCompleted) {
      // TODO make the parameter to navigate surely to onboarding -> simplify
      if (hasVehicleAndCard) {
        onboardingCompleted(true);
      } else {
        setReturnRedirectToBuyPermit(pmcId, undefined, false);
        navigateToOnboarding();
        return;
      }
    }

    navigate('buyScreen', { pmcId });
  };

  useEffect(() => {
    if (permits) {
      setSelectedPmcs(permits);
    }
  }, [permits, setSelectedPmcs]);

  if (loading) {
    return (
      <ColumnWithMargins mt={30}>
        <StyledWrapper center mb={20}>
          <Spinner />
        </StyledWrapper>
      </ColumnWithMargins>
    );
  }

  if (error || !data?.readCustomerPmcs) {
    return <></>;
  }

  return (
    <BottomSheetScrollViewCustom>
      <ColumnWithMargins mt={30}>
        {permits
          ?.filter((permit) => isPmcVisible(permit, householdBenefits ?? []))
          .map((permit: Pmc, index: number) => {
            const {
              pmcId = '',
              availability,
              name = '',
              prices,
              reservationType,
              parkingSpacesV2,
              countryCode: permitCountry,
            } = permit;
            const thirtyDayPrice = getPriceForSalesOrder(prices, permitCountry);
            const memberGroupName = householdBenefits?.find(
              (benefit) => benefit.benefitTargetUid === pmcId
            )?.name;
            if (reservationType === 'RESERVED') {
              return (
                <ReservedPermits
                  parkingSpaces={
                    (parkingSpacesV2?.content ?? []) as ReservedParkingSpace[]
                  }
                  priceLabel={thirtyDayPrice}
                  pmcId={pmcId as string}
                  name={name as string}
                  testID={`reserved-permits-${index}`}
                  key={`${pmcId}-${index}`}
                  handleNavigate={handleNavigate}
                  memberGroupName={memberGroupName}
                />
              );
            }
            return (
              <NonReservedPermits
                key={`${pmcId}-${index}`}
                pmcId={pmcId as string}
                name={name as string}
                testID={`non-reserved-permits-${index}`}
                availability={availability}
                priceLabel={thirtyDayPrice}
                handleNavigate={handleNavigate}
                memberGroupName={memberGroupName}
              />
            );
          })}
      </ColumnWithMargins>
    </BottomSheetScrollViewCustom>
  );
};

export default ParkingZonePermits;
