import { createStackNavigator } from '@react-navigation/stack';
import { theme } from 'aimo-ui';
import ServicesDefault from 'components/services/ServicesDefault';
import StackHeader from 'navigation/StackHeader';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Permit from 'screens/permit/Permit';
import PermitEdit from 'screens/permit/PermitEdit';
import Permits from 'screens/permit/Permits';
import PrepaidTicket from 'screens/prepaidTickets/PrepaidTicket';
import PrepaidTicketEdit from 'screens/prepaidTickets/PrepaidTicketEdit';
import PrepaidTickets from 'screens/prepaidTickets/PrepaidTickets';
import { isWeb } from 'utils/deviceUtils';
import { ServicesStackParamList } from 'types/navigation';
import {
  selectedLicensePlatesPermitRedeem,
  tabBarVisible,
} from 'states/common';
import AccessPermitRedeem from 'screens/permit/access/AccessPermitRedeem';
import ModalStackHeader, {
  StackHeaderCustomProps,
} from 'navigation/ModalStackHeader';
import { getModalOptions } from 'utils/navigationUtils';
import AccessPermitSelectVehicle from 'screens/permit/access/AccessPermitSelectVehicle';
import VehicleAdd from 'components/account/vehicle/VehicleAdd';

const ServicesStack = createStackNavigator<ServicesStackParamList>();

const ModalHeaderComponent = (props: StackHeaderCustomProps) => (
  <ModalStackHeader
    {...props}
    closeButtonType="tertiary"
    backButtonType="tertiary"
  />
);

const ServicesNavigator: React.FC = () => {
  const { t } = useTranslation();
  const modalFullScreenOptions = useMemo(
    () => getModalOptions(undefined, true),
    []
  );
  const modalPartialScreenOptions = useMemo(
    () => getModalOptions('70%', true),
    []
  );

  return (
    <ServicesStack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerTransparent: isWeb,
        animationEnabled: true,
        cardStyle: {
          backgroundColor: isWeb ? theme.colors.white : theme.colors.gray100,
        },
        header: (props) => (
          <StackHeader {...props} background={theme.colors.gray100} />
        ),
      }}>
      <ServicesStack.Screen
        name="servicesMain"
        component={ServicesDefault}
        options={{ title: t('services.mainTitle'), headerShown: false }}
        listeners={{
          focus: () => {
            tabBarVisible(true);
          },
        }}
      />
      <ServicesStack.Screen
        name="permits"
        component={Permits}
        options={{
          title: t('services.permits'),
          headerBackTitleVisible: true,
          headerTransparent: false,
          headerShown: true,
          cardStyle: {
            backgroundColor: theme.colors.gray100,
          },
        }}
      />
      <ServicesStack.Screen
        name="permit"
        component={Permit}
        options={{
          headerBackTitleVisible: true,
          headerTransparent: false,
          headerShown: true,
          cardStyle: {
            backgroundColor: theme.colors.gray100,
          },
          header: (props) => (
            <StackHeader
              {...props}
              background={isWeb ? theme.colors.white : theme.colors.gray100}
            />
          ),
        }}
      />
      <ServicesStack.Screen
        name="permitEdit"
        component={PermitEdit}
        options={{
          headerShown: false,
        }}
      />
      <ServicesStack.Screen
        name="permitRedeem"
        component={AccessPermitRedeem}
        options={{
          presentation: 'modal',
          title: t('permit.access.redeemPermit'),
          headerBackTitleVisible: true,
          header: (props) =>
            ModalHeaderComponent({
              ...props,
              ...modalFullScreenOptions.headerStyle,
              closeButton: true,
              forceBackButton: true,
            }),
          cardStyle: modalFullScreenOptions.cardStyle,
        }}
        listeners={{
          focus: () => {
            tabBarVisible(false);
          },
          beforeRemove: () => {
            selectedLicensePlatesPermitRedeem([]);
          },
        }}
      />
      <ServicesStack.Screen
        name="permitVehicles"
        component={AccessPermitSelectVehicle}
        options={{
          presentation: 'modal',
          title: t('parking.parkingProcess.selectVehicle'),
          headerBackTitleVisible: true,
          header: (props) =>
            ModalHeaderComponent({
              ...props,
              ...modalPartialScreenOptions.headerStyle,
              closeButton: true,
              forceBackButton: false,
            }),
          cardStyle: isWeb
            ? modalFullScreenOptions.cardStyle
            : modalPartialScreenOptions.cardStyle,
        }}
        listeners={{
          focus: () => {
            tabBarVisible(false);
          },
        }}
      />
      <ServicesStack.Screen
        name="addVehiclePermit"
        component={VehicleAdd}
        options={{
          ...modalFullScreenOptions.screenOptions,
          title: t('permit.access.addVehicle'),
          header: (props) =>
            ModalHeaderComponent({
              ...props,
              ...modalFullScreenOptions.headerStyle,
            }),
          cardStyle: modalFullScreenOptions.cardStyle,
        }}
        listeners={{
          focus: () => {
            tabBarVisible(false);
          },
        }}
      />
      <ServicesStack.Screen
        name="prepaidTickets"
        component={PrepaidTickets}
        options={{
          title: t('services.prepaidTickets'),
          headerBackTitleVisible: true,
          headerTransparent: false,
          headerShown: true,
          cardStyle: {
            backgroundColor: theme.colors.gray100,
          },
        }}
      />
      <ServicesStack.Screen
        name="prepaidTicket"
        component={PrepaidTicket}
        options={{
          title: t('services.prepaidTickets'),
          headerBackTitleVisible: true,
          headerTransparent: false,
          headerShown: true,
          cardStyle: {
            backgroundColor: theme.colors.gray100,
          },
        }}
        listeners={{
          focus: () => {
            tabBarVisible(false);
          },
        }}
      />
      <ServicesStack.Screen
        name="prepaidEdit"
        component={PrepaidTicketEdit}
        options={{
          headerShown: false,
        }}
        listeners={{
          focus: () => {
            tabBarVisible(false);
          },
        }}
      />
    </ServicesStack.Navigator>
  );
};

export default ServicesNavigator;
