import { BaseText, Button, Notification, Title } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { StyledWrapper } from 'styles/ContainerStyles';
import { FooterWrapper, MainWrapper } from 'styles/ModalStackContainerStyles';
import { checkNotificationsPermissions, isIos } from 'utils/deviceUtils';
import AuthManager from 'components/auth/AuthManager';
import { testIdentifiers } from 'utils/testIdentifiers';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';

const LogoIcon = () => <Icon name="AimoApp-Rounded" size="m" />;

const PushNotifications = () => {
  const { t } = useTranslation();
  const { setOnboardingCompleted } = useAfterAuthenticationRedirect();

  const handleAllow = async () => {
    if (isIos) {
      const notificationsDenied = await checkNotificationsPermissions('denied');
      if (notificationsDenied) {
        const token = await AuthManager.fetchDeviceToken();
        await AuthManager.setDeviceToken(token);
      } else {
        Linking.openSettings();
      }
    } else {
      Linking.openSettings();
    }
    setOnboardingCompleted();
  };

  return (
    <>
      <MainWrapper vAlignCenter={true}>
        <StyledWrapper maxWidth={350} center mb={30}>
          <Notification
            title={t('onboarding.parkingReminderTitle')}
            text={t('onboarding.parkingReminder')}
            time={t('time.now')}
            iconComponent={LogoIcon}
            testID="onboarding-reminder-note"
          />
        </StyledWrapper>
        <StyledWrapper maxWidth={350} center mb={35}>
          <Title
            {...testIdentifiers('onboarding-pushnotification-title')}
            center>
            {t('onboarding.allowPushNotifications')}
          </Title>
        </StyledWrapper>
        <StyledWrapper maxWidth={250} center mb={50}>
          <BaseText testID="onboarding-pushnotification-description" center>
            {t('onboarding.allowPushNotificationsDescription')}
          </BaseText>
        </StyledWrapper>
      </MainWrapper>
      <FooterWrapper>
        <StyledWrapper maxWidth={350} center mb={10}>
          <Button
            text={t('onboarding.allowPushNotifications')}
            size="l"
            buttonType="secondary"
            onPress={handleAllow}
            testID="onboarding-allow-notes-button"
          />
        </StyledWrapper>
        <StyledWrapper maxWidth={350} center mb={10}>
          <Button
            text={t('onboarding.maybeLater')}
            size="l"
            buttonType="link"
            onPress={() => setOnboardingCompleted()}
            {...testIdentifiers('onboarding-maybe-later-button')}
          />
        </StyledWrapper>
      </FooterWrapper>
    </>
  );
};

export default PushNotifications;
