import React from 'react';
import { Meta, theme } from 'aimo-ui';
import styled from 'styled-components/native';
import { CenterVerticalRow } from 'styles/ContainerStyles';
import { LicensePlate } from 'types/generatedSchemaTypes';

const VehicleCountryCodeWrapper = styled.View`
  background-color: ${(props) => props.theme.colors.lightBg.navyBlue};
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 2px;
  align-items: center;
  padding: 2px;
`;

type VehicleLicensePlateProps = {
  licensePlate: LicensePlate;
};

const VehicleLicensePlate = ({ licensePlate }: VehicleLicensePlateProps) => {
  return (
    <CenterVerticalRow>
      {licensePlate?.countryCode && (
        <>
          <VehicleCountryCodeWrapper>
            <Meta color={theme.colors.white}>{licensePlate.countryCode}</Meta>
          </VehicleCountryCodeWrapper>
          <Meta> </Meta>
        </>
      )}
      <Meta
        color={theme.colors.gray200}
        testID={`vehicle-license-${licensePlate?.plateText}`}>
        {licensePlate?.plateText}
      </Meta>
    </CenterVerticalRow>
  );
};

export default VehicleLicensePlate;
