import { useQuery } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import {
  BaseText,
  BottomButtonsLayout,
  Button,
  Headline1,
  Meta,
  Spinner,
  theme,
} from 'aimo-ui';
import Sheet from 'components/sheets/Sheet';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { READ_RECEIPT_WITH_SALES_ORDER } from 'graphql/queries/readReceiptWithSalesOrder';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';
import usePollSalesOrderStatus from 'hooks/usePollSalesOrderStatus';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { WebWrapper } from 'screens/account/SheetWrapper';
import PermitInfo from 'screens/permit/PermitInfo';
import PermitPaymentInfo from 'screens/permit/PermitPaymentInfo';
import { selectedPermit, sheetModal, tabBarVisible } from 'states/common';
import styled from 'styled-components/native';
import {
  ColumnWithMargins,
  ScreenContainer,
  StyledWrapper,
} from 'styles/ContainerStyles';
import {
  BillingFrequency,
  CountryCode,
  Maybe,
  Pmc,
  Query,
  QueryReadReceiptByTransactionIdArgs,
} from 'types/generatedSchemaTypes';
import { ServicesNavProps, ServicesStackParamList } from 'types/navigation';
import { currencyFormatter, isSalesOrderFree } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { SalesOrderExpiration, salesOrderExpiration } from 'utils/permitUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import ActivatePermitButton from './ActivatePermitButton';
import { ActivateInfoBox, PaymentErrorInfoBox } from './PermitInfoBoxes';
import { SalesOrderReceipt } from './SalesOrderReceiptClass';
import { WebContentWrapper } from './styles';

const GreyWrapper = styled.View`
  background-color: ${isWeb ? theme.colors.white : theme.colors.gray100};
`;

type ContentProps = {
  pmc: Maybe<Pmc> | undefined;
  totalPrice: number | undefined;
  historyCountry: Maybe<CountryCode> | undefined;
  billingFrequency: Maybe<BillingFrequency> | undefined;
  recurring: boolean;
  salesOrder: SalesOrderReceipt;
  isHistory: boolean;
  isInvoicing: boolean;
  expiration: SalesOrderExpiration;
};

const Content = ({
  pmc,
  billingFrequency,
  historyCountry,
  recurring,
  salesOrder,
  totalPrice,
  isHistory,
  isInvoicing,
  expiration,
}: ContentProps) => {
  const { t } = useTranslation();
  const freeSalesOrder = isSalesOrderFree(pmc?.prices);

  const permitRenewalText = () => {
    if (recurring && freeSalesOrder) {
      return t('cityPermits.renewInfo');
    }
    if (recurring) {
      return t(`longTermParking.recurring.${billingFrequency}`);
    }
    return t('longTermParking.oneTimeFee');
  };

  return (
    <>
      <StyledWrapper mb={0} maxWidth={400}>
        <BaseText center testID="permit-details-header">
          {pmc?.parkZone?.name}
          {pmc?.parkZone?.address?.city
            ? `, ${pmc?.parkZone.address.city}`
            : ''}
        </BaseText>
      </StyledWrapper>
      <StyledWrapper mb={5}>
        <BaseText center testID="permit-details-name">
          {pmc?.name}
        </BaseText>
      </StyledWrapper>
      <StyledWrapper center mb={5}>
        <Headline1 center {...testIdentifiers('permit-details-price')}>
          {!!totalPrice &&
            historyCountry &&
            currencyFormatter(totalPrice, historyCountry)}
        </Headline1>
        {billingFrequency !== undefined && (
          <Meta
            center
            color={theme.colors.gray200}
            testID="permit-details-type">
            {permitRenewalText()}
          </Meta>
        )}
      </StyledWrapper>
      <View>
        <PaymentErrorInfoBox salesOrder={salesOrder} />
        {salesOrder.isInProgress && (
          <ActivateInfoBox salesOrder={salesOrder} expiration={expiration} />
        )}
        <StyledWrapper mb={40}>
          <PermitInfo isHistory={isHistory} salesOrder={salesOrder} />
        </StyledWrapper>
        {!isInvoicing && (
          <StyledWrapper mb={10}>
            <PermitPaymentInfo salesOrder={salesOrder} isHistory={isHistory} />
          </StyledWrapper>
        )}
      </View>
    </>
  );
};

type ButtonProps = {
  isHistory: boolean;
  permitId: string;
  handleEditPermit: () => void;
  handleTerminatePermit: () => void;
  t: (text: string) => string;
  salesOrder: SalesOrderReceipt;
  isExpired: boolean;
  isCancelable?: boolean;
};

const Buttons: React.FC<ButtonProps> = ({
  isHistory,
  isCancelable,
  permitId,
  handleEditPermit,
  handleTerminatePermit,
  t,
  isExpired,
  salesOrder: { isWaiting, isUpdating, isActivating },
}) => (
  <>
    {!isHistory && (
      <>
        <StyledWrapper mb={isCancelable ? 10 : 0}>
          {isWaiting ? (
            <ActivatePermitButton permitId={permitId} isExpired={isExpired} />
          ) : (
            <Button
              {...testIdentifiers('edit-permit-button')}
              testID="edit-permit-button"
              size="l"
              buttonType="tertiary"
              text={t('permit.editPermit')}
              onPress={handleEditPermit}
              disabled={isUpdating || isActivating}
            />
          )}
        </StyledWrapper>

        {isCancelable && (
          <Button
            {...testIdentifiers('cancel-permit-button')}
            testID="cancel-permit-button"
            size="l"
            buttonType="tertiary"
            text={t('button.cancelPermit')}
            onPress={handleTerminatePermit}
          />
        )}
      </>
    )}
  </>
);

type PermitContentProps = {
  salesOrder: SalesOrderReceipt;
  isHistory: boolean;
  permitId: string;
};

const PermitContent = ({
  salesOrder,
  isHistory,
  permitId,
}: PermitContentProps) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<ServicesNavProps>();
  const {
    countryCode,
    orderLines,
    totalAmount,
    isInvoicing,
    orderStatus,
    renewal,
    isWaiting,
  } = salesOrder || {};
  const {
    flags: { cancel_sales_order },
  } = useContext(FeatureFlagsContext);
  const showCancelButton = showFeatureFlag(cancel_sales_order);
  const isCancelable =
    ((orderStatus === 'ACTIVE' && renewal) || isWaiting) && showCancelButton;

  const orderLine = orderLines?.[0];
  const tabBarHeight = useBottomMenuHeight();

  const { pmc, billingFrequency } = orderLine || {};
  const recurring = billingFrequency === 'MONTHLY' && !!renewal;

  const historyCountry = isHistory
    ? salesOrder?.item?.countryCode
    : countryCode;

  const totalPrice = (totalAmount || 0) / 100;

  const expiration = salesOrderExpiration(salesOrder);

  const handleTerminatePermit = () => {
    selectedPermit(permitId);
    sheetModal('terminatePermit');
  };
  const handleEditPermit = () => {
    navigate('permitEdit', { permitId });
  };

  // Hide tab bar
  useEffect(() => {
    !isHistory && tabBarVisible(isWeb);
  }, [isHistory]);

  if (isWeb) {
    const WebContent = () => (
      <ScrollView>
        <StyledWrapper center>
          <WebContentWrapper>
            <ColumnWithMargins ml={20} mr={20}>
              <Content
                pmc={pmc}
                totalPrice={totalPrice}
                historyCountry={historyCountry}
                billingFrequency={billingFrequency}
                recurring={recurring}
                salesOrder={salesOrder}
                isHistory={isHistory}
                isInvoicing={isInvoicing}
                expiration={expiration}
              />
              <Buttons
                isHistory={isHistory}
                permitId={permitId}
                isCancelable={isCancelable}
                handleEditPermit={handleEditPermit}
                handleTerminatePermit={handleTerminatePermit}
                t={t}
                isExpired={expiration.isExpired}
                salesOrder={salesOrder}
              />
            </ColumnWithMargins>
          </WebContentWrapper>
        </StyledWrapper>
      </ScrollView>
    );
    return <WebWrapper page={WebContent} mt={0} />;
  }

  return (
    <ScreenContainer mb={1} mt={10} background={theme.colors.transparent}>
      <Sheet hideHandle snappingPoints={['100%']}>
        <ColumnWithMargins mt={20} ml={20} mr={20} mb={tabBarHeight}>
          <BottomButtonsLayout
            contentBottom={
              <Buttons
                isHistory={isHistory}
                permitId={permitId}
                isCancelable={isCancelable}
                handleEditPermit={handleEditPermit}
                handleTerminatePermit={handleTerminatePermit}
                t={t}
                isExpired={expiration.isExpired}
                salesOrder={salesOrder}
              />
            }>
            <Content
              pmc={pmc}
              totalPrice={totalPrice}
              historyCountry={historyCountry}
              billingFrequency={billingFrequency}
              recurring={recurring}
              salesOrder={salesOrder}
              isHistory={isHistory}
              isInvoicing={isInvoicing}
              expiration={expiration}
            />
          </BottomButtonsLayout>
        </ColumnWithMargins>
      </Sheet>
    </ScreenContainer>
  );
};

const Permit: React.FC<{ isHistory?: boolean }> = ({ isHistory = false }) => {
  const { t } = useTranslation();
  const {
    params: { permitId, transactionId },
  } = useRoute<RouteProp<ServicesStackParamList, 'permit'>>();

  const {
    customer: { permits },
  } = useContext(AccountContext);

  const { data, loading } = useQuery<
    Query,
    QueryReadReceiptByTransactionIdArgs
  >(READ_RECEIPT_WITH_SALES_ORDER, {
    skip: !transactionId,
    variables: {
      transactionId: transactionId as string,
    },
    errorPolicy: 'all',
  });

  const permitData = permits?.find((sOrder) => sOrder?.id === permitId);

  const salesOrderReceipt = new SalesOrderReceipt(
    data?.readReceiptByTransactionId ?? permitData
  );

  usePollSalesOrderStatus(
    salesOrderReceipt.uid,
    salesOrderReceipt.orderStatus ?? 'ACTIVE'
  );

  const orderLine = salesOrderReceipt?.orderLines?.[0];

  const { billingFrequency } = orderLine || {};

  return (
    <>
      <GreyWrapper>
        <StyledWrapper
          center
          mb={5}
          {...testIdentifiers('permit-details-headline')}>
          <Headline1
            center
            {...testIdentifiers('permit-details-headline-text')}>
            {salesOrderReceipt.title}
          </Headline1>
        </StyledWrapper>
        <StyledWrapper center>
          {billingFrequency && (
            <Meta
              color={theme.colors.gray200}
              testID="permit-details-headline-type">
              {t(`permit.type.${billingFrequency}`)}
            </Meta>
          )}
        </StyledWrapper>
      </GreyWrapper>
      {loading ? (
        <StyledWrapper center>
          <Spinner />
        </StyledWrapper>
      ) : (
        <PermitContent
          permitId={permitId as string}
          salesOrder={salesOrderReceipt}
          isHistory={isHistory}
        />
      )}
    </>
  );
};

export default Permit;
