import React from 'react';
import { Pressable } from 'react-native';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { BaseText, Meta } from 'aimo-ui';
import { sheetModal } from 'states/common';

export type ApproveTermsType = 'Location' | 'Updated';

type ApproveTermsTextProps = { type?: ApproveTermsType };

const MODALS = ['privacyPolicy', 'termsAndConditions'];

const CustomLink = ({
  modal,
  children,
  type,
}: {
  modal: string;
  children: React.ReactNode;
  type?: ApproveTermsType;
}) => {
  const StyledLink = type ? StyledLinkBaseText : StyledLinkMetaText;

  return (
    <Pressable onPress={() => sheetModal(modal)}>
      <StyledLink>{children}</StyledLink>
    </Pressable>
  );
};

const ApproveTermsTextContainer = styled.View<{ shrink: boolean }>`
  flex-direction: row;
  flex-wrap: wrap;
  ${(p) => (p.shrink ? 'flex-shrink: 1;' : undefined)}
`;

const StyledLinkBaseText = styled(BaseText)`
  color: ${(p) => p.theme.colors.boost.green};
  text-decoration-line: underline;
`;

const StyledLinkMetaText = styled(Meta)`
  color: ${(p) => p.theme.colors.boost.green};
  text-decoration-line: underline;
`;

const StyledBaseText = styled(BaseText)`
  color: ${(p) => p.theme.colors.black};
  flex-shrink: 1;
`;

const StyledMetaText = styled(Meta)`
  color: ${(p) => p.theme.colors.gray200};
  flex-shrink: 1;
`;

const ApproveTermsText: React.FC<ApproveTermsTextProps> = ({ type }) => {
  const { t } = useTranslation();
  const text = t(
    `account.terms.privacyPolicyAndTermsConditionsInfo${type ?? ''}`
  );

  // Use regex to split the text into an array of chunks and keep only the content between <0> and <1>
  const chunks = text.split(/<\/?\d+>/);

  // Filter out empty strings and get an array of links
  const links = chunks.filter((_, index) => index % 2 !== 0);

  const StyledText = type ? StyledBaseText : StyledMetaText;

  return (
    <ApproveTermsTextContainer shrink={!!type}>
      {chunks.filter(Boolean).map((chunk) => {
        if (links.includes(chunk)) {
          return (
            <CustomLink
              type={type}
              key={`pp-tc-link-${chunk}`}
              modal={MODALS[links.indexOf(chunk)]}>
              {chunk}
            </CustomLink>
          );
        } else {
          return <StyledText key={`pp-tc-text-${chunk}`}>{chunk}</StyledText>;
        }
      })}
    </ApproveTermsTextContainer>
  );
};

export default ApproveTermsText;
