import { useNavigation } from '@react-navigation/native';
import { PromotionalItem, theme } from 'aimo-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledImage } from 'styles/ContainerStyles';
import { AccountNavProps } from 'types/navigation';

type PromotionCardExpiresSoonProps = {
  daysUntilExpiration: number;
};

const ImageComponent = () => (
  <StyledImage
    source={require('assets/images/onboarding-payment-method.png')}
    width={theme.normalising.widthPixel(63)}
    height={theme.normalising.heightPixel(52)}
  />
);

const PromotionCardExpiresSoon = ({
  daysUntilExpiration,
}: PromotionCardExpiresSoonProps) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<AccountNavProps>();

  return (
    <PromotionalItem
      bgColor={theme.colors.lightBg.yellow}
      title={
        daysUntilExpiration === 0
          ? t('paymentMethods.indicator.cardExpiresSoonTitleToday')
          : t('paymentMethods.indicator.cardExpiresSoonTitle', {
              count: daysUntilExpiration,
            })
      }
      text={t('paymentMethods.indicator.cardExpiresSoonSubtitle')}
      imageComponent={ImageComponent}
      onPress={() => navigate('myPaymentMethods')}
    />
  );
};

export default PromotionCardExpiresSoon;
