import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { Dropdown } from 'aimo-ui';
import {
  CheckIcon,
  DropdownIcon,
} from 'components/account/vehicle/VehicleIcons';
import { colors } from 'utils/vehicleUtils';

type ColorSelectorProps = {
  selectedColor: string;
  setSelectedColor: React.Dispatch<React.SetStateAction<string>>;
};

const ColorItem = styled.View<{ color: string }>`
  height: 24px;
  width: 24px;
  background-color: ${(props) => props.color};
  border-radius: 24px;
  ${(props) =>
    props.color === '#FFFFFF' ? 'border: 2px solid #D6DFE3' : undefined}
`;

type IconComponentProps = {
  color: string;
};
const IconComponent = ({ color }: IconComponentProps) => (
  <ColorItem color={color} testID={`${color}-color-button`} />
);

const VehicleColorSelector = ({
  selectedColor,
  setSelectedColor,
}: ColorSelectorProps) => {
  const { t } = useTranslation();

  const colorsDropdownItems = colors.map((color) => {
    return {
      label: t(`vehicle.form.color.${color}`),
      value: color,
      icon: () => IconComponent({ color }),
    };
  });

  const selectedItem = useMemo(
    () => colorsDropdownItems.find((item) => item.value === selectedColor),
    [colorsDropdownItems, selectedColor]
  );

  return (
    <Dropdown
      items={colorsDropdownItems}
      defaultItem={selectedItem}
      onSelect={(item) => setSelectedColor(item.value)}
      placeholder={t('vehicle.form.color.label')}
      dropdownIcon={DropdownIcon}
      checkIcon={CheckIcon}
    />
  );
};

export default React.memo(VehicleColorSelector);
