import React, { useMemo } from 'react';
import { BaseText, Meta, theme } from 'aimo-ui';
import { useTranslation } from 'react-i18next';
import { Row } from './styles/styles';
import { Maybe, ParkZoneNotes } from 'types/generatedSchemaTypes';
import { getLang } from 'utils/commonUtils';
import { StyledWrapper } from 'styles/ContainerStyles';
import { Divider } from 'styles/CommonLayoutStyles';
import { isValidNote } from 'utils/parkUtils';
import styled from 'styled-components/native';
import WarningWrapper from 'components/park/ParkingZoneWarningWrapper';

const WeightedMeta = styled(Meta)`
  font-weight: 500;
`;

export const ImportantZoneNotes: React.FC<{
  parkZoneNotes: Maybe<ParkZoneNotes[]> | undefined;
  marginTop?: boolean;
}> = ({ parkZoneNotes, marginTop }) => {
  const importantNotes = useMemo(
    () => parkZoneNotes?.filter((note) => isValidNote(note, 3)),
    [parkZoneNotes]
  );
  return (
    <>
      {importantNotes && importantNotes.length > 0 && (
        <WarningWrapper marginTop={marginTop}>
          <ParkingZoneNotes
            testID="park-zone-important-information"
            parkZoneNotes={importantNotes}
            important
          />
        </WarningWrapper>
      )}
    </>
  );
};

const ParkingZoneNotes = ({
  testID,
  headline,
  parkZoneNotes,
  important,
}: {
  testID: string;
  headline?: string;
  parkZoneNotes: ParkZoneNotes[];
  important?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {headline && (
        <Row mb={10}>
          <BaseText testID={`${testID}-headline`} numberOfLines={1}>
            {t(headline)}
          </BaseText>
        </Row>
      )}
      {parkZoneNotes.map(({ text }, i, { length }) => {
        return (
          <StyledWrapper mb={0} key={`${testID}-${i}`}>
            <WeightedMeta
              color={important ? theme.colors.black : theme.colors.gray200}
              testID={`${testID}-meta-${i}`}>
              {text[getLang()]}
            </WeightedMeta>
            {length - 1 !== i && !important && (
              <Row mt={5} mb={5}>
                <Divider marginVertical={0} />
              </Row>
            )}
          </StyledWrapper>
        );
      })}
    </>
  );
};

export default ParkingZoneNotes;
