import { useLazyQuery } from '@apollo/client';
import { AUTHENTICATE_DEVICE_TIME } from 'graphql/queries/authenticateDeviceTime';
import { useEffect, useState } from 'react';
import { AppState } from 'react-native';
import {
  Query,
  QueryAuthenticateDeviceTimeArgs,
} from 'types/generatedSchemaTypes';

const useDeviceTimeAuthentication = () => {
  const [appState, setAppState] = useState(AppState.currentState);

  const [authenticateDeviceTime, { data, loading }] = useLazyQuery<
    Query,
    QueryAuthenticateDeviceTimeArgs
  >(AUTHENTICATE_DEVICE_TIME, {
    variables: {
      input: {
        deviceTime: new Date().toISOString(),
      },
    },
  });

  useEffect(() => {
    const listener = AppState.addEventListener('change', setAppState);
    return listener?.remove;
  }, []);

  useEffect(() => {
    if (appState === 'active') {
      const deviceTime = new Date().toISOString();
      authenticateDeviceTime({ variables: { input: { deviceTime } } });
    }
  }, [appState, authenticateDeviceTime]);

  return { data, loading };
};

export default useDeviceTimeAuthentication;
