import { gql } from '@apollo/client';

export const CREATE_ACCEPTANCES = gql`
  mutation CreateAcceptancesV3($input: [AcceptanceInput!]!) {
    createAcceptancesV3(input: $input) {
      accepted
      countryCode
      lastChanged
      type
    }
  }
`;
