import {
  ParkingPrice,
  BillingItem,
  CountryCode,
} from 'types/generatedSchemaTypes';
import { currencyFormatter } from 'utils/commonUtils';

enum ReceiptType {
  PARKING_RECEIPT,
  FAIL_AND_NO_PRICE_RECEIPT,
  NON_PARKING_RECEIPT,
}

const usePriceExtraction = (
  price: ParkingPrice | BillingItem | [BillingItem],
  countryCode: CountryCode,
  isFailedChargingSession?: boolean,
  failedChargingSessionPrice?: number
) => {
  const isParkingPrice =
    Array.isArray(price) || price?.__typename === 'ParkingPrice';

  let vatAmount,
    vatPercentage,
    amountExclVAT,
    parkingFeeAmount,
    serviceFeeAmount,
    sum;

  let priceCast;

  switch (receiptTypeCheck(price, isParkingPrice)) {
    case ReceiptType.PARKING_RECEIPT:
      priceCast = (price as [BillingItem]) || {};

      vatAmount = priceCombine('vatAmount', priceCast);
      vatPercentage = priceCast[0].vatPercentage;
      amountExclVAT = priceCombine('amountExclVAT', priceCast);
      sum = priceCombine('amountCents', priceCast) / 100;

      parkingFeeAmount = priceCombine(
        'amountExclVAT',
        priceCast?.filter((billingItem) => billingItem.type !== 'SERVICE_FEE')
      );
      serviceFeeAmount = priceCast?.filter(
        (billingItem) => billingItem.type === 'SERVICE_FEE'
      )[0]?.amountExclVAT;

      break;
    case ReceiptType.FAIL_AND_NO_PRICE_RECEIPT:
      priceCast = (price as ParkingPrice) || {};

      ({ vatAmount, vatPercentage, amountExclVAT } = priceCast);
      sum = priceCast?.totalAmount as number;

      const { breakdown } = priceCast;

      parkingFeeAmount = breakdown?.parkingFee?.amountExclVAT;
      serviceFeeAmount = breakdown?.serviceFee?.amountExclVAT;

      break;
    case ReceiptType.NON_PARKING_RECEIPT:
      if (!isFailedChargingSession) {
        priceCast = (price as BillingItem) || {};

        ({ vatAmount, vatPercentage, amountExclVAT } = priceCast);
        sum = (priceCast?.amountCents as number) / 100;
      } else {
        amountExclVAT = failedChargingSessionPrice;
      }

      break;
  }

  const vatAmountInEuros = isParkingPrice
    ? (vatAmount as number) || 0
    : ((vatAmount as number) || 0) / 100;
  const amountExclVATinEuros = isParkingPrice
    ? (amountExclVAT as number) || 0
    : ((amountExclVAT as number) || 0) / 100;

  const total = currencyFormatter((sum as number) || 0, countryCode);
  const subTotal = currencyFormatter(amountExclVATinEuros, countryCode);
  const totalTax = currencyFormatter(vatAmountInEuros, countryCode);
  const parkingFee = currencyFormatter(
    (parkingFeeAmount as number) || 0,
    countryCode
  );
  const serviceFee = currencyFormatter(
    (serviceFeeAmount as number) || 0,
    countryCode
  );

  const totalChargingNumeric = isFailedChargingSession
    ? amountExclVATinEuros
    : sum;

  return {
    isParkingPrice,
    total,
    subTotal,
    totalTax,
    parkingFee,
    serviceFee,
    vatPercentage,
    totalChargingNumeric,
  };
};

const receiptTypeCheck = (
  price: ParkingPrice | BillingItem | [BillingItem],
  isParkingPrice: boolean
): ReceiptType => {
  if (isParkingPrice) {
    if (Array.isArray(price)) {
      return ReceiptType.PARKING_RECEIPT;
    } else {
      return ReceiptType.FAIL_AND_NO_PRICE_RECEIPT;
    }
  } else {
    return ReceiptType.NON_PARKING_RECEIPT;
  }
};

const priceCombine = <T extends Record<string, any>>(
  key: string,
  array: T[]
): number =>
  array.length === 0
    ? 0
    : array.reduce<number>((sum, current) => sum + (current[key] || 0), 0);

export default usePriceExtraction;
