import React from 'react';
import Icon from '@components/icons/Icon';
import { theme, IconButton } from 'aimo-ui';
import { ButtonTypes } from 'aimo-ui/lib/typescript/src/components/Button/types';

type BackButtonProps = {
  onPress?: () => void;
  testID?: string;
  buttonType?: ButtonTypes;
};

const BackIcon = ({ color }: { color: string }) => (
  <Icon name="back" size="s" color={color} />
);

const BackButton = ({
  onPress,
  testID,
  buttonType = 'tertiary',
}: BackButtonProps) => {
  return (
    <IconButton
      fullWidth={false}
      buttonType={buttonType}
      onPress={onPress}
      rounded
      testID={testID}
      iconComponent={() =>
        BackIcon({
          color:
            buttonType === 'secondary'
              ? theme.colors.white
              : theme.colors.black,
        })
      }
    />
  );
};

export default BackButton;
