import { gql } from '@apollo/client';

export const UPDATE_SALES_ORDER = gql`
  mutation updateSalesOrder($input: UpdateSalesOrderInput!) {
    updateSalesOrder(input: $input) {
      uid
      creditCardId
      renewal
      orderStatus
      paymentState
      orderLines {
        orderLineId
        salesOrderId
        salesOrderStatus
        salesOrderPaymentState
        registrationPlates {
          plateText
          countryCode
        }
        validity {
          validFrom
          validTo
        }
        charge {
          uid
          salesOrderUid
          orderLineId
          validity {
            validFrom
            validTo
          }
          creditCardEvents {
            content {
              created
            }
          }
        }
      }
    }
  }
`;
