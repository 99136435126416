import { gql } from '@apollo/client';

export const READ_PARKING_RECEIPT_PDF = gql`
  query readParkingReceiptPdf($sessionId: ID!) {
    readParkingReceiptPdf(sessionId: $sessionId) {
      sessionId
      base64PdfFile
    }
  }
`;
