import { useMemo } from 'react';
import { useAnimatedStyle } from 'react-native-reanimated';
import { isWeb } from 'utils/deviceUtils';
import useCustomBottomSheet from 'hooks/useCustomBottomSheet';
import { useReactiveVar } from '@apollo/client';
import {
  ongoingChargingSheetExpanded,
  ongoingParkingSheetExpanded,
} from 'states/common';

const useOngoingSessionsSheet = () => {
  const { animatedIndex } = useCustomBottomSheet();
  const ongoingParkingSheetExp = useReactiveVar(ongoingParkingSheetExpanded);
  const ongoingChargingSheetExp = useReactiveVar(ongoingChargingSheetExpanded);

  const isExpanded = useMemo(
    () => ongoingParkingSheetExp || ongoingChargingSheetExp,
    [ongoingChargingSheetExp, ongoingParkingSheetExp]
  );

  const expandedContainerAnimatedStyle = useAnimatedStyle(() => {
    return {
      display:
        animatedIndex.value >= 0.5 || (isWeb && isExpanded) ? 'flex' : 'none',
      flexDirection: 'row',
    };
  });

  const collapsedManualContainerAnimatedStyle = useAnimatedStyle(() => {
    return {
      display:
        animatedIndex.value >= 0.5 || (isWeb && isExpanded) ? 'none' : 'flex',
      flexDirection: 'row',
    };
  });

  const expandedSheetContainerStyle = useMemo(
    () => expandedContainerAnimatedStyle,
    [expandedContainerAnimatedStyle]
  );

  const collapsedSheetContainerStyle = useMemo(
    () => collapsedManualContainerAnimatedStyle,
    [collapsedManualContainerAnimatedStyle]
  );

  return {
    collapsedSheetContainerStyle,
    expandedSheetContainerStyle,
  };
};

export default useOngoingSessionsSheet;
