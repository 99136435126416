import { gql } from '@apollo/client';

export const READ_FAILED_SESSIONS = gql`
  query ReadFailedSessions($input: FailedSessionsInput) {
    readFailedSessions(input: $input) {
      hasFailedSession
      failedParkingSessions {
        sessionId
      }
      failedChargingSessions {
        chargingSessionId
      }
    }
  }
`;
