import { CardStatus } from 'types/generatedSchemaTypes';
import { getDaysUntilExpiration, isCardErrorStatus } from 'utils/paymentUtils';

type ShowPaymentCardStatus = {
  isErrorStatus: boolean;
  expiresSoon: boolean;
  daysUntilExpiration: number;
};

const useShowPaymentCardStatus = (
  cardStatus: CardStatus,
  expMonth: string,
  expYear: string
): ShowPaymentCardStatus => {
  const daysUntilExpiration = getDaysUntilExpiration(
    cardStatus,
    expMonth,
    expYear
  );

  return {
    isErrorStatus: isCardErrorStatus(cardStatus),
    expiresSoon: daysUntilExpiration >= 0,
    daysUntilExpiration,
  };
};

export default useShowPaymentCardStatus;
