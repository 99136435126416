import { useReactiveVar } from '@apollo/client';
import { HorizontalList, QuickAccessListItem, theme } from 'aimo-ui';
import { itemProps } from 'aimo-ui/lib/typescript/src/components/ListItem/types';
import Icon from 'components/icons/Icon';
import { AccessPanel } from 'components/park/styles/styles';
import { useTranslation } from 'react-i18next';
import Animated, { FadeIn } from 'react-native-reanimated';
import { activeElement } from 'states/common';
interface itemData {
  title: string;
  iconName: string;
}

type buttonData = {
  data: itemData[];
  onPress: (value: any) => any;
  selectedIndex?: number;
};

const AnimatedAccessPanel = Animated.createAnimatedComponent<any>(AccessPanel);

export default ({ data, onPress, selectedIndex }: buttonData) => {
  const activeEl = useReactiveVar(activeElement);
  const hide = activeEl?.length > 0;

  const QuickAccessItem = ({
    item,
    indexValue,
    testID,
    ...props
  }: itemProps<any>) => {
    const { t } = useTranslation();
    const isSelected = selectedIndex === indexValue;

    const IconComponent = () => (
      <Icon
        color={isSelected ? theme.colors.white : theme.colors.gray200}
        name={item.iconName}
        size="m"
      />
    );
    return (
      <QuickAccessListItem
        {...props}
        item={item}
        indexValue={indexValue}
        title={t(item.title)}
        titleColor={isSelected ? theme.colors.white : theme.colors.gray200}
        onPress={() => onPress(indexValue)}
        withBackground={
          isSelected ? theme.colors.darkBg.grey : theme.colors.white
        }
        iconComponent={IconComponent}
        testID={isSelected ? `${testID}-selected` : testID}
      />
    );
  };

  if (hide) {
    return <></>;
  }
  return (
    <AnimatedAccessPanel entering={FadeIn.duration(300)}>
      <HorizontalList
        listData={data}
        listElement={QuickAccessItem}
        withBackground={theme.colors.white}
        testID="quick-access-panel"
      />
    </AnimatedAccessPanel>
  );
};
