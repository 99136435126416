import { Button, Headline3, Meta, theme } from 'aimo-ui';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import SheetLayout, {
  FooterButtonContainer,
} from 'components/sheets/SheetLayout';
import HslContext from 'context/HslContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { ColumnWithMargins } from 'styles/ContainerStyles';

type InvalidDeviceTimeProps = {
  hideTitle?: boolean;
  hideCloseButton?: boolean;
};

const InvalidDeviceTime: React.FC<InvalidDeviceTimeProps> = ({
  hideTitle = false,
  hideCloseButton = false,
}) => {
  const { t } = useTranslation();
  const { setCurrentScreen } = useContext(HslContext);

  const handleBackButtonPress = () => setCurrentScreen('');

  const handleOpenSettingButtonPress = () => {
    Linking.openSettings();
  };

  return (
    <SheetLayout
      testIDName="invalid-device-time-modal"
      onHeadlineButtonPress={
        hideCloseButton ? undefined : handleBackButtonPress
      }
      headlineComponent={
        hideTitle ? (
          <></>
        ) : (
          <Headline3 center>{t('hslTicket.invalidTime.title')}</Headline3>
        )
      }>
      <BottomSheetScrollViewCustom bottomThreshold={0}>
        <ColumnWithMargins mt={20}>
          <Meta color={theme.colors.gray200}>
            {t('hslTicket.invalidTime.subtitle')}
          </Meta>
        </ColumnWithMargins>
      </BottomSheetScrollViewCustom>

      <FooterButtonContainer>
        <Button
          size="l"
          onPress={handleOpenSettingButtonPress}
          text={t('hslTicket.invalidTime.button')}
        />
      </FooterButtonContainer>
    </SheetLayout>
  );
};

export default InvalidDeviceTime;
