import React from 'react';
import { Meta, Spinner, theme } from 'aimo-ui';
import { Pill } from 'styles/CommonLayoutStyles';
import Icon from 'components/icons/Icon';
import styled from 'styled-components/native';
import { Row } from 'components/park/styles/styles';

const IconWrapper = styled.View`
  margin: auto 3px auto 0;
  background-color: ${theme.colors.primary};
  border-radius: 10px;
`;

const ChargerBadge: React.FC<{
  availableSpots: number;
  loading?: boolean;
  isCollapsed?: boolean;
}> = ({ availableSpots, loading = false, isCollapsed = false }) => {
  const ChargerBadgeContent = () => {
    if (loading) {
      return <Spinner small testID="charger-badge-loader" />;
    }
    return (
      <>
        <IconWrapper>
          <Icon name="Charging" size="s" color={theme.colors.white} />
        </IconWrapper>
        <Meta testID="charger-badge-spots">{availableSpots}</Meta>
      </>
    );
  };

  return isCollapsed ? (
    <Row>
      <ChargerBadgeContent />
    </Row>
  ) : (
    <Pill bg={theme.colors.lightBg.green} py={5} px={7}>
      <ChargerBadgeContent />
    </Pill>
  );
};

export default ChargerBadge;
