/* @typescript-eslint-disable-all */
/* reat-native-web-maps doesn't have types at all */

import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import GoogleMapStyle from '@styles/mapStyle.json';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import React, { useEffect, useState } from 'react';
import { LatLng, Region } from 'react-native-maps';
import {
  optimizeMapContent,
  regionCoordinates,
  userLocation,
} from 'states/map';
import { featureCountryCode } from 'states/persistInStorage';
import { MapComponentProps, WebAndMobileRegion } from 'types/MapTypes';
import { SetParamsProps } from 'types/navigation';
import {
  defaultRegionByAppLocation,
  getBoxDimensionsFromRegion,
} from 'utils/mapUtils';
import { mapCoordinates } from '../../states/map';
import { BackButton, UserLocationButton, ZoomedTooFar } from './MapButtons';
import MapView from './RNWeb/MapView';

const handleLocationError = (_isAvailable: boolean) => {
  userLocation(null);
};

const MapComponent = ({
  children,
  showLocation = true,
  showBeforeMapLoaded = false,
  showBackButton = false,
  showZoomInfo = false,
}: MapComponentProps) => {
  const appCountry = useReactiveVar(featureCountryCode);
  const { isLoggedIn } = useIsLoggedIn();
  const defaultRregion = defaultRegionByAppLocation(appCountry, isLoggedIn);
  const [region, setCurrentRegion] = useState(defaultRregion);
  let setRegionTimeout: any;
  const { setParams }: SetParamsProps = useNavigation();
  const oldRegion = useReactiveVar(regionCoordinates);
  const featureCountry = useReactiveVar(featureCountryCode);
  const isFin = featureCountry === 'FI';
  const handleLocationSuccess = (position: any) => {
    const pos: LatLng = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    if (pos?.latitude && pos?.longitude) {
      userLocation(pos);
    }
  };

  useEffect(() => {
    const newRegion = defaultRegionByAppLocation(appCountry, isLoggedIn);
    setCurrentRegion(newRegion as Region);
  }, [appCountry, isLoggedIn]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          handleLocationSuccess(position);
          mapCoordinates(position.coords);
        },
        () => {
          handleLocationError(true);
        }
      );
    } else {
      // Browser doesn't support Geolocation
      handleLocationError(false);
    }
  }, []);

  useEffect(() => {
    let id: number;
    if (navigator.geolocation) {
      id = navigator.geolocation.watchPosition(
        (position) => handleLocationSuccess(position),
        () => handleLocationError(true),
        {
          enableHighAccuracy: true,
          maximumAge: 5000,
        }
      );
    } else {
      // Browser doesn't support Geolocation
      handleLocationError(false);
    }

    return () => {
      if (id) {
        navigator.geolocation.clearWatch(id);
      }
    };
  }, []);

  const updateRegion = (newRegion: WebAndMobileRegion) => {
    const { zoom = 0 } = newRegion;
    optimizeMapContent(zoom < 14);
    // reset mapCoordinates when map moves
    clearTimeout(setRegionTimeout);
    setRegionTimeout = setTimeout(() => {
      const { outerBox, zoom: oldZoom } = getBoxDimensionsFromRegion(oldRegion);
      const { viewPort, zoom: newZoom } = getBoxDimensionsFromRegion(newRegion);
      if (
        viewPort.upperRightLat >= outerBox.upperRightLat ||
        viewPort.upperRightLng >= outerBox.upperRightLng ||
        viewPort.bottomLeftLat <= outerBox.bottomLeftLat ||
        viewPort.bottomLeftLng <= outerBox.bottomLeftLng ||
        oldZoom !== newZoom
      ) {
        regionCoordinates(newRegion);
      }
    }, 500);
    mapCoordinates({ latitude: 0, longitude: 0 });
  };

  const handleMapPress = () => {
    setParams({ parkingZoneUid: '' });
  };

  return (
    <>
      <MapView
        showBeforeMapLoaded={showBeforeMapLoaded}
        initialRegion={region as Region}
        customMapStyle={GoogleMapStyle}
        onRegionChangeComplete={updateRegion}
        onPress={handleMapPress}
        testid="web-map-view">
        {children}
      </MapView>
      {showLocation && <UserLocationButton testID="find-location-button" />}
      {showBackButton && <BackButton testID="map-back-button" />}
      {showZoomInfo && isFin && <ZoomedTooFar />}
    </>
  );
};

export default MapComponent;
