import { BaseText, Headline3, Meta, Spinner, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import { isFunction } from 'lodash';
import React from 'react';
import styled from 'styled-components/native';
import { Pill } from 'styles/CommonLayoutStyles';
import { MenuRowWrapper, MenuWrapper } from 'styles/ContainerStyles';
import { IndicatorType } from 'types/IconTypes';
import { isWeb } from 'utils/deviceUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const StyledView = styled.View<{
  mt?: number;
}>`
  margin-right: 15px;
  margin-top: ${({ mt }) => mt ?? 0}px;
`;

const ArrowView = styled.View`
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1 1 auto;
`;

const RightComponentWrapper = styled.View<{
  mr?: number;
}>`
  justify-content: flex-end;
  align-items: flex-end;
  max-width: ${!isWeb ? theme.normalising.widthPixel(250) : '600'}px;
  margin-right: ${({ mr }) => mr ?? 0}px;
`;

const TextWrapper = styled.View`
  flex: 1 1 100%;
`;

const ColumnFlex = styled.View<{ background?: string }>`
  flex-direction: column;
  background-color: ${({ background }) => background || 'transparent'};
`;

type MenuItemProps = {
  iconName?: string;
  iconFlexStart?: boolean;
  iconMt?: number;
  indicatorType?: IndicatorType;
  indicatorNumber?: number;
  menuLabel: string;
  color?: string;
  testID?: string;
  noBorder?: boolean;
  onPress?: () => void;
  loading?: boolean;
  large?: boolean;
  background?: string;
  withArrow?: boolean;
  disabled?: boolean;
  arrowSize?: string;
  borderW?: number;
  subtitle?: string;
  pillText?: string;
  smallLabel?: string;
  smallLabelColor?: string;
  paddingVertical?: number;
  borderColor?: string;
  bold?: boolean;
  rightComponent?: JSX.Element | false;
  imageComponent?: JSX.Element | false;
  bottomPillText?: string;
};
const MenuItem: React.FC<MenuItemProps> = ({
  iconName,
  iconFlexStart,
  iconMt,
  indicatorType,
  indicatorNumber,
  menuLabel,
  color,
  testID,
  background,
  pillText = '',
  smallLabel = '',
  smallLabelColor,
  noBorder = false,
  subtitle,
  onPress,
  large = false,
  loading = false,
  withArrow = false,
  arrowSize,
  borderW = 3,
  disabled = false,
  borderColor = theme.colors.gray50,
  bold = false,
  paddingVertical = 18,
  rightComponent,
  imageComponent,
  bottomPillText,
}) => {
  const borderWidth = () => {
    if (noBorder) {
      return 0;
    }
    return borderW;
  };

  const MenuLabel = () => {
    if (bold) {
      return (
        <Headline3
          color={color}
          {...testIdentifiers(`${testID as string}-text`)}
          numberOfLines={1}>
          {menuLabel}
        </Headline3>
      );
    }
    return (
      <BaseText
        color={color}
        testID={testID ? testID + '-text' : undefined}
        numberOfLines={1}>
        {menuLabel} {loading && <Spinner small />}
      </BaseText>
    );
  };

  return (
    <MenuWrapper
      {...testIdentifiers(testID as string)}
      accessible={false}
      borderWidth={borderWidth()}
      onPress={onPress}
      borderColor={borderColor}
      background={background}
      paddingVertical={paddingVertical}
      disabled={disabled || !isFunction(onPress)}>
      <MenuRowWrapper flexStart={iconFlexStart}>
        {!!iconName && (
          <StyledView mt={iconMt}>
            <Icon
              name={iconName}
              color={color}
              size={large ? 'l' : 'm'}
              indicatorType={indicatorType}
              indicatorNumber={indicatorNumber}
            />
          </StyledView>
        )}

        {imageComponent && <StyledView>{imageComponent}</StyledView>}

        <TextWrapper>
          <MenuLabel />
          {subtitle && (
            <Meta
              color={theme.colors.gray200}
              testID={testID ? testID + '-subtext' : undefined}>
              {subtitle}
            </Meta>
          )}
        </TextWrapper>
        {pillText?.length > 0 && (
          <Pill
            mr={3}
            ml={3}
            testID={testID ? testID + '-pill-text' : undefined}>
            <Meta>{pillText}</Meta>
          </Pill>
        )}
        {smallLabel?.length > 0 && (
          <Meta
            color={smallLabelColor ?? theme.colors.gray200}
            testID={testID ? testID + '-small-label' : undefined}>
            {smallLabel}
          </Meta>
        )}
        {rightComponent && (
          <RightComponentWrapper
            {...testIdentifiers(testID + '-right-component')}
            mr={withArrow ? 15 : 0}>
            {rightComponent}
          </RightComponentWrapper>
        )}
        {withArrow && (
          <ArrowView>
            <Icon
              name="Chevron_Right"
              color={smallLabel?.length > 0 ? smallLabelColor : color}
              size={arrowSize}
            />
          </ArrowView>
        )}
      </MenuRowWrapper>
      {bottomPillText && (
        <Pill
          mt={10}
          testID={testID ? testID + '-bottom-pill-text' : undefined}>
          <Meta>{bottomPillText}</Meta>
        </Pill>
      )}
    </MenuWrapper>
  );
};

export default MenuItem;
