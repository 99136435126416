import { getAnalytics, logEvent } from 'firebase/analytics';
import Logger from './Logger';

/**
 * Creates a new event to Firebase
 * @param event name of the event shown in Firebase. Should contain 1 to 40 alphanumeric characters or underscores.
 * @param params Additional event parameters
 */
export const logCustomEvent = async (
  event: string,
  params?: {
    [key: string]: any;
  }
) => {
  try {
    const analytics = getAnalytics();
    logEvent(analytics, event, params);
  } catch (e) {
    Logger.error(`Log Firebase Analytics custom event - ${e}`);
  }
};
