import AccountContext from 'context/AccountContext';
import useUpdateCustomerMutation from 'hooks/useUpdateCustomerMutation';
import React, { useContext, useState } from 'react';
import MenuToggleItem from './MenuToggleItem';

const SendEmailReceiptsMenuItem: React.FC<{
  borderColor?: string;
  borderW?: number;
}> = ({ borderColor, borderW }) => {
  const {
    customer: { applicationPreferences, contactEmail },
  } = useContext(AccountContext);
  const [sendEmailReceipts, setSendEmailReceipts] = useState(
    applicationPreferences?.sendEmailReceipts ?? true
  );

  const errorCallback = () => {
    setSendEmailReceipts(!sendEmailReceipts);
  };
  const { updateCustomer, loading } = useUpdateCustomerMutation({
    errorCallback,
  });

  const onChange = (value: boolean) => {
    if (loading) {
      return;
    }
    setSendEmailReceipts(value);
    updateCustomer({
      variables: {
        input: {
          applicationPreferences: { sendEmailReceipts: value },
        },
      },
    });
  };

  return (
    <MenuToggleItem
      value={sendEmailReceipts}
      onValueChange={onChange}
      menuLabel={'account.sendEmailReceipts'}
      testID={'send-email-receipts'}
      subtitle={contactEmail ?? undefined}
      iconName={'Email-Outlined'}
      borderColor={borderColor}
      borderWidth={borderW}
      loading={loading}
    />
  );
};

export default SendEmailReceiptsMenuItem;
