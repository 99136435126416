import React, { useCallback, useRef } from 'react';
import { ListItem, Meta, theme } from 'aimo-ui';
import { ListItemWrapper, Row } from './styles';
import Icon from 'components/icons/Icon';
import { useTranslation } from 'react-i18next';
import { getLocale } from 'utils/commonUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const RightContent = ({
  text,
  testID,
  showArrow,
}: {
  text: string;
  testID?: string;
  showArrow?: boolean;
}) => (
  <Row>
    <Meta {...testIdentifiers(`${testID}-content`)}>{text}</Meta>
    {showArrow && <Icon testID={`${testID}-icon`} name="Chevron_Right" />}
  </Row>
);

const ValidFromSelector = ({
  testID,
  value,
  showDateSelector,
}: {
  testID: string;
  value: Date;
  showDateSelector: boolean;
}) => {
  const { t } = useTranslation();
  const today = useRef(new Date());
  const getValidDate = useCallback(
    (selectedDate: Date) => {
      const locale = getLocale();
      if (today.current.toDateString() === selectedDate.toDateString()) {
        return t('longTermParking.validImmediately');
      }
      return selectedDate.toLocaleDateString(locale.code);
    },
    [t]
  );

  const RightComponent = useCallback(
    () => (
      <RightContent
        text={getValidDate(value)}
        testID={testID}
        showArrow={showDateSelector}
      />
    ),
    [getValidDate, showDateSelector, testID, value]
  );

  const MetaComponent = useCallback(
    () => (
      <Meta color={theme.colors.gray300} testID={`${testID}-title`}>
        {t('longTermParking.valid')}
      </Meta>
    ),
    [t, testID]
  );

  return (
    <ListItemWrapper noBorder>
      <ListItem
        testID={testID}
        withBackground={theme.colors.lightBg.green}
        item={null}
        indexValue={0}
        titleComponent={MetaComponent}
        rightContentComponent={RightComponent}
      />
    </ListItemWrapper>
  );
};

export default ValidFromSelector;
