import { useNavigation } from '@react-navigation/native';
import { BaseText, BottomButtonsLayout, Button, Title } from 'aimo-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  parkingHistoryViewFilter,
  sheetModal,
  tabBarVisible,
} from 'states/common';
import { StyledImage, StyledWrapper } from 'styles/ContainerStyles';
import { RootNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';

const AccountRestrictedModalContent: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<RootNavProps>();

  const navigateToUnpaidFees = () => {
    parkingHistoryViewFilter('failed');
    tabBarVisible(true);
    navigate('mainTabs', {
      screen: 'accountScreen',
      params: {
        screen: 'parkingHistory',
      },
    });
    sheetModal('');
  };

  const navigateToContactUs = () => {
    tabBarVisible(true);
    navigate('mainTabs', {
      screen: 'accountScreen',
      params: {
        screen: 'contactUs',
      },
    });
    sheetModal('');
  };
  return (
    <BottomButtonsLayout
      scrollable={false}
      contentBottom={
        <>
          <StyledWrapper mb={15}>
            <Button
              testID="account-restricted-unpaid-fees-button"
              text={t('account.restricted.unpaidFees')}
              size="l"
              buttonType="secondary"
              onPress={navigateToUnpaidFees}
            />
          </StyledWrapper>
          <StyledWrapper mb={isWeb ? 0 : 50}>
            <Button
              testID="account-restricted-contact-button"
              text={t('account.restricted.contact')}
              size="l"
              buttonType="tertiary"
              onPress={navigateToContactUs}
            />
          </StyledWrapper>
        </>
      }>
      <StyledWrapper mb={20}>
        <StyledImage
          testID="account-restricted-image"
          width={140}
          height={140}
          center
          source={require('assets/images/onboarding-welcome.png')}
        />
      </StyledWrapper>
      <StyledWrapper mb={30}>
        <Title center testID="c-title">
          {t('account.restricted.title')}
        </Title>
      </StyledWrapper>
      <StyledWrapper>
        <BaseText center testID="account-restricted-description">
          {t('account.restricted.description')}
        </BaseText>
      </StyledWrapper>
    </BottomButtonsLayout>
  );
};

export default AccountRestrictedModalContent;
