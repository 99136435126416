import { useReactiveVar } from '@apollo/client';
import AccountContext from 'context/AccountContext';
import { useContext } from 'react';
import { featureCountryCode } from 'states/persistInStorage';
import {
  Acceptance,
  AcceptanceType,
  CountryCode,
  Maybe,
} from 'types/generatedSchemaTypes';

type HasAcceptanceProps = {
  type: AcceptanceType;
};
const useHasAcceptance = ({ type }: HasAcceptanceProps) => {
  const {
    customer: { acceptances },
  } = useContext(AccountContext);
  const featureCountry = useReactiveVar(featureCountryCode);
  return hasAcceptedTerms(featureCountry as CountryCode, type, acceptances);
};

export const hasAcceptedTerms = (
  countryCode: CountryCode,
  type: AcceptanceType,
  acceptances?: Maybe<Acceptance>[] | null
) => {
  if (acceptances?.[0]) {
    return acceptances.some(
      (acceptance) =>
        acceptance?.type === type &&
        acceptance.countryCode === countryCode &&
        acceptance.accepted
    );
  }
  return false;
};

export default useHasAcceptance;
