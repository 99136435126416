import { showToast } from 'components/common/CustomToast';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import i18n from 'i18next';
import { merge } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyBenefit } from 'types/generatedSchemaTypes';
import { getCompanyBenefitsUids } from 'utils/commonUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { getItemFromStorage, setItemToStorage } from 'utils/MMKVStorageUtils';
import { joinObjectsByProperty } from 'utils/stringUtils';

const useCompanyBenefitNotification = () => {
  const { t } = useTranslation();
  const {
    customer: { companyBenefits },
  } = useContext(AccountContext);
  const {
    flags: { company_benefits },
  } = useContext(FeatureFlagsContext);
  const showCompanyBenefits = showFeatureFlag(company_benefits);

  const companyBenefitsUids = useMemo(
    () => getCompanyBenefitsUids(companyBenefits as CompanyBenefit[]),
    [companyBenefits]
  );

  const getCompanyBenefitNotificationSent = (uids: string[]) => {
    return uids
      ?.map((uid) =>
        getItemFromStorage(`companyBenefitNotificationSent_${uid}`)
      )
      ?.every((value) => value === true);
  };

  const setCompanyBenefitNotificationSent = async (uids: string[]) => {
    uids?.map((uid) =>
      setItemToStorage(`companyBenefitNotificationSent_${uid}`, true)
    );
  };

  useEffect(() => {
    setItemToStorage(
      'allCompanyBenefitNotificationSentUids',
      merge(
        getItemFromStorage('allCompanyBenefitNotificationSentUids') || [],
        companyBenefitsUids
      )
    );
  }, [companyBenefitsUids, showCompanyBenefits]);

  const handleCompanyBenefitNotification = async (navigationRef: any) => {
    const goToCompanyPermit = () => {
      navigationRef.navigate('mainTabs', {
        screen: 'accountScreen',
        params: { screen: 'companyPermit' },
      });
    };

    if (
      !getCompanyBenefitNotificationSent(companyBenefitsUids) &&
      showCompanyBenefits
    ) {
      showToast(
        t('account.companyPermits.notificationLabel', {
          name: joinObjectsByProperty(
            companyBenefits as CompanyBenefit[],
            'name',
            i18n.t('account.companyPermits.notificationLabelJoin')
          ),
          count: companyBenefits?.length,
        }),
        'success',
        undefined,
        {
          link: {
            onPressCallback: goToCompanyPermit,
            translationKey: 'account.companyPermits.notificationLink',
          },
        }
      );
    }

    await setCompanyBenefitNotificationSent(companyBenefitsUids);
  };

  return {
    handleCompanyBenefitNotification,
  };
};

export default useCompanyBenefitNotification;
