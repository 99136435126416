import {
  ApolloError,
  QueryResult,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { GET_PARK_ZONE } from '@queries/getParkZone';
import { GET_PARK_ZONE_WITH_CHARGING_STATIONS } from '@queries/getParkZoneWithChargingStations';
import { READ_PARKING_SESSIONS } from '@queries/readParkingSessions';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import NetworkConnectionContext from 'context/NetworkConnectionContext';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  ongoingParkingSession,
  selectedSessionIdFromMultiple,
} from 'states/common';
import { latestVehicleUsed } from 'states/persistInStorage';
import {
  Maybe,
  ParkingSession,
  Query,
  QueryParkZoneArgs,
  QueryReadParkingSessionsArgs,
} from 'types/generatedSchemaTypes';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { updateStateAndStorage } from 'utils/MMKVStorageUtils';

const getVehicleLabel = (name: string, licensePlate: string) => {
  if (name) {
    return `${name} (${licensePlate})`;
  }
  return licensePlate;
};

const useOngoingParkingSession = (
  bypassCache = false,
  skipInitialLoading = false
): {
  parkingZoneResult?: QueryResult<Query, QueryParkZoneArgs>;
  ongoingParkingSession?: Maybe<ParkingSession>;
  allParkingSessions?: Maybe<ParkingSession>[];
  ongoingParkingSessionLoading?: boolean;
  ongoingParkingSessionError?: ApolloError;
  ongoingParkingSessionStartPolling: (pollInterval: number) => void;
  ongoingParkingSessionStopPolling: () => void;
  ongoingParkingSessionRefetch: () => void;
} => {
  const { isLoggedIn } = useIsLoggedIn();
  const {
    flags: { ev_charging_public_ongoingParking },
  } = useContext(FeatureFlagsContext);
  const showEVCharging = showFeatureFlag(ev_charging_public_ongoingParking);
  const selectedSessionId = useReactiveVar(selectedSessionIdFromMultiple);
  const { hasConnection } = useContext(NetworkConnectionContext);
  const {
    customer: { vehicles },
    loading: customerLoading,
  } = useContext(AccountContext) || [];
  const [initialLoadingCompleted, setInitialLoadingCompleted] = useState(false);

  const { data, loading, error, startPolling, stopPolling, refetch } = useQuery<
    Query,
    QueryReadParkingSessionsArgs
  >(READ_PARKING_SESSIONS, {
    fetchPolicy: bypassCache ? 'network-only' : 'cache-and-network',
    skip: !isLoggedIn || customerLoading || !hasConnection,
    refetchWritePolicy: 'merge',
    variables: {
      input: {
        parkingState: ['Started'],
        sessionType: ['ANPR', 'START_STOP'],
      },
    },
    onCompleted: ({ readParkingSessions }) => {
      const licensePlate =
        readParkingSessions?.content &&
        readParkingSessions.content[0]?.accessDevice?.value;
      if (licensePlate) {
        updateStateAndStorage(
          latestVehicleUsed,
          'latestVehicleUsed',
          licensePlate
        );
      }
      setInitialLoadingCompleted(true);
    },
  });

  const ongoingShortTermParkingSessions =
    data?.readParkingSessions?.content || [];

  const sessionToShow = () => {
    if (
      ongoingShortTermParkingSessions.length === 1 ||
      (ongoingShortTermParkingSessions.length > 1 && selectedSessionId === null)
    ) {
      return ongoingShortTermParkingSessions?.[0];
    }
    return ongoingShortTermParkingSessions.find(
      (parkingSession) => parkingSession?.sessionId === selectedSessionId
    );
  };
  // Atm we have design and case for only one ongoing session so pick first ongoing in array
  const session = sessionToShow();

  const {
    parkingZoneUid,
    sessionId = '',
    accessDevice,
    type: parkingSessionType,
  } = session || {};
  const licensePlate = accessDevice?.value || '';

  const parkingZoneResult = useQuery<Query, QueryParkZoneArgs>(
    showEVCharging ? GET_PARK_ZONE_WITH_CHARGING_STATIONS : GET_PARK_ZONE,
    {
      variables: { uid: parkingZoneUid || '' },
      skip: !parkingZoneUid || !session,
    }
  );

  const endTime = useMemo(
    () =>
      session?.predefinedEndDate
        ? new Date(session?.predefinedEndDate as string)
        : null,
    [session?.predefinedEndDate]
  );
  const { name } =
    vehicles?.find((v) => v.licensePlate?.plateText === licensePlate) || {};
  const vehicleLabel = getVehicleLabel(name || '', licensePlate);

  useEffect(() => {
    ongoingParkingSession({
      sessionId,
      vehicleLabel,
      endTime,
      parkingSessionType: parkingSessionType,
      parkZoneUid: parkingZoneUid as string,
    });
  }, [sessionId, vehicleLabel, endTime, parkingSessionType, parkingZoneUid]);

  return {
    parkingZoneResult,
    ongoingParkingSession: session,
    allParkingSessions: ongoingShortTermParkingSessions,
    ongoingParkingSessionLoading:
      loading && (skipInitialLoading ? initialLoadingCompleted : true),
    ongoingParkingSessionError: error,
    ongoingParkingSessionStartPolling: startPolling,
    ongoingParkingSessionStopPolling: stopPolling,
    ongoingParkingSessionRefetch: refetch,
  };
};

export default useOngoingParkingSession;
