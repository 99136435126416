import { Button } from 'aimo-ui';
import useRetryChargingPaymentMutation from 'hooks/useRetryChargingPaymentMutation';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChargingPaymentState
} from 'types/generatedSchemaTypes';

type RetryChargingPaymentProps = {
  token: string;
  chargingSessionId: string | undefined;
  loading: boolean;
  paymentState: ChargingPaymentState;
};

const RetryChargingPaymentButton: React.FC<RetryChargingPaymentProps> = ({
  token,
  chargingSessionId,
  loading,
  paymentState,
}) => {
  const { t } = useTranslation();
  const paymentCard = useSelectedPaymentMethod();
  const { retryChargingPayment, retryPaymentLoading } =
    useRetryChargingPaymentMutation({
      chargingSessionId,
    });

  const handleRetry = async () => {
    await retryChargingPayment({
      variables: { input: { cardId: paymentCard?.cardId, token } },
    });
  };

  return (
    <Button
      text={t('button.retryPayment')}
      size="l"
      testID="close"
      buttonType="primary"
      onPress={handleRetry}
      loading={retryPaymentLoading || loading}
      disabled={paymentState !== 'Failed' || retryPaymentLoading || loading}
    />
  );
};

export default RetryChargingPaymentButton;
