import { useMutation } from '@apollo/client';
import { Headline3 } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import SheetLayout from 'components/sheets/SheetLayout';
import HslContext from 'context/HslContext';
import { CREATE_HSL_AUTHENTICATION } from 'graphql/mutations/createHslAuthentication';
import { READ_HSL_AUTHENTICATION_STATUS } from 'graphql/queries/readHslAuthenticationStatus';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import base64 from 'react-native-base64';
import DeviceInfo from 'react-native-device-info';
import WebView, { WebViewNavigation } from 'react-native-webview';
import {
  Mutation,
  MutationCreateHslAuthenticationArgs,
} from 'types/generatedSchemaTypes';
import { getLang } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';

const HslAuthenticateScreen = () => {
  const { setCurrentScreen } = useContext(HslContext);
  const { t } = useTranslation();
  const { hslToken } = useContext(HslContext);

  const [createHslAuthentication] = useMutation<
    Mutation,
    MutationCreateHslAuthenticationArgs
  >(CREATE_HSL_AUTHENTICATION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: READ_HSL_AUTHENTICATION_STATUS }],
    onCompleted: () => showToast('hslTicket.authenticated', 'success'),
    onError: () => showToast('error.hslAuthenticationFailed', 'error'),
  });

  const payload = {
    locale: getLang(),
    redirectUrl: isWeb
      ? window.location.href
      : process.env.HSL_SALES_API_REDIRECT_URL,
    organizationId: process.env.HSL_ORGANIZATION_ID,
    nonce: DeviceInfo.getUniqueIdSync(),
  };

  const authenticateUrl = `${process.env.HSL_SALES_API_URL}${base64.encode(
    JSON.stringify(payload)
  )}`;

  const handleNavigateBack = useCallback(() => {
    setCurrentScreen('SELECT_TICKET');
  }, [setCurrentScreen]);

  const getToken = (url: string) =>
    url.substring(url.indexOf('=') + 1, url.indexOf('&state'));

  const onNavigationStateChange = async ({ url }: WebViewNavigation) => {
    await handleUrlChange(url);
  };

  const handleUrlChange = async (url: string) => {
    if (url.includes('response') && url.includes('token=')) {
      await createHslAuthentication({
        variables: { input: { hslToken: getToken(url) } },
      });
      handleNavigateBack();
    } else if (url.includes('error=')) {
      showToast('error.hslAuthenticationFailed', 'error');
      handleNavigateBack();
    }
  };

  useEffect(() => {
    if (isWeb) {
      if (hslToken) {
        (async () => {
          await createHslAuthentication({
            variables: { input: { hslToken } },
          });
          handleNavigateBack();
        })();
      } else {
        window.open(authenticateUrl);
        handleNavigateBack();
      }
    }
  }, [
    authenticateUrl,
    hslToken,
    handleNavigateBack,
    createHslAuthentication,
    setCurrentScreen,
  ]);

  return (
    <SheetLayout
      onHeadlineButtonPress={handleNavigateBack}
      testIDName="hsl-authenticate-screen"
      headlineComponent={
        <Headline3 center>{t('hslTicket.authenticationTitle')}</Headline3>
      }>
      {!isWeb && (
        <WebView
          useWebKit
          testID="strong-auth-webview"
          automaticallyAdjustContentInsets={false}
          source={{
            uri: authenticateUrl,
          }}
          onNavigationStateChange={onNavigationStateChange}
        />
      )}
    </SheetLayout>
  );
};

export default HslAuthenticateScreen;
