import { Headline1, Headline3, Meta, theme } from 'aimo-ui';
import CrickleCutCardBackground from 'components/common/CrickleCutCardBackground';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import {
  ColumnWithMargins,
  StyledImage,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';

const StyledView = styled.View`
  margin: 5px;
`;

const PermitContent = (item: any, index: number) => {
  const { t } = useTranslation();
  const permitItem = isWeb ? item : item.item;
  return (
    <>
      <StyledWrapper mb={10}>
        <StyledImage
          center={true}
          width={theme.normalising.widthPixel(104)}
          height={theme.normalising.heightPixel(100)}
          resizeMode="cover"
          source={require('assets/images/permit.png')}
          defaultSource={require('assets/images/permit.png')}
          testID={`company-permit-image-${index}`}
        />
      </StyledWrapper>
      <StyledWrapper mb={10}>
        <Headline1>{t('account.companyPermit')}</Headline1>
      </StyledWrapper>
      <StyledWrapper mb={theme.normalising.heightPixel(20)}>
        <Meta>{permitItem.name}</Meta>
      </StyledWrapper>
      <StyledWrapper mb={theme.normalising.heightPixel(20)}>
        <ColumnWithMargins ml={20} mr={20}>
          <Headline3 center>{t('account.companyPermits.validFor')}</Headline3>
        </ColumnWithMargins>
      </StyledWrapper>
    </>
  );
};

const CompanyPermitItem = (item: any, index: number) => {
  return (
    <StyledView key={`company-permit-item-${index}`}>
      <StyledWrapper center maxWidth={isWeb ? 600 : undefined}>
        <CrickleCutCardBackground
          aboveCutView={() => PermitContent(item, index)}
          belowCutView={() => (
            <StyledWrapper mb={theme.normalising.heightPixel(50)} />
          )}
        />
      </StyledWrapper>
    </StyledView>
  );
};

export default CompanyPermitItem;
