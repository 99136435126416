import { addDays, format, isAfter, isToday } from 'date-fns';
import i18n from 'i18next';
import { SalesOrderReceipt } from 'screens/permit/SalesOrderReceiptClass';
import { ParkzonePmcUnion } from 'types/common';
import {
  CompanyBenefit,
  Maybe,
  OrderLine,
  Permit,
  Pmc,
  PmcVisibility,
  PredefinedTicket,
  UnifiedPermit,
  Vehicle,
} from 'types/generatedSchemaTypes';
import { getRemainingDays, parseISO8601Duration } from 'utils/timeUtils';
import { getLocale } from './commonUtils';

export const isItemPmc = (item: ParkzonePmcUnion): item is Pmc => {
  return (item as Pmc).pmcId !== undefined;
};
export const isItemPermit = (item: ParkzonePmcUnion): item is UnifiedPermit => {
  return (item as UnifiedPermit).permitType === 'ACCESS';
};
export const isItemPredefinedTicket = (
  item: ParkzonePmcUnion
): item is PredefinedTicket => {
  return (
    (item as PredefinedTicket).uid !== undefined ||
    (item as PredefinedTicket).sessionId !== undefined
  );
};

export const isItemSalesOrder = (
  item: ParkzonePmcUnion
): item is UnifiedPermit => {
  return (item as UnifiedPermit)?.orderLines !== undefined;
};

export const isItemOwned = (item: ParkzonePmcUnion) => {
  if (isItemPermit(item)) {
    return item.endDate;
  }
  if (isItemPmc(item)) {
    return null;
  }
  if (isItemSalesOrder(item)) {
    return isSalesOrderActive(item);
  }
  return item?.endTime || null;
};

export const getPermitsAmount = (
  permits?: Maybe<UnifiedPermit[]>,
  licensePlate?: string
) => {
  return (
    permits?.filter((permit) =>
      permit?.registrationPlates?.find(
        (plate) => plate?.plateText === licensePlate
      )
    ).length || 0
  );
};

export const isActivePermit = (permit: Permit) => {
  const expirationTime = new Date(permit.expirationDate ?? '');
  return (
    permit?.permitStatus?.status === 'ACTIVE' ||
    (permit?.permitStatus?.status === 'TERMINATED' &&
      getRemainingDays(expirationTime) >= 0)
  );
};

export const isPermitForParkZone = (permit: Permit, parkZoneUid?: string) =>
  permit?.parkingZoneUid === parkZoneUid;

export const isPermitForVehicle = (permit: Permit, selectedVehicle?: Vehicle) =>
  permit?.licensePlates?.[0] === selectedVehicle?.licensePlate?.plateText;

export const durationText = (item: ParkzonePmcUnion) => {
  if (isItemPredefinedTicket(item) && item.duration) {
    const duration = parseISO8601Duration(item.duration);
    if (duration) {
      return duration > 1
        ? `${duration} ${i18n.t('time.days')}`
        : `${duration} ${i18n.t('time.day')}`;
    }
  }
};

/**
 * Checks PMC)is visible based on its visibility and household benefits.
 *
 * @param {Pmc} pmc - The PMC to check for visibility.
 * @param {Maybe<CompanyBenefit>[]} householdBenefits - An array of household benefits.
 * @returns {boolean} `true` if the PMC is visible, `false` otherwise.
 */
export const isPmcVisible = (
  pmc: Pmc,
  householdBenefits: Maybe<CompanyBenefit>[]
) => {
  return (
    pmc.paymentMethods?.some(
      (paymentMethod) => paymentMethod === 'CUSTOMER_CREDIT_CARD'
    ) &&
    (pmc.visibility === 'PUBLIC' ||
      (pmc.visibility === 'PRIVATE' &&
        householdBenefits?.some(
          (benefit) => benefit?.benefitTargetUid === pmc.pmcId
        )))
  );
};

export const isSalesOrderActive = (salesOrder: UnifiedPermit) =>
  salesOrder.orderStatus === 'ACTIVE' ||
  salesOrder.orderStatus === 'ACTIVATING';

export const isSalesOrderWaiting = (
  salesOrder: SalesOrderReceipt | Maybe<UnifiedPermit> | undefined
) => salesOrder?.orderStatus === 'WAITING_FOR_ACTIVATION';

export const salesOrderForZone = (
  salesOrder: UnifiedPermit,
  parkZoneUid?: string
) => {
  const orderLine = salesOrder?.orderLines?.[0];
  const orderLineZone = orderLine?.pmc?.physicalZoneUid;

  return orderLineZone === parkZoneUid;
};

export const isSalesOrderForVehicle = (
  orderLine: Maybe<OrderLine> | undefined,
  selectedVehicle?: Vehicle
) =>
  orderLine?.registrationPlates?.[0]?.plateText ===
  selectedVehicle?.licensePlate?.plateText;

export type SalesOrderExpiration = {
  expirationDateString: string;
  isExpired: boolean;
};

export const salesOrderExpiration = ({
  orderLines,
  created,
  isWaiting,
}: SalesOrderReceipt): SalesOrderExpiration => {
  const dateFormat = (date: Date) =>
    format(new Date(date), isToday(date) ? 'HH:mm' : 'dd.MM.yyyy', {
      locale: getLocale(),
    });
  const expirationLimit = orderLines?.find(Boolean)?.pmc?.expirationLimit;
  if (expirationLimit && created) {
    const expirationDate = addDays(new Date(created), expirationLimit);
    return {
      expirationDateString: dateFormat(expirationDate),
      isExpired: isWaiting && isAfter(new Date(), expirationDate),
    };
  }
  return { isExpired: false, expirationDateString: '' };
};

/**
 * Sorts an array of PMC items by a specified visibility.
 * @param pmcs - The array of PMC items to sort.
 * @param visibility - The visibility to use for sorting.
 * @returns A new array of PMC items sorted by the specified visibility.
 */
export const sortPmcsByVisibility = (
  pmcs: Maybe<Pmc[]> | undefined,
  visibility: PmcVisibility = 'PRIVATE'
) => {
  return [...(pmcs ?? [])].sort((a, b) => {
    if (a.visibility === visibility && b.visibility !== visibility) {
      return -1;
    } else if (a.visibility !== visibility && b.visibility === visibility) {
      return 1;
    } else {
      return 0;
    }
  });
};

/*
 * Check if access permit is redeemed
 */
export const isAccessPermitRedeemed = (permit: UnifiedPermit): boolean => {
  return !!permit?.registrationPlates && permit?.registrationPlates.length > 0;
};
