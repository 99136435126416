import i18n from 'i18next';
import { i18next } from 'i18n/init';
import { Lang } from 'types/generatedSchemaTypes';
import { getLang } from 'utils/commonUtils';
import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { supportedLanguages } from 'utils/languageUtils';

const CookieConsent = () => {
  const cc = window.initCookieConsent();
  i18next.on('initialized', () => {
    cc.run({
      current_lang: getLang(),
      autoclear_cookies: true, // default: false
      page_scripts: true, // default: false

      // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
      // delay: 0,                               // default: 0
      // auto_language: null                     // default: null; could also be 'browser' or 'document'
      // autorun: true,                          // default: true
      // force_consent: true, // default: false
      // hide_from_bots: false,                  // default: false
      // remove_cookie_tables: false             // default: false
      // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
      cookie_expiration: 365, // default: 182 (days)
      // cookie_necessary_only_expiration: 182   // default: disabled
      // cookie_domain: location.hostname,       // default: current domain
      // cookie_path: '/',                       // default: root
      // cookie_same_site: 'Lax',                // default: 'Lax'
      // use_rfc_cookie: false,                  // default: false
      // revision: 0,                            // default: 0

      /*    onFirstAction: (user_preferences, cookie) => {
        // callback triggered only once
      },

      onAccept: (cookie) => {
      },

      onChange: (cookie, changed_preferences) => {
      },
*/
      languages: languages(),
    });
  });
  return null;
};

const languages = (): Record<string, LanguageSetting> => {
  return supportedLanguages.reduce(
    (a, lng) => ({ ...a, [lng]: buildCookieConsent(lng) }),
    {}
  );
};

const buildCookieConsent = (lng: Lang): LanguageSetting => {
  const t = (key: string) => i18n.t(`cookieConsent.${key}`, { lng });
  return {
    consent_modal: {
      title: t('consent.title'),
      description: t('consent.description'),
      primary_btn: {
        text: t('consent.primaryButtonText'),
        role: 'accept_all', // 'accept_selected' or 'accept_all'
      },
      secondary_btn: {
        text: t('consent.secondaryButtonText'),
        role: 'accept_necessary', // 'settings' or 'accept_necessary'
      },
    },
    settings_modal: {
      title: t('settings.title'),
      save_settings_btn: t('settings.saveButton'),
      accept_all_btn: t('settings.acceptAllButton'),
      reject_all_btn: t('settings.rejectAllButton'),
      close_btn_label: t('settings.closeButtonLabel'),
      cookie_table_headers: [
        { col1: t('settings.cookieTableHeaders.col1') },
        { col2: t('settings.cookieTableHeaders.col2') },
        { col3: t('settings.cookieTableHeaders.col3') },
        { col4: t('settings.cookieTableHeaders.col4') },
      ],
      blocks: [
        {
          title: t('settings.blocks.header.title'),
          description: t('settings.blocks.header.description'),
        },
        {
          title: t('settings.blocks.necessary.title'),
          description: t('settings.blocks.necessary.description'),
          toggle: {
            value: 'necessary',
            enabled: true,
            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
          },
          cookie_table: [
            // list of all expected cookies
            {
              col1: 'cc_cookie',
              col2: location.hostname,
              col3: t('settings.blocks.necessary.consentExpiration'),
              col4: t('settings.blocks.necessary.consentDescription'),
            },
          ],
        },
        {
          title: t('settings.blocks.analytics.title'),
          description: t('settings.blocks.analytics.description'),
          toggle: {
            value: 'analytics', // your cookie category
            enabled: false,
            readonly: false,
          },
          cookie_table: [
            // list of all expected cookies
            {
              col1: '^_ga', // match all cookies starting with "_ga"
              col2: 'google.com',
              col3: t('settings.blocks.analytics.gaExpiration'),
              col4: t('settings.blocks.analytics.gaDescription'),
              is_regex: true,
            },
            {
              col1: '_gid',
              col2: 'google.com',
              col3: t('settings.blocks.analytics.gidExpiration'),
              col4: t('settings.blocks.analytics.gidDescription'),
            },
          ],
        },
        {
          title: t('settings.blocks.moreInformation.title'),
          description: t('settings.blocks.moreInformation.description'),
        },
      ],
    },
  };
};
export default CookieConsent;
