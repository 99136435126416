/* eslint-disable react-native/no-inline-styles */
import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { theme } from 'aimo-ui';
import useIsDesktop from 'hooks/useIsDesktop';
import React, { useEffect, useRef } from 'react';
import { Dimensions } from 'react-native';
import BottomSheet from 'react-native-simple-bottom-sheet';
import { activeElement } from 'states/common';
import StickySheetFooter from './StickySheetFooter';

type WebSheetProps = {
  children?: React.ReactNode | (() => void);
  snappingPoints?: number[];
  showBackDrop?: boolean;
  hideTabBarOnIndex?: number;
  bgColor?: string;
  stickyFooter?: JSX.Element | false;
  expandedState?: ReactiveVar<boolean>;
  bottom?: boolean;
  left?: number;
  bottomInset?: boolean;
  sheetMinHeight?: number;
  sheetMaxHeight?: number;
  webSheetOpenDefault?: boolean;
};
const Sheet: React.FC<WebSheetProps> = ({
  children,
  stickyFooter,
  bgColor,
  bottom,
  left = 30,
  expandedState,
  sheetMaxHeight,
  sheetMinHeight,
  webSheetOpenDefault = true,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const panelRef = useRef<any>();
  const isDesktop = useIsDesktop();
  const { height } = Dimensions.get('window');
  const selectedZone = useReactiveVar(activeElement);
  useEffect(() => {
    if (selectedZone !== '' && !panelRef.current.state.isPanelOpened) {
      panelRef.current.togglePanel();
    }
  }, [selectedZone]);

  useEffect(() => {
    if (expandedState && webSheetOpenDefault && !isDesktop) {
      expandedState(true);
    }
  }, [expandedState, webSheetOpenDefault, isDesktop]);

  return (
    <BottomSheet
      ref={panelRef}
      onOpen={() => expandedState && expandedState(true)}
      onClose={() => expandedState && expandedState(false)}
      togglePanel
      innerContentStyle={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginTop: 30,
        marginBottom: 30,
        height: 11500,
        paddingHorizontal: !isDesktop ? 5 : 15,
        backgroundColor: bgColor || theme.colors.white,
      }}
      lineContainerStyle={isDesktop ? { display: 'none' } : undefined}
      lineStyle={{ marginBottom: 0 }}
      wrapperStyle={{
        backgroundColor: bgColor || theme.colors.white,
        top: bottom ? undefined : isDesktop ? 110 : undefined,
        left: isDesktop ? left : 0,
        width: isDesktop ? 500 : '100%',
        borderRadius: isDesktop ? 20 : 0,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        paddingHorizontal: 0,
        bottom: bottom ? 10 : isDesktop ? 10 : 0,
      }}
      sliderMinHeight={sheetMinHeight}
      sliderMaxHeight={
        !isDesktop && sheetMaxHeight ? sheetMaxHeight : height * 0.8
      }
      isOpen={webSheetOpenDefault}>
      <>
        {typeof children === 'function' ? children() : children}
        <StickySheetFooter visible={!!stickyFooter}>
          {stickyFooter}
        </StickySheetFooter>
      </>
    </BottomSheet>
  );
};

export default Sheet;
