import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Button, Meta, theme } from 'aimo-ui';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { selectedPermit, sheetModal } from 'states/common';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import { NestedTabNavProps } from 'types/navigation';
import Logger from 'utils/Logger';
import { testIdentifiers } from 'utils/testIdentifiers';

const ItemUnavailableModalContent = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<NestedTabNavProps>();
  useEffect(() => {
    return () => {
      selectedPermit(undefined);
    };
  });
  const permitId = useReactiveVar(selectedPermit);
  const handleConfirmCancel = () => {
    sheetModal('');
  };
  const handleNavigateToPermit = () => {
    if (!permitId) {
      Logger.error('invalid operation: goToPermit without permitId');
      sheetModal('');
      return;
    }
    navigate('mainTabs', {
      screen: 'servicesScreen',
      params: { screen: 'permit', params: { permitId } },
    });
    sheetModal('');
  };
  return (
    <StyledWrapper mb={0}>
      <StyledWrapper mb={60} center testID="item-unavailable-description">
        <Meta center color={theme.colors.gray200}>
          {t('services.ongoingPermit')}
        </Meta>
      </StyledWrapper>
      <>
        <Button
          {...testIdentifiers('item-unavailable-go-to-permit')}
          text={t('button.goToPermit')}
          size="l"
          buttonType="secondary"
          onPress={handleNavigateToPermit}
        />
        <ColumnWithMargins mt={10}>
          <Button
            testID="item-unavailable-cancel"
            text={t('button.cancel')}
            size="l"
            buttonType="tertiary"
            onPress={handleConfirmCancel}
          />
        </ColumnWithMargins>
      </>
    </StyledWrapper>
  );
};

export default ItemUnavailableModalContent;
