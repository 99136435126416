import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_PARK_ZONE } from '@queries/getParkZone';
import { GET_PARK_ZONE_WITH_CHARGING_STATIONS } from '@queries/getParkZoneWithChargingStations';
import { Spinner } from 'aimo-ui';
import { Center } from 'components/park/styles/styles';
import ParkAndChargeTariffSection from 'components/park/tariff/ParkAndChargeTariffSection';
import ParkTariffSection from 'components/park/tariff/ParkTariffSection';
import useAllowedParkingTimes from 'hooks/useAllowedParkingTimes';
import useFeatureFlagEVCharging from 'hooks/useFeatureFlagEVCharging';
import React from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import {
  activeElement,
  activeElementType,
  selectedParkingZoneTab,
  selectedChargingPricingInfoSegment,
  selectedChargingPricingInfoSegmentTariffPlanId,
  selectedChargingPricingInfoSegmentNameBasedOnNotes,
  selectedTariffId,
  selectedTariffName,
  sheetModal,
  unlockTariffItem,
} from 'states/common';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { ParkingZoneType } from 'types/common';
import {
  AccessMethod,
  AccessType,
  ChargingStation,
  Query,
  QueryParkZoneArgs,
  SegmentProps,
} from 'types/generatedSchemaTypes';

const SelectTariffModalContent = () => {
  const parkingZoneUid = useReactiveVar(activeElement);
  const { pricingSegments, pricingInfoLoading, pricingCountryCode } =
    useAllowedParkingTimes(parkingZoneUid);
  const selectedTariff = useReactiveVar(selectedTariffId);
  const selectedChargingTariff = useReactiveVar(
    selectedChargingPricingInfoSegmentTariffPlanId
  );

  const showEVCharging = useFeatureFlagEVCharging();
  const { data } = useQuery<Query, QueryParkZoneArgs>(
    showEVCharging ? GET_PARK_ZONE_WITH_CHARGING_STATIONS : GET_PARK_ZONE,
    {
      variables: { uid: parkingZoneUid },
      skip: !parkingZoneUid,
    }
  );
  const zoneCode = data?.parkZone?.commercialZoneView?.zoneCode || undefined;
  const selectedTab = useReactiveVar(selectedParkingZoneTab);
  const selectedType = useReactiveVar(activeElementType);

  const showChargingSection =
    selectedType === 'CHARGING' ||
    (selectedType === 'PARK_AND_CHARGE' &&
      (selectedTab === 'PARK_AND_CHARGE' || selectedTab === 'CHARGE'));

  const handleLockedSegment = () => {
    return sheetModal('unlockTariff');
  };

  const setChargingTariff = (
    tariffId: string,
    tariffName: string,
    chargingTariff?: SegmentProps
  ) => {
    selectedChargingPricingInfoSegment(chargingTariff);
    if (chargingTariff) {
      selectedChargingPricingInfoSegmentTariffPlanId(tariffId);
      selectedChargingPricingInfoSegmentNameBasedOnNotes(tariffName);
    }
  };

  const setParkingTariff = (tariffId: string, tariffName: string) => {
    selectedTariffId(tariffId);
    selectedTariffName(tariffName);
  };

  const handleSetTariff = (
    tariffId: string,
    tariffName: string,
    type: ParkingZoneType,
    accessType?: AccessType,
    accessMethod?: AccessMethod,
    isUnlockValid?: boolean,
    chargingTariff?: SegmentProps
  ) => {
    if (accessType === 'LOCKED' && !isUnlockValid) {
      if (zoneCode) {
        unlockTariffItem({
          tariffId,
          tariffName,
          accessMethod,
          activeElementType: type,
          zoneCode,
        });
        return handleLockedSegment();
      }
      return;
    }
    if (selectedType === 'PARK_AND_CHARGE') {
      if (type === 'PARKING') {
        setParkingTariff(tariffId, tariffName);
      } else if (type === 'CHARGING') {
        setChargingTariff(tariffId, tariffName, chargingTariff);
      }
    } else {
      activeElementType(type);
      setParkingTariff(tariffId, tariffName);
      setChargingTariff(tariffId, tariffName, chargingTariff);
    }
    sheetModal('');
  };

  if (pricingInfoLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <ScrollView nestedScrollEnabled={true}>
      <ColumnWithMargins mb={40}>
        {!data?.parkZone?.parkingMethods?.unmanagedParking &&
          selectedTab !== 'CHARGE' &&
          pricingSegments && (
            <ParkTariffSection
              publishedParkTariffs={pricingSegments}
              selectedTariffId={selectedTariff}
              countryCode={pricingCountryCode || 'SE'}
              onPress={handleSetTariff}
              zoneCode={zoneCode}
            />
          )}
        {showEVCharging &&
          data?.parkZone?.parkingZoneChargingStations &&
          data?.parkZone?.parkingZoneChargingStations.length > 0 &&
          showChargingSection && (
            <ParkAndChargeTariffSection
              chargingStations={
                data.parkZone.parkingZoneChargingStations as ChargingStation[]
              }
              countryCode={pricingCountryCode || 'SE'}
              selectedTariffId={selectedChargingTariff}
              onPress={(tariffId, tariffName, type, chargingTariff) =>
                handleSetTariff(
                  tariffId,
                  tariffName,
                  type,
                  undefined,
                  undefined,
                  undefined,
                  chargingTariff
                )
              }
            />
          )}
      </ColumnWithMargins>
    </ScrollView>
  );
};

export default SelectTariffModalContent;
