import styled from 'styled-components/native';
import { CountryCode } from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';
import PriceEstimate from './PriceEstimate';

export const DEBOUNCE_ENDTIME_VALUE = 1300;
const timeWrapperMb = (marginBottom?: boolean | number) => {
  if (typeof marginBottom === 'number') {
    return `${marginBottom}px`;
  } else if (isWeb) {
    return '4vh';
  } else {
    return marginBottom ? '30px' : '0px';
  }
};

export const TimeWrapper = styled.View<{ marginBottom?: boolean | number }>`
  align-items: center;
  min-height: 25px;
  margin-bottom: ${(p) => timeWrapperMb(p.marginBottom)};
`;

export const PriceEstimateWrapper: React.FC<{
  parkingZoneCountryCode: CountryCode;
  testID?: string;
  darkMode?: boolean;
  marginBottom?: boolean;
}> = ({ parkingZoneCountryCode, testID, darkMode, marginBottom }) => {
  return (
    <TimeWrapper marginBottom={marginBottom}>
      <PriceEstimate
        parkingZoneCountryCode={parkingZoneCountryCode}
        testID={`${testID}-price-estimate`}
        darkMode={darkMode}
      />
    </TimeWrapper>
  );
};

export interface TimeSelectionProps {
  parkingZoneUid: string;
  parkingZoneCountryCode: CountryCode;
  sessionId?: string;
  darkMode?: boolean;
  testID?: string;
  minDate?: Date;
  maxDate?: Date;
  startTime?: Date;
  endTime?: Date;
  initTime?: Date;
  setEndTimeCallback: (endTime: Date) => void;
  setIsValidEndTimeCallback?: (isValidEndTime: boolean) => void;
}
