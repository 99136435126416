import { useReactiveVar } from '@apollo/client';
import { Spinner } from 'aimo-ui';
import kebabCase from 'lodash/kebabCase';
import React, { useMemo } from 'react';
import { Linking } from 'react-native';
import WebView from 'react-native-webview';
import { currentCustomerCountry, sheetModal } from 'states/common';
import { featureCountryCode } from 'states/persistInStorage';
import styled from 'styled-components/native';
import { getLang } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { getDomain } from 'utils/urlUtils';

const Container = styled.View`
  height: 100%;
  margin-bottom: 60px;
  margin: -20px;
`;

const SpinnerContainer = styled.View`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

type TermsPdfProps = {
  document: 'privacyPolicy' | 'termsAndConditions';
};

const TermsPdfModalContent: React.FC<TermsPdfProps> = ({
  document,
}: TermsPdfProps) => {
  const featureCountry = useReactiveVar(featureCountryCode);
  const currentCountry = useReactiveVar(currentCustomerCountry);
  const baseUrl = process.env.LEGAL_DOCS_BASE_URL || '';

  const countryCodeISO2 = currentCountry || featureCountry;
  const lang = getLang();

  const legalDocsUrl = useMemo(() => {
    const url = new URL(baseUrl);
    url.searchParams.append('lang', `${lang}-${countryCodeISO2}`);
    url.searchParams.append('legalDocumentType', kebabCase(document));
    url.searchParams.append('contentOnly', '1');
    return url.toString();
  }, [baseUrl, countryCodeISO2, document, lang]);

  if (!legalDocsUrl) {
    return <></>;
  }

  return isWeb ? (
    (window as any).open(legalDocsUrl, '_blank') && sheetModal('')
  ) : (
    <Container>
      <WebView
        renderLoading={() => (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        source={{
          uri: legalDocsUrl,
        }}
        testID="pdf-modal-webview"
        nestedScrollEnabled={true}
        originWhitelist={['https://*', 'tel:*', 'mailto:*']}
        onShouldStartLoadWithRequest={(request) => {
          if (request.url.includes(getDomain(legalDocsUrl))) {
            return true;
          }
          Linking.openURL(request.url);
          return false;
        }}
      />
    </Container>
  );
};

export default TermsPdfModalContent;
