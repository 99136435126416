import { ticketName } from 'components/history/HistoryComponents';
import { addDays, format } from 'date-fns';
import {
  BillingItem,
  Maybe,
  Receipt,
  SalesOrder,
  SalesOrderPaymentMethod,
  UnifiedPermit,
} from 'types/generatedSchemaTypes';
import { getLocale } from 'utils/commonUtils';

export type SalesOrderReceiptIntersection = UnifiedPermit &
  Receipt &
  SalesOrder;

export class SalesOrderReceipt {
  item: SalesOrderReceiptIntersection | undefined | null;

  constructor(
    item:
      | (Maybe<SalesOrder> | Maybe<Receipt> | Maybe<UnifiedPermit>)
      | undefined
  ) {
    this.item = item as SalesOrderReceiptIntersection;
  }

  public get uid() {
    return this.item?.id ?? '';
  }

  public get orderLines() {
    return this.salesOrder?.orderLines;
  }

  public get renewal() {
    return this.salesOrder?.renewal;
  }

  public get orderStatus() {
    return this.salesOrder?.orderStatus;
  }

  public get countryCode() {
    return this.salesOrder?.countryCode;
  }

  public get creditCardId() {
    return this.item?.creditCard?.cardId ?? this.item?.creditCardId;
  }

  public get paymentState() {
    return this.salesOrder?.paymentState;
  }

  public get vatPercent() {
    return this.item?.vatPercent ?? this.firstBillingItem?.vatPercentage;
  }

  public get created() {
    return this.item?.created;
  }

  public get billingItems() {
    return this.item?.items as [BillingItem];
  }

  public get totalAmount() {
    if (this.firstBillingItem?.amountCents) {
      return this.firstBillingItem.amountCents;
    }
    return this.firstOrderLine?.lineTotal ?? 0;
  }

  public get title() {
    return (
      ticketName(
        this.firstBillingItem?.zoneName,
        this.firstBillingItem?.zoneCode,
        ''
      ) || this.firstOrderLine?.pmc?.parkZone?.name
    );
  }

  public get plateText() {
    return (
      this.firstBillingItem?.licensePlate ??
      this.firstOrderLine?.registrationPlates?.find(Boolean)?.plateText
    );
  }

  private get paymentMethod() {
    return this.item?.paymentMethod;
  }

  public get isInvoicing() {
    return !!(
      this.paymentMethod &&
      (['INVOICE', 'INTRUM_INVOICING'] as SalesOrderPaymentMethod[]).includes(
        this.paymentMethod
      )
    );
  }

  public get subMerchant() {
    return this.item?.subMerchant;
  }

  public get isFailedPayment() {
    return this.salesOrder?.paymentState === 'FAILED_PAY';
  }

  public get isActivating() {
    return this.orderStatus === 'ACTIVATING';
  }

  public get isWaiting() {
    return this.orderStatus === 'WAITING_FOR_ACTIVATION';
  }

  public get isUpdating() {
    return this.orderStatus === 'UPDATING';
  }

  public get isInProgress() {
    return this.isActivating || this.isWaiting || this.isUpdating;
  }

  public get endDate() {
    return this.salesOrder?.endDate;
  }

  public get isEnding() {
    return !!this.salesOrder?.endDate;
  }

  public get nextBillingDate() {
    const date =
      this.firstOrderLine?.charge?.validity?.validTo ??
      this.firstBillingItem?.endTime;
    const daysToAdd = this.isRecurring ? 1 : 0;
    return date ? addDays(new Date(date), daysToAdd) : undefined;
  }

  public get paymentAttempted() {
    const dates =
      this.firstOrderLine?.charge?.creditCardEvents?.content
        ?.map((a) => a.created)
        .filter((a): a is Date => !!a) ?? [];
    if (dates.length > 0) {
      const maxDate = new Date(
        Math.max(...dates.map((date) => new Date(date).getTime()))
      );
      return format(maxDate, 'dd.MM.yyyy HH:mm', {
        locale: getLocale(),
      });
    }
    return '';
  }

  public get isRecurring() {
    return !!(
      this.firstOrderLine?.billingFrequency === 'MONTHLY' && this.renewal
    );
  }

  private get firstOrderLine() {
    return this.orderLines?.find(Boolean);
  }

  private get salesOrder() {
    return this.item?.salesOrder ?? this.item;
  }

  private get firstBillingItem() {
    return this.item?.items?.find(Boolean);
  }
}
