import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Headline1, Meta, theme } from 'aimo-ui';
import EmptyView from 'components/account/companyBenefits/EmptyView';
import CrickleCutCardBackground from 'components/common/CrickleCutCardBackground';
import AccountContext from 'context/AccountContext';
import { default as React, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';
import RedeemPermitButton from 'screens/permit/RedeemPermitButton';
import { tabBarVisible } from 'states/common';
import styled from 'styled-components/native';
import { SheetDetailsWrapper, StyledWrapper } from 'styles/ContainerStyles';
import { UnifiedPermit } from 'types/generatedSchemaTypes';
import {
  RootNavProps,
  ServicesNavProps,
  ServicesStackParamList,
} from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { salesOrderExpiration } from 'utils/permitUtils';
import ActivatePermitButton from './ActivatePermitButton';
import { ActivateInfoBox, PaymentErrorInfoBox } from './PermitInfoBoxes';
import PermitTicketContent from './PermitTicketContent';
import {
  SalesOrderReceipt,
  SalesOrderReceiptIntersection,
} from './SalesOrderReceiptClass';

const PermitWrapper = styled.TouchableOpacity`
  margin-bottom: 20px;
`;

const PermitTitleContainer = styled.View`
  width: 100%;
  padding: 15px 20px;
`;

const PermitItemTitle = ({ permit }: { permit: UnifiedPermit }) => {
  const { t } = useTranslation();
  const { id, orderLines, parkingFacilityName, isWaitingActivation } = permit;
  const { pmc } = orderLines?.[0] ?? {};
  const metaText =
    permit?.permitType === 'ACCESS'
      ? t('permit.access.name')
      : t('services.permitParking');
  const salesOrder =
    permit?.permitType === 'SALES_ORDER'
      ? new SalesOrderReceipt(permit as SalesOrderReceiptIntersection)
      : null;

  return (
    <PermitTitleContainer>
      <StyledWrapper center mb={5}>
        <Headline1 testID={`permit-${id}-name`}>
          {parkingFacilityName || pmc?.parkZone?.name || ''}
        </Headline1>
      </StyledWrapper>
      <StyledWrapper center>
        <Meta color={theme.colors.gray200}>{metaText}</Meta>
      </StyledWrapper>
      {salesOrder && <PaymentErrorInfoBox salesOrder={salesOrder} />}
      {salesOrder?.isInProgress && (
        <StyledWrapper mb={10}>
          <ActivateInfoBox
            salesOrder={salesOrder}
            expiration={salesOrderExpiration(salesOrder)}
          />
        </StyledWrapper>
      )}
      {salesOrder?.isWaiting && (
        <StyledWrapper mb={0}>
          <ActivatePermitButton
            permitId={salesOrder.uid}
            isExpired={salesOrderExpiration(salesOrder)?.isExpired}
          />
        </StyledWrapper>
      )}
      {!salesOrder && isWaitingActivation && (
        <StyledWrapper mb={0}>
          <RedeemPermitButton permitId={id} />
        </StyledWrapper>
      )}
    </PermitTitleContainer>
  );
};

const PermitItem = ({
  permit,
  cutBackgroundColor,
}: {
  permit: UnifiedPermit;
  cutBackgroundColor?: string;
}) => {
  const { navigate } = useNavigation<ServicesNavProps>();

  const handleClick = () => {
    if (permit.permitType === 'ACCESS') {
      navigate('permitRedeem', { permitId: permit.id });
    } else {
      navigate('permit', { permitId: permit.id });
    }
  };

  return (
    <PermitWrapper onPress={handleClick} activeOpacity={0.65}>
      <CrickleCutCardBackground
        aboveCutView={<PermitItemTitle permit={permit} />}
        belowCutView={<PermitTicketContent permit={permit} />}
        backgroundColor={theme.colors.white}
        cutColor={cutBackgroundColor ?? theme.colors.gray100}
      />
    </PermitWrapper>
  );
};

const Permits: React.FC = () => {
  const { navigate, addListener } = useNavigation<RootNavProps>();

  const {
    customer: { permits },
  } = useContext(AccountContext);
  const { params } = useRoute<RouteProp<ServicesStackParamList, 'permits'>>();
  const { licensePlate, cutBackgroundColor } = params ?? {};

  const filteredPermits = licensePlate
    ? permits?.filter((unifiedPermit) =>
        unifiedPermit?.registrationPlates?.find(
          (plate) => plate?.plateText === licensePlate
        )
      )
    : permits;

  // Hide tab bar
  useEffect(() => {
    tabBarVisible(isWeb);
  }, []);

  useEffect(() => {
    addListener('beforeRemove', handleNavigation);
    return () => {
      addListener('beforeRemove', handleNavigation);
    };
  }, [addListener, navigate]);

  const handleNavigation = (e: any) => {
    // Show tab bar when navigates back
    if (e?.data.action.type === 'GO_BACK') {
      tabBarVisible(true);
    }
  };

  return (
    <ScrollView>
      <SheetDetailsWrapper>
        <StyledWrapper
          center={isWeb}
          mb={10}
          maxWidth={isWeb ? 600 : undefined}>
          {(filteredPermits ?? []).length > 0 ? (
            filteredPermits?.map((permit) => (
              <PermitItem
                permit={permit}
                key={permit?.id}
                cutBackgroundColor={cutBackgroundColor}
              />
            ))
          ) : (
            <EmptyView LTP />
          )}
        </StyledWrapper>
      </SheetDetailsWrapper>
    </ScrollView>
  );
};

export default Permits;
