import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Meta, theme } from 'aimo-ui';
import { StyledWrapper } from 'styles/ContainerStyles';
import { sheetModal } from 'states/common';

const AccountRemovalPreventedLinkedService = () => {
  const { t } = useTranslation();

  return (
    <StyledWrapper mb={0}>
      <StyledWrapper mb={40}>
        <Meta
          center
          color={theme.colors.gray200}
          testID="account-remove-prevent-reason-linked-service">
          {t('account.remove.preventedLinkedService')}
        </Meta>
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <Button
          size="l"
          onPress={() => sheetModal('')}
          text={t('button.close')}
          testID="close-button"
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default AccountRemovalPreventedLinkedService;
