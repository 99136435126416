import { useReactiveVar } from '@apollo/client';
import { useHeaderHeight } from '@react-navigation/elements';
import { BottomButtonsLayout, theme } from 'aimo-ui';
import VehicleFormButtons from 'components/account/vehicle/VehicleFormButtons';
import VehicleFormDetails from 'components/account/vehicle/VehicleFormDetails';
import VehicleFormExtras from 'components/account/vehicle/VehicleFormExtras';
import VehicleFormTabs, {
  vehicleTabsValue,
} from 'components/account/vehicle/VehicleFormTabs';
import AccountContext from 'context/AccountContext';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Pressable,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {
  editVehicleCameraRecognitionEnabled,
  selectedVehicleTypeForm,
} from 'states/common';
import styled from 'styled-components/native';
import { ScreenWrapper } from 'styles/ContainerStyles';
import { Vehicle, VehicleType } from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';
import { getPermitsAmount } from 'utils/permitUtils';
import { validateLicensePlate } from 'utils/vehicleUtils';

export type VehicleFormProps = {
  isNew: boolean;
  isOnboarding?: boolean;
  isParkingPermitFlow?: boolean;
  initialVehicle?: Vehicle;
  onSubmit: (vehicle: Vehicle) => void;
  onDelete?: () => void;
  onFormChange?: (vehicle: Vehicle) => void;
  loading?: boolean;
  hasUnsavedChanges?: boolean;
};

type KeyboardAvoidProps = {
  children?: ReactNode;
};

const ButtonsWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.white};
  padding: ${theme.normalising.heightPixel(17)}px 20px 0;
`;

const AvoidKeyboard = ({ children }: KeyboardAvoidProps) => {
  const headerHeight = useHeaderHeight();

  if (isWeb) {
    return (
      <ScreenWrapper tabBarHeight={headerHeight} padding={0}>
        {children}
      </ScreenWrapper>
    );
  }
  return (
    <KeyboardAvoidingView
      behavior="position"
      enabled
      keyboardVerticalOffset={Platform.select({ ios: -30, android: -110 })}>
      {children}
    </KeyboardAvoidingView>
  );
};

const VehicleForm = ({
  isNew,
  isOnboarding = false,
  isParkingPermitFlow = false,
  initialVehicle,
  onSubmit,
  onDelete,
  onFormChange,
  loading,
  hasUnsavedChanges,
}: VehicleFormProps) => {
  const selectedVehicleType = useReactiveVar(selectedVehicleTypeForm);
  const isCameraRecognitionEnabled = useReactiveVar(
    editVehicleCameraRecognitionEnabled
  );
  const [licensePlate, setLicensePlate] = useState(
    initialVehicle?.licensePlate?.plateText || ''
  );
  const [name, setName] = useState(initialVehicle?.name || '');
  const [color, setColor] = useState(initialVehicle?.appSettings?.color || '');
  const [icon, setIcon] = useState(initialVehicle?.appSettings?.icon || '');
  const [vehicleType, setVehicleType] = useState<VehicleType | undefined>(
    initialVehicle?.appSettings?.type || selectedVehicleType || undefined
  );
  const [vehicleCountry, setVehicleCountry] = useState(
    initialVehicle?.licensePlate?.countryCode || undefined
  );
  const [cameraRecognitionEnabled, setCameraRecognitionEnabled] = useState(
    initialVehicle?.cameraRecognition?.enabled || false
  );
  const [chargerPlugType, setChargerPlugType] = useState(
    initialVehicle?.appSettings?.evPlugType || undefined
  );
  const [selectedVehicleTab, setSelectedVehicleTab] =
    useState<vehicleTabsValue>('DETAILS');
  const [showLicensePlateError, setShowLicensePlateError] = useState(false);

  const {
    customer: { countryCode, permits },
  } = useContext(AccountContext);

  const vehicle = useMemo(
    () => ({
      id: initialVehicle?.id || licensePlate,
      name: name,
      licensePlate: { plateText: licensePlate, countryCode: vehicleCountry },
      cameraRecognition: {
        allowed: cameraRecognitionEnabled || false,
        enabled: cameraRecognitionEnabled || false,
        countryCodes: initialVehicle?.cameraRecognition?.countryCodes || [
          countryCode,
        ],
      },
      appSettings: {
        icon,
        color,
        type: vehicleType,
        evPlugType: chargerPlugType,
      },
    }),
    [
      cameraRecognitionEnabled,
      chargerPlugType,
      color,
      countryCode,
      icon,
      initialVehicle?.cameraRecognition?.countryCodes,
      initialVehicle?.id,
      licensePlate,
      name,
      vehicleCountry,
      vehicleType,
    ]
  );

  const permitsAmount = getPermitsAmount(
    permits,
    initialVehicle?.licensePlate?.plateText
  );

  // Update cameraRecognitionEnabled when the reactive variable changes.
  useEffect(() => {
    setCameraRecognitionEnabled(isCameraRecognitionEnabled);
  }, [isCameraRecognitionEnabled]);

  const dismissHandler = () => {
    Keyboard.dismiss();
  };

  const handleSubmit = () => {
    if (!validateLicensePlate(licensePlate)) {
      setSelectedVehicleTab('DETAILS');
      setShowLicensePlateError(true);
      return;
    }
    setShowLicensePlateError(false);
    onSubmit(vehicle);
  };

  useEffect(() => {
    if (onFormChange) {
      onFormChange(vehicle);
    }
  }, [vehicle, onFormChange]);

  return (
    <BottomButtonsLayout
      contentBottom={
        <ButtonsWrapper>
          <VehicleFormButtons
            isNew={isNew}
            loading={loading}
            hasUnsavedChanges={hasUnsavedChanges}
            onDelete={onDelete}
            handleSubmit={handleSubmit}
          />
        </ButtonsWrapper>
      }>
      <AvoidKeyboard>
        <ScrollView
          scrollIndicatorInsets={{ right: 1 }}
          scrollEventThrottle={0}>
          <Pressable onPress={dismissHandler}>
            <ScreenWrapper tabBarHeight={15}>
              <>
                <VehicleFormTabs
                  selectedVehicleTab={selectedVehicleTab}
                  handleOnSelectedTab={setSelectedVehicleTab}
                />
                {selectedVehicleTab === 'DETAILS' && (
                  <VehicleFormDetails
                    initialVehicle={initialVehicle}
                    licensePlate={licensePlate}
                    setLicensePlate={setLicensePlate}
                    showLicensePlateError={showLicensePlateError}
                    permitsAmount={permitsAmount}
                    vehicleType={vehicleType}
                    setVehicleType={setVehicleType}
                    vehicleCountry={vehicleCountry}
                    setVehicleCountry={setVehicleCountry}
                    chargerPlugType={chargerPlugType}
                    setChargerPlugType={setChargerPlugType}
                    cameraRecognitionEnabled={cameraRecognitionEnabled}
                    isOnboarding={isOnboarding}
                    isParkingPermitFlow={isParkingPermitFlow}
                  />
                )}
                {selectedVehicleTab === 'EXTRAS' && (
                  <VehicleFormExtras
                    name={name}
                    setName={setName}
                    color={color}
                    setColor={setColor}
                    icon={icon}
                    setIcon={setIcon}
                    isNew={isNew}
                  />
                )}
              </>
            </ScreenWrapper>
          </Pressable>
        </ScrollView>
      </AvoidKeyboard>
    </BottomButtonsLayout>
  );
};

export default VehicleForm;
