import { ApolloError, useQuery } from '@apollo/client';
import { READ_ONGOING_CHARGING_SESSION } from '@queries/readOngoingChargingSession';
import AccountContext from 'context/AccountContext';
import NetworkConnectionContext from 'context/NetworkConnectionContext';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useContext, useEffect } from 'react';
import { ongoingChargingSession } from 'states/common';
import { ChargingSession, Maybe, Query } from 'types/generatedSchemaTypes';

const useOngoingChargingSession = (
  bypassCache = false
): {
  ongoingChargingSession?: Maybe<ChargingSession>;
  ongoingChargingSessionError?: ApolloError;
  ongoingChargingSessionStartPolling: (pollInterval: number) => void;
  ongoingChargingSessionStopPolling: () => void;
  ongoingChargingSessionRefetch: () => void;
  ongoingChargingSessionLoading: boolean;
} => {
  const { isLoggedIn } = useIsLoggedIn();
  const { hasConnection } = useContext(NetworkConnectionContext);
  const { loading: customerLoading } = useContext(AccountContext) || [];
  const { data, error, startPolling, stopPolling, refetch, loading } =
    useQuery<Query>(READ_ONGOING_CHARGING_SESSION, {
      fetchPolicy: bypassCache ? 'network-only' : 'cache-and-network',
      skip: !isLoggedIn || customerLoading || !hasConnection,
    });

  const { chargingSessionId } = data?.readOngoingChargingSessionV3 || {};

  useEffect(() => {
    ongoingChargingSession(chargingSessionId || '');
  }, [chargingSessionId]);

  return {
    ongoingChargingSession: data?.readOngoingChargingSessionV3,
    ongoingChargingSessionError: error,
    ongoingChargingSessionStartPolling: startPolling,
    ongoingChargingSessionStopPolling: stopPolling,
    ongoingChargingSessionRefetch: refetch,
    ongoingChargingSessionLoading: loading,
  };
};

export default useOngoingChargingSession;
