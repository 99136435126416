import { Route } from '@react-navigation/native';
import { theme } from 'aimo-ui';
import config from 'config/config';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { enUS, fi, nb, sv } from 'date-fns/locale';
import i18n from 'i18next';
import ReactNativeBlobUtil from 'react-native-blob-util';
import { getVersion } from 'react-native-device-info';
import { throttleTimers } from 'states/common';
import {
  BillingItem,
  CompanyBenefit,
  CountryCode,
  Lang,
  Maybe,
  ParkZone,
  Pricing,
  Vehicle,
  VehicleCountryCode,
} from 'types/generatedSchemaTypes';
import Logger from 'utils/Logger';
import { isAndroid, isIos, isWeb } from 'utils/deviceUtils';
import { version as packageJsonVersion } from '../../package.json';

export type CurrencyProps = 'EUR' | 'NOK' | 'SEK';

interface LangMapProps {
  [index: string]: Locale;
}

const langMap: LangMapProps = {
  fi,
  en: enUS,
  no: nb,
  sv,
};

export const fallbackCountry = 'Finland';
export const fallbackCountryCode = 'FI';

export const getLocale = () => langMap[i18n.language] || enUS;

export const getLang = () =>
  (i18n?.language?.toLowerCase() as Lang) || ('en' as Lang);

export const getCurrencyLocales = (country: CountryCode = 'FI') => {
  const currencyLocales = {
    locales: 'fi-FI',
    currency: 'EUR',
  };

  if (country === 'SE') {
    currencyLocales.locales = 'sv-SE';
    currencyLocales.currency = 'SEK';
  }
  if (country === 'NO') {
    currencyLocales.locales = 'no-NO';
    currencyLocales.currency = 'NOK';
  }
  return currencyLocales;
};

// TODO: remove this when RN 0.65 is released
export const getCurrencyString = (country: CountryCode = 'FI') => {
  if (country === 'SE') {
    return 'kr';
  }
  if (country === 'NO') {
    return 'kr';
  }
  return '€';
};

export const getDistanceText = (distanceInKms: number) => {
  return distanceInKms >= 1
    ? `${Number(Math.round(Number(distanceInKms + 'e1')) + 'e-1')} km`
    : `${Math.round(distanceInKms * 1000)} m`;
};

export const getCorrectBillingItem = (
  value?: BillingItem[]
): BillingItem[] | undefined =>
  value && value.length > 0
    ? value.filter((data) => data.type !== 'SERVICE_FEE')
    : undefined;

export const currencyFormatter = (
  value: number,
  country: CountryCode,
  withDecimal?: boolean
) => {
  const formatter = new global.Intl.NumberFormat(
    getCurrencyLocales(country)?.locales,
    {
      style: 'currency',
      currency: getCurrencyLocales(country)?.currency,
      minimumFractionDigits: withDecimal ? 2 : 0,
      maximumFractionDigits: 2,
    }
  );
  let formattedValue = formatter.format(value);
  formattedValue = formattedValue.replace(/\s/g, ' ');
  return formattedValue;
};

export const numberFormatter = (value: number, country: CountryCode) => {
  const formatter = new global.Intl.NumberFormat(
    getCurrencyLocales(country)?.locales
  );
  return formatter.format(value);
};

/**
 * parse document title for @react-navigation NavigationContainer
 * @param  {Record<string, any> | undefined} options options from @react-navigation options
 * @param {Route<string, object | undefined> | undefined} route from @react-navigation options
 * @return {string} format string to Title - Domain/ThemeTitle
 */
export const titleFormatter = (
  options: Record<string, any> | undefined,
  // eslint-disable-next-line @typescript-eslint/ban-types
  route: Route<string, object | undefined> | undefined
) => {
  let title: string = options?.title ?? route?.name;
  title += theme?.name ? ` - ${theme.name}` : '';
  return title;
};

export const countryCodeToServiceChannel = (
  countryCode: CountryCode
): string => {
  switch (countryCode) {
    case 'SE':
      return 'FI-AIMO-ONE';
    case 'NO':
      return 'NO-AIMO-ONE';
    default:
      return 'FI-AIMO-ONE';
  }
};

export const countryCodeToLang = (countryCode: CountryCode = 'FI'): Lang => {
  switch (countryCode) {
    case 'SE':
      return 'sv';
    case 'NO':
      return 'no';
    default:
      return 'fi';
  }
};

export const countryCodeToCountry = (countryCode?: CountryCode): string => {
  switch (countryCode) {
    case 'FI':
      return 'Finland';
    case 'SE':
      return 'Sweden';
    case 'NO':
      return 'Norway';
    default:
      Logger.warn(
        `Getting country from countryCode ${countryCode} returns fallbackCountry: ${fallbackCountry}`
      );
      return fallbackCountry;
  }
};

export const countryToCountryCode = (country?: string): CountryCode => {
  switch (country) {
    case 'Finland':
      return 'FI';
    case 'Sweden':
      return 'SE';
    case 'Norway':
      return 'NO';
    default:
      Logger.warn(
        `Getting country from countryCode ${country} returns fallbackCountry: ${fallbackCountryCode}`
      );
      return fallbackCountryCode;
  }
};

export const getPriceForSalesOrder = (
  prices: Maybe<Pricing[]> | undefined,
  countryCode: CountryCode
) => {
  if (!prices || prices?.length === 0) {
    return i18n.t('price.noPrice');
  }
  const validPrice = getValidPrice(prices);
  if (
    validPrice?.thirtyDayPrice !== null &&
    validPrice?.thirtyDayPrice !== undefined
  ) {
    const price = currencyFormatter(
      (validPrice?.thirtyDayPrice || 0) / 100,
      countryCode
    );
    return i18n.t('price.thirtyDay', { price });
  } else if (
    validPrice?.monthlyPrice !== undefined &&
    validPrice?.monthlyPrice !== null
  ) {
    const price = currencyFormatter(
      (validPrice?.monthlyPrice || 0) / 100,
      countryCode
    );
    return i18n.t('price.monthlyPrice', { price });
  }
  return i18n.t('price.noPrice');
};

export const isSalesOrderFree = (prices: Maybe<Pricing[]> | undefined) => {
  if (!prices || prices?.length === 0) {
    return false;
  }
  const validPrice = getValidPrice(prices);
  if (validPrice?.thirtyDayPrice !== null) {
    return validPrice?.thirtyDayPrice === 0;
  } else if (validPrice?.monthlyPrice !== null) {
    return validPrice?.monthlyPrice === 0;
  }
  return false;
};
export const getMonthlyPrice = (
  prices: Maybe<Pricing[]> | undefined,
  countryCode: CountryCode
) => {
  if (!prices || prices?.length === 0) {
    return i18n.t('price.noPrice');
  }
  const validPrice = getValidPrice(prices);
  if (validPrice?.monthlyPrice) {
    const price = currencyFormatter(
      validPrice?.monthlyPrice / 100,
      countryCode
    );
    return i18n.t('price.monthlyPrice', { price });
  }
  return i18n.t('price.noPrice');
};

export const getValidPrice = (prices: Pricing[]) => {
  return prices.find((price: Pricing) => {
    const { validity } = price;
    if (validity?.validFrom) {
      const today = new Date();
      const validFromDate = new Date(validity.validFrom);
      const validToDate = validity.validTo ? new Date(validity.validTo) : null;
      const isValidFrom =
        isSameDay(today, validFromDate) || isAfter(today, validFromDate);
      const isValidTo =
        !validToDate ||
        isSameDay(today, validToDate) ||
        isBefore(today, validToDate);
      return isValidFrom && isValidTo;
    }
    return false;
  });
};

export const getFlattenObject = (
  object: any,
  flattenedObject: any,
  flattenedKey?: any
) => {
  Object.keys(object).forEach((key: string) => {
    let newKey;
    if (flattenedKey === undefined) {
      newKey = key;
    } else {
      newKey = flattenedKey + '.' + key;
    }
    const value = object[key];
    if (typeof value === 'object') {
      getFlattenObject(value, flattenedObject, newKey);
    } else {
      flattenedObject[newKey] = value;
    }
  });
  return flattenedObject;
};

export const getParkingZoneName = (parkZone?: Maybe<ParkZone>) => {
  return `${parkZone?.name}${
    parkZone?.address?.city ? ', ' + parkZone?.address?.city : ''
  }`;
};

export const isRunningLocally = () => {
  return (
    config.dotEnvFilenameVar === 'env' || process.env.NODE_ENV === 'development'
  );
};

export const isDatadogAvailable = (): boolean => {
  // For now, we do not set up Datadog in Web browser neither in local environment.
  return !isWeb && !isRunningLocally();
};

export const splitAddress = (street: string) => {
  const streetObj: string[] = street
    .trim()
    .replace(/\s+/g, ' ')
    .split(/^([a-zA-Z]{1,30}?)\s(\d{1,9}?)?$/)
    .filter((e) => e);
  return {
    streetName: streetObj[0],
    streetNumber: streetObj[1],
  };
};

export const getShouldAppUpdate = (
  mandatoryVersion: string,
  optionalVersion: string,
  currentVersion: string
) => {
  if (currentVersion === '') {
    return { mandatory: false, optional: false };
  }
  return {
    mandatory: compareVersions(mandatoryVersion, currentVersion) > 0,
    optional: compareVersions(optionalVersion, currentVersion) > 0,
  };
};

export const compareVersions = (version1: string, version2: string) => {
  const v1Parts = version1?.split('.').map(Number);
  const v2Parts = version2?.split('.').map(Number);

  for (let i = 0; i < Math.max(v1Parts?.length, v2Parts?.length); i++) {
    const v1Part = v1Parts[i] || 0;
    const v2Part = v2Parts[i] || 0;

    if (v1Part < v2Part) {
      return -1;
    } else if (v1Part > v2Part) {
      return 1;
    }
  }

  return 0; // Both versions are equal
};

// Read the getVersion function from react-native-device-info and return the version.
// If version has format X.Y.Z-<env>.<hash>, return the version without the -<env>.<hash> part.
export const getFormattedVersion = () => {
  const version = isWeb ? packageJsonVersion : getVersion();
  return version.split('-')[0];
};

export const getCountryFromCurrency = (currency?: string) => {
  switch (currency) {
    case 'SEK':
      return 'SE';
    case 'EUR':
      return 'FI';
    case 'NOK':
      return 'NO';
  }
};

export const convertBase64DataToPDF = (
  base64PdfFile: string,
  type: string,
  sessionId: string
) => {
  if (isWeb) {
    const binary_string = window.atob(base64PdfFile);
    const bytes = new Uint8Array(base64PdfFile.length);
    for (let i = 0; i < base64PdfFile.length; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    const blob = new Blob([bytes.buffer], { type });
    const url = URL.createObjectURL(blob);
    return url;
  } else {
    const { dirs } = ReactNativeBlobUtil.fs;
    const dir = isIos ? dirs.CacheDir : dirs.DocumentDir;
    const path = `${dir}/${sessionId}.pdf`;
    return path;
  }
};

export const openPDF = (
  pdfUrl: string,
  base64PdfFile: string,
  type: string,
  errorCallback: (error: any) => void
) => {
  if (isWeb) {
    window.open(pdfUrl);
  } else {
    ReactNativeBlobUtil.fs
      .writeFile(pdfUrl, base64PdfFile, 'base64')
      .then(() => {
        if (isAndroid) {
          ReactNativeBlobUtil.android.actionViewIntent(pdfUrl, type);
        } else if (isIos) {
          ReactNativeBlobUtil.ios.openDocument(pdfUrl);
        }
      })
      .catch((error) => {
        errorCallback?.(error);
      });
  }
};

type ThrottledFunction<T> = (...args: any[]) => T;

/**
 * Throttle logic that delays the execution of a function based on a given ID.
 * @param id The ID used to identify the throttle logic.
 * @param func The function to throttle.
 * @param delay The throttle period in milliseconds.
 * @returns A throttled version of the function.
 */
export const throttleWithId = <T>(
  id: string,
  func: ThrottledFunction<T>,
  delay: number
) => {
  return (...args: any[]) => {
    const timers = throttleTimers();
    if (!timers[id]) {
      // If there's no throttle timeout set, execute the function immediately
      const result = func.apply(this, args);
      // Set a new throttle timeout
      const timerId = setTimeout(() => {
        const timersToUpdate = throttleTimers();
        // Reset the throttle timeout
        timersToUpdate[id] = undefined;
        throttleTimers(timersToUpdate);
      }, delay);
      timers[id] = timerId;
      throttleTimers(timers);
      return result;
    }
  };
};

export const vehicleLicensePlateCountryCode = (vehicle: Vehicle) => {
  const combinedLicensePlateCountryCode = (
    licensePlate: string,
    countryCode?: Maybe<VehicleCountryCode>
  ) => `${countryCode || ''}:${licensePlate}`;

  return combinedLicensePlateCountryCode(
    vehicle.licensePlate.plateText,
    vehicle.licensePlate.countryCode
  );
};

export const getCompanyBenefitsUids = (
  companyBenefitsValue: CompanyBenefit[]
) => {
  return !Array.isArray(companyBenefitsValue)
    ? []
    : (companyBenefitsValue
        ?.map((companyBenefit) => companyBenefit?.uid)
        ?.filter((uid) => !!uid) as string[]);
};
