import { Headline3 } from 'aimo-ui';
import React from 'react';
import { Trans } from 'react-i18next';

const SetCustomEndTimeHeader: React.FC = () => {
  return (
    <Headline3 center testID="time-selection-header">
      <Trans i18nKey={'parking.setEndTime.title'} />
    </Headline3>
  );
};

export default SetCustomEndTimeHeader;
