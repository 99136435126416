import { Configuration } from '@azure/msal-browser';
import config from 'config/config.web';
const {
  REACT_APP_AZURE_DOMAIN,
  REACT_APP_SIGNIN_FLOW,
  REACT_APP_SIGNUP_SIGNIN_FLOW,
  REACT_APP_SUSI_FLOW,
  REACT_APP_SUSI_TEST_FLOW,
  REACT_APP_AZURE_CLIENT_ID,
  REACT_APP_REDIRECT_URI_LOGOUT,
} = config;

export const acquireTokenScopes = [REACT_APP_AZURE_CLIENT_ID || ''];

export const loginRequestWeb = {
  scopes: [
    'profile',
    'email',
    'offline_access',
    REACT_APP_AZURE_CLIENT_ID || '',
  ],
  popupWindowAttributes: {
    popupSize: {
      height: 700,
      width: 550,
    },
  },
  prompt: 'login',
  redirectStartPage: undefined,
};

export const msalConfigSignIn: Configuration = {
  auth: {
    clientId: REACT_APP_AZURE_CLIENT_ID || '',
    authority: `${REACT_APP_AZURE_DOMAIN}/${REACT_APP_SIGNIN_FLOW}`,
    knownAuthorities: [
      REACT_APP_AZURE_DOMAIN || '',
      REACT_APP_SIGNIN_FLOW || '',
    ],
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: REACT_APP_REDIRECT_URI_LOGOUT,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const msalConfigSignUp: Configuration = {
  auth: {
    clientId: REACT_APP_AZURE_CLIENT_ID || '',
    authority: `${REACT_APP_AZURE_DOMAIN}/${REACT_APP_SIGNUP_SIGNIN_FLOW}`,
    knownAuthorities: [
      REACT_APP_AZURE_DOMAIN || '',
      REACT_APP_SIGNUP_SIGNIN_FLOW || '',
    ],
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: REACT_APP_REDIRECT_URI_LOGOUT,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const msalConfigSuSi: Configuration = {
  ...msalConfigSignUp,
  auth: {
    ...msalConfigSignUp.auth,
    authority: `${REACT_APP_AZURE_DOMAIN}/${REACT_APP_SUSI_FLOW}`,
    knownAuthorities: [REACT_APP_AZURE_DOMAIN || '', REACT_APP_SUSI_FLOW || ''],
  },
};

export const msalConfigSuSiTest: Configuration = {
  ...msalConfigSignUp,
  auth: {
    ...msalConfigSignUp.auth,
    authority: `${REACT_APP_AZURE_DOMAIN}/${REACT_APP_SUSI_TEST_FLOW}`,
    knownAuthorities: [
      REACT_APP_AZURE_DOMAIN || '',
      REACT_APP_SUSI_TEST_FLOW || '',
    ],
  },
};
