import { theme } from 'aimo-ui';
import styled from 'styled-components/native';
import { IndicatorType } from 'types/IconTypes';

export const IndicatorShape = styled.View<{
  type: IndicatorType;
}>`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 15px;
  height: 15px;
  border-radius: 8px;
  background-color: ${({ type }: { type: IndicatorType }) =>
    type === 'warning' ? theme.colors.boost.yellow : theme.colors.boost.red};
  align-items: center;
`;

export const IndicatorNumber = styled.Text<{
  type: IndicatorType;
}>`
  font-size: 10px;
  font-weight: 700;
  color: ${({ type }: { type: IndicatorType }) =>
    type === 'warning' ? theme.colors.black : theme.colors.white};
`;
