import { useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Button, Headline3, Spinner } from 'aimo-ui';
import ParkingDetails from 'components/account/parking/ParkingDetails';
import PaymentDetails from 'components/account/parking/PaymentDetails';
import AimoMarker from 'components/map/AimoMarker';
import MapComponent from 'components/map/MapComponent';
import { Center } from 'components/park/styles/styles';
import ParkingBadge from 'components/receipt/ParkingBadge';
import Sheet from 'components/sheets/Sheet';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { GET_PARK_ZONE } from 'graphql/queries/getParkZone';
import { READ_RECEIPT_BY_TRANSACTION_ID } from 'graphql/queries/readReceiptByTransactionId';
import useCache from 'hooks/useCache';
import useIsAccountRestricted from 'hooks/useIsAccountRestricted';
import useReadChargingSession from 'hooks/useReadChargingSession';
import SafeAreaHeaderContainer from 'navigation/SafeAreaHeaderContainer';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { mapCoordinates } from 'states/map';
import { Divider } from 'styles/CommonLayoutStyles';
import {
  ButtonContainer,
  ColumnWithMargins,
  ScreenContainer,
} from 'styles/ContainerStyles';
import {
  BillingItem,
  ChargingPaymentState,
  ChargingSession,
  ParkZone,
  Query,
  QueryParkZoneArgs,
  QueryReadReceiptByTransactionIdArgs,
  Receipt,
} from 'types/generatedSchemaTypes';
import { ParkingDetailsNavigationProp, RootNavProps } from 'types/navigation';
import { evictFromCache } from 'utils/cacheUtils';
import { getCorrectBillingItem } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { makePoint } from 'utils/mapUtils';
import { getLocationIconType } from 'utils/parkUtils';
import RetryChargingPaymentButton from './RetryChargingPaymentButton';

const { height } = Dimensions.get('window');
const ChargingHistoryDetailsScreen: React.FC<{
  showBeforeMapLoaded?: boolean;
}> = ({ showBeforeMapLoaded = false }) => {
  const { t } = useTranslation();
  const {
    flags: { anpr },
  } = useContext(FeatureFlagsContext);
  const showCameraRecognition = showFeatureFlag(anpr);
  const { goBack } = useNavigation<RootNavProps>();
  const { apolloCache } = useCache();
  const { refetchFailedSessions } = useIsAccountRestricted();
  const { params } = useRoute<ParkingDetailsNavigationProp['route']>();
  const {
    chargingSessionId,
    parkingZoneUid,
    transactionId,
    closeButton = false,
  } = params;

  const { chargingSessionData, chargingSessionLoading, chargingSessionError } =
    useReadChargingSession(chargingSessionId, false, !!transactionId);

  const transactionIdentifier = (transactionId ||
    chargingSessionData?.readChargingSession?.transactionId) as string;

  const chargingSessionPayment = transactionIdentifier
    ? undefined
    : chargingSessionData?.readChargingSession;

  const {
    data: chargingReceipt,
    loading: chargingReceiptLoading,
    error: chargingReceiptError,
  } = useQuery<Query, QueryReadReceiptByTransactionIdArgs>(
    READ_RECEIPT_BY_TRANSACTION_ID,
    {
      onCompleted: () => {
        if (chargingSessionId) {
          evictFromCache(apolloCache, {
            __typename: 'ChargingSession',
            chargingSessionId,
          });
          refetchFailedSessions();
        }
      },
      skip: !transactionIdentifier,
      variables: {
        transactionId: transactionIdentifier,
      },
    }
  );

  const billingItem = getCorrectBillingItem(
    chargingReceipt?.readReceiptByTransactionId?.items as
      | [BillingItem]
      | undefined
  )?.[0];

  const receipt = chargingReceipt?.readReceiptByTransactionId;

  const chargingPaymentStatus =
    chargingSessionPayment?.paymentState === 'Failed' ||
    chargingSessionPayment?.paymentState === 'Retrying';

  const isPaymentFailed = transactionIdentifier
    ? !receipt
    : chargingPaymentStatus;

  const licensePlate =
    billingItem?.licensePlate || chargingSessionPayment?.licensePlate || '';
  const parkZone = parkingZoneUid || billingItem?.zoneUid || '';

  const {
    data: parkZoneData,
    loading: parkZoneLoading,
    error: parkZoneError,
  } = useQuery<Query, QueryParkZoneArgs>(GET_PARK_ZONE, {
    variables: {
      uid: parkZone,
    },
    skip: !parkZone,
    errorPolicy: 'ignore',
  });

  const loading =
    chargingReceiptLoading || parkZoneLoading || chargingSessionLoading;
  const error = chargingReceiptError || parkZoneError || chargingSessionError;

  const { address, locationType, parkingMethods } =
    parkZoneData?.parkZone || {};

  useEffect(() => {
    let timeoutHandler: any;
    if (address?.marker?.coordinates) {
      const coordinates = makePoint(address?.marker?.coordinates);
      timeoutHandler = setTimeout(() => mapCoordinates(coordinates), 500);
    }
    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [address?.marker?.coordinates]);

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }
  if (error) {
    return <></>;
  }

  const markerType = getLocationIconType(
    locationType,
    showCameraRecognition && parkingMethods?.anpr,
    true
  );
  const Markers = address?.marker?.coordinates ? (
    <AimoMarker
      key={`parking-spot-${parkingZoneUid}`}
      coordinate={makePoint(address?.marker?.coordinates)}
      markerType={markerType}
      activeIndex={1}
      onPress={() => undefined}
      testID={`${markerType}-marker-${parkingZoneUid}`}
      id={parkingZoneUid}
      isSelected={true}
    />
  ) : undefined;

  const StickyFooter = () => (
    <ButtonContainer>
      {isPaymentFailed ? (
        <RetryChargingPaymentButton
          token={chargingSessionPayment?.token ?? ''}
          chargingSessionId={chargingSessionId}
          loading={chargingReceiptLoading || chargingSessionLoading}
          paymentState={
            chargingSessionPayment?.paymentState as ChargingPaymentState
          }
        />
      ) : (
        <Button
          text={t('button.close')}
          size="l"
          testID="close"
          onPress={() => goBack()}
        />
      )}
    </ButtonContainer>
  );

  return (
    <>
      {isWeb && (
        <ScreenContainer>
          <MapComponent
            showBeforeMapLoaded={showBeforeMapLoaded}
            showLocation={false}
            showLogo={false}
            mapPadding={{ top: 0, left: 0, right: 0, bottom: height * 0.62 }}
            scrollEnabled={false}>
            {Markers}
          </MapComponent>
          <ParkingBadge
            licensePlate={licensePlate}
            testID="charging-history-details-info"
          />
        </ScreenContainer>
      )}

      <Sheet
        snappingPoints={[isWeb ? '80vh' : '100%']}
        hideHandle
        stickyFooter={<StickyFooter />}>
        <>
          <SafeAreaHeaderContainer extraPadding="all">
            <Headline3 center>{t('account.chargingDetails')}</Headline3>
          </SafeAreaHeaderContainer>
          {!isWeb && (
            <ColumnWithMargins mt={20} mb={30}>
              <ParkingBadge
                licensePlate={licensePlate}
                testID="parking-history-details-info"
                noMap
              />
            </ColumnWithMargins>
          )}
          <ScrollView>
            <ColumnWithMargins ml={20} mr={20}>
              {parkZoneData?.parkZone && (
                <ParkingDetails
                  testID="charging-summary"
                  receiptData={
                    chargingReceipt?.readReceiptByTransactionId as Receipt
                  }
                  chargingSession={chargingSessionPayment as ChargingSession}
                  parkZone={parkZoneData?.parkZone as ParkZone}
                />
              )}
            </ColumnWithMargins>
            <Divider size={10} marginVertical={30} />
            <ColumnWithMargins ml={20} mr={20} mb={closeButton ? 85 : 165}>
              <PaymentDetails
                testID="payment-summary"
                isPaymentFailed={isPaymentFailed}
                receiptData={
                  chargingReceipt?.readReceiptByTransactionId as Receipt
                }
                chargingSession={chargingSessionPayment as ChargingSession}
                transactionId={transactionId}
              />
            </ColumnWithMargins>
          </ScrollView>
        </>
      </Sheet>
    </>
  );
};

export default ChargingHistoryDetailsScreen;
