import { compareDesc, isAfter, isBefore } from 'date-fns';
import { ParkingTypeFilter } from 'hooks/useParkingHistoryFilter';
import i18n from 'i18next';
import {
  favorites,
  recentParkingsWithSegment,
  recentZonesOrChargers,
} from 'states/persistInStorage';
import { PoolingType } from 'types/common';
import {
  ChargingSession,
  CompanyBenefit,
  CountryCode,
  LocationType,
  Maybe,
  ParkingSession,
  ParkZone,
  ParkZoneNotes,
  PmcVisibility,
  PmcVisibilityProps,
  PoolingGroup,
  PoolParking,
  Query,
  QueryReadParkingSessionsArgs,
  QueryReadReceiptsByTypeArgs,
  Receipt,
  ReceiptItemType,
  UnifiedPermit,
} from 'types/generatedSchemaTypes';
import { getItemFromStorage, updateStateAndStorage } from './MMKVStorageUtils';
import { getLang } from './commonUtils';
import { QuickAccessType } from 'navigation/ParkScreenQuickAccess';
import { LatLng } from 'types/MapTypes';
import { getDistanceFromLatLonInMeters, makePoint } from './mapUtils';
import { ParkingTariffAndZone } from 'types/states';
import { Dictionary, groupBy } from 'lodash';
import {
  ParkZoneMemberGroup,
  ParkZoneWithVisibility,
} from 'components/park/ParkingZoneSearch';

export const removeFavorite = (uid: string) => {
  const favoritesString = getItemFromStorage('favorites') || '';
  const stringToArray = favoritesString?.split(',');
  const index = stringToArray.indexOf(uid);
  if (index > -1) {
    stringToArray.splice(index, 1);
  }
  const newString = stringToArray.join();
  updateStateAndStorage(favorites, 'favorites', newString);
};

export const addFavorite = (uid: string) => {
  const favoritesString = getItemFromStorage('favorites') || '';
  const stringToArray = favoritesString?.split(',');
  const index = stringToArray.indexOf(uid);
  // push only if it's not already there
  if (index === -1) {
    stringToArray.push(uid);
    const newString = stringToArray.join();
    updateStateAndStorage(favorites, 'favorites', newString);
  }
};

const MAX_RECENT_ZONES_OR_CHARGERS = 5;

export const addToRecentZonesOrChargers = (parkZone: ParkZone) => {
  const recentZonesOrChargersStored =
    getItemFromStorage('recentZonesOrChargers') || [];
  // Add to first item and remove duplicates or excessive items
  const newArray = [
    parkZone,
    ...recentZonesOrChargersStored.filter(
      (o: ParkZone) => o && o.uid !== parkZone.uid
    ),
  ];
  newArray.length = Math.min(newArray.length, MAX_RECENT_ZONES_OR_CHARGERS);
  recentZonesOrChargers(newArray);
};

export const removeFromRecentZonesOrChargers = (parkZone: ParkZone) => {
  const recentZonesOrChargersStored: ParkZone[] =
    getItemFromStorage('recentZonesOrChargers') || [];
  const newArray = recentZonesOrChargersStored.filter(
    (o) => o.uid !== parkZone.uid
  );
  recentZonesOrChargers(newArray);
};

export const getParkingZoneType = (locationType: LocationType) => {
  if (locationType) {
    return i18n.t(`parkingZone.locationType.${locationType}`);
  }
  return '';
};

// get location icon name
export const locationIcon: { [key: string]: string } = {
  garage: 'garage',
  default: 'Parking',
  anpr: 'CCTV-Outlined',
  evCharging: 'Charging',
  free: 'Free',
  skistar: 'Skistar',
  boat: 'Boat',
};

// return icon type based on parking zone location type and anpr
export const getLocationIconType = (
  locationType: LocationType | undefined | null,
  isANPR: Maybe<boolean> | undefined,
  isCharging: Maybe<boolean> | undefined = false
) => {
  if (isCharging) {
    return 'evCharging';
  }
  if (isANPR) {
    return 'anpr';
  }
  switch (locationType) {
    case 'HALL':
      return 'garage';
    default:
      return 'default';
  }
};

// return amount of available spots in organisational parking
export const getPoolingSpotAmount = (
  total: Maybe<number> | undefined,
  reserved: Maybe<number> | undefined
) => {
  if (total === null) {
    return 'unlimited';
  }
  return typeof total === 'number' && typeof reserved === 'number'
    ? total - reserved
    : 0;
};

// define type of pricing in pool
const defineInsidePool = (pool: PoolParking) => {
  switch (pool) {
    case 'FORBIDDEN':
      return 'forbidden';
    case 'USER_PAYS':
      return 'discount';
    default:
      return 'free';
  }
};

// define type of pricing in outside pool when pooling is full or not allowed
const defineOutsidePool = (pool: PoolParking | null | undefined) => {
  switch (pool) {
    case null:
    case undefined:
    case 'FORBIDDEN':
      return 'forbidden';
    case 'FREE':
      return 'free';
    case 'USER_PAYS':
      return 'normal';
    case 'COMPANY_PAYS':
      return 'free';
    default:
      return 'normal';
  }
};

const defaultPricings: PoolingType = {
  paymentType: 'normal',
  poolingFull: false,
  disabled: false,
};

// define pooling types so that correct pricing can be calculated and shown
export const getPaymentTypeFromPooling = (
  pooling?: PoolingGroup
): PoolingType => {
  if (!pooling) {
    return defaultPricings;
  }
  const { poolSize, level, insidePool, outsidePool } = pooling;
  const available = getPoolingSpotAmount(poolSize, level);
  if (
    insidePool !== 'FORBIDDEN' &&
    (available === 'unlimited' || available > 0)
  ) {
    return {
      paymentType: defineInsidePool(insidePool),
      disabled: false,
      poolingFull: false,
    };
  }

  return {
    paymentType: defineOutsidePool(outsidePool || 'USER_PAYS'),
    disabled: outsidePool === 'FORBIDDEN',
    poolingFull: available >= 0,
  };
};

export const receiptQuerySort = (receipt: Receipt[]) =>
  receipt
    .slice() // Because aTeemPTo ASsigN rEadoNLy vALue!!!!
    .sort((a, b) =>
      compareDesc(
        new Date(a?.items?.[0]?.startTime || a.created || ''),
        new Date(b?.items?.[0]?.startTime || b.created || '')
      )
    );

export const updateReceiptQuery = (
  prev: Query,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: Query;
    variables?: QueryReadReceiptsByTypeArgs;
  }
) => {
  if (!fetchMoreResult) {
    return prev;
  }
  const newObject: Query = {
    ...fetchMoreResult,
  };

  if (newObject?.readReceiptsByType?.content) {
    const oldItems = prev?.readReceiptsByType?.content || [];
    const newItems = fetchMoreResult?.readReceiptsByType?.content || [];
    newObject.readReceiptsByType.content = [...oldItems, ...newItems];
  }
  return newObject;
};

export const parkingTypes: ReceiptItemType[] = [
  'PARKING',
  'START_STOP',
  'ANPR',
];
export const chargingTypes: ReceiptItemType[] = [
  'EV_CHARGING_V2',
  'EV_CHARGING_ENERGY',
  'EV_CHARGING_TIME',
];
export const permitTypes: ReceiptItemType[] = [
  'PERMIT',
  'LONGTERM_PERMIT',
  'SALES_ORDER',
];
export const prepaidTypes: ReceiptItemType[] = [
  'MONTHLY',
  'THIRTY_DAY',
  'PREPAID',
];

export const sortFilter = 'created,desc';

export const filterReceiptData = (
  receipts: Receipt[],
  filter?: ParkingTypeFilter
) => {
  if (receipts) {
    return receipts.filter((receipt) => {
      const type = (receipt?.items?.[0]?.type || '') as ReceiptItemType;

      switch (filter) {
        case 'parking':
          return parkingTypes.includes(type);
        case 'charging':
          return chargingTypes.includes(type);
        case 'permits':
          return permitTypes.includes(type);
        default:
          return true;
      }
    });
  }
};

export const filterFailedOrFreeSessions = (
  sessions?: ParkingSession[] & ChargingSession[],
  filter?: ParkingTypeFilter
) => {
  if (sessions) {
    return sessions.filter((session) => {
      const sessionType = session.__typename;

      if (filter === 'parking') {
        return sessionType === 'ParkingSession';
      } else if (filter === 'charging') {
        return sessionType !== 'ParkingSession';
      } else {
        return true;
      }
    });
  }
};

export const updateParkingQuery = (
  prev: Query,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: Query;
    variables?: QueryReadParkingSessionsArgs;
  }
) => {
  if (!fetchMoreResult) {
    return prev;
  }
  const newObject: Query = {
    ...fetchMoreResult,
  };

  if (newObject?.readParkingSessions?.content) {
    const oldItems = prev?.readParkingSessions?.content || [];
    const newItems = fetchMoreResult?.readParkingSessions?.content || [];
    newObject.readParkingSessions.content = [...oldItems, ...newItems];
  }
  return newObject;
};

export const updateParkZonesPaginationQuery = (
  prev: Query,
  fetchMoreResult?: Query
) => {
  if (!fetchMoreResult || !fetchMoreResult?.parkZones?.content) {
    return prev;
  }
  const tempMergedParkZones = { ...prev };
  if (tempMergedParkZones?.parkZones?.content) {
    tempMergedParkZones.parkZones.content =
      tempMergedParkZones.parkZones.content.concat(
        fetchMoreResult?.parkZones?.content
      );
  }
  return tempMergedParkZones;
};

export const getCustomerSegmentUid = (selectedCustomerSegment?: string) => {
  return selectedCustomerSegment !== 'Standard'
    ? selectedCustomerSegment
    : undefined;
};

export const isValidNote = (
  { priority, startDate, endDate, text, visibility }: ParkZoneNotes,
  ...priorities: number[]
) => {
  return (
    priorities.includes(priority) &&
    isAfter(new Date(), new Date(startDate)) &&
    (!endDate || isBefore(new Date(), new Date(endDate))) &&
    text[getLang()] &&
    visibility === 'AIMO_APP'
  );
};

export const quickMenuFilter = (
  parkZone: Maybe<ParkZone> | undefined,
  selectedQuickMenuItem: QuickAccessType | undefined
) => {
  if (selectedQuickMenuItem === 'CHARGING') {
    return parkZone?.parkingMethods?.evCharging;
  }
  if (selectedQuickMenuItem === 'PARKING') {
    return !parkZone?.parkingMethods?.unmanagedParking;
  }
  return true;
};

const distanceCompare = (
  a: Maybe<ParkZone> | undefined,
  b: Maybe<ParkZone> | undefined,
  currentLocation: LatLng | null
) => {
  const coordinatesA = a?.address?.marker?.coordinates;
  const coordinatesB = b?.address?.marker?.coordinates;

  if (currentLocation && coordinatesA && coordinatesB) {
    const distanceA = getDistanceFromLatLonInMeters(
      currentLocation,
      makePoint(coordinatesA)
    );
    const distanceB = getDistanceFromLatLonInMeters(
      currentLocation,
      makePoint(coordinatesB)
    );
    return distanceA - distanceB;
  }
  return 0;
};

export const quickMenuSort = (
  a: Maybe<ParkZone> | undefined,
  b: Maybe<ParkZone> | undefined,
  selectedQuickMenuItem: QuickAccessType | undefined,
  currentLocation: LatLng | null,
  withLTP?: boolean
) => {
  const fieldCompare = (fieldA: boolean, fieldB: boolean) => {
    if (fieldA === fieldB) {
      return distanceCompare(a, b, currentLocation);
    } else if (fieldA === true) {
      return -1;
    }
    return 1;
  };

  if (selectedQuickMenuItem === 'CHARGING' && !withLTP) {
    return fieldCompare(
      a?.parkingMethods?.evCharging ?? false,
      b?.parkingMethods?.evCharging ?? false
    );
  } else if (selectedQuickMenuItem === 'PARKING' && !withLTP) {
    return fieldCompare(
      !a?.parkingMethods?.unmanagedParking,
      !b?.parkingMethods?.unmanagedParking
    );
  }
  return distanceCompare(a, b, currentLocation);
};
export const addRecentParkingWithSegment = (
  parkingWithSegment: ParkingTariffAndZone
) => {
  const recentParkingsWithSegmentArray =
    getItemFromStorage('recentParkingsWithSegment') || [];
  // Find if user has a parking made with a customer segment
  const foundIndex = recentParkingsWithSegmentArray.findIndex(
    (item: ParkingTariffAndZone) => item.zoneUid === parkingWithSegment.zoneUid
  );
  if (foundIndex !== -1) {
    // If a zoneCode is found, update that object in the array and push the new array to the storage
    recentParkingsWithSegmentArray[foundIndex].customerSegmentUid =
      parkingWithSegment.customerSegmentUid;
    recentParkingsWithSegmentArray[foundIndex].customerSegmentName =
      parkingWithSegment.customerSegmentName;
    recentParkingsWithSegment(recentParkingsWithSegmentArray);
    return;
  }
  // zone code not found, add the new parking to the array
  const newArray = [...recentParkingsWithSegmentArray, parkingWithSegment];
  recentParkingsWithSegment(newArray);
};

export const getRecentParkingWithSegment = (zoneUid: string) => {
  const recentParkingsWithSegmentArray =
    getItemFromStorage('recentParkingsWithSegment') || [];
  return recentParkingsWithSegmentArray.find(
    (parking: ParkingTariffAndZone) => parking.zoneUid === zoneUid
  );
};

/**
 * Retrieves an array of park zones with their visibility information based on household benefits and payment method.
 *
 * @param {ParkZone[]} parkZones - An array of park zones.
 * @param {PmcVisibilityProps[]} visibilities - An array of visibility properties.
 * @param {CompanyBenefit[]} householdBenefits - An array of household benefits.
 * @returns {ParkZoneWithVisibility[]} An array of park zones with their visibility information.
 */
export const getZonesWithVisibility = (
  parkZones: ParkZone[],
  visibilities: Maybe<PmcVisibilityProps[]> | undefined,
  householdBenefits: Maybe<CompanyBenefit[]> | undefined,
  currentLocation: LatLng | null,
  currentAppCountry: Maybe<CountryCode>,
  skipSorting = false
) => {
  const groupByUid = groupBy(visibilities ?? [], 'parkingZoneUid');

  const filteredZones = parkZones
    ?.filter((parkZone) =>
      filterParkzonesByVisibilityLTP(parkZone, householdBenefits, groupByUid)
    )
    .map((parkZone) => {
      return {
        ...parkZone,
        ...addMemberGroup(groupByUid[parkZone?.uid ?? ''], householdBenefits),
      } as ParkZoneWithVisibility;
    });

  if (skipSorting) {
    return filteredZones;
  }

  return sortZonesByHouseholdPermits(
    filteredZones,
    groupByUid,
    householdBenefits,
    currentLocation,
    currentAppCountry
  );
};

const filterParkzonesByVisibilityLTP = (
  parkZone: ParkZone | undefined,
  householdbenefits: Maybe<CompanyBenefit[]> | undefined,
  groupByUid: Dictionary<PmcVisibilityProps[]>
) => {
  const zoneVisibility = parkZone?.visibility as PmcVisibility;
  const parkZonePmcVisibilities = groupByUid[parkZone?.uid ?? ''];
  return (
    isPublicParkzone(zoneVisibility) ||
    isPrivateZone(zoneVisibility, parkZonePmcVisibilities, householdbenefits)
  );
};

export const parkMapParkzoneFilter = (
  parkZones: ParkZone[],
  householdBenefits: Maybe<CompanyBenefit[]> | undefined,
  permits?: Maybe<UnifiedPermit[]> | undefined
) => {
  return parkZones?.filter((parkZone) =>
    filterParkzonesByVisibilityAndBenefit(parkZone, householdBenefits, permits)
  );
};

const filterParkzonesByVisibilityAndBenefit = (
  parkZone: ParkZone | undefined,
  benefits: Maybe<CompanyBenefit[]> | undefined,
  permits?: Maybe<UnifiedPermit[]> | undefined
) => {
  const zoneVisibility = parkZone?.visibility as PmcVisibility;
  const parkzoneUid = parkZone?.uid as string;
  return (
    isPublicParkzone(zoneVisibility) ||
    (zoneVisibility === 'PRIVATE' &&
      hasBenefitForZone(benefits, parkzoneUid)) ||
    hasPermitForZone(permits, parkzoneUid)
  );
};

export const hasBenefitForZone = (
  benefits: Maybe<CompanyBenefit[]> | undefined,
  parkZoneUid: string
) => {
  return benefits?.some(
    (benefit) => benefit?.pmc?.physicalZoneUid === parkZoneUid
  );
};

const hasPermitForZone = (
  permits: Maybe<UnifiedPermit[]> | undefined,
  parkZoneUid: string
) => {
  return permits?.some(
    (unifiedPermit: UnifiedPermit) =>
      unifiedPermit?.parkingZoneUid === parkZoneUid
  );
};

export const sortInMainMapViewByZoneAndBenefit = (
  a: Maybe<ParkZone> | undefined,
  b: Maybe<ParkZone> | undefined,
  benefits: Maybe<CompanyBenefit[]> | undefined
) => {
  const zoneUidA = a?.uid as string;
  const zoneUidB = b?.uid as string;

  const hasBenefitForA = hasBenefitForZone(benefits, zoneUidA);
  const hasBenefitForB = hasBenefitForZone(benefits, zoneUidB);

  if (hasBenefitForA && !hasBenefitForB) {
    return -1;
  }
  if (!hasBenefitForA && hasBenefitForB) {
    return 1;
  }
  return 0;
};

/*
  HELPER FUNCTIONS USED IN ABOVE FILTERING OF PARK ZONES
*/

// Check if zone is public. If visibility is NULL, treat it as public
const isPublicParkzone = (parkZoneVisibility: PmcVisibility | null) => {
  return parkZoneVisibility === 'PUBLIC' || parkZoneVisibility === null;
};

// Check if user has a benefit that matches one of the zones PMCs
const hasHouseholdBenefitForZoneLTP = (
  parkZonePmcs: PmcVisibilityProps[],
  userHouseholdbenefits: Maybe<CompanyBenefit[]> | undefined
) => {
  return parkZonePmcs?.some((pmc) => {
    return userHouseholdbenefits?.some(
      (benefit) => benefit.benefitTargetUid === pmc.pmcId
    );
  });
};

/*
Check if the zone is private. If it is, then require user to have a benefit for one of the private PMCs
OR that zone has to have atleast 1 public PMC to be visible in list, search & map
*/
const isPrivateZone = (
  parkZoneVisibility: PmcVisibility | null,
  parkZonePmcs: PmcVisibilityProps[],
  userHouseholdbenefits: Maybe<CompanyBenefit[]> | undefined
) => {
  return (
    parkZoneVisibility === 'PRIVATE' &&
    (hasHouseholdBenefitForZoneLTP(parkZonePmcs, userHouseholdbenefits) ||
      parkZonePmcs?.some((pmc) => pmc.visibility === 'PUBLIC'))
  );
};

// IF user has the membership to certain PMC add the benefits name to the search list item
const addMemberGroup = (
  pmcs: PmcVisibilityProps[],
  houseHoldbenefits: Maybe<CompanyBenefit[]> | undefined
) => {
  return pmcs?.reduce((acc, obj) => {
    const householdBenefit = houseHoldbenefits?.find(
      (benefit) => benefit.benefitTargetUid === obj.pmcId
    );
    if (householdBenefit && !acc.memberGroupName) {
      acc = {
        memberGroupName: householdBenefit.name,
      };
    }
    return acc;
  }, {} as ParkZoneMemberGroup);
};

const sortZonesByHouseholdPermits = (
  parkZones: ParkZone[],
  groupByUid: Dictionary<PmcVisibilityProps[]>,
  householdBenefits: Maybe<CompanyBenefit[]> | undefined,
  currentLocation: LatLng | null,
  currentAppCountry: Maybe<CountryCode>
) => {
  return [...parkZones].sort((a, b) => {
    const parkZonePmcVisibilitiesA = groupByUid[a?.uid ?? ''];
    const parkZonePmcVisibilitiesB = groupByUid[b?.uid ?? ''];
    const hasHhBenefitA = hasHouseholdBenefitForZoneLTP(
      parkZonePmcVisibilitiesA,
      householdBenefits
    );
    const hasHhBenefitB = hasHouseholdBenefitForZoneLTP(
      parkZonePmcVisibilitiesB,
      householdBenefits
    );

    const isSameCountryA = a?.countryCode === currentAppCountry;
    const isSameCountryB = b?.countryCode === currentAppCountry;

    if (hasHhBenefitA && isSameCountryA) {
      return -1;
    }
    if (hasHhBenefitB && isSameCountryB) {
      return 1;
    }

    return distanceCompare(a, b, currentLocation);
  });
};
