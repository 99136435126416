import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Button } from 'aimo-ui';
import ParkingSignInButton from 'components/park/ParkingSignInButton';
import {
  FooterButtonContainer,
  VerticalDistributedContainer,
} from 'components/sheets/SheetLayout';
import AccountContext from 'context/AccountContext';
import ParkingContext from 'context/ParkingContext';
import useAddPaymentMethod from 'hooks/useAddPaymentMethod';
import useGetMatchingAccessPermitForParkingZone from 'hooks/useGetMatchingAccessPermitForParkingZone';
import useIsDesktop from 'hooks/useIsDesktop';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useResumableParkingSession from 'hooks/useResumableParkingSession';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { selectedOngoingSession, sheetModal } from 'states/common';
import { PaymentDataProps } from 'types/PaymentData';
import { RootNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import PoolingFullInfo from '../pooling/PoolingFullInfo';
import PaymentCardDropDown from './PaymentCardDropDown';
import VehicleSelectDropDown from './VehicleSelectDropDown';

const paymentData: PaymentDataProps[] = [
  {
    layout: 'button',
    title: 'parking.anpr.addPaymentMethod',
    icon: '',
    action: 'addCardModal',
  },
];

const EnableAnprButton = () => {
  const { navigate } = useNavigation<RootNavProps>();
  const { t } = useTranslation();
  const selectedVehicle = useSelectedParkingVehicle();
  const { isLoggedIn, loginLoading } = useIsLoggedIn();
  const { cameraRecognition } = selectedVehicle || {};
  const anprText = cameraRecognition?.enabled
    ? 'parking.startsAutomatically'
    : 'parking.allowAnpr';
  const ongoingSessionType = useReactiveVar(selectedOngoingSession);
  const {
    parkingConditions: { parkingZoneUid, paymentMethod },
  } = useContext(ParkingContext) || {};
  const {
    customer: { vehicles },
  } = useContext(AccountContext);
  useResumableParkingSession();
  const { companyPays } =
    useGetMatchingAccessPermitForParkingZone(parkingZoneUid);
  const { addCard, loadingAddCard } = useAddPaymentMethod();

  const hasVehicles = !!(vehicles && vehicles.length > 0);

  const noPaymentMethodAndCompanyWontPay = !paymentMethod && !companyPays;

  const isDesktop = useIsDesktop();
  const handleOpenAnprModal = () => {
    navigate('vehicleCameraRecognitionParking', { saveOnModalConfirm: true });
  };

  const renderContainerPadding = () => {
    if (ongoingSessionType !== '') {
      if (isWeb && !isDesktop) {
        return 40;
      }
      return 80;
    }
    return 0;
  };

  if (!isLoggedIn) {
    return (
      <ParkingSignInButton
        testID="park-zone-details-anpr-login"
        loading={loginLoading}
      />
    );
  }

  const onPress = () => {
    if (noPaymentMethodAndCompanyWontPay && !hasVehicles) {
      // Redirect to onboarding flow
      navigate('onboardingModal', {
        screen: 'welcome',
      });
    } else if (noPaymentMethodAndCompanyWontPay) {
      // Redirect to add payment method screen
      addCard();
    } else if (!selectedVehicle && !hasVehicles) {
      // Redirect to add vehicle screen
      navigate('addVehicleParking');
    } else if (!selectedVehicle && hasVehicles) {
      sheetModal('parkingVehicle');
    } else {
      handleOpenAnprModal();
    }
  };

  const buttonText = () => {
    if (noPaymentMethodAndCompanyWontPay) {
      return t('onboarding.addPaymentMethod');
    }
    if (!selectedVehicle && !hasVehicles) {
      return t('onboarding.addVehicle');
    }
    if (!selectedVehicle && hasVehicles) {
      return t('parking.parkingProcess.noVehicleSelected');
    }
    return t(anprText);
  };

  return (
    <FooterButtonContainer pb={0}>
      <VerticalDistributedContainer>
        <PoolingFullInfo parkingZoneUid={parkingZoneUid} />
        <FooterButtonContainer pt={0} pb={renderContainerPadding()} prPl={10}>
          <VehicleSelectDropDown />
          <PaymentCardDropDown isAnpr parkingZoneUid={parkingZoneUid} />
        </FooterButtonContainer>
        <FooterButtonContainer>
          <Button
            size="l"
            text={buttonText()}
            testID="park-zone-details-allow-anpr"
            onPress={onPress}
            disabled={
              !!cameraRecognition?.enabled &&
              !!selectedVehicle &&
              !noPaymentMethodAndCompanyWontPay
            }
            loading={loadingAddCard}
          />
        </FooterButtonContainer>
      </VerticalDistributedContainer>
    </FooterButtonContainer>
  );
};

export default EnableAnprButton;
