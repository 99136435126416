import { Button, Meta, theme } from 'aimo-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
const WrapperCustom = styled(StyledWrapper)`
  margin: 40px 0px 50px 0px;
`;
const UnavailableModalContent = () => {
  const { t } = useTranslation();
  const handleClose = () => {
    sheetModal('');
  };
  return (
    <View>
      <WrapperCustom mb={50}>
        <Meta
          center
          color={theme.colors.gray200}
          testID="longTermParking-unavailable-item">
          {t('longTermParking.unavailable')}
        </Meta>
      </WrapperCustom>
      <StyledWrapper center maxWidth={300} mb={20}>
        <Button
          testID="longTermParking-unavailable-notify-me"
          text={t('button.notifyMe')}
          size="l"
          buttonType="secondary"
          onPress={() => {
            // TODO integrate notification
          }}
        />
      </StyledWrapper>
      <StyledWrapper center maxWidth={300}>
        <Button
          testID="longTermParking-unavailable-no-thanks"
          text={t('button.noThanks')}
          size="l"
          buttonType="tertiary"
          onPress={handleClose}
        />
      </StyledWrapper>
    </View>
  );
};

export default UnavailableModalContent;
