import React from 'react';
import {
  Query,
  QueryParkZoneArgs,
  StopChargingSessionResponse,
} from 'types/generatedSchemaTypes';
import {
  StyledWrapper,
  RowSpaceBetween,
  ColumnWithMargins,
} from 'styles/ContainerStyles';
import {
  BaseText,
  Button,
  Headline2,
  Headline3,
  Meta,
  theme as styleTheme,
} from 'aimo-ui';
import { useTranslation } from 'react-i18next';
import { getDurationFromDates } from 'utils/timeUtils';
import { isWeb } from 'utils/deviceUtils';
import { useQuery } from '@apollo/client';
import { GET_PARK_ZONE } from '@queries/getParkZone';
import usePaymentDetails from 'hooks/usePaymentDetails';
import { useNavigation } from '@react-navigation/native';
import { RootNavProps } from 'types/navigation';
import styled from 'styled-components/native';
import { numberFormatter } from 'utils/commonUtils';

const NotificationBox = styled.View`
  border-radius: 10px;
  background-color: ${styleTheme.colors.gray50};
  padding: 20px;
  height: 112px;
`;

const ChargingReportContent: React.FC<{
  sessionData?: StopChargingSessionResponse;
}> = ({ sessionData }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<RootNavProps>();

  const {
    startTime,
    endTime,
    priceInCents,
    parkingZoneUid,
    licensePlate,
    cardId,
    energyInWatts,
    countryCode,
  } = sessionData || {};

  const { data, error, loading } = useQuery<Query, QueryParkZoneArgs>(
    GET_PARK_ZONE,
    {
      variables: { uid: parkingZoneUid || '' },
      skip: !parkingZoneUid,
    }
  );
  const paymentDetails = usePaymentDetails({
    transactionId: null, // For charging session, at this point there's no payment yet
    cardId,
    totalAmount: priceInCents,
  });

  if (!data || error || loading) {
    return <></>;
  }

  const duration = startTime
    ? getDurationFromDates(
        new Date(startTime),
        endTime ? new Date(endTime) : new Date()
      )
    : '';
  const energyConsumedKiloWatts = (energyInWatts || 0) / 1000;
  const energyUsed = `${
    countryCode
      ? numberFormatter(energyConsumedKiloWatts, countryCode)
      : energyConsumedKiloWatts
  } kWh`;

  const { name, commercialZoneView } = data.parkZone || {};

  return (
    <>
      <StyledWrapper mb={15} center maxWidth={550}>
        <Headline3 testID="charging-report-sheet-title" center>
          {t('evCharging.report.sheetHeader')}
        </Headline3>
      </StyledWrapper>
      <StyledWrapper mb={0} center maxWidth={550}>
        <RowSpaceBetween>
          <Headline2 testID="charging-report-duration">{duration}</Headline2>
          <Headline2 testID="charging-report-total-price">
            {energyUsed}
          </Headline2>
        </RowSpaceBetween>
      </StyledWrapper>
      <StyledWrapper mb={20}>
        <NotificationBox>
          <BaseText numberOfLines={3}>
            {t('evCharging.report.paymentInfo')}
          </BaseText>
        </NotificationBox>
      </StyledWrapper>
      <StyledWrapper mb={isWeb ? 30 : 10} center maxWidth={550}>
        <RowSpaceBetween withBorder>
          <ColumnWithMargins>
            <Meta color={styleTheme.colors.gray200}>
              {t('parking.receipt.location')}
            </Meta>
          </ColumnWithMargins>
          <ColumnWithMargins ml={15}>
            <Meta numberOfLines={1} testID="charging-report-location">
              <Meta color={styleTheme.colors.primary}>
                {commercialZoneView?.zoneCode
                  ? commercialZoneView?.zoneCode + ' '
                  : ''}
              </Meta>
              {name}
            </Meta>
          </ColumnWithMargins>
        </RowSpaceBetween>
        <RowSpaceBetween withBorder>
          <Meta color={styleTheme.colors.gray200}>
            {t('parking.receipt.car')}
          </Meta>
          <Meta testID="charging-report-license-plate">{licensePlate}</Meta>
        </RowSpaceBetween>
        <RowSpaceBetween withBorder>
          <Meta color={styleTheme.colors.gray200}>
            {t('parking.receipt.payment')}
          </Meta>
          <Meta testID="charging-report-payment-details">{paymentDetails}</Meta>
        </RowSpaceBetween>
      </StyledWrapper>
      <StyledWrapper mb={10} center>
        <Button
          testID="parking-receipt-confirm-button"
          size="l"
          fullWidth={true}
          text={t('button.close')}
          onPress={() => navigate('mainTabs', { screen: 'parkScreen' })}
        />
      </StyledWrapper>
    </>
  );
};

export default ChargingReportContent;
