import NetInfo from '@react-native-community/netinfo';
import { showToast } from 'components/common/CustomToast';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { isWeb } from 'utils/deviceUtils';
import NetworkConnectionContext from './NetworkConnectionContext';

const NetworkConnectionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [hasConnection, setHasConnection] = useState<boolean | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [notificationId, setNotificationId] = useState<string>('');
  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      const online = state.isConnected && state.isInternetReachable;
      setHasConnection(online as boolean);
    });
    setInitialized(true);
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (isWeb) {
      const handleNetworkChange = () => {
        setHasConnection(navigator.onLine);
      };
      window.addEventListener('online', handleNetworkChange);

      window.addEventListener('offline', handleNetworkChange);

      return () => {
        window.removeEventListener('online', handleNetworkChange);
        window.removeEventListener('offline', handleNetworkChange);
      };
    }
  }, [hasConnection]);

  useEffect(() => {
    let id = '';
    if (hasConnection !== null && !hasConnection && initialized) {
      if (!notificationId) {
        id = showToast('error.noNetworkConnection', 'info', 500000) as string;
        setNotificationId(id);
        return;
      }
    } else {
      global.toast?.hide(notificationId);
      setNotificationId('');
    }
  }, [hasConnection, notificationId, initialized]);

  return (
    <NetworkConnectionContext.Provider
      value={{
        hasConnection: hasConnection as boolean,
        initialized: initialized,
      }}>
      {children}
    </NetworkConnectionContext.Provider>
  );
};

export default NetworkConnectionProvider;
