import RideScreen from './RideScreen';
import ParkScreen from './ParkScreen';
import AccountScreen from '../navigation/AccountNavigator';
import ServicesScreen from '../navigation/ServicesNavigator';
import DevScreen from './DevScreen';

type ScreensType = {
  [key: string]: React.FC;
};

const screens: ScreensType = {
  parkScreen: ParkScreen,
  accountScreen: AccountScreen,
  rideScreen: RideScreen,
  servicesScreen: ServicesScreen,
  devScreen: DevScreen,
};

export default screens;
