import { useContext } from 'react';
import AccountContext from 'context/AccountContext';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import ParkingContext from 'context/ParkingContext';
import {
  isSalesOrderActive,
  isSalesOrderForVehicle,
  salesOrderForZone,
} from 'utils/permitUtils';

const useUserPermitVehicleCombination = (parkZoneUid?: string) => {
  const selectedVehicle = useSelectedParkingVehicle();
  const {
    customer: { permits },
  } = useContext(AccountContext);
  const { parkingZoneResult: parkingZone } = useContext(ParkingContext);
  const { uid } = parkingZone?.data?.parkZone || {};

  return permits?.find(
    (salesOrder) =>
      salesOrder &&
      isSalesOrderActive(salesOrder) &&
      salesOrderForZone(salesOrder, parkZoneUid ?? uid) &&
      isSalesOrderForVehicle(salesOrder.orderLines?.[0], selectedVehicle)
  );
};

export default useUserPermitVehicleCombination;
