import { ApolloProvider } from '@apollo/client';
import { UIThemeProvider } from 'aimo-ui';
import AccountDetailsProvider from 'context/AccountDetailsProvider';
import CustomToastProvider from 'context/CustomToastProvider';
import NetworkConnectionProvider from 'context/NetworkConnectionProvider';
import ServiceContextProvider from 'context/ServiceContextProvider';
import CookieConsent from 'CookieConsent';
import navigation from 'navigation/settings';
import React, { useEffect, useState } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import useCreateApolloClient from './hooks/useCreateApolloClient';
import './i18n/init.ts';
import Navigation from './navigation';
import { hydrateReactiveMMKVStorage } from './utils/MMKVStorageUtils';
import FeatureFlagsContextProvider from 'context/FeatureFlagsProvider';

const App: React.FC = () => {
  const client = useCreateApolloClient();
  const [isHydrating, setIsHydrating] = useState(true);

  useEffect(() => {
    const fetchMMKVStorage = async () => {
      await hydrateReactiveMMKVStorage();
      setIsHydrating(false);
    };
    // update states of reactive variables from async storage
    fetchMMKVStorage();
  }, []);

  if (isHydrating || !client) {
    return <></>;
  }
  return (
    <ApolloProvider client={client}>
      <FeatureFlagsContextProvider>
        <NetworkConnectionProvider>
          <AccountDetailsProvider>
            <ServiceContextProvider>
              <UIThemeProvider>
                <SafeAreaProvider>
                  <CustomToastProvider />
                  <Navigation navigation={navigation} />
                  <CookieConsent />
                </SafeAreaProvider>
              </UIThemeProvider>
            </ServiceContextProvider>
          </AccountDetailsProvider>
        </NetworkConnectionProvider>
      </FeatureFlagsContextProvider>
    </ApolloProvider>
  );
};

export default App;
