import Logger from './Logger';
import { CountryCode } from 'types/generatedSchemaTypes';

export type ServiceChip = {
  serviceChipId: string;
  title: string;
  text: { title: string; description: string };
  style?: { backgroundColor?: string };
  countriesActive?: CountryCode[];
  redirectUrl: string;
  imageSource?: string;
};

// Return boolean value to show or hide a feature.
// The feature should be shown if the value is not defined.
// Used when the feature flag is defined as a boolean in Firebase Remote Config.
export const showFeatureFlag = (featureFlag?: string): boolean => {
  return featureFlag === undefined ? true : featureFlag === 'true';
};

// Return boolean value to show or hide a feature.
// Show the feature if the current value is included in the allowed values or
// if the allowed values is ALL
// Used when the feature flag is defined as a comma separated string in Firebase Remote Config.
export const showFeatureFlagByValue = (
  allowedValues: string,
  currentValue: string
) => {
  return allowedValues === 'ALL'
    ? true
    : allowedValues.split(',').indexOf(currentValue) > -1;
};

// Filter list of items by a feature flag.
// Used when the feature flag is defined as a comma separated string in Firebase Remote Config.
export const filteredItemsByFeatureFlag = (
  items: any[],
  filterProperty: string,
  featureFlag?: string
): any[] => {
  return featureFlag === undefined || featureFlag === 'true'
    ? items
    : items.filter((item) => {
        return featureFlag.indexOf(item[filterProperty]) > -1;
      });
};

export type TypeDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

type FlagName = 'service_chips' | 'sales_order_permits';

const getDefault = <T>(flagName: FlagName): T => {
  switch (flagName) {
    case 'sales_order_permits':
      return { FI: true, SE: false } as T;
    case 'service_chips':
      return [] as T;
  }
};

export const parseFeatureFlagObject = <T>(
  objectString: string,
  flagName: FlagName
) => {
  try {
    if (objectString) {
      const parsedObject: T = JSON.parse(objectString);
      return parsedObject;
    }
  } catch (e) {
    Logger.error(`Parsing ${flagName} | Value: ${objectString} | Error: ${e}`);
  }
  return getDefault<T>(flagName);
};
