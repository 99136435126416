import { gql } from '@apollo/client';

export const READ_RECEIPT_WITH_SALES_ORDER = gql`
  query ReadReceiptByTransactionId($transactionId: ID!) {
    readReceiptByTransactionId(transactionId: $transactionId) {
      items {
        amountCents
        endTime
        startTime
        type
        licensePlate
        vatAmount
        vatPercentage
        amountExclVAT
        parkingSessionId
        zoneName
        zoneCode
        zoneUid
      }
      countryCode
      id
      creditCard {
        cardId
      }
      transactionId
      created
      orderNumber
      subMerchant {
        subMerchantName
      }
      salesOrder {
        uid
        creditCardId
        orderStatus
        endDate
        orderLines {
          pmcId
          orderLineId
          lineTotal
          version
          quantity
          lineVatAmount
          billingFrequency
          billingType
          status
          registrationPlates {
            plateText
            countryCode
          }
          parkingSpace {
            name
          }
          validity {
            validFrom
            validTo
          }
          pmc {
            name
            physicalZoneUid
            parkZone {
              name
              locationType
              commercialZoneView {
                name
                zoneCode
              }
              address {
                street
                marker
                number
                city
              }
            }
          }
        }
        renewal
      }
    }
  }
`;
