import { StackActions, useNavigation } from '@react-navigation/native';
import React, { useContext, useEffect } from 'react';
import { AccountScreenNavigationProp } from 'types/navigation';
import { Spinner } from 'aimo-ui';
import { ScreenWrapper, StyledWrapper } from 'styles/ContainerStyles';
import AccountContext from 'context/AccountContext';
import { useReactiveVar } from '@apollo/client';
import { onboardingCompleted } from 'states/persistInStorage';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';

const WholePageSpinner = () => (
  <ScreenWrapper tabBarHeight={200} testID="whole-page-spinner">
    <StyledWrapper center>
      <Spinner />
    </StyledWrapper>
  </ScreenWrapper>
);

const WebLoginSuccess: React.FC = () => {
  const navigation = useNavigation<AccountScreenNavigationProp>();
  const { dispatch } = navigation;
  const { error: customerError } = useContext(AccountContext);
  const isOnboardingCompleted = useReactiveVar(onboardingCompleted);
  const { hasAcceptedTermsCustomerCountry, loginLoading, isLoggedIn } =
    useIsLoggedIn();
  const { navigateToNext } = useAfterAuthenticationRedirect();

  useEffect(() => {
    if (isLoggedIn && !loginLoading) {
      if (!hasAcceptedTermsCustomerCountry) {
        dispatch(StackActions.replace('approveTerms'));
      } else if (!isOnboardingCompleted) {
        dispatch(
          StackActions.replace('onboardingModal', {
            screen: 'welcome',
          })
        );
      } else if (customerError) {
        dispatch(
          StackActions.replace('mainTabs', {
            screen: 'accountScreen',
          })
        );
      } else {
        navigateToNext();
      }
    }
  }, [
    customerError,
    dispatch,
    hasAcceptedTermsCustomerCountry,
    isLoggedIn,
    isOnboardingCompleted,
    loginLoading,
    navigateToNext,
  ]);

  return <WholePageSpinner />;
};
export default WebLoginSuccess;
