import { useQuery } from '@apollo/client';
import { READ_RECEIPT_BY_TYPE } from 'graphql/queries/readReceiptByType';
import { useEffect, useState } from 'react';
import {
  Query,
  QueryReadReceiptsByTypeArgs,
  ReceiptItemType,
  ReceiptInput,
} from 'types/generatedSchemaTypes';
import { sortFilter, updateReceiptQuery } from 'utils/parkUtils';
import useParkingHistoryFilter from './useParkingHistoryFilter';

const useReadReceipts = (receiptType: ReceiptItemType[]) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { vehicle: vehicleFilter } = useParkingHistoryFilter();

  const { licensePlate } = vehicleFilter ?? {};

  const resetPage = () => setCurrentPage(0);
  const nextPage = () => setCurrentPage(currentPage + 1);

  const pagingInput: ReceiptInput = {
    itemType: receiptType,
    licensePlate: licensePlate?.plateText,
    page: currentPage,
    size: 50,
    sort: sortFilter,
  };

  const input: ReceiptInput = {
    ...pagingInput,
    page: 0,
  };

  const { data, loading, error, fetchMore, refetch } = useQuery<
    Query,
    QueryReadReceiptsByTypeArgs
  >(READ_RECEIPT_BY_TYPE, {
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    resetPage();
    refetch({
      input,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, licensePlate]);

  useEffect(() => {
    if (currentPage !== 0) {
      fetchMore({
        variables: {
          input: pagingInput,
        },
        updateQuery: updateReceiptQuery,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const fetchMoreCallback = () => {
    if (!data?.readReceiptsByType?.last) {
      nextPage();
    }
  };

  return {
    data: data?.readReceiptsByType,
    loading,
    error,
    fetchMoreCallback,
    resetPage,
  };
};

export default useReadReceipts;
