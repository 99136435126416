import {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client/core/types';
import { emptyCustomer } from 'components/auth/authConstants';
import * as React from 'react';
import { Customer, Query } from 'types/generatedSchemaTypes';
import { DecodedAccessTokenType } from 'utils/accessTokenUtils';

export type AccountContextProps = {
  customer: Customer;
  error?: Error;
  loading?: boolean;
  decodedToken?: DecodedAccessTokenType;
  refetchCustomer: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<Query>> | undefined;
};

export const accountContextDefaultValue: AccountContextProps = {
  customer: {
    ...emptyCustomer,
  },
  error: undefined,
  loading: false,
  refetchCustomer: () => undefined,
};

const AccountContext = React.createContext<AccountContextProps>(
  accountContextDefaultValue
);

export default AccountContext;
