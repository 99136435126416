import { theme } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { Availability, Maybe } from 'types/generatedSchemaTypes';

type NonReservedPermitsProps = {
  availability?: Maybe<Availability>;
  priceLabel: string;
  pmcId: string;
  name: string;
  testID?: string;
  handleNavigate: (pmcId: string, notAvailable: boolean) => void;
  memberGroupName?: string;
};

const NonReservedPermits = ({
  availability,
  priceLabel,
  pmcId,
  testID,
  name,
  handleNavigate,
  memberGroupName,
}: NonReservedPermitsProps) => {
  const notAvailable = availability?.availability === 0;
  const { t } = useTranslation();

  return (
    <ColumnWithMargins mb={20}>
      <MenuItem
        testID={testID}
        menuLabel={name}
        withArrow
        noBorder
        disabled={notAvailable}
        subtitle={priceLabel}
        onPress={() => handleNavigate(pmcId, notAvailable)}
        pillText={
          notAvailable ? undefined : availability?.availability?.toString()
        }
        smallLabel={
          notAvailable ? t('longTermParking.spotUnavailable') : undefined
        }
        background={theme.colors.gray50}
        bottomPillText={memberGroupName}
      />
    </ColumnWithMargins>
  );
};

export default NonReservedPermits;
