import {
  ongoingParkingSheetExpanded,
  ongoingParkingStartChargingSheetExpanded,
  tabBarVisible,
} from 'states/common';
import {
  ChargingStatus,
  Evse,
  EvseConnectorType,
  EvseStatus,
  Maybe,
} from 'types/generatedSchemaTypes';

export const getSupportedTypes = (evses: Evse[]): string[] => {
  return evses
    .map((evse) => evse.connectors)
    .map(
      (connectors) => connectors?.map((connector) => connector.type || '') || ''
    )
    .flat()
    .filter((type) => type !== '')
    .filter((elem, index, self) => index === self.indexOf(elem));
};

export const isAvailableChargingStationSpot = (
  status: Maybe<EvseStatus> | undefined
) => {
  return status === 'AVAILABLE' || status === 'OCCUPIED';
};

export const handleCloseOngoingParkingStartChargingSheet = () => {
  tabBarVisible(false);
  ongoingParkingStartChargingSheetExpanded(false);
  ongoingParkingSheetExpanded(true);
};

type EvseConnectors = {
  [key in EvseConnectorType]: string;
};

export const getConnectorIcon = (type: EvseConnectorType) => {
  const connectorIcons: EvseConnectors = {
    CHADEMO: 'CHAdeMO',
    IEC_62196_T1: 'Type1',
    DOMESTIC_A: '',
    DOMESTIC_B: '',
    DOMESTIC_C: '',
    DOMESTIC_D: '',
    DOMESTIC_E: '',
    DOMESTIC_F: '',
    DOMESTIC_G: '',
    DOMESTIC_H: '',
    DOMESTIC_I: '',
    DOMESTIC_J: '',
    DOMESTIC_K: '',
    DOMESTIC_L: '',
    IEC_62196_T1_COMBO: 'CCS1',
    IEC_62196_T2: 'Type2',
    IEC_62196_T2_COMBO: 'CCS2',
    IEC_62196_T3A: '',
    IEC_62196_T3C: '',
    TESLA_R: '',
    TESLA_S: '',
  };
  return connectorIcons[type];
};

export const chargingAllowedToStop = (chargingStatus: ChargingStatus) => {
  return (['IN_PROGRESS', 'STOP_IN_QUEUE'] as ChargingStatus[]).includes(
    chargingStatus
  );
};
