import { useReactiveVar } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { showToast } from 'components/common/CustomToast';
import Sheet from 'components/sheets/Sheet';
import ParkingContext from 'context/ParkingContext';
import PriceEstimateContextProvider from 'context/PriceEstimateContextProvider';
import useAllowedParkingTimes from 'hooks/useAllowedParkingTimes';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';
import { ParamList } from 'screens/ParkScreen';
import PrepaidTicketBuyView from 'screens/prepaidTickets/PrepaidTicketBuyView';
import PrepaidTicketEdit from 'screens/prepaidTickets/PrepaidTicketEdit';
import {
  activeElementType,
  isFullScreen,
  selectedOngoingSession,
  tabBarVisible,
  vehicleFromParkzone,
} from 'states/common';
import { SetParamsProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import ParkingConditionsCustomEndTime from './ParkingConditionsCustomEndTime';
import ParkingZoneDetails from './ParkingZoneDetails';
import ParkingZoneSearch from './ParkingZoneSearch';
import ResumeParking from './ResumeParking';
import PredefinedTicketAndPermitList from './detail/PredefinedTicketAndPermitList';
import ConnectCharger from './evcharge/ConnectCharger';
import SelectCharger from './evcharge/SelectCharger';
import ParkingWizardFooterSwitch from './shortTermParkingWizard/ParkingWizardFooterSwitch';
import ParkingZoneDetailsLoading from './shortTermParkingWizard/ParkingZoneDetailsLoading';

const Empty: React.FC<
  ParkingSheetProps & { withLTP?: boolean; isTesting?: boolean }
> = () => <></>;

export const snappingPointsWebMobile = (
  web: (string | number)[],
  mobile: (string | number)[]
): SnappingPointSet => {
  return { web, mobile };
};

export type SnappingPointSet = {
  web: (string | number)[];
  mobile: (string | number)[];
};

export type ParkingSheetProps = {
  // Start charging after user connects charger
  autoStartCharging?: boolean;
  footerHeight?: number;
};

export const ParkingWizardSheet: React.FC = () => {
  const parkingZoneType = useReactiveVar(activeElementType);
  const { setParams }: SetParamsProps = useNavigation();
  const { params } = useRoute<RouteProp<ParamList, 'Park'>>();
  const { currentScreen, parkingZoneResult } = useContext(ParkingContext);
  const isAnpr =
    parkingZoneResult?.data?.parkZone?.parkingMethods?.anpr === true;
  const parkzoneUid = parkingZoneResult?.data?.parkZone?.uid;
  let snappingPoints = snappingPointsWebMobile([350], [350]);
  const parkingZoneUidParamIsSet = `${params?.parkingZoneUid}`.length > 0;
  let Component = Empty;
  const detailsLoadingFailed =
    currentScreen !== 'SEARCH' &&
    !parkingZoneResult?.data?.parkZone &&
    parkingZoneResult?.error;
  const fullScreen = useReactiveVar(isFullScreen);
  const editFromParkzone = useReactiveVar(vehicleFromParkzone);
  const ongoingSessionType = useReactiveVar(selectedOngoingSession);
  const { predefinedTickets } = useAllowedParkingTimes(
    parkzoneUid as string,
    true
  );
  const hasPrepaidTicket = predefinedTickets.length > 0;
  const addMargin = ongoingSessionType !== '';
  const minifiedHeight = !addMargin ? '22.5%' : '32%';
  const [footerHeight, setFooterHeight] = useState(0);
  let hideHandle = false;
  useEffect(() => {
    // Set tabbar visible again, if we come back from edit vehicle view that was triggered from park zone view
    if (!editFromParkzone) {
      tabBarVisible(true);
    }
  }, [editFromParkzone]);

  const extraHeight = useCallback(() => {
    if (
      parkingZoneType === 'CHARGING' ||
      parkingZoneType === 'PARK_AND_CHARGE'
    ) {
      return 60;
    }
    if (isAnpr || hasPrepaidTicket) {
      return isWeb ? 40 : 155;
    }
    if (ongoingSessionType !== '') {
      return 100;
    }

    return 0;
  }, [parkingZoneType, isAnpr, hasPrepaidTicket, ongoingSessionType]);

  const showBackDropIndex = () => {
    if (Component === ParkingZoneSearch && !isWeb) {
      return 2;
    }
    if (Component === ParkingZoneDetails && !isWeb) {
      return fullScreen ? 0 : 1;
    }
    return fullScreen ? 1 : 0;
  };

  switch (currentScreen) {
    case 'SEARCH':
      snappingPoints = snappingPointsWebMobile(
        [430],
        [minifiedHeight, '45%', '95%']
      );
      Component = ParkingZoneSearch;
      break;
    case 'SHOW_DETAILS':
      snappingPoints = snappingPointsWebMobile(
        [600 + extraHeight()],
        fullScreen ? ['90%'] : ['75%' + extraHeight(), '95%']
      );
      Component = parkingZoneResult?.loading
        ? ParkingZoneDetailsLoading
        : ParkingZoneDetails;
      break;
    case 'PREDEFINED_TICKETS_AND_PERMITS':
      Component = PredefinedTicketAndPermitList;
      snappingPoints = snappingPointsWebMobile([600], ['100%']);
      hideHandle = true;
      break;
    case 'CHOOSE_END_TIME':
      snappingPoints = snappingPointsWebMobile([600], ['100%']);
      hideHandle = true;
      Component = ParkingConditionsCustomEndTime;

      break;
    case 'SELECT_CHARGER':
      snappingPoints = snappingPointsWebMobile(
        [600 + extraHeight()],
        ['65%' + extraHeight(), '85%']
      );
      Component = SelectCharger;
      break;
    case 'CONNECT_CHARGER':
      snappingPoints = snappingPointsWebMobile([600 + extraHeight()], ['95%']);
      Component = ConnectCharger;
      break;
    case 'PURCHASE_PREPAID':
      snappingPoints = snappingPointsWebMobile([700 + extraHeight()], ['100%']);
      hideHandle = true;
      Component = PrepaidTicketBuyView;
      break;
    case 'EDIT_PREPAID':
      snappingPoints = snappingPointsWebMobile([700 + extraHeight()], ['100%']);
      hideHandle = true;
      Component = PrepaidTicketEdit;
      break;
    case 'RESUME_PARKING':
      snappingPoints = snappingPointsWebMobile([600], ['95%']);
      Component = ResumeParking;
      break;
    default:
  }

  const handleFooterOnLayout = (event: LayoutChangeEvent) => {
    setFooterHeight(event.nativeEvent.layout.height || 200);
  };

  if (
    detailsLoadingFailed &&
    parkingZoneUidParamIsSet &&
    !parkingZoneResult?.data?.parkZone?.parkingMethods?.evCharging
  ) {
    showToast('error.getParkZoneFailed', 'error');
    setParams({ parkingZoneUid: '' });
    return <></>;
  }
  return (
    <PriceEstimateContextProvider>
      <Sheet
        snappingPoints={snappingPoints[isWeb ? 'web' : 'mobile']}
        showBackDropIndex={showBackDropIndex()}
        sheetMinHeight={100}
        bottomInset={25}
        hideHandle={hideHandle}
        stickyFooter={
          <ParkingWizardFooterSwitch
            onLayout={handleFooterOnLayout}
            autoStartCharging
          />
        }>
        <Component autoStartCharging footerHeight={footerHeight} />
      </Sheet>
    </PriceEstimateContextProvider>
  );
};

export default ParkingWizardSheet;
