import { makeVar, ReactiveVar } from '@apollo/client';
import { Region } from 'react-native-maps';
import { LatLng, WebAndMobileRegion } from '../types/MapTypes';

const initialCoords: LatLng = {
  latitude: 0,
  longitude: 0,
};

const initialRegion: Region = {
  latitude: 60.192059,
  longitude: 24.945831,
  latitudeDelta: 0.015,
  longitudeDelta: 0.0121,
};

export const userLocation: ReactiveVar<LatLng | null> = makeVar<LatLng | null>(
  null
);

export const mapCoordinates: ReactiveVar<LatLng | LatLng[]> = makeVar<
  LatLng | LatLng[]
>(initialCoords);

export const optimizeMapContent: ReactiveVar<boolean> = makeVar<boolean>(false);

export const regionCoordinates: ReactiveVar<WebAndMobileRegion> =
  makeVar<Region>(initialRegion);
