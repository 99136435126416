import { useMutation } from '@apollo/client';
import { showToast } from 'components/common/CustomToast';
import { UPDATE_CUSTOMER } from 'graphql/mutations/updateCustomer';
import { GET_CUSTOMER } from 'graphql/queries/getCustomer';
import { t } from 'i18next';
import {
  Mutation,
  MutationPatchCustomerArgs,
} from 'types/generatedSchemaTypes';

type UpdateCustomerProps = {
  callback?: () => any;
  errorCallback?: () => any;
};

const useUpdateCustomerMutation = ({
  callback,
  errorCallback,
}: UpdateCustomerProps) => {
  const [updateCustomer, { loading }] = useMutation<
    Mutation,
    MutationPatchCustomerArgs
  >(UPDATE_CUSTOMER, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        errorPolicy: 'all',
      },
    ],
    onCompleted: () => {
      if (callback) {
        callback();
      }
    },
    onError: () => {
      if (errorCallback) {
        errorCallback();
      }
      showToast(t('error.genericError'), 'error');
    },
  });
  return { updateCustomer, loading };
};

export default useUpdateCustomerMutation;
