import { useReactiveVar } from '@apollo/client';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { theme } from 'aimo-ui';
import VehicleAdd from 'components/account/vehicle/VehicleAdd';
import VehicleCameraRecognition from 'components/account/vehicle/VehicleCameraRecognition';
import Modal from 'components/modal/Modal';
import CreditCardRedirect from 'components/payment/CreditCardRedirect';
import config from 'config/config';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useCompanyBenefitNotification from 'hooks/useCompanyBenefitNotification';
import useIsMobile from 'hooks/useIsMobile';
import useOpenScreenOnAppStartUp from 'hooks/useOpenScreenOnAppStartUp';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { enableScreens } from 'react-native-screens';
import ChargingReportScreen from 'screens/ChargingReportScreen';
import LandingScreen from 'screens/LandingScreen';
import ParkingReceiptScreen from 'screens/ParkingReceiptScreen';
import ParkingAndChargingHistoryDetailsScreen from 'screens/account/ParkingAndChargingHistoryDetailsScreen';
import ParkingHistoryDetailsScreen from 'screens/account/ParkingHistoryDetailsScreen';
import WebLoginSuccess from 'screens/account/WebLoginSuccess';
import ApproveTerms from 'screens/approveTerms/ApproveTerms';
import ApproveTermsAppLocation from 'screens/approveTerms/ApproveTermsAppLocation';
import ApproveUpdatedTermsScreen from 'screens/approveTerms/ApproveUpdatedTermsScreen';
import HslTicketScreen from 'screens/hslTicket/HslTicketScreen';
import { MapScreen } from 'screens/longTermParking';
import ParkingSpaceMapViewerScreen from 'screens/longTermParking/ParkingSpaceMapViewerScreen';
import StreetParkingPmcsScreen from 'screens/streetParking/StreetParkingPmcsScreen';
import { isAppStartupScreenOpen, tabBarIndicators } from 'states/common';
import { showLandingScreen } from 'states/persistInStorage';
import { RootStackParamList } from 'types/navigation';
import { titleFormatter } from 'utils/commonUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { getTabBarIndicator } from 'utils/indicatorUtils';
import {
  NavigationSettingsType,
  beforeRemoveApproveTerms,
  devScreen,
  getModalOptions,
  onFocusApproveTerms,
} from 'utils/navigationUtils';
import Icon from '../components/icons/Icon';
import { Logo } from '../components/map/MapButtons';
import screens from '../screens';
import { webNavigationStyle } from '../styles/StyleSheetStyles';
import ModalStackHeader from './ModalStackHeader';
import OnboardingNavigator from './OnboardingNavigator';
import StackHeader from './StackHeader';
import {
  StackHeaderComponent,
  StackHeaderComponentNoHeaderBack,
} from './navigationComponents';

enableScreens(false);
const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const TabsNavigatorScreen = ({ nav }: { nav: NavigationSettingsType }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const {
    flags: { ride },
  } = useContext(FeatureFlagsContext);
  const showRide = showFeatureFlag(ride);

  const { activeTintColor, inactiveTintColor } = nav;
  let { screenArray } = nav;
  if (config.devScreen === 'true') {
    screenArray = [...screenArray, devScreen];
  }
  const tabBarIndicatorsList = useReactiveVar(tabBarIndicators);

  // Feature Flag - ride menu item
  if (!showRide) {
    screenArray = screenArray.filter(
      (screenProps) => screenProps.screenName !== 'rideScreen'
    );
  }

  const tabBarStyle = isMobile
    ? webNavigationStyle.tabContainerMobile
    : webNavigationStyle.tabContainerNotMobile;

  const tabBarLabelPosition = isMobile ? 'below-icon' : 'beside-icon';

  const tabBarItemStyle = isMobile
    ? webNavigationStyle.tabMobile
    : webNavigationStyle.tabNotMobile;

  return (
    <>
      <Logo testID="aimo-logo" />
      <Tab.Navigator backBehavior="history">
        {screenArray.map(
          (
            {
              tabLocalizationKey,
              screenName,
              iconName,
              iconNameActive,
              initialParams,
              screenTitle,
            },
            index: number
          ) => {
            const screenComponent: React.FC = screens[screenName];
            return (
              <Tab.Screen
                key={`tab-screen-${index}`}
                name={screenName}
                component={screenComponent}
                initialParams={initialParams}
                options={{
                  title: `${screenTitle || screenName}`,
                  tabBarTestID: tabLocalizationKey,
                  tabBarLabel: t(tabLocalizationKey),
                  tabBarStyle: [webNavigationStyle.tabContainer, tabBarStyle],
                  tabBarActiveTintColor: activeTintColor,
                  tabBarInactiveTintColor: inactiveTintColor,
                  tabBarLabelStyle: [
                    webNavigationStyle.label,
                    isMobile
                      ? webNavigationStyle.labelMobile
                      : webNavigationStyle.labelNotMobile,
                  ],
                  tabBarLabelPosition: tabBarLabelPosition,
                  tabBarHideOnKeyboard: true,
                  tabBarItemStyle: [webNavigationStyle.tab, tabBarItemStyle],
                  headerShown: false,
                  tabBarIcon: ({ focused, color }) => {
                    const tabBarIndicator = getTabBarIndicator(
                      tabBarIndicatorsList,
                      tabLocalizationKey
                    );

                    return (
                      <Icon
                        name={focused ? iconNameActive : iconName}
                        color={color}
                        size="l"
                        indicatorType={tabBarIndicator?.indicatorType}
                        indicatorNumber={tabBarIndicator?.indicatorNumber}
                      />
                    );
                  },
                }}
              />
            );
          }
        )}
      </Tab.Navigator>
    </>
  );
};

const Navigation = ({ navigation }: { navigation: NavigationSettingsType }) => {
  const navigationRef = useNavigationContainerRef<RootStackParamList>();
  const { t } = useTranslation();

  const [navigationReady, setNavigationReady] = useState<boolean>(false);
  const { handleCompanyBenefitNotification } = useCompanyBenefitNotification();
  useOpenScreenOnAppStartUp(navigationReady, navigationRef);
  const { linking } = navigation;
  const isFirstLaunch = useReactiveVar(showLandingScreen);

  const modalOptions = useMemo(() => getModalOptions(), []);

  useEffect(() => {
    if (navigationReady) {
      handleCompanyBenefitNotification(navigationRef);
    }
  }, [handleCompanyBenefitNotification, navigationReady, navigationRef]);

  const TabNav = useCallback(
    () => <TabsNavigatorScreen nav={navigation} />,
    [navigation]
  );

  return (
    <View style={webNavigationStyle.backgroundContainer}>
      <NavigationContainer
        linking={linking}
        ref={navigationRef}
        onReady={() => {
          setNavigationReady(true);
          if (isFirstLaunch) {
            navigationRef.navigate('landingScreen');
          }
        }}
        documentTitle={{
          formatter: (options, route) => titleFormatter(options, route),
        }}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            presentation: 'card',
            animationEnabled: true,
          }}>
          <Stack.Screen name="mainTabs" component={TabNav} />
          <Stack.Screen
            name="onboardingModal"
            component={OnboardingNavigator}
          />
          <Stack.Screen
            name="parkingDetails"
            component={ParkingHistoryDetailsScreen}
            options={{
              headerShown: true,
              headerBackTitleVisible: false,
              headerTransparent: true,
              header: (props) => (
                <ModalStackHeader
                  {...props}
                  background={theme.colors.transparent}
                  closeButton={false}
                  backButtonType="secondary"
                />
              ),
            }}
          />
          <Stack.Screen
            name="parkAndChargeDetails"
            component={ParkingAndChargingHistoryDetailsScreen}
            options={{
              headerShown: true,
              headerBackTitleVisible: false,
              headerTransparent: true,
              header: (props) => (
                <ModalStackHeader
                  {...props}
                  background={theme.colors.transparent}
                  closeButton={false}
                  backButtonType="secondary"
                />
              ),
            }}
          />
          <Stack.Screen
            name="creditCardRedirectCancel"
            component={CreditCardRedirect}
          />
          <Stack.Screen
            name="creditCardRedirectFailure"
            component={CreditCardRedirect}
          />
          <Stack.Screen
            name="creditCardRedirectSuccess"
            component={CreditCardRedirect}
          />
          <Stack.Screen
            name="parkingReceipt"
            component={ParkingReceiptScreen}
          />
          <Stack.Screen
            name="chargingReport"
            component={ChargingReportScreen}
            options={{
              title: t('evCharging.receipt.sheetHeader'),
            }}
          />
          <Stack.Screen
            name="landingScreen"
            component={LandingScreen}
            options={{
              title: t('landingScreen.title'),
            }}
          />
          <Stack.Screen
            name="loginSuccess"
            component={WebLoginSuccess}
            options={{
              title: t('account.signIn'),
              headerShown: true,
              header: StackHeaderComponentNoHeaderBack,
              cardStyle: { backgroundColor: theme.colors.white },
            }}
          />
          <Stack.Screen
            name="approveTerms"
            component={ApproveTerms}
            options={{
              title: t('account.terms.approveTerms'),
              headerShown: true,
              header: StackHeaderComponentNoHeaderBack,
            }}
            listeners={{
              focus: () => {
                onFocusApproveTerms();
              },
              beforeRemove: () => {
                beforeRemoveApproveTerms();
              },
            }}
          />
          <Stack.Screen
            name="approveUpdatedTerms"
            component={ApproveUpdatedTermsScreen}
            options={{
              headerShown: false,
            }}
            listeners={{
              beforeRemove: () => {
                isAppStartupScreenOpen(false);
              },
            }}
          />
          <Stack.Group
            screenOptions={{
              presentation: 'modal',
              header: (props) => (
                <ModalStackHeader {...props} {...modalOptions.headerStyle} />
              ),
            }}>
            <Stack.Screen
              name="addVehicleParking"
              component={VehicleAdd}
              options={{
                title: t('account.addVehicle'),
                headerShown: true,
                cardStyle: {
                  backgroundColor: theme.colors.white,
                },
                header: StackHeaderComponent,
              }}
            />
            <Stack.Screen
              name="vehicleCameraRecognitionParking"
              component={VehicleCameraRecognition}
              options={{
                title: t('vehicle.cameraRecognition.title'),
                headerShown: true,
                cardStyle: modalOptions.cardStyle,
              }}
            />
            <Stack.Screen
              name="approveTermsAppLocation"
              component={ApproveTermsAppLocation}
              options={{
                title: t('account.terms.termsAgreement'),
                headerShown: true,
                cardStyle: {
                  backgroundColor: theme.colors.white,
                },
                header: (props) => (
                  <StackHeader background={theme.colors.white} {...props} />
                ),
              }}
              listeners={{
                focus: () => {
                  onFocusApproveTerms();
                },
                beforeRemove: () => {
                  beforeRemoveApproveTerms();
                },
              }}
            />
          </Stack.Group>
          <Stack.Group
            screenOptions={{
              headerBackTitleVisible: false,
              animationEnabled: true,
              header: (props) => (
                <StackHeader
                  {...props}
                  buttonType="tertiary"
                  background={theme.colors.white}
                />
              ),
            }}>
            <Stack.Screen
              name="longTermParking"
              component={MapScreen}
              options={{
                headerMode: 'float',
                headerShown: false,
                headerBackTitleVisible: false,
                title: t('longTermParking.search'),
              }}
            />
            <Stack.Screen
              name="parkingSpaceMapViewer"
              component={ParkingSpaceMapViewerScreen}
              options={{
                title: t('longTermParking.selectParkingSpace'),
                headerMode: 'float',
                headerShown: false,
                headerBackTitleVisible: false,
              }}
            />
          </Stack.Group>
          <Stack.Group
            screenOptions={{
              headerShown: true,
              header: (props) => (
                <StackHeader
                  {...props}
                  buttonType="secondary"
                  background={theme.colors.gray100}
                />
              ),
            }}>
            <Stack.Screen
              name="streetParking"
              component={StreetParkingPmcsScreen}
              options={{
                headerMode: 'float',
                headerShown: false,
                headerBackTitleVisible: false,
                title: t('services.turkuPermitsTitle'),
              }}
            />
            <Stack.Screen
              name="hslTicket"
              component={HslTicketScreen}
              options={{
                headerShown: true,
                title: t('hslTicket.title'),
              }}
            />
          </Stack.Group>
        </Stack.Navigator>
        <Modal />
      </NavigationContainer>
    </View>
  );
};

export default Navigation;
