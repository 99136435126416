import { useReactiveVar } from '@apollo/client';
import { useIsFocused } from '@react-navigation/native';
import { theme } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import { OngoingChargingSheet } from 'components/ongoingParking/OngoingChargingSheet';
import OngoingMultipleSessionsHeader from 'components/ongoingParking/OngoingMultipleSessionsHeader';
import OngoingParkingSheet from 'components/ongoingParking/OngoingParkingSheet';
import Sheet from 'components/sheets/Sheet';
import NetworkConnectionContext from 'context/NetworkConnectionContext';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useOngoingChargingSession from 'hooks/useOngoingChargingSession';
import useOngoingMultipleSession from 'hooks/useOngoingMultipleSession';
import useOngoingParkingSession from 'hooks/useOngoingParkingSession';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { StatusBar } from 'react-native';
import {
  activeElement,
  ongoingChargingSheetExpanded,
  ongoingParkingSheetExpanded,
  selectedOngoingSession,
  tabBarVisible,
} from 'states/common';
import {
  checkNotificationsPermissions,
  isIos,
  isWeb,
  isIosMini,
  isIosMax,
  isAndroid,
} from 'utils/deviceUtils';

const COLLAPSED_SNAP_POINT = '10.5%';
const EXPANDED_SNAP_POINT = '21%';

const OngoingSessionsSheet: React.FC<{
  showSheet?: boolean;
}> = ({ showSheet = true }) => {
  useOngoingMultipleSession();
  const tabBarHeight = useBottomMenuHeight();
  const selectedOngoingSessionType = useReactiveVar(selectedOngoingSession);
  const ongoingParkingSheetExp = useReactiveVar(ongoingParkingSheetExpanded);
  const ongoingChargingSheetExp = useReactiveVar(ongoingChargingSheetExpanded);
  const {
    ongoingParkingSession,
    ongoingParkingSessionError,
    ongoingParkingSessionStartPolling,
    ongoingParkingSessionStopPolling,
  } = useOngoingParkingSession();
  const {
    ongoingChargingSession,
    ongoingChargingSessionError,
    ongoingChargingSessionStartPolling,
    ongoingChargingSessionStopPolling,
  } = useOngoingChargingSession();
  const { isLoggedIn } = useIsLoggedIn();
  const { hasConnection } = useContext(NetworkConnectionContext);
  const isFocused = useIsFocused();
  const [ongoingParkingErrorShown, setOngoingParkingErrorShown] =
    useState(false);
  const [ongoingChargingErrorShown, setOngoingChargingErrorShown] =
    useState(false);

  const { status } = ongoingChargingSession || {};

  const snappingPoints = useMemo(() => {
    const minSnapPoint =
      tabBarHeight > 0 ? EXPANDED_SNAP_POINT : COLLAPSED_SNAP_POINT;
    if (isWeb) {
      return ['85%'];
    } else if (selectedOngoingSessionType === 'MULTIPLE') {
      return [minSnapPoint];
    }
    return [minSnapPoint, '100%'];
  }, [selectedOngoingSessionType, tabBarHeight]);

  const isSheetExp = useMemo(
    () => ongoingParkingSheetExp || ongoingChargingSheetExp,
    [ongoingChargingSheetExp, ongoingParkingSheetExp]
  );

  const hasOngoingChargingSession = useMemo(
    () =>
      status === 'IN_PROGRESS' ||
      status === 'STOP_IN_QUEUE' ||
      status === 'START_IN_QUEUE',
    [status]
  );

  const hasOngoingSession = useMemo(
    () => !!ongoingParkingSession || hasOngoingChargingSession,
    [hasOngoingChargingSession, ongoingParkingSession]
  );

  const showOngoingParkingSession = useMemo(
    () =>
      (ongoingParkingSession && selectedOngoingSessionType === '') ||
      selectedOngoingSessionType === 'PARKING',
    [ongoingParkingSession, selectedOngoingSessionType]
  );

  const showOngoingChargingSession = useMemo(
    () =>
      (hasOngoingChargingSession && selectedOngoingSessionType === '') ||
      selectedOngoingSessionType === 'CHARGING',
    [hasOngoingChargingSession, selectedOngoingSessionType]
  );

  useEffect(() => {
    // fetch right away & poll sessions every 15 secs on park screen focus
    const pollerCheck = async () => {
      const notificationPermission =
        await checkNotificationsPermissions('granted');
      isLoggedIn &&
      isFocused &&
      hasConnection &&
      (isWeb || !notificationPermission)
        ? ongoingParkingSessionStartPolling(15000)
        : ongoingParkingSessionStopPolling();

      // This code should be implemented together with the above code when charging session push notifications are available (CPS-13492).
      isLoggedIn && isFocused && hasConnection
        ? ongoingChargingSessionStartPolling(15000)
        : ongoingChargingSessionStopPolling();
    };
    pollerCheck();
    return () => {
      ongoingParkingSessionStopPolling();
      ongoingChargingSessionStopPolling();
    };
  }, [
    hasConnection,
    isFocused,
    isLoggedIn,
    ongoingChargingSessionStartPolling,
    ongoingChargingSessionStopPolling,
    ongoingParkingSessionStartPolling,
    ongoingParkingSessionStopPolling,
  ]);

  if (ongoingParkingSessionError) {
    if (!ongoingParkingErrorShown) {
      setOngoingParkingErrorShown(true);
      showToast('error.showSessions', 'error');
    }
    ongoingParkingSessionStopPolling();
  }

  if (ongoingChargingSessionError) {
    if (!ongoingChargingErrorShown) {
      setOngoingChargingErrorShown(true);
      showToast('error.showCharging', 'error');
    }
    ongoingChargingSessionStopPolling();
  }

  const [hadOngoingSession, setHadOngoingSession] = useState(hasOngoingSession);
  useEffect(() => {
    // show tab bar when session ends
    if (hadOngoingSession && !hasOngoingSession) {
      tabBarVisible(true);
      ongoingParkingSheetExpanded(false);
      activeElement('');
    }
    setHadOngoingSession(hasOngoingSession);
  }, [hasOngoingSession, hadOngoingSession]);

  if (!showSheet || !hasOngoingSession) {
    return <></>;
  }

  const isOngoingMultipleSessions = selectedOngoingSessionType === 'MULTIPLE';

  let bottomInset = 0;
  if (isIos && isIosMini) {
    bottomInset = -6;
  } else if (isIos && isIosMax) {
    bottomInset = -22;
  } else if (isIos && !isIosMax && !isIosMini) {
    bottomInset = -11;
  } else if (isAndroid) {
    bottomInset = -10;
  }

  return (
    <>
      <StatusBar barStyle={isSheetExp ? 'light-content' : 'default'} />
      <Sheet
        showBackDropIndex={1}
        snappingPoints={snappingPoints}
        bgColor={theme.colors.darkBg.grey}
        hideTabBarOnIndex={1}
        bottomInset={bottomInset}
        hideHandle
        sheetMinHeight={60}
        sheetMaxHeight={isOngoingMultipleSessions ? 150 : undefined}
        borderRadius={theme.borderRadius.ongoingSheet}>
        {isOngoingMultipleSessions ? (
          <OngoingMultipleSessionsHeader />
        ) : showOngoingParkingSession ? (
          <OngoingParkingSheet />
        ) : (
          showOngoingChargingSession && <OngoingChargingSheet />
        )}
      </Sheet>
    </>
  );
};

export default OngoingSessionsSheet;
