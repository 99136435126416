import { gql } from '@apollo/client';

export const READ_POOLING_GROUP = gql`
  query Query($poolingGroupId: ID!) {
    readPoolingGroup(poolingGroupId: $poolingGroupId) {
      parkingZoneUid
      uid
      poolSize
      insidePool
      outsidePool
      level
    }
  }
`;
