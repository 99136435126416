import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Meta, theme } from 'aimo-ui';
import { StyledWrapper } from 'styles/ContainerStyles';
import useRemoveAccount from 'hooks/useRemoveAccount';
import { testIdentifiers } from 'utils/testIdentifiers';

const RemoveAccount = () => {
  const { t } = useTranslation();
  const { removeAccount } = useRemoveAccount();
  const [loading, setLoading] = useState(false);

  const onPress = () => {
    setLoading(true);
    removeAccount();
  };

  return (
    <StyledWrapper mb={0}>
      <StyledWrapper center>
        <Meta
          {...testIdentifiers('remove-info-text')}
          color={theme.colors.gray200}>
          {t('account.removeInfoText')}
        </Meta>
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <Button
          {...testIdentifiers('remove-account-button')}
          size="l"
          text={t('account.removeAccount')}
          onPress={onPress}
          buttonType="alert"
          loading={loading}
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default RemoveAccount;
