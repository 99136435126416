import { useReactiveVar } from '@apollo/client';
import { Headline3, Spinner } from 'aimo-ui';
import { Center } from 'components/park/styles/styles';
import React, { useCallback } from 'react';
import { parkingHistoryViewFilter } from 'states/common';
import styled from 'styled-components/native';
import {
  ChargingSessionHistoryItem,
  NoHistory,
  ParkingSessionHistoryItem,
} from './HistoryComponents';
import { ListFooter, SessionHistoryData } from './HistoryContent';
import SectionListCustom from './SectionListCustom';

const TitleElement = styled(Headline3)`
  margin-bottom: 20px;
  padding-bottom: 8px;
  background-color: ${(p) => p.theme.colors.white};
`;

const Separator = styled.View`
  border-bottom-width: 3px;
  margin-top: -3px;
  border-bottom-color: ${(p) => p.theme.colors.gray50};
`;

const ListItemSeparator = () => <Separator />;

const FilteredSessionContent: React.FC<{
  sessions: SessionHistoryData;
  sessionsFetchMore?: () => void;
  isLast?: boolean;
  loading?: boolean;
  noData?: boolean;
}> = ({ sessions, noData, sessionsFetchMore, isLast, loading }) => {
  const historyFilter = useReactiveVar(parkingHistoryViewFilter);
  const memoizedItem = useCallback(
    ({ section: { groupIndex }, item, index }: any) => {
      if (item.chargingSessionId) {
        return (
          <ChargingSessionHistoryItem
            item={item}
            groupIndex={groupIndex}
            index={index}
          />
        );
      }
      return (
        <ParkingSessionHistoryItem
          item={item}
          groupIndex={groupIndex}
          index={index}
        />
      );
    },
    []
  );

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (noData && !loading) {
    return (
      <NoHistory
        isFailed={historyFilter === 'failed'}
        isFree={historyFilter === 'free'}
        isBilling={historyFilter === 'billing'}
        isOther={historyFilter === 'other'}
      />
    );
  }

  return (
    <SectionListCustom
      sections={sessions}
      stickySectionHeadersEnabled={true}
      keyExtractor={({ sessionId = '' }, index) => sessionId + index}
      renderItem={memoizedItem}
      renderSectionHeader={({ section: { groupIndex, title } }) => (
        <TitleElement testID={`${'failed-or-free-session'}-${groupIndex}`}>
          {title}
        </TitleElement>
      )}
      ItemSeparatorComponent={ListItemSeparator}
      ListFooterComponent={() => (
        <ListFooter
          isLast={isLast || true}
          fetchMore={sessionsFetchMore}
          loading={loading || true}
        />
      )}
    />
  );
};

export default FilteredSessionContent;
