import { Headline1, Meta, Spinner, theme } from 'aimo-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { ColumnWithMargins, StyledImage } from 'styles/ContainerStyles';
import { ParkzonePmcUnion } from 'types/common';
import { CountryCode } from 'types/generatedSchemaTypes';
import { currencyFormatter, getPriceForSalesOrder } from 'utils/commonUtils';
import {
  durationText,
  isItemOwned,
  isItemPermit,
  isItemPmc,
  isItemPredefinedTicket,
  isItemSalesOrder,
} from 'utils/permitUtils';
import { Row } from '../styles/styles';
import { testIdentifiers } from 'utils/testIdentifiers';
import usePollSalesOrderStatus from 'hooks/usePollSalesOrderStatus';

const Wrapper = styled.TouchableOpacity<{ isHorizontalList?: boolean }>`
  background-color: ${theme.colors.lightBg.green};
  border-radius: 20px;
  padding: 0 10px;
  ${({ isHorizontalList }) =>
    isHorizontalList ? 'max-width: 300px' : undefined};
`;

type PrepaidItemProps = {
  item: ParkzonePmcUnion;
  countryCode: CountryCode;
  testID?: string;
  onPress?: (params?: any) => void;
  isHorizontalList?: boolean;
};

const PermitImage = ({ testID }: { testID?: string }) => (
  <StyledImage
    source={require('assets/images/permit.png')}
    width={62}
    height={53}
    center
    testID={testID}
  />
);

const PrepaidTicketOrPermitItem = ({
  item,
  countryCode,
  testID,
  onPress,
  isHorizontalList,
}: PrepaidItemProps) => {
  const { t } = useTranslation();
  const handleOnPress = () => onPress?.(item);

  const salesOrder = isItemSalesOrder(item) ? item : undefined;
  const isUpdating = salesOrder?.orderStatus === 'UPDATING';
  usePollSalesOrderStatus(
    salesOrder?.id as string,
    salesOrder?.orderStatus ?? 'ACTIVE'
  );

  const showServiceFee = () => {
    if (isItemPredefinedTicket(item)) {
      return item?.serviceFee !== 0 && typeof item?.serviceFee === 'number'
        ? `+ ${currencyFormatter(item?.serviceFee, countryCode)} ${t(
            'parking.receipt.serviceFee'
          )}`
        : null;
    }
  };

  const pricingText = () => {
    if (isItemPmc(item)) {
      return getPriceForSalesOrder(item.prices, countryCode);
    }
    if (isItemPermit(item)) {
      return '';
    }
    if (isItemSalesOrder(item)) {
      return '';
    }
    return currencyFormatter((item?.price as number) || 0, countryCode);
  };

  const itemName = (listItem: ParkzonePmcUnion) => {
    if (isItemPmc(listItem)) {
      return listItem.name;
    }
    if (isItemPermit(listItem)) {
      return listItem.pmcName;
    }
    if (isItemSalesOrder(listItem)) {
      return listItem?.orderLines?.[0]?.pmc?.name || '';
    }
    const duration = durationText(item);
    if (duration) {
      return `${duration} ${listItem?.name || ''}`;
    }
    return listItem?.name || '';
  };

  return (
    <Wrapper
      {...testIdentifiers(testID as string)}
      isHorizontalList={isHorizontalList}
      onPress={isUpdating ? undefined : handleOnPress}>
      <Row>
        <PermitImage testID={`${testID}-image`} />
        <ColumnWithMargins mr={45}>
          <Meta numberOfLines={2}>{itemName(item)}</Meta>
          <Headline1 {...testIdentifiers(`${testID}-price`)}>
            {isUpdating && <Spinner size={25} />}
            {isItemOwned(item) ? t('button.manage') : pricingText()}
          </Headline1>
          <Meta testID={`${testID}-serviceFee`}>
            {isItemPredefinedTicket(item) && !isItemOwned(item)
              ? showServiceFee()
              : ''}
          </Meta>
        </ColumnWithMargins>
      </Row>
    </Wrapper>
  );
};

export default PrepaidTicketOrPermitItem;
