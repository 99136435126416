import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { BaseText, FieldInput, Meta, Spinner, Title, theme } from 'aimo-ui';
import BackButton from 'components/common/BackButton';
import CloseButton from 'components/common/CloseButton';
import { showToast } from 'components/common/CustomToast';
import MenuItem from 'components/common/MenuItem';
import { MarginView } from 'components/park/styles/styles';
import FlatListCustom from 'components/sheets/FlatListCustom';
import Sheet from 'components/sheets/Sheet';
import AccountContext from 'context/AccountContext';
import { FinnishSSN } from 'finnish-ssn';
import { READ_LEGAL_ADDRESS_STATUS } from 'graphql/queries/readLegalAddressStatus';
import { READ_VISMA_SIGN_DOCUMENT } from 'graphql/queries/readVismaSignDocument';
import { READ_VISMA_SIGN_METHODS } from 'graphql/queries/readVismaSignMethods';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WebView, { WebViewNavigation } from 'react-native-webview';
import { strongAuthCompleted, strongAuthFlow } from 'states/common';
import styled from 'styled-components/native';
import {
  ColumnWithMargins,
  StyledImage,
  StyledWrapper,
} from 'styles/ContainerStyles';
import {
  Query,
  QueryReadLegalAddressStatusArgs,
  QueryReadVismaSignDocumentArgs,
} from 'types/generatedSchemaTypes';
import { StreetParkingStackParamList } from 'types/navigation';
import { isRunningLocally } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';

const Container = styled(ColumnWithMargins)`
  flex: 1;
`;

const HeaderContainer = styled(StyledWrapper)`
  flex-direction: row;
  justify-content: space-between;
`;

const WebContainer = styled(StyledWrapper)`
  background-color: ${(p) => p.theme.colors.white};
  flex: 1;
  border-radius: 20px;
`;

const AuthContent: React.FC<{
  methods: any;
  authUrl: string;
  loading: boolean;
  startAuth: (ssn: string, method: string) => void;
}> = ({ methods, startAuth, authUrl, loading }) => {
  const [ssn, setSsn] = useState('');
  const [isValid, setIsValid] = useState(true);
  const { t } = useTranslation();
  const { goBack } =
    useNavigation<StackNavigationProp<StreetParkingStackParamList>>();
  const { refetchCustomer: fetchCustomerAfterSuccess } =
    useContext(AccountContext);
  const [readLegalAddressStatus] = useLazyQuery<
    Query,
    QueryReadLegalAddressStatusArgs
  >(READ_LEGAL_ADDRESS_STATUS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      fetchCustomerAfterSuccess();
      strongAuthCompleted(true);
    },
    onError: () => showToast(t('error.addressFetchFailed'), 'error'),
  });

  const validateSsn = (text: string) => {
    const trimmedSsn = text.trim();
    setSsn(trimmedSsn.toUpperCase());
    const validSsn = FinnishSSN.validate(trimmedSsn.toUpperCase());
    setIsValid(validSsn);
  };

  const onNavigationStateChange = async (event: WebViewNavigation) => {
    if (event.url.includes('includeAllIdProviders=true')) {
      readLegalAddressStatus({
        variables: {
          input: {
            ssn: isRunningLocally() ? '300369-806L' : ssn,
          },
        },
      });
    }
  };

  if (authUrl) {
    return isWeb ? (
      (window as any).open(authUrl, '_self')
    ) : (
      <WebView
        useWebKit
        testID="strong-auth-webview"
        width={'100%'}
        height={'auto'}
        source={{ uri: authUrl }}
        onNavigationStateChange={onNavigationStateChange}
      />
    );
  }

  const ssnIsValid = isValid && ssn !== '';
  return (
    <Container ml={10} mr={10} mt={15}>
      <HeaderContainer mb={20}>
        <BackButton buttonType="transparent" onPress={() => goBack()} />
        <CloseButton
          testID={'strong-auth-close'}
          onPress={() => strongAuthFlow(false)}
        />
      </HeaderContainer>
      <StyledWrapper center>
        <Title testID="strong-auth-title">
          {t('legalAddressAuth.identification')}
        </Title>
      </StyledWrapper>
      <StyledWrapper center maxWidth={isWeb ? 450 : 335}>
        <BaseText testID="strong-auth-info" center>
          {t('legalAddressAuth.identificationInfo')}
        </BaseText>
      </StyledWrapper>
      <StyledWrapper center maxWidth={isWeb ? 450 : 335}>
        <FieldInput
          testID="strong-auth-ssn"
          value={ssn}
          disabled={loading}
          title={t('legalAddressAuth.ssn')}
          onChangeText={(value) => validateSsn(value)}
        />
        <Meta
          color={
            !isValid && ssn !== ''
              ? theme.colors.boost.red
              : theme.colors.gray200
          }
          testID="strong-auth-meta">
          {!isValid && ssn.length > 0
            ? t('legalAddressAuth.invalidSsn')
            : t('legalAddressAuth.ssnInfo')}
        </Meta>
      </StyledWrapper>
      {loading && (
        <StyledWrapper center>
          <Spinner />
        </StyledWrapper>
      )}
      {methods.length > 0 && !loading && (
        <FlatListCustom
          data={methods}
          testID="strong-auth-method-list"
          renderItem={({ item }) => (
            <MenuItem
              menuLabel={item.name}
              withArrow
              testID={`strong-auth-method-${item.name}`}
              onPress={() => ssnIsValid && startAuth(ssn, item.identifier)}
              arrowSize="s"
              imageComponent={
                <StyledImage
                  source={{ uri: item.image }}
                  resizeMode="contain"
                  width={60}
                  height={40}
                />
              }
            />
          )}
          keyExtractor={(item, index) => `${item?.uid}-${index}`}
          ListFooterComponent={() => <MarginView />}
        />
      )}
    </Container>
  );
};

const LegalAddressAuthScreen = () => {
  const snappingPoints = ['90%'];
  const [url, setUrl] = useState('');
  const { t } = useTranslation();
  const [createVismaSignDocument, { loading }] = useLazyQuery<
    Query,
    QueryReadVismaSignDocumentArgs
  >(READ_VISMA_SIGN_DOCUMENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: async (data) => {
      setUrl(data?.readVismaSignDocument?.fulfillUrl as string);
    },
    onError: () => {
      showToast(t('error.signDocumentFailed'), 'error');
    },
  });

  const { data: vismSignMethods } = useQuery<Query>(READ_VISMA_SIGN_METHODS, {
    fetchPolicy: 'cache-and-network',
  });

  const authMethods = React.useMemo(
    () => vismSignMethods?.readVismaSignMethods || [],
    [vismSignMethods]
  );

  const getRedirectionUrl = (ssn: string) => {
    if (isRunningLocally()) {
      return `${process.env.REACT_APP_REDIRECT_URI_WEB}/street-parking?ssn=300369-806L`;
    }
    return `${process.env.REACT_APP_REDIRECT_URI_WEB}/street-parking?ssn=${ssn}`;
  };

  const startAuthentication = (ssn: string, method: string) => {
    createVismaSignDocument({
      variables: {
        input: {
          ssn,
          authService: method,
          returnUrl: isWeb
            ? getRedirectionUrl(ssn)
            : 'https://aimoone.aimopark.io/success?includeAllIdProviders=true',
        },
      },
    });
  };

  if (isWeb) {
    return (
      <WebContainer center maxWidth={600} testID="strong-auth-web">
        <AuthContent
          methods={authMethods}
          startAuth={startAuthentication}
          authUrl={url}
          loading={loading}
        />
      </WebContainer>
    );
  }
  return (
    <Sheet snappingPoints={snappingPoints} hideHandle showBackDropIndex={0}>
      <AuthContent
        methods={authMethods}
        startAuth={startAuthentication}
        authUrl={url}
        loading={loading}
      />
    </Sheet>
  );
};

export default LegalAddressAuthScreen;
