import { Headline3, Meta, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import {
  OngoingParkingHeaderWrapper,
  OngoingParkingHeaderStyles,
} from 'components/ongoingParking/styles';
import { Row } from 'components/park/styles/styles';
import useOngoingMultipleSession from 'hooks/useOngoingMultipleSession';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable } from 'react-native';
import { sheetModal } from 'states/common';
import { groupBy } from 'lodash';
import { OngoingSessionsIcons } from 'components/ongoingParking/OngoingParkingComponents';
import { OngoingSessionType } from 'types/common';
import { CenterRow } from 'styles/ContainerStyles';

const OngoingMultipleSessionsHeader: React.FC = () => {
  const { t } = useTranslation();
  const { parkAndChargeData } = useOngoingMultipleSession();

  const sessionsGroupedByParkZone = useMemo(
    () =>
      groupBy(parkAndChargeData, (res) => {
        return res.parkZone?.uid;
      }),
    [parkAndChargeData]
  );

  return (
    <>
      <Pressable
        style={OngoingParkingHeaderStyles.container}
        onPress={() => sheetModal('showOngoingMultipleSessions')}
        testID="ongoing-multiple-container">
        <OngoingParkingHeaderWrapper mr={20} grow>
          <Headline3 numberOfLines={1} color={theme.colors.white}>
            {t('parking.ongoingActiveServicesCount', {
              count:
                parkAndChargeData && parkAndChargeData.length > 1
                  ? parkAndChargeData.length
                  : 0,
            })}
          </Headline3>
          <Row>
            {Object.values(sessionsGroupedByParkZone).map(
              (sessionsByParkZone: OngoingSessionType[]) => (
                <CenterRow
                  key={`ongoing-session-header-${sessionsByParkZone[0].parkZone?.name}`}>
                  <OngoingSessionsIcons
                    sessionsByParkZone={sessionsByParkZone}
                  />
                  <Meta numberOfLines={1} color={theme.colors.white}>
                    {sessionsByParkZone[0].parkZone?.name}
                  </Meta>
                </CenterRow>
              )
            )}
          </Row>
        </OngoingParkingHeaderWrapper>
        <OngoingParkingHeaderWrapper>
          <Pressable onPress={() => sheetModal('showOngoingMultipleSessions')}>
            <Icon
              name="Chevron_Right"
              size="m"
              testID="chevron-right"
              color={theme.colors.white}
            />
          </Pressable>
        </OngoingParkingHeaderWrapper>
      </Pressable>
    </>
  );
};

export default OngoingMultipleSessionsHeader;
