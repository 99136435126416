import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Button, theme } from 'aimo-ui';
import ParkingSignInButton from 'components/park/ParkingSignInButton';
import { ParkingSheetProps } from 'components/park/ParkingWizardSheet';
import PaymentCardDropDown from 'components/park/detail/PaymentCardDropDown';
import VehicleSelectDropDown from 'components/park/detail/VehicleSelectDropDown';
import {
  FooterButtonContainer,
  VerticalDistributedContainer,
} from 'components/sheets/SheetLayout';
import AccountContext from 'context/AccountContext';
import ParkingContext from 'context/ParkingContext';
import useAddPaymentMethod from 'hooks/useAddPaymentMethod';
import useCreateSession from 'hooks/useCreateSession';
import useIsDesktop from 'hooks/useIsDesktop';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useOngoingChargingSession from 'hooks/useOngoingChargingSession';
import React, { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  accountRestricted,
  selectedOngoingSession,
  sheetModal,
  tabBarVisible,
} from 'states/common';
import { onboardingCompleted } from 'states/persistInStorage';
import { Charger } from 'types/common';
import { RootNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';

type DefineDisabledProps = {
  parkingZoneUid: string | undefined;
  selectedEvCharger: Charger | undefined;
};

const defineDisabled = ({
  parkingZoneUid,
  selectedEvCharger,
}: DefineDisabledProps) => {
  return !parkingZoneUid || !selectedEvCharger;
};

const StartChargingButton: React.FC<ParkingSheetProps> = ({
  autoStartCharging,
}) => {
  const { navigate } = useNavigation<RootNavProps>();
  const { t } = useTranslation();
  const {
    parkingConditions: {
      vehicle,
      paymentMethod,
      parkingZoneUid,
      companyBenefit,
    },
    setCurrentScreen,
    selectedEvCharger,
  } = useContext(ParkingContext) || {};
  const {
    customer: { applicationPreferences, vehicles },
  } = useContext(AccountContext);
  const { isLoggedIn, loginLoading } = useIsLoggedIn();
  const ongoingSessionType = useReactiveVar(selectedOngoingSession);
  const { ongoingChargingSession } = useOngoingChargingSession();
  const ongoingChargingChanged = useMemo(() => {
    return ongoingChargingSession;
  }, [ongoingChargingSession]);
  const prevSessions = useRef(ongoingChargingChanged);
  const chargingSessionChange = prevSessions.current !== ongoingChargingChanged;
  const isDesktop = useIsDesktop();
  const isUserRestricted = useReactiveVar(accountRestricted);
  const hasVehicles = !!(vehicles && vehicles.length > 0);
  const { addCard, loadingAddCard } = useAddPaymentMethod();

  const { createCharging, createChargingLoading, chargingSuccess } =
    useCreateSession({});

  const missingDetails = useMemo(
    () =>
      (!paymentMethod?.cardId && !companyBenefit?.benefitTargetUid) ||
      (!vehicle && !applicationPreferences?.alwaysSelectVehicle),
    [
      paymentMethod,
      companyBenefit,
      vehicle,
      applicationPreferences?.alwaysSelectVehicle,
    ]
  );

  const chargingSuccessAndChargingSessionNotChanged =
    chargingSuccess && !chargingSessionChange;
  const disabled =
    createChargingLoading ||
    defineDisabled({
      parkingZoneUid,
      selectedEvCharger,
    }) ||
    chargingSuccessAndChargingSessionNotChanged;
  const renderMarging = () => {
    if (ongoingSessionType !== '' && tabBarVisible()) {
      if (isWeb && !isDesktop) {
        return 30;
      }
      return theme.normalising.heightPixel(90);
    }
    return theme.normalising.heightPixel(autoStartCharging ? 15 : 35);
  };

  const buttonText = () => {
    if (!selectedEvCharger) {
      return t('evCharging.selectCharger');
    }
    if (!paymentMethod?.cardId && !companyBenefit?.benefitTargetUid) {
      return t('onboarding.addPaymentMethod');
    }
    if (!vehicle && !hasVehicles) {
      return t('onboarding.addVehicle');
    }
    if (!vehicle && hasVehicles) {
      return t('parking.parkingProcess.noVehicleSelected');
    }
    if (createChargingLoading || (chargingSuccess && !chargingSessionChange)) {
      return t('evCharging.startChargingLoading');
    }
    return t('button.start');
  };

  const handleStartCharging = async () => {
    const hasValidPaymentMethod = !!(
      paymentMethod?.cardId || companyBenefit?.memberGroupUid
    );

    if (isUserRestricted) {
      sheetModal('accountRestricted');
    } else if (!hasValidPaymentMethod && !vehicle && !onboardingCompleted()) {
      // Redirect to onboarding flow
      navigate('onboardingModal', {
        screen: 'welcome',
      });
    } else if (!hasValidPaymentMethod) {
      // Redirect to add payment method screen under account
      addCard();
    } else if (!vehicle && !hasVehicles) {
      // Redirect to add vehicle screen
      navigate('addVehicleParking');
    } else if (!vehicle && hasVehicles) {
      sheetModal('parkingVehicle');
    } else if (
      selectedEvCharger?.evse.status !== 'OCCUPIED' &&
      autoStartCharging
    ) {
      setCurrentScreen('CONNECT_CHARGER');
    } else {
      await createCharging();
    }
  };

  if (!isLoggedIn) {
    return (
      <ParkingSignInButton
        testID="park-zone-details-login"
        loading={loginLoading}
      />
    );
  }

  return (
    <FooterButtonContainer pb={0}>
      <VerticalDistributedContainer>
        <FooterButtonContainer pb={0}>
          <VehicleSelectDropDown />
          <PaymentCardDropDown />
        </FooterButtonContainer>
        <FooterButtonContainer pb={renderMarging()}>
          <Button
            buttonType={missingDetails ? 'secondary' : 'primary'}
            size="l"
            loading={
              createChargingLoading ||
              (chargingSuccess && !chargingSessionChange) ||
              loadingAddCard
            }
            text={buttonText()}
            testID="park-zone-start-charging-button"
            disabled={disabled}
            onPress={handleStartCharging}
          />
        </FooterButtonContainer>
      </VerticalDistributedContainer>
    </FooterButtonContainer>
  );
};

export default StartChargingButton;
