import React from 'react';
import { Headline3 } from 'aimo-ui';
import ParkAndChargeTariffItem from 'components/park/tariff/ParkAndChargeTariffItem';
import { useTranslation } from 'react-i18next';
import { StyledWrapper } from 'styles/ContainerStyles';
import { ParkingZoneType } from 'types/common';
import {
  ChargingStation,
  CountryCode,
  SegmentProps,
} from 'types/generatedSchemaTypes';
import useEVChargingPricing from 'hooks/useEVChargingPricing';

const ParkAndChargeTariffSection = ({
  chargingStations,
  selectedTariffId,
  countryCode,
  onPress,
}: {
  chargingStations: ChargingStation[];
  selectedTariffId?: string;
  countryCode: CountryCode;
  onPress: (
    tariffId: string,
    tariffName: string,
    type: ParkingZoneType,
    chargingSegment?: SegmentProps
  ) => void;
}) => {
  const { t } = useTranslation();

  const { EVChargingPricings } = useEVChargingPricing({
    chargingStations: chargingStations || [],
  });

  return (
    <StyledWrapper mb={30}>
      <Headline3 testID="tariff-parkAndCharge-title">
        {t('parking.tariff.typeCharge')}
      </Headline3>
      {EVChargingPricings?.map((EVChargingPricing) => {
        const segments = [
          EVChargingPricing.defaultSegment,
          ...(EVChargingPricing?.customerSegments || []),
        ];
        return segments
          .filter((segment) => segment?.accessType !== 'PRIVATE')
          .map((segment) => (
            <ParkAndChargeTariffItem
              segment={segment}
              countryCode={countryCode}
              isSelected={selectedTariffId === segment?.tariffPlanId}
              key={segment?.tariffPlanId}
              onPress={(tariffId, tariffName, type) =>
                onPress(tariffId, tariffName, type, segment as SegmentProps)
              }
            />
          ));
      })}
    </StyledWrapper>
  );
};

export default ParkAndChargeTariffSection;
