import { useReactiveVar } from '@apollo/client';
import PriceEstimateContext from 'context/PriceEstimateContext';
import React, { useContext, useEffect } from 'react';
import { activeElementType, selectedTariffId } from 'states/common';
import { getCustomerSegmentUid } from 'utils/parkUtils';
import useSelectedCompanyBenefit from './useSelectedCompanyBenefit';

const useFetchPriceEstimate = ({
  controllerRef,
  sessionId,
  parkingZoneUid,
  startTime,
  endTime,
}: {
  controllerRef: React.MutableRefObject<AbortController | undefined>;
  sessionId: string | undefined;
  parkingZoneUid: string;
  startTime: Date | undefined;
  endTime: Date;
}) => {
  const { getPricingEstimateWithoutId, getPricingEstimateWithId } =
    useContext(PriceEstimateContext);

  const selectedCustomerSegment = useReactiveVar(selectedTariffId);
  const customerSegmentUid = getCustomerSegmentUid(selectedCustomerSegment);
  const { transactionFee } = useSelectedCompanyBenefit();

  useEffect(() => {
    fetchPricing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkingZoneUid, endTime, customerSegmentUid]);

  const fetchPricing = () => {
    const controller = new AbortController();
    controllerRef.current = controller;
    if (!sessionId) {
      getPricingEstimateWithoutId({
        variables: {
          input: {
            parkingZoneUid,
            startDate: startTime,
            endDate: endTime ?? new Date(),
            customerSegmentUid,
            serviceFee: transactionFee,
          },
        },
        context: { fetchOptions: { signal: controllerRef?.current?.signal } },
        skip: !parkingZoneUid || activeElementType() === 'CHARGING',
      });
      return;
    }
    getPricingEstimateWithId({
      variables: {
        input: {
          sessionId: sessionId,
          endDate: endTime ?? new Date(),
          serviceFee: transactionFee,
        },
      },
      context: { fetchOptions: { signal: controllerRef?.current?.signal } },
    });
  };
};

export default useFetchPriceEstimate;
