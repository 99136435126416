import { Headline3, theme } from 'aimo-ui';
import React from 'react';
import styled from 'styled-components/native';

const PublicDisplayIdText = styled(Headline3)`
  font-size: ${theme.normalising.fontPixel(20)}px;
`;

const IDWrapper = styled.View<{ disabled: boolean }>`
  border-radius: 5px;
  padding: 10px;
  background-color: ${(p) =>
    p.disabled ? p.theme.colors.gray100 : p.theme.colors.boost.green};
  flex: 0 1 auto;
  max-width: 45%;
  align-items: center;
`;

const PublicDisplayId: React.FC<{
  publicDisplayId: string;
  isAvailable: boolean;
  testID: string;
}> = ({ publicDisplayId, isAvailable, testID }) => {
  return (
    <IDWrapper disabled={!isAvailable} testID={testID + '-publicId'}>
      <PublicDisplayIdText color={theme.colors.white}>
        {publicDisplayId}
      </PublicDisplayIdText>
    </IDWrapper>
  );
};

export default PublicDisplayId;
