import { useQuery } from '@apollo/client';
import { RouteProp, useRoute } from '@react-navigation/native';
import ParkMap from 'components/map/ParkMap';
import LongTermParking from 'components/park/longTermParking/LongTermParking';
import AccountContext from 'context/AccountContext';
import { GET_LTP_PARK_ZONES } from 'graphql/queries/getLTPParkZones';
import React, { useContext, useEffect } from 'react';
import { activeElement, searchInputActive } from 'states/common';
import {
  ParkZone,
  Query,
  QueryParkZonesWithLtpArgs,
} from 'types/generatedSchemaTypes';
import { LongTermStackParamList } from 'types/navigation';
import { getZonesWithVisibility } from 'utils/parkUtils';

const MapScreen: React.FC<{
  showBeforeMapLoaded?: boolean;
  isTesting?: boolean;
}> = ({ showBeforeMapLoaded = false, isTesting = false }) => {
  const { params } = useRoute<RouteProp<LongTermStackParamList, 'mapScreen'>>();
  const { data } = useQuery<Query, QueryParkZonesWithLtpArgs>(
    GET_LTP_PARK_ZONES,
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const {
    customer: { householdBenefits },
  } = useContext(AccountContext);

  const parkingZonesData = React.useMemo(
    () =>
      getZonesWithVisibility(
        (data?.parkZonesWithLTP?.content ?? []) as ParkZone[],
        data?.parkZonesWithLTP?.pmcVisibilities,
        householdBenefits,
        null,
        null
      ),
    [
      data?.parkZonesWithLTP?.content,
      data?.parkZonesWithLTP?.pmcVisibilities,
      householdBenefits,
    ]
  );

  useEffect(() => {
    if (params?.parkingZoneUid) {
      activeElement(params?.parkingZoneUid);
    }
  }, [params?.parkingZoneUid]);

  useEffect(() => {
    return () => {
      searchInputActive(false);
    };
  }, []);

  return (
    <>
      <ParkMap
        showBeforeMapLoaded={showBeforeMapLoaded}
        showLogo={false}
        parkingZonesData={parkingZonesData}
        showBackButton={true}
        withLTP={true}
      />
      <LongTermParking isTesting={isTesting} />
    </>
  );
};

export default MapScreen;
