import { Headline3, theme } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectedParkingSpaces } from 'states/common';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { ReservedParkingSpace } from 'types/generatedSchemaTypes';

type ReservedPermitsProps = {
  parkingSpaces: ReservedParkingSpace[];
  priceLabel: string;
  pmcId: string;
  name: string;
  testID?: string;
  handleNavigate: (pmcId: string, notAvailable: boolean) => void;
  memberGroupName?: string;
};

const ReservedPermits = ({
  parkingSpaces = [],
  priceLabel,
  name,
  testID,
  handleNavigate,
  pmcId,
  memberGroupName,
}: ReservedPermitsProps) => {
  const { t } = useTranslation();
  if (parkingSpaces?.length === 0) {
    return (
      <Headline3 center color={theme.colors.gray200}>
        {t('longTermParking.noParkingSpaces')}
      </Headline3>
    );
  }
  const availability = parkingSpaces?.filter(
    ({ parkingSpaceStatus }) => parkingSpaceStatus === 'AVAILABLE'
  ).length;
  const notAvailable = availability === 0;

  const handleNavigation = () => {
    selectedParkingSpaces(parkingSpaces);
    handleNavigate(pmcId, notAvailable);
  };
  return (
    <ColumnWithMargins mb={20}>
      <MenuItem
        testID={testID}
        menuLabel={name}
        withArrow
        noBorder
        subtitle={priceLabel}
        onPress={handleNavigation}
        background={theme.colors.gray50}
        disabled={notAvailable}
        pillText={notAvailable ? undefined : availability.toString()}
        smallLabel={
          notAvailable ? t('longTermParking.spotUnavailable') : undefined
        }
        bottomPillText={memberGroupName}
      />
    </ColumnWithMargins>
  );
};

export default ReservedPermits;
