import { useReactiveVar } from '@apollo/client';
import { AnimatedTime, Headline1, TimePicker, useTimeWheel } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import { addMinutes, startOfToday } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Animated from 'react-native-reanimated';
import { parkingEndingReminder } from 'states/persistInStorage';
import styled from 'styled-components/native';
import { isWeb } from 'utils/deviceUtils';

const START_TIME = isWeb ? '00:05' : '00:01';
const END_TIME = '01:00';

const ButtonIcon: React.FC<{ icon: string }> = ({ icon }) => (
  <Icon name={icon} size="xl" />
);

const TimeWrapper = styled.View`
  align-items: center;
  min-height: 15px;
`;

type MinuteSelectionProps = {
  setMinutes: (minutes: number) => void;
};

const MinuteDisplay = ({
  currentStepTextMinutes,
}: {
  currentStepTextMinutes: Animated.SharedValue<string>;
}) => {
  const { t } = useTranslation();

  if (isWeb) {
    return (
      <Headline1>{`${currentStepTextMinutes.value} ${t(
        'account.setReminder.minutesAhead'
      )}`}</Headline1>
    );
  }
  return (
    <AnimatedTime
      text={currentStepTextMinutes}
      onlyMinutes
      subText={t('account.setReminder.minutesAhead')}
      testID={'minute-time-display'}
    />
  );
};

const MinuteSelection = ({ setMinutes }: MinuteSelectionProps) => {
  const remindMinutesBefore = useReactiveVar(parkingEndingReminder);
  const initTime = useMemo(
    () => addMinutes(startOfToday(), remindMinutesBefore),
    [remindMinutesBefore]
  );

  useEffect(() => {
    minuteCount.value = initTime.getMinutes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    translateY,
    minuteCount,
    currentStepTextMinutes,
    currentStepText,
    config,
  } = useTimeWheel();

  const updateValue = () => {
    setMinutes(minuteCount.value);
  };

  return (
    <>
      <TimeWrapper>
        <MinuteDisplay currentStepTextMinutes={currentStepTextMinutes} />
      </TimeWrapper>
      <TimePicker
        config={config}
        translateY={translateY}
        minuteCount={minuteCount}
        timeChangeCallback={updateValue}
        startLimit={START_TIME}
        endLimit={END_TIME}
        endLimitMinutes={60}
        backIcon={() => <ButtonIcon icon="minus5" />}
        forwardIcon={() => <ButtonIcon icon="plus5" />}
        jumpTime={5}
        testID={'minute-time-picker'}
        currentStepText={currentStepText}
        hideWheel={isWeb}
      />
    </>
  );
};

export default MinuteSelection;
