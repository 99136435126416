import { useQuery } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import { Button, Headline3, Spinner } from 'aimo-ui';
import ParkingDetails from 'components/account/parking/ParkingDetails';
import PaymentDetails from 'components/account/parking/PaymentDetails';
import AimoMarker from 'components/map/AimoMarker';
import MapComponent from 'components/map/MapComponent';
import { Center } from 'components/park/styles/styles';
import ParkingBadge from 'components/receipt/ParkingBadge';
import Sheet from 'components/sheets/Sheet';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { GET_PARK_ZONE } from 'graphql/queries/getParkZone';
import { READ_PARKING_SESSION } from 'graphql/queries/readParkingSession';
import { READ_RECEIPT_BY_TRANSACTION_ID } from 'graphql/queries/readReceiptByTransactionId';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import useUpdateParkingMutation from 'hooks/useUpdateParkingMutation';
import SafeAreaHeaderContainer from 'navigation/SafeAreaHeaderContainer';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { selectedParkingSession, sheetModal } from 'states/common';
import { mapCoordinates } from 'states/map';
import { Divider } from 'styles/CommonLayoutStyles';
import {
  ButtonContainer,
  ColumnWithMargins,
  ScreenContainer,
} from 'styles/ContainerStyles';
import {
  BillingItem,
  ParkZone,
  ParkingSession,
  Query,
  QueryParkZoneArgs,
  QueryReadParkingSessionArgs,
  QueryReadReceiptByTransactionIdArgs,
  Receipt,
  UpdateParkingSessionActionType,
} from 'types/generatedSchemaTypes';
import { ParkingDetailsNavigationProp } from 'types/navigation';
import { getCorrectBillingItem } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { makePoint } from 'utils/mapUtils';
import { getLocationIconType } from 'utils/parkUtils';
import ExportPdfButton from '../../components/receipt/ExportPdfButton';

const { height } = Dimensions.get('window');
const ParkingHistoryDetailsScreen: React.FC<{
  showBeforeMapLoaded?: boolean;
}> = ({ showBeforeMapLoaded = false }) => {
  const { t } = useTranslation();
  const {
    flags: { anpr, retry_payment },
  } = useContext(FeatureFlagsContext);
  const showCameraRecognition = showFeatureFlag(anpr);
  const showRetry = showFeatureFlag(retry_payment);
  const paymentMethod = useSelectedPaymentMethod();
  const tabBarHeight = useBottomMenuHeight();

  const { params } = useRoute<ParkingDetailsNavigationProp['route']>();
  const { sessionId, parkingZoneUid, transactionId } = params;

  // This is fail or 0 credit payment, will be using sessionId here
  const {
    data: parkingSessionData,
    loading: parkingSessionLoading,
    error: parkingSessionError,
  } = useQuery<Query, QueryReadParkingSessionArgs>(READ_PARKING_SESSION, {
    variables: {
      sessionId: sessionId as string,
    },
    fetchPolicy: 'cache-and-network',
    skip: !sessionId || !!transactionId,
  });

  // Use transactionId to query receipt for normal parking history
  const {
    data: parkingReceiptData,
    loading: parkingReceiptLoading,
    error: parkingReceiptError,
  } = useQuery<Query, QueryReadReceiptByTransactionIdArgs>(
    READ_RECEIPT_BY_TRANSACTION_ID,
    {
      skip: !transactionId,
      variables: {
        transactionId: transactionId as string,
      },
    }
  );
  const parkingSessionId =
    parkingReceiptData?.readReceiptByTransactionId?.items?.[0]
      ?.parkingSessionId;

  const {
    data: parkZoneData,
    loading: parkZoneLoading,
    error: parkZoneError,
  } = useQuery<Query, QueryParkZoneArgs>(GET_PARK_ZONE, {
    variables: {
      uid: parkingZoneUid,
    },
    skip: !parkingZoneUid,
  });

  const retryParkingParams: {
    sessionId: string;
    actionType: UpdateParkingSessionActionType;
  } = {
    sessionId: sessionId as string,
    actionType: 'RETRY_PAYMENT',
  };

  const { updateParking, updateParkingLoading } = useUpdateParkingMutation({
    ...retryParkingParams,
    callback: () => sheetModal('retryPaymentResult'),
  });

  let loading, error;

  if (sessionId) {
    loading = parkingSessionLoading;
    error = parkingSessionError;
  } else {
    loading = parkingReceiptLoading || parkZoneLoading || updateParkingLoading;
    error = parkingReceiptError || parkZoneError;
  }

  const { address, locationType, parkingMethods } =
    parkZoneData?.parkZone || {};
  const { paymentState } = parkingSessionData?.readParkingSession || {};

  useEffect(() => {
    selectedParkingSession(sessionId);
    return () => {
      selectedParkingSession(null);
    };
  }, [sessionId]);

  useEffect(() => {
    let timeoutHandler: any;
    if (address?.marker?.coordinates) {
      const coordinates = makePoint(address?.marker?.coordinates);
      timeoutHandler = setTimeout(() => mapCoordinates(coordinates), 500);
    }
    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [address?.marker?.coordinates]);

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }
  if (error) {
    return <></>;
  }

  const onRetry = () => {
    if (sessionId) {
      updateParking({
        variables: {
          input: {
            ...retryParkingParams,
            cardId: paymentMethod?.cardId as string | undefined,
          },
        },
      });
    }
  };

  const isPaymentFailed =
    !!sessionId && (paymentState === 'Failed' || paymentState === 'Retrying');
  const licensePlate =
    parkingSessionData?.readParkingSession?.accessDevice?.value ||
    getCorrectBillingItem(
      parkingReceiptData?.readReceiptByTransactionId?.items as
        | [BillingItem]
        | undefined
    )?.[0].licensePlate ||
    '';
  const markerType = getLocationIconType(
    locationType,
    showCameraRecognition && parkingMethods?.anpr
  );
  const Markers = address?.marker?.coordinates ? (
    <AimoMarker
      key={`parking-spot-${parkingZoneUid}`}
      coordinate={makePoint(address?.marker?.coordinates)}
      markerType={markerType}
      activeIndex={1}
      onPress={() => undefined}
      testID={`${markerType}-marker-${parkingZoneUid}`}
      id={parkingZoneUid}
      isSelected={true}
    />
  ) : undefined;

  const StickyFooter = () => (
    <ButtonContainer>
      <Button
        text={t('button.retryPayment')}
        size="l"
        testID="retry"
        buttonType="primary"
        onPress={onRetry}
        loading={updateParkingLoading}
        disabled={paymentState !== 'Failed'}
      />
    </ButtonContainer>
  );

  const showRetryButton = isPaymentFailed && showRetry;

  const marginBottom = () => {
    if (showRetryButton) {
      return 85 + tabBarHeight;
    } else {
      return 20 + tabBarHeight;
    }
  };

  return (
    <>
      {isWeb && (
        <ScreenContainer>
          <MapComponent
            showBeforeMapLoaded={showBeforeMapLoaded}
            showLocation={false}
            showLogo={false}
            mapPadding={{ top: 0, left: 0, right: 0, bottom: height * 0.62 }}
            scrollEnabled={false}>
            {Markers}
          </MapComponent>
          <ParkingBadge
            licensePlate={licensePlate}
            testID="parking-history-details-info"
          />
        </ScreenContainer>
      )}
      <Sheet
        snappingPoints={[isWeb ? '80vh' : '100%']}
        hideHandle
        stickyFooter={showRetryButton && <StickyFooter />}>
        <>
          <SafeAreaHeaderContainer extraPadding="all">
            <Headline3 center>{t('account.parkingDetails')}</Headline3>
          </SafeAreaHeaderContainer>
          {!isWeb && (
            <ColumnWithMargins mt={20} mb={30}>
              <ParkingBadge
                licensePlate={licensePlate}
                testID="parking-history-details-info"
                noMap
              />
            </ColumnWithMargins>
          )}
          <ScrollView>
            <ColumnWithMargins ml={20} mr={20}>
              <ParkingDetails
                testID="parking-summary"
                parkingSession={
                  parkingSessionData?.readParkingSession as ParkingSession
                }
                receiptData={
                  parkingReceiptData?.readReceiptByTransactionId as Receipt
                }
                parkZone={parkZoneData?.parkZone as ParkZone}
              />
            </ColumnWithMargins>
            <Divider size={10} marginVertical={30} />
            <ColumnWithMargins ml={20} mr={20} mb={marginBottom()}>
              <PaymentDetails
                testID="payment-summary"
                receiptData={
                  parkingReceiptData?.readReceiptByTransactionId as Receipt
                }
                parkingSession={
                  parkingSessionData?.readParkingSession as ParkingSession
                }
                isPaymentFailed={isPaymentFailed}
                transactionId={transactionId}
              />
              {!showRetryButton && !!parkingSessionId && (
                <ExportPdfButton
                  isPaymentFailed={isPaymentFailed}
                  sessionId={parkingSessionId}
                />
              )}
            </ColumnWithMargins>
          </ScrollView>
        </>
      </Sheet>
    </>
  );
};

export default ParkingHistoryDetailsScreen;
