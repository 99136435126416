import { gql } from '@apollo/client';

export const SEARCH_PARK_ZONES_LTP_BY_TEXT = gql`
  query searchParkZonesLTPByText($input: ParkZoneSearchInput!) {
    searchParkZonesLTPByText(input: $input) {
      content {
        uid
        name
        locationType
        address {
          street
          number
          city
          marker
        }
        countryCode
        commercialZoneView {
          zoneCode
          name
        }
        parkingMethods {
          anpr
        }
        visibility
        serviceChannelCodes
      }
      pmcVisibilities {
        parkingZoneUid
        pmcId
        visibility
        hasCreditCardPayment
      }
    }
  }
`;
