import React from 'react';
import { parkingHistoryViewFilter, sheetModal } from 'states/common';
import { Text } from 'react-native';
import { BaseText, Headline3 } from 'aimo-ui';
import { useTranslation } from 'react-i18next';
import BlockerMenuItem from './BlockerMenuItem';
import { useNavigation } from '@react-navigation/native';
import { AccountScreenNavigationProp, RootNavProps } from 'types/navigation';
import { ChargingSession, ParkingSession } from 'types/generatedSchemaTypes';

const PaymentBlocker = ({
  failedPaymentSessions,
}: {
  failedPaymentSessions: (ParkingSession | ChargingSession)[];
}) => {
  const { navigate } = useNavigation<RootNavProps>();
  const { navigate: accountNavigate } =
    useNavigation<AccountScreenNavigationProp>();
  const { t } = useTranslation();
  const numberOfPayments = failedPaymentSessions.length;

  const navigateTo = () => {
    sheetModal('');
    if (numberOfPayments > 1) {
      parkingHistoryViewFilter('failed');
      accountNavigate('accountScreen', { screen: 'parkingHistory' });
    } else if (numberOfPayments === 1) {
      const parkingSession = failedPaymentSessions[0] as ParkingSession;
      const chargingSession = failedPaymentSessions[0] as ChargingSession;
      if (parkingSession?.sessionId) {
        navigate('parkingDetails', {
          sessionId: parkingSession.sessionId,
          parkingZoneUid: parkingSession.parkingZoneUid || '',
          closeButton: true,
          navigateToParkScreen: true,
        });
      } else if (chargingSession?.chargingSessionId) {
        accountNavigate('chargingHistoryDetails', {
          chargingSessionId: chargingSession.chargingSessionId as string,
          parkingZoneUid: chargingSession.parkingZoneUid as string,
        });
      }
    }
  };

  const TextComponent = () => {
    if (numberOfPayments === 1) {
      return (
        <Text>
          <BaseText>{t('account.remove.payments_front_one')}</BaseText>
          <Headline3>{t('account.remove.payments_one')}</Headline3>
          <BaseText>{t('account.remove.payments_tail_one')}</BaseText>
        </Text>
      );
    }
    return (
      <Text>
        <BaseText>{t('account.remove.payments_front_other')}</BaseText>
        <Headline3>
          {t('account.remove.payments_other', { count: numberOfPayments })}
        </Headline3>
        <BaseText>{t('account.remove.payments_tail_other')}</BaseText>
      </Text>
    );
  };

  if (numberOfPayments > 0) {
    return (
      <BlockerMenuItem
        text={TextComponent}
        testID="account-remove-payment-blocker"
        onPress={navigateTo}
      />
    );
  }
  return <></>;
};

export default PaymentBlocker;
