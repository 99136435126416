import { useQuery, useReactiveVar } from '@apollo/client';
import { READ_CARD } from '@queries/readCard';
import { useFocusEffect } from '@react-navigation/native';
import { Spinner } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import AccountContext from 'context/AccountContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import WebView, { WebViewNavigation } from 'react-native-webview';
import {
  creditCardUrlPaymentHighway,
  selectedPaymentCard,
  sheetModal,
} from 'states/common';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
import { Card, Query } from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';
import Logger from 'utils/Logger';
import {
  defineNetsUrlType,
  getNetsCardId,
  getParameterByName,
} from 'utils/urlUtils';

type PaymentMethodFormProps = {
  afterFailure?: () => void;
  afterSuccess?: () => void;
  isTesting?: boolean;
};

const contentStyle = {
  paddingBottom: 40,
};

const CreditCardFormContainer = styled.View`
  height: 100%;
`;

const CreditCardForm = ({
  afterSuccess,
  afterFailure,
  isTesting = false,
}: PaymentMethodFormProps) => {
  const {
    loading: customerLoading,
    refetchCustomer: fetchCustomerAfterSuccess,
  } = useContext(AccountContext);
  const creditCardUrl = useReactiveVar(creditCardUrlPaymentHighway);
  const [cardId, setCardId] = useState<string | undefined>(undefined);
  const [isLoadingFrame, setIsLoadingFrame] = useState<boolean>(true);
  const [cardVerificationInProgress, setCardVerificationInProgress] =
    useState<boolean>(false);

  const onAddCardSuccess = useCallback(() => {
    showToast('success.verifyPaymentCard', 'success');
    afterSuccess && afterSuccess();
  }, [afterSuccess]);

  const {
    loading: readCardLoading,
    startPolling: startPollingCard,
    stopPolling: stopPollingCard,
  } = useQuery<Query>(READ_CARD, {
    variables: {
      cardId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !cardId,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const { expMonth, expYear, isDefault, lastNums, type } =
        data.readCard as Card;
      if (data.readCard?.status === 'Active') {
        selectedPaymentCard({
          cardId: cardId || '',
          title: type || '',
          subtitle: lastNums ? ` **** ${lastNums}` : '',
          category: '',
          status: '',
          isDefault: isDefault === true,
          description: '',
          newCard: true,
          expMonth: expMonth || '',
          expYear: expYear || '',
        });
        onAddCardSuccess();
        sheetModal('paymentCard');
      }
    },
    onError: () => {
      selectedPaymentCard({
        cardId: '',
        title: '',
        subtitle: '',
        category: '',
        status: '',
        description: '',
        isDefault: false,
        newCard: false,
        expMonth: '',
        expYear: '',
      });
      showToast('error.verifyPaymentCard', 'error');
      if (afterFailure) {
        afterFailure();
      }
      sheetModal('');
    },
  });

  const handleUrlChange = useCallback(
    async (url: string) => {
      const state = defineNetsUrlType(url);
      switch (state) {
        case 'cancel':
          creditCardUrlPaymentHighway(undefined);
          sheetModal('');
          break;
        case 'success':
          // For NETS, verification happens asynchronous. Start polling the card to be update the status.
          const netsCardId = getNetsCardId(url);
          setCardId(netsCardId);
          selectedPaymentCard({
            cardId: netsCardId || '',
            title: '',
            subtitle: '',
            category: '',
            status: 'Verifying',
            isDefault: false,
            description: '',
            newCard: true,
            expMonth: '',
            expYear: '',
          });
          setCardVerificationInProgress(true);
          startPollingCard(3000);
          break;
        case 'failure':
          showToast('error.verifyPaymentCard', 'error');
          const reason = getParameterByName('reason', url);
          Logger.error(
            `Verify Payment Card | ${
              reason !== '' ? 'Reason: ' + reason : 'Url: ' + url
            }`
          );
          creditCardUrlPaymentHighway(undefined);
          sheetModal('');
          break;
      }
    },
    [startPollingCard]
  );

  useEffect(() => {
    if (isTesting && creditCardUrl) {
      handleUrlChange(creditCardUrl || '');
    }
  }, [handleUrlChange, creditCardUrl, isTesting]);

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        // This function will run when the screen loses focus
        // Clean up any resources or subscriptions here
        fetchCustomerAfterSuccess();
        setCardVerificationInProgress(false);
        setCardId(undefined);
        stopPollingCard();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  const onNavigationStateChange = async (event: WebViewNavigation) => {
    await handleUrlChange(event.url);
  };

  // If there's a type for target.contentWindow, could be used
  const onloadStateChange = async (event: any) => {
    setIsLoadingFrame(false);
    try {
      const url = event.target.contentWindow.location.href;
      await handleUrlChange(url);
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    return () => {
      creditCardUrlPaymentHighway(undefined);
    };
  }, []);

  if (customerLoading || readCardLoading || cardVerificationInProgress) {
    return (
      <StyledWrapper center testID="verify-credit-card">
        <Spinner />
      </StyledWrapper>
    );
  }

  if (creditCardUrl) {
    return (
      <CreditCardFormContainer>
        {isLoadingFrame && (
          <StyledWrapper center>
            <Spinner />
          </StyledWrapper>
        )}
        {isWeb ? (
          <iframe
            id="add-credit-card-iframe"
            frameBorder="0"
            height={500}
            width="100%"
            src={creditCardUrl}
            onLoad={onloadStateChange}
          />
        ) : (
          <WebView
            testID="add-credit-card-webview"
            useWebKit
            width={'100%'}
            height={'auto'}
            source={{ uri: creditCardUrl }}
            onNavigationStateChange={onNavigationStateChange}
            nestedScrollEnabled={true}
            style={contentStyle}
            onLoad={() => setIsLoadingFrame(false)}
          />
        )}
      </CreditCardFormContainer>
    );
  }
  return <></>;
};

export default CreditCardForm;
