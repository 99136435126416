import {
  useQuery,
  useReactiveVar,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import { GET_PARK_ZONE_WITH_CHARGING_STATIONS } from '@queries/getParkZoneWithChargingStations';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { GET_PARK_ZONE } from 'graphql/queries/getParkZone';
import useFeatureFlagEVCharging from 'hooks/useFeatureFlagEVCharging';
import { useContext } from 'react';
import { ongoingParkingSession } from 'states/common';
import { Query, QueryParkZoneArgs } from 'types/generatedSchemaTypes';
import { showFeatureFlag } from 'utils/featureFlagUtils';

const useParkingZoneWithEVCheck = (
  parkZoneUid: string,
  fetchPolicy?: WatchQueryFetchPolicy
) => {
  const isParkingZoneSet = parkZoneUid?.length > 0;
  const { sessionId: ongoingParkingSessionId } = useReactiveVar(
    ongoingParkingSession
  );
  const {
    flags: { ev_charging_public_ongoingParking },
  } = useContext(FeatureFlagsContext);
  const isOngoingParkingWithChargingStations =
    showFeatureFlag(ev_charging_public_ongoingParking) &&
    !!ongoingParkingSessionId;
  const showEVCharging =
    useFeatureFlagEVCharging() || isOngoingParkingWithChargingStations;
  const parkingZoneResult = useQuery<Query, QueryParkZoneArgs>(
    showEVCharging ? GET_PARK_ZONE_WITH_CHARGING_STATIONS : GET_PARK_ZONE,
    {
      variables: { uid: parkZoneUid },
      fetchPolicy: fetchPolicy || 'cache-first',
      skip: !isParkingZoneSet,
      errorPolicy: 'all',
    }
  );
  return parkingZoneResult;
};

export default useParkingZoneWithEVCheck;
