import React from 'react';
import styled from 'styled-components/native';
import Icon from '@components/icons/Icon';
import { Meta } from 'aimo-ui';

const QuickActionButtonContainer = styled.TouchableOpacity<{
  alignEnd: boolean;
  flexRow: boolean;
}>`
  justify-content: center;
  align-items: ${(p) => (p.alignEnd ? 'flex-end' : 'center')};
  ${({ flexRow }) => (flexRow ? 'flex-direction: row;' : undefined)}
`;

const IconLabel = styled(Meta)`
  margin-top: 10px;
`;

type QuickActionButtonProps = {
  testID?: string;
  onPress: () => void;
  iconName: string;
  coloring: string;
  iconLabel?: string;
  disabled?: boolean;
  alignEnd?: boolean;
  subtitle?: React.ReactNode;
  flexRow?: boolean;
};

export const QuickActionButton = ({
  testID,
  onPress,
  disabled = false,
  iconName,
  iconLabel,
  coloring,
  alignEnd = false,
  subtitle,
  flexRow = false,
}: QuickActionButtonProps) => (
  <QuickActionButtonContainer
    testID={testID}
    onPress={onPress}
    disabled={disabled}
    alignEnd={alignEnd}
    flexRow={flexRow}>
    {subtitle}
    <Icon name={iconName} color={coloring} />
    {iconLabel && <IconLabel color={coloring}>{iconLabel}</IconLabel>}
  </QuickActionButtonContainer>
);
