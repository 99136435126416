import { gql } from '@apollo/client';

export const GET_EV_CHARGING_PRICINGS = gql`
  query GetEVChargingPricings($locationIds: [ID]!, $lang: Lang) {
    getEVChargingPricings(locationIds: $locationIds, lang: $lang) {
      pricingSchemeId
      countryCode
      pricingEngine
      pricingType
      defaultSegment {
        uid
        title
        tariffPlanId
        showDefaultChips
        predefinedTicketsOnly
        parkingAllowed
        parkingAllowedFrom
        parkingAllowedUntil
        chargingPriceKwh
        notes {
          text
        }
        accessType
        accessMethod {
          instructions {
            en
            fi
            sv
            no
          }
          type
          url
        }
        predefinedTickets {
          sessionType
          uid
          type
          name
          startTime
          endTime
          duration
          price
          serviceFee
        }
      }
      customerSegments {
        uid
        title
        tariffPlanId
        showDefaultChips
        predefinedTicketsOnly
        parkingAllowed
        parkingAllowedFrom
        parkingAllowedUntil
        chargingPriceKwh
        notes {
          text
        }
        accessType
        accessMethod {
          instructions {
            en
            fi
            sv
            no
          }
          type
          url
        }
        predefinedTickets {
          sessionType
          uid
          type
          name
          startTime
          endTime
          duration
          price
          serviceFee
        }
      }
    }
  }
`;
