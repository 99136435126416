import { Meta, Spinner } from 'aimo-ui';
import ParkingContext from 'context/ParkingContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { SheetDetailsWrapper } from 'styles/ContainerStyles';

const Center = styled.View`
  height: 100px;
  align-items: center;
  justify-content: center;
`;

const ParkingZoneDetailsLoading: React.FC = () => {
  const { parkingZoneResult } = useContext(ParkingContext);
  const { t } = useTranslation();
  return (
    <SheetDetailsWrapper testID={'park-zone-details'}>
      <Center>
        {(!parkingZoneResult || parkingZoneResult.loading) && (
          <Spinner testID="park-zone-details-loader" />
        )}
        {(parkingZoneResult && !parkingZoneResult.loading && !parkingZoneResult?.data && (
          <Meta testID="park-zone-details-error-msg">
            {t('parkingZone.details.notFound')}
          </Meta>
        ))}
      </Center>
    </SheetDetailsWrapper>
  );
};

export default ParkingZoneDetailsLoading;
