import { useQuery, useReactiveVar } from '@apollo/client';
import { READ_PARKING_SESSION } from 'graphql/queries/readParkingSession';
import { Query, QueryReadParkingSessionArgs } from 'types/generatedSchemaTypes';
import { selectedParkingSession } from '../states/common';
import useIsAccountRestricted from './useIsAccountRestricted';

const useRetryPaymentResult = () => {
  const sessionId = useReactiveVar(selectedParkingSession) as unknown as string;
  const { refetchFailedSessions } = useIsAccountRestricted();
  const {
    data: parkingSessionData,
    loading: parkingSessionLoading,
    error: parkingSessionError,
    startPolling: parkingSessionStartPolling,
    stopPolling: parkingSessionStopPolling,
  } = useQuery<Query, QueryReadParkingSessionArgs>(READ_PARKING_SESSION, {
    variables: {
      sessionId,
    },
    skip: !sessionId,
    onCompleted: (data) => {
      const paymentState = data.readParkingSession?.paymentState;
      if (paymentState !== 'Failed' && paymentState !== 'Retrying') {
        refetchFailedSessions();
      }
    },
  });
  return {
    parkingSessionData,
    parkingSessionLoading,
    parkingSessionError,
    parkingSessionStartPolling,
    parkingSessionStopPolling,
  };
};

export default useRetryPaymentResult;
