import React, { useContext } from 'react';
import { Pmc } from 'types/generatedSchemaTypes';
import styled from 'styled-components/native';
import { BaseText, Button, Meta, theme } from 'aimo-ui';
import { IconWrapper, Row } from '../styles/styles';
import Icon from 'components/icons/Icon';
import { getMonthlyPrice } from 'utils/commonUtils';
import { useTranslation } from 'react-i18next';
import {
  selectedCityPmc,
  selectedPermit,
  sheetModal,
  cityPmcsScreenName,
} from 'states/common';
import useUserPermitVehicleCombination from 'hooks/useUserPermitVehicleCombination';
import AccountContext from 'context/AccountContext';

const ItemRow = styled.Pressable`
  flex-direction: row;
  flex: 1 1 100%;
  padding: 3px 0px;
  justify-content: space-between;
`;

const ItemColumn = styled.View<{ shrink?: boolean; ml?: number }>`
  flex-wrap: wrap;
  flex-direction: column;
  width: auto;
  overflow: hidden;
  flex-shrink: ${(p) => (p.shrink ? 1 : 0)};
  flex-grow: 0;
  margin-left: ${(p) => (p.ml ? p.ml : 0)}px;
`;

const ButtonWrapper = styled.View`
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 4px;
`;

const BaseTextShrink = styled(BaseText)`
  flex-shrink: 1;
  font-weight: 500;
`;

const MetaShrink = styled(Meta)``;

const StreetParkingPmcItem: React.FC<{
  item: any;
  canPurchase: boolean;
}> = ({ item, canPurchase }) => {
  const pmcItem = item as Pmc;
  const { id, name, prices, countryCode, physicalZoneUid } = pmcItem;
  const { customer } = useContext(AccountContext);
  const permitForParkZone = useUserPermitVehicleCombination(
    physicalZoneUid as string
  );
  const onItemSelect = () => {
    if (permitForParkZone) {
      selectedPermit(permitForParkZone?.id);
      sheetModal('duplicatePermitWarning');
      return;
    }

    selectedCityPmc(pmcItem);
    if (!customer?.strongAuthentication?.gedi) {
      cityPmcsScreenName('IDENTIFY');
      return;
    }
    cityPmcsScreenName(canPurchase ? 'BUY_PERMIT' : 'APPLY_PERMIT');
  };

  const priceString = getMonthlyPrice(prices, countryCode);
  const { t } = useTranslation();
  return (
    <ItemRow testID={`city-pmcs-search-item-${id}`}>
      <ItemColumn shrink>
        <Row mt={0} mb={0}>
          <BaseTextShrink numberOfLines={1} testID="city-pmcs-search-item-name">
            {name}
          </BaseTextShrink>
        </Row>
        <Row mt={0} mb={0}>
          <IconWrapper mr={10}>
            <Icon size="s" name="Service-Filled" color={theme.colors.gray300} />
          </IconWrapper>
          <MetaShrink testID="city-pmc-item-price" color={theme.colors.gray200}>
            {priceString}
          </MetaShrink>
        </Row>
      </ItemColumn>
      <ItemColumn ml={5}>
        <ButtonWrapper>
          <Button
            testID="city-pmc-button"
            buttonType="primary"
            size="s"
            text={canPurchase ? t('button.buy') : t('button.apply')}
            onPress={onItemSelect}
          />
        </ButtonWrapper>
      </ItemColumn>
    </ItemRow>
  );
};

export default StreetParkingPmcItem;
