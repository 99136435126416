import { useReactiveVar } from '@apollo/client';
import { BaseText, Headline1, Meta } from 'aimo-ui';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import {
  CardBackground,
  CutWrapper,
  LeftCut,
  RightCut,
} from 'components/common/CrickleCutCardBackground';
import ValidFromPicker from 'components/longTermParking/ValidFromPicker';
import { addMonths, isBefore } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RemainingValidityIndicator from 'screens/permit/RemainingValidityIndicator';
import { RowContainer } from 'screens/permit/styles';
import { country } from 'states/persistInStorage';
import {
  ColumnWithMargins,
  RowSpaceBetween,
  StyledImage,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { PredefinedTicket, UnifiedPermit } from 'types/generatedSchemaTypes';
import { currencyFormatter } from 'utils/commonUtils';
import { isItemPredefinedTicket, isItemSalesOrder } from 'utils/permitUtils';

const PrepaidTicketContent: React.FC<{
  testID: string;
  selectedPrepaidTicket: PredefinedTicket | UnifiedPermit;
}> = ({ testID, selectedPrepaidTicket }) => {
  const { t } = useTranslation();
  const countryCode = useReactiveVar(country);

  const priceString = () => {
    if (isItemSalesOrder(selectedPrepaidTicket)) {
      const totalAmount =
        selectedPrepaidTicket?.orderLines?.find(Boolean)?.lineTotal || 0;
      return currencyFormatter(totalAmount, countryCode);
    }
    if (isItemPredefinedTicket(selectedPrepaidTicket)) {
      return currencyFormatter(selectedPrepaidTicket.price || 0, countryCode);
    }
  };

  const prepaidInFuture =
    !isItemSalesOrder(selectedPrepaidTicket) &&
    isItemPredefinedTicket(selectedPrepaidTicket)
      ? isBefore(
          new Date(),
          new Date(selectedPrepaidTicket?.startTime as string)
        )
      : undefined;

  const dataByType = () => {
    if (isItemSalesOrder(selectedPrepaidTicket)) {
      const { orderLines, id } = selectedPrepaidTicket;
      const { pmc, validity } = orderLines?.find(Boolean) || {};
      const { parkZone, name } = pmc || {};
      const parkZoneName = `${parkZone?.commercialZoneView?.zoneCode || ''} ${
        parkZone?.commercialZoneView?.name || parkZone?.name
      }`;
      const validUntil = new Date(
        addMonths(new Date(validity?.validFrom || ''), 1)
      );
      return {
        name: name,
        parkZoneName,
        endTime: validUntil.toDateString(),
        id,
      };
    }
    if (isItemPredefinedTicket(selectedPrepaidTicket)) {
      const { name, parkZoneName, endTime, sessionId } = selectedPrepaidTicket;
      return {
        name,
        parkZoneName,
        endTime,
        sessionId,
      };
    }
  };

  const data = dataByType();

  return (
    <BottomSheetScrollViewCustom>
      <CardBackground>
        <StyledWrapper mb={27}>
          <StyledImage
            center={true}
            width={114}
            height={98}
            source={require('assets/images/permit.png')}
            testID={`${testID}-image`}
          />
        </StyledWrapper>
        <StyledWrapper mb={20}>
          <Headline1>{data?.name}</Headline1>
        </StyledWrapper>
        <StyledWrapper center>
          <BaseText center testID={`${testID}-parkzone-name`}>
            {data?.parkZoneName}
          </BaseText>
          <BaseText center>
            {t('parking.prepaidTickets.buyScreenInfo')}
          </BaseText>
          <BaseText center testID={`${testID}-pricing`}>
            {priceString()}
          </BaseText>
        </StyledWrapper>
        <CutWrapper>
          <LeftCut />
          <RightCut />
        </CutWrapper>
        <StyledWrapper center={true} maxWidth={360} mb={0}>
          <ColumnWithMargins ml={10} mr={10}>
            {prepaidInFuture &&
              isItemPredefinedTicket(selectedPrepaidTicket) && (
                <ValidFromPicker
                  validFrom={
                    new Date(selectedPrepaidTicket.startTime as string)
                  }
                  onConfirm={() => null}
                  testID={`${testID}-valid-selection`}
                />
              )}
            <RowSpaceBetween>
              <Meta>{t('services.validity')}</Meta>
              <RowContainer>
                <RemainingValidityIndicator
                  isRecurring={false}
                  expirationDate={data?.endTime}
                  permitStatus={undefined}
                  permitId={data?.sessionId || data?.id || ''}
                  isPrepaid={!isItemSalesOrder(selectedPrepaidTicket)}
                />
              </RowContainer>
            </RowSpaceBetween>
          </ColumnWithMargins>
        </StyledWrapper>
      </CardBackground>
    </BottomSheetScrollViewCustom>
  );
};

export default PrepaidTicketContent;
