import styled from 'styled-components/native';

const WarningWrapper = styled.View<{
  marginTop?: boolean;
  marginBottom?: boolean;
}>`
  background-color: ${(p) => p.theme.colors.infoYellow};
  padding: 20px;
  border-radius: 20px;
  margin-top: ${(p) => (p.marginTop ? 15 : 0)}px;
  margin-bottom: ${(p) => (p.marginBottom ? 15 : 0)}px;
`;

export default WarningWrapper;
