import FlatListCustom from 'components/sheets/FlatListCustom.web';
import React from 'react';
import { HslTicketContent } from 'types/generatedSchemaTypes';
import HslTicketListItem from './HslTicketListItem';

type HslTicketListProps = {
  tickets: HslTicketContent[];
};

const HslTicketList = ({ tickets }: HslTicketListProps) => {
  return (
    <FlatListCustom
      data={tickets}
      removeClippedSubviews={false}
      renderItem={({
        item,
        index,
      }: {
        item: HslTicketContent;
        index: number;
      }) => <HslTicketListItem ticket={item} index={index} />}
      keyExtractor={(item: HslTicketContent, index: number) =>
        `${item?.hslDetails.ticketId}-${index}`
      }
      testID="hsl-ticket-item-list"
    />
  );
};

export default HslTicketList;
