import React, { useMemo } from 'react';
import { CountryCode, Maybe, SegmentProps } from 'types/generatedSchemaTypes';
import TariffItem from 'components/park/tariff/TariffItem';
import { ParkingZoneType } from 'types/common';
import { getCurrencyString } from 'utils/commonUtils';
import Logger from 'utils/Logger';
import { showToast } from 'components/common/CustomToast';
import { useTranslation } from 'react-i18next';

const ParkAndChargeTariffItem = ({
  segment,
  countryCode,
  isSelected,
  onPress,
}: {
  segment?: Maybe<SegmentProps>;
  countryCode: CountryCode;
  isSelected: boolean;
  onPress: (
    tariffId: string,
    tariffName: string,
    type: ParkingZoneType
  ) => void;
}) => {
  const {
    tariffPlanId,
    notes,
    chargingPriceKwh,
    title,
    accessType,
    accessMethod,
  } = segment as SegmentProps;

  const { t } = useTranslation();

  const name = useMemo(() => {
    return (
      notes?.map((note) => note?.text).join(' ') ||
      `${chargingPriceKwh} ${getCurrencyString(countryCode)} /kWh` ||
      title ||
      '-'
    );
  }, [chargingPriceKwh, countryCode, notes, title]);

  const handleSetTariff = () => {
    onPress(tariffPlanId, name || '-', 'CHARGING');
  };

  //In some places charging is "Free" (e.g. parking zone SE-3211701), charging price is 0, so we should only catch
  // values that are not numbers or less than 0
  if (typeof chargingPriceKwh !== 'number' || chargingPriceKwh < 0) {
    Logger.error(
      'Invalid charging price',
      `chargingPriceKwh: ${chargingPriceKwh}, tariffPlanId: ${segment?.tariffPlanId}`
    );
    showToast(t('error.invalidChargingPrice'), 'info');
    return <></>;
  }

  return (
    <TariffItem
      isSelected={isSelected}
      key={`tariff-item-${tariffPlanId}`}
      tariffPlan={{
        id: tariffPlanId,
        name: name,
        accessType: accessType,
        accessMethod: accessMethod,
      }}
      countryCode={countryCode}
      type="CHARGING"
      onPress={handleSetTariff}
    />
  );
};

export default ParkAndChargeTariffItem;
