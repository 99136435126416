import { useNavigation } from '@react-navigation/native';
import { BaseText, Button, Meta, Spinner, Title, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import SpeechBubble from 'components/svgImage/SpeechBubble';
import SvgImage from 'components/svgImage/SvgImage';
import AccountContext from 'context/AccountContext';
import useBackButton from 'hooks/useBackButton';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledWrapper } from 'styles/ContainerStyles';
import { FooterWrapper, MainWrapper } from 'styles/ModalStackContainerStyles';
import { OnboardingNavProps, RootNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import styled from 'styled-components';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';

const TaskuparkkiMigratedText = styled(BaseText)`
  margin-bottom: 10px;
  letter-spacing: -0.16px; /*BaseText is not inheriting some props like this correctly*/
  line-height: 20px;
`;

const Description: React.FC<{ isTaskuparkkiMigrated: boolean }> = ({
  isTaskuparkkiMigrated,
}) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper maxWidth={350} center mb={10}>
      {isTaskuparkkiMigrated ? (
        <>
          <TaskuparkkiMigratedText
            center
            {...testIdentifiers(
              'onboarding-welcome-new-taskuparkki-customer-description-1'
            )}>
            {t('onboarding.descriptionTaskuparkkiMigrated.paragraph1')}
          </TaskuparkkiMigratedText>
          <TaskuparkkiMigratedText
            center
            {...testIdentifiers(
              'onboarding-welcome-new-taskuparkki-customer-description-2'
            )}>
            {t('onboarding.descriptionTaskuparkkiMigrated.paragraph2')}
          </TaskuparkkiMigratedText>
          <TaskuparkkiMigratedText
            center
            {...testIdentifiers(
              'onboarding-welcome-new-taskuparkki-customer-description-3'
            )}>
            {t('onboarding.descriptionTaskuparkkiMigrated.paragraph3')}
          </TaskuparkkiMigratedText>
        </>
      ) : (
        <BaseText
          center
          {...testIdentifiers('onboarding-welcome-new-customer-description')}>
          {t('onboarding.descriptionNewCustomer')}
        </BaseText>
      )}
    </StyledWrapper>
  );
};

const Buttons: React.FC = () => {
  const { navigate } = useNavigation<OnboardingNavProps>();
  const { t } = useTranslation();
  const { skipOnboarding } = useAfterAuthenticationRedirect();
  const {
    customer: { paymentCards, vehicles },
  } = useContext(AccountContext);

  const handleContinueSetup = async () => {
    const hasCards = paymentCards && paymentCards.length > 0;
    const hasVehicles = vehicles && vehicles.length > 0;
    if (!hasCards) {
      return navigate('addPaymentMethod');
    } else if (!hasVehicles) {
      return navigate('onboardingContinue');
    }
    await skipOnboarding();
  };

  return (
    <>
      <StyledWrapper center maxWidth={300} mb={20}>
        <Button
          {...testIdentifiers('onboarding-welcome-add-payment-method-button')}
          text={t('onboarding.continueSetup')}
          size="l"
          buttonType="secondary"
          onPress={handleContinueSetup}
        />
      </StyledWrapper>
      <StyledWrapper center maxWidth={300} mb={0}>
        <Button
          {...testIdentifiers('onboarding-skip-button')}
          text={t('onboarding.skipForNow')}
          size="l"
          buttonType="link"
          onPress={skipOnboarding}
        />
      </StyledWrapper>
      <SpeechBubble
        centerItem={
          <StyledWrapper maxWidth={isWeb ? 300 : undefined} mb={0}>
            <Meta>{t('onboarding.skipTitle')}</Meta>
          </StyledWrapper>
        }
        leftItem={<Icon name="Lamp" color={theme.colors.black} size="xl" />}
      />
    </>
  );
};

const OnboardingWelcome: React.FC = () => {
  const { t } = useTranslation();
  const {
    customer: { vehicles },
    decodedToken,
    loading: loadingCustomer,
  } = useContext(AccountContext);
  const data = vehicles || [];
  const { navigate } = useNavigation<RootNavProps>();

  const handleBackButton = () => {
    if (data.length > 0) {
      navigate('mainTabs', { screen: 'parkScreen' });
    }
    return true;
  };

  useBackButton(handleBackButton);

  if (loadingCustomer) {
    return (
      <MainWrapper vAlignCenter={true}>
        <StyledWrapper center>
          <Spinner />
        </StyledWrapper>
      </MainWrapper>
    );
  }

  const isCustomerExistingTaskuparkki =
    decodedToken?.extension_AccountType === 'EXISTING_TP';

  return (
    <>
      <MainWrapper vAlignCenter>
        <StyledWrapper maxWidth={350} center mb={37}>
          <SvgImage
            testID="onboarding-welcome-image"
            width={theme.normalising.widthPixel(131 * 1.25)}
            height={theme.normalising.heightPixel(109 * 1.25)}
            name={'OnboardingComplete'}
          />
        </StyledWrapper>
        <StyledWrapper maxWidth={350} center mb={30}>
          <Title center {...testIdentifiers('onboarding-welcome-title')}>
            {t('onboarding.welcomeToAimoFamily')}
          </Title>
        </StyledWrapper>
        <Description isTaskuparkkiMigrated={isCustomerExistingTaskuparkki} />
      </MainWrapper>
      <FooterWrapper>
        <Buttons />
      </FooterWrapper>
    </>
  );
};

export default OnboardingWelcome;
