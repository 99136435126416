import { gql } from '@apollo/client';

export const READ_SALES_ORDER_STATUS = gql`
  query ReadSalesOrderStatus($salesOrderId: String!) {
    readSalesOrder(salesOrderId: $salesOrderId) {
      uid
      orderStatus
      orderLines {
        orderLineId
        salesOrderId
        salesOrderStatus
        status
        charge {
          validity {
            validFrom
            validTo
          }
        }
      }
    }
  }
`;
