import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListContainer } from 'styles/ListStyle';
import VehicleAddItem from 'components/account/vehicle/VehicleAddItem';

export type VehicleAddSectionProps = { onPress: any; style: 'LIST' | 'BUTTON' };

const VehicleAddSection = ({ onPress, style }: VehicleAddSectionProps) => {
  const { t } = useTranslation();

  return (
    <ListContainer>
      <VehicleAddItem
        key="vehicle-type-REGULAR"
        item={{
          id: 'REGULAR',
          icon: 'Car-Outlined',
          testID: 'add-vehicle-REGULAR',
          label: t('vehicle.addVehicle.label'),
        }}
        indexValue={0}
        style={style}
        onPress={onPress}
      />
    </ListContainer>
  );
};

export default VehicleAddSection;
