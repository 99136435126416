import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Meta, theme } from 'aimo-ui';
import { StyledWrapper } from 'styles/ContainerStyles';
import { sheetModal } from 'states/common';

const AccountRemovalFailed = () => {
  const { t } = useTranslation();
  return (
    <StyledWrapper mb={0}>
      <StyledWrapper center>
        <Meta center testID="remove-failed-text" color={theme.colors.gray200}>
          {t('account.removeFailedText')}
        </Meta>
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <Button
          testID="close-button"
          size="l"
          text={t('button.close')}
          onPress={() => sheetModal('')}
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default AccountRemovalFailed;
