import { useMutation, useReactiveVar } from '@apollo/client';
import { showToast } from 'components/common/CustomToast';
import { CREATE_SALES_ORDER } from 'graphql/mutations/createSalesOrder';
import { confirmCreatePermitData, sheetModal } from 'states/common';
import { ConfirmCreatePermitDataProps } from 'types/common';
import {
  Mutation,
  MutationCreateSalesOrderArgs,
  Pmc,
} from 'types/generatedSchemaTypes';
import useCreatePermitInputFactory from './useCreatePermitInputFactory';

const useCreateSalesOrder = () => {
  const confirmCreateSalesOrder = useReactiveVar(confirmCreatePermitData);
  const { createInput } = useCreatePermitInputFactory();

  const [createSalesOrder, { loading: loadingSalesOrder }] = useMutation<
    Mutation,
    MutationCreateSalesOrderArgs
  >(CREATE_SALES_ORDER, {
    onCompleted: (data) => {
      const createData = data.createSalesOrder;
      const orderLine = createData?.orderLines?.find(Boolean);
      confirmCreatePermitData({
        ...confirmCreateSalesOrder,
        salesOrderId: createData?.uid,
        orderLineId: orderLine?.orderLineId,
        version: orderLine?.version,
        quantity: orderLine?.quantity,
      } as ConfirmCreatePermitDataProps);
      sheetModal('confirmPermit');
    },
    onError: () => showToast('error.permitCreationFailed', 'error'),
  });

  const createDraftSalesOrder = async ({
    pmcId,
    selectedPmc,
    fromParkZoneDetails = false,
    startDate,
  }: {
    pmcId: string | undefined;
    selectedPmc: Pmc | undefined;
    fromParkZoneDetails?: boolean;
    startDate: Date;
  }) => {
    if (confirmCreateSalesOrder?.salesOrderId) {
      sheetModal('confirmPermit');
    } else {
      const permitConfirmData = {
        pmcId,
        startDate,
        selectedPmc,
        fromParkZoneDetails,
      };
      const [permitInput, meta] = createInput(permitConfirmData);
      confirmCreatePermitData({ ...permitConfirmData, meta });
      await createSalesOrder({ variables: { input: permitInput } });
    }
  };
  return { createDraftSalesOrder, loadingSalesOrder };
};

export default useCreateSalesOrder;
