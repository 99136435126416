import { useReactiveVar } from '@apollo/client';
import useAllowedParkingTimes from 'hooks/useAllowedParkingTimes';
import useEVChargingTariff from 'hooks/useEVChargingTariff';
import useTariffs from 'hooks/useTariffs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  activeElement,
  selectedParkingZoneTab,
  selectedChargingPricingInfoSegmentTariffPlanId,
  selectedTariffId,
  selectedTariffName,
  sheetModal,
} from 'states/common';
import { ChargingStation } from 'types/generatedSchemaTypes';
import ZoneDetailsRow from '../ZoneDetailsRow';

const Tariff = ({
  chargingStations,
  borderTop,
  rightItem,
  headlineTitle,
  borderBottom,
}: {
  /* EV charging tariff */
  chargingStations?: ChargingStation[];
  borderTop?: boolean;
  rightItem?: React.ReactNode;
  headlineTitle?: boolean;
  borderBottom?: boolean;
}) => {
  const { t } = useTranslation();
  const selectedTab = useReactiveVar(selectedParkingZoneTab);
  const selectedTariff = useReactiveVar(selectedTariffName);
  const selectedTariffID = useReactiveVar(selectedTariffId);
  const selectedTariffChargingID = useReactiveVar(
    selectedChargingPricingInfoSegmentTariffPlanId
  );
  const parkingZoneUid = useReactiveVar(activeElement);
  const { pricingInfoLoading, pricingSegments } =
    useAllowedParkingTimes(parkingZoneUid);
  const { isLoadingEVTariffs } = useTariffs();
  const { EVChargingTariffs } = useEVChargingTariff({
    chargingStations: chargingStations || [],
  });

  const selectedChargingStation = useMemo(() => {
    return EVChargingTariffs?.find(
      (EVChargingTariff) =>
        EVChargingTariff.tariffPlan?.id === selectedTariffChargingID ||
        selectedTariffID
    );
  }, [EVChargingTariffs, selectedTariffID, selectedTariffChargingID]);

  const hasNoSegmentsAvailable =
    pricingSegments.length < 1 &&
    selectedChargingStation?.availableSpots === undefined &&
    !pricingInfoLoading;

  const titleText = () => {
    if (selectedTab === 'CHARGE') {
      return `${t('parking.tariff.typeCharge')} ${t('parking.tariff.tariff')}`;
    } else if (selectedTab === 'PARK_AND_CHARGE') {
      return `${t('parking.tariff.typeParkAndCharge')} ${t(
        'parking.tariff.tariff'
      )}`;
    }
    return selectedTariff
      ? `${selectedTariff} ${t('parking.tariff.tariff')}`
      : '';
  };

  if (hasNoSegmentsAvailable) {
    return null;
  }
  return (
    <ZoneDetailsRow
      title={titleText() || t('parking.tariff.selectTariff')}
      linkText={t('parking.tariff.title')}
      onPress={() => sheetModal('parkingTariffs')}
      borderTop={borderTop}
      rightItem={rightItem}
      loading={pricingInfoLoading || isLoadingEVTariffs}
      active={selectedTariff !== undefined}
      testIDprefix="tariff"
      headlineTitle={headlineTitle}
      borderBottom={borderBottom}
    />
  );
};

export default Tariff;
