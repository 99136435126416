import React from 'react';
import {
  BillingItem,
  CountryCode,
  Maybe,
  ParkingPrice,
  ParkingSession,
  Receipt,
} from 'types/generatedSchemaTypes';
import PriceBreakDown from 'components/receipt/PriceBreakDown';

const PermitPaymentInfo = ({
  sessionData,
  receiptData,
}: {
  sessionData?: Maybe<ParkingSession>;
  receiptData?: Maybe<Receipt>;
}) => {
  const checkPaymentDate = () => {
    const receipt = sessionData?.receipt || receiptData;
    if (!receipt) {
      return undefined;
    }
    return new Date(receipt.created as Date);
  };

  return (
    <PriceBreakDown
      paymentDate={checkPaymentDate()}
      price={
        (sessionData?.price || receiptData?.items) as
          | ParkingPrice
          | [BillingItem]
      }
      countryCode={
        sessionData?.countryCode || (receiptData?.countryCode as CountryCode)
      }
      isPaymentFailed={false}
    />
  );
};

export default PermitPaymentInfo;
