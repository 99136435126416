import { useReactiveVar } from '@apollo/client';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { isWeb } from 'utils/deviceUtils';
import { tabBarVisible } from '../states/common';

const useBottomMenuHeight = () => {
  const isTabBarVisible = useReactiveVar(tabBarVisible);
  try {
    const height = useBottomTabBarHeight();
    return (isWeb || isTabBarVisible)  ? height : 0;
  } catch (e) {
    return 0;
  }
};
export default useBottomMenuHeight;
