import { Button, Meta, theme } from 'aimo-ui';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { showFeatureFlag } from 'utils/featureFlagUtils';

const ItemRow = styled.Pressable`
  flex-direction: row;
  padding: 10px 20px 10px 25px;
  justify-content: space-between;
  background-color: ${theme.colors.gray50};
  border-radius: 10px;
  margin-bottom: 20px;
  align-items: center;
`;

const ButtonWrapper = styled.View`
  margin-left: 15px;
`;

const TextWrapper = styled.View`
  flex-direction: column;
  flex-shrink: 1;
`;

const GoToSignIn: React.FC = () => {
  const { t } = useTranslation();
  const { navigateToAuthentication, setReturnToMainPage } =
    useAfterAuthenticationRedirect();
  const {
    flags: { onboarding_v2 },
  } = useContext(FeatureFlagsContext);
  const useOnboardingV2 = showFeatureFlag(onboarding_v2);

  const handleOnPress = async () => {
    setReturnToMainPage();
    await navigateToAuthentication();
  };

  return (
    <ItemRow onPress={handleOnPress} testID="parking-to-sign-in">
      <TextWrapper>
        <Meta testID="to-sign-in-text">
          {useOnboardingV2
            ? t('parking.notSignedIn.description')
            : t('account.notSignedInDescriptionGoToSignIn')}
        </Meta>
      </TextWrapper>
      <ButtonWrapper>
        <Button
          text={
            useOnboardingV2
              ? t('parking.notSignedIn.button.short')
              : t('account.signIn')
          }
          testID="to-sign-in-button"
          buttonType="primary"
          size="s"
          onPress={handleOnPress}
        />
      </ButtonWrapper>
    </ItemRow>
  );
};

export default GoToSignIn;
