import { useNavigation } from '@react-navigation/native';
import { Button, Meta, theme } from 'aimo-ui';
import ParkingContext from 'context/ParkingContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { sheetModal } from 'states/common';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import { SetParamsProps } from 'types/navigation';
import { handleElPress } from 'utils/mapUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const StreetParkingLocationConfirmModal = () => {
  const { parkingZoneResult: parkingZone } = useContext(ParkingContext);
  const { uid = '' } = parkingZone?.data?.parkZone || {};
  const { t } = useTranslation();
  const { setParams }: SetParamsProps = useNavigation();

  return (
    <StyledWrapper mb={0}>
      <StyledWrapper testID={'street-parking-confirmation-info'} mb={60}>
        <Meta
          {...testIdentifiers('street-parking-confirmation-info-text')}
          color={theme.colors.gray200}
          center>
          {t('parking.streetParkingPrompt')}
        </Meta>
      </StyledWrapper>
      <>
        <ColumnWithMargins mb={12}>
          <Button
            text={t('button.confirm')}
            size="l"
            {...testIdentifiers('street-parking-confirm-button')}
            onPress={() => sheetModal('')}
          />
        </ColumnWithMargins>

        <Button
          text={t('parking.chooseAgain')}
          buttonType="tertiary"
          size="l"
          testID="street-parking-chooseAgain-button"
          onPress={() => {
            handleElPress(uid, { latitude: 0, longitude: 0 }, setParams);
            sheetModal('');
          }}
        />
      </>
    </StyledWrapper>
  );
};

export default StreetParkingLocationConfirmModal;
