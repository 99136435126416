import { theme } from 'aimo-ui';
import React, { ReactNode } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import styled from 'styled-components/native';
import { testIdentifiers } from 'utils/testIdentifiers';
import Icon from 'components/icons/Icon';

const border = (
  isSelected: boolean,
  notSelectedBorder: boolean,
  allowMultipleSelection: boolean,
  disabled: boolean
) => {
  let color = theme.colors.white;
  let size = 1;
  if (allowMultipleSelection) {
    if (isSelected) {
      // Multiple items can be selected and current item is selected
      color = theme.colors.boost.green;
      size = 2;
    } else if (notSelectedBorder && !disabled) {
      // Multiple items can be selected and not selected items that are not disabled have border
      color = theme.colors.gray100;
    }
  } else if (isSelected) {
    // Only one item can be selected and current item is selected
    color = theme.colors.boost.green;
    size = 3;
  } else if (notSelectedBorder) {
    // Only one item can be selected and not selected items have border
    color = theme.colors.gray200;
  }
  return `${size}px solid ${color}`;
};

const backgroundColor = (
  isSelected: boolean,
  notSelectedBorder: boolean,
  isLocked: boolean,
  allowMultipleSelection: boolean,
  disabled: boolean
) => {
  if (allowMultipleSelection) {
    return disabled ? theme.colors.gray50 : theme.colors.white;
  } else {
    return (isSelected || notSelectedBorder) && !isLocked
      ? theme.colors.white
      : theme.colors.gray50;
  }
};

const checkIconBorderColor = (isSelected: boolean, disabled: boolean) => {
  let color = theme.colors.gray200;
  if (isSelected) {
    color = theme.colors.transparent;
  } else if (disabled) {
    color = theme.colors.gray100;
  }
  return color;
};

const CardWrapper = styled.View<{
  isSelected: boolean;
  notSelectedBorder: boolean;
  alignCenter: boolean;
  isLocked: boolean;
  allowMultipleSelection: boolean;
  disabled: boolean;
}>`
  flex-direction: row;
  align-items: ${(p) => (p.alignCenter ? 'center' : 'flex-start')};
  padding: ${(p) => (p.allowMultipleSelection ? '10px 20px' : '17px')};
  margin-top: ${(p) => (p.allowMultipleSelection ? '20px' : '16px')};
  border-radius: ${(p) => (p.allowMultipleSelection ? '8px' : '10px')};
  background-color: ${(p) =>
    backgroundColor(
      p.isSelected,
      p.notSelectedBorder,
      p.isLocked,
      p.allowMultipleSelection,
      p.disabled
    )};
  border: ${(p) =>
    border(
      p.isSelected,
      p.notSelectedBorder,
      p.allowMultipleSelection,
      p.disabled
    )};
`;

const ChildContentWrapper = styled.View<{ paddingRight: number }>`
  width: 100%;
  padding-right: ${({ paddingRight }) => paddingRight}px;
  flex-direction: row;
  margin-left: 20px;
  justify-content: space-between;
  flex-shrink: 1;
`;

const CheckIconWrapper = styled.View<{
  isSelected: boolean;
  disabled: boolean;
}>`
  background-color: ${({ isSelected }) =>
    isSelected ? theme.colors.boost.green : theme.colors.transparent};
  border-width: 2px;
  border-color: ${(p) => checkIconBorderColor(p.isSelected, p.disabled)};
  border-radius: 4px;
`;

type SelectableModalItemProps = {
  isSelected: boolean;
  disabled: boolean;
  testID?: string;
  iconNode: ReactNode;
  children?: ReactNode;
  onPress: () => void;
  hideChildren?: boolean;
  iconNodeRight?: ReactNode;
  notSelectedBorder?: boolean;
  alignCenter?: boolean;
  isLocked?: boolean;
  paddingRight?: number;
  allowMultipleSelection?: boolean;
};

const SelectableModalContentItem = ({
  isSelected,
  disabled,
  testID,
  iconNode,
  children,
  onPress,
  hideChildren,
  iconNodeRight,
  notSelectedBorder = false,
  alignCenter = true,
  isLocked = false,
  paddingRight = 48,
  allowMultipleSelection = false,
}: SelectableModalItemProps) => {
  return (
    <TouchableWithoutFeedback
      {...testIdentifiers(testID as string)}
      onPress={() => (!disabled ? onPress() : undefined)}>
      <CardWrapper
        isLocked={isLocked}
        alignCenter={alignCenter}
        isSelected={isSelected}
        notSelectedBorder={notSelectedBorder}
        allowMultipleSelection={allowMultipleSelection}
        disabled={disabled}>
        {iconNode}
        {!hideChildren && (
          <ChildContentWrapper paddingRight={paddingRight}>
            {children}
          </ChildContentWrapper>
        )}
        {iconNodeRight}
        {allowMultipleSelection && (
          <CheckIconWrapper isSelected={isSelected} disabled={disabled}>
            <Icon
              name="check"
              size="m"
              color={isSelected ? theme.colors.white : theme.colors.transparent}
            />
          </CheckIconWrapper>
        )}
      </CardWrapper>
    </TouchableWithoutFeedback>
  );
};

export default SelectableModalContentItem;
