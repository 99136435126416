import { useNavigation } from '@react-navigation/native';
import VehicleCameraRecognitionSelector from 'components/account/vehicle/VehicleCameraRecognitionSelector';
import VehicleChargerPlugTypeSelector from 'components/account/vehicle/VehicleChargerPlugTypeSelector';
import VehicleCountrySelector from 'components/account/vehicle/VehicleCountrySelector';
import VehicleLicensePlateInput from 'components/account/vehicle/VehicleLicensePlateInput';
import VehiclePermitSelector from 'components/account/vehicle/VehiclePermitSelector';
import VehicleTypeSelector from 'components/account/vehicle/VehicleTypeSelector';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import React, { useCallback, useContext } from 'react';
import { StyledWrapper } from 'styles/ContainerStyles';
import {
  Vehicle,
  VehicleCountryCode,
  VehicleType,
} from 'types/generatedSchemaTypes';
import { RootNavProps } from 'types/navigation';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { isLicensePlateFormatValid } from 'utils/vehicleUtils';

type VehicleFormDetailsProps = {
  initialVehicle?: Vehicle;
  licensePlate: string;
  setLicensePlate: React.Dispatch<React.SetStateAction<string>>;
  showLicensePlateError?: boolean;
  permitsAmount?: number;
  vehicleType?: VehicleType;
  setVehicleType: React.Dispatch<React.SetStateAction<VehicleType | undefined>>;
  vehicleCountry?: VehicleCountryCode;
  setVehicleCountry: React.Dispatch<
    React.SetStateAction<VehicleCountryCode | undefined>
  >;
  chargerPlugType?: string;
  setChargerPlugType: React.Dispatch<React.SetStateAction<string | undefined>>;
  cameraRecognitionEnabled?: boolean;
  isOnboarding?: boolean;
  isParkingPermitFlow?: boolean;
};

const VehicleFormDetails = ({
  initialVehicle,
  licensePlate,
  setLicensePlate,
  showLicensePlateError,
  permitsAmount = 0,
  vehicleType,
  setVehicleType,
  vehicleCountry,
  setVehicleCountry,
  chargerPlugType,
  setChargerPlugType,
  cameraRecognitionEnabled = false,
  isOnboarding = false,
  isParkingPermitFlow = false,
}: VehicleFormDetailsProps) => {
  const { navigate } = useNavigation<RootNavProps>();
  const [showInvalidFormatInfo, setShowInvalidFormatInfo] =
    React.useState<boolean>(false);

  const {
    flags: { anpr, vehicle_registration_country, vehicle_type },
  } = useContext(FeatureFlagsContext);
  const showCameraRecognition = showFeatureFlag(anpr);
  const showRegistrationCountry = showFeatureFlag(vehicle_registration_country);
  const showVehicleType = showFeatureFlag(vehicle_type);

  const licensePlateCheck = useCallback(
    (licensePlateTrimmed: string) => {
      const isValidFormat = isLicensePlateFormatValid(
        licensePlateTrimmed,
        vehicleCountry
      );
      setShowInvalidFormatInfo(!isValidFormat);
    },
    [vehicleCountry]
  );

  return (
    <>
      <StyledWrapper mb={20}>
        <VehicleLicensePlateInput
          licensePlate={licensePlate}
          setLicensePlate={setLicensePlate}
          showError={showLicensePlateError}
          disabled={!!initialVehicle && licensePlate !== ''}
          onBlur={licensePlateCheck}
          showInvalidFormatInfo={showInvalidFormatInfo}
        />
      </StyledWrapper>
      {showVehicleType && !!initialVehicle && (
        <StyledWrapper mb={20}>
          <VehicleTypeSelector
            selectedType={vehicleType}
            setSelectedType={setVehicleType}
          />
        </StyledWrapper>
      )}
      {showRegistrationCountry && (
        <StyledWrapper mb={20}>
          <VehicleCountrySelector
            selectedCountry={vehicleCountry as VehicleCountryCode}
            setSelectedCountry={setVehicleCountry}
          />
        </StyledWrapper>
      )}
      {vehicleType === 'ELECTRIC' && (
        <StyledWrapper mb={20}>
          <VehicleChargerPlugTypeSelector
            selectedChargerPlugType={chargerPlugType}
            setSelectedChargerPlugType={setChargerPlugType}
          />
        </StyledWrapper>
      )}
      {showCameraRecognition && (
        <StyledWrapper mb={20}>
          <VehicleCameraRecognitionSelector
            selectedCameraRecognition={cameraRecognitionEnabled}
            isOnboarding={isOnboarding}
            isParkingPermitFlow={isParkingPermitFlow}
          />
        </StyledWrapper>
      )}
      {permitsAmount > 0 && (
        <StyledWrapper mb={20}>
          <VehiclePermitSelector
            selectedPermit={permitsAmount}
            onPress={() =>
              navigate('mainTabs', {
                screen: 'accountScreen',
                params: {
                  screen: 'permits',
                  params: {
                    licensePlate: initialVehicle?.licensePlate?.plateText,
                  },
                },
              })
            }
          />
        </StyledWrapper>
      )}
    </>
  );
};

export default VehicleFormDetails;
