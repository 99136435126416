import { theme } from 'aimo-ui';
import styled from 'styled-components/native';

type Values = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

type BGViewProps = {
  noPaddings?: boolean;
  noMargins?: boolean;
  paddings?: Values;
  margins?: Values;
  bgColor?: string;
  noShrink?: boolean;
  noFlex?: boolean;
  noRoundedCorners?: boolean;
};

const extractValue = (
  defaultValues: string,
  values?: Values,
  noValuesFlag?: boolean
) =>
  noValuesFlag
    ? '0px 0px 0px 0px'
    : values
    ? `${values.top || 0}px ${values.right || 0}px ${values.bottom || 0}px ${
        values.left || 0
      }px`
    : defaultValues;

const defaultPaddings = '20px 20px 40px 20px';
const defaultMargins = '20px 20px 20px 20px';

const BGView = styled.View<BGViewProps>`
  width: 100%;
  padding: ${({ paddings, noPaddings }) =>
    extractValue(defaultPaddings, paddings, noPaddings)};
  margin: ${({ margins, noMargins }) =>
    extractValue(defaultMargins, margins, noMargins)};
  background-color: ${({ bgColor }) => bgColor ?? theme.colors.white};
  ${({noRoundedCorners}) => noRoundedCorners ? '' :
    `border-top-left-radius: ${parseInt(theme.borderRadius.card, 10)}px;
    border-top-right-radius: ${parseInt(theme.borderRadius.card, 10)}px;`
  }
  ${(p) => !p.noFlex && `flex: 1 ${p.noShrink ? '0 auto' : ''}`};
`;

export default BGView;
