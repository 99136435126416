import { makeVar, ReactiveVar } from '@apollo/client';
import { theme } from 'aimo-ui';
import { AccountRemovalBlockers } from 'components/account/AccountRemovalPrevented';
import {
  HistoryTypeFilter,
  ParkingHistoryFilters,
} from 'hooks/useParkingHistoryFilter';
import { QuickAccessType } from 'navigation/ParkScreenQuickAccess';
import {
  ConfirmCreatePermitDataProps,
  ParkingZoneTab,
  ParkingZoneType,
} from 'types/common';
import {
  CountryCode,
  HslTicketPricing,
  LicensePlate,
  Maybe,
  Pmc,
  PricingProps,
  ReservedParkingSpace,
  ResumableParkingInfo,
  SegmentProps,
  Vehicle,
  VehicleType,
} from 'types/generatedSchemaTypes';
import {
  CreateParkingSessionWithPrepaidInput,
  OngoingParkingStateProps,
  ParkingPricingProps,
  ParkingSpaceData,
  SelectedHslTicketStateProps,
  SelectedPaymentCardStateProps,
  TabBarIndicator,
  ThrottleTimer,
  UnlockTariffItem,
} from 'types/states';

export const sheetModal: ReactiveVar<string> = makeVar<string>('');

export const secondSheetModal: ReactiveVar<string> = makeVar<string>('');

export const tabBarVisible: ReactiveVar<boolean> = makeVar<boolean>(true);

// This state shows current active element like parkZone, streetpark or other marker.
// Secondary uid will be used for example if type is a charging station ("CHARGING")
export const activeElement: ReactiveVar<string> = makeVar<string>('');
export const activeElementType: ReactiveVar<ParkingZoneType> =
  makeVar<ParkingZoneType>('');
export const activeElementSecondaryUid: ReactiveVar<string> =
  makeVar<string>('');

// This state keeps track if the park zone search bar input is active
export const searchInputActive: ReactiveVar<boolean> = makeVar<boolean>(false);
export const parkzoneSearchText: ReactiveVar<string> = makeVar<string>('');

export const sheetIndex: ReactiveVar<number> = makeVar<number>(0);
export const sheetExpanded: ReactiveVar<boolean> = makeVar<boolean>(false);
export const ongoingParkingSheetExpanded: ReactiveVar<boolean> =
  makeVar<boolean>(false);
export const ongoingChargingSheetExpanded: ReactiveVar<boolean> =
  makeVar<boolean>(false);

// This state keeps track if the start charging process has been initialized for an ongoing parking session
export const ongoingParkingStartChargingSheetExpanded: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const creditCardUrlPaymentHighway: ReactiveVar<string | undefined> =
  makeVar<string | undefined>(undefined);

export const selectedPaymentCard: ReactiveVar<SelectedPaymentCardStateProps> =
  makeVar<SelectedPaymentCardStateProps>({
    cardId: '',
    title: '',
    subtitle: '',
    category: '',
    status: '',
    description: '',
    isDefault: false,
    newCard: false,
    expMonth: '',
    expYear: '',
  });

export const parkingVehicleLicensePlate: ReactiveVar<string> =
  makeVar<string>('');
export const parkingPaymentCardId: ReactiveVar<string> = makeVar<string>('');

export const ongoingParkingSession: ReactiveVar<OngoingParkingStateProps> =
  makeVar<OngoingParkingStateProps>({
    sessionId: '',
    vehicleLabel: '',
    endTime: undefined,
    parkingSessionType: undefined,
  });

export const ongoingChargingSession: ReactiveVar<string> = makeVar<string>('');

// This state keeps track of the selected ongoing session.
// Used to display the proper sheet.
export const selectedOngoingSession: ReactiveVar<ParkingZoneType | 'MULTIPLE'> =
  makeVar<ParkingZoneType | 'MULTIPLE'>('');
// THis value keeps track of the selected sessionId when you select on from the list
// Used to show correct session when having multiple ongoing sessions
export const selectedSessionIdFromMultiple: ReactiveVar<string | null> =
  makeVar<string | null>(null);
export const editVehicleData: ReactiveVar<Vehicle> = makeVar<Vehicle>(
  {} as Vehicle
);
// Vehicle edit dialog was closed with changes discarded
export const editVehicleIsDiscarded: ReactiveVar<boolean> =
  makeVar<boolean>(false);
// Vehicle edit dialog was closed with the vehicle being deleted
export const editVehicleIsDeleted: ReactiveVar<boolean> =
  makeVar<boolean>(false);
export const editVehicleCameraRecognitionEnabled: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const parkingPricingInfo: ReactiveVar<ParkingPricingProps> =
  makeVar<ParkingPricingProps>({
    parkingType: 'normal',
    poolingId: undefined,
    poolingFull: false,
  });

export const isFullScreen: ReactiveVar<boolean> = makeVar<boolean>(false);
export const longTermParkingCompleted: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const selectedParkingSpaces: ReactiveVar<
  ReservedParkingSpace[] | undefined
> = makeVar<ReservedParkingSpace[] | undefined>(undefined);

export const parkingHistoryFilter: ReactiveVar<ParkingHistoryFilters> =
  makeVar<ParkingHistoryFilters>(undefined);

export const parkingHistoryViewFilter: ReactiveVar<HistoryTypeFilter> =
  makeVar<HistoryTypeFilter>('receipts');
export const parkingTypesVisible: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const selectedPermit: ReactiveVar<string | undefined> = makeVar<
  string | undefined
>(undefined);

export const selectedCityPmc: ReactiveVar<Pmc | null> = makeVar<Pmc | null>(
  null
);

export const cityPmcsScreenName: ReactiveVar<string | undefined> = makeVar<
  string | undefined
>(undefined);
export const confirmCreatePermitData: ReactiveVar<ConfirmCreatePermitDataProps> =
  makeVar<ConfirmCreatePermitDataProps>(undefined);

// This control what is currently the parking session being view / edit
export const selectedParkingSession: ReactiveVar<string | null> = makeVar<
  string | null
>(null);
export const currentParkingSession: ReactiveVar<string | null> = makeVar<
  string | null
>(null);

export const strongAuthFlow: ReactiveVar<boolean> = makeVar<boolean>(false);

export const strongAuthCompleted: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const selectedTariffId: ReactiveVar<string | undefined> = makeVar<
  string | undefined
>(undefined);

export const selectedChargingPricingInfoSegmentTariffPlanId: ReactiveVar<
  string | undefined
> = makeVar<string | undefined>(undefined);

export const selectedTariffName: ReactiveVar<string | undefined> = makeVar<
  string | undefined
>(undefined);

export const selectedChargingPricingInfoSegmentNameBasedOnNotes: ReactiveVar<
  string | undefined
> = makeVar<string | undefined>(undefined);

export const selectedChargingPricingInfo: ReactiveVar<
  PricingProps | undefined
> = makeVar<PricingProps | undefined>(undefined);
export const selectedChargingPricingInfoSegment: ReactiveVar<
  SegmentProps | undefined
> = makeVar<SegmentProps | undefined>(undefined);

export const selectedChargingPricingInfoSegmentCountryCode: ReactiveVar<CountryCode | null> =
  makeVar<CountryCode | null>(null);

export const selectedPricingSegment: ReactiveVar<
  Maybe<SegmentProps> | undefined
> = makeVar<Maybe<SegmentProps> | undefined>(undefined);

export const selectedHslPricing: ReactiveVar<HslTicketPricing | null> =
  makeVar<HslTicketPricing | null>(null);

export const expectingChargingReceipt: ReactiveVar<boolean> =
  makeVar<boolean>(false);
export const confirmPrepaidTicketData: ReactiveVar<
  CreateParkingSessionWithPrepaidInput | undefined
> = makeVar<CreateParkingSessionWithPrepaidInput | undefined>(undefined);

export const prepaidPurchaseCompleted: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const selectedHslTicket: ReactiveVar<SelectedHslTicketStateProps | null> =
  makeVar<SelectedHslTicketStateProps | null>(null);

export const accountTheme: ReactiveVar<string> = makeVar<string>(
  theme.colors.lightBg.orange
);

export const unlockTariffItem: ReactiveVar<UnlockTariffItem | undefined> =
  makeVar<UnlockTariffItem | undefined>(undefined);

export const currentCustomerCountry: ReactiveVar<CountryCode | null> =
  makeVar<CountryCode | null>(null);

export const locationModalShown: ReactiveVar<boolean> = makeVar<boolean>(false);

export const isSignUpFlow: ReactiveVar<boolean> = makeVar<boolean>(false);

export const updateInformation: ReactiveVar<{
  isMandatory: boolean;
  openFailedSessions?: boolean;
} | null> = makeVar<{
  isMandatory: boolean;
  openFailedSessions?: boolean;
} | null>(null);

export const isAppStartupScreenOpen: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const vehicleFromParkzone: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const vehicleSaved: ReactiveVar<boolean> = makeVar<boolean>(false);

export const acceptedTermsAfterFeatureCountryChange: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const acceptanceError: ReactiveVar<boolean> = makeVar<boolean>(false);

export const canStartParkingZoneUid: ReactiveVar<string> = makeVar<string>('');

export const resumableSession: ReactiveVar<ResumableParkingInfo | undefined> =
  makeVar<ResumableParkingInfo | undefined>(undefined);

export const resumeSessionPossible: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const accountRemovalBlockers: ReactiveVar<AccountRemovalBlockers> =
  makeVar<AccountRemovalBlockers>({
    ongoingParking: undefined,
    ongoingCharging: undefined,
    failedPayments: undefined,
    prepaidTickets: undefined,
    permits: undefined,
  });

export const accountRemoveWithWarnings: ReactiveVar<boolean> =
  makeVar<boolean>(false);
export const expandSheetAfterPurchase: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const selectedParkingZoneTab: ReactiveVar<ParkingZoneTab> =
  makeVar<ParkingZoneTab>('PARK');

export const selectedParkScreenQuickAccessItem: ReactiveVar<
  QuickAccessType | undefined
> = makeVar<QuickAccessType | undefined>(undefined);

export const throttleTimers: ReactiveVar<ThrottleTimer> =
  makeVar<ThrottleTimer>({});

export const blockCompanybenefitInEdit: ReactiveVar<string> =
  makeVar<string>('');

export const selectedParkingSpace: ReactiveVar<ParkingSpaceData | undefined> =
  makeVar<ParkingSpaceData | undefined>(undefined);

export const wasPermitTerminated: ReactiveVar<boolean> =
  makeVar<boolean>(false);

export const accountRestricted: ReactiveVar<boolean> = makeVar<boolean>(false);

// This state keeps track of the selected vehicle type to be used in the vehicle form
export const selectedVehicleTypeForm: ReactiveVar<VehicleType | undefined> =
  makeVar<VehicleType | undefined>(undefined);

export const isApproveTermsOpen: ReactiveVar<boolean> = makeVar<boolean>(true);

export const selectedLicensePlatesPermitRedeem: ReactiveVar<LicensePlate[]> =
  makeVar<LicensePlate[]>([]);

export const tabBarIndicators: ReactiveVar<TabBarIndicator[]> = makeVar<
  TabBarIndicator[]
>([]);
