import { ApolloError, gql, useMutation } from '@apollo/client';
import {
  RouteProp,
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { Banner, Button, Headline3, theme } from 'aimo-ui';
import ModalHeader from 'components/account/permit/ModalHeader';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import { showToast } from 'components/common/CustomToast';
import Shadow from 'components/common/Shadow';
import Icon from 'components/icons/Icon';
import PrepaidTicketContent from 'components/park/PrepaidTicketContent';
import PaymentCardDropDown from 'components/park/detail/PaymentCardDropDown';
import VehicleSelectDropDown from 'components/park/detail/VehicleSelectDropDown';
import Sheet from 'components/sheets/Sheet';
import SheetLayout, {
  FooterButtonContainer,
} from 'components/sheets/SheetLayout';
import ParkingContext from 'context/ParkingContext';
import { UPDATE_SALES_ORDER } from 'graphql/mutations/updateSalesOrder';
import useReadPurchasedPrepaidTickets from 'hooks/useReadPurchasedPrepaidTickets';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import useUpdateParkingMutation from 'hooks/useUpdateParkingMutation';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  parkingPaymentCardId,
  parkingVehicleLicensePlate,
} from 'states/common';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import {
  Mutation,
  MutationUpdateSalesOrderArgs,
  ParkingSession,
  PredefinedTicket,
  SalesOrderActionType,
  UnifiedPermit,
  UpdateParkingSessionActionType,
} from 'types/generatedSchemaTypes';
import { RootNavProps, ServicesStackParamList } from 'types/navigation';
import Logger from 'utils/Logger';
import { updateCache } from 'utils/cacheUtils';
import { isWeb } from 'utils/deviceUtils';
import { blockCompanyBenefitUsage } from 'utils/paymentUtils';
import { isItemPredefinedTicket, isItemSalesOrder } from 'utils/permitUtils';

const getSnappingPoints = () => {
  if (isWeb) {
    return ['75vh'];
  }
  return ['95%'];
};

const containerStyle = {
  flex: 1,
};

const WarningIcon = () => (
  <Icon name="Error-Outlined" color={theme.colors.black} />
);

const PrepaidTicketEdit: React.FC = () => {
  const { t } = useTranslation();
  const vehicle = useSelectedParkingVehicle();
  const { selectedPrepaidTicket, setSelectedPrepaidTicket, setCurrentScreen } =
    useContext(ParkingContext);
  const { goBack } = useNavigation<RootNavProps>();
  const {
    params: { ticketId },
  } = useRoute<RouteProp<ServicesStackParamList, 'prepaidEdit'>>();
  const { purchasedPrepaidTickets } = useReadPurchasedPrepaidTickets(true);
  const paymentMethod = useSelectedPaymentMethod();
  const [updateSalesOrder, { loading: updateLoading }] = useMutation<
    Mutation,
    MutationUpdateSalesOrderArgs
  >(UPDATE_SALES_ORDER, {
    update: (cache, { data }) => {
      const id = data?.updateSalesOrder?.uid;
      const storeObject = { __typename: 'UnifiedPermit', id };
      updateCache(cache, storeObject, {
        fragment: gql`
          fragment UnifiedPermitFragment on UnifiedPermit {
            creditCardId
            renewal
            orderStatus
            paymentState
          }
        `,
        data: {
          creditCardId: data?.updateSalesOrder?.creditCardId,
          renewal: data?.updateSalesOrder?.renewal,
          orderStatus: data?.updateSalesOrder?.orderStatus,
          paymentState: data?.updateSalesOrder?.paymentState,
        },
      });
    },
    onCompleted: () => {
      navigateBack();
    },
    onError: () => {
      showToast(t('error.updatePermit'), 'error');
      navigateBack();
    },
  });

  const getSessionId = () => {
    if (ticketId) {
      return ticketId;
    }
    if (isItemPredefinedTicket(selectedPrepaidTicket)) {
      return selectedPrepaidTicket.sessionId;
    }
  };
  const sessionId = getSessionId();
  const prepaidTicket = purchasedPrepaidTickets.find(
    (item) => item?.sessionId === sessionId
  );

  useFocusEffect(
    React.useCallback(() => {
      blockCompanyBenefitUsage('EDIT_PERMIT');
      return () => {
        blockCompanyBenefitUsage('');
      };
    }, [])
  );

  useFocusEffect(
    React.useCallback(() => {
      if (selectedPrepaidTicket && isItemSalesOrder(selectedPrepaidTicket)) {
        parkingVehicleLicensePlate(
          selectedPrepaidTicket?.orderLines?.find(Boolean)
            ?.registrationPlates?.[0]?.plateText || ''
        );
        blockCompanyBenefitUsage('EDIT_PERMIT');
      }
      return () => {
        parkingVehicleLicensePlate(undefined);
        blockCompanyBenefitUsage('');
      };
    }, [selectedPrepaidTicket])
  );

  useEffect(() => {
    if (selectedPrepaidTicket && isItemSalesOrder(selectedPrepaidTicket)) {
      parkingPaymentCardId(selectedPrepaidTicket.creditCardId || undefined);
      parkingVehicleLicensePlate(
        selectedPrepaidTicket?.orderLines?.find(Boolean)
          ?.registrationPlates?.[0]?.plateText || ''
      );
      return;
    }
    parkingVehicleLicensePlate(prepaidTicket?.accessDevice?.value || '');
  }, [prepaidTicket?.accessDevice?.value, selectedPrepaidTicket]);

  const navigateBack = () => {
    if (ticketId) {
      parkingVehicleLicensePlate('');
      goBack();
      return;
    }
    setCurrentScreen('SHOW_DETAILS');
    setSelectedPrepaidTicket(undefined);
  };

  const getNotificationText = (reassignmentsLeft: number) => {
    let notificationText = t(
      'success.updatePrepaidTicket.reassignmentsSuccess'
    );
    if (reassignmentsLeft === 0) {
      notificationText += t(
        'success.updatePrepaidTicket.reassignmentsRestricted'
      );
    } else if (reassignmentsLeft) {
      notificationText += t('success.updatePrepaidTicket.reassignmentsLeft', {
        count: reassignmentsLeft,
      });
    }
    notificationText += ` ${t(
      'success.updatePrepaidTicket.reassignmentsInformation'
    )}`;
    return notificationText;
  };

  const callback = (
    _sessionId: string,
    _actionType: UpdateParkingSessionActionType,
    updatedPrepaidTicket: ParkingSession
  ) => {
    const reassignmentsLeft = updatedPrepaidTicket.reassignments
      ?.reassignmentsLeft as number;
    const text = getNotificationText(reassignmentsLeft);
    showToast(text, reassignmentsLeft <= 1 ? 'info' : 'success', 10000);
    parkingPaymentCardId(undefined);
    parkingVehicleLicensePlate(undefined);
    navigateBack();
  };

  const errorCallback = (error: ApolloError) => {
    if (
      error.graphQLErrors.some(
        (e) => e.extensions?.code === 'PREPAID_TICKET_DUPLICATED'
      )
    ) {
      showToast(
        `${t('parking.prepaidTickets.hasValidTicket', {
          zone: prepaidTicket?.parkZoneName,
          vehicle: vehicle?.licensePlate?.plateText,
        })}`,
        'info'
      );
      Logger.warn(
        `Prepaid ticket ${sessionId} cannot be updated because user has an active prepaid ticket for the vehicle ${vehicle?.licensePlate?.plateText} in the park zone ${prepaidTicket?.parkZoneName}.`
      );
    }
  };

  const { updateParking, updateParkingLoading } = useUpdateParkingMutation({
    sessionId: prepaidTicket?.sessionId as string,
    actionType: 'UPDATE',
    isModal: true,
    licensePlate: vehicle?.licensePlate?.plateText,
    callback,
    errorCallback,
    skipRefetch: true,
  });

  const testID = 'prepaid-ticket-edit';

  const updatePrepaidTicket = () => {
    updateParking();
  };

  const updatePermitPress = () => {
    if (isItemSalesOrder(selectedPrepaidTicket)) {
      const { id, orderLines, renewal } = selectedPrepaidTicket;
      const { quantity, version, pmcId, orderLineId } = orderLines?.[0] || {};
      const { cardId: creditCardId } = paymentMethod || {};
      const updateInput = {
        salesOrderId: id,
        creditCardId: creditCardId,
        renewal,
        registrationPlate: {
          plateText: vehicle?.licensePlate?.plateText || '',
          countryCode: vehicle?.licensePlate?.countryCode,
        },
        quantity,
        version,
        pmcId,
        orderLineId,
        updateType: 'PATCH' as SalesOrderActionType,
      };

      updateSalesOrder({
        variables: {
          input: updateInput,
        },
      });
    }
  };

  const showReassignmentNotAllowed = useMemo(
    () => prepaidTicket?.reassignments?.reassignmentsLeft === 0,
    [prepaidTicket?.reassignments?.reassignmentsLeft]
  );

  if (ticketId) {
    return (
      <Sheet
        showBackDropIndex={0}
        snappingPoints={getSnappingPoints()}
        stickyFooter={
          <Shadow>
            <ColumnWithMargins>
              {showReassignmentNotAllowed && (
                <FooterButtonContainer pt={12} pb={0}>
                  <Banner
                    text={t('parking.prepaidTickets.editPrepaidRestricted', {
                      count: prepaidTicket?.reassignments
                        ?.waitingHours as number,
                    })}
                    type="warning"
                    iconComponent={WarningIcon}
                  />
                </FooterButtonContainer>
              )}
              <FooterButtonContainer pb={0}>
                <VehicleSelectDropDown
                  isDisabled={showReassignmentNotAllowed}
                />
              </FooterButtonContainer>
              <FooterButtonContainer>
                <Button
                  loading={updateParkingLoading}
                  disabled={
                    !vehicle ||
                    updateParkingLoading ||
                    showReassignmentNotAllowed
                  }
                  size="l"
                  onPress={updatePrepaidTicket}
                  text={t('button.saveChanges')}
                  testID={`${testID}-buy-button`}
                />
              </FooterButtonContainer>
            </ColumnWithMargins>
          </Shadow>
        }
        hideHandle={false}>
        <ColumnWithMargins
          mr={isWeb ? 0 : 20}
          ml={isWeb ? 0 : 20}
          style={containerStyle}>
          <ModalHeader title={t('permit.editPermit')} onBack={navigateBack} />
          <BottomSheetScrollViewCustom bottomThreshold={0}>
            <PrepaidTicketContent
              testID={testID}
              selectedPrepaidTicket={prepaidTicket as PredefinedTicket}
            />
          </BottomSheetScrollViewCustom>
        </ColumnWithMargins>
      </Sheet>
    );
  }

  return (
    <SheetLayout
      onHeadlinePress={() => null}
      testIDName={`${testID}-sheet`}
      onHeadlineButtonPress={navigateBack}
      headlineComponent={
        <Headline3 center>
          {isItemSalesOrder(selectedPrepaidTicket)
            ? t('permit.editPermit')
            : t('parking.prepaidTickets.editPrepaidTitle')}
        </Headline3>
      }>
      <PrepaidTicketContent
        testID={testID}
        selectedPrepaidTicket={
          isItemSalesOrder(selectedPrepaidTicket)
            ? (selectedPrepaidTicket as UnifiedPermit)
            : (prepaidTicket as PredefinedTicket)
        }
      />
      <FooterButtonContainer>
        <VehicleSelectDropDown />
        {isItemSalesOrder(selectedPrepaidTicket) && <PaymentCardDropDown />}
      </FooterButtonContainer>
      <FooterButtonContainer>
        <Button
          loading={updateParkingLoading || updateLoading}
          disabled={!vehicle || updateParkingLoading || updateLoading}
          size="l"
          onPress={
            isItemSalesOrder(selectedPrepaidTicket)
              ? updatePermitPress
              : updatePrepaidTicket
          }
          text={t('button.saveChanges')}
          testID={`${testID}-buy-button`}
        />
      </FooterButtonContainer>
    </SheetLayout>
  );
};

export default PrepaidTicketEdit;
