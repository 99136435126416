import { useReactiveVar } from '@apollo/client';
import { BaseText, Meta, Spinner, Toggle } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import useCreateAcceptanceMutation from 'hooks/useCreateAcceptanceMutation';
import useHasAcceptance from 'hooks/useHasAcceptance';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sheetModal } from 'states/common';
import { featureCountryCode } from 'states/persistInStorage';
import styled from 'styled-components/native';
import {
  CenterRow,
  SheetDetailsWrapper,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { CountryCode } from 'types/generatedSchemaTypes';
import { countMarginFromTop, isWeb } from 'utils/deviceUtils';
import { WEB_MENU_BAR_HEIGHT } from 'utils/navigationUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const ToggleText = styled.View`
  flex: 1 1 auto;
  margin-right: 20px;
`;

const ToggleInput = styled.View`
  flex: 0 0 auto;
`;

const Separator = styled.View`
  border-bottom-width: 3px;
  margin: 12px 12px;
  border-bottom-color: ${(p) => p.theme.colors.gray50};
`;

const StyledTitle = styled(BaseText)`
  margin-bottom: 5px;
`;

const StyledBaseText = styled(Meta)`
  color: ${(p) => p.theme.colors.gray200};
  flex-shrink: 1;
`;

const StyledTerms = styled.View<{ tabBarHeight: number }>`
  margin: ${(p) => (isWeb ? p.tabBarHeight : countMarginFromTop(0))}px
    ${isWeb ? 20 : 5}px 0px;
`;

export const ViewTermsItems = () => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <MenuItem
        menuLabel={t('account.terms.privacyPolicy')}
        testID="account-privacy-policy-link"
        withArrow={true}
        bold={false}
        onPress={() => sheetModal('privacyPolicy')}
      />
      <MenuItem
        menuLabel={t('account.terms.termsAndConditions')}
        testID="account-terms-conditions-link"
        withArrow={true}
        bold={false}
        onPress={() => sheetModal('termsAndConditions')}
      />
    </StyledWrapper>
  );
};

type ToggleRowProps = {
  title: string;
  info?: string;
  infoComponent?: React.ReactNode;
  value: boolean;
  setValue: (value: boolean) => void;
  separator?: boolean;
  testID: string;
  loading?: boolean;
  disabled?: boolean;
  isDarkToggle?: boolean;
};

export const ToggleRowItem = ({
  title,
  info,
  infoComponent: InfoComponent,
  value,
  setValue,
  separator = true,
  testID,
  loading,
  disabled = false,
  isDarkToggle = false,
}: ToggleRowProps) => {
  const { t } = useTranslation();
  return (
    <>
      <CenterRow isVerticalCenter={true}>
        <ToggleText>
          <StyledTitle {...testIdentifiers(`${testID}-title`)}>
            {t(title)}
          </StyledTitle>
          {info && <StyledBaseText>{t(info)}</StyledBaseText>}
          {InfoComponent}
        </ToggleText>
        {loading ? (
          <Spinner size={35} />
        ) : (
          <ToggleInput>
            <Toggle
              {...testIdentifiers(testID)}
              dark={isDarkToggle}
              value={value}
              onValueChange={() => setValue(!value)}
              disabled={disabled}
            />
          </ToggleInput>
        )}
      </CenterRow>
      {separator && <Separator />}
    </>
  );
};

const ViewTerms: React.FC = () => {
  const hasAcceptedMarketing = useHasAcceptance({ type: 'MARKETING_ALLOWED' });
  const [allowMarketing, setAllowMarketing] = useState(hasAcceptedMarketing);
  const { createAcceptance, loading } = useCreateAcceptanceMutation();
  const appCode = useReactiveVar(featureCountryCode);
  const handleUpdate = async (value: boolean) => {
    setAllowMarketing(value);
    await createAcceptance(value, 'MARKETING_ALLOWED', appCode as CountryCode);
  };

  return (
    <SheetDetailsWrapper pt={isWeb ? WEB_MENU_BAR_HEIGHT : 0}>
      <ViewTermsItems />
      <StyledWrapper mb={10}>
        <ToggleRowItem
          title="account.terms.marketingConsent"
          info="account.terms.marketingConsentInfo"
          value={allowMarketing}
          loading={loading}
          setValue={(value) => (!loading ? handleUpdate(value) : undefined)}
          separator={false}
          testID="allow-marketing-toggle"
        />
      </StyledWrapper>
    </SheetDetailsWrapper>
  );
};

export default ViewTerms;
