import { gql } from '@apollo/client';

export const GET_PARK_ZONES_BY_BOUNDING_BOX = gql`
  query ParkZonesByBoundingBox($input: ParkZonesQueryByBoundingBoxInput!) {
    parkZonesByBoundingBox(input: $input) {
      content {
        uid
        name
        locationType
        countryCode
        commerciallyActive
        commercialZoneView {
          zoneCode
          name
        }
        area
        parkingMethods {
          anpr
        }
        visibility
      }
    }
  }
`;
