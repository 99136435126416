import { useQuery } from '@apollo/client';
import { READ_CHARGING_SESSION } from 'graphql/queries/readChargingSession';
import { useEffect } from 'react';
import {
  Query,
  QueryReadChargingSessionArgs,
} from 'types/generatedSchemaTypes';
import useOngoingChargingSession from './useOngoingChargingSession';

const useReadChargingSession = (
  chargingSessionId?: string,
  startPolling?: boolean,
  skip?: boolean
) => {
  const { ongoingChargingSessionRefetch } = useOngoingChargingSession();

  const {
    data: chargingSessionData,
    loading: chargingSessionLoading,
    error: chargingSessionError,
    startPolling: chargingSessionStartPolling,
    stopPolling: chargingSessionStopPolling,
  } = useQuery<Query, QueryReadChargingSessionArgs>(READ_CHARGING_SESSION, {
    variables: {
      sessionId: chargingSessionId ?? '',
    },
    skip: !chargingSessionId || skip,
    fetchPolicy: 'cache-and-network',
  });

  const chargingTransactionId =
    chargingSessionData?.readChargingSession?.transactionId;

  const chargingPaymentError =
    !!chargingSessionData?.readChargingSession?.errorMessage;

  useEffect(() => {
    startPolling &&
    chargingSessionData &&
    !chargingTransactionId &&
    !chargingPaymentError
      ? chargingSessionStartPolling(2000)
      : chargingSessionStopPolling();
    return () => chargingSessionStopPolling();
  }, [
    chargingSessionData,
    chargingTransactionId,
    chargingSessionStartPolling,
    chargingSessionStopPolling,
    startPolling,
    chargingPaymentError,
  ]);

  useEffect(() => {
    if (startPolling && chargingTransactionId) {
      ongoingChargingSessionRefetch();
    }
  }, [chargingTransactionId, ongoingChargingSessionRefetch, startPolling]);

  return {
    chargingSessionData,
    chargingSessionLoading,
    chargingSessionStartPolling,
    chargingSessionStopPolling,
    chargingTransactionId,
    chargingPaymentError,
    chargingSessionError,
  };
};

export default useReadChargingSession;
