import { gql } from '@apollo/client';

export const CREATE_ACCEPTANCE = gql`
  mutation CreateAcceptanceV3($input: AcceptanceInput!) {
    createAcceptanceV3(input: $input) {
      type
      countryCode
      accepted
      lastChanged
    }
  }
`;
