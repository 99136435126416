import { useLazyQuery } from '@apollo/client';
import { Button } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import { READ_PARKING_RECEIPT_PDF } from 'graphql/queries/readParkingReceiptPdf';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Query,
  QueryReadParkingReceiptPdfArgs,
} from 'types/generatedSchemaTypes';
import Logger from 'utils/Logger';
import {
  convertBase64DataToPDF as convertBase64DataToPDFUrl,
  openPDF,
} from 'utils/commonUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const ExportPdfButton = ({
  sessionId,
  isPaymentFailed,
}: {
  sessionId: string;
  isPaymentFailed: boolean;
}) => {
  const { t } = useTranslation();

  const [readParkingReceiptPdf, { loading: loadingPdf }] = useLazyQuery<
    Query,
    QueryReadParkingReceiptPdfArgs
  >(READ_PARKING_RECEIPT_PDF, {
    fetchPolicy: 'cache-and-network',
    variables: {
      sessionId,
    },
    onCompleted: async (data) => {
      const base64PdfFile = data.readParkingReceiptPdf?.base64PdfFile;
      const type = 'application/pdf';
      if (!base64PdfFile) {
        return;
      }
      const pdfUrl = convertBase64DataToPDFUrl(base64PdfFile, type, sessionId);
      openPDF(pdfUrl, base64PdfFile, type, (error) => {
        showToast('error.downloadPdfFailed', 'error');
        Logger.error(`Saving pdf for session ${sessionId} failed | ${error}`);
      });
    },
    onError: () => showToast('error.downloadPdfFailed', 'error'),
  });

  const onExportReceiptPDF = () => {
    if (sessionId) {
      readParkingReceiptPdf({
        variables: {
          sessionId,
        },
      });
    }
  };

  return (
    <Button
      text={t('button.exportPDF')}
      size="l"
      disabled={isPaymentFailed}
      loading={loadingPdf}
      {...testIdentifiers('export-receipt-pdf')}
      onPress={onExportReceiptPDF}
    />
  );
};

export default ExportPdfButton;
