import { Headline1, Meta, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SheetDetailsWrapper,
  StyledImage,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';

export const ArrowIcon = () => (
  <Icon name="Chevron_Right" size="m" color={theme.colors.white} />
);

const EmptyView = ({ LTP }: { LTP?: boolean }) => {
  const { t } = useTranslation();
  return (
    <SheetDetailsWrapper pt={isWeb ? 60 : 30}>
      <StyledWrapper center maxWidth={isWeb ? 600 : undefined}>
        <StyledWrapper>
          <StyledImage
            center
            width={123}
            height={102}
            resizeMode="contain"
            source={require('assets/images/onboarding-payment-method.png')}
            defaultSource={require('assets/images/onboarding-payment-method.png')}
            testID={'company-permit-image-empty'}
          />
        </StyledWrapper>
        <StyledWrapper mb={20} center maxWidth={LTP ? undefined : 250}>
          <Headline1 center>
            {LTP
              ? t('longTermParking.noPermits')
              : t('account.companyPermits.headline')}
          </Headline1>
        </StyledWrapper>
        {!LTP && (
          <>
            <StyledWrapper mb={40} center>
              <Meta>{t('account.companyPermits.info')}</Meta>
            </StyledWrapper>
            <StyledWrapper>
              {/* <Button
                size="l"
                onPress={() => null}
                text={t('button.companyPermit')}
                buttonType="secondary"
                testID="company-permit-empty-add-new"
                iconComponent={ArrowIcon}
                iconRight
              /> */}
            </StyledWrapper>
          </>
        )}
      </StyledWrapper>
    </SheetDetailsWrapper>
  );
};

export default EmptyView;
