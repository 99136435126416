import { gql } from '@apollo/client';

export const STOP_CHARGING_SESSION = gql`
  mutation StopChargingSessionV2 {
    stopChargingSessionV2 {
      startTime
      endTime
      licensePlate
      parkingZoneUid
      priceInCents
      cardId
      energyInWatts
      countryCode
    }
  }
`;
