import { theme } from 'aimo-ui';
import React from 'react';
import styled from 'styled-components/native';
import { testIdentifiers } from 'utils/testIdentifiers';

const BGView = styled.View<{
  bgColor?: string;
  fullscreen?: boolean;
  borderRadius?: string;
}>`
  height: 100%;
  width: 100%;
  padding: 20px;
  background-color: ${(p) => p.bgColor || theme.colors.white};
  position: absolute;
  border-radius: ${(p) =>
    p.fullscreen
      ? '0px'
      : `${parseInt(p.borderRadius ?? p.theme.borderRadius.card, 10)}px`};
`;

const HandleView = styled.View`
  padding: 10px;
`;

const DragHandle = styled.View<{ handleColor?: string }>`
  align-self: center;
  width: 30px;
  height: 4px;
  border-radius: 4px;
  background-color: ${(p) => p.handleColor || theme.colors.gray100};
`;

export const BackgroundComponent = ({
  bgColor,
  snappingPoints,
  isMaxIndex = false,
  borderRadius,
}: {
  bgColor?: string;
  snappingPoints?: (string | number)[];
  isMaxIndex?: boolean;
  borderRadius?: string;
}) => {
  const fullScreen =
    isMaxIndex &&
    snappingPoints &&
    snappingPoints.length > 0 &&
    snappingPoints[snappingPoints.length - 1] === '100%';

  return (
    <BGView
      bgColor={bgColor}
      fullscreen={fullScreen}
      borderRadius={borderRadius}
    />
  );
};

type HandleProps = {
  handleColor?: string;
};

export const HandleComponent: React.FC<HandleProps> = ({ handleColor }) => {
  return (
    <HandleView {...testIdentifiers('bottom-sheet-handle')}>
      <DragHandle handleColor={handleColor} />
    </HandleView>
  );
};

export const NoHandleComponent = () => <HandleView />;

export const animationConfig = {
  damping: 280,
  overshootClamping: true,
  restDisplacementThreshold: 0.5,
  restSpeedThreshold: 2,
  stiffness: 350,
  mass: 0.5,
};
