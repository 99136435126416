import { useReactiveVar } from '@apollo/client';
import { theme } from 'aimo-ui';
import Sheet from 'components/sheets/Sheet';
import LongTermParkingProvider from 'context/LongTermParkingProvider';
import React, { useEffect, useState } from 'react';
import { activeElement } from 'states/common';
import styled from 'styled-components/native';
import { isIos } from 'utils/deviceUtils';
import ParkingZoneSearch from '../ParkingZoneSearch';
import ParkDetailsSheet from './ParkDetailsSheet';

const AdjustRadius = styled.View`
  margin-top: 0px;
  flex: 1;
`;
const LongTermParking = ({ isTesting = false }: { isTesting?: boolean }) => {
  const parkZoneActiveChange = useReactiveVar(activeElement);
  const [animateOnMount, setAnimateOnMount] = useState(false);
  const snappingPoints = ['45%', '85%'];

  useEffect(() => {
    // for some very weird reason, sheet closes sometimes on iPhone, this has to be in place on initial render
    const timeout = setTimeout(() => {
      setAnimateOnMount(true);
    }, 1000);
    activeElement('');
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (parkZoneActiveChange) {
    return (
      <LongTermParkingProvider>
        <ParkDetailsSheet />
      </LongTermParkingProvider>
    );
  }
  return (
    <Sheet
      bgColor={theme.colors.white}
      showBackDropIndex={1}
      animateOnMount={isIos ? animateOnMount : false}
      snappingPoints={snappingPoints}>
      <AdjustRadius>
        <ParkingZoneSearch withLTP isTesting={isTesting} />
      </AdjustRadius>
    </Sheet>
  );
};

export default LongTermParking;
