import { BaseText, theme } from 'aimo-ui';
import InfoBox from 'components/common/InfoBox';
import ParkingContext from 'context/ParkingContext';
import useGetMatchingAccessPermitForParkingZone from 'hooks/useGetMatchingAccessPermitForParkingZone';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnWithMargins } from 'styles/ContainerStyles';

const PoolingFullInfo: React.FC<{ parkingZoneUid?: string }> = ({
  parkingZoneUid,
}) => {
  const { t } = useTranslation();
  const { insideCompanyOutsideUserPays } =
    useGetMatchingAccessPermitForParkingZone(parkingZoneUid);
  const { currentScreen } = useContext(ParkingContext);

  if (insideCompanyOutsideUserPays && currentScreen === 'SHOW_DETAILS') {
    return (
      <ColumnWithMargins mr={10} ml={10} mt={10}>
        <InfoBox
          bgColor={theme.colors.infoYellow}
          padding={15}
          radius={20}
          margin={0}>
          <BaseText>{t('permit.access.poolingFull')}</BaseText>
        </InfoBox>
      </ColumnWithMargins>
    );
  }
  return <></>;
};

export default PoolingFullInfo;
