import { useNavigation } from '@react-navigation/core';
import { BottomButtonsLayout, theme } from 'aimo-ui';
import AccountContext from 'context/AccountContext';
import React, { useContext, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import { tabBarVisible } from 'states/common';

import CompanyPermitItem from 'components/account/companyBenefits/CompanyPermitItem';
import EmptyView from 'components/account/companyBenefits/EmptyView';
import CustomCarousel from 'components/common/CustomCarousel';
import { MarginView } from 'components/park/styles/styles';
import FlatListCustom from 'components/sheets/FlatListCustom';
import { SheetDetailsWrapper, StyledWrapper } from 'styles/ContainerStyles';
import { NestedTabNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';

const CompanyPermit: React.FC = () => {
  const { navigate, addListener } = useNavigation<NestedTabNavProps>();
  // const { t } = useTranslation();
  const {
    customer: { companyBenefits },
  } = useContext(AccountContext);

  useEffect(() => {
    addListener('beforeRemove', handleNavigation);
    return () => {
      addListener('beforeRemove', handleNavigation);
    };
  }, [addListener, navigate]);

  const handleNavigation = (e: any) => {
    const eventType = e?.data.action.type;
    // Show tab bar when navigates back
    if (eventType === 'GO_BACK' || eventType === 'POP') {
      tabBarVisible(true);
    }
  };

  if (!companyBenefits || companyBenefits.length < 1) {
    return <EmptyView />;
  }

  return (
    <SheetDetailsWrapper pt={isWeb ? 60 : 0}>
      <BottomButtonsLayout
        scrollable={false}
        contentBottom={
          <StyledWrapper center maxWidth={600}>
            {/* <Button
              size="l"
              onPress={() => null}
              text={t('button.companyPermit')}
              buttonType="secondary"
              testID="company-permit-add-new"
              iconComponent={ArrowIcon}
              iconRight
            /> */}
          </StyledWrapper>
        }>
        {isWeb ? (
          <FlatListCustom
            data={companyBenefits}
            removeClippedSubviews={false}
            renderItem={({ item, index }) => CompanyPermitItem(item, index)}
            ListFooterComponent={() => <MarginView />}
            keyExtractor={(item, index) => `${item?.uid}-${index}`}
          />
        ) : (
          <StyledWrapper center>
            <CustomCarousel
              carouselData={companyBenefits as any[]}
              width={theme.normalising.widthPixel(340)}
              height={theme.normalising.heightPixel(380)}
              enabled={(companyBenefits || []).length > 1}
              carouselItem={(item, index) => CompanyPermitItem(item, index)}
            />
          </StyledWrapper>
        )}
      </BottomButtonsLayout>
    </SheetDetailsWrapper>
  );
};

export default CompanyPermit;
