import { gql } from '@apollo/client';

export const UPDATE_CARD = gql`
  mutation updateCard(
    $cardId: String!
    $updateCardActionType: UpdateCardActionType!
    $description: String
  ) {
    updateCard(
      cardId: $cardId
      updateCardActionType: $updateCardActionType
      description: $description
    ) {
      cardId
    }
  }
`;
