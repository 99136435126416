import { theme } from 'aimo-ui';
import BackButton from 'components/common/BackButton';
import CloseButton from 'components/common/CloseButton';
import useBackButton from 'hooks/useBackButton';
import SafeAreaHeaderContainer from 'navigation/SafeAreaHeaderContainer';
import React from 'react';
import styled from 'styled-components/native';
import { SheetDetailsWrapper } from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

export const FooterButtonContainer = styled.View<{
  pt?: number;
  pb?: number;
  prPl?: number;
}>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => p.pt ?? theme.normalising.heightPixel(5)}px
    ${(p) => (!isWeb ? theme.normalising.widthPixel(p.prPl ?? 15) : 0)}px
    ${(p) => p.pb ?? theme.normalising.heightPixel(15)}px;
`;

export const VerticalDistributedContainer = styled.View`
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  flex: 1;
`;

const HeadlineWrapper = styled.Pressable`
  flex-shrink: 1;
`;
const ActionWrapper = styled.View`
  align-self: flex-start;
`;

export type SheetLayoutProps = {
  testIDName: string;
  headlineComponent?: JSX.Element | false;
  onHeadlinePress?: () => void;
  onHeadlineButtonPress?: () => void;
  children?: React.ReactNode;
  withBackButton?: boolean;
  onBackPress?: () => void;
  resetPadding?: boolean;
  leftAlignHeader?: boolean;
  extraPaddingValue?: number;
};

const SheetLayout = ({
  testIDName,
  headlineComponent,
  children,
  onHeadlinePress,
  onHeadlineButtonPress,
  withBackButton = false,
  onBackPress,
  resetPadding = false,
  leftAlignHeader = false,
  extraPaddingValue,
}: SheetLayoutProps) => {
  const backHandler = () => {
    if (onHeadlineButtonPress) {
      onHeadlineButtonPress();
      // Consume the event
      return true;
    }
    // Let the event bubble up
    return false;
  };

  useBackButton(backHandler);

  return (
    <SheetDetailsWrapper resetPadding={resetPadding}>
      <SafeAreaHeaderContainer
        extraPadding="bottom"
        leftAlignHeader={leftAlignHeader}
        extraPaddingValue={extraPaddingValue}
        headline={
          <HeadlineWrapper
            onPress={onHeadlinePress}
            {...testIdentifiers(`${testIDName}-headline`)}>
            {headlineComponent}
          </HeadlineWrapper>
        }
        leftContent={
          withBackButton && (
            <ActionWrapper>
              <BackButton
                buttonType="transparent"
                onPress={onBackPress}
                testID={`${testIDName}-back-button`}
              />
            </ActionWrapper>
          )
        }
        rightContent={
          onHeadlineButtonPress && (
            <ActionWrapper>
              <CloseButton
                onPress={onHeadlineButtonPress}
                testID={`${testIDName}-headline-button`}
              />
            </ActionWrapper>
          )
        }
      />
      {children}
    </SheetDetailsWrapper>
  );
};

export default SheetLayout;
