import { theme } from 'aimo-ui';
import { ParkingZoneType } from 'types/common';

export const getPriceInfoColor = (
  hasParkingPrice: boolean,
  hasChargingPrice: boolean,
  isPooling: boolean,
  selectedType: ParkingZoneType,
  isLoading: boolean
) => {
  const showChargingRates =
    selectedType === 'CHARGING' || selectedType === 'PARK_AND_CHARGE';

  if (isLoading) {
    return theme.colors.lightBg.green;
  }

  if (
    (!hasParkingPrice && !showChargingRates) ||
    (!hasChargingPrice && showChargingRates)
  ) {
    return theme.colors.boost.red;
  }
  return isPooling ? theme.colors.boost.purple : theme.colors.lightBg.green;
};
