import { useEffect, useState } from 'react';

const useMinimumLoadingTime = (
  loading: boolean,
  delay = 700,
  startDelay = 0
) => {
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setShowLoading(true);
      }, startDelay);
      setTimeout(() => {
        setShowLoading(false);
      }, delay);
    }
  }, [loading, delay, startDelay]);
  return { showLoading };
};
export default useMinimumLoadingTime;
