import { useQuery, useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Button, Headline1, Headline3, Meta, Spinner, theme } from 'aimo-ui';
import SheetLayout from 'components/sheets/SheetLayout';
import AccountContext from 'context/AccountContext';
import { format } from 'date-fns';
import { READ_CUSTOMER_APPLICATION } from 'graphql/queries/readPermitApplication';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  cityPmcsScreenName,
  selectedCityPmc,
  strongAuthFlow,
} from 'states/common';
import styled from 'styled-components/native';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import {
  Application,
  Query,
  QueryReadPermitApplicationArgs,
} from 'types/generatedSchemaTypes';
import { StreetParkingStackParamList } from 'types/navigation';
import { currencyFormatter, getLocale } from 'utils/commonUtils';

const StyledContainer = styled(StyledWrapper)<{ color: string }>`
  background-color: ${(p) => p.color};
  border-radius: 8px;
  padding: 16px;
`;

const RenderButtons: React.FC<{
  screenName: string | undefined;
  testId: string;
  hasApplication: Application;
}> = ({ screenName, testId, hasApplication }) => {
  const { t } = useTranslation();
  const { navigate } =
    useNavigation<StackNavigationProp<StreetParkingStackParamList>>();
  switch (screenName) {
    case 'BUY_PERMIT': {
      return (
        <Button
          size="l"
          buttonType="primary"
          onPress={() => navigate('purchase')}
          text={t('button.buyPermit')}
          testID={`${testId}-buy-permit-button`}
        />
      );
    }
    case 'APPLY_PERMIT': {
      if (hasApplication && hasApplication.queueCustomerStatus !== 'DECLINED') {
        return (
          <Button
            disabled={hasApplication.queueCustomerStatus === 'OPEN'}
            size="l"
            buttonType="primary"
            onPress={() => {
              cityPmcsScreenName('BUY_PERMIT');
              navigate('purchase');
            }}
            text={t('button.buyPermit')}
            testID={`${testId}-buy-permit-button`}
          />
        );
      }
      return (
        <Button
          size="l"
          buttonType="primary"
          onPress={() => navigate('apply')}
          text={t('button.applyPermit')}
          testID={`${testId}-apply-permit-button`}
        />
      );
    }
    case 'IDENTIFY': {
      return (
        <Button
          size="l"
          buttonType="secondary"
          onPress={() => {
            strongAuthFlow(true);
          }}
          text={t('button.identify')}
          testID={`${testId}-identify-button`}
        />
      );
    }
    case 'REIDENTIFY': {
      return (
        <Button
          size="l"
          buttonType="tertiary"
          onPress={() => {
            strongAuthFlow(true);
          }}
          text={t('button.reIdentify')}
          testID={`${testId}-reidentify-button`}
        />
      );
    }
    default:
      return null;
  }
};

const InfoBox: React.FC<{
  status: string;
  created: string;
}> = ({ status, created }) => {
  const { t } = useTranslation();
  switch (status) {
    case 'OPEN':
      return (
        <StyledContainer
          center
          mb={10}
          testID={'application-created'}
          color={theme.colors.lightBg.yellow}>
          <Meta>
            {t('cityPermits.applicationCreated', {
              applicationCreated: created,
            })}
          </Meta>
        </StyledContainer>
      );
    case 'ACCEPTED':
      return (
        <StyledContainer
          center
          mb={10}
          testID={'application-accepted'}
          color={theme.colors.lightBg.green}>
          <Meta>
            {t('cityPermits.applicationAccepted', {
              applicationCreated: created,
            })}
          </Meta>
        </StyledContainer>
      );

    case 'DECLINED':
      return (
        <StyledContainer
          center
          mb={10}
          testID={'application-declined'}
          color={theme.colors.lightBg.red}>
          <Meta>
            {t('cityPermits.applicationDeclined', {
              applicationCreated: created,
            })}
          </Meta>
        </StyledContainer>
      );
    default:
      return null;
  }
};

const StreetPermitActionScreen = () => {
  const screenName = useReactiveVar(cityPmcsScreenName);
  const selectedItem = useReactiveVar(selectedCityPmc);
  const testId = 'street-parking-screen-selector';
  const { t } = useTranslation();
  const { customer } = useContext(AccountContext);
  const { contactEmail } = customer;
  const { data, loading } = useQuery<Query, QueryReadPermitApplicationArgs>(
    READ_CUSTOMER_APPLICATION,
    {
      variables: {
        input: {
          customerGroupUid: selectedItem?.customerGroupUids?.[0] as string,
          email: contactEmail as string,
        },
      },
      fetchPolicy: 'network-only',
    }
  );
  const userApplication = React.useMemo(
    () => data?.readPermitApplication || null,
    [data]
  );

  let applicationCreated;

  if (userApplication) {
    applicationCreated = format(
      new Date(userApplication?.created || ''),
      'd.M.Y',
      {
        locale: getLocale(),
      }
    );
  }

  const closeScreen = () => {
    selectedCityPmc(null);
    cityPmcsScreenName('');
  };

  if (loading) {
    return (
      <StyledWrapper center>
        <Spinner />
      </StyledWrapper>
    );
  }

  const priceString = (selectedItem?.prices?.[0].monthlyPrice || 0) / 100;
  const userAddress = customer.strongAuthentication?.addresses?.[0];
  return (
    <SheetLayout
      resetPadding
      testIDName={testId}
      headlineComponent={
        <Headline3 testID={`${testId}-title`}>{selectedItem?.name}</Headline3>
      }
      onHeadlineButtonPress={closeScreen}>
      {userApplication && (
        <InfoBox
          status={userApplication.queueCustomerStatus as string}
          created={applicationCreated as string}
        />
      )}
      {selectedItem && (
        <StyledWrapper center>
          <Headline1 center testID={`${testId}-price`}>
            {currencyFormatter(priceString, 'FI')}
          </Headline1>
          <ColumnWithMargins ml={20} mr={20} mt={10}>
            <Meta center={true} color={theme.colors.gray200}>
              {t('cityPermits.permitBillingInfo')}
            </Meta>
          </ColumnWithMargins>
        </StyledWrapper>
      )}
      {screenName === 'REIDENTIFY' && (
        <>
          <StyledWrapper center mb={40} testID="reidentify-address">
            <Meta>
              <Headline3>Your home address is</Headline3>
              <Headline3
                color={
                  theme.colors.link
                }>{` ${userAddress?.streetName} ${userAddress?.streetNumber}`}</Headline3>
            </Meta>
          </StyledWrapper>
          <StyledWrapper center mb={36}>
            <Meta color={theme.colors.gray200} center testID="reidentify-meta">
              {t('legalAddressAuth.reidentifyInfo')}
            </Meta>
          </StyledWrapper>
        </>
      )}
      <ColumnWithMargins mt={15}>
        <RenderButtons
          screenName={screenName}
          testId={testId}
          hasApplication={userApplication as Application}
        />
      </ColumnWithMargins>
    </SheetLayout>
  );
};

export default StreetPermitActionScreen;
