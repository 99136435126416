import { isIos, isWeb } from './deviceUtils';

export const creditCardRedirectUrl = () => {
  if (isWeb) {
    return window.location.origin + '/credit-card-redirect';
  }
  return isIos ? 'com.aimoone' : 'com.aimoone.auth:///';
};

export const getParameterByName = (name: string, url: string) => {
  if (!url) {
    return '';
  }
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return '';
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getDomain = (url: string) => {
  if (!url) {
    return '';
  }
  // eslint-disable-next-line no-control-regex
  const regex = new RegExp('^(?:https?://)?(?:[^@/\n]+@)?(?:www.)?([^:/?\n]+)');
  const results = regex.exec(url);
  if (!results) {
    return '';
  }
  return results[1];
};

export const definePaymentHighwayUrlType = (urlString: string) => {
  if (!urlString || !urlString?.includes(creditCardRedirectUrl())) {
    return false;
  }
  if (getParameterByName('sph-cancel', urlString) === 'CANCEL') {
    return 'cancel';
  }
  if (getParameterByName('sph-success', urlString) === 'OK') {
    return 'success';
  }
  if (getParameterByName('sph-failure', urlString) !== '') {
    return 'failure';
  }
  return false;
};

export const defineNetsUrlType = (urlString: string) => {
  if (!urlString || !urlString?.includes(creditCardRedirectUrl())) {
    return false;
  }
  if (getParameterByName('status', urlString) === 'cancelled') {
    return 'cancel';
  }
  if (getParameterByName('status', urlString) === 'ok') {
    return 'success';
  }
  if (getParameterByName('status', urlString) === 'error') {
    return 'failure';
  }
  return false;
};

export const getCardVerificationParams = (urlString: string) => {
  const object = {
    sphRequestId: '',
    sphTokenizationId: '',
  };
  if (urlString?.includes(creditCardRedirectUrl())) {
    object.sphRequestId = getParameterByName('sph-request-id', urlString);
    object.sphTokenizationId = getParameterByName(
      'sph-tokenization-id',
      urlString
    );
  }
  return object;
};

export const getNetsCardId = (urlString: string) => {
  return getParameterByName('cardId', urlString);
};
