import React from 'react';
import { updateStateAndStorage } from 'utils/MMKVStorageUtils';
import { accessToken } from 'states/persistInStorage';
import { useReactiveVar } from '@apollo/client';
import { Button, Headline2, Meta } from 'aimo-ui';
import AuthManager from 'components/auth/AuthManager';

const MockedLogin = () => {
  const isLoggedIn = useReactiveVar(accessToken) !== '';
  const signInMocked = async () => {
    const token = await AuthManager.fetchDeviceToken();
    AuthManager.setDeviceToken(token);
    updateStateAndStorage(accessToken, 'accessToken', 'testMocked');
  };
  const signOutMocked = () => {
    updateStateAndStorage(accessToken, 'accessToken', '');
    AuthManager.setDeviceToken('');
  };
  return (
    <>
      <Headline2 center>Login mock</Headline2>
      <Meta center>{isLoggedIn ? 'Currently logged in' : 'Not logged in'}</Meta>
      {!isLoggedIn && <Button onPress={signInMocked} text="Mock Login" />}
      {isLoggedIn && <Button onPress={signOutMocked} text="Mock Logout" />}
    </>
  );
};

export default MockedLogin;
