import ParkingHistoryContent from 'components/history/HistoryContent';
import useParkingHistoryFilter from 'hooks/useParkingHistoryFilter';
import useReadFilteredSessions from 'hooks/useReadFilteredSessions';
import useReadReceipts from 'hooks/useReadReceipts';
import { concat } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import {
  ChargingSession,
  ParkingSession,
  Receipt,
} from 'types/generatedSchemaTypes';
import {
  chargingTypes,
  filterFailedOrFreeSessions,
  filterReceiptData,
  parkingTypes,
} from 'utils/parkUtils';

const ParkingHistoryScreen: React.FC = () => {
  const { parkingType: parkingTypeFilter } = useParkingHistoryFilter();
  const {
    data: parkingAndChargingData,
    loading: parkingAndChargingLoading,
    error: parkingAndChargingError,
    fetchMoreCallback,
    resetPage,
  } = useReadReceipts(concat(parkingTypes, chargingTypes));

  const {
    sessionsData,
    sessionsLoading,
    chargingSessions,
    isLast,
    fetchMoreCallback: fetchMoreSessions,
  } = useReadFilteredSessions();

  const combinedFailedSessions = useMemo(
    () =>
      filterFailedOrFreeSessions(
        [...sessionsData, ...chargingSessions] as ParkingSession[] &
          ChargingSession[],
        parkingTypeFilter
      ),
    [chargingSessions, sessionsData, parkingTypeFilter]
  );

  useEffect(() => {
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (parkingAndChargingError) {
    return <></>;
  }

  return (
    <ParkingHistoryContent
      fetchMore={fetchMoreCallback}
      failedSessions={
        (combinedFailedSessions || []) as ParkingSession[] & ChargingSession[]
      }
      isParkAndCharge
      sessionsFetchMore={fetchMoreSessions}
      sessionsIsLast={isLast || false}
      sessionsLoading={sessionsLoading}
      loading={parkingAndChargingLoading || sessionsLoading}
      isLast={parkingAndChargingData?.last || false}
      receiptData={filterReceiptData(
        (parkingAndChargingData?.content || []) as Receipt[],
        parkingTypeFilter
      )}
    />
  );
};

export default ParkingHistoryScreen;
