import { theme } from 'aimo-ui';
import styled from 'styled-components/native';
import React from 'react';

export const LeftCut = styled.View<{ color?: string }>`
  background-color: ${(p) => (p.color ? p.color : theme.colors.white)};
  height: 18px;
  width: 18px;
  right: 10px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
`;

export const RightCut = styled.View<{ color?: string }>`
  background-color: ${(p) => (p.color ? p.color : theme.colors.white)};
  height: 18px;
  width: 18px;
  left: 10px;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
`;

export const CutWrapper = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
`;

export const CardBackground = styled.View<{ backgroundColor?: string }>`
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : theme.colors.lightBg.green};
  overflow: hidden;
  border-radius: 20px;
  margin: ${theme.normalising.heightPixel(10)}px 0px 0px 0px;
  align-items: center;
  padding-top: ${theme.normalising.heightPixel(30)}px;
`;

type CrinkcleCutCardBackgroundProps = {
  backgroundColor?: string;
  cutColor?: string;
  aboveCutView: (() => JSX.Element) | React.ReactNode;
  belowCutView: (() => JSX.Element) | React.ReactNode;
};

const CrickleCutCardBackground = ({
  aboveCutView: AboveCutView,
  belowCutView: BelowCutView,
  backgroundColor = undefined,
  cutColor = undefined,
}: CrinkcleCutCardBackgroundProps) => (
  <CardBackground backgroundColor={backgroundColor}>
    {typeof AboveCutView === 'function' ? <AboveCutView /> : AboveCutView}
    <CutWrapper>
      <LeftCut color={cutColor} />
      <RightCut color={cutColor} />
    </CutWrapper>
    {typeof BelowCutView === 'function' ? <BelowCutView /> : BelowCutView}
  </CardBackground>
);

export default CrickleCutCardBackground;
