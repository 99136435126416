import { BaseText, Headline1, Meta, theme } from 'aimo-ui';
import CrickleCutCardBackground from 'components/common/CrickleCutCardBackground';
import { ChangeSpaceItem } from 'components/longTermParking/TitleCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
import { VoidCallBack } from 'types/common';
import {
  BillingInterval,
  ParkZone,
  SalesOrderStatus,
} from 'types/generatedSchemaTypes';
import MenuToggleItem from '../menuItems/MenuToggleItem';
import PermitTicketHeader from 'screens/permit/PermitTicketHeader';

const ListItemWrapper = styled.View`
  width: 100%;
  padding: 0 5px;
`;

type ParkingInfo = PermitDetailsProps & AutoRenewalViewProps;

type PermitDetailsProps = {
  parkZone: ParkZone;
  permitName: string;
  price: string;
  testID?: string;
  billingInterval: BillingInterval;
  parkingSpaceName?: string;
};

type AutoRenewalViewProps = {
  isRecurring: boolean;
  setIsRecurring: VoidCallBack;
  testID?: string;
  status: SalesOrderStatus;
  isInvoicing: boolean;
};

type ParkingSpaceViewProps = {
  parkingSpaceName: string;
  testID: string;
};

const SelectListItem = ({
  title,
  selection,
  onPress,
  testID,
  isAllowRecurring,
}: {
  title: string;
  selection: boolean;
  onPress: VoidCallBack;
  testID: string;
  noBorder?: boolean;
  isAllowRecurring: boolean;
}) => {
  const textColor = isAllowRecurring
    ? theme.colors.gray300
    : theme.colors.gray200;
  return (
    <ListItemWrapper>
      <MenuToggleItem
        color={textColor}
        value={selection}
        onValueChange={onPress}
        menuLabel={title}
        testID={testID}
        iconName={''}
        borderWidth={0}
        disabled={!isAllowRecurring}
      />
    </ListItemWrapper>
  );
};

const PermitDetails = ({
  testID,
  price,
  billingInterval,
  parkZone,
}: PermitDetailsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <PermitTicketHeader
        title={t('longTermParking.title')}
        subtitle={t('longTermParking.infoText')}
        testID={testID}
      />
      <StyledWrapper mb={theme.normalising.heightPixel(20)}>
        <BaseText center testID={`${testID}-garage-name`}>
          {parkZone?.commercialZoneView?.zoneCode
            ? `${parkZone.commercialZoneView.zoneCode}`
            : ''}
          {parkZone?.commercialZoneView?.name
            ? ` ${parkZone.commercialZoneView.name}`
            : ''}
        </BaseText>
      </StyledWrapper>
      <StyledWrapper center mb={theme.normalising.heightPixel(0)}>
        <Headline1 center testID={`${testID}-price`}>
          {price}
        </Headline1>
        <Meta
          testID={`${testID}-permit-recurring`}
          color={theme.colors.gray300}>
          {billingInterval
            ? t(`longTermParking.recurring.${billingInterval}`)
            : t('longTermParking.oneTimeFee')}
        </Meta>
      </StyledWrapper>
    </>
  );
};

const AutoRenewalView = ({
  isRecurring,
  testID,
  setIsRecurring,
  status,
  isInvoicing,
}: AutoRenewalViewProps) => {
  const { t } = useTranslation();
  const isValidStateForRecurring = status === 'ACTIVE';

  if (isInvoicing) {
    return <StyledWrapper />;
  }

  return (
    <StyledWrapper center maxWidth={320} mb={0}>
      <SelectListItem
        title={t('permit.recurringTitle')}
        selection={isRecurring}
        onPress={setIsRecurring}
        testID={`${testID}-recurring-selection`}
        isAllowRecurring={isValidStateForRecurring}
      />
    </StyledWrapper>
  );
};

const ParkingSpaceView = ({
  parkingSpaceName,
  testID,
}: ParkingSpaceViewProps) => {
  return (
    <StyledWrapper center maxWidth={320} mb={0}>
      <ChangeSpaceItem parkingSpaceName={parkingSpaceName} testID={testID} />
    </StyledWrapper>
  );
};

const PermitCard = ({
  parkZone,
  permitName,
  price,
  isRecurring,
  setIsRecurring,
  testID,
  billingInterval,
  status,
  parkingSpaceName,
  isInvoicing,
}: ParkingInfo) => (
  <CrickleCutCardBackground
    aboveCutView={() => (
      <PermitDetails
        parkZone={parkZone}
        permitName={permitName}
        price={price}
        testID={testID}
        billingInterval={billingInterval}
      />
    )}
    belowCutView={() => (
      <>
        <AutoRenewalView
          testID={testID}
          isRecurring={isRecurring}
          setIsRecurring={setIsRecurring}
          status={status}
          isInvoicing={isInvoicing}
        />
        {parkingSpaceName && (
          <ParkingSpaceView
            parkingSpaceName={parkingSpaceName}
            testID="parking-space-item"
          />
        )}
      </>
    )}
  />
);

export default PermitCard;
