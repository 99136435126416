import SafeAreaHeaderContainer from 'navigation/SafeAreaHeaderContainer';
import styled from 'styled-components/native';
import { isWeb } from 'utils/deviceUtils';

export const StackHeaderContainer = styled(SafeAreaHeaderContainer)<{
  headerMode: 'screen' | 'float';
  tabBarHeight: number;
  background: string;
  justifyContent?: 'flex-start' | 'space-between';
  alignItems?: 'center' | 'flex-start';
}>`
  margin-top: ${(p) => (isWeb ? p.tabBarHeight : 0)}px;
  align-items: ${({ alignItems }) => alignItems ?? 'center'};
  align-content: center;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  flex-direction: row;
  position: ${({ headerMode }) =>
    headerMode === 'float' && !isWeb ? 'absolute' : 'relative'};
  background-color: ${({ background, headerMode }) =>
    headerMode === 'float' ? 'transparent' : background};
`;

export const TitleWrapper = styled.Pressable<{ mr: boolean }>`
  flex: 0 1 auto;
  margin: 0px 15px;
  width: 100%;
  margin-right: ${(p) => (p.mr ? 48 : 0)}px;
`;
