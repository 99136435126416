import { useReactiveVar } from '@apollo/client';
import { useFocusEffect, useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { BaseText, Button, Headline1, Headline3 } from 'aimo-ui';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import {
  CardBackground,
  CutWrapper,
  LeftCut,
  RightCut,
} from 'components/common/CrickleCutCardBackground';
import { ChangeSpaceItem } from 'components/longTermParking/TitleCard';
import ValidFromSelector from 'components/longTermParking/ValidFromSelector';
import PaymentCardDropDown from 'components/park/detail/PaymentCardDropDown';
import VehicleSelectDropDown from 'components/park/detail/VehicleSelectDropDown';
import SheetLayout, {
  FooterButtonContainer,
} from 'components/sheets/SheetLayout';
import AccountContext from 'context/AccountContext';
import ParkingContext from 'context/ParkingContext';
import useBottomMenuHeight from 'hooks/useBottomMenuHeight';
import useCreateSalesOrder from 'hooks/useCreateSalesOrder';
import useIsDesktop from 'hooks/useIsDesktop';
import useReadPurchasedPrepaidTickets from 'hooks/useReadPurchasedPrepaidTickets';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  accountRestricted,
  blockCompanybenefitInEdit,
  confirmCreatePermitData,
  confirmPrepaidTicketData,
  prepaidPurchaseCompleted,
  selectedOngoingSession,
  selectedParkingSpace,
  selectedParkingSpaces,
  sheetModal,
} from 'states/common';
import {
  ColumnWithMargins,
  StyledImage,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { ParkzonePmcUnion } from 'types/common';
import { Pmc, ReservedParkingSpace } from 'types/generatedSchemaTypes';
import { RootStackParamList } from 'types/navigation';

import {
  currencyFormatter,
  getPriceForSalesOrder,
  isSalesOrderFree,
} from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import {
  durationText,
  isItemPmc,
  isItemPredefinedTicket,
} from 'utils/permitUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import { parseISO8601Duration } from 'utils/timeUtils';

const containerStyle = {
  flex: 1,
};

const PrepaidTicketBuyView: React.FC = () => {
  const { t } = useTranslation();
  const { licensePlate } = useSelectedParkingVehicle() || {};
  const { cardId: creditCardId } = useSelectedPaymentMethod() || {};
  const {
    parkingConditions: { vehicle, paymentMethod, parkingZoneUid },
    parkingZoneResult: parkingZone,
    selectedPrepaidTicket,
    setSelectedPrepaidTicket,
    setCurrentScreen,
  } = useContext(ParkingContext);
  const { purchasedPrepaidTickets } = useReadPurchasedPrepaidTickets();
  const { createDraftSalesOrder, loadingSalesOrder } = useCreateSalesOrder();
  const { navigate } = useNavigation<StackNavigationProp<RootStackParamList>>();

  const isFreeSalesOrder =
    isItemPmc(selectedPrepaidTicket) &&
    isSalesOrderFree(selectedPrepaidTicket.prices);

  useFocusEffect(
    React.useCallback(() => {
      blockCompanybenefitInEdit('PURCHASE_PREPAID');

      return () => {
        blockCompanybenefitInEdit('');
      };
    }, [])
  );

  const { customer } = useContext(AccountContext);
  const {
    name = '',
    countryCode: zoneCountry,
    commercialZoneView,
    spotMap,
  } = parkingZone?.data?.parkZone || {};
  const ongoingSessionType = useReactiveVar(selectedOngoingSession);
  const isUserRestricted = useReactiveVar(accountRestricted);
  const isReserved =
    isItemPmc(selectedPrepaidTicket) &&
    selectedPrepaidTicket.reservationType === 'RESERVED';
  const currentParkingSpace = useReactiveVar(selectedParkingSpace);
  const parkingSpaceNotSelected =
    isReserved && !currentParkingSpace?.parkingSpaceUid;
  const tabBarHeight = useBottomMenuHeight();
  const getPriceText = (item: ParkzonePmcUnion) => {
    if (isItemPmc(item)) {
      return getPriceForSalesOrder(item.prices ?? [], zoneCountry || 'FI');
    }
    if (isItemPredefinedTicket(item)) {
      return `${currencyFormatter(item.price ?? 0, zoneCountry || 'FI')}/${
        durationText(item) ?? item.name
      }`;
    }
  };
  const isDesktop = useIsDesktop();

  const getNameText = (item: ParkzonePmcUnion) => {
    if (isItemPmc(item) || isItemPredefinedTicket(item)) {
      return durationString ? `${durationString} ${item.name}` : item.name;
    }
  };
  const priceString = getPriceText(selectedPrepaidTicket);

  const durationString = durationText(selectedPrepaidTicket);

  const purchaseCompleted = useReactiveVar(prepaidPurchaseCompleted);

  useEffect(() => {
    if (purchaseCompleted) {
      setCurrentScreen('SHOW_DETAILS');
    }
    return () => {
      prepaidPurchaseCompleted(false);
    };
  }, [purchaseCompleted, setCurrentScreen]);

  const checkPrepaidTicketDates = (): Date => {
    if (
      purchasedPrepaidTickets.length > 0 &&
      isItemPredefinedTicket(selectedPrepaidTicket)
    ) {
      const ticketsByLicensePlate = purchasedPrepaidTickets.filter(
        (ticket) =>
          ticket?.accessDevice?.value === vehicle?.licensePlate?.plateText
      );
      if (ticketsByLicensePlate.length < 1) {
        return new Date();
      }
      const maxDate = ticketsByLicensePlate.reduce((a, b) => {
        if (a?.endTime && b?.endTime) {
          return a.endTime > b.endTime ? a : b;
        }
      });
      return new Date(maxDate?.endTime as string);
    }
    return new Date();
  };
  const endTimeForPrepaidTicket = (): Date => {
    if (isItemPredefinedTicket(selectedPrepaidTicket)) {
      const startTime = checkPrepaidTicketDates();
      const ticketDuration = parseISO8601Duration(
        selectedPrepaidTicket.duration as string
      );
      return new Date(
        startTime.setDate(startTime.getDate() + (ticketDuration ?? 0))
      );
    }
    return new Date();
  };
  const confirmCreatePurchase = () => {
    if (parkingSpaceNotSelected) {
      return navigate('parkingSpaceMapViewer', { spotMap: spotMap ?? '' });
    }
    if (isUserRestricted) {
      return sheetModal('accountRestricted');
    }
    if (isItemPredefinedTicket(selectedPrepaidTicket)) {
      confirmPrepaidTicketData({
        cardId: paymentMethod?.cardId || '',
        predefinedTicketUid: selectedPrepaidTicket?.uid ?? '',
        customerEmail: customer.contactEmail || '',
        parkingZoneUid: parkingZoneUid || '',
        licensePlate: vehicle?.licensePlate?.plateText,
        vehicleName: vehicle?.name || '',
        type: 'PREPAID',
        startDate: checkPrepaidTicketDates(),
        parkzoneLocation: commercialZoneView?.name || name || '',
        prepaidName: selectedPrepaidTicket?.name ?? '',
        validFrom: checkPrepaidTicketDates(),
        validTo: endTimeForPrepaidTicket(),
      });
      sheetModal('confirmPrepaidPurchase');
      return;
    }
    if (isItemPmc(selectedPrepaidTicket)) {
      createDraftSalesOrder({
        pmcId: selectedPrepaidTicket.pmcId ?? '',
        startDate: new Date(),
        selectedPmc: selectedPrepaidTicket,
        fromParkZoneDetails: true,
      });
    }
  };
  const showServiceFee = () => {
    if (isItemPredefinedTicket(selectedPrepaidTicket)) {
      return selectedPrepaidTicket?.serviceFee !== 0 &&
        typeof selectedPrepaidTicket?.serviceFee === 'number'
        ? `+ ${currencyFormatter(
            selectedPrepaidTicket?.serviceFee,
            zoneCountry || 'FI'
          )} ${t('parking.receipt.serviceFee')}`
        : '';
    }
    return '';
  };

  useEffect(() => {
    return () => {
      confirmCreatePermitData(undefined);
    };
  }, []);

  const renderMarging = () => {
    if (ongoingSessionType !== '') {
      if (isWeb && !isDesktop) {
        return 40;
      }
      return 150 + tabBarHeight;
    }
    return isWeb ? 0 : tabBarHeight + 30;
  };

  const buyButtonText = () => {
    if (parkingSpaceNotSelected) {
      return t('longTermParking.selectSpace');
    }
    if (isFreeSalesOrder) {
      return t('button.redeem');
    }
    return isItemPmc(selectedPrepaidTicket)
      ? t('button.buyPermit')
      : t('parking.prepaidTickets.buyPrepaidTitle');
  };

  const selectSpaces = () => {
    selectedParkingSpaces(
      ((selectedPrepaidTicket as Pmc).parkingSpacesV2?.content ??
        []) as ReservedParkingSpace[]
    );
    navigate('parkingSpaceMapViewer', { spotMap: spotMap ?? '' });
  };
  const testID = 'prepaid-buy-screen';

  return (
    <SheetLayout
      onHeadlinePress={() => null}
      testIDName={`${testID}-sheet`}
      onHeadlineButtonPress={() => {
        setCurrentScreen('SHOW_DETAILS');
        setSelectedPrepaidTicket(undefined);
      }}
      headlineComponent={
        <Headline3 center>
          {isItemPmc(selectedPrepaidTicket)
            ? t('longTermParking.buyScreenTitle')
            : t('parking.prepaidTickets.buyPrepaidTitle')}
        </Headline3>
      }>
      <ColumnWithMargins style={containerStyle}>
        <BottomSheetScrollViewCustom bottomThreshold={0}>
          <CardBackground>
            <StyledWrapper mb={27}>
              <StyledImage
                center={true}
                width={114}
                height={98}
                source={require('assets/images/permit.png')}
                {...testIdentifiers(`${testID}-image`)}
              />
            </StyledWrapper>
            <StyledWrapper mb={20}>
              <Headline1 center>{getNameText(selectedPrepaidTicket)}</Headline1>
            </StyledWrapper>
            <StyledWrapper center>
              <BaseText center {...testIdentifiers(`${testID}-parkzone-name`)}>
                {commercialZoneView?.zoneCode || ''}
                {commercialZoneView?.name
                  ? ` ${commercialZoneView?.name}`
                  : name}
              </BaseText>
              <BaseText center>
                {t('parking.prepaidTickets.buyScreenInfo')}
              </BaseText>
              <BaseText center {...testIdentifiers(`${testID}-pricing`)}>
                {`${priceString || ''} ${showServiceFee()}`}
              </BaseText>
            </StyledWrapper>
            <CutWrapper>
              <LeftCut />
              <RightCut />
            </CutWrapper>
            <StyledWrapper center maxWidth={320} mb={0}>
              <ValidFromSelector
                value={checkPrepaidTicketDates()}
                testID={`${testID}-valid-selection`}
                showDateSelector={false}
              />
            </StyledWrapper>
            {isReserved && (
              <StyledWrapper center maxWidth={320} mb={0}>
                <ChangeSpaceItem onPress={selectSpaces} testID={testID} />
              </StyledWrapper>
            )}
          </CardBackground>
        </BottomSheetScrollViewCustom>
      </ColumnWithMargins>
      <FooterButtonContainer>
        <VehicleSelectDropDown />
        <PaymentCardDropDown isFreeSalesOrder={isFreeSalesOrder} />
      </FooterButtonContainer>
      <FooterButtonContainer pb={renderMarging()}>
        <Button
          disabled={
            !licensePlate?.plateText || !creditCardId || loadingSalesOrder
          }
          size="l"
          onPress={confirmCreatePurchase}
          text={buyButtonText()}
          {...testIdentifiers(`${testID}-buy-button`)}
          loading={loadingSalesOrder}
        />
      </FooterButtonContainer>
    </SheetLayout>
  );
};

export default PrepaidTicketBuyView;
