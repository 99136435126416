import { useContext } from 'react';
import AccountContext from 'context/AccountContext';
import {
  isSalesOrderActive,
  isSalesOrderWaiting,
  salesOrderForZone,
} from 'utils/permitUtils';

const usePermitParkZone = (parkZoneUid: string) => {
  const {
    customer: { permits },
  } = useContext(AccountContext);

  return permits?.find(
    (salesOrder) =>
      salesOrder &&
      (isSalesOrderActive(salesOrder) || isSalesOrderWaiting(salesOrder)) &&
      salesOrderForZone(salesOrder, parkZoneUid)
  );
};

export default usePermitParkZone;
