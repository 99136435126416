import SheetLayout from 'components/sheets/SheetLayout';
import React, { ReactNode } from 'react';
import { isWeb } from 'utils/deviceUtils';
import SetCustomEndTimeHeader from '../timeSelection/SetCustomEndTimeHeader';

export type ParkingConditionsContainerProps = {
  children?: ReactNode;
  onCancel: () => void;
  onBackPress?: () => void;
};

const ParkingConditionsContainer: React.FC<ParkingConditionsContainerProps> = ({
  onCancel,
  children,
  onBackPress,
}) => {
  return (
    <SheetLayout
      testIDName="parkingConditionsContainer"
      onHeadlineButtonPress={onCancel}
      onBackPress={onBackPress}
      resetPadding={isWeb}
      headlineComponent={<SetCustomEndTimeHeader />}>
      {children}
    </SheetLayout>
  );
};

export default ParkingConditionsContainer;
