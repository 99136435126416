import * as React from 'react';
export type NetworkConnectionContextProps = {
  hasConnection: boolean;
  initialized: boolean;
};

const NetworkConnectionContext =
  React.createContext<NetworkConnectionContextProps>({
    hasConnection: true,
    initialized: false,
  });

export default NetworkConnectionContext;
