import { useEffect, useState } from 'react';
import { getDurationFromDates } from 'utils/timeUtils';
/**
 * Hook for getting duration to time now in selected format with desired interval
 * @param  {Date} startDate Date of start time
 * @param  {number} interval interval on how often time is updated. Default 1000ms.
 * @param  {string[]} format format array can defined what is shown (days,hours,minutes,seconds by default)
 * @return {string} Returns and updates duration in selected units on selected interval cycle
 */
const useDurationFromDate = (
  startDate: Date,
  interval?: number,
  format?: string[]
): string => {
  const initialDuration = getDurationFromDates(startDate, new Date(), format);
  const [duration, setDuration] = useState(initialDuration);
  useEffect(() => {
    const durationInterval = setInterval(
      () => setDuration(getDurationFromDates(startDate, new Date(), format)),
      interval ?? 1000
    );
    return () => clearInterval(durationInterval);
  }, [initialDuration, setDuration, startDate, interval, format]);
  if (duration === '' && format && !format?.includes('seconds')) {
    return '1 min';
  }
  return duration;
};

export default useDurationFromDate;
