import { Meta, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import React, { ReactNode } from 'react';
import styled from 'styled-components/native';
import {
  Evse,
  EvseConnectorType,
  EvseStatus,
  Maybe,
} from 'types/generatedSchemaTypes';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { TouchableWithoutFeedback } from 'react-native';
import Animated, { FadeInRight, FadeOutLeft } from 'react-native-reanimated';
import { RowSpaceBetween } from 'styles/ContainerStyles';
import { Charger } from 'types/common';
import {
  getConnectorIcon,
  getSupportedTypes,
  isAvailableChargingStationSpot,
} from 'utils/chargingUtils';
import { isWeb } from 'utils/deviceUtils';
import PublicDisplayId from './PublicDisplayId';

const CardWrapper = styled.View<{ isSelected: boolean; lastElement?: boolean }>`
  flex-direction: row;
  padding: 17px 10px;
  cursor: pointer;
  align-items: center;
  ${({ isSelected, theme: themeProp }) =>
    isSelected
      ? `border: 3px solid ${themeProp.colors.boost.green}`
      : undefined}
  border-radius: ${({ isSelected }) => (isSelected ? '10px' : '0px')};
  border-bottom-width: ${(p) => (p.lastElement && !p.isSelected ? '0' : '3')}px;
  border-bottom-color: ${({ isSelected, theme: themeProp }) =>
    isSelected
      ? `${themeProp.colors.boost.green}`
      : `${themeProp.colors.gray50}`};
`;

const ChildContentWrapper = styled.View`
  flex-direction: row;
  width: auto;
  padding-right: 10px;
  margin-left: 15px;
  justify-content: space-between;
  flex: 1 1 auto;
`;

type SelectableChargerProps = {
  isSelected: boolean;
  disabled: boolean;
  testID: string;
  iconNode: ReactNode;
  children?: ReactNode;
  onPress: () => void;
  lastElement?: boolean;
};

const SelectableChargerItem: React.FC<SelectableChargerProps> = ({
  isSelected,
  disabled,
  testID,
  iconNode,
  children,
  onPress,
  lastElement,
}: SelectableChargerProps) => {
  return (
    <Animated.View
      entering={!isWeb ? FadeInRight.duration(500) : undefined}
      exiting={!isWeb ? FadeOutLeft.duration(200) : undefined}>
      <TouchableWithoutFeedback
        testID={testID + '-card'}
        onPress={() => (!disabled ? onPress() : undefined)}>
        <CardWrapper isSelected={isSelected} lastElement={lastElement}>
          {iconNode}
          {<ChildContentWrapper>{children}</ChildContentWrapper>}
        </CardWrapper>
      </TouchableWithoutFeedback>
    </Animated.View>
  );
};

const BoldMeta = styled(Meta)`
  font-family: ${theme.fontFamily.bold};
`;

type StatusTextProps = {
  testID: string;
  status?: Maybe<EvseStatus>;
  disabled: boolean;
};

const StatusText: React.FC<StatusTextProps> = ({
  testID,
  status,
  disabled,
}) => {
  const { t } = useTranslation();

  const color = () => {
    if (disabled) {
      return theme.colors.gray100;
    } else if (status === 'OCCUPIED') {
      return theme.colors.gray200;
    }
    return theme.colors.boost.green;
  };

  return (
    <BoldMeta testID={`${testID}-status`} color={color()}>
      {t(`evCharging.connectorStatus.${status}`)}
    </BoldMeta>
  );
};

const Column = styled.View<{ center?: boolean }>`
  flex-direction: column;
  justify-content: space-between;
  align-items: ${(p) => (p.center ? 'center' : 'flex-start')};
`;

type SelectParkAndChargeItemProps = {
  isSelected: boolean;
  onPress: (charger: Charger) => void;
  charger: Charger;
  testID: string;
  lastElement?: boolean;
};

const SelectParkAndChargeItem: React.FC<SelectParkAndChargeItemProps> = ({
  isSelected,
  onPress,
  charger,
  testID,
  lastElement,
}) => {
  const { t } = useTranslation();

  const {
    publicDisplayId,
    evse: { status },
  } = charger;

  const isAvailable = isAvailableChargingStationSpot(status);
  const supportedTypesItems = getSupportedTypesItems(
    charger.evse,
    t,
    testID,
    !isAvailable
  );

  const connectorValues = Array.from(charger.evse.connectors?.values() || []);
  const powers = connectorValues.flatMap((conn) => conn.power || []);

  let powerText = '';
  if (powers.length > 0) {
    const minPower = Math.min(...powers);
    const maxPower = Math.max(...powers);
    powerText =
      minPower !== maxPower ? `${minPower} - ${maxPower} kW` : `${minPower} kW`;
  }

  return (
    <SelectableChargerItem
      isSelected={isSelected}
      disabled={!isAvailable}
      onPress={() => onPress(charger)}
      testID={testID}
      lastElement={lastElement}
      iconNode={
        <PublicDisplayId
          isAvailable={isAvailable}
          publicDisplayId={publicDisplayId}
          testID={testID}
        />
      }>
      <Column>
        <StatusText disabled={!isAvailable} testID={testID} status={status} />
        <Meta
          color={isAvailable ? theme.colors.gray200 : theme.colors.gray100}
          testID={testID + '-power'}>
          {powerText}
        </Meta>
      </Column>
      <RowSpaceBetween noFlex center padding={0}>
        {supportedTypesItems}
      </RowSpaceBetween>
    </SelectableChargerItem>
  );
};

const getSupportedTypesItems = (
  evse: Evse,
  t: TFunction,
  testID: string,
  disabled: boolean
) => {
  const supportedTypes = getSupportedTypes([evse]);
  const supportedTypesItems = supportedTypes.map((type, i) => {
    const connectorIcon = type
      ? getConnectorIcon(type as EvseConnectorType)
      : undefined;
    return (
      <Column key={`supported-type-${type}`} center>
        {connectorIcon && (
          <Icon
            testID={`${testID}-type-icon-${i}`}
            name={connectorIcon}
            size="m"
            strokeColor={disabled ? theme.colors.gray100 : theme.colors.black}
            color={theme.colors.white}
          />
        )}
        <BoldMeta
          color={disabled ? theme.colors.gray100 : theme.colors.black}
          testID={`${testID}-type-${i}`}>
          {typeof type === 'string'
            ? t(`evCharging.connectorSupportedTypes.${type}`, type)
            : type}
          {i < (supportedTypes || []).length - 1 ? ', ' : ''}
        </BoldMeta>
      </Column>
    );
  });
  return supportedTypesItems;
};

export default SelectParkAndChargeItem;
