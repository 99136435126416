import { useReactiveVar } from '@apollo/client';
import { Button, Headline2, Headline3 } from 'aimo-ui';
import MockedLogin from 'components/account/MockedLogin';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import i18next from 'i18next';
import React, { useState } from 'react';
import { Linking, StyleSheet, Text, View } from 'react-native';
import { sheetModal } from 'states/common';
import {
  accessToken,
  country,
  deviceToken,
  deviceTokenRegistered,
  onboardingCompleted,
  tutorialCompletion,
} from 'states/persistInStorage';
import {
  ScreenContainer,
  ScreenWrapper,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { CountryCode, Lang } from 'types/generatedSchemaTypes';
import { MMKVStorage } from 'utils/MMKVStorage';
import { updateStateAndStorage } from 'utils/MMKVStorageUtils';
import { getFlattenObject } from 'utils/commonUtils';
import { isAndroid, isWeb } from 'utils/deviceUtils';
import { defaultCompletion } from 'utils/tutorialUtils';
import config from '../config/config';

const ListItemsWithoutBackground = () => {
  const flattenedObject = getFlattenObject(config, {});
  const configToList = Object.keys(flattenedObject).map((key: string) => ({
    title: `${key}`,
    subTitle: `${flattenedObject[key]}`,
  }));
  return (
    <View>
      {configToList.map((item: any) => (
        <View key={item.title} style={style.envListItem}>
          <Headline3>{item.title}</Headline3>
          <Text>{item.subTitle}</Text>
        </View>
      ))}
    </View>
  );
};
const DevScreen: React.FC = () => {
  const { currentStack } = useAfterAuthenticationRedirect();
  const currentCountry = useReactiveVar(country);
  const deviceIdentifier = useReactiveVar(deviceToken);
  const deviceIdentifierRegistered = useReactiveVar(deviceTokenRegistered);
  const accessTok = useReactiveVar(accessToken);
  const [currentLang, setCurrentLang] = useState(i18next.language);
  const refreshToken = MMKVStorage.getString('refreshToken');

  //cimode is a special code for i18next to show only translation keys during debugging:
  //https://www.i18next.com/overview/api#changelanguage
  const setLanguage = (langCode: Lang | 'cimode') => {
    i18next.changeLanguage(langCode);
    setCurrentLang(langCode);
  };
  const setCountry = (countryCode: CountryCode) => {
    updateStateAndStorage(country, 'country', countryCode);
  };
  const openPlayStore = (env: 'dev' | 'staging' | 'prod') => {
    const marketAppURL = `market://details?id=com.aimoone.${env === 'prod' ? 'app' : env}`;
    console.log(
      `Opening PlayStore for ${env} environment with URL: ${marketAppURL}`
    );
    Linking.openURL(marketAppURL);
  };

  return (
    <ScreenContainer>
      <ScreenWrapper tabBarHeight={100}>
        <StyledWrapper testID="mocked-login" maxWidth={300} center>
          <MockedLogin />
        </StyledWrapper>
        <StyledWrapper testID="current-country" maxWidth={300} center>
          <Headline2 center>Region</Headline2>
          <Headline3 center>Current: {currentCountry}</Headline3>
          <Button onPress={() => setCountry('FI')} text="Set country to FI" />
          <Button onPress={() => setCountry('SE')} text="Set country to SE" />
        </StyledWrapper>
        <StyledWrapper testID="current-language" maxWidth={300} center>
          <Headline2 center>Language</Headline2>
          <Headline3 center>Current: {currentLang}</Headline3>
          <Button onPress={() => setLanguage('en')} text="English" />
          <Button onPress={() => setLanguage('sv')} text="Swedish" />
          <Button onPress={() => setLanguage('fi')} text="Finnish" />
          <Button onPress={() => setLanguage('no')} text="Norwegian" />
          <Button
            onPress={() => setLanguage('cimode')}
            text="[Debug] Show translation keys only"
          />
        </StyledWrapper>
        {isAndroid && (
          <StyledWrapper testID="playstore-links" maxWidth={300} center>
            <Headline2 center>Google PlayStore links</Headline2>
            <Headline3 center>Open PlayStore like in update modal</Headline3>
            <Button onPress={() => openPlayStore('dev')} text="DEV" />
            <Button onPress={() => openPlayStore('staging')} text="STAGING" />
            <Button onPress={() => openPlayStore('prod')} text="PRODUCTION" />
          </StyledWrapper>
        )}
        <StyledWrapper testID="tutorial-settings" maxWidth={300} center>
          <Headline2 center>Tutorial settings</Headline2>
          <Button
            text="Reset completed tutorials"
            onPress={() => tutorialCompletion(defaultCompletion)}
          />
          <Button
            text="Show completed tutorials"
            onPress={() =>
              console.log('completed tutorials', tutorialCompletion())
            }
          />
        </StyledWrapper>
        <StyledWrapper testID="other-settings" maxWidth={300} center>
          <Headline2 center>Other settings</Headline2>
          <Button
            onPress={() => onboardingCompleted(false)}
            text="Reset onboarding completed"
          />
          <Button
            onPress={() => MMKVStorage.clearAll()}
            text="Clear application cache"
          />
          <Button
            onPress={() => console.log('currentStack', currentStack)}
            text="Show redirect stack"
          />
          {!isWeb && (
            <Button
              onPress={() =>
                MMKVStorage.set(
                  'refreshToken',
                  refreshToken?.substring(0, 1) +
                    'A' +
                    refreshToken?.substring(2)
                )
              }
              text="Force invalid_grant token"
            />
          )}
        </StyledWrapper>
        {/* Temporary section for testing purposes. TODO: Remove when ongoin charging is implemented */}
        <StyledWrapper testID="testing" maxWidth={300} center>
          <Headline2 center>Testing</Headline2>
          <Button
            onPress={() => sheetModal('stopCharging')}
            text="Stop charging"
          />
        </StyledWrapper>
        <StyledWrapper testID="device-token-status" maxWidth={300} center>
          <Headline2 center>Acceess and device token</Headline2>
          <Headline3>Access token: </Headline3>
          <Text testID="access-token" selectable>
            {accessTok.toString() || '-'}
          </Text>
          <Headline3>Device token:</Headline3>
          <Text testID="device-token" selectable>
            {deviceIdentifier || '-'}
          </Text>
          <Headline3>Device token registered: </Headline3>
          <Text testID="device-token-registered">
            {deviceIdentifierRegistered.toString()}
          </Text>
        </StyledWrapper>
        <StyledWrapper testID="env-vars" maxWidth={400} mb={70}>
          <Headline2 center>Environment vars:</Headline2>
          <ListItemsWithoutBackground />
        </StyledWrapper>
      </ScreenWrapper>
    </ScreenContainer>
  );
};

const style = StyleSheet.create({
  envListItem: {
    margin: 10,
  },
});
export default DevScreen;
