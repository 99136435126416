import { gql } from '@apollo/client';

export const GET_PARK_ZONES_BY_DISTANCE = gql`
  query getParkZonesByDistance(
    $countryCode: CountryCode
    $coordinates: Coordinates!
    $size: Int
  ) {
    parkZonesByDistance(
      countryCode: $countryCode
      coordinates: $coordinates
      size: $size
    ) {
      content {
        uid
        name
        locationType
        address {
          marker
          street
          number
          city
          distance
        }
        countryCode
        commerciallyActive
        commercialZoneView {
          zoneCode
          name
        }
        parkingMethods {
          anpr
          unmanagedParking
          evCharging
        }
        visibility
      }
    }
  }
`;
