import { gql } from '@apollo/client';

export const GET_PARK_ZONE_WITH_CHARGING_STATIONS = gql`
  query parkZone($uid: String!) {
    parkZone(uid: $uid) {
      uid
      name
      locationType
      countryCode
      commerciallyActive
      address {
        street
        number
        city
        marker
      }
      area
      commercialZoneView {
        zoneCode
        name
      }
      parkingFacilityUid
      parkingFacility {
        timeSlots {
          type
          weekDay
          startTime
          endTime
        }
      }
      parkingMethods {
        anpr
        unmanagedParking
        parkingAbuseFix
        evCharging
      }
      info {
        doorCode
      }
      parkingZoneChargingStations {
        id
        chargingLocationId
        chargingPricePerKwh
        status
        publicDisplayIds
        evses{
          id
          status
          connectors {
              status
              type
              power
          }
          publicDisplayId
        }
      }
      notes {
        type
        text {
          fi
          sv
          en
          no
        }
        priority
        visibility
        startDate
        endDate
      }
      markerType
      serviceChannelCodes
      spotMap
      visibility
    }
  }
`;
