import { useReactiveVar } from '@apollo/client';
import ParkingContext from 'context/ParkingContext';
import { isBefore } from 'date-fns';
import useIsDesktop from 'hooks/useIsDesktop';
import { useKeyboardVisible } from 'hooks/useKeyboardVisible';
import useReadPurchasedPrepaidTickets from 'hooks/useReadPurchasedPrepaidTickets';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import React, { useContext, useMemo } from 'react';
import { StyleSheet, ViewProps } from 'react-native';
import { Shadow } from 'react-native-shadow-2';
import {
  activeElementType,
  selectedParkingZoneTab,
  tabBarVisible,
} from 'states/common';
import { PredefinedTicket } from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';
import { ParkingSheetProps } from '../ParkingWizardSheet';
import EnableAnprButton from '../detail/EnableAnprButton';
import PrepaidActiveButton from '../detail/PrepaidActiveButton';
import StartParkingButton from '../detail/StartParkingButton';
import StartParkAndChargeButton from '../evcharge/StartParkAndChargeButton';
import StartChargingButton from '../evcharge/detail/StartChargingButton';

const Empty = () => <></>;

const styles = (tabBar: boolean) =>
  StyleSheet.create({
    component: {
      paddingBottom: tabBar ? 0 : 25,
    },
    shadow: {
      width: '100%',
    },
  });

const ParkingWizardFooterSwitch = ({
  autoStartCharging,
  ...props
}: ParkingSheetProps & ViewProps) => {
  const { currentScreen, parkingZoneResult } = useContext(ParkingContext);
  const parkingZoneType = useReactiveVar(activeElementType);
  const isAnpr =
    parkingZoneResult?.data?.parkZone?.parkingMethods?.anpr === true;
  const { purchasedPrepaidTickets } = useReadPurchasedPrepaidTickets();
  const isDesktop = useIsDesktop();
  const selectedVehicle = useSelectedParkingVehicle();

  const isKeyboardVisible = useKeyboardVisible();
  const tabBar = useReactiveVar(tabBarVisible);

  const selectedTab = useReactiveVar(selectedParkingZoneTab);
  const showChargeButton =
    parkingZoneType === 'CHARGING' ||
    (parkingZoneType === 'PARK_AND_CHARGE' && selectedTab === 'CHARGE');
  const showParkAndChargeButton =
    parkingZoneType === 'PARK_AND_CHARGE' && selectedTab === 'PARK_AND_CHARGE';

  const hasPrepaidForSelectedVehicle = useMemo(
    () =>
      purchasedPrepaidTickets
        .filter((item): item is PredefinedTicket => !!item)
        .some(
          ({ accessDevice, startTime }) =>
            accessDevice?.value === selectedVehicle?.licensePlate.plateText &&
            startTime &&
            isBefore(new Date(startTime), new Date())
        ),
    [purchasedPrepaidTickets, selectedVehicle?.licensePlate.plateText]
  );

  let Component: React.FC<any> = Empty;
  let shadowDistance;
  const defaultShadowDistance = isWeb && isDesktop ? 0 : 10;

  switch (currentScreen) {
    case 'SHOW_DETAILS':
      shadowDistance = defaultShadowDistance;
      if (isAnpr && !showChargeButton) {
        Component = EnableAnprButton;
      } else if (showParkAndChargeButton) {
        Component = StartParkAndChargeButton;
      } else if (showChargeButton) {
        Component = StartChargingButton;
      } else if (hasPrepaidForSelectedVehicle) {
        Component = PrepaidActiveButton;
      } else {
        Component = StartParkingButton;
      }
      break;
    case 'CHOOSE_END_TIME':
      shadowDistance = defaultShadowDistance;
      Component = showParkAndChargeButton
        ? StartParkAndChargeButton
        : StartParkingButton;
      break;
    case 'RESUME_PARKING':
      shadowDistance = defaultShadowDistance;
      Component = StartParkingButton;
      break;
    case 'CONNECT_CHARGER':
      shadowDistance = defaultShadowDistance;
      Component = autoStartCharging ? Empty : StartChargingButton;
      break;
    case 'SEARCH':
    default:
      shadowDistance = 0;
      Component = Empty;
      break;
  }
  if (isKeyboardVisible) {
    return <></>;
  }
  return (
    <Shadow
      sides={{ top: true, bottom: false, end: false, start: false }}
      distance={shadowDistance}
      style={styles(tabBar).shadow}
      containerStyle={styles(tabBar).shadow}>
      <Component
        onLayout={props.onLayout}
        autoStartCharging={autoStartCharging}
        style={styles(tabBar).component}
      />
    </Shadow>
  );
};

export default ParkingWizardFooterSwitch;
