import { Position } from 'geojson';
import { Keyboard, Linking } from 'react-native';
import Geolocation from 'react-native-geolocation-service';
import { Region } from 'react-native-maps';
import {
  activeElement,
  activeElementSecondaryUid,
  activeElementType,
  isFullScreen,
  selectedParkingZoneTab,
  selectedPermit,
  selectedChargingPricingInfoSegmentTariffPlanId,
  selectedChargingPricingInfoSegmentNameBasedOnNotes,
  selectedTariffId,
  selectedTariffName,
  sheetModal,
} from 'states/common';
import { mapCoordinates, userLocation } from 'states/map';
import { ParkingZoneType } from 'types/common';
import { CountryCode } from 'types/generatedSchemaTypes';
import { LatLng } from 'types/MapTypes';
import { isAndroid, isIos, isWeb } from 'utils/deviceUtils';

export interface MarkerIconsMapProps {
  [markerType: string]: string;
}
export const markerIconsMap: MarkerIconsMapProps = {
  garage: 'MarkerGarageActive',
  default: 'MarkerOutdoorParkingActive',
  anpr: 'MarkerCameraRecognitionActive',
  evCharging: 'MarkerEVChargeActive',
  ride: 'MarkerRideActive',
  scooter: 'MarkerScooterActive',
  subway: 'MarkerSubwayActive',
  free: 'MarkerFreeActive',
  boat: 'MarkerBoatActive',
  skistar: 'MarkerSkistarActive',
};

export const mapGeoJSONToPolygonArray = (area: Position[][][]) => {
  let mappedPolygonArray: LatLng[][] = [];
  if (area?.length > 0) {
    mappedPolygonArray = area.map((firstLevel: Position[][]) => {
      // skip second level
      const mappedPoints: LatLng[] = firstLevel[0]?.map(
        (thirdLevel: number[]) => {
          return { longitude: thirdLevel[0], latitude: thirdLevel[1] };
        }
      );
      return mappedPoints;
    });
  }
  return mappedPolygonArray;
};

export const setParkingZoneParamsAndReactiveVars = (
  setParams: (params: Record<string, unknown>) => void,
  parkingZoneUid = '',
  type: ParkingZoneType = '',
  secondaryUid = ''
) => {
  setParams({ parkingZoneUid, type, secondaryUid });
  activeElement(parkingZoneUid);
  activeElementType(type);
  activeElementSecondaryUid(secondaryUid);
  selectedTariffName(undefined);
  selectedTariffId(undefined);
  selectedChargingPricingInfoSegmentTariffPlanId(undefined);
  selectedChargingPricingInfoSegmentNameBasedOnNotes(undefined);
  selectedParkingZoneTab(undefined);
};

export const handleParkZoneSelected = (
  parkZoneUid: string,
  coordinates: LatLng[] | LatLng | undefined,
  setParams: (params: Record<string, unknown>) => void,
  withLTP: boolean,
  permitForParkZone: string | undefined
) => {
  // Show warning and skip the flow
  // if current view is LTP and the user has already a permit in the parking zone.
  if (withLTP && !!permitForParkZone) {
    selectedPermit(permitForParkZone);
    sheetModal('duplicatePermitWarning');
    return;
  }
  handleElPress(parkZoneUid, coordinates, setParams);
};

export const handleElPress = (
  id: string,
  coordinates: LatLng[] | LatLng | undefined,
  setParams: (params: Record<string, unknown>) => void
) => {
  if (activeElement() === id) {
    setParkingZoneParamsAndReactiveVars(setParams);
    isFullScreen(false);
  } else {
    // TODO: Delete this hard-coded part in AO-572 when the user can select ev-charging
    // Valtatie 30, Tampere
    // if (id === 'FI-PZ0002499') {
    //   const chargingStationUid = '347777ee-04e0-45cb-a790-781ca4208cd8';
    //   setParkingZoneParamsAndReactiveVars(
    //     setParams,
    //     id,
    //     'CHARGING',
    //     chargingStationUid
    //   );
    // }
    setParkingZoneParamsAndReactiveVars(setParams, id, 'PARKING');
    if (coordinates) {
      mapCoordinates(coordinates);
    }
  }
  Keyboard.dismiss();
};

/**
 * Returns latitude and longitude object from coordinates array
 * @param c Position as number array
 * @returns Position as object
 */
export const makePoint = (c: Position) => ({
  latitude: c[1],
  longitude: c[0],
});

export const getDistanceFromLatLonInMeters = (
  { latitude: lat1, longitude: lon1 }: LatLng,
  { latitude: lat2, longitude: lon2 }: LatLng
) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c * 1000; // Distance in m
  // round to closest m
  return Math.round(d);
};

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

export const checkLocationPermissions = async () => {
  if (isWeb) {
    const success = () => false;
    const error = () => false;
    navigator.geolocation.getCurrentPosition(success, error, {
      enableHighAccuracy: true,
    });
    return '';
  }
  let access = '';
  import('react-native-permissions').then(async (Permissions) => {
    if (isIos) {
      try {
        access = await Permissions.check('ios.permission.LOCATION_WHEN_IN_USE');
      } catch (e) {
        access = 'unavailable';
      }
    }
    if (isAndroid) {
      try {
        access = await Permissions.check(
          'android.permission.ACCESS_COARSE_LOCATION'
        );
      } catch (e) {
        access = 'unavailable';
      }
    }

    // this will open settings in both Android and IOS in cases needed
    if (
      access === 'unavailable' ||
      access === 'denied' ||
      access === 'blocked'
    ) {
      userLocation(null);
      import('react-native-permissions').then(({ openSettings }) => {
        openSettings();
      });
    }

    // just to make sure gps is not turned off and to get dialog if it is on Android
    if (isAndroid) {
      const success = () => false;
      const error = () => false;
      Geolocation.getCurrentPosition(success, error, {
        enableHighAccuracy: true,
        showLocationDialog: true,
      });
    }
  });
  return access;
};

export const getMapDirections = (label: string, latLng: string) => {
  if (isWeb) {
    return `https://www.google.com/maps/dir/?api=1&destination=${latLng}`;
  }
  return isIos
    ? `maps:0,0?q=${label}@${latLng}`
    : `google.navigation:?q=${label}&@${latLng}`;
};

export const navigateWithCoordinates = (
  coordinateInfo: Position | undefined,
  destinationName = ''
) => {
  const coordinates = coordinateInfo && makePoint(coordinateInfo);
  const latLng = `${coordinates?.latitude},${coordinates?.longitude}`;
  const label = encodeURIComponent(destinationName);
  const directions = getMapDirections(label, latLng);
  if (coordinates) {
    Linking.openURL(directions);
  }
};

export const getBoxDimensionsFromRegion = (region: Region) => {
  const viewPort = {
    upperRightLat: region.latitude + region.latitudeDelta / 2,
    upperRightLng: region.longitude + region.longitudeDelta / 2,
    bottomLeftLat: region.latitude - region.latitudeDelta / 2,
    bottomLeftLng: region.longitude - region.longitudeDelta / 2,
  };

  const outerBox = {
    upperRightLat:
      region.latitude + (region.latitudeDelta * (isWeb ? 4 : 2)) / 3,
    upperRightLng:
      region.longitude + (region.longitudeDelta * (isWeb ? 4 : 2)) / 3,
    bottomLeftLat:
      region.latitude - (region.latitudeDelta * (isWeb ? 4 : 2)) / 3,
    bottomLeftLng:
      region.longitude - (region.longitudeDelta * (isWeb ? 4 : 2)) / 3,
  };

  const zoom = region ? region.latitudeDelta > 0.01 : true;

  return {
    viewPort,
    outerBox,
    zoom,
  };
};

export const defaultRegionByAppLocation = (
  appLocation: CountryCode | null,
  loggedIn: boolean
) => {
  if (!appLocation || !loggedIn) {
    return {
      latitude: 59.334591,
      longitude: 18.06324,
      latitudeDelta: 0.015,
      longitudeDelta: 0.0121,
    };
  }
  if (appLocation === 'FI') {
    return {
      latitude: 60.192059,
      longitude: 24.945831,
      latitudeDelta: 0.015,
      longitudeDelta: 0.0121,
    };
  }
  return {
    latitude: 59.334591,
    longitude: 18.06324,
    latitudeDelta: 0.015,
    longitudeDelta: 0.0121,
  };
};
