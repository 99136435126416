import { gql } from '@apollo/client';

export const READ_HSL_TICKET_POLICY = gql`
  query ReadHslTicketPolicy {
    readHslTicketPolicy {
      maxActiveSingleTickets
      maxActiveDayTickets
      maxActiveSeasonTickets
    }
  }
`;
