import { Meta, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import useIsDesktop from 'hooks/useIsDesktop';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableHighlight } from 'react-native';
import { ToastProps } from 'react-native-toast-notifications/lib/typescript/toast';
import styled from 'styled-components/native';
import { throttleWithId } from 'utils/commonUtils';
import { isAndroid, isIos, isWeb } from 'utils/deviceUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

export type CustomToastTypes = 'info' | 'success' | 'error';

export const toastDuration = {
  animationTime: 350,
  displayTime: 4000,
  extraDisplayTimeForLink: 2500,
};

const ToastContainer = styled.Pressable<{
  borderColor: string;
  isDesktop?: boolean;
}>`
  min-width: ${({ isDesktop }) => (isDesktop ? '320px' : '90%')};
  max-width: ${({ isDesktop }) => (isDesktop ? '800px' : '90%')};
  border-left-color: ${({ borderColor }) => borderColor};
  border-left-width: 10px;
  margin: 15px 20px ${isWeb ? '20px' : '0px'}
    ${(p) => (isWeb && p.isDesktop ? 'auto' : '20px')};
  background: ${theme.colors.gray50};
  padding: 10px 15px;
  border-radius: 10px;
  /*
    Setting box-shadow in mobile conflicts with the specific shadow api,
    (please see ToastContainer's 'style' property for mentioned api)
    effectively removing the shadow completely
  */
  box-shadow: ${isWeb ? '0px 5px 16px rgba(1, 1, 1, 0.2)' : 'none'};
`;

const ToastWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TextWrapper = styled.View`
  flex: 0 1 auto;
`;

const ButtonWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;

const ToastText = styled(Meta)<{
  withLink?: boolean;
}>`
  width: 100%;
  flex-direction: row;
  line-height: 22px;
  padding-right: ${({ withLink }) => (withLink ? '40px' : 'auto')};
`;

const LinkText = styled.Text<{
  pressed: boolean;
}>`
  line-height: 18px;
  color: ${({ pressed }) =>
    pressed ? theme.colors.black : theme.colors.gray200};
`;

const toastShadows = StyleSheet.create({
  ios: {
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.2,
    shadowRadius: 16,
    elevation: 7,
  },
  android: {
    /*
      Apparently on android, elevation makes the shadow spread from the border's line
      both inward and outward, and during the toast's slide-in animation an ugly
      inner space not affected by the shadow shows up much brighter, due to the
      opacity transition. At this value it's less likely to happen, probably happens
      when the toast is too tall.
      Also, it seems this is the only attribute affecting "box-shadow" in android
    */
    elevation: 30,
  },
});

const getBorderColor = (type: string | undefined) => {
  switch (type) {
    case 'success':
      return theme.colors.boost.green;
    case 'error':
      return theme.colors.boost.red;
    case 'info':
      return theme.colors.boost.yellow;
    default:
      return theme.colors.gray50;
  }
};

const LinkButton = ({
  testIDPrefix = 'toast-link-',
  translationKey = 'button.showMore',
  pressed = false,
}: {
  testIDPrefix?: string;
  translationKey?: string;
  pressed?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <ButtonWrapper>
      <LinkText pressed={pressed} testID={testIDPrefix + 'text'}>
        {t([translationKey])}
      </LinkText>
      <Icon name="Chevron_Right" color={theme.colors.black} />
    </ButtonWrapper>
  );
};

/**
 * showToast()'s parameter `data` can be an object with anything (as previously was)
 * But if within the object there is a property `link`, link must be an object with
 * both the `translationKey` and `onPress` properties, we need both for the link to
 * actually make sense: if any of them is missing we either can't see the link to press
 * or we lack an action on pressing on the link
 */
export type ShowToastData =
  | undefined
  | {
      [index: string | number | symbol]: any;
      link?: ToastLink;
    };

export type ToastLink = {
  onPressCallback: (data: Record<any, any>) => void;
  translationKey?: string;
};

const CustomToast = ({ message, type, data, id }: ToastProps) => {
  const isDesktop = useIsDesktop();
  const borderColor = getBorderColor(type);
  const maxNumberOfLines = data?.maxNumberOfLines || 5;
  const testID = data?.testID || `${type ? type + '-' : ''}toast-message-${id}`;
  const { onPressCallback, translationKey }: ToastLink = data?.link || {};
  let toastContainerShadowStyle;
  const [pressed, setPressed] = useState<boolean>();

  /*
   * If it's not ios nor android it will default to ToastContainer's CSS,
   * which works for web and maybe other unknown environments
   */
  if (isIos) {
    toastContainerShadowStyle = toastShadows.ios;
  } else if (isAndroid) {
    toastContainerShadowStyle = toastShadows.android;
  }

  return (
    <ToastContainer
      accessible={false}
      {...testIdentifiers(testID)}
      isDesktop={isDesktop}
      borderColor={borderColor}
      style={toastContainerShadowStyle}>
      <ToastWrapper>
        <TextWrapper>
          <ToastText
            numberOfLines={maxNumberOfLines}
            withLink={onPressCallback}
            {...testIdentifiers('toast-text')}>
            {message}
          </ToastText>
        </TextWrapper>
        {onPressCallback && (
          <TouchableHighlight
            onPress={onPressCallback}
            onPressIn={() => setPressed(true)}
            onPressOut={() => setPressed(false)}
            underlayColor="transparent"
            testID="toast-link-wrapper">
            <LinkButton translationKey={translationKey} pressed={pressed} />
          </TouchableHighlight>
        )}
      </ToastWrapper>
    </ToastContainer>
  );
};

/**
 * Shows toast with preferred type, toast is declared in global namespace and function can be called outside react components
 * jest testing: add expect(global?.toast?.show).toBeCalledWith(your request from function)
 * same toast message won't be shown until 5 seconds have passed
 * @param {string} message Message string that will be translated in toast. Required.
 * @param {CustomToastTypes} type A string array of selectable value
 * @param {number | undefined} duration You can customise duration in ms. default: 4000ms
 * @param {any|undefined} data object of other params that can be passed to toast
 */

export const showToast = (
  message: string,
  type: CustomToastTypes = 'info',
  duration: number | undefined = 4000,
  data: any = undefined
) => {
  if (global?.toast?.show) {
    const translatedMessage = i18next.t(message);
    if (data?.link) {
      duration += toastDuration.extraDisplayTimeForLink;
    }
    return throttleWithId<string | undefined>(
      translatedMessage,
      () =>
        global?.toast?.show(translatedMessage, {
          type,
          duration,
          data: { ...data, testID: message },
        }),
      5000
    )();
  }
};

export default CustomToast;
