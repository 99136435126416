import React from 'react';
import { theme, Meta, Headline1 } from 'aimo-ui';
import { ColumnWithMargins, RowSpaceBetween } from 'styles/ContainerStyles';
import { useTranslation } from 'react-i18next';
import { CountryCode, Maybe } from 'types/generatedSchemaTypes';
import { currencyFormatter } from 'utils/commonUtils';
import PaymentCardSelector from './PaymentCardSelector';

const RetryPaymentSummary: React.FC<{
  totalAmount: Maybe<number> | undefined;
  countryCode: CountryCode;
}> = ({ totalAmount, countryCode }) => {
  const { t } = useTranslation();
  const total = currencyFormatter((totalAmount as number) || 0, countryCode);

  return (
    <ColumnWithMargins mb={20}>
      <RowSpaceBetween>
        <ColumnWithMargins>
          <Meta color={theme.colors.gray200}>
            {t('parking.receipt.paymentAmount')}
          </Meta>
          <Headline1>{total}</Headline1>
        </ColumnWithMargins>
        <ColumnWithMargins>
          <PaymentCardSelector />
        </ColumnWithMargins>
      </RowSpaceBetween>
    </ColumnWithMargins>
  );
};

export default RetryPaymentSummary;
