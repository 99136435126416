import jwtDecode from 'jwt-decode';
import Logger from 'utils/Logger';
import pick from 'lodash/pick';

//The token returns more fields, but for security reasons only the used or useful ones are shown, and the more sensitive
// ones are not
export type DecodedAccessTokenType =
  | undefined
  | {
      country: string;
      email: string;
      emails: [string];
      extension_AccountType: string;
      family_name: string;
      given_name: string;
      idp: string;
      isForgotPassword: boolean;
      newUser: boolean;
      sub: string;
    };

export function getCustomerUidFromToken(token: string) {
  try {
    const decoded = jwtDecode<{ sub: string } | undefined>(token);
    return decoded?.sub;
  } catch {
    return null;
  }
}

export function getNewUserFromToken(token: string) {
  try {
    return jwtDecode<{ newUser: boolean } | undefined>(token)?.newUser;
  } catch {
    return false;
  }
}

export function getDecodedToken(token: string) {
  try {
    //Returning "Useful" fields from decoded token, ignoring the ones that could be dangerous to have exposed
    return pick(jwtDecode(token), [
      'country',
      'email',
      'emails',
      'extension_AccountType',
      'family_name',
      'given_name',
      'idp',
      'isForgotPassword',
      'newUser',
      'sub',
    ]);
  } catch {
    Logger.error('Failed to jwt decode token');
    return undefined;
  }
}
