import { useMutation, useReactiveVar } from '@apollo/client';
import { Button, Headline3 } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import SheetLayout from 'components/sheets/SheetLayout';
import AccountContext from 'context/AccountContext';
import HslContext from 'context/HslContext';
import ServiceContext from 'context/ServiceContext';
import { format, isAfter } from 'date-fns';
import { CREATE_HSL_TICKET } from 'graphql/mutations/createHslTicket';
import { READ_HSL_TICKETS } from 'graphql/queries/readHslTickets';
import { defaultSelections } from 'hooks/useHslWizard';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getUniqueIdSync } from 'react-native-device-info';
import {
  accountRestricted,
  selectedHslPricing,
  sheetModal,
} from 'states/common';
import {
  AccountType,
  HslZone,
  Mutation,
  MutationCreateHslTicketArgs,
  TicketType,
} from 'types/generatedSchemaTypes';
import { getLocale } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import { titleTypeText } from 'utils/hslUtils';
import HslPricingView from './HslPricingView';
import ValueSelectionItem from './ValueSelectionItem';

const BuyTicket: React.FC = () => {
  const {
    setCurrentScreen,
    setSelectedTicketType,
    setSelections,
    setSelectedSubscriptionType,
    selectedSubscriptionType,
    selectedTicketType,
    selections,
    hslPricingResult,
    hslTicketPolicy,
  } = useContext(HslContext);
  const { customer } = useContext(AccountContext);
  const { t } = useTranslation();
  const { selectedZone, validFrom, customerType } = selections;
  const selectedPricing = useReactiveVar(selectedHslPricing);
  const {
    customer: { countryCode },
  } = useContext(AccountContext);
  const { myHslTickets } = useContext(ServiceContext);
  const isUserRestricted = useReactiveVar(accountRestricted);

  const handleNavigateback = () => {
    setCurrentScreen('SELECT_TICKET');
    setSelectedTicketType(undefined);
    setSelections(defaultSelections);
    setSelectedSubscriptionType('ONEOFF');
    selectedHslPricing(null);
  };
  const paymentMethod = useSelectedPaymentMethod();

  const [createHslTicket, { loading }] = useMutation<
    Mutation,
    MutationCreateHslTicketArgs
  >(CREATE_HSL_TICKET, {
    refetchQueries: [{ query: READ_HSL_TICKETS }],
    onCompleted: (data) => {
      const formatForDate =
        selectedTicketType?.type === 'SINGLE' ? 'd.M.Y HH:mm' : 'd.M.Y';
      const date = format(
        new Date(data.createHslTicket?.hslDetails?.validTo as Date),
        `${formatForDate}`,
        { locale: getLocale() }
      );
      const text = t('success.hslTicketPurchase', {
        type: titleTypeText(data.createHslTicket?.hslDetails?.ticketType, true),
        date: date,
      });
      showToast(`${text}`, 'success');
      setCurrentScreen('');
    },
    onError: () => showToast('error.hslTicketCreationFailed', 'error'),
  });

  useEffect(() => {
    if (
      hslPricingResult?.data?.readHslTicketPricing &&
      hslPricingResult?.data?.readHslTicketPricing.length > 0
    ) {
      if (selectedSubscriptionType === 'AUTOSAVER') {
        selectedHslPricing(
          hslPricingResult?.data?.readHslTicketPricing.find(
            (pricing) => pricing?.validityPeriod === 30
          )
        );
        return;
      }
      const val =
        selectedTicketType?.type !== 'SINGLE'
          ? hslPricingResult?.data?.readHslTicketPricing.find(
              (pricing) =>
                pricing?.validityPeriod === selectedPricing?.validityPeriod
            ) || hslPricingResult?.data?.readHslTicketPricing?.[0]
          : hslPricingResult?.data?.readHslTicketPricing?.[0];
      selectedHslPricing(val);
    }
  }, [
    hslPricingResult,
    selectedPricing,
    selectedTicketType,
    selectedSubscriptionType,
  ]);

  const formatDate =
    validFrom === 'Now'
      ? validFrom
      : format(validFrom as Date, 'd.M.Y HH:mm', { locale: getLocale() });

  const isLoading = hslPricingResult?.loading;

  const validityPeriod = () => {
    if (selectedPricing) {
      return selectedTicketType?.type === 'SINGLE'
        ? t('hslTicket.validity.minutes', {
            value: selectedPricing.validityMinutes,
          })
        : t('hslTicket.validity.days', {
            value: selectedPricing.validityPeriod,
          });
    }
    return '';
  };

  const onPress = () => {
    if (isUserRestricted) {
      return sheetModal('accountRestricted');
    } else if (hasMaxActiveTickets()) {
      return showToast(
        t('hslTicket.maxTicketsReached', {
          type: t(
            `hslTicket.types.${selectedTicketType?.type?.toLowerCase()}.title`
          ).toLowerCase(),
        }),
        'error'
      );
    }
    createHslTicket({
      variables: {
        input: {
          phoneNumber: customer.mobilePhone as string,
          ticketType: selectedTicketType?.type as TicketType,
          accountType: customerType?.key as AccountType,
          zones: selectedZone as HslZone,
          creditCardId: paymentMethod?.cardId as string,
          autoRenewable: selectedSubscriptionType === 'AUTOSAVER',
          validityPeriod:
            selectedTicketType?.type === 'SINGLE'
              ? null
              : selectedPricing?.validityPeriod,
          locale: countryCode,
          deviceId: isWeb ? customer.contactEmail : getUniqueIdSync(),
          validFrom: validFrom === 'Now' ? null : (validFrom as string),
        },
      },
    });
  };

  const hasMaxActiveTickets = () => {
    if (!hslTicketPolicy || !selectedTicketType?.type) {
      return false;
    }
    switch (selectedTicketType.type as TicketType) {
      case 'SEASON':
        return (
          currentTicketAmount('SEASON') >=
          hslTicketPolicy.maxActiveSeasonTickets
        );
      case 'DAY':
        return (
          currentTicketAmount('DAY') >= hslTicketPolicy.maxActiveDayTickets
        );
      case 'SINGLE':
        return (
          currentTicketAmount('SINGLE') >=
          hslTicketPolicy.maxActiveSingleTickets
        );
      default:
        return false;
    }
  };

  const currentTicketAmount = (ticketType: TicketType) => {
    return myHslTickets.filter(
      (ticket) =>
        ticket.hslDetails.ticketType === ticketType &&
        ticket.hslDetails.validTo &&
        isAfter(new Date(ticket.hslDetails.validTo), new Date())
    ).length;
  };

  return (
    <SheetLayout
      onHeadlineButtonPress={handleNavigateback}
      testIDName="hsl-buy-ticket"
      headlineComponent={
        <Headline3 center>
          {t(
            `hslTicket.${
              selectedTicketType?.type === 'SEASON'
                ? `subscriptionTypes.${selectedSubscriptionType.toLowerCase()}.buyTitle`
                : 'buyTitle'
            }`,
            {
              type: titleTypeText(
                selectedTicketType?.translationKey as TicketType,
                true
              ),
            }
          )}
        </Headline3>
      }>
      <>
        <ValueSelectionItem
          title={t('hslTicket.zoneTitle')}
          value={selectedZone}
          selectable
          testId="hsl-purchase-select-zone"
          onPress={() => setCurrentScreen('SELECT_ZONE')}
        />
        {selectedTicketType?.type === 'SEASON' && (
          <ValueSelectionItem
            title={t('hslTicket.subscriptionTitle')}
            value={t(
              `hslTicket.subscriptionTypes.${selectedSubscriptionType.toLowerCase()}.selected`
            )}
            selectable
            loading={isLoading}
            testId="hsl-purchase-select-subscription"
            onPress={() => setCurrentScreen('SELECT_SUBSCRIPTION')}
          />
        )}
        <ValueSelectionItem
          title={t('hslTicket.durationTitle')}
          value={validityPeriod()}
          selectable={
            selectedTicketType?.type !== 'SINGLE' &&
            selectedSubscriptionType !== 'AUTOSAVER'
          }
          loading={isLoading}
          testId="hsl-purchase-select-duration"
          onPress={() => setCurrentScreen('SELECT_DURATION')}
        />
        <ValueSelectionItem
          title={t('hslTicket.validFromTitle')}
          value={formatDate}
          selectable
          testId="hsl-purchase-select-validFrom"
          onPress={() => setCurrentScreen('SELECT_VALID_FROM')}
        />
        <ValueSelectionItem
          title={t('hslTicket.customerTypeTitle')}
          value={customerType?.value}
          selectable={false}
          noBorder
          testId="hsl-purchase-select-customerType"
          onPress={() => null}
        />
        <HslPricingView />
        <Button
          text="Buy ticket"
          buttonType="secondary"
          size="l"
          testID="hsl-buy-ticket-button"
          disabled={loading || selectedZone === '' || customerType?.key === ''}
          onPress={onPress}
          loading={loading}
        />
      </>
    </SheetLayout>
  );
};

export default BuyTicket;
