import { ShortTermParkingWizard } from 'hooks/useShortTermParkingWizard';
import * as React from 'react';

const noop = () => undefined;

const ParkingContext = React.createContext<ShortTermParkingWizard>({
  parkingConditions: {},
  currentScreen: 'SEARCH',
  setCurrentScreen: noop,
  setEndTime: noop,
  setIsValidEndTime: noop,
  setSelectedEvCharger: noop,
  setSelectedPrepaidTicket: noop,
  isOngoingParking: false,
});

export default ParkingContext;
