import React, { useContext, useLayoutEffect, useMemo } from 'react';
import { BaseText, Button, Meta, Headline1, theme, Spinner } from 'aimo-ui';
import Icon from '../icons/Icon';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  Maybe,
  ParkZone,
  Query,
  QuerySearchParkZonesByTextArgs,
  QuerySearchParkZonesLtpByTextArgs,
} from 'types/generatedSchemaTypes';
import { SEARCH_PARK_ZONES_BY_TEXT } from '@queries/searchParkZonesByText';
import { SEARCH_PARK_ZONES_LTP_BY_TEXT } from '@queries/searchParkZonesLTPByText';
import { userLocation } from 'states/map';
import { Container, Center, MarginView } from './styles/styles';
import { Pressable } from 'react-native';
import SearchItem from './SearchItem';
import { IconWrapper, Row } from './styles/styles';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import {
  searchInputActive,
  selectedParkScreenQuickAccessItem,
} from 'states/common';
import {
  featureCountryCode,
  recentZonesOrChargers,
} from 'states/persistInStorage';
import { Divider } from 'styles/CommonLayoutStyles';
import FlatListCustom from 'components/sheets/FlatListCustom';
import {
  getZonesWithVisibility,
  parkMapParkzoneFilter,
  quickMenuSort,
  sortInMainMapViewByZoneAndBenefit,
} from 'utils/parkUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import { ParkZoneWithVisibility } from 'components/park/ParkingZoneSearch';
import AccountContext from 'context/AccountContext';

type ParkingZoneSearchProps = {
  searchTerm: string;
  testID?: string;
  withLTP?: boolean;
};

const NoMatchesWrapper = styled.View`
  flex: 1;
  align-items: center;
  margin-top: 20px;
`;

const NoMatchesTextWrapper = styled.View`
  margin-top: 31px;
  margin-bottom: 39px;
`;

const NoMatchesButtonWrapper = styled.View`
  width: 266px;
`;

const Hint = () => {
  const { t } = useTranslation();
  return (
    <Meta color={theme.colors.gray200} center>
      {t('search.hint')}
    </Meta>
  );
};

const NoMatches = () => {
  const { t } = useTranslation();
  return (
    <Container testID="no-matches">
      <NoMatchesWrapper>
        <Headline1>{t('search.noMatches')}</Headline1>
        <NoMatchesTextWrapper>
          <Meta center>{t('search.noMatchesText')}</Meta>
        </NoMatchesTextWrapper>
        <NoMatchesButtonWrapper>
          <Button
            onPress={() => searchInputActive(false)}
            text={t('search.seeNearbyButton')}
            iconComponent={() => (
              <Icon
                name="Location-Filled-01"
                size="s"
                color={theme.colors.white}
              />
            )}
            iconRight={true}
            size="m"
          />
        </NoMatchesButtonWrapper>
      </NoMatchesWrapper>
    </Container>
  );
};

const LocationItem = ({ testID }: { testID: string }) => {
  const { t } = useTranslation();
  const usersLocation = useReactiveVar(userLocation);
  return (
    <Pressable onPress={() => searchInputActive(false)}>
      <Row testID={testID} mt={0} mb={0}>
        <IconWrapper>
          <Icon
            name="Location"
            size="s"
            color={usersLocation ? undefined : theme.colors.boost.red}
          />
        </IconWrapper>
        <BaseText>{t('search.myLocation')}</BaseText>
      </Row>
    </Pressable>
  );
};

const ParkingZoneSearchList = ({
  searchTerm,
  testID,
  withLTP,
}: ParkingZoneSearchProps) => {
  const { t } = useTranslation();
  const {
    customer: { householdBenefits, poolingBenefits, permits },
  } = useContext(AccountContext);
  const currentLocation = useReactiveVar(userLocation);
  const appCountry = useReactiveVar(featureCountryCode);
  const selectedQuickAccessItem = useReactiveVar(
    selectedParkScreenQuickAccessItem
  );
  const [getZonesByText, { error, loading, data: parkZones }] = useLazyQuery<
    Query,
    QuerySearchParkZonesByTextArgs | QuerySearchParkZonesLtpByTextArgs
  >(withLTP ? SEARCH_PARK_ZONES_LTP_BY_TEXT : SEARCH_PARK_ZONES_BY_TEXT, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const searchResults: Maybe<ParkZoneWithVisibility>[] = useMemo(() => {
    if (parkZones && searchTerm.length > 0) {
      return withLTP
        ? [
            ...getZonesWithVisibility(
              (parkZones.searchParkZonesLTPByText?.content ?? []) as ParkZone[],
              parkZones.searchParkZonesLTPByText?.pmcVisibilities,
              householdBenefits,
              null,
              appCountry,
              true
            ),
          ]
        : [
            ...parkMapParkzoneFilter(
              (parkZones.searchParkZonesByText?.content ?? []) as ParkZone[],
              [...(householdBenefits || []), ...(poolingBenefits || [])],
              permits
            )
              .sort((a, b) =>
                quickMenuSort(
                  a,
                  b,
                  selectedQuickAccessItem,
                  currentLocation,
                  withLTP
                )
              )
              .sort((a, b) => {
                return sortInMainMapViewByZoneAndBenefit(
                  a,
                  b,
                  householdBenefits
                );
              }),
          ];
    }
    return [];
  }, [
    currentLocation,
    householdBenefits,
    poolingBenefits,
    permits,
    parkZones,
    searchTerm.length,
    selectedQuickAccessItem,
    withLTP,
    appCountry,
  ]);

  useLayoutEffect(() => {
    if (searchTerm.length > 1) {
      getZonesByText({
        variables: {
          input: {
            searchWord: searchTerm,
            coordinates: userLocation(),
            size: 20,
          },
        },
      });
    }
  }, [getZonesByText, searchTerm]);

  const recentZonesOrChargersArray = useReactiveVar(recentZonesOrChargers);
  const userRecentZonesOrChargers = parkMapParkzoneFilter(
    recentZonesOrChargersArray || [],
    householdBenefits,
    permits
  );

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (error && !parkZones) {
    return (
      <Container testID="error-text">
        <Center>
          <Meta>{t('parkingZone.errorInLoading')}</Meta>
        </Center>
      </Container>
    );
  }

  if (
    (searchTerm.length > 3 ||
      (!isNaN(Number(searchTerm)) && searchTerm.length >= 2)) &&
    !loading &&
    !error &&
    searchResults.length === 0
  ) {
    return <NoMatches />;
  }

  if (
    searchTerm.length < 4 &&
    searchTerm.length > 0 &&
    !loading &&
    !error &&
    parkZones &&
    searchResults.length === 0
  ) {
    return <Hint />;
  }

  return (
    <Container testID={testID}>
      {searchTerm.length === 0 && (
        <>
          <LocationItem testID="nearest-locations" />
          {!withLTP && userRecentZonesOrChargers.length > 0 && (
            <>
              <Row mt={15} mb={16}>
                <Meta
                  {...testIdentifiers('recent-searches-label')}
                  color={theme.colors.gray200}>
                  {t('search.recentZonesAndChargers')}
                </Meta>
              </Row>
              <FlatListCustom
                data={userRecentZonesOrChargers}
                removeClippedSubviews={false}
                ItemSeparatorComponent={() => <Divider marginVertical={10} />}
                renderItem={({ item }) => (
                  <SearchItem
                    item={item}
                    currentLocation={currentLocation}
                    withLTP={withLTP}
                  />
                )}
                ListFooterComponent={() => <MarginView />}
                keyExtractor={(item, index) => `${item?.uid}-${index}`}
              />
            </>
          )}
        </>
      )}
      {!!searchResults && searchTerm.length > 0 && (
        <>
          <Row mt={15} mb={15}>
            <Meta
              {...testIdentifiers('results-label')}
              color={theme.colors.gray200}>
              {t('search.results', { amount: searchResults.length })}
            </Meta>
          </Row>
          <FlatListCustom
            ItemSeparatorComponent={() => <Divider marginVertical={10} />}
            data={searchResults as ParkZoneWithVisibility[]}
            removeClippedSubviews={false}
            renderItem={({ item }) => (
              <SearchItem
                item={item}
                currentLocation={currentLocation}
                withLTP={withLTP}
              />
            )}
            ListFooterComponent={() => <MarginView />}
            keyExtractor={(item, index) => `${item?.uid}-${index}`}
            {...testIdentifiers('search-results-list')}
          />
        </>
      )}
    </Container>
  );
};

export default ParkingZoneSearchList;
