import React, { useEffect, useMemo, useRef } from 'react';
import { Dimensions } from 'react-native';
import {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { ReactiveVar, useReactiveVar } from '@apollo/client';
import {
  AnimatedTouchable,
  OngoingParkingHeaderStyles,
} from 'components/ongoingParking/styles';
import useCustomBottomSheet from 'hooks/useCustomBottomSheet';
import {
  selectedOngoingSession,
  selectedSessionIdFromMultiple,
} from 'states/common';
import { countMarginFromTop, isWeb } from 'utils/deviceUtils';
import { toggleOngoingSessionSheet } from 'utils/ongoingSessionUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

type OngoingSessionHeaderContainerProps = {
  expandedState: ReactiveVar<boolean>;
  sessionId: string;
  children: React.ReactNode;
  testID: string;
  onPress?: () => void;
};

const { width } = Dimensions.get('window');
const marginTop = countMarginFromTop(20);

const OngoingSessionHeaderContainer: React.FC<
  OngoingSessionHeaderContainerProps
> = ({ expandedState, sessionId, children, testID, onPress }) => {
  const prevAnimatedIndex = useRef(0);
  const { collapse, expand, animatedIndex } = useCustomBottomSheet();
  const selectedSessionIdFromMulti = useReactiveVar(
    selectedSessionIdFromMultiple
  );

  useEffect(() => {
    const currentAnimatedIndex = animatedIndex.value;

    if (
      selectedSessionIdFromMulti &&
      sessionId === selectedSessionIdFromMulti
    ) {
      /**
       * When an ongoing session is selected from multiple active sessions,
       * expand it by default but allow to collapse the sheet if it's being sliding down.
       */
      if (
        currentAnimatedIndex >= 0 &&
        prevAnimatedIndex.current > currentAnimatedIndex
      ) {
        selectedOngoingSession('MULTIPLE');
        toggleOngoingSessionSheet(expandedState, false);
        // Make sure OngoingSessionsSheet have time to switch to correct ongoing session before snap
        setTimeout(() => {
          collapse();
        }, 100);
      } else {
        toggleOngoingSessionSheet(expandedState, true);
        // Make sure OngoingSessionsSheet have time to switch to correct ongoing session before snap
        setTimeout(() => {
          expand();
        }, 500);
      }
    } else {
      /**
       * Use animatedIndex.value to control the sheet position.
       * The range is [0,1] so we assume that:
       *  - Sheet is collapsed when the index is less than 0.5
       *  - Sheet is expanded when the index is greater or equals to 0.5
       */
      const isSheetExpanded = currentAnimatedIndex >= 0.5;
      toggleOngoingSessionSheet(expandedState, isSheetExpanded);
    }
    prevAnimatedIndex.current = currentAnimatedIndex;
  }, [
    animatedIndex.value,
    expandedState,
    selectedSessionIdFromMulti,
    sessionId,
    expand,
    collapse,
  ]);

  const expandModal = () => {
    toggleOngoingSessionSheet(expandedState, true);
    expand();
  };

  const containerAnimatedStyle = useAnimatedStyle(() => ({
    marginTop: interpolate(
      animatedIndex.value,
      [0, 1],
      [0, marginTop],
      Extrapolation.CLAMP
    ),
  }));

  const containerStyle = useMemo(
    () => [
      OngoingParkingHeaderStyles.container,
      containerAnimatedStyle,
      !isWeb && { width: width },
    ],
    [containerAnimatedStyle]
  );

  return (
    <>
      <AnimatedTouchable
        style={containerStyle}
        onPress={onPress ? onPress : expandModal}
        {...testIdentifiers(testID)}>
        {children}
      </AnimatedTouchable>
    </>
  );
};

export default OngoingSessionHeaderContainer;
