import {
  ApolloCache,
  Cache,
  DataProxy,
  InMemoryCacheConfig,
  StoreObject,
} from '@apollo/client';

type Cache = ApolloCache<any> | undefined;

export const updateCache = <TData, TVariables>(
  cache: Cache,
  storeObject: StoreObject,
  { fragment, data }: DataProxy.WriteFragmentOptions<TData, TVariables>
) => {
  const identifiedId = cache?.identify(storeObject);
  if (identifiedId) {
    cache?.writeFragment({
      id: identifiedId,
      fragment,
      data,
    });
  }
};

export const evictFromCache = (cache: Cache, storeObject: StoreObject) => {
  const identifiedId = cache?.identify(storeObject);
  if (identifiedId) {
    cache?.evict({ id: identifiedId });
    cache?.gc();
  }
};

export const cacheSettings: InMemoryCacheConfig = {
  typePolicies: {
    ParkingSession: {
      keyFields: ['sessionId'],
      fields: {
        tariffName: {
          merge(existing?: string, incoming?: string) {
            return existing ?? incoming;
          },
        },
      },
    },
    ChargingSession: { keyFields: ['chargingSessionId'] },
    OrderLine: {
      keyFields: ['orderLineId'],
    },
    UnifiedPermit: {
      keyFields: ['id'],
    },

    //Evse's will contain a fake "id" field which is autogenerated and not unique
    //setting keyFields to false will avoid Apollo from normalizing by default
    // by the id field and merging all
    // the Evse objects with same id (which is always 1,2,3,4... for now) into
    // the same data
    Evse: {
      keyFields: false,
    },
    AccessPermitPoolingGroupCombinedInfo: {
      keyFields: ['uid'],
    },
  },
};
