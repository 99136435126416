import Icon from '@components/icons/Icon';
import { IconButton, theme } from 'aimo-ui';
import React from 'react';

type HelpButtonProps = {
  onPress: () => void;
  testID?: string;
};

const HelpIcon = () => <Icon name="Help" size="s" color={theme.colors.white} />;

const HelpButton = ({ onPress, testID }: HelpButtonProps) => {
  return (
    <IconButton
      fullWidth={false}
      onPress={onPress}
      rounded
      testID={testID}
      iconComponent={() => <HelpIcon />}
    />
  );
};

export default HelpButton;
