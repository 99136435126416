import { theme } from 'aimo-ui';
import styled from 'styled-components/native';

export const CenterView = styled.View<{ background?: string }>`
  background-color: ${(p) => p.background || p.theme.colors.white};
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Tag = styled.View`
  padding: 6px 12px;
  background-color: ${theme.colors.gray50};
  border-radius: 60px;
`;

export const TagText = styled.Text`
  font-size: ${theme.textStyles.meta.fontSize};
  line-height: ${theme.textStyles.meta.lineHeight};
  font-family: ${theme.fontFamily.medium};
`;
