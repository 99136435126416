export const toTitleCase = (string: string) =>
  string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();

export const joinObjectsByProperty = (
  array: any[],
  property: string,
  joinText: string
): string => {
  const propertyValues = array.map((obj) => obj[property]);

  if (propertyValues.length === 1) {
    return propertyValues[0];
  }

  const lastValue = propertyValues.pop();
  return propertyValues.join(', ') + ' ' + joinText + ' ' + lastValue;
};
