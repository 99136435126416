import MenuItem from 'components/common/MenuItem';
import QuickActionDirection from 'components/common/QuickActionDirection';
import AccountContext from 'context/AccountContext';
import { add, isWithinInterval } from 'date-fns';
import usePaymentDetails from 'hooks/usePaymentDetails';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationType, Maybe, PoolingInfo } from 'types/generatedSchemaTypes';
import { locationIcon } from 'utils/parkUtils';
import { getDurationFromDates } from 'utils/timeUtils';
import { Position } from 'geojson';
import { makePoint } from 'utils/mapUtils';
import { getCardLogo } from 'utils/paymentUtils';
import { testIdentifiers } from 'utils/testIdentifiers';
import { theme } from 'aimo-ui';
import Icon from '@components/icons/Icon';

type ParkingDetailsSummaryProps = {
  name?: Maybe<string>;
  transactionId?: Maybe<string>;
  cardId?: Maybe<string>;
  corporateAccountUid?: Maybe<string>;
  totalAmount?: Maybe<number>;
  locationType?: Maybe<LocationType>;
  endTime: Maybe<Date>;
  startTime: Maybe<Date>;
  markerType: string;
  doorCode?: Maybe<string>;
  coordinates?: Maybe<Position>;
  poolingInfo?: Maybe<PoolingInfo>;
};
const ParkingDetailsSummary = ({
  endTime,
  startTime,
  name,
  transactionId,
  cardId,
  corporateAccountUid,
  totalAmount,
  markerType,
  doorCode,
  coordinates,
  poolingInfo,
}: ParkingDetailsSummaryProps) => {
  const { t } = useTranslation();
  const {
    customer: { paymentCards },
  } = useContext(AccountContext);
  const { type } = paymentCards?.find((card) => cardId === card?.cardId) || {};
  const paymentDetails = usePaymentDetails({
    transactionId,
    cardId,
    corporateAccountUid,
    totalAmount,
  });

  const paymentDetailSmallLabel =
    typeof paymentDetails === 'object' ? paymentDetails.title : paymentDetails;
  const paymentDetailsDescription =
    typeof paymentDetails === 'object' ? paymentDetails.companyName : undefined;
  const duration =
    startTime && endTime ? getDurationFromDates(startTime, endTime) : '';

  const showDoorCode =
    doorCode !== null &&
    doorCode !== '' &&
    endTime &&
    isWithinInterval(new Date(), {
      start: endTime,
      end: add(endTime, { minutes: 15 }),
    });

  return (
    <>
      {showDoorCode && (
        <MenuItem
          testID="garage-entry-code"
          iconName={'Info-Outlined'}
          menuLabel={t('parking.garageEntryCode')}
          smallLabel={doorCode}
        />
      )}
      <MenuItem
        testID="zone-name"
        iconName={locationIcon[markerType]}
        menuLabel={name || ''}
        rightComponent={
          coordinates ? (
            <QuickActionDirection
              name={name}
              showIconLabel={false}
              markerCoordinate={makePoint(coordinates)}
            />
          ) : undefined
        }
      />
      <MenuItem
        testID="duration"
        iconName="Time-Outlined"
        menuLabel={t('parking.duration')}
        smallLabel={duration}
      />
      {poolingInfo && (
        <MenuItem
          {...testIdentifiers('pooling-row-details')}
          iconName="Services-Outlined"
          menuLabel={t('parking.receipt.pooling')}
          rightComponent={
            <Icon
              {...testIdentifiers('pooling-row-icon')}
              name={poolingInfo.pooled ? 'check' : 'close'}
              color={theme.colors.gray200}
            />
          }
        />
      )}
      <MenuItem
        testID="payment-details"
        noBorder
        iconName={`${getCardLogo(type)}`}
        menuLabel={
          corporateAccountUid
            ? t('services.paymentMethod')
            : t('payment.creditCardOnly')
        }
        smallLabel={paymentDetailSmallLabel}
        subtitle={paymentDetailsDescription}
      />
    </>
  );
};

export default ParkingDetailsSummary;
