import * as React from 'react';
import { SharedValue } from 'react-native-reanimated';
import { Maybe, PriceEstimate } from 'types/generatedSchemaTypes';

export type PriceEstimateContextProps = {
  loadingPrice: boolean;
  priceEstimate?: Maybe<PriceEstimate>;
  error?: Error;
  startButtonOpacity?: SharedValue<number>;
  setStartButtonOpacity: (value: 0 | 1) => void;
  getPricingEstimateWithoutId: any;
  getPricingEstimateWithId: any;
};

const PriceEstimateContext = React.createContext<PriceEstimateContextProps>({
  loadingPrice: false,
  priceEstimate: undefined,
  error: undefined,
  setStartButtonOpacity: () => undefined,
  getPricingEstimateWithoutId: () => undefined,
  getPricingEstimateWithId: () => undefined,
});

export default PriceEstimateContext;
