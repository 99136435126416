import { theme } from 'aimo-ui';
import { Dimensions, StyleSheet } from 'react-native';
import { isIos } from '../utils/deviceUtils';

export const clusterStyles = [
  {
    textColor: theme.colors.white,
    url: '/images/circle.svg',
    height: 50,
    width: 50,
  },
  {
    textColor: theme.colors.white,
    url: '/images/circle.svg',
    height: 50,
    width: 50,
  },
  {
    textColor: theme.colors.white,
    url: '/images/circle.svg',
    height: 50,
    width: 50,
  },
];

export const webSheetStyles = StyleSheet.create({
  sheetOuter: {},
  lineContainer: { display: 'none' },
  sheetWrapper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    flex: 1,
  },
  sheetInner: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: 20,
    height: 11500,
    overflow: 'hidden',
    backgroundColor: theme.colors.white,
  },
});

export const webNavigationStyle = StyleSheet.create({
  backgroundContainer: {
    backgroundColor: theme.colors.white,
    flex: 1,
  },
  tabContainer: {
    position: 'absolute',
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderColor: 'rgba(0,0,0,0.33)',
    minHeight: 80,
    paddingHorizontal: 10,
    top: 0,
    left: 0,
    width: '100%',
  },
  tabContainerNotMobile: {
    alignItems: 'center',
  },
  tabContainerMobile: {
    alignItems: 'flex-end',
  },
  label: {
    alignItems: 'center',
    lineHeight: 12,
    fontSize: 12,
    fontFamily: theme.fontFamily.medium,
  },
  labelNotMobile: {
    marginBottom: 0,
  },
  labelMobile: {
    marginBottom: 10,
  },
  tab: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
  },
  tabNotMobile: {
    paddingLeft: 40,
    width: 'auto',
    paddingRight: 5,
  },
  tabMobile: {
    paddingLeft: 0,
    width: 65,
    paddingRight: 0,
  },
});

const navigationHeight = theme.normalising.heightPixel(90);
export const navigationStyle = StyleSheet.create({
  backgroundContainer: {
    backgroundColor: theme.colors.lightBg.pink,
    flex: 1,
  },
  tabContainer: {
    position: 'absolute',
    borderTopWidth: 1,
    borderColor: 'rgba(0,0,0,0.33)',
    alignItems: 'center',
    minHeight: navigationHeight,
    justifyContent: 'flex-start',
    alignContent: 'center',
    paddingHorizontal: 10,
  },
  label: {
    marginTop: isIos ? -2 : -10,
    lineHeight: 12,
    fontSize: 12,
    fontFamily: theme.fontFamily.medium,
    alignSelf: 'center',
    justifyContent: 'flex-start',
  },
  tab: { minWidth: 70, marginBottom: isIos ? -3 : 10 },
});

export const mapStyles = (navBarHeight = 0) =>
  StyleSheet.create({
    mapView: {
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height,
    },
    mapPadding: {
      bottom: Dimensions.get('window').height * 0.45 + navBarHeight,
      top: 0,
      right: 0,
      left: 0,
    },
    mapButton: { elevation: 20 },
    markerView: {
      transform: [{ scale: 0.8 }, { translateY: 16.5 }],
    },
    selectedMarkerView: {
      overflow: 'hidden',
      transform: [{ scale: 1 }, { translateY: 0 }],
    },
  });

export const sheetStyles = StyleSheet.create({
  container: {
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 7,
    elevation: 5,
    shadowColor: theme.colors.black,
  },
});

export const sheetModalStyles = StyleSheet.create({
  backdrop: {
    zIndex: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
});
