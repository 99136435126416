import { Meta } from 'aimo-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { testIdentifiers } from 'utils/testIdentifiers';
import { ExtendButton, StopButton } from './OngoingParkingComponents';

const ActionContainer = styled.View`
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  min-height: 240px;
`;
const ActionWrapper = styled.View`
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
`;

const Column = styled.View`
  margin: 20px 30px;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const StopText = styled(Meta)`
  color: ${({ theme }) => theme.colors.white};
`;

const OngoingParkingActions = ({
  onShowExtendTime,
  showExtendButton,
}: {
  onShowExtendTime: () => void;
  showExtendButton: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ActionContainer {...testIdentifiers('ongoing-parking-expanded-actions')}>
        <ActionWrapper>
          <Column>
            {showExtendButton && (
              <ExtendButton set onPress={onShowExtendTime} />
            )}
          </Column>
          <Column>
            <StopButton
              testID="ongoing-parking-expanded-actions-stop-parking"
              onPress={() => sheetModal('stopOngoingParking')}
              bigger
            />
          </Column>
          <Column>
            {showExtendButton && (
              <ExtendButton
                onPress={() => sheetModal('extendOngoingParking')}
              />
            )}
          </Column>
        </ActionWrapper>
        <ActionWrapper testID="ongoing-parking-expanded-actions-text">
          <StopText>{t('parking.stopParking')}</StopText>
        </ActionWrapper>
      </ActionContainer>
    </>
  );
};

export default OngoingParkingActions;
