import { gql } from '@apollo/client';

export const READ_CUSTOMER_PMCS_BY_DISTANCE_V2 = gql`
  query ReadCustomerPmcsOrderByDistanceV2(
    $input: CustomerPmcsByDistanceInput!
  ) {
    readCustomerPmcsOrderByDistanceV2(input: $input) {
      content {
        physicalZoneUid
        visibility
        parkZone {
          uid
          name
          locationType
          countryCode
          commerciallyActive
          serviceChannelCodes
          address {
            marker
            street
            number
            city
            distance
          }
          commercialZoneView {
            zoneCode
            name
          }
          parkingMethods {
            anpr
          }
          visibility
        }
      }
      pmcVisibilities {
        parkingZoneUid
        pmcId
        visibility
        hasCreditCardPayment
      }
    }
  }
`;
