import { BottomSheetScrollViewMethods } from '@gorhom/bottom-sheet';
import FailedToLoadChargersWarning from 'components/park/FailedToLoadChargersWarning';
import Tariff from 'components/park/tariff/Tariff';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import React, { MutableRefObject, useContext } from 'react';
import { ChargingStation } from 'types/generatedSchemaTypes';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import SelectChargerZoneDetails from '../evcharge/SelectChargerZoneDetails';
import { ImportantZoneNotes } from '../ParkingZoneNotes';
import TabContainer, { CommonTabProps } from './TabContainer';

type ParkingZoneChargeTabProps = {
  scrollRef: MutableRefObject<BottomSheetScrollViewMethods>;
} & CommonTabProps;

const ParkingZoneChargeTab: React.FC<ParkingZoneChargeTabProps> = ({
  parkZone,
  isActiveTab,
  scrollRef,
  hasImportantNotes,
}: ParkingZoneChargeTabProps) => {
  const {
    flags: { stp_tariff },
  } = useContext(FeatureFlagsContext);
  const showSTPTariff = showFeatureFlag(stp_tariff);
  const { parkingZoneChargingStations } = parkZone || {};

  return (
    <TabContainer isActiveTab={isActiveTab}>
      {showSTPTariff && parkingZoneChargingStations ? (
        <Tariff
          headlineTitle
          chargingStations={parkingZoneChargingStations as ChargingStation[]}
          borderBottom={!hasImportantNotes}
        />
      ) : (
        <FailedToLoadChargersWarning marginBottom={true} />
      )}
      <ImportantZoneNotes parkZoneNotes={parkZone?.notes} />
      {parkingZoneChargingStations && (
        <SelectChargerZoneDetails parkZone={parkZone} scrollRef={scrollRef} />
      )}
    </TabContainer>
  );
};

export default ParkingZoneChargeTab;
