import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import {
  CheckIcon,
  DropdownIcon,
  EmptyIcon,
  VEHICLE_INPUT_ICON_SIZE,
} from 'components/account/vehicle/VehicleIcons';
import { vehicleCountries } from 'utils/vehicleUtils';
import { VehicleCountryCode } from 'types/generatedSchemaTypes';

export type VehicleCountrySelectorProps = {
  selectedCountry?: VehicleCountryCode;
  setSelectedCountry: React.Dispatch<
    React.SetStateAction<VehicleCountryCode | undefined>
  >;
};

type IconComponentProps = {
  country: string;
};

const getFlagCountryCode = (country?: string) => {
  switch (country) {
    case 'FIN':
      return 'FI';
    case 'S':
      return 'SE';
    case 'N':
      return 'NO';
    default:
      return undefined;
  }
};

const IconComponent = ({ country }: IconComponentProps) => {
  const flagCountryCode = getFlagCountryCode(country);
  if (!flagCountryCode) {
    return <EmptyIcon />;
  }
  return (
    <Icon
      name={`flag-${flagCountryCode}`}
      size={VEHICLE_INPUT_ICON_SIZE}
      color={theme.colors.black}
      testID={`${flagCountryCode}-icon-button`}
    />
  );
};

const VehicleCountrySelector = ({
  selectedCountry,
  setSelectedCountry,
}: VehicleCountrySelectorProps) => {
  const { t } = useTranslation();

  const vehicleCountriesDropdownItems = vehicleCountries
    .map((country) => {
      return {
        label: t(`vehicle.form.country.${country}`),
        value: country,
        icon: () =>
          IconComponent({
            country,
          }),
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const selectedItem = useMemo(
    () =>
      vehicleCountriesDropdownItems.find(
        (item) => item.value === selectedCountry
      ),
    [selectedCountry, vehicleCountriesDropdownItems]
  );

  return (
    <Dropdown
      items={vehicleCountriesDropdownItems}
      defaultItem={selectedItem}
      onSelect={(item) => setSelectedCountry(item.value as VehicleCountryCode)}
      placeholder={t('vehicle.form.country.label')}
      dropdownIcon={DropdownIcon}
      checkIcon={CheckIcon}
    />
  );
};

export default React.memo(VehicleCountrySelector);
