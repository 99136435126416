import ParkingHistoryContent from 'components/history/HistoryContent';
import useParkingHistoryFilter from 'hooks/useParkingHistoryFilter';
import useReadReceipts from 'hooks/useReadReceipts';
import { concat } from 'lodash';
import React, { useEffect } from 'react';
import { Receipt } from 'types/generatedSchemaTypes';
import { permitTypes, prepaidTypes } from 'utils/parkUtils';

const PrepaidHistoryScreen: React.FC = () => {
  const { parkingType, filter, setFilter } = useParkingHistoryFilter();

  useEffect(() => {
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: prepaidAndPermitData,
    loading: prepaidAndPermitLoading,
    error: prepaidAndPermitError,
    fetchMoreCallback,
    resetPage,
  } = useReadReceipts(concat(permitTypes, prepaidTypes));

  useEffect(() => {
    if (parkingType !== undefined) {
      setFilter({ ...filter, parkingType: undefined });
    }
  }, [parkingType, filter, setFilter]);

  if (prepaidAndPermitError) {
    return <></>;
  }

  return (
    <ParkingHistoryContent
      fetchMore={fetchMoreCallback}
      loading={prepaidAndPermitLoading}
      isLast={prepaidAndPermitData?.last || false}
      receiptData={(prepaidAndPermitData?.content || []) as Receipt[]}
    />
  );
};

export default PrepaidHistoryScreen;
