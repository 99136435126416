import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledWrapper } from 'styles/ContainerStyles';
import { Button, Meta } from 'aimo-ui';
import { sheetModal } from 'states/common';

const AccountRemovalErrors = () => {
  const { t } = useTranslation();
  return (
    <StyledWrapper mb={0}>
      <StyledWrapper mb={20}>
        <Meta testID="account-remove-error-text">
          {t('account.remove.error')}
        </Meta>
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <Button
          size="l"
          onPress={() => sheetModal('')}
          text={t('button.close')}
          testID="account-remove-error-close-button"
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default AccountRemovalErrors;
