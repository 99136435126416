import { useMutation, useReactiveVar } from '@apollo/client';
import { showToast } from 'components/common/CustomToast';
import { CREATE_CARD } from 'graphql/mutations/createCard';
import i18next from 'i18next';
import { creditCardUrlPaymentHighway, sheetModal } from 'states/common';
import { country } from 'states/persistInStorage';
import { PaymentDataProps } from 'types/PaymentData';
import { Mutation, MutationCreateCardArgs } from 'types/generatedSchemaTypes';
import { creditCardRedirectUrl } from 'utils/urlUtils';

const returnUrl = creditCardRedirectUrl();

const paymentData: PaymentDataProps = {
  title: 'payment.addPaymentCard',
  icon: 'plus',
  action: 'addCardModal',
  testID: 'add-credit-card-button',
};

const useAddPaymentMethod = () => {
  const countryCode = useReactiveVar(country);

  const [createCard, { loading: loadingAddCard }] = useMutation<
    Mutation,
    MutationCreateCardArgs
  >(CREATE_CARD, {
    variables: {
      input: {
        countryCode,
        language: i18next.language || 'FI',
        returnUrl,
      },
    },
    onCompleted: (data) => {
      if (data?.createCard?.cardUrl) {
        creditCardUrlPaymentHighway(data.createCard.cardUrl);
      }
    },
    onError: () => showToast('error.fetchingPaymentHighwayFormFailed', 'error'),
  });

  const addCard = async ({ action }: PaymentDataProps = paymentData) => {
    switch (action) {
      case 'addCard':
        await createCard();
        break;
      case 'addCardModal':
        await createCard();
        sheetModal('addPaymentMethod');
        break;
      default:
        break;
    }
  };

  return { addCard, loadingAddCard };
};

export default useAddPaymentMethod;
