import { IconMap, theme } from 'aimo-ui';
import React from 'react';

const EmptyImage = () => <></>;

const sizes = theme.iconSizes;

export type SvgName = keyof typeof IconMap;

const SvgImage: React.FC<{
  name: SvgName;
  height?: number;
  width?: number;
  testID: string;
  align?: string;
  color?: string;
  style?: any;
  size?: keyof typeof sizes;
  preserveAspectRatio?: 'xMidYMin meet';
}> = ({
  name,
  height,
  width,
  align = 'center',
  style,
  color = theme.colors.transparent,
  size,
  preserveAspectRatio,
  ...rest
}) => {
  const ReturnedImage = IconMap[name] || EmptyImage;
  const numericSize = size ? sizes[size] : undefined;
  return (
    <ReturnedImage
      style={{ ...style, alignSelf: align }}
      width={numericSize ?? width}
      height={numericSize ?? height}
      fill={color}
      preserveAspectRatio={preserveAspectRatio}
      {...rest}
    />
  );
};

export default SvgImage;
