import { useReactiveVar } from '@apollo/client';
import AccountContext from 'context/AccountContext';
import { useContext, useMemo } from 'react';
import { parkingVehicleLicensePlate } from 'states/common';
import { latestVehicleUsed } from 'states/persistInStorage';
import { Vehicle } from 'types/generatedSchemaTypes';

const findVehicle = (licensePlateText: string, vehicles: Vehicle[]) => {
  return vehicles.find(
    (currentVehicle: Vehicle) =>
      currentVehicle.licensePlate?.plateText === licensePlateText
  );
};

/**
 * Get the license plate to use for parking
 * @param selectedLicensePlate - The license plate selected by the user
 * @param alwaysSelectVehicle - If the user has selected to always use a vehicle
 * @param latestVehicle - The latest vehicle used by the user
 * @param vehicles - The vehicles of the user
 * @returns The preselected license plate to use for parking
 */
const getLicensePlate = (
  selectedLicensePlate: string,
  alwaysSelectVehicle: boolean,
  latestVehicle: string,
  vehicles: Vehicle[]
) => {
  if (selectedLicensePlate) {
    return selectedLicensePlate;
  }
  if (alwaysSelectVehicle && latestVehicle) {
    return latestVehicle;
  }
  if (alwaysSelectVehicle && vehicles?.length) {
    return vehicles?.[0].licensePlate?.plateText || '';
  }
  return '';
};

const useSelectedParkingVehicle = () => {
  const {
    customer: { vehicles, applicationPreferences },
  } = useContext(AccountContext);
  const latestVehicle = useReactiveVar(latestVehicleUsed);
  const selectedLicensePlate = useReactiveVar(parkingVehicleLicensePlate);

  const vehicle = useMemo(() => {
    const licensePlate = getLicensePlate(
      selectedLicensePlate,
      applicationPreferences?.alwaysSelectVehicle || false,
      latestVehicle,
      vehicles || []
    );
    return findVehicle(licensePlate, vehicles || []);
  }, [
    selectedLicensePlate,
    vehicles,
    applicationPreferences?.alwaysSelectVehicle,
    latestVehicle,
  ]);

  return vehicle;
};

export default useSelectedParkingVehicle;
