import { useReactiveVar } from '@apollo/client';
import { Meta } from 'aimo-ui';
import DropDownButton from 'components/common/DropDownButton';
import useAllowedParkingTimes from 'hooks/useAllowedParkingTimes';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  activeElementType,
  parkingPricingInfo,
  selectedChargingPricingInfoSegment,
  selectedChargingPricingInfoSegmentNameBasedOnNotes,
  selectedTariffId,
  selectedTariffName,
} from 'states/common';
import { Pill } from 'styles/CommonLayoutStyles';
import { PaymentType } from 'types/common';
import {
  CountryCode,
  EvChargingTariffPlanProps,
  Maybe,
  PoolingGroup,
  StationAddress,
} from 'types/generatedSchemaTypes';
import { getPaymentTypeFromPooling } from 'utils/parkUtils';
import ChargerBadge from './ChargerBadge';
import ParkingPriceEstimateInfo from './ParkingPriceEstimateInfo';
import { getPriceInfoColor } from 'utils/priceUtils';

interface ParkingPriceEstimateProps {
  parkingZoneUid: string;
  parkingZoneCountryCode: CountryCode;
  startDate: Date;
  withLinkText?: string;
  chargingStation?: EvChargingTariffPlanProps;
  onPricePress: () => void;
  pooling?: PoolingGroup;
  showLeftItem?: boolean;
}

type ParkingPriceEstimateItemProps = {
  pillBg?: string;
  parkingZoneUid: string;
  parkingZoneCountryCode: CountryCode;
  setPrice?: ({
    serviceFee,
    total,
  }: {
    serviceFee: number;
    total: any;
  }) => void;
  chargingPricePerKwh?: Maybe<number>;
  paymentType: PaymentType;
  chargingAddress?: Maybe<StationAddress>;
  startDate: Date;
};

const ParkingPriceEstimateItem: React.FC<ParkingPriceEstimateItemProps> = ({
  pillBg,
  parkingZoneUid,
  parkingZoneCountryCode,
  chargingPricePerKwh,
  setPrice,
  paymentType,
  startDate,
}) => {
  return (
    <Pill bg={pillBg}>
      <ParkingPriceEstimateInfo
        parkingZoneUid={parkingZoneUid}
        parkingZoneCountryCode={parkingZoneCountryCode}
        priceEstimateCallback={setPrice}
        chargingPricePerKwh={chargingPricePerKwh}
        paymentType={paymentType}
        startDate={startDate}
      />
    </Pill>
  );
};

const ParkingPriceEstimate = ({
  parkingZoneUid,
  parkingZoneCountryCode,
  startDate,
  onPricePress,
  withLinkText = '',
  pooling,
  showLeftItem = true,
  chargingStation,
}: ParkingPriceEstimateProps) => {
  const { paymentType, disabled, poolingFull } =
    getPaymentTypeFromPooling(pooling);

  // For modal, we need to set info
  parkingPricingInfo({
    poolingId: pooling?.uid || '',
    parkingType: paymentType,
    poolingFull,
  });
  const selectedCustomerSegment = useReactiveVar(selectedTariffId);
  const tariffName = useReactiveVar(selectedTariffName);
  const tariffNameCharging = useReactiveVar(
    selectedChargingPricingInfoSegmentNameBasedOnNotes
  );
  const selectedType = useReactiveVar(activeElementType);
  const chargingTariff = useReactiveVar(selectedChargingPricingInfoSegment);
  const chargingPriceKwh = chargingTariff?.chargingPriceKwh;

  const { pricingSegments, pricingInfoLoading } =
    useAllowedParkingTimes(parkingZoneUid);
  const [price, setPrice] = useState<{ total: any; serviceFee: number }>();

  const { t } = useTranslation();

  const showCharging =
    selectedType === 'CHARGING' || selectedType === 'PARK_AND_CHARGE';

  const total = price?.total;
  const hasPrice = typeof total === 'number';

  const totalWithoutServiceFee = (total || 0) - (price?.serviceFee ?? 0);

  const disabledPricing =
    disabled ||
    (paymentType !== 'free' && typeof totalWithoutServiceFee !== 'number') ||
    (poolingFull && pooling?.outsidePool === 'FORBIDDEN');

  const pillBg = useMemo(
    () =>
      getPriceInfoColor(
        hasPrice,
        chargingPriceKwh !== undefined,
        pooling !== undefined,
        selectedType,
        pricingInfoLoading
      ),
    [chargingPriceKwh, hasPrice, pooling, pricingInfoLoading, selectedType]
  );

  const multipleTariffs = useMemo(
    () => pricingSegments?.length > 1,
    [pricingSegments]
  );

  const RateInfoItem = () => {
    if (showLeftItem) {
      return (
        <Meta testID="dropdown-text">{tariffNameCharging || tariffName}</Meta>
      );
    } else if (showCharging && !showLeftItem) {
      return (
        <ChargerBadge
          availableSpots={chargingStation?.availableSpots || 0}
          loading={!chargingStation}
        />
      );
    } else if (multipleTariffs) {
      return <Meta testID="dropdown-text">{selectedCustomerSegment}</Meta>;
    }
    return (
      <ParkingPriceEstimateItem
        parkingZoneCountryCode={parkingZoneCountryCode}
        paymentType={paymentType}
        parkingZoneUid={parkingZoneUid}
        startDate={startDate}
        pillBg={pillBg}
        chargingPricePerKwh={chargingPriceKwh}
        setPrice={setPrice}
      />
    );
  };

  return (
    <DropDownButton
      disabled={disabledPricing}
      onPress={onPricePress}
      testID="price-estimate-dropdown"
      text={
        disabledPricing || multipleTariffs || !hasPrice
          ? ''
          : t('time.rightNow')
      }
      textItem={
        multipleTariffs && !showCharging ? (
          <ParkingPriceEstimateItem
            parkingZoneCountryCode={parkingZoneCountryCode}
            paymentType={paymentType}
            parkingZoneUid={parkingZoneUid}
            startDate={startDate}
            pillBg={pillBg}
            chargingPricePerKwh={chargingPriceKwh}
            setPrice={setPrice}
          />
        ) : undefined
      }
      withLinkText={withLinkText ? t(withLinkText) : ''}
      leftItem={<RateInfoItem />}
    />
  );
};

export default ParkingPriceEstimate;
