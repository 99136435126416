import DatePickerSelector from 'components/park/timeSelection/DatePickerSelector.web';
import { format } from 'date-fns';
import React, { ChangeEvent } from 'react';
import { Pressable, View } from 'react-native';
import '../styles/datePicker.css';
import { DatePickerProps } from './DatePicker';

const DatePicker: React.FC<DatePickerProps> = ({
  testID,
  currentDate,
  setDate,
  minDate = new Date(),
  maxDate,
  darkMode,
}) => {
  const handleConfirm = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(new Date(event.target.value));
  };

  return (
    <View>
      <Pressable testID={`${testID}-day-selection`}>
        <DatePickerSelector date={currentDate} darkMode={darkMode} />
        <input
          type="date"
          className="datepicker-input"
          onChange={handleConfirm}
          min={format(minDate, 'yyyy-MM-dd')}
          max={maxDate && format(maxDate, 'yyyy-MM-dd')}
          required
        />
      </Pressable>
    </View>
  );
};

export default DatePicker;
