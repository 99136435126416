import { BaseText, Meta, theme } from 'aimo-ui';
import SelectableModalContentItem from 'components/common/SelectableModalContentItem';
import VehicleAvatar from 'components/common/VehicleAvatar';
import AccountContext from 'context/AccountContext';
import useOngoingMultipleSession from 'hooks/useOngoingMultipleSession';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';
import {
  ongoingChargingSheetExpanded,
  ongoingParkingSheetExpanded,
  ongoingParkingStartChargingSheetExpanded,
  selectedOngoingSession,
  selectedSessionIdFromMultiple,
  sheetModal,
} from 'states/common';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
import {
  ChargingSession,
  ChargingStatus,
  ParkingSession,
} from 'types/generatedSchemaTypes';
import {
  getTimeFromDate,
  getTimeLeft,
  getTodayYesterdayOrDate,
} from 'utils/timeUtils';
import { toggleOngoingSessionSheet } from 'utils/ongoingSessionUtils';
import { country, deviceLanguage } from 'states/persistInStorage';

const Column = styled.View`
  flex-direction: column;
  width: 100%;
`;

const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;
const isItemParking = (
  item: ParkingSession | ChargingSession
): item is ParkingSession => {
  return (item as ParkingSession).sessionId !== undefined;
};

const OngoingMultipleSessionsModalContent = () => {
  const { t } = useTranslation();
  const [timeNow, setTimeNow] = useState(new Date());
  const { parkAndChargeData } = useOngoingMultipleSession();
  const {
    customer: { vehicles },
  } = useContext(AccountContext) || [];

  const getVehicleDetails = (item: ParkingSession | ChargingSession) => {
    const licensePlate = isItemParking(item)
      ? item.accessDevice?.value
      : item.licensePlate;
    const { color, icon } =
      vehicles?.find((v) => v.licensePlate?.plateText === licensePlate)
        ?.appSettings || {};
    return { color, icon };
  };

  useEffect(() => {
    // if screen is mounted, update every 15 secs
    const handler = setInterval(() => {
      setTimeNow(new Date());
    }, 15000);

    return () => {
      clearInterval(handler);
    };
  }, [setTimeNow]);

  const handleSelectSession = (item: ParkingSession | ChargingSession) => {
    const type = isItemParking(item) ? 'PARKING' : 'CHARGING';

    const sessionId = isItemParking(item)
      ? item.sessionId
      : item.chargingSessionId;
    selectedOngoingSession(type);
    selectedSessionIdFromMultiple(sessionId);
    sheetModal('');
    ongoingParkingStartChargingSheetExpanded(false);
    if (type === 'PARKING') {
      toggleOngoingSessionSheet(ongoingParkingSheetExpanded, true);
    } else if (type === 'CHARGING') {
      toggleOngoingSessionSheet(ongoingChargingSheetExpanded, true);
    }
  };

  const renderSessionLength = (ending: Date) => {
    const { overdue, timeLeft } = getTimeLeft(ending || null, timeNow);
    const timeLeftTrans = t(overdue ? 'time.overdue' : 'time.remaining', {
      timeLeft,
    });
    return timeLeftTrans;
  };

  const renderVehicleInfo = (item: ParkingSession | ChargingSession) => {
    const licensePlate = isItemParking(item)
      ? item.accessDevice?.value
      : item.licensePlate;
    const usedVehicle = vehicles?.find(
      (vehicle) => vehicle.licensePlate.plateText === licensePlate
    );
    return ` • ${usedVehicle?.licensePlate.plateText}`;
  };

  const renderChargingStatus = (status?: ChargingStatus) => {
    switch (status) {
      case 'START_IN_QUEUE':
        return t('evCharging.starting');
      case 'STOP_IN_QUEUE':
        return t('evCharging.stopping');
      case 'IN_PROGRESS':
      default:
        return t('evCharging.charging');
    }
  };

  const renderParkingText = (parkingSession: ParkingSession) => {
    if (parkingSession.type === 'ANPR') {
      const startDate = new Date(parkingSession?.startDate as Date);
      const parkingCountry = parkingSession.countryCode;
      const parkingStartedTime = getTodayYesterdayOrDate(
        startDate,
        deviceLanguage()
      )
        ? getTodayYesterdayOrDate(startDate, deviceLanguage()) +
          ' ' +
          getTimeFromDate(startDate, parkingCountry || country())
        : getTimeFromDate(startDate, parkingCountry || country());
      return `${t('parking.anprStartedTime')} ${parkingStartedTime}`;
    }
    return (
      t('parking.ongoingTitle') +
      ' ' +
      renderSessionLength(new Date(parkingSession?.predefinedEndDate as string))
    );
  };

  return (
    <>
      <StyledWrapper mb={50}>
        <ScrollView>
          {parkAndChargeData.map((item: ParkingSession | ChargingSession) => (
            <SelectableModalContentItem
              key={
                isItemParking(item) ? item.sessionId : item.chargingSessionId
              }
              isSelected={false}
              disabled={false}
              onPress={() => handleSelectSession(item)}
              testID={`active-session-wrap-${
                isItemParking(item) ? item.sessionId : item.chargingSessionId
              }`}
              iconNode={
                <VehicleAvatar
                  size="l"
                  color={getVehicleDetails(item).color}
                  icon={getVehicleDetails(item).icon}
                />
              }>
              <Column>
                <Row>
                  <BaseText>
                    {isItemParking(item)
                      ? renderParkingText(item)
                      : renderChargingStatus(item?.status as ChargingStatus)}
                  </BaseText>
                </Row>
                <Row>
                  <Meta
                    testID={`charger-item-${
                      isItemParking(item)
                        ? item.sessionId
                        : item.chargingSessionId
                    }-power`}
                    color={theme.colors.gray200}>
                    {item?.parkZone?.name}
                    {renderVehicleInfo(item)}
                  </Meta>
                </Row>
              </Column>
            </SelectableModalContentItem>
          ))}
        </ScrollView>
      </StyledWrapper>
    </>
  );
};

export default OngoingMultipleSessionsModalContent;
