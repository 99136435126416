import { BaseText, Headline3, Meta, Spinner, theme } from 'aimo-ui';
import React from 'react';
import styled from 'styled-components/native';
import Icon from 'components/icons/Icon';
import { testIdentifiers } from 'utils/testIdentifiers';

const Container = styled.Pressable<{
  borderTop?: boolean;
  borderBottom?: boolean;
}>`
  flex-direction: row;
  align-items: center;
  border-bottom-width: ${(p) => (p.borderBottom ? '3px' : '0px')};
  padding: ${() => theme.normalising.heightPixel(10)}px 0;
  min-height: ${() => theme.normalising.heightPixel(60)}px;
  width: 100%;

  ${(p) => (p.borderTop ? 'border-top-width: 3px;' : undefined)}
  border-top-color: ${(p) => p.theme.colors.gray50};
  border-bottom-color: ${(p) => p.theme.colors.gray50};
`;

const ItemWrapper = styled.View<{
  width?: string;
  alignRight?: boolean;
  marginRight?: string;
}>`
  width: ${(p) => (p.width ? p.width + 'px' : 'auto')};
  flex: ${(p) => (p.alignRight ? '1 1 auto' : '0 1 auto')};
  flex-direction: row;
  justify-content: flex-end;
  margin-right: ${(p) => (p.marginRight ? p.marginRight + 'px' : '0px')};
`;

const StyledText = styled(BaseText)<{ active: boolean }>`
  color: ${(p) =>
    p.active ? `${theme.colors.black}` : `${theme.colors.boost.red}`};
`;

type ZoneDetailsRowProps = {
  title: string;
  icon?: React.ReactNode;
  linkText?: string;
  onPress?: () => void;
  loading?: boolean;
  active?: boolean;
  rightItem?: React.ReactNode;
  borderTop?: boolean;
  testIDprefix?: string;
  borderBottom?: boolean;
  chevronStyle?: 'Down' | 'Right';
  headlineTitle?: boolean;
};

const ZoneDetailsRow = ({
  title,
  icon,
  linkText,
  onPress,
  loading = false,
  active = true,
  rightItem,
  borderTop = false,
  testIDprefix,
  borderBottom = true,
  chevronStyle = 'Right',
  headlineTitle = false,
}: ZoneDetailsRowProps) => {
  const TitleComponent = headlineTitle ? Headline3 : StyledText;
  return (
    <Container
      onPress={onPress}
      borderTop={borderTop}
      borderBottom={borderBottom}>
      {loading ? (
        <Spinner small testID="spinner" />
      ) : (
        <>
          {!rightItem && icon && (
            <ItemWrapper width="20" marginRight="10">
              {icon}
            </ItemWrapper>
          )}
          <ItemWrapper
            marginRight="10"
            {...testIdentifiers(
              (testIDprefix as string) && `${testIDprefix}-title`
            )}>
            <TitleComponent active={active}>{title}</TitleComponent>
          </ItemWrapper>
        </>
      )}
      <ItemWrapper
        alignRight
        {...testIdentifiers(
          (testIDprefix as string) && `${testIDprefix}-link`
        )}>
        {rightItem ? (
          rightItem
        ) : (
          <Meta color={theme.colors.boost.green}>{linkText}</Meta>
        )}
        <Icon
          name={`Chevron_${chevronStyle}`}
          size="s"
          color={theme.colors.boost.green}
        />
      </ItemWrapper>
    </Container>
  );
};

export default ZoneDetailsRow;
