import { useReactiveVar } from '@apollo/client';
import { theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import { groupBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { sheetModal } from 'states/common';
import { country } from 'states/persistInStorage';
import { ParkingFacilityProps } from 'types/generatedSchemaTypes';
import {
  getOpeningEndingTimeToday,
  getOpenAndClosingTime,
  removeSecondsFromTimeString,
} from 'utils/timeUtils';
import ZoneDetailsRow from './ZoneDetailsRow';

const ParkingSchedule = ({
  parkingFacilityData,
}: {
  parkingFacilityData?: ParkingFacilityProps | null;
}) => {
  const { t } = useTranslation();
  const countryCode = useReactiveVar(country);

  if (!parkingFacilityData?.timeSlots) {
    return <></>;
  }
    const { startTime, endTime } = getOpenAndClosingTime(
      parkingFacilityData?.timeSlots
    );

    const schedule = parkingFacilityData?.timeSlots;
    const groupedSchedule = groupBy(schedule, 'weekDay');

    const driveInOpeningClosingTime = getOpeningEndingTimeToday(
      startTime || '',
      removeSecondsFromTimeString(endTime || ''),
      groupedSchedule,
      new Date(),
      countryCode
    );

  // TODO: add sheet modal showing opening times when pressed
  return (
    <ZoneDetailsRow
      icon={<Icon
        name="Time-Filled"
        color={
          driveInOpeningClosingTime?.key === 'CLOSED'
            ? theme.colors.boost.red
            : theme.colors.primary
        }
        size="s"
      />}
      title={driveInOpeningClosingTime?.text || ''}
      linkText={t('parking.openHours')}
      onPress={() => sheetModal('parkingHours')}
      testIDprefix="open-hours"
    />
  );
};

export default ParkingSchedule;
