import { gql } from '@apollo/client';

export const READ_HSL_TICKET_PRICING = gql`
  query ReadHslTicketPricing($input: HslPricingInput!) {
    readHslTicketPricing(input: $input) {
      currentPrice
      validityPeriod
      validityMinutes
      subscriberPrice
    }
  }
`;
