import { useReactiveVar } from '@apollo/client';
import { Button } from 'aimo-ui';
import {
  FooterButtonContainer,
  VerticalDistributedContainer,
} from 'components/sheets/SheetLayout';
import { format } from 'date-fns';
import useIsDesktop from 'hooks/useIsDesktop';
import useReadPurchasedPrepaidTickets from 'hooks/useReadPurchasedPrepaidTickets';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectedOngoingSession } from 'states/common';
import { getLocale } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import PaymentCardDropDown from './PaymentCardDropDown';
import VehicleSelectDropDown from './VehicleSelectDropDown';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import { PredefinedTicket } from 'types/generatedSchemaTypes';
import { testIdentifiers } from 'utils/testIdentifiers';

const PrepaidActiveButton = () => {
  const { purchasedPrepaidTickets, loading } = useReadPurchasedPrepaidTickets();
  const selectedVehicle = useSelectedParkingVehicle();
  const prepaidTicketsValidUntil = useMemo(
    () =>
      purchasedPrepaidTickets
        ?.filter(
          (ticket) =>
            ticket?.accessDevice?.value ===
            selectedVehicle?.licensePlate.plateText
        )
        .reduce<PredefinedTicket | undefined>(
          (a, b) => {
            if (a?.endTime && b?.endTime) {
              return a.endTime > b.endTime ? a : b;
            } else if (!a || (a && !a.endTime)) {
              return b;
            } else {
              return a;
            }
          },
          { sessionType: 'PREPAID', type: 'FIXED_DURATION' }
        ),
    [purchasedPrepaidTickets, selectedVehicle]
  );
  const prepaidSession = purchasedPrepaidTickets?.[0];
  const { t } = useTranslation();
  const ongoingSessionType = useReactiveVar(selectedOngoingSession);
  const isDesktop = useIsDesktop();
  if (!prepaidSession || loading) {
    return <></>;
  }

  const dateFormat = prepaidTicketsValidUntil?.endTime
    ? format(
        new Date(prepaidTicketsValidUntil.endTime as string),
        'dd.MM.yyyy HH:mm',
        {
          locale: getLocale(),
        }
      )
    : '';
  const endDateText = t('button.prepaidUntil', { dateTime: dateFormat });

  const renderMarging = () => {
    if (ongoingSessionType !== '') {
      if (isWeb && !isDesktop) {
        return 30;
      }
      return 80;
    }
    return 10;
  };
  return (
    <FooterButtonContainer pb={renderMarging()}>
      <VerticalDistributedContainer>
        <FooterButtonContainer pb={0}>
          <VehicleSelectDropDown />
          <PaymentCardDropDown />
        </FooterButtonContainer>
        <Button
          {...testIdentifiers('prepaid-active-button')}
          size="l"
          text={endDateText}
          disabled={true}
          buttonType="tertiary"
        />
      </VerticalDistributedContainer>
    </FooterButtonContainer>
  );
};

export default PrepaidActiveButton;
