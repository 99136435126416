import { useReactiveVar } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { BottomButtonsLayout, Button } from 'aimo-ui';
import { TitleCard } from 'components/longTermParking';
import PaymentCardDropDown from 'components/park/detail/PaymentCardDropDown';
import VehicleSelectDropDown from 'components/park/detail/VehicleSelectDropDown';
import { FooterButtonContainer } from 'components/sheets/SheetLayout';
import LongTermParkingContext from 'context/LongTermParkingContext';
import useCreateSalesOrder from 'hooks/useCreateSalesOrder';
import useCustomBottomSheet from 'hooks/useCustomBottomSheet';
import useSelectedParkingVehicle from 'hooks/useSelectedParkingVehicle';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  accountRestricted,
  confirmCreatePermitData,
  isFullScreen,
  selectedParkingSpace,
  sheetModal,
} from 'states/common';
import { LongTermStackParamList, RootStackParamList } from 'types/navigation';
import {
  getParkingZoneName,
  getPriceForSalesOrder,
  isSalesOrderFree,
} from 'utils/commonUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const BuyScreen: React.FC = () => {
  const { selectedPmcs } = useContext(LongTermParkingContext);
  const { licensePlate } = useSelectedParkingVehicle() || {};
  const { cardId: creditCardId } = useSelectedPaymentMethod() || {};
  const { navigate, setOptions } =
    useNavigation<StackNavigationProp<RootStackParamList>>();
  const { params } = useRoute<RouteProp<LongTermStackParamList, 'buyScreen'>>();
  const [startDate, setStartDate] = useState(new Date());

  const isUserRestricted = useReactiveVar(accountRestricted);
  const selectedPmc = selectedPmcs?.find((pmc) => pmc.pmcId === params?.pmcId);
  const {
    reservationType,
    name,
    prices,
    parkZone,
    countryCode = 'FI',
  } = selectedPmc || {};
  const { createDraftSalesOrder, loadingSalesOrder } = useCreateSalesOrder();

  const currentParkingSpace = useReactiveVar(selectedParkingSpace);
  const parkingSpaceNotSelected =
    reservationType === 'RESERVED' && !currentParkingSpace?.parkingSpaceUid;

  const parkingZoneName = getParkingZoneName(parkZone);

  const priceString = getPriceForSalesOrder(prices, countryCode);

  const freeSalesOrder = isSalesOrderFree(prices);

  const { snapToIndex } = useCustomBottomSheet();
  const { t } = useTranslation();

  const confirmCreatePermit = () => {
    if (parkingSpaceNotSelected) {
      return navigateToParkingSpaceViewer();
    }
    if (isUserRestricted) {
      sheetModal('accountRestricted');
    } else {
      createDraftSalesOrder({
        pmcId: params?.pmcId,
        selectedPmc,
        startDate,
      });
    }
  };

  const navigateToParkingSpaceViewer = () => {
    return navigate('parkingSpaceMapViewer', {
      spotMap: parkZone?.spotMap ?? '',
    });
  };

  useLayoutEffect(() => {
    if (freeSalesOrder) {
      setOptions({ title: t('button.redeem') });
    }
  }, [freeSalesOrder, setOptions, t]);

  useEffect(() => {
    return () => {
      confirmCreatePermitData(undefined);
    };
  }, []);

  useEffect(() => {
    isFullScreen(true);
    snapToIndex(0);
    return () => {
      isFullScreen(false);
    };
  }, [snapToIndex]);

  const buyButtonText = () => {
    if (parkingSpaceNotSelected) {
      return t('longTermParking.selectSpace');
    }
    if (freeSalesOrder) {
      return t('button.redeem');
    }
    return t('button.buyPermit');
  };
  return (
    <BottomButtonsLayout
      contentBottom={
        <>
          <FooterButtonContainer pb={0} pt={0}>
            <VehicleSelectDropDown />
            <PaymentCardDropDown isFreeSalesOrder={freeSalesOrder} />
          </FooterButtonContainer>
          <Button
            disabled={
              !licensePlate?.plateText ||
              (!freeSalesOrder && !creditCardId) ||
              loadingSalesOrder
            }
            size="l"
            onPress={confirmCreatePermit}
            text={buyButtonText()}
            {...testIdentifiers('long-term-parking-buy-button')}
            loading={loadingSalesOrder}
          />
        </>
      }>
      <TitleCard
        parkingZoneName={parkingZoneName}
        price={priceString}
        location={undefined}
        permitName={name || ''}
        validFrom={startDate}
        changeValid={setStartDate}
        testID="long-term-parking"
        isReserved={reservationType === 'RESERVED'}
        onParkingSpacePress={navigateToParkingSpaceViewer}
      />
    </BottomButtonsLayout>
  );
};

export default BuyScreen;
