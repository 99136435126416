import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_PARK_ZONE_WITH_CHARGING_STATIONS } from '@queries/getParkZoneWithChargingStations';
import { Headline2, Headline3, Meta, theme } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import ChargerBadge from 'components/park/ChargerBadge';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import ParkingContext from 'context/ParkingContext';
import { GET_PARK_ZONE } from 'graphql/queries/getParkZone';
import useEVChargingTariff from 'hooks/useEVChargingTariff';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {
  activeElement,
  activeElementSecondaryUid,
  activeElementType,
  ongoingChargingSession,
  ongoingParkingStartChargingSheetExpanded,
  parkingVehicleLicensePlate,
  selectedOngoingSession,
} from 'states/common';
import { SheetDetailsWrapper, StyledWrapper } from 'styles/ContainerStyles';
import {
  ChargingStation,
  Query,
  QueryParkZoneArgs,
} from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import Logger from 'utils/Logger';
import { getMapDirections, makePoint } from 'utils/mapUtils';

const OngoingParkingZoneDetails = ({
  parkingZoneUid,
  licensePlate,
}: {
  parkingZoneUid: string;
  licensePlate?: string | null;
}) => {
  const selectedOngoingSessionType = useReactiveVar(selectedOngoingSession);
  const ongoingChargingSessionId = useReactiveVar(ongoingChargingSession);
  const { t } = useTranslation();
  const {
    flags: { ev_charging_public_ongoingParking },
  } = useContext(FeatureFlagsContext);
  const showEVCharging = showFeatureFlag(ev_charging_public_ongoingParking);
  const { setCurrentScreen } = useContext(ParkingContext);

  const { data } = useQuery<Query, QueryParkZoneArgs>(
    showEVCharging ? GET_PARK_ZONE_WITH_CHARGING_STATIONS : GET_PARK_ZONE,
    {
      variables: { uid: parkingZoneUid },
      skip: !parkingZoneUid,
    }
  );

  const {
    address,
    commercialZoneView,
    locationType,
    name,
    info,
    parkingZoneChargingStations,
    parkingMethods,
  } = data?.parkZone || {};
  const { street, number, city, marker } = address || {};
  const label = encodeURIComponent(name || '');

  const { EVChargingTariffs, EVChargingTariffsLoading } = useEVChargingTariff({
    chargingStations: (parkingZoneChargingStations || []) as ChargingStation[],
  });
  const availableSpots =
    EVChargingTariffs?.map((e) => e.availableSpots).reduce((acc, curr) => {
      if (
        acc !== null &&
        acc !== undefined &&
        curr !== null &&
        curr !== undefined
      ) {
        acc += curr;
      }
      return acc;
    }, 0) || 0;

  const openDirections = async () => {
    if (marker?.coordinates) {
      const coordinates = makePoint(marker?.coordinates);
      const latLng = `${coordinates?.latitude},${coordinates?.longitude}`;
      const directionsUrl = getMapDirections(label, latLng);
      try {
        await Linking.openURL(directionsUrl);
      } catch (e) {}
    }
  };

  const handleStartChargingProcess = () => {
    activeElement(parkingZoneUid);
    setCurrentScreen('SELECT_TARIFF');
    ongoingParkingStartChargingSheetExpanded(true);
  };

  useEffect(() => {
    // If current parking zone has charging station and is unmanaged, set the active element to be used in the hook
    if (
      parkingZoneChargingStations &&
      parkingZoneChargingStations?.length > 0
    ) {
      activeElementType(
        !parkingMethods?.unmanagedParking ? 'PARK_AND_CHARGE' : 'CHARGING'
      );
      activeElementSecondaryUid(parkingZoneChargingStations?.[0]?.id);
    }
  }, [parkingZoneChargingStations, parkingMethods?.unmanagedParking]);

  useEffect(() => {
    if (licensePlate) {
      parkingVehicleLicensePlate(licensePlate);
    } else {
      Logger.error(
        `Ongoing session in park zone ${parkingZoneUid} is missing the accessDevice (licensePlate: ${licensePlate})`
      );
    }
  }, [licensePlate, parkingZoneUid]);

  return (
    <SheetDetailsWrapper resetPadding={isWeb}>
      <StyledWrapper mb={23}>
        <Headline3 center testID="ongoing-parking-zone-details">
          {commercialZoneView?.zoneCode && (
            <Headline3
              color={theme.colors.primary}
              testID="ongoing-parking-zone">
              {commercialZoneView.zoneCode}{' '}
            </Headline3>
          )}
          {commercialZoneView?.name || name}
        </Headline3>
        <Meta center testID="ongoing-parking-address">
          {street && street + ' '}
          {number && number + ' '}
          {city}
        </Meta>
      </StyledWrapper>
      <ScrollView>
        {showEVCharging &&
          parkingZoneChargingStations &&
          parkingZoneChargingStations.length > 0 &&
          selectedOngoingSessionType !== 'CHARGING' &&
          !ongoingChargingSessionId &&
          licensePlate && (
            <>
              <StyledWrapper>
                <MenuItem
                  menuLabel={t('evCharging.chargeYourCar')}
                  iconName="Charging"
                  testID="ongoing-parking-charge"
                  onPress={() => handleStartChargingProcess()}
                  background={theme.colors.lightBg.green}
                  large
                  withArrow
                  rightComponent={
                    <ChargerBadge
                      availableSpots={availableSpots}
                      loading={EVChargingTariffsLoading}
                      isCollapsed
                    />
                  }
                />
              </StyledWrapper>
            </>
          )}
        {info?.doorCode !== '' && info?.doorCode !== null && (
          <StyledWrapper>
            <MenuItem
              menuLabel={t('parking.garageEntryCode')}
              iconName="Unlocked-Outlined"
              testID="ongoing-parking-doorcode-title"
              background={theme.colors.gray50}
              large
              rightComponent={<Headline2>{info?.doorCode}</Headline2>}
            />
          </StyledWrapper>
        )}
        {locationType !== 'STREET' && (
          <StyledWrapper>
            <MenuItem
              menuLabel={t('parking.navigateTo')}
              iconName="Directions-Outlined"
              testID="ongoing-parking-directions"
              onPress={openDirections}
              background={theme.colors.gray50}
              large
              withArrow
            />
          </StyledWrapper>
        )}
      </ScrollView>
    </SheetDetailsWrapper>
  );
};

export default OngoingParkingZoneDetails;
