import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Meta } from 'aimo-ui';
import ParkingSchedule from 'components/park/ParkingSchedule';
import ParkingZoneDetailsHeadline from 'components/park/ParkingZoneDetailsHeadline';
import ParkingZonePermits from 'components/park/longTermParking/ParkingZonePermits';
import SheetLayout from 'components/sheets/SheetLayout';
import { GET_PARK_ZONE } from 'graphql/queries/getParkZone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { activeElement } from 'states/common';
import { mapCoordinates } from 'states/map';
import { Pill } from 'styles/CommonLayoutStyles';
import { RowSpaceBetween } from 'styles/ContainerStyles';
import { Query, QueryParkZoneArgs } from 'types/generatedSchemaTypes';
import { SetParamsProps } from 'types/navigation';
import { handleElPress, makePoint } from 'utils/mapUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const PermitAndParkZoneScreen = () => {
  const uid = activeElement();
  const { setParams }: SetParamsProps = useNavigation();
  const { t } = useTranslation();
  const { data, error, loading } = useQuery<Query, QueryParkZoneArgs>(
    GET_PARK_ZONE,
    {
      variables: {
        uid,
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  if (error || loading || !data?.parkZone) {
    return <></>;
  }
  const parkingZoneDetails = data.parkZone;
  const { address, parkingFacility } = parkingZoneDetails || {};
  const { marker } = address || {};
  const markerCoordinate = marker && makePoint(marker?.coordinates);

  const handleNamePress = () => {
    if (markerCoordinate) {
      mapCoordinates(markerCoordinate);
    }
  };
  return (
    <SheetLayout
      resetPadding
      leftAlignHeader
      onHeadlinePress={handleNamePress}
      testIDName="park-zone-details"
      onHeadlineButtonPress={() =>
        handleElPress(uid, { latitude: 0, longitude: 0 }, setParams)
      }
      headlineComponent={
        <ParkingZoneDetailsHeadline
          parkZone={parkingZoneDetails}
          markerCoordinate={markerCoordinate}
        />
      }>
      <RowSpaceBetween noFlex padding={0}>
        <ParkingSchedule parkingFacilityData={parkingFacility} />
      </RowSpaceBetween>
      <Pill alignLeft mt={15} testID="30-day-permits-header">
        <Meta {...testIdentifiers('30-day-permits-header-text')}>
          {t('longTermParking.30DayPermits')}
        </Meta>
      </Pill>
      <ParkingZonePermits parkingZoneUid={uid} />
    </SheetLayout>
  );
};

export default PermitAndParkZoneScreen;
