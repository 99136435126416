import { useQuery, useReactiveVar } from '@apollo/client';
import { NavigationContainerRefWithCurrent } from '@react-navigation/core';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { GET_APPLICATION_OPEN_SCREEN } from 'graphql/queries/getApplicationUpdateInfo';
import { useContext, useEffect, useState } from 'react';
import {
  accountRestricted,
  isAppStartupScreenOpen,
  updateInformation,
} from 'states/common';
import { featureCountryCode } from 'states/persistInStorage';
import {
  Query,
  QueryReadApplicationOpenScreenV2Args,
} from 'types/generatedSchemaTypes';
import { RootStackParamList } from 'types/navigation';
import { getFormattedVersion, getShouldAppUpdate } from 'utils/commonUtils';
import { isWeb } from 'utils/deviceUtils';
import useIsAccountRestricted from './useIsAccountRestricted';

const useOpenScreenOnAppStartUp = (
  navigationReady: boolean,
  navigationRef: NavigationContainerRefWithCurrent<RootStackParamList>
) => {
  const featureCountry = useReactiveVar(featureCountryCode);
  useIsAccountRestricted(true);
  const [updateShown, setUpdateShown] = useState(false);
  const {
    flags: { app_version_mandatory, app_version_optional },
  } = useContext(FeatureFlagsContext);

  const {
    data: { readApplicationOpenScreenV2: readApplicationOpenScreen } = {},
  } = useQuery<Query, QueryReadApplicationOpenScreenV2Args>(
    GET_APPLICATION_OPEN_SCREEN,
    {
      variables: {
        countryCode: featureCountry,
      },
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (
      !readApplicationOpenScreen ||
      !navigationReady ||
      !app_version_mandatory ||
      !app_version_optional
    ) {
      return;
    }
    const { hasFailedSession, approveTerms } = readApplicationOpenScreen;

    const navigateToUpdateScreen = (isMandatory: boolean) => {
      updateInformation({
        isMandatory,
        openFailedSessions: hasFailedSession,
      });
      isAppStartupScreenOpen(true);
      navigationRef.navigate('updateAvailable');
    };

    const navigateToApproveUpdatedTerms = () => {
      isAppStartupScreenOpen(true);
      navigationRef.navigate('approveUpdatedTerms');
    };

    const { mandatory, optional } = getShouldAppUpdate(
      app_version_mandatory,
      app_version_optional,
      getFormattedVersion()
    );
    if (!isWeb && (mandatory || optional) && !updateShown) {
      navigateToUpdateScreen(mandatory);
      setUpdateShown(true);
    } else if (approveTerms) {
      //Navigation on web is not actually ready so we need to add timeout
      setTimeout(() => navigateToApproveUpdatedTerms(), isWeb ? 2000 : 0);
    } else if (hasFailedSession) {
      accountRestricted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    readApplicationOpenScreen,
    navigationRef,
    navigationReady,
    app_version_mandatory,
    app_version_optional,
  ]);
};

export default useOpenScreenOnAppStartUp;
