import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { BaseText, Button, FieldInput, Headline1 } from 'aimo-ui';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import CloseButton from 'components/common/CloseButton';
import { showToast } from 'components/common/CustomToast';
import AccountContext from 'context/AccountContext';
import { CREATE_PERMIT_APPLICATION } from 'graphql/mutations/createPermitApplication';
import useCreateApplicationInputFactory from 'hooks/useCreateApplicationInputFactory';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isFullScreen } from 'states/common';
import styled from 'styled-components/native';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import {
  Mutation,
  MutationCreatePermitApplicationArgs,
} from 'types/generatedSchemaTypes';
import {
  RootStackParamList,
  StreetParkingStackParamList,
} from 'types/navigation';

const ContainerRight = styled.View`
  align-items: flex-end;
  margin-bottom: 20px;
`;

const StyledBaseText = styled(BaseText)`
  flex-shrink: 1;
`;

const StreetParkingApplyScreen: React.FC = () => {
  const [rationale, setRationale] = useState('');
  const { customer } = useContext(AccountContext);
  const { t } = useTranslation();
  const { goBack, canGoBack, navigate } =
    useNavigation<StackNavigationProp<StreetParkingStackParamList>>();
  const { replace } = useNavigation<StackNavigationProp<RootStackParamList>>();
  const address = customer.strongAuthentication?.addresses?.[0];
  const createApplicationInput = useCreateApplicationInputFactory(rationale);
  const [createPermitApplication, { loading }] = useMutation<
    Mutation,
    MutationCreatePermitApplicationArgs
  >(CREATE_PERMIT_APPLICATION, {
    onCompleted: () => {
      replace('mainTabs', {
        screen: 'servicesScreen',
        params: {
          screen: 'servicesMain',
        },
      });
    },
    onError: () => showToast('error.applicationCreationFailed', 'error'),
  });

  useEffect(() => {
    isFullScreen(true);
    return () => {
      isFullScreen(false);
    };
  }, []);

  const sendApplication = () => {
    createPermitApplication({ variables: { input: createApplicationInput } });
  };

  const handleNavigateBack = () => {
    if (canGoBack()) {
      goBack();
    } else {
      navigate('cityPmcs');
    }
    return true;
  };

  const addressString = `${address?.streetName} ${address?.streetNumber}`;

  return (
    <>
      <BottomSheetScrollViewCustom bottomThreshold={0}>
        <ContainerRight>
          <CloseButton
            testID="city-pmc-apply-close"
            onPress={handleNavigateBack}
          />
        </ContainerRight>
        <StyledWrapper center mb={40}>
          <Headline1>{t('cityPermits.applicationTitle')}</Headline1>
        </StyledWrapper>
        <StyledWrapper maxWidth={300} center mb={25}>
          <BaseText>
            {t('cityPermits.applicationMainText', { addressString })}
          </BaseText>
        </StyledWrapper>
        <StyledWrapper maxWidth={300} center mb={25}>
          <StyledBaseText>{t('cityPermits.neededDocuments')}</StyledBaseText>
        </StyledWrapper>
        <StyledWrapper maxWidth={300} center mb={45}>
          <BaseText>{t('cityPermits.orApplyNow')}</BaseText>
        </StyledWrapper>
        <FieldInput
          value={rationale}
          title={t('cityPermits.applicationRationale')}
          onChangeText={setRationale}
          testID="city-pmc-apply-input"
        />
      </BottomSheetScrollViewCustom>
      <ColumnWithMargins mb={20}>
        <Button
          loading={loading}
          disabled={rationale.length < 10 || loading}
          size="l"
          onPress={sendApplication}
          text={t('button.send')}
          testID="street-parking-apply-send"
        />
      </ColumnWithMargins>
    </>
  );
};

export default StreetParkingApplyScreen;
