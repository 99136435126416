import React from 'react';
import { RouteProp, useRoute } from '@react-navigation/native';
import { RootStackParamList } from 'types/navigation';
import {
  ScreenContainer,
  StyledWrapper,
  ColumnWithMargins,
  RowSpaceBetween,
  StyledImage,
  ScreenWrapper,
  SheetDetailsWrapper,
} from 'styles/ContainerStyles';
import { Title, theme } from 'aimo-ui';
import { useTranslation } from 'react-i18next';
import { StopChargingSessionResponse } from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';
import Sheet from 'components/sheets/Sheet';
import ChargingReportContent from 'components/receipt/ChargingReportContent';
import { View } from 'react-native';

const GetSheetLayout = ({
  sessionData,
}: {
  sessionData?: StopChargingSessionResponse;
}) => {
  if (isWeb) {
    return <ChargingReportContent sessionData={sessionData} />;
  }
  return (
    <Sheet snappingPoints={[500]} hideTabBarOnIndex={1} hideHandle>
      <SheetDetailsWrapper pt={20}>
        <ChargingReportContent sessionData={sessionData} />
      </SheetDetailsWrapper>
    </Sheet>
  );
};

const ChargingReportScreen = () => {
  const { t } = useTranslation();
  const { params } =
    useRoute<RouteProp<RootStackParamList, 'chargingReport'>>();
  const data = params?.receiptData || {};

  return (
    <>
      <ScreenContainer background={theme.colors.gray50}>
        <ScreenWrapper tabBarHeight={10}>
          <StyledWrapper center maxWidth={550}>
            <ColumnWithMargins mb={40}>
              <RowSpaceBetween padding={0}>
                <View />
              </RowSpaceBetween>
            </ColumnWithMargins>
            <StyledImage
              testID="charging-report-image"
              width={173}
              height={62}
              center
              source={require('assets/images/parking-receipt-car.png')}
            />
            <StyledWrapper center maxWidth={300}>
              <Title center testID="charging-report-header">
                {t('evCharging.receipt.header')}
              </Title>
            </StyledWrapper>
          </StyledWrapper>
          {isWeb && <GetSheetLayout sessionData={data} />}
        </ScreenWrapper>
      </ScreenContainer>
      {!isWeb && <GetSheetLayout sessionData={data} />}
    </>
  );
};

export default ChargingReportScreen;
