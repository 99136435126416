import { gql } from '@apollo/client';

export const UNLOCK_URL_LOCKED_SEGMENT = gql`
  mutation UnlockUrlLockedSegment($input: UnlockUrlSegmentInput!) {
    unlockUrlLockedSegment(input: $input) {
      errorMessage
      httpResponseStatusCode
      httpResponseStatusMessage
      response
      username
    }
  }
`;
