import React from 'react';
import PrepaidTicket from 'screens/prepaidTickets/PrepaidTicket';
import { ScreenContainer, SimpleFullContainer } from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';

const Container: any = isWeb ? ScreenContainer : SimpleFullContainer;

const PrepaidTicketHistoryDetailsScreen: React.FC = () => (
  <Container mt={200}>
    <PrepaidTicket isHistory={true} />
  </Container>
);
export default PrepaidTicketHistoryDetailsScreen;
