import { gql } from '@apollo/client';

export const READ_PARK_ZONE_PRICE_ESTIMATES = gql`
  query readParkZonePriceEstimates($input: [ParkZonePriceEstimateInput!]!) {
    readParkZonePriceEstimates(input: $input) {
      totalAmount
      breakdown {
        serviceFee {
          amount
        }
      }
    }
  }
`;
