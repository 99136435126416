import React from 'react';
import ModalStackHeader, { StackHeaderCustomProps } from './ModalStackHeader';
import StackHeader from './StackHeader';

export const ModalHeaderComponent = (props: StackHeaderCustomProps) => (
  <ModalStackHeader {...props} />
);

export const StackHeaderComponent = (props: StackHeaderCustomProps) => (
  <StackHeader {...props} />
);

export const StackHeaderComponentNoHeaderBack = (
  props: StackHeaderCustomProps
) => <StackHeader {...props} headerBackIconVisible={false} />;
