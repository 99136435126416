import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Headline1, Headline3, Meta, Spinner, theme } from 'aimo-ui';
import SheetLayout from 'components/sheets/SheetLayout';
import ParkingContext from 'context/ParkingContext';
import useBackButton from 'hooks/useBackButton';
import useChargingStation from 'hooks/useChargingStation';
import useCreateSession from 'hooks/useCreateSession';
import useBottomSheet from 'hooks/useCustomBottomSheet';
import useHasParkingSession from 'hooks/useHasParkingSession';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet } from 'react-native';
import {
  activeElementType,
  ongoingParkingSheetExpanded,
  selectedOngoingSession,
  selectedParkingZoneTab,
  sheetModal
} from 'states/common';
import { mapCoordinates } from 'states/map';
import styled from 'styled-components/native';
import { RowNoAlign, StyledWrapper } from 'styles/ContainerStyles';
import { Charger } from 'types/common';
import { IsFocusedProps, SetParamsProps } from 'types/navigation';
import { handleCloseOngoingParkingStartChargingSheet } from 'utils/chargingUtils';
import { handleElPress, makePoint } from 'utils/mapUtils';
import { ParkingSheetProps } from '../ParkingWizardSheet';
import PublicDisplayId from './PublicDisplayId';

const ImageContainer = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BGWrapper = styled.View`
  flex: 1;
  max-height: 150px;
  min-height: 100px;
  background-color: ${(p) => p.theme.colors.lightBg.yellow};
  padding: 25px 30px;
  border-radius: 8px;
`;

const InfoText = styled(Meta)`
  font-weight: 500;
  margin-top: 24px;
`;

const BottomMargin = styled.View<{ addMargin: boolean }>`
  margin-bottom: ${({ addMargin }) =>
    addMargin
      ? theme.normalising.heightPixel(250)
      : theme.normalising.heightPixel(150)}px;
`;

const styles = StyleSheet.create({
  imageCharger: {
    width: 87,
    height: 87,
    alignSelf: 'center',
  },
  imageCar: {
    width: 102,
    height: 67,
    alignSelf: 'center',
  },
});

export const ConnectCharger: React.FC<ParkingSheetProps> = ({
  autoStartCharging,
}) => {
  const { t } = useTranslation();
  const selectedTab = useReactiveVar(selectedParkingZoneTab);

  const {
    parkingZoneResult: parkingZone,
    selectedEvCharger,
    isOngoingParking,
    setCurrentScreen,
    parkingConditions: { vehicle },
  } = useContext(ParkingContext);

  const { createParking, createCharging } = useCreateSession({
    parkingZoneType:
      selectedTab === 'PARK_AND_CHARGE' && autoStartCharging
        ? 'PARK_AND_CHARGE'
        : 'CHARGING',
    onErrorCallback: () => setCurrentScreen('SHOW_DETAILS'),
  });

  const {
    name = '',
    address,
    uid = '',
    commercialZoneView,
  } = parkingZone?.data?.parkZone || {};

  const { marker } = address || {};

  const { id: selectedChargerId, publicDisplayId } =
    selectedEvCharger || ({} as Charger);

  const ongoingParkingType = useReactiveVar(selectedOngoingSession);
  const { collapse } = useBottomSheet();
  const { setParams, isFocused }: SetParamsProps & IsFocusedProps =
    useNavigation();
  const isSheetModal = !!useReactiveVar(sheetModal);
  const selectedType = useReactiveVar(activeElementType);
  const { hasParkingSession } = useHasParkingSession({
    parkingZoneUid: uid,
    vehicle: vehicle,
  });

  const memoizedHasParkingSession = useRef(hasParkingSession);

  const onBackPress = () => {
    // Make sure onBackPress is not overwritten if modal is open
    if (!isFocused()) {
      return false;
    }
    if (isSheetModal) {
      sheetModal('');
    } else {
      handleBackPress();
    }
    return true;
  };
  useBackButton(onBackPress);

  useEffect(() => {
    collapse();
  }, [collapse]);

  const {
    data: stationData,
    startPolling,
    stopPolling,
  } = useChargingStation(selectedChargerId);
  const stationOccupied = stationData?.readChargingStationV2?.evses?.some(
    (evse) =>
      evse.publicDisplayId === publicDisplayId && evse.status === 'OCCUPIED'
  );

  useEffect(() => {
    !stationOccupied && autoStartCharging ? startPolling(3000) : stopPolling();
    return () => stopPolling();
  }, [stationOccupied, startPolling, stopPolling, autoStartCharging]);

  useEffect(() => {
    if (
      stationOccupied &&
      autoStartCharging &&
      ongoingParkingSheetExpanded() === false
    ) {
      (async () => {
        !memoizedHasParkingSession.current && selectedTab === 'PARK_AND_CHARGE'
          ? await createParking()
          : await createCharging();
      })();
    }
  }, [
    stationOccupied,
    createCharging,
    createParking,
    memoizedHasParkingSession,
    selectedTab,
    autoStartCharging,
  ]);

  if (!parkingZone?.data?.parkZone?.uid) {
    setCurrentScreen('SEARCH');
    return <></>;
  } else if (!selectedEvCharger) {
    setCurrentScreen('SELECT_CHARGER');
    return <></>;
  }

  const markerCoordinate = marker && makePoint(marker?.coordinates);

  const handleBackPress = () => {
    if (isOngoingParking) {
      return setCurrentScreen('SELECT_CHARGER');
    }
    if (
      hasParkingSession ||
      selectedType === 'CHARGING' ||
      selectedTab === 'CHARGE'
    ) {
      return setCurrentScreen('SHOW_DETAILS');
    }
    return setCurrentScreen('CHOOSE_END_TIME');
  };

  const handleNamePress = () => {
    if (markerCoordinate) {
      mapCoordinates(markerCoordinate);
    }
  };

  const onClosePress = () => {
    if (isOngoingParking) {
      handleCloseOngoingParkingStartChargingSheet();
      setCurrentScreen('SEARCH');
    } else {
      handleElPress(uid, { latitude: 0, longitude: 0 }, setParams);
    }
  };

  return (
    <>
      <SheetLayout
        onHeadlinePress={handleNamePress}
        testIDName="connect-charger-layout"
        withBackButton={true}
        onBackPress={handleBackPress}
        onHeadlineButtonPress={onClosePress}
        headlineComponent={
          <Headline3
            center
            color={theme.colors.link}
            testID="parkzone-commercial-zone-code">
            {commercialZoneView?.zoneCode ?? ''}{' '}
            <Headline3 color={theme.colors.black}>
              {commercialZoneView?.name ?? name}
            </Headline3>
          </Headline3>
        }>
        <StyledWrapper>
          <RowNoAlign>
            <PublicDisplayId
              isAvailable={true}
              publicDisplayId={publicDisplayId}
              testID="evcharger"
            />
          </RowNoAlign>
        </StyledWrapper>
        <StyledWrapper>
          <Headline1 center>
            {`${t('evCharging.connectChargerToYourCar')}...`}
          </Headline1>
        </StyledWrapper>
        <BGWrapper>
          <ImageContainer>
            <Image
              testID="car-image"
              style={styles.imageCar}
              source={require('assets/images/ev-car.png')}
            />
            <Spinner />
            <Image
              testID="charger-image"
              style={styles.imageCharger}
              source={require('assets/images/charger.png')}
            />
            <></>
          </ImageContainer>
        </BGWrapper>
        {autoStartCharging && (
          <InfoText center color={theme.colors.gray300}>
            {t('evCharging.connectChargerInfo')}
          </InfoText>
        )}
        <BottomMargin addMargin={ongoingParkingType !== ''} />
      </SheetLayout>
    </>
  );
};

export default ConnectCharger;
