
import { useQuery, useReactiveVar } from '@apollo/client';
import { READ_PARKING_SESSION } from 'graphql/queries/readParkingSession';
import { currentParkingSession } from 'states/common';
import {
  Query,
  QueryReadParkingSessionArgs,
  ParkingPaymentState
} from 'types/generatedSchemaTypes';

const useSessionPaymentState = () => {
    const sessionId = useReactiveVar(currentParkingSession);
    let paymentState: ParkingPaymentState | undefined;

    const { data, loading, startPolling, stopPolling } = useQuery<
    Query,
    QueryReadParkingSessionArgs
  >(READ_PARKING_SESSION, {
    variables: { sessionId: sessionId || '' },
    skip: !sessionId || sessionId.length === 0,
    fetchPolicy: 'cache-and-network',
  });

  if (data) {
    paymentState = data.readParkingSession?.paymentState as ParkingPaymentState;
    (paymentState === 'NotInitiated' || paymentState === 'Pending' || paymentState === 'Retrying') ?
      startPolling(1000) :
      stopPolling();
  }

  const pending =
    loading ||
    paymentState === 'NotInitiated' ||
    paymentState === 'Pending' ||
    paymentState === 'Retrying'
    ;

    return {
        pending,
        paymentState
    };
};

export default useSessionPaymentState;
