import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'aimo-ui';
import RemoveButton from 'components/common/RemoveButton';
import styled from 'styled-components/native';
import { testIdentifiers } from 'utils/testIdentifiers';

type VehicleFormButtonsProps = {
  isNew: boolean;
  handleSubmit: () => void;
  onDelete?: () => void;
  loading?: boolean;
  hasUnsavedChanges?: boolean;
};

const RemoveButtonWrapper = styled.View`
  margin-right: 10px;
`;

const ButtonWrapper = styled.View`
  flex: 1 1 auto;
`;

type AddButtonProps = {
  handleSubmit: () => void;
  loading?: boolean;
};

interface EditButtonsProps extends AddButtonProps {
  onDelete?: () => void;
  hasUnsavedChanges?: boolean;
}

const EditButtons = ({
  handleSubmit,
  onDelete,
  loading,
  hasUnsavedChanges,
}: EditButtonsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <RemoveButtonWrapper>
        <RemoveButton
          buttonType="alert"
          onPress={onDelete}
          testID="remove-vehicle-button"
        />
      </RemoveButtonWrapper>
      <ButtonWrapper>
        <Button
          {...testIdentifiers('update-vehicle-button')}
          size="l"
          buttonType="secondary"
          text={t('button.updateVehicle')}
          onPress={handleSubmit}
          loading={loading}
          disabled={!hasUnsavedChanges || loading}
        />
      </ButtonWrapper>
    </>
  );
};

const AddButton = ({ handleSubmit, loading }: AddButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      loading={loading}
      {...testIdentifiers('add-vehicle-button')}
      text={loading ? t('button.adding') : t('button.addVehicle')}
      size="l"
      buttonType="secondary"
      onPress={handleSubmit}
      disabled={loading}
    />
  );
};

const VehicleFormButtons = ({
  isNew,
  handleSubmit,
  onDelete,
  hasUnsavedChanges,
  loading,
}: VehicleFormButtonsProps) => {
  return isNew ? (
    <AddButton handleSubmit={handleSubmit} loading={loading} />
  ) : (
    <EditButtons
      handleSubmit={handleSubmit}
      loading={loading}
      hasUnsavedChanges={hasUnsavedChanges}
      onDelete={onDelete}
    />
  );
};
export default VehicleFormButtons;
