import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { BaseText, Meta, theme } from 'aimo-ui';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { hasAcceptedTerms } from 'hooks/useHasAcceptance';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  activeElement,
  currentCustomerCountry,
  sheetModal,
} from 'states/common';
import { featureCountryCode } from 'states/persistInStorage';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
import { CountryCode } from 'types/generatedSchemaTypes';
import { RootNavProps, SetParamsProps } from 'types/navigation';
import { changeFutureAppCountryCode } from 'utils/countryUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { handleElPress } from 'utils/mapUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const Box = styled.TouchableHighlight<{ isSelected: boolean }>`
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding: 0px 20px;
  margin-bottom: 10px;
  border-width: 1px;
  height: 60px;
  background-color: ${({ isSelected }) =>
    isSelected ? theme.colors.white : theme.colors.gray50};
  border-color: ${({ isSelected }) =>
    isSelected ? theme.colors.boost.green : theme.colors.gray50};
`;

const SelectButton = ({
  isSelected,
  item,
  testID,
}: {
  isSelected: boolean;
  item: CountryCode;
  testID: string;
}) => {
  const { t } = useTranslation();
  const { setParams }: SetParamsProps = useNavigation();
  const uid = useReactiveVar(activeElement);
  const currentCountry = useReactiveVar(currentCustomerCountry);
  const { navigate } = useNavigation<RootNavProps>();
  const {
    customer: { acceptances },
  } = useContext(AccountContext);

  const setAppCountry = (appCountry: CountryCode) => {
    const hasAcceptedTermsAppCountry = hasAcceptedTerms(
      appCountry,
      'TERMS_ACCEPTED',
      acceptances
    );
    sheetModal('');
    if (hasAcceptedTermsAppCountry) {
      featureCountryCode(appCountry);
    } else {
      changeFutureAppCountryCode(appCountry);
      navigate('approveTermsAppLocation');
    }
    // Reset selected zone when changing country, if the selected country is not the country of zone
    if (appCountry !== currentCountry) {
      handleElPress(uid, { latitude: 0, longitude: 0 }, setParams);
    }
  };

  return (
    <Box
      isSelected={isSelected}
      onPress={() => setAppCountry(item)}
      underlayColor={theme.colors.white}
      {...testIdentifiers(`${testID}-select-button`)}>
      <BaseText {...testIdentifiers(`select-button-text-${testID}`)}>
        {t(`account.appLocations.${item}`)}
      </BaseText>
    </Box>
  );
};

const AppLocation = () => {
  const appCountry = useReactiveVar(featureCountryCode);
  const {
    flags: { show_norway },
  } = useContext(FeatureFlagsContext);
  const showNorway = showFeatureFlag(show_norway);
  const { t } = useTranslation();
  return (
    <>
      <StyledWrapper center>
        <Meta center color={theme.colors.gray200}>
          <BaseText
            color={theme.colors.gray200}
            {...testIdentifiers('app-location-desc')}>
            {t('account.appLocationDesc')}
          </BaseText>
        </Meta>
      </StyledWrapper>
      <StyledWrapper maxWidth={350} center mb={0}>
        <SelectButton isSelected={appCountry === 'FI'} item="FI" testID="fi" />
        <SelectButton isSelected={appCountry === 'SE'} item="SE" testID="se" />
        {showNorway && (
          <SelectButton
            isSelected={appCountry === 'NO'}
            item="NO"
            testID="no"
          />
        )}
      </StyledWrapper>
    </>
  );
};

export default AppLocation;
