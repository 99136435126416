import { useLazyQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import {
  BaseText,
  BottomButtonsLayout,
  Button,
  FieldInput,
  theme,
} from 'aimo-ui';
import Icon from 'components/icons/Icon';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { CAN_DELETE_CUSTOMER } from 'graphql/queries/canDeleteCustomer';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  accountRemovalBlockers,
  accountRemoveWithWarnings,
  sheetModal,
} from 'states/common';
import {
  ColumnWithMargins,
  SheetDetailsWrapper,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { Query, QueryCanDeleteCustomerArgs } from 'types/generatedSchemaTypes';
import { AccountNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const lockIcon = <Icon name="Locked-Outlined" color={theme.colors.gray200} />;

const blockAccountRemovalReasons = [
  'ongoingParking',
  'ongoingCharging',
  'failedPayments',
];

const ContactInfo: React.FC = () => {
  const { t } = useTranslation();
  const { customer } = useContext(AccountContext);
  const { goBack } = useNavigation<AccountNavProps>();
  const {
    flags: {
      account_contact_edit,
      account_contact_phone_number,
      account_contact_invoice_email,
    },
  } = useContext(FeatureFlagsContext);
  const allowContactEdit = showFeatureFlag(account_contact_edit);
  const showPhoneNumber = showFeatureFlag(account_contact_phone_number);
  const showInvoiceEmail = showFeatureFlag(account_contact_invoice_email);
  const [invoiceEmail, setInvoiceEmail] = useState(
    customer?.invoiceEmail || ''
  );
  const [contactEmail, setContactEmail] = useState(
    customer?.contactEmail || ''
  );
  const [phoneNumber, setPhoneNumber] = useState(customer?.mobilePhone || '');
  const [isLoading, setIsLoading] = useState(false);

  const [fetchCanDeleteCustomer] = useLazyQuery<
    Query,
    QueryCanDeleteCustomerArgs
  >(CAN_DELETE_CUSTOMER, {
    fetchPolicy: 'no-cache',
    variables: { fetchEvPayments: true },
  });

  const handlePreventCustomerDeletion = (reasons: Record<string, unknown>) => {
    if (reasons.customerSubTypes) {
      sheetModal('accountRemovalErrorLinkedService');
    } else {
      accountRemoveWithWarnings(true);
      Object.keys(reasons).forEach((key) => {
        accountRemovalBlockers({
          ...accountRemovalBlockers(),
          [key]: reasons[key],
        });
        if (blockAccountRemovalReasons.includes(key)) {
          accountRemoveWithWarnings(false);
        }
      });
      sheetModal('preventAccountRemoval');
    }
  };

  const handleRemove = async () => {
    setIsLoading(true);
    accountRemovalBlockers({});
    const { data: canDeleteCustomerData, error: canDeleteCustomerError } =
      await fetchCanDeleteCustomer();
    setIsLoading(false);

    if (
      canDeleteCustomerError?.graphQLErrors &&
      canDeleteCustomerError?.graphQLErrors?.length > 0
    ) {
      if (
        canDeleteCustomerError?.graphQLErrors[0].extensions.code ===
        'PREVENT_CUSTOMER_DELETION'
      ) {
        const preventCustomerDeletionReasons = canDeleteCustomerError
          .graphQLErrors[0].extensions.data as Record<string, unknown>;
        handlePreventCustomerDeletion(preventCustomerDeletionReasons);
      } else {
        sheetModal('accountRemovalErrors');
      }
    } else if (canDeleteCustomerData) {
      goBack();
      sheetModal('removeAccount');
    }
  };

  return (
    <SheetDetailsWrapper pt={isWeb ? 60 : 20}>
      <BottomButtonsLayout
        scrollable={true}
        contentBottom={
          <>
            {allowContactEdit && (
              <StyledWrapper mb={20}>
                <Button
                  size="l"
                  text={t('account.updateDetails')}
                  disabled={!allowContactEdit}
                  testID="contact-info-update-button"
                />
              </StyledWrapper>
            )}
            <StyledWrapper mb={20}>
              <Button
                size="l"
                text={t('account.removeAccount')}
                {...testIdentifiers('contact-info-remove-button')}
                buttonType="tertiary"
                onPress={handleRemove}
                loading={isLoading}
              />
            </StyledWrapper>
          </>
        }>
        <>
          {showInvoiceEmail && (
            <StyledWrapper mb={20}>
              <FieldInput
                disabled={!allowContactEdit}
                title={t('account.emailInvoice')}
                value={invoiceEmail}
                onChangeText={setInvoiceEmail}
                testID="contact-info-email-invoice"
                icon={!allowContactEdit && lockIcon}
              />
            </StyledWrapper>
          )}
          <StyledWrapper mb={20}>
            <FieldInput
              disabled={!allowContactEdit}
              title={t('account.emailContact')}
              value={contactEmail}
              onChangeText={setContactEmail}
              {...testIdentifiers('contact-info-email-contact')}
              icon={!allowContactEdit && lockIcon}
            />
          </StyledWrapper>
          {showPhoneNumber && (
            <StyledWrapper mb={20}>
              <FieldInput
                disabled={!allowContactEdit}
                title={t('account.mobilePhone')}
                value={phoneNumber}
                onChangeText={setPhoneNumber}
                testID="contact-info-phone-number"
                icon={!allowContactEdit && lockIcon}
              />
            </StyledWrapper>
          )}
          {!allowContactEdit && (
            <ColumnWithMargins mt={20} ml={20} mb={20} mr={20}>
              <BaseText color={theme.colors.black}>
                {t('account.updateDetailsHelpText')}
              </BaseText>
            </ColumnWithMargins>
          )}
        </>
      </BottomButtonsLayout>
    </SheetDetailsWrapper>
  );
};

export default ContactInfo;
