import { BaseText, Headline1, ListItem, Meta, theme } from 'aimo-ui';
import {
  CardBackground,
  CutWrapper,
  LeftCut,
  RightCut,
} from 'components/common/CrickleCutCardBackground';
import Icon from 'components/icons/Icon';
import ValidFromPicker from 'components/longTermParking/ValidFromPicker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ColumnWithMargins,
  StyledImage,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { BlackDot, ListItemWrapper, Row } from './styles';
import { selectedParkingSpace } from 'states/common';
import { useReactiveVar } from '@apollo/client';
import { testIdentifiers } from 'utils/testIdentifiers';

type parkingInfo = {
  parkingZoneName: string;
  location?: string;
  permitName: string;
  price: string;
  validFrom?: Date;
  validUntil?: string;
  changeValid?: (newDate: Date) => void;
  testID?: string;
  isCityPmc?: boolean;
  isReserved?: boolean;
  onParkingSpacePress?: () => void;
};

const RightContent = ({
  text,
  testID,
  textColor,
}: {
  text: string;
  testID?: string;
  textColor?: string;
}) => (
  <Row>
    <Meta color={textColor} {...testIdentifiers(`${testID}-content`)}>
      {text}
    </Meta>
    <Icon color={textColor} testID={`${testID}-icon`} name="Chevron_Right" />
  </Row>
);

const SelectListItem = ({
  title,
  selection,
  onPress,
  testID,
  noBorder = false,
  textColor,
}: {
  title: string;
  selection: string;
  onPress?: () => void;
  testID: string;
  noBorder?: boolean;
  textColor?: string;
}) => (
  <ListItemWrapper noBorder={noBorder}>
    <ListItem
      testID={testID}
      onPress={onPress}
      withBackground={theme.colors.lightBg.green}
      item={null}
      indexValue={0}
      titleComponent={() => (
        <Meta
          testID={`${testID}-title`}
          color={textColor ?? theme.colors.gray300}>
          {title}
        </Meta>
      )}
      rightContentComponent={() => (
        <RightContent textColor={textColor} testID={testID} text={selection} />
      )}
    />
  </ListItemWrapper>
);

export const ChangeSpaceItem = ({
  testID,
  onPress,
  parkingSpaceName,
}: {
  testID: string | undefined;
  onPress?: () => void;
  parkingSpaceName?: string;
}) => {
  const { t } = useTranslation();
  const parkingSpace = useReactiveVar(selectedParkingSpace);
  return (
    <SelectListItem
      title={t('longTermParking.parkingSpace')}
      selection={
        parkingSpaceName ??
        parkingSpace?.name ??
        t('longTermParking.selectSpace')
      }
      onPress={onPress}
      textColor={
        typeof onPress === 'function' ? undefined : theme.colors.gray200
      }
      testID={`${testID}-space-selection`}
    />
  );
};

const TitleCard = ({
  testID,
  parkingZoneName,
  permitName,
  price,
  validFrom,
  changeValid,
  isCityPmc = false,
  validUntil,
  location,
  isReserved,
  onParkingSpacePress,
}: parkingInfo) => {
  const { t } = useTranslation();
  return (
    <CardBackground>
      <StyledWrapper mb={theme.normalising.heightPixel(27)}>
        <StyledImage
          center={true}
          width={114}
          height={98}
          source={require('assets/images/permit.png')}
          testID={`${testID}-image`}
        />
      </StyledWrapper>
      <StyledWrapper center={true} mb={theme.normalising.heightPixel(25)}>
        <Headline1 center={true} testID={`${testID}-title`}>
          {!isCityPmc ? t('longTermParking.title') : t('cityPermits.title')}
        </Headline1>
        <Meta center={true} testID={`${testID}-info`}>
          {!isCityPmc
            ? t('longTermParking.infoText')
            : t('cityPermits.infoText')}
        </Meta>
      </StyledWrapper>
      <StyledWrapper center={true}>
        <BaseText center {...testIdentifiers(`${testID}-garage-name`)}>
          {parkingZoneName}
        </BaseText>
        <Row>
          <BaseText color={theme.colors.gray300} testID={`${testID}-location`}>
            {location}
          </BaseText>
          {location && <BlackDot />}
          <BaseText center testID={`${testID}-permit-name`}>
            {permitName}
          </BaseText>
        </Row>
        {isCityPmc ? (
          <ColumnWithMargins mt={theme.normalising.heightPixel(20)}>
            <Headline1 center testID={`${testID}-city-price`}>
              {price}
            </Headline1>
          </ColumnWithMargins>
        ) : (
          <BaseText center testID={`${testID}-price`}>
            {price}
          </BaseText>
        )}
      </StyledWrapper>
      <CutWrapper>
        <LeftCut />
        <RightCut />
      </CutWrapper>
      <StyledWrapper center={true} maxWidth={320} mb={0}>
        {validFrom && changeValid && (
          <ValidFromPicker
            validFrom={validFrom}
            onConfirm={changeValid}
            testID={`${testID}-valid-selection`}
          />
        )}
        {validUntil && (
          <StyledWrapper mb={theme.normalising.heightPixel(20)}>
            <Row justify="space-between">
              <BaseText testID={`${testID}-valid-until`}>
                {t('cityPermits.validUntil')}
              </BaseText>
              <BaseText testID={`${testID}-until-date`}>{validUntil}</BaseText>
            </Row>
          </StyledWrapper>
        )}
        {isReserved && (
          <ChangeSpaceItem onPress={onParkingSpacePress} testID={testID} />
        )}
      </StyledWrapper>
    </CardBackground>
  );
};

export default TitleCard;
