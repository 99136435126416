import { gql } from '@apollo/client';

export const GET_PRICING_SCHEME_WITH_ID = gql`
  query GetPricingScheme($pricingSchemeId: String!) {
    getPricingSchemeWithId(pricingSchemeId: $pricingSchemeId) {
      id
      tariffPlans {
        notes {
          text {
            en
            fi
            no
            sv
          }
        }
        addOns {
          amount
          serviceChannelCodes
          type
        }
      }
    }
  }
`;
