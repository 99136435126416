import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledWrapper } from 'styles/ContainerStyles';
import { Button } from 'aimo-ui';
import {
  accountRemovalBlockers,
  accountRemoveWithWarnings,
  sheetModal,
} from 'states/common';
import {
  ArchivedFailedPayment,
  ChargingSession,
  FailedPayment,
  Maybe,
  ParkingSession,
} from 'types/generatedSchemaTypes';
import { useReactiveVar } from '@apollo/client';
import OngoingSessionBlocker from 'components/account/removalBlockers/OngoingSessionBlocker';
import PermitBlocker from 'components/account/removalBlockers/PermitBlocker';
import PaymentBlocker from 'components/account/removalBlockers/PaymentBlocker';
import PrepaidBlocker from 'components/account/removalBlockers/PrepaidBlocker';
import { testIdentifiers } from 'utils/testIdentifiers';

export type PaymentData = [FailedPayment | ArchivedFailedPayment | null];

export type AccountRemovalBlockers = {
  ongoingParking?: Maybe<ParkingSession>;
  ongoingCharging?: Maybe<ChargingSession>;
  failedPayments?: any;
  prepaidTickets?: any;
  permits?: any;
};

const AccountRemovalPrevented = () => {
  const { t } = useTranslation();
  const blockers = useReactiveVar(accountRemovalBlockers);
  const canContinueWithWarnings = useReactiveVar(accountRemoveWithWarnings);

  const ContinueButton = () => {
    if (canContinueWithWarnings) {
      return (
        <Button
          size="l"
          onPress={() => sheetModal('removeAccount')}
          text={t('button.continue')}
          {...testIdentifiers('continue-button')}
        />
      );
    }
    return (
      <Button
        size="l"
        onPress={() => sheetModal('')}
        text={t('button.close')}
        testID="close-button"
      />
    );
  };

  return (
    <StyledWrapper mb={0}>
      <StyledWrapper mb={20}>
        <OngoingSessionBlocker
          parkingSession={blockers.ongoingParking}
          chargingSession={blockers.ongoingCharging}
        />
        {blockers.failedPayments && (
          <PaymentBlocker failedPaymentSessions={blockers.failedPayments} />
        )}
        {blockers.prepaidTickets && (
          <PrepaidBlocker
            activePrepaidParkingSessions={blockers.prepaidTickets}
          />
        )}
        {blockers.permits && <PermitBlocker permitsData={blockers.permits} />}
      </StyledWrapper>
      <StyledWrapper mb={0}>
        <ContinueButton />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default AccountRemovalPrevented;
