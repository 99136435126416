import { isWeb } from '@notifee/react-native/dist/utils';
import { theme } from 'aimo-ui';
import CustomCarousel from 'components/common/CustomCarousel';
import { CarouselTutorialInfo, TutorialItem } from './TutorialComponents';

const tutorialData: CarouselTutorialInfo[] = [
  {
    image: 'Tutorial_First',
    translationKey: 'first',
    imageShadow: true,
  },
  {
    image: 'Tutorial_Map',
    translationKey: 'map',
  },
  { image: 'Tutorial_Wheel', translationKey: 'wheel', imageShadow: true },
  {
    image: 'Tutorial_Payment',
    translationKey: 'payment',
  },
  {
    image: 'Tutorial_Complete',
    translationKey: 'complete',
    buttonPress: 'COMPLETE',
  },
];

const FirstTimer = () => {
  return (
    <CustomCarousel
      carouselData={tutorialData}
      width={theme.normalising.widthPixel(isWeb ? 500 : 320)}
      height={theme.normalising.heightPixel(500)}
      enabled
      carouselItem={TutorialItem}
    />
  );
};

export default FirstTimer;
