import { useQuery, useReactiveVar } from '@apollo/client';
import AccountContext from 'context/AccountContext';
import { READ_CORPORATE_ACCOUNT } from 'graphql/queries/readCorporateAccount';
import { useContext, useMemo } from 'react';
import {
  blockCompanybenefitInEdit,
  parkingPaymentCardId,
  selectedParkingZoneTab,
} from 'states/common';
import {
  CompanyBenefit,
  Maybe,
  Query,
  QueryReadCorporateAccountArgs,
} from 'types/generatedSchemaTypes';
import {
  blockCompanyBenefitUsage,
  cardErrorStatuses,
} from 'utils/paymentUtils';

const useSelectedCompanyBenefit = (cardId?: string) => {
  const {
    customer: { companyBenefits, paymentCards },
  } = useContext(AccountContext);
  const selectedPaymentMethod = useReactiveVar(parkingPaymentCardId);
  const conditionalCreditCardId = cardId ?? selectedPaymentMethod;
  // Selected tab blocks usage of company benefit in charge & park & charge screens
  const selectedTab = useReactiveVar(selectedParkingZoneTab);
  // Blocked route blocks usage of company benefit in prepaid buy/edit and permit edit views
  const blockedRoute = useReactiveVar(blockCompanybenefitInEdit);
  const blockBenefit = blockCompanyBenefitUsage(selectedTab, blockedRoute);

  const hasDefaultCard = paymentCards?.find(
    (card) =>
      card?.isDefault && !cardErrorStatuses.includes(card.status || 'Active')
  );

  const companyBenefit = useMemo(() => {
    if (blockBenefit) {
      return undefined;
    }
    if (paymentCards?.length === 0 && !hasDefaultCard) {
      return companyBenefits?.[0] || undefined;
    }

    return (companyBenefits || []).find((benefit: Maybe<CompanyBenefit>) => {
      return benefit?.uid === conditionalCreditCardId;
    });
  }, [
    blockBenefit,
    companyBenefits,
    conditionalCreditCardId,
    paymentCards,
    hasDefaultCard,
  ]);

  if (blockBenefit && companyBenefit) {
    parkingPaymentCardId(undefined);
  }

  const corporateAccountUid = companyBenefit?.benefitTargetUid || '';

  const { data } = useQuery<Query, QueryReadCorporateAccountArgs>(
    READ_CORPORATE_ACCOUNT,
    {
      variables: {
        input: {
          corporateAccountUid,
        },
      },
      skip: !companyBenefit,
    }
  );

  const transactionFee = data?.readCorporateAccount?.pricing?.transactionFee;

  return {
    companyBenefit: companyBenefit || undefined,
    isUseAllowed: blockBenefit,
    transactionFee,
  };
};

export default useSelectedCompanyBenefit;
