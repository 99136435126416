/* eslint-disable react-hooks/exhaustive-deps */
import { QueryResult, useQuery, useReactiveVar } from '@apollo/client';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import { READ_CUSTOMER_PMCS_DETAILS } from 'graphql/queries/readCustomerPmcsDetails';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  activeElement,
  ongoingParkingSession,
  ongoingParkingSheetExpanded,
  ongoingParkingStartChargingSheetExpanded,
  tabBarVisible,
} from 'states/common';
import { featureCountryCode } from 'states/persistInStorage';
import { Charger, ParkzonePmcUnion } from 'types/common';
import {
  Card,
  CompanyBenefit,
  CountryCode,
  Maybe,
  Pmc,
  Query,
  QueryParkZoneArgs,
  QueryReadCustomerPmcsArgs,
  UnifiedPermit,
  Vehicle,
} from 'types/generatedSchemaTypes';
import { parseFeatureFlagObject, TypeDictionary } from 'utils/featureFlagUtils';
import { isPmcVisible } from 'utils/permitUtils';
import useAllowedParkingTimes from './useAllowedParkingTimes';
import useParkingZoneWithEVCheck from './useParkingZoneWithEVCheck';
import useSelectedCompanyBenefit from './useSelectedCompanyBenefit';
import useSelectedParkingVehicle from './useSelectedParkingVehicle';
import useSelectedPaymentMethod from './useSelectedPaymentMethod';

export type ShortTermParkingScreen =
  | 'SEARCH'
  | 'SHOW_DETAILS'
  | 'CHOOSE_END_TIME'
  | 'SELECT_TARIFF'
  | 'SELECT_CHARGER'
  | 'CONNECT_CHARGER'
  | 'PREDEFINED_TICKETS_AND_PERMITS'
  | 'PURCHASE_PREPAID'
  | 'EDIT_PREPAID'
  | 'RESUME_PARKING';

const PROCESS_ENTRANCE_SCREEN: ShortTermParkingScreen = 'SEARCH';

const SCREENS_WITH_TABBAR_HIDDEN: ShortTermParkingScreen[] = [
  'CHOOSE_END_TIME',
  'PURCHASE_PREPAID',
  'EDIT_PREPAID',
  'RESUME_PARKING',
];

export type ShortTermParkingConditions = {
  parkingZoneUid?: string;
  parkingZoneCountryCode?: CountryCode;
  vehicle?: Vehicle;
  endTime?: Date;
  paymentMethod?: Card;
  companyBenefit?: CompanyBenefit;
  allowedTimes?: {
    parkingAllowedFrom: string;
    parkingAllowedUntil: string;
  };
  isValidEndTime?: boolean;
};

export type ShortTermParkingWizard = {
  parkingConditions: ShortTermParkingConditions;
  currentScreen: ShortTermParkingScreen;
  parkingZoneResult?: QueryResult<Query, QueryParkZoneArgs>;
  myPermitsForZone?: Maybe<UnifiedPermit>[];
  selectedEvCharger?: Charger;
  selectedPrepaidTicket?: ParkzonePmcUnion;
  setCurrentScreen: (screen: ShortTermParkingScreen) => void;
  setEndTime: (endTime?: Date) => void;
  setIsValidEndTime: (isValidEndTime: boolean) => void;
  setSelectedEvCharger: (charger: Charger) => void;
  setSelectedPrepaidTicket: (ticket: ParkzonePmcUnion) => void;
  isOngoingParking: boolean;
  availablePermits?: Maybe<Pmc[]>;
};

const useShortTermParkingWizard = (): ShortTermParkingWizard => {
  const currentParkingZone = useReactiveVar(activeElement);
  const appCountryCode = useReactiveVar(featureCountryCode);
  const {
    customer: { permits, householdBenefits },
  } = useContext(AccountContext);
  const isOngoingParking = useReactiveVar(
    ongoingParkingStartChargingSheetExpanded
  );
  const { sessionId: ongoingParkingSessionId } = useReactiveVar(
    ongoingParkingSession
  );
  const isParkingZoneSet = currentParkingZone?.length > 0;
  const [currentScreen, setCurrentScreen] = useState<ShortTermParkingScreen>(
    PROCESS_ENTRANCE_SCREEN
  );
  const [endTime, setEndTime] = useState<Date>();
  // For web application, the endTime is a free input text,
  // field validation is used to disable the start parking button.
  const [isValidEndTime, setIsValidEndTime] = useState<boolean>(true);
  const [selectedEvCharger, setSelectedEvCharger] = useState<Charger>();
  const [selectedPrepaidTicket, setSelectedPrepaidTicket] =
    useState<ParkzonePmcUnion>(undefined);

  const {
    flags: { sales_order_permits },
  } = useContext(FeatureFlagsContext);
  const ongoingParkingSheetExp = useReactiveVar(ongoingParkingSheetExpanded);
  const parkingZoneResult = useParkingZoneWithEVCheck(
    currentParkingZone,
    'cache-and-network'
  );

  const { data: permitData } = useQuery<Query, QueryReadCustomerPmcsArgs>(
    READ_CUSTOMER_PMCS_DETAILS,
    {
      variables: {
        input: {
          parkingZoneUids: [currentParkingZone],
          permitType: 'SALES_ORDER',
        },
      },
      skip: !isParkingZoneSet,
      errorPolicy: 'all',
    }
  );

  const mySalesOrdersForZone =
    permits?.filter(
      (sOrder) =>
        sOrder?.orderLines?.[0]?.pmc?.physicalZoneUid === currentParkingZone &&
        (sOrder?.orderStatus === 'ACTIVE' || sOrder?.orderStatus === 'UPDATING')
    ) || [];

  const vehicle = useSelectedParkingVehicle();
  const paymentMethod = useSelectedPaymentMethod();
  const { companyBenefit } = useSelectedCompanyBenefit();
  const allowedTimes = useAllowedParkingTimes(currentParkingZone);

  const handleParkingZoneChange = () => {
    if (!ongoingParkingSheetExp) {
      setCurrentScreen(
        currentParkingZone ? 'SHOW_DETAILS' : PROCESS_ENTRANCE_SCREEN
      );
      setSelectedEvCharger(undefined);
    }
  };
  useEffect(handleParkingZoneChange, [currentParkingZone]);
  useEffect(() => {
    tabBarVisible(
      isOngoingParking || ongoingParkingSheetExp
        ? false
        : !SCREENS_WITH_TABBAR_HIDDEN.includes(currentScreen)
    );
  }, [currentScreen]);

  const showAvailablePermits = useMemo(() => {
    const countryObject = parseFeatureFlagObject<
      TypeDictionary<CountryCode, boolean>
    >(sales_order_permits, 'sales_order_permits');
    return !!appCountryCode && countryObject[appCountryCode];
  }, [appCountryCode]);

  return {
    parkingConditions: {
      paymentMethod,
      companyBenefit,
      endTime,
      parkingZoneUid: currentParkingZone,
      parkingZoneCountryCode:
        parkingZoneResult.data?.parkZone?.countryCode || 'FI',
      vehicle,
      allowedTimes,
      isValidEndTime,
    },
    currentScreen,
    setCurrentScreen,
    parkingZoneResult,
    myPermitsForZone: mySalesOrdersForZone,
    selectedPrepaidTicket,
    selectedEvCharger,
    setSelectedPrepaidTicket,
    setEndTime,
    setIsValidEndTime,
    setSelectedEvCharger,
    isOngoingParking: !!ongoingParkingSessionId,
    availablePermits: permitData?.readCustomerPmcs?.filter(
      (pmc) =>
        showAvailablePermits && isPmcVisible(pmc, householdBenefits ?? [])
    ),
  };
};

export default useShortTermParkingWizard;
