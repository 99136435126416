import { useReactiveVar } from '@apollo/client';
import { Headline3, Meta, theme } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import SheetLayout from 'components/sheets/SheetLayout';
import HslContext from 'context/HslContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';
import { selectedHslPricing } from 'states/common';
import { country } from 'states/persistInStorage';
import { StyledWrapper } from 'styles/ContainerStyles';
import { HslTicketPricing } from 'types/generatedSchemaTypes';
import { currencyFormatter } from 'utils/commonUtils';

const HslDurationSelection = () => {
  const { setCurrentScreen, hslPricingResult } = useContext(HslContext);
  const handleNavigateBack = () => {
    setCurrentScreen('BUY_TICKET');
  };
  const { t } = useTranslation();
  const countryCode = useReactiveVar(country);
  const data = hslPricingResult?.data?.readHslTicketPricing;

  const selectValue = (pricing: HslTicketPricing) => {
    selectedHslPricing(pricing);
    handleNavigateBack();
  };

  return (
    <SheetLayout
      onHeadlineButtonPress={handleNavigateBack}
      testIDName="hsl-select-duration-ticket"
      headlineComponent={
        <Headline3 center>{t('hslTicket.durationScreenTitle')}</Headline3>
      }>
      <ScrollView>
        {data?.map((pricing) => (
          <StyledWrapper key={`hsl-duration-${pricing?.validityPeriod}`} mb={5}>
            <MenuItem
              noBorder
              testID={`hsl-duration-item-${pricing?.validityPeriod}`}
              onPress={() => selectValue(pricing as HslTicketPricing)}
              menuLabel={t('hslTicket.validity.days', {
                value: pricing?.validityPeriod,
              })}
              rightComponent={
                <Meta color={theme.colors.gray200}>
                  {currencyFormatter(
                    (pricing?.currentPrice as number) / 100,
                    countryCode,
                    true
                  )}
                </Meta>
              }
              background={theme.colors.gray50}
            />
          </StyledWrapper>
        ))}
      </ScrollView>
    </SheetLayout>
  );
};

export default HslDurationSelection;
