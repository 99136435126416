import { useQuery } from '@apollo/client';
import {
  Query,
  QueryReadReceiptByTransactionIdArgs,
} from 'types/generatedSchemaTypes';
import { READ_RECEIPT_BY_TRANSACTION_ID } from '@queries/readReceiptByTransactionId';

const useReadReceiptByTransactionId = (transactionId: string) => {
  return useQuery<Query, QueryReadReceiptByTransactionIdArgs>(
    READ_RECEIPT_BY_TRANSACTION_ID,
    {
      skip: !transactionId,
      variables: {
        transactionId: transactionId,
      },
    }
  );
};
export default useReadReceiptByTransactionId;
