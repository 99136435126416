import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { showToast } from 'components/common/CustomToast';
import AccountContext from 'context/AccountContext';
import ParkingContext from 'context/ParkingContext';
import { CREATE_CHARGING_SESSION_MUTATION } from 'graphql/mutations/createChargingSesssion';
import { CREATE_PARKING_SESSION } from 'graphql/mutations/createParkingSession';
import { useContext, useState } from 'react';
import {
  expandSheetAfterPurchase,
  ongoingChargingSheetExpanded,
  ongoingParkingSheetExpanded,
  ongoingParkingStartChargingSheetExpanded,
  parkingPaymentCardId,
  parkingVehicleLicensePlate,
  parkzoneSearchText,
  resumableSession,
  resumeSessionPossible,
  searchInputActive,
  selectedSessionIdFromMultiple,
  selectedChargingPricingInfoSegment,
  selectedTariffId,
  selectedTariffName,
} from 'states/common';
import { country } from 'states/persistInStorage';
import { ParkingZoneType } from 'types/common';
import {
  Mutation,
  MutationCreateChargingSessionV3Args,
  MutationCreateParkingSessionArgs,
  SelectedSegment,
} from 'types/generatedSchemaTypes';
import { updateCache } from 'utils/cacheUtils';
import { toggleOngoingSessionSheet } from 'utils/ongoingSessionUtils';
import { addRecentParkingWithSegment } from 'utils/parkUtils';
import useMinimumLoadingTime from './useMinimumLoadingTime';
import useOngoingChargingSession from './useOngoingChargingSession';
import useOngoingParkingSession from './useOngoingParkingSession';
import useSelectedCompanyBenefit from './useSelectedCompanyBenefit';

type CreateSessionProps = {
  parkingZoneType?: ParkingZoneType;
  onErrorCallback?: () => void;
};

const useCreateSession = ({
  parkingZoneType,
  onErrorCallback,
}: CreateSessionProps) => {
  const {
    parkingConditions: {
      endTime,
      vehicle,
      paymentMethod,
      companyBenefit,
      parkingZoneUid,
    },
    selectedEvCharger,
    parkingZoneResult: parkingZone,
    setCurrentScreen,
  } = useContext(ParkingContext) || {};
  const {
    customer: { contactEmail },
  } = useContext(AccountContext);

  const resumableSessionInfo = useReactiveVar(resumableSession);

  const selectedTariff = useReactiveVar(selectedTariffId);
  const tariffName = useReactiveVar(selectedTariffName);
  const countryCode = useReactiveVar(country);
  const customerSegment =
    selectedTariff && selectedTariff !== 'Standard'
      ? ({ uid: selectedTariff, countryCode } as SelectedSegment)
      : undefined;

  const { id: stationId, evse, chargingLocationId } = selectedEvCharger || {};

  const selectedPaymentMethod = paymentMethod || companyBenefit;

  const isCreditCard = selectedPaymentMethod?.__typename === 'Card';

  const {
    ongoingParkingSession,
    ongoingParkingSessionRefetch,
    ongoingParkingSessionLoading,
  } = useOngoingParkingSession(false, true);
  const { ongoingChargingSession, ongoingChargingSessionRefetch } =
    useOngoingChargingSession();
  const [parkingSuccess, setParkingSuccess] = useState<boolean>(false);
  const [chargingSuccess, setChargingSuccess] = useState<boolean>(false);

  const serviceFeeAmount = useSelectedCompanyBenefit()?.transactionFee;

  const [createParking, { loading: createParkingLoading }] = useMutation<
    Mutation,
    MutationCreateParkingSessionArgs
  >(CREATE_PARKING_SESSION, {
    variables: {
      input: {
        type: 'START_STOP',
        cardId: isCreditCard ? (selectedPaymentMethod.cardId as string) : '',
        customerEmail: contactEmail || '',
        endDate: endTime || new Date(),
        startDate: resumableSessionInfo
          ? resumableSessionInfo.startDate
          : undefined,
        resumedSessionId: resumableSessionInfo
          ? resumableSessionInfo.sessionId
          : undefined,
        licensePlate: vehicle?.licensePlate?.plateText,
        parkingZoneUid: parkingZoneUid || '',
        customerSegment: customerSegment,
        paymentType: isCreditCard ? 'CREDIT_CARD' : 'CORPORATE_ACCOUNT',
        corporateAccountUid: isCreditCard
          ? undefined
          : companyBenefit?.benefitTargetUid,
        serviceFeeAmount,
      },
    },
    onCompleted: async () => {
      customerSegment &&
        addRecentParkingWithSegment({
          zoneUid:
            parkingZone?.data?.parkZone?.commercialZoneView?.zoneCode || '',
          customerSegmentUid: selectedTariff,
          customerSegmentName: tariffName,
        });
      await ongoingParkingSessionRefetch();
      setParkingSuccess(true);
      resumeSessionPossible(false);
      if (parkingZoneType !== 'PARK_AND_CHARGE') {
        setCurrentScreen('SEARCH');
        expandSheetAfterPurchase(
          !ongoingParkingSession && !ongoingChargingSession
        );
      } else {
        await createCharging();
      }
      parkingPaymentCardId(undefined);
      parkingVehicleLicensePlate(undefined);
      parkzoneSearchText('');
      searchInputActive(false);
    },
    update: (cache, { data: updateData }) => {
      const sessionId = updateData?.createParkingSession?.sessionId;
      updateCache(
        cache,
        { __typename: 'ParkingSession', sessionId },
        {
          fragment: gql`
            fragment ParkingSessionFragment on ParkingSession {
              tariffName
            }
          `,
          data: { tariffName: selectedTariffName() },
        }
      );
    },
    onError: (error) => {
      onErrorCallback?.();
      if (
        error.graphQLErrors.some(
          (e) => e.extensions?.code === 'CARD_NOT_ACTIVE'
        )
      ) {
        showToast('error.paymentCardNotActive', 'error');
        return;
      }
      showToast('error.parkingStartedFailed', 'error');
    },
  });

  const [createCharging, { loading: createChargingLoading }] = useMutation<
    Mutation,
    MutationCreateChargingSessionV3Args
  >(CREATE_CHARGING_SESSION_MUTATION, {
    variables: {
      input: {
        cardId: paymentMethod?.cardId || '',
        corporateAccountUid: companyBenefit?.benefitTargetUid || '',
        licensePlate: vehicle?.licensePlate?.plateText || '',
        chargingLocationId: chargingLocationId || '',
        countryCode: parkingZone?.data?.parkZone?.countryCode || countryCode,
        evseId: evse?.id || '',
        parkingZoneUid: parkingZoneUid || '',
        stationId: stationId || '',
        customerSegmentUid: selectedChargingPricingInfoSegment()?.uid,
      },
    },
    onCompleted: async () => {
      setCurrentScreen('SEARCH');
      await ongoingChargingSessionRefetch();
      setChargingSuccess(true);
      parkingPaymentCardId(undefined);
      ongoingParkingStartChargingSheetExpanded(false);
      toggleOngoingSessionSheet(ongoingParkingSheetExpanded, false);
      toggleOngoingSessionSheet(ongoingChargingSheetExpanded, false);
      selectedSessionIdFromMultiple(null);
      parkingVehicleLicensePlate(undefined);
    },
    onError: () => {
      onErrorCallback?.();
      showToast('error.chargingStartedFailed', 'error');
    },
  });

  const { showLoading } = useMinimumLoadingTime(createParkingLoading, 5000);

  return {
    createParking,
    createParkingLoading:
      ongoingParkingSessionLoading || createParkingLoading || showLoading,
    parkingSuccess,
    createCharging,
    createChargingLoading,
    chargingSuccess,
  };
};

export default useCreateSession;
