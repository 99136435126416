import React, { ReactElement, useContext, useLayoutEffect } from 'react';
import { Container } from '../styles/styles';
import { Button, Headline1, Meta, Spinner, theme } from 'aimo-ui';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { country } from 'states/persistInStorage';
import {
  Query,
  QueryReadCityPmcsArgs,
  Pmc,
  LegallAddress,
  Maybe,
  Customer,
} from 'types/generatedSchemaTypes';
import { READ_CITY_PMCS } from 'graphql/queries/readCityPMCs';
import FlatListCustom from 'components/sheets/FlatListCustom';
import { Divider } from 'styles/CommonLayoutStyles';
import StreetParkingPmcsItem from './StreetParkingPmcItem';
import { useTranslation } from 'react-i18next';
import AccountContext from 'context/AccountContext';
import {
  cityPmcsScreenName,
  searchInputActive,
  strongAuthFlow,
} from 'states/common';
import { splitAddress } from 'utils/commonUtils';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import styled from 'styled-components/native';
import { isWeb } from 'utils/deviceUtils';
import { TouchableOpacity } from '@gorhom/bottom-sheet';
type StreetParkingSearchProps = {
  searchTerm: string;
  testID?: string;
};

const PurchaseableWrapper = styled(StyledWrapper)`
  flex: ${isWeb ? 'none' : '0.3'};
`;

const ColoredContainer = styled(StyledWrapper)`
  background-color: ${(p) => p.theme.colors.gray50};
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
`;

const ContainerItemWrapper = styled.View<{
  width?: string;
}>`
  width: ${(p) => (p.width ? p.width + 'px' : 'auto')};
  justify-content: center;
  align-items: center;
`;

const NoPmcsFound: React.FC<{ testID: string }> = ({ testID }) => {
  const { t } = useTranslation();
  return (
    <Container testID={testID}>
      <StyledWrapper center>
        <ColumnWithMargins mt={40} mb={40}>
          <Headline1 center testID="city-pmcs-not-found-title">
            {t('search.noMatches')}
          </Headline1>
        </ColumnWithMargins>
        <StyledWrapper center mb={40}>
          <Meta testID="city-pmcs-not-found-meta" center>
            {t('cityPermits.notFoundWithSearch')}
          </Meta>
        </StyledWrapper>
        <StyledWrapper center maxWidth={400}>
          <Button
            text="Show available permits"
            buttonType="secondary"
            size="l"
            onPress={() => searchInputActive(false)}
          />
        </StyledWrapper>
      </StyledWrapper>
    </Container>
  );
};

const PurchaseOrAuthenticate: React.FC<{
  addressList: LegallAddress[];
  purchaseablePmcs: Pmc[];
  searchTerm: string;
  customer: Customer;
  renderItem: (item: Pmc, canPurchase: boolean) => ReactElement;
}> = ({ addressList, purchaseablePmcs, searchTerm, customer, renderItem }) => {
  const { t } = useTranslation();

  if (!customer.strongAuthentication?.gedi) {
    return (
      <ColoredContainer>
        <ContainerItemWrapper width="200">
          <Meta>{t('cityPermits.needAddress')}</Meta>
        </ContainerItemWrapper>
        <ContainerItemWrapper width="80">
          <Button
            size="s"
            text={t('button.identify')}
            onPress={() => strongAuthFlow(true)}
          />
        </ContainerItemWrapper>
      </ColoredContainer>
    );
  }

  if (addressList.length > 0 && searchTerm === '') {
    return (
      <PurchaseableWrapper testID="city-pmcs-purchaseable">
        <ColumnWithMargins mt={10} mb={20}>
          <TouchableOpacity onPress={() => cityPmcsScreenName('REIDENTIFY')}>
            <Meta
              testID="city-pmcs-buyable-meta"
              color={theme.colors.gray200}
              numberOfLines={1}>
              {t('cityPermits.suggestionsList', {
                forAddress: 'for ',
              })}
              <Meta
                color={
                  theme.colors.link
                }>{`${addressList[0]?.streetName} ${addressList[0]?.streetNumber}`}</Meta>
            </Meta>
          </TouchableOpacity>
        </ColumnWithMargins>
        {purchaseablePmcs.length > 0 ? (
          <FlatListCustom
            data={purchaseablePmcs as Pmc[]}
            windowSize={10}
            ItemSeparatorComponent={() => <Divider marginHorizontal={0} />}
            renderItem={({ item }) => renderItem(item, true)}
            keyExtractor={(item, index) => `${item?.uid}-${index}`}
            testID="city-pmcs-purchaseable-list"
          />
        ) : (
          <StyledWrapper maxWidth={400}>
            <Meta>{t('cityPermits.addressNotCovered')}</Meta>
          </StyledWrapper>
        )}
      </PurchaseableWrapper>
    );
  }

  return null;
};

const StreetParkingListSearch: React.FC<StreetParkingSearchProps> = ({
  searchTerm,
  testID,
}) => {
  const countryCode = useReactiveVar(country) || 'FI';
  const { t } = useTranslation();
  const { customer } = useContext(AccountContext);
  const { strongAuthentication } = customer;
  const { streetName, streetNumber } = splitAddress(searchTerm);
  const [getCityPmcs, { data, error, loading }] = useLazyQuery<
    Query,
    QueryReadCityPmcsArgs
  >(READ_CITY_PMCS);

  const [getPurcahseablePmcs, { data: purchaseablePmcs }] = useLazyQuery<
    Query,
    QueryReadCityPmcsArgs
  >(READ_CITY_PMCS);

  const addressList: Maybe<LegallAddress>[] = React.useMemo(
    () => strongAuthentication?.addresses || [],
    [strongAuthentication]
  );

  useLayoutEffect(() => {
    if (
      searchTerm === '' ||
      (searchTerm !== '' && streetName && streetNumber)
    ) {
      getCityPmcs({
        variables: {
          input: {
            streetName: streetName,
            streetNumber: streetNumber,
            city: 'Turku',
            countryCode,
          },
        },
      });
    }
  }, [getCityPmcs, countryCode, streetName, streetNumber, searchTerm]);

  useLayoutEffect(() => {
    if (addressList.length > 0) {
      getPurcahseablePmcs({
        variables: {
          input: {
            streetName: addressList[0]?.streetName,
            streetNumber: addressList[0]?.streetNumber,
            city: 'Turku',
            countryCode,
          },
        },
      });
    }
  }, [addressList, getPurcahseablePmcs, countryCode]);

  const pmcsData = React.useMemo(
    () => data?.readCityPmcs?.content || [],
    [data]
  );

  const purchaseablePmcsData = React.useMemo(
    () => purchaseablePmcs?.readCityPmcs?.content || [],
    [purchaseablePmcs]
  );

  const filteredApplyable = React.useMemo(
    () =>
      pmcsData.filter((item) => {
        return !purchaseablePmcsData.find((purchaseableItem) => {
          return item?.id === purchaseableItem?.id;
        });
      }),
    [pmcsData, purchaseablePmcsData]
  );

  if (error) {
    return <></>;
  }

  if (loading) {
    return (
      <StyledWrapper center>
        <Spinner />
      </StyledWrapper>
    );
  }

  if (searchTerm !== '' && !loading && (!streetName || !streetNumber)) {
    return (
      <Meta center testID="city-pmc-search-meta">
        {t('search.streetWithNumber')}
      </Meta>
    );
  }

  if (filteredApplyable.length < 1 && !loading) {
    return (
      <Container testID={testID}>
        <NoPmcsFound testID="city-pmcs-no-data" />
      </Container>
    );
  }

  const renderItem = (item: any, canPurchase: boolean) => (
    <StreetParkingPmcsItem item={item} canPurchase={canPurchase} />
  );

  return (
    <Container testID={testID}>
      <PurchaseOrAuthenticate
        searchTerm={searchTerm}
        addressList={addressList as LegallAddress[]}
        customer={customer}
        purchaseablePmcs={purchaseablePmcsData as Pmc[]}
        renderItem={renderItem}
      />
      <Meta testID="city-pmcs-searc-meta" color={theme.colors.gray200}>
        {t('cityPermits.suggestionsList', { forAddress: null })}
      </Meta>
      <FlatListCustom
        data={filteredApplyable as Pmc[]}
        ItemSeparatorComponent={() => <Divider marginHorizontal={0} />}
        renderItem={({ item }) => renderItem(item, false)}
        keyExtractor={(item, index) => `${item?.uid}-${index}`}
        testID="city-pmcs-search-list-applyable"
      />
    </Container>
  );
};

export default StreetParkingListSearch;
