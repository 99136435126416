import { Headline3, theme } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import SheetLayout from 'components/sheets/SheetLayout';
import HslContext from 'context/HslContext';
import { t } from 'i18next';
import React, { useContext } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { StyledImage, StyledWrapper } from 'styles/ContainerStyles';

const HslZoneSelectModalContent = () => {
  const { availableZones, selections, setSelections, setCurrentScreen } =
    useContext(HslContext);

  const handleNavigateback = () => {
    setCurrentScreen('BUY_TICKET');
  };
  const onSelection = (zone: string) => {
    setSelections({ ...selections, selectedZone: zone });
    handleNavigateback();
  };

  return (
    <SheetLayout
      onHeadlineButtonPress={handleNavigateback}
      testIDName="hsl-select-zone"
      headlineComponent={
        <Headline3 center>{t('hslTicket.zoneSelectTitle')}</Headline3>
      }>
      <StyledWrapper mb={20} center>
        <StyledImage
          testID="hsl-zone-map"
          width={350}
          height={291}
          source={require('../../assets/images/HSLMap.png')}
        />
      </StyledWrapper>
      <ScrollView>
        {availableZones.map((zone) => (
          <StyledWrapper key={`hsl-zone-${zone}`} mb={5}>
            <MenuItem
              noBorder
              testID={`hsl-zone-${zone}`}
              onPress={() => onSelection(zone)}
              menuLabel={zone}
              background={theme.colors.gray50}
            />
          </StyledWrapper>
        ))}
      </ScrollView>
    </SheetLayout>
  );
};

export default HslZoneSelectModalContent;
