import { BaseText, theme } from 'aimo-ui';
import { VEHICLE_INPUT_ICON_SIZE } from 'components/account/vehicle/VehicleIcons';
import SelectableModalContentItem from 'components/common/SelectableModalContentItem';
import VehicleAvatar from 'components/common/VehicleAvatar';
import VehicleLicensePlate from 'components/common/VehicleLicensePlate';
import Icon from 'components/icons/Icon';
import React from 'react';
import styled from 'styled-components/native';
import { Vehicle } from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';

const Container = styled.View`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const VehicleContentWrapper = styled.View`
  ${isWeb ? 'flex-grow:' : 'flex-shrink:'} 1;
`;

const VehicleInfoWrapper = styled.View`
  flex-direction: column;
`;

const VehicleNameWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

const StyledSettings = styled.View`
  margin-left: 10px;
`;

interface SelectVehicleItemProps {
  vehicle: Vehicle;
  isSelected: boolean;
  onPress: (setVehicle: Vehicle) => void;
  editVehicle?: (v: Vehicle) => void;
  allowMultipleSelection?: boolean;
  disabled?: boolean;
}

const SelectVehicleItem: React.FC<SelectVehicleItemProps> = ({
  vehicle,
  isSelected,
  onPress,
  editVehicle,
  allowMultipleSelection = false,
  disabled = false,
}) => {
  return (
    <Container>
      <VehicleContentWrapper>
        <SelectableModalContentItem
          isSelected={isSelected}
          disabled={disabled}
          onPress={() => onPress(vehicle)}
          testID={`vehicle-wrap-${vehicle.licensePlate?.plateText}`}
          iconNode={
            <VehicleAvatar
              color={
                disabled ? theme.colors.gray100 : vehicle?.appSettings?.color
              }
              icon={vehicle?.appSettings?.icon}
              size="l"
            />
          }
          allowMultipleSelection={allowMultipleSelection}
          notSelectedBorder={allowMultipleSelection}>
          <VehicleInfoWrapper>
            <VehicleNameWrapper>
              {vehicle?.cameraRecognition?.allowed &&
                vehicle?.cameraRecognition?.enabled && (
                  <Icon
                    name="CCTV-Outlined"
                    size={VEHICLE_INPUT_ICON_SIZE}
                    color={theme.colors.black}
                  />
                )}
              <BaseText
                testID={`vehicle-name-${vehicle.licensePlate?.plateText}`}
                color={disabled ? theme.colors.gray200 : theme.colors.black}>
                {vehicle.name || vehicle.licensePlate?.plateText}
              </BaseText>
            </VehicleNameWrapper>
            <VehicleLicensePlate licensePlate={vehicle.licensePlate} />
          </VehicleInfoWrapper>
        </SelectableModalContentItem>
      </VehicleContentWrapper>
      {editVehicle && (
        <StyledSettings>
          <SelectableModalContentItem
            isSelected={false}
            disabled={false}
            onPress={() => editVehicle(vehicle)}
            testID={`vehicle-settings-${vehicle.licensePlate?.plateText}`}
            hideChildren
            iconNode={
              <VehicleAvatar
                icon={'Settings-Filled'}
                size="l"
                showBackground={false}
                showBorder={false}
              />
            }
          />
        </StyledSettings>
      )}
    </Container>
  );
};

export default SelectVehicleItem;
