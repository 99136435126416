import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/core';
import { BottomButtonsLayout, Button, Headline3, theme } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import useBackButton from 'hooks/useBackButton';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';
import Animated, { FadeInRight, FadeOutLeft } from 'react-native-reanimated';
import { selectedParkingSpace, selectedParkingSpaces } from 'states/common';
import styled from 'styled-components/native';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import { ReservedParkingSpace } from 'types/generatedSchemaTypes';
import { RootNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';

const Container = styled.View`
  flex-grow: 1;
  justify-content: center;
`;

const enterAnimation = (delay = 500) =>
  isWeb ? undefined : FadeInRight.delay(delay);

const ParkingSpaceSelector: React.FC = () => {
  const parkingSpace = useReactiveVar(selectedParkingSpace);
  const { goBack } = useNavigation<RootNavProps>();

  useEffect(() => {
    selectedParkingSpace(undefined);
  }, []);

  const handleContinue = () => {
    goBack();
  };

  const onBackPress = () => {
    if (parkingSpace) {
      selectedParkingSpace(undefined);
      return true;
    }
    return false;
  };
  useBackButton(onBackPress);

  if (parkingSpace) {
    return (
      <ConfirmParkingSpace
        externalNote={parkingSpace?.externalNote ?? ''}
        handleContinue={handleContinue}
      />
    );
  }
  return <SelectParkingSpace handleContinue={handleContinue} />;
};

const SelectParkingSpace: React.FC<{
  handleContinue: () => void;
}> = ({ handleContinue }) => {
  const parkingSpaces = useReactiveVar(selectedParkingSpaces);
  const availableParkingSpaces = parkingSpaces?.filter(
    ({ parkingSpaceStatus, parkingSpace }) => {
      return (
        parkingSpaceStatus === 'AVAILABLE' &&
        parkingSpace?.reserved === true &&
        !parkingSpace?.blocked
      );
    }
  );
  return (
    <ScrollView nestedScrollEnabled>
      <ColumnWithMargins mt={20}>
        {availableParkingSpaces?.map(
          (
            { parkingSpace, pmcIdRef = '' }: ReservedParkingSpace,
            index: number
          ) => {
            const { name, uid = '', externalNote = '' } = parkingSpace || {};
            return (
              <Animated.View
                key={`parking-space-${name}`}
                entering={enterAnimation()}
                exiting={!isWeb ? FadeOutLeft.duration(400) : undefined}>
                <StyledWrapper maxWidth={500} key={uid} mb={10}>
                  <MenuItem
                    testID={`available-parking-space-${index}`}
                    background={theme.colors.gray50}
                    menuLabel={name || ''}
                    subtitle={externalNote ?? ''}
                    withArrow
                    onPress={() => {
                      selectedParkingSpace({
                        pmcIdRef: pmcIdRef ?? '',
                        externalNote: externalNote ?? '',
                        name: name ?? '',
                        parkingSpaceUid: uid,
                      });
                      handleContinue();
                    }}
                  />
                </StyledWrapper>
              </Animated.View>
            );
          }
        )}
      </ColumnWithMargins>
    </ScrollView>
  );
};

const ConfirmParkingSpace: React.FC<{
  handleContinue: () => void;
  externalNote: string;
}> = ({ handleContinue, externalNote }) => {
  const { t } = useTranslation();
  return (
    <BottomButtonsLayout
      scrollable={false}
      contentBottom={
        <StyledWrapper mb={30}>
          <Button
            size="l"
            onPress={handleContinue}
            text={t('longTermParking.selectSpace')}
            testID="confirm-parking-space"
          />
        </StyledWrapper>
      }>
      {externalNote && (
        <Container>
          <Animated.View entering={enterAnimation(300)}>
            <StyledWrapper>
              <Headline3>{t('longTermParking.parkingSpaceFeatures')}</Headline3>
            </StyledWrapper>
            <StyledWrapper>
              <MenuItem
                menuLabel={externalNote}
                noBorder
                background={theme.colors.gray50}
              />
            </StyledWrapper>
          </Animated.View>
        </Container>
      )}
    </BottomButtonsLayout>
  );
};
export default ParkingSpaceSelector;
