import { Headline3, Meta, theme } from 'aimo-ui';
import { format } from 'date-fns';
import usePriceExtraction from 'hooks/usePriceExtraction';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'styles/CommonLayoutStyles';
import {
  ColumnWithMargins,
  RowSpaceBetween,
  StyledWrapper,
} from 'styles/ContainerStyles';
import {
  BillingItem,
  CountryCode,
  ParkingPrice,
  SubMerchantPayment,
} from 'types/generatedSchemaTypes';
import { currencyFormatter, getLocale, numberFormatter } from 'utils/commonUtils';

const Row = ({
  label,
  value,
  labelTestID,
  valueTestID,
}: {
  label: string;
  value: string;
  labelTestID?: string;
  valueTestID?: string;
}) => {
  return (
    <RowSpaceBetween padding={6}>
      <ColumnWithMargins>
        <Meta color={theme.colors.gray200} testID={labelTestID}>
          {label}
        </Meta>
      </ColumnWithMargins>
      <ColumnWithMargins>
        <Meta testID={valueTestID}>{value}</Meta>
      </ColumnWithMargins>
    </RowSpaceBetween>
  );
};

const getEvChargingItem = (
  item: BillingItem,
  countryCode: CountryCode,
  t: TFunction
) => {
  if (!item.amountExclVAT || item.amountExclVAT === 0) {
    return null;
  }

  const value = currencyFormatter(item.amountExclVAT, countryCode);
  let label: string;
  let valueTestID: string;

  switch (item.type) {
    case 'EV_CHARGING_ENERGY':
      {
        const energyConsumedKiloWatts = item.energyConsumedWatts
          ? item.energyConsumedWatts / 1000
          : 0;
        label = t('parking.receipt.chargingEnergy', {
          charge: numberFormatter(energyConsumedKiloWatts,countryCode),
        });
        valueTestID = 'charging-energy';
      }
      break;
    case 'EV_CHARGING_TIME':
      label = t('parking.receipt.chargingTime');
      valueTestID = 'charging-time';
      break;
    case 'SERVICE_FEE':
      label = t('parking.receipt.serviceFee');
      valueTestID = 'service-fee';
      break;
    default:
      label = t('parking.receipt.otherFee');
      valueTestID = 'other-fee';
      break;
  }

  return <Row label={label} value={value} valueTestID={valueTestID} />;
};

const PriceBreakDown: React.FC<{
  paymentDate?: Date;
  price: ParkingPrice | BillingItem | [BillingItem];
  countryCode: CountryCode;
  isPaymentFailed: boolean;
  isCompacted?: boolean;
  feeType?: string;
  energyUsed?: number;
  isFailedChargingSession?: boolean;
  failedChargingSessionPrice?: number;
  transactionId?: string;
  showTitle?: boolean;
  billingItems?: BillingItem[];
  subMerchant?: SubMerchantPayment | null;
}> = ({
  paymentDate,
  price,
  countryCode,
  isPaymentFailed,
  isCompacted = false,
  feeType = 'parking.receipt.parkingFee',
  energyUsed,
  transactionId,
  isFailedChargingSession,
  failedChargingSessionPrice,
  showTitle = true,
  billingItems = [],
  subMerchant,
}) => {
  const { t } = useTranslation();
  const {
    isParkingPrice,
    total,
    subTotal,
    totalTax,
    parkingFee,
    serviceFee,
    vatPercentage,
  } = usePriceExtraction(
    price,
    countryCode,
    isFailedChargingSession,
    failedChargingSessionPrice
  );
  const hasEnergyUsed = energyUsed !== null && energyUsed !== undefined;

  const hasSeparateEvChargingItems = !!billingItems?.some(
    (item) =>
      (item?.type === 'EV_CHARGING_ENERGY' && (item?.amountExclVAT ?? 0) > 0) ||
      (item?.type === 'EV_CHARGING_TIME' && (item?.amountExclVAT ?? 0) > 0)
  );

  const allowSumming = true; //TODO: temporary static control flag, remove when summing feature is more clear
  return (
    <StyledWrapper>
      {showTitle && (
        <ColumnWithMargins mb={10}>
          <Headline3>{t('parking.receipt.payment')}</Headline3>
        </ColumnWithMargins>
      )}
      {paymentDate && (
        <>
          <Row
            label={t('parking.receipt.paymentDate')}
            value={format(paymentDate, 'd.M.Y', { locale: getLocale() })}
            valueTestID="paymentDate"
          />
          {!subMerchant && <Divider />}
        </>
      )}
      {subMerchant && (
        <>
          <Row
            label={t('parking.receipt.subMerchant')}
            value={subMerchant.subMerchantName || ''}
            valueTestID="paymentDate"
          />
          <Divider />
        </>
      )}
      {transactionId && (
        <>
          <Row
            label={t('parking.receipt.transactionId')}
            value={''}
            valueTestID="transactionId"
          />
          <Meta testID="transactionId">{transactionId}</Meta>
          <Divider />
        </>
      )}
      {hasSeparateEvChargingItems && (
        <>
          {billingItems?.flatMap((item) =>
            item.amountExclVAT && item.amountExclVAT > 0
              ? [getEvChargingItem(item, countryCode, t)]
              : []
          )}
          <Divider />
        </>
      )}
      {!hasSeparateEvChargingItems && hasEnergyUsed && (
        <>
          <Row
            label={t('parking.receipt.chargingFee', {
              charge: (energyUsed || 0) / 1000,
            })}
            value={subTotal}
            valueTestID="charging-fee"
          />
          <Divider />
        </>
      )}
      {!hasSeparateEvChargingItems &&
        !isPaymentFailed &&
        isParkingPrice &&
        !hasEnergyUsed && (
          <Row
            label={t(`${feeType}`)}
            value={parkingFee}
            valueTestID="parking-fee"
          />
        )}
      {!hasSeparateEvChargingItems &&
        !isPaymentFailed &&
        !isCompacted &&
        isParkingPrice &&
        !hasEnergyUsed && (
          <>
            <Row
              label={t('parking.receipt.serviceFee')}
              value={serviceFee}
              valueTestID="service-fee"
            />
            <Divider />
          </>
        )}

      {allowSumming && (
        <>
          {!isCompacted && (
            <Row
              label={t('price.subTotal')}
              value={subTotal}
              valueTestID="subtotal"
            />
          )}
          {!isFailedChargingSession && (
            <Row
              label={t('price.tax', {
                vatPercentage: isPaymentFailed ? 0 : vatPercentage,
              })}
              value={totalTax}
              labelTestID="tax-percentage"
              valueTestID="total-tax"
            />
          )}
          <Divider />
          <RowSpaceBetween padding={6}>
            <ColumnWithMargins>
              <Meta
                testID="total-price-header"
                color={
                  isPaymentFailed
                    ? theme.colors.boost.red
                    : theme.colors.gray200
                }>
                {t(isPaymentFailed ? 'price.amountDue' : 'price.totalAmount')}
              </Meta>
            </ColumnWithMargins>
            <ColumnWithMargins>
              <Headline3
                testID="total-price"
                color={
                  isPaymentFailed ? theme.colors.boost.red : theme.colors.black
                }>
                {isFailedChargingSession ? subTotal : total}
              </Headline3>
            </ColumnWithMargins>
          </RowSpaceBetween>
        </>
      )}
    </StyledWrapper>
  );
};

export default PriceBreakDown;
