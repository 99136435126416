import { Meta } from 'aimo-ui';
import VehicleAvatar from 'components/common/VehicleAvatar';
import AccountContext from 'context/AccountContext';
import React, { useContext } from 'react';
import styled from 'styled-components/native';
import { isWeb } from 'utils/deviceUtils';

const floatViewTop = (noMap?: boolean) => {
  if (isWeb) {
    return '35vh';
  } else {
    return noMap ? '-20px' : '50px';
  }
};

const FloatView = styled.View<{ noMap?: boolean }>`
  position: absolute;
  background-color: ${(p) => p.theme.colors.white};
  justify-content: center;
  align-items: center;
  left: 50%;
  top: ${(p) => floatViewTop(p.noMap)};
  border-radius: 60px;
  padding: 6px 14px 6px 6px;
  width: 110px;
  margin-left: -50px;
  elevation: 10;
  box-shadow: 0px 4px 6px rgba(1, 1, 1, 0.5);
`;
const Row = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BadgeText = styled(Meta)`
  margin-left: 8px;
`;

const ParkingBadge: React.FC<{
  licensePlate?: string;
  testID?: string;
  noMap?: boolean;
}> = ({ licensePlate, testID, noMap }) => {
  const {
    customer: { vehicles },
  } = useContext(AccountContext);

  const parkedVehicle = vehicles?.find(
    (vehicle) => vehicle.licensePlate?.plateText === licensePlate
  );

  return (
    <FloatView testID={testID} noMap={noMap}>
      <Row>
        <VehicleAvatar
          color={parkedVehicle?.appSettings?.color}
          icon={parkedVehicle?.appSettings?.icon}
        />
        <BadgeText>{licensePlate || ''}</BadgeText>
      </Row>
    </FloatView>
  );
};
export default ParkingBadge;
