import {
  AuthenticationResult,
  PublicClientApplication,
} from '@azure/msal-browser';
import { showToast } from 'components/common/CustomToast';
import { addMinutes, isAfter } from 'date-fns';
import { getLanguage } from 'i18n/init';
import { accessToken, featureCountryCode } from 'states/persistInStorage';
import { getCountryForAzureQueryParam } from 'utils/authUtils';
import Logger from 'utils/Logger';
import { MMKVStorage } from 'utils/MMKVStorage';
import { updateStateAndStorage } from 'utils/MMKVStorageUtils';
import {
  acquireTokenScopes,
  loginRequestWeb,
  msalConfigSignIn,
  msalConfigSignUp,
  msalConfigSuSi,
  msalConfigSuSiTest,
} from './AuthConfig.web';

const getApproveTermsUrl = () => {
  const url = new URL(window.location.href);
  return `${url.protocol}//${url.host}/account/approve-terms`;
};

const getWebAuthReturnUrl = () => {
  const url = new URL(window.location.href);
  return `${url.protocol}//${url.host}/loginSuccess`;
};

const getWebAuthLogoutUrl = () => {
  const url = new URL(window.location.href);
  return `${url.protocol}//${url.host}/account/main`;
};

const msalSignIn = new PublicClientApplication(msalConfigSignIn);
const msalSignUp = new PublicClientApplication(msalConfigSignUp);
const msalSuSi = new PublicClientApplication(msalConfigSuSi);
const msalSuSiTest = new PublicClientApplication(msalConfigSuSiTest);
msalSignIn
  .handleRedirectPromise()
  .then(async (tokenResponse) => {
    AuthManager.handleTokenSuccess(tokenResponse);
  })
  .catch(() => {
    const redirectUrl =
      msalSignIn.getConfiguration().auth.postLogoutRedirectUri;
    (window as any).open(redirectUrl, '_self');
    Logger.error('Sign in failed');
  });

class AuthManager {
  static readonly handleTokenSuccess = async (
    tokenResponse: AuthenticationResult | null
  ) => {
    if (tokenResponse) {
      const accessTokenString = tokenResponse?.accessToken || '';
      const configAuth = this.getConfigAuth();
      if (configAuth === undefined) {
        await this.handleSignOutDueToInvalidConfigAuth();
        return;
      }
      configAuth.setActiveAccount(tokenResponse.account);
      const expires = tokenResponse.expiresOn?.getTime().toString();
      MMKVStorage.set('expireTime', expires || '');
      updateStateAndStorage(accessToken, 'accessToken', accessTokenString);
    }
  };

  static signInAsync = async () => {
    try {
      MMKVStorage.set('authConfig', 'msalSignIn');
      featureCountryCode(null);
      msalSignIn.setActiveAccount(null);
      await msalSignIn.loginRedirect({
        ...loginRequestWeb,
        redirectUri: getApproveTermsUrl(),
        redirectStartPage: getWebAuthReturnUrl(),
        extraQueryParameters: {
          ui_locales: getLanguage(),
        },
      });
      /* NOTE: this never executes as it is redirected away from the workflow in web, check loginSuccess */
      return true;
    } catch (e) {
      Logger.error(`Azure sign in - ${e}`);
      return false;
    }
  };

  static signUpAsync = async () => {
    try {
      MMKVStorage.set('authConfig', 'msalSignUp');
      await msalSignUp.loginRedirect({
        ...loginRequestWeb,
        redirectUri: getWebAuthLogoutUrl(),
        redirectStartPage: getWebAuthReturnUrl(),
        extraQueryParameters: {
          ui_locales: getLanguage(),
        },
      });
      /* NOTE: this never executes as it is redirected away from the workflow in web, check loginSuccess */
      return true;
    } catch (e) {
      Logger.error(`Azure sign up - ${e}`);
      return false;
    }
  };

  static suSiAsync = async (useEmailVerification = true) => {
    try {
      MMKVStorage.set(
        'authConfig',
        useEmailVerification ? 'msalSuSi' : 'msalSuSiTest'
      );
      const publicClientApplication = useEmailVerification
        ? msalSuSi
        : msalSuSiTest;
      await publicClientApplication.loginRedirect({
        ...loginRequestWeb,
        redirectStartPage: getWebAuthReturnUrl(),
        extraQueryParameters: {
          ui_locales: getLanguage(),
          cnty: getCountryForAzureQueryParam(),
        },
      });
      /* NOTE: this never executes as it is redirected away from the workflow in web, check loginSuccess */
      return true;
    } catch (e) {
      Logger.error(`Azure sign up - ${e}`);
      return false;
    }
  };

  static signOutAsync = async (_datadogLogReason?: string) => {
    // Clear storage and logout
    try {
      const configAuth = this.getConfigAuth();
      if (configAuth) {
        await configAuth.logoutPopup();
      } else {
        // configAuth should be undefined only when users were logged in before the app was updated to v1.17.0
        // In that case use the signIn config that was used before the update
        // This code could be removed in the future when we are sure that all users have updated to v1.17.0
        await msalSignIn.logoutPopup();
      }
      updateStateAndStorage(accessToken, 'accessToken', '');
      MMKVStorage.delete('expireTime');
      MMKVStorage.delete('authConfig');
      return true;
    } catch (e) {
      return false;
    }
  };

  private static handleSignOutDueToInvalidConfigAuth = async () => {
    // configAuth should be undefined only when users were logged in before the app was updated to v1.17.0
    // In this case, we should log a warning and log the user out
    // This code could be removed in the future when we are sure that all users have updated to v1.17.0
    showToast('error.tokenExpired', 'error');
    await this.signOutAsync();
  };

  private static getConfigAuth = (): PublicClientApplication | undefined => {
    const authConfigString = MMKVStorage.getString('authConfig');
    switch (authConfigString) {
      case 'msalSignIn':
        return msalSignIn;
      case 'msalSignUp':
        return msalSignUp;
      case 'msalSuSi':
        return msalSuSi;
      case 'msalSuSiTest':
        return msalSuSiTest;
      default:
        break;
    }
  };

  static getAccessTokenAsync = async () => {
    const expireTime = MMKVStorage.getString('expireTime') || undefined;
    if (expireTime !== undefined) {
      // Get expiration time - 5 minutes
      // If it's <= 5 minutes before expiration, then refresh
      const expire = addMinutes(new Date(parseInt(expireTime, 10)), -5);
      const now = new Date();
      if (isAfter(now, expire)) {
        try {
          const configAuth = this.getConfigAuth();
          if (configAuth === undefined) {
            await this.handleSignOutDueToInvalidConfigAuth();
            return;
          }
          const silentRequest = {
            scopes: acquireTokenScopes,
            forceRefresh: false,
            account: configAuth.getActiveAccount() || undefined,
          };
          try {
            const tokenResponse =
              await configAuth.acquireTokenSilent(silentRequest);
            this.handleTokenSuccess(tokenResponse);
            return tokenResponse?.accessToken || '';
          } catch (e) {
            // user is not logged in anymore, TODO: notify user
            await this.signOutAsync();
            return '';
          }
        } catch (e) {
          return '';
        }
      }
    }
    return accessToken();
  };
}

export default AuthManager;
