import { theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import React from 'react';
import styled from 'styled-components/native';

export const VEHICLE_INPUT_ICON_SIZE = 'm';
const VEHICLE_INPUT_ICON_COLOR = theme.colors.black;

const ArrowIconWrapper = styled.View`
  margin-right: 20px;
`;

export const ArrowIcon = () => (
  <ArrowIconWrapper>
    <Icon
      name="Chevron_Right"
      size={VEHICLE_INPUT_ICON_SIZE}
      color={VEHICLE_INPUT_ICON_COLOR}
    />
  </ArrowIconWrapper>
);

export const DropdownIcon = () => (
  <Icon
    name="Chevron_Down"
    size={VEHICLE_INPUT_ICON_SIZE}
    color={VEHICLE_INPUT_ICON_COLOR}
  />
);

export const CheckIcon = () => (
  <Icon
    name="check"
    size={VEHICLE_INPUT_ICON_SIZE}
    color={VEHICLE_INPUT_ICON_COLOR}
  />
);

export const EmptyIcon = styled.View`
  width: 24px;
  height: 24px;
`;
