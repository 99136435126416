import { useReactiveVar } from '@apollo/client';
import { Headline2, Meta, Spinner, theme, Title } from 'aimo-ui';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import SheetLayout from 'components/sheets/SheetLayout';
import ParkingContext from 'context/ParkingContext';
import PriceEstimateContext from 'context/PriceEstimateContext';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { resumableSession, resumeSessionPossible } from 'states/common';
import styled from 'styled-components/native';
import {
  ColumnWithMargins,
  StyledContainer,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { currencyFormatter } from 'utils/commonUtils';
import ResumeTimeDisplay from './ResumeTimeDisplay';
import { Row } from './styles/styles';

const PillContainer = styled(StyledContainer)`
  flex: 0 1 100%;
  border-radius: 60px;
  padding: 0px 12px;
  align-self: baseline;
  font-weight: 700;
`;

const BoldMeta = styled(Meta)`
  font-family: ${theme.fontFamily.bold};
`;

const ResumeParking = () => {
  const {
    parkingZoneResult: parkingZone,
    parkingConditions: { parkingZoneUid, endTime },
    setCurrentScreen,
  } = useContext(ParkingContext);
  const {
    loadingPrice,
    priceEstimate,
    getPricingEstimateWithId,
    setStartButtonOpacity,
  } = useContext(PriceEstimateContext);

  const { t } = useTranslation();
  const { commercialZoneView, countryCode } = parkingZone?.data?.parkZone || {};
  const resumableSessionInfo = useReactiveVar(resumableSession);

  useEffect(() => {
    if (!resumableSessionInfo) {
      setCurrentScreen('SHOW_DETAILS');
      resumeSessionPossible(false);
    }
  }, [resumableSessionInfo, setCurrentScreen, t]);

  useEffect(() => {
    if (
      endTime &&
      ((parkingZoneUid && resumableSessionInfo?.startDate) ||
        resumableSessionInfo?.sessionId)
    ) {
      getPricingEstimateWithId({
        variables: {
          input: {
            sessionId: resumableSessionInfo?.sessionId,
            endDate: endTime ? endTime : new Date(),
          },
        },
      });
      resumeSessionPossible(true);
    }
  }, [
    getPricingEstimateWithId,
    endTime,
    parkingZoneUid,
    resumableSessionInfo?.startDate,
    resumableSessionInfo?.sessionId,
    setStartButtonOpacity,
  ]);

  return (
    <>
      <SheetLayout
        testIDName="resume-parking-container"
        onHeadlineButtonPress={() => {
          setCurrentScreen('SHOW_DETAILS');
          resumeSessionPossible(false);
        }}>
        <BottomSheetScrollViewCustom>
          <StyledWrapper center mb={theme.normalising.heightPixel(30)}>
            <Row>
              <ColumnWithMargins mr={5}>
                <Headline2
                  testID="resume-parking-header"
                  color={theme.colors.gray200}>
                  {commercialZoneView?.zoneCode}
                </Headline2>
              </ColumnWithMargins>
              <Headline2>{commercialZoneView?.name}</Headline2>
            </Row>
          </StyledWrapper>
          <StyledWrapper center maxWidth={310}>
            <Title center>{t('parking.resumeParkingSession.title')}</Title>
            <ColumnWithMargins mt={10}>
              <Meta center>
                {t('parking.resumeParkingSession.description')}
              </Meta>
            </ColumnWithMargins>
          </StyledWrapper>
          <StyledWrapper center mb={5}>
            <PillContainer
              color={theme.colors.gray50}
              testID={'resume-parking-price'}>
              <Row>
                <BoldMeta testID={'resume-parking-desc'}>
                  {t('parking.resumeParkingSession.price')}
                </BoldMeta>
                {loadingPrice && !priceEstimate ? (
                  <Spinner small />
                ) : (
                  <BoldMeta
                    testID={
                      'resume-parking-price-value'
                    }>{` ${currencyFormatter(
                    priceEstimate?.totalAmount || 0,
                    countryCode || 'SE'
                  )}`}</BoldMeta>
                )}
              </Row>
            </PillContainer>
          </StyledWrapper>
          <StyledWrapper center maxWidth={295} testID={'resume-parking-time'}>
            {resumableSessionInfo && (
              <ResumeTimeDisplay
                spacerCount={5}
                startTime={resumableSessionInfo?.startDate as Date}
                endTime={endTime}
              />
            )}
          </StyledWrapper>
        </BottomSheetScrollViewCustom>
      </SheetLayout>
    </>
  );
};

export default ResumeParking;
