import { useReactiveVar } from '@apollo/client';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Spinner, theme } from 'aimo-ui';
import AccountDefault from 'components/account/AccountDefault';
import Notifications from 'components/account/notifications/Notifications';
import VehicleAdd from 'components/account/vehicle/VehicleAdd';
import VehicleCameraRecognition from 'components/account/vehicle/VehicleCameraRecognition';
import BGView from 'components/common/BGView';
import CloseButton from 'components/common/CloseButton';
import HelpButton from 'components/common/HelpButton';
import HistoryFilterButton from 'components/common/HistoryFilterButton';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import StackHeader from 'navigation/StackHeader';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ChargingHistoryDetailsScreen from 'screens/account/ChargingHistoryDetailsScreen';
import CompanyPermit from 'screens/account/CompanyPermit';
import ContactInfo from 'screens/account/ContactInfo';
import ContactUs from 'screens/account/ContactUs';
import EditVehicle from 'screens/account/EditVehicle';
import MyPaymentMethods from 'screens/account/MyPaymentMethods';
import MyVehicles from 'screens/account/MyVehicles';
import ParkingHistoryDetailsScreen from 'screens/account/ParkingHistoryDetailsScreen';
import ParkingHistoryScreen from 'screens/account/ParkingHistoryScreen';
import PermitHistoryDetailsScreen from 'screens/account/PermitHistoryDetailsScreen';
import PrepaidHistoryScreen from 'screens/account/PrepaidHistoryScreen';
import PrepaidTicketHistoryDetailsScreen from 'screens/account/PrepaidTicketHistoryDetailsScreen';
import SheetWrapper, { WebWrapper } from 'screens/account/SheetWrapper';
import ViewTerms from 'screens/account/ViewTerms';
import Permit from 'screens/permit/Permit';
import PermitEdit from 'screens/permit/PermitEdit';
import Permits from 'screens/permit/Permits';
import {
  accountTheme,
  selectedLicensePlatesPermitRedeem,
  selectedVehicleTypeForm,
  sheetModal,
  tabBarVisible,
  vehicleFromParkzone,
} from 'states/common';
import { ScreenContainer, StyledWrapper } from 'styles/ContainerStyles';
import {
  AccountNavProps,
  AccountScreenNavigationProp,
  AccountStackParamList,
} from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { getModalOptions } from 'utils/navigationUtils';
import { ModalHeaderComponent } from './navigationComponents';
import AccessPermitRedeem from 'screens/permit/access/AccessPermitRedeem';

type RoundBackgroundViewProps = {
  page: React.FC;
  loading?: boolean;
  mb?: number;
};

const AccountStack = createStackNavigator<AccountStackParamList>();

const RoundBackgroundView: React.FC<RoundBackgroundViewProps> = ({
  page: Page,
  loading,
  mb,
}) => {
  const usedTheme = useReactiveVar(accountTheme);
  return (
    <ScreenContainer background={usedTheme} mb={mb}>
      {loading ? (
        <StyledWrapper center>
          <Spinner />
        </StyledWrapper>
      ) : (
        <BGView noMargins paddings={{ top: 20 }}>
          <Page />
        </BGView>
      )}
    </ScreenContainer>
  );
};

const getScreen = <P,>(
  Component: React.FC,
  loading?: boolean,
  mb?: number,
  isSheet?: boolean
) => {
  if (isWeb) {
    return (props: P) => <WebWrapper page={Component} {...props} />;
  } else {
    if (isSheet) {
      return (props: P) => (
        <SheetWrapper page={Component} mb={mb} loading={loading} {...props} />
      );
    } else {
      return (props: P) => (
        <RoundBackgroundView
          page={Component}
          mb={mb}
          loading={loading}
          {...props}
        />
      );
    }
  }
};

const AccountNavigator: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<AccountNavProps>();
  const { navigate: navigateAccount } =
    useNavigation<AccountScreenNavigationProp>();
  const { isLoggedIn } = useIsLoggedIn();
  const selectedVehicleType = useReactiveVar(selectedVehicleTypeForm);
  const modalOptions = useMemo(
    () => getModalOptions(undefined, true, 'secondary'),
    []
  );
  const modalFullScreenOptions = useMemo(
    () => getModalOptions(undefined, true),
    []
  );

  return (
    <AccountStack.Navigator
      screenOptions={{
        headerBackTitleVisible: true,
        headerTransparent: isWeb,
        animationEnabled: true,
        headerShown: true,
        cardStyle: {
          backgroundColor: isWeb ? theme.colors.white : theme.colors.gray100,
        },
        header: (props) => (
          <StackHeader
            {...props}
            background={
              isWeb ? theme.colors.white : theme.colors.lightBg.orange
            }
          />
        ),
      }}>
      <AccountStack.Screen
        name="accountMain"
        component={AccountDefault}
        options={{
          headerTitle: '',
          title: t('account.signedInWelcome'),
          headerRight: () => (
            <HelpButton
              onPress={() =>
                isLoggedIn
                  ? navigate('contactUs')
                  : sheetModal('selectCountryForSupport')
              }
              testID="help-button"
            />
          ),
          header: (props) => (
            <StackHeader
              {...props}
              headerBackIconVisible={false}
              background={
                isWeb ? theme.colors.white : theme.colors.lightBg.pink
              }
              isTitleBig={true}
            />
          ),
        }}
        listeners={{
          focus: () => {
            tabBarVisible(true);
          },
        }}
      />
      <AccountStack.Screen
        name="contactUs"
        component={ContactUs}
        listeners={({ navigation }) => ({
          blur: () => {
            if (!isLoggedIn) {
              navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: 'accountMain' }],
                })
              );
            }
          },
        })}
        options={{
          headerTitle: '',
          title: t('account.contactUs.title'),
          headerRight: () => (
            <CloseButton
              onPress={() => {
                navigateAccount('mainTabs', {
                  screen: 'accountScreen',
                  params: { screen: 'accountMain' },
                });
              }}
              testID="contact-us-back-button"
              buttonType="secondary"
            />
          ),
          header: (props) => (
            <StackHeader
              {...props}
              headerBackIconVisible={false}
              background={
                isWeb ? theme.colors.white : theme.colors.lightBg.orange
              }
              isTitleBig={true}
            />
          ),
        }}
      />
      <AccountStack.Screen
        name="myVehicles"
        component={MyVehicles}
        options={{
          title: t('account.myVehicles'),
          header: (props) => (
            <StackHeader
              {...props}
              background={
                isWeb ? theme.colors.white : theme.colors.lightBg.pink
              }
            />
          ),
          cardStyle: {
            backgroundColor: theme.colors.white,
          },
        }}
        listeners={{
          focus: () => {
            accountTheme(theme.colors.lightBg.pink);
            tabBarVisible(false);
            vehicleFromParkzone(false);
          },
        }}
      />
      <AccountStack.Screen
        name="myPaymentMethods"
        component={getScreen(MyPaymentMethods)}
        options={{
          title: t('account.paymentMethods'),
        }}
        listeners={{
          focus: () => {
            accountTheme(theme.colors.lightBg.orange);
            tabBarVisible(true);
          },
        }}
      />
      <AccountStack.Screen
        name="addVehicleAccount"
        component={VehicleAdd}
        options={{
          ...modalOptions.screenOptions,
          title: t(`vehicle.addVehicle.type.${selectedVehicleType}`),
          header: (props) =>
            ModalHeaderComponent({ ...props, ...modalOptions.headerStyle }),
          cardStyle: modalOptions.cardStyle,
        }}
        listeners={{
          focus: () => {
            tabBarVisible(false);
          },
        }}
      />
      <AccountStack.Screen
        name="editVehicle"
        component={EditVehicle}
        options={{
          title: t('vehicle.editVehicle'),
          header: (props) => (
            <StackHeader
              {...props}
              background={
                isWeb ? theme.colors.white : theme.colors.lightBg.pink
              }
            />
          ),
          cardStyle: {
            backgroundColor: theme.colors.white,
          },
        }}
        listeners={{
          focus: () => {
            accountTheme(theme.colors.lightBg.pink);
            tabBarVisible(false);
          },
          blur: () => {
            tabBarVisible(true);
          },
        }}
      />
      <AccountStack.Screen
        name="vehicleCameraRecognitionAccount"
        component={VehicleCameraRecognition}
        options={{
          ...modalOptions.screenOptions,
          title: t('vehicle.cameraRecognition.title'),
          header: (props) =>
            ModalHeaderComponent({ ...props, ...modalOptions.headerStyle }),
          cardStyle: modalOptions.cardStyle,
        }}
        listeners={{
          focus: () => {
            tabBarVisible(false);
          },
        }}
      />
      <AccountStack.Screen
        name="parkingHistory"
        component={getScreen(ParkingHistoryScreen, undefined, undefined, true)}
        options={{
          title: t('account.parkAndChargeHistory'),
          header: (props) => (
            <StackHeader
              {...props}
              background={isWeb ? theme.colors.white : theme.colors.gray100}
            />
          ),
          headerRight: () => <HistoryFilterButton showParkingTypes={true} />,
        }}
        listeners={{
          focus: () => {
            accountTheme(theme.colors.gray100);
          },
        }}
      />
      <AccountStack.Screen
        name="prepaidHistory"
        component={getScreen(PrepaidHistoryScreen, undefined, undefined, true)}
        options={{
          title: t('account.prepaid'),
          header: (props) => (
            <StackHeader
              {...props}
              background={isWeb ? theme.colors.white : theme.colors.gray100}
            />
          ),
          headerRight: () => <HistoryFilterButton />,
        }}
        listeners={{
          focus: () => {
            accountTheme(theme.colors.gray100);
          },
        }}
      />
      <AccountStack.Screen
        name="parkingHistoryDetails"
        component={ParkingHistoryDetailsScreen}
        options={{
          headerMode: 'float',
          headerBackTitleVisible: false,
          title: t('account.parkingDetails'),
        }}
      />
      <AccountStack.Screen
        name="permitHistoryDetails"
        component={PermitHistoryDetailsScreen}
        options={{
          header: (props) => (
            <StackHeader
              {...props}
              background={isWeb ? theme.colors.white : theme.colors.gray100}
            />
          ),
        }}
      />
      <AccountStack.Screen
        name="prepaidHistoryDetails"
        component={PrepaidTicketHistoryDetailsScreen}
        options={{
          header: (props) => (
            <StackHeader
              {...props}
              background={isWeb ? theme.colors.white : theme.colors.gray100}
            />
          ),
        }}
      />
      <AccountStack.Screen
        name="chargingHistoryDetails"
        component={ChargingHistoryDetailsScreen}
        options={{
          headerMode: 'float',
          headerBackTitleVisible: false,
          title: t('account.chargingDetails'),
        }}
      />
      <AccountStack.Screen
        name="contactInfo"
        component={getScreen(ContactInfo)}
        options={{
          title: t('account.contactInfo'),
          headerShown: true,
        }}
        listeners={{
          focus: () => {
            accountTheme(theme.colors.lightBg.orange);
          },
        }}
      />
      <AccountStack.Screen
        name="companyPermit"
        component={getScreen(CompanyPermit, false, 1)}
        options={{
          title: t('account.companyPermit'),
          headerShown: true,
        }}
        listeners={{
          focus: () => {
            accountTheme(theme.colors.lightBg.orange);
            tabBarVisible(false);
          },
        }}
      />
      <AccountStack.Screen
        name="permits"
        component={Permits}
        options={{
          title: t('services.permits'),
          headerBackTitleVisible: true,
          headerTransparent: false,
          headerShown: true,
          cardStyle: {
            backgroundColor: theme.colors.gray100,
          },
          header: (props) => (
            <StackHeader
              {...props}
              background={isWeb ? theme.colors.white : theme.colors.gray100}
            />
          ),
        }}
      />
      <AccountStack.Screen
        name="permit"
        component={Permit}
        options={{
          headerBackTitleVisible: true,
          headerTransparent: false,
          headerShown: true,
        }}
      />
      <AccountStack.Screen
        name="permitEdit"
        component={PermitEdit}
        options={{
          headerShown: false,
        }}
      />
      <AccountStack.Screen
        name="permitRedeem"
        component={AccessPermitRedeem}
        options={{
          presentation: 'modal',
          title: t('permit.access.redeemPermit'),
          headerBackTitleVisible: true,
          header: (props) =>
            ModalHeaderComponent({
              ...props,
              ...modalFullScreenOptions.headerStyle,
              closeButton: true,
              forceBackButton: true,
            }),
          cardStyle: modalFullScreenOptions.cardStyle,
        }}
        listeners={{
          focus: () => {
            tabBarVisible(false);
          },
          beforeRemove: () => {
            selectedLicensePlatesPermitRedeem([]);
          },
        }}
      />
      <AccountStack.Screen
        name="viewTerms"
        component={getScreen(ViewTerms)}
        options={{
          title: t('account.terms.privacyTermsAndConditions'),
          headerShown: true,
        }}
        listeners={{
          focus: () => {
            accountTheme(theme.colors.lightBg.orange);
          },
        }}
      />
      <AccountStack.Screen
        name="notifications"
        component={getScreen(Notifications)}
        options={{
          title: t('account.notifications'),
        }}
        listeners={{
          focus: () => {
            accountTheme(theme.colors.lightBg.orange);
          },
        }}
      />
    </AccountStack.Navigator>
  );
};

export default AccountNavigator;
