import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { GET_PARK_ZONE } from '@queries/getParkZone';
import { GET_PARK_ZONE_WITH_CHARGING_STATIONS } from '@queries/getParkZoneWithChargingStations';
import useFeatureFlagEVCharging from 'hooks/useFeatureFlagEVCharging';
import { useEffect, useState } from 'react';
import {
  activeElement,
  activeElementSecondaryUid,
  activeElementType,
  selectedChargingPricingInfoSegment,
  selectedChargingPricingInfoSegmentTariffPlanId,
  selectedChargingPricingInfoSegmentNameBasedOnNotes,
  selectedChargingPricingInfoSegmentCountryCode,
  selectedTariffId,
  selectedTariffName,
  selectedChargingPricingInfo,
} from 'states/common';
import { ParkingZoneType } from 'types/common';
import {
  Query,
  QueryGetEvChargingPricingsArgs,
  QueryParkZoneArgs,
} from 'types/generatedSchemaTypes';
import { getRecentParkingWithSegment } from 'utils/parkUtils';
import {
  findDefaultTariff,
  isSegmentLocked,
  selectedTariffAvailable,
} from 'utils/tariffUtils';
import useAllowedParkingTimes from './useAllowedParkingTimes';
import useHasUnlockedCustomerSegment from './useHasUnlockedCustomerSegment';
import { GET_EV_CHARGING_PRICINGS } from '@queries/getEVChargingPricings';

/**
 * useTariffs hooks sets the preselected tariff
 * when a Park Zone Details screen is rendered.
 **/
const useTariffs = () => {
  const showEVCharging = useFeatureFlagEVCharging();
  const parkingZoneUid = useReactiveVar(activeElement);
  const selectedTariff = useReactiveVar(selectedTariffId);
  const { pricingSegments, pricingCountryCode } =
    useAllowedParkingTimes(parkingZoneUid);
  const [isLoadingEVPricings, setIsLoadingEVPricings] = useState(false);

  const [getEVChargingPricings] = useLazyQuery<
    Query,
    QueryGetEvChargingPricingsArgs
  >(GET_EV_CHARGING_PRICINGS);

  const { data: parkingZone } = useQuery<Query, QueryParkZoneArgs>(
    showEVCharging ? GET_PARK_ZONE_WITH_CHARGING_STATIONS : GET_PARK_ZONE,
    {
      variables: { uid: parkingZoneUid },
      skip: !parkingZoneUid,
      onCompleted: async (data) => {
        const { parkingMethods, parkingZoneChargingStations } =
          data?.parkZone || {};

        if (parkingZoneChargingStations?.[0]) {
          setIsLoadingEVPricings(true);
          /**
           * An unmanaged Park Zone can only be used to charge
           * (parking session is not controlled in Aimo side),
           * therefore default tariff should be the first price scheme
           * of the first charging station
           **/
          const { chargingLocationId, id: chargingStationId } =
            parkingZoneChargingStations[0];
          const EVChargingPricings = await getEVChargingPricings({
            variables: {
              locationIds: [chargingLocationId],
            },
            fetchPolicy: 'no-cache',
          });
          const pricing = EVChargingPricings?.data?.getEVChargingPricings?.[0];
          const selectedSegment = [
            pricing?.defaultSegment,
            ...(pricing?.customerSegments || []),
          ].filter(
            (segment) => segment?.accessType && segment.accessType !== 'PRIVATE'
          )[0];

          const countryCode = pricing?.countryCode;
          const tariffName =
            selectedSegment?.notes?.map((note) => note?.text).join(' ') ||
            undefined;

          const parkAndCharge = !parkingMethods?.unmanagedParking;
          selectedChargingPricingInfo(pricing);
          selectedChargingPricingInfoSegment(selectedSegment || undefined);
          selectedChargingPricingInfoSegmentTariffPlanId(
            selectedSegment?.tariffPlanId
          );
          selectedChargingPricingInfoSegmentNameBasedOnNotes(tariffName);
          selectedChargingPricingInfoSegmentCountryCode(countryCode);
          setTariff(
            parkAndCharge ? 'PARK_AND_CHARGE' : 'CHARGING',
            tariffName,
            selectedSegment?.tariffPlanId,
            chargingStationId,
            !parkAndCharge
          );
          setIsLoadingEVPricings(false);
        }
      },
    }
  );

  const defaultSegment = findDefaultTariff(pricingSegments);

  const { isUnlockValid } = useHasUnlockedCustomerSegment(
    defaultSegment?.uid || '',
    pricingCountryCode || 'SE',
    parkingZone?.parkZone?.commercialZoneView?.zoneCode || ''
  );

  const setTariff = (
    type: ParkingZoneType,
    name?: string | undefined | null,
    id?: string | undefined | null,
    secondaryId?: string,
    setTariffIdName = true
  ) => {
    if (setTariffIdName) {
      selectedTariffName(name || undefined);
      selectedTariffId(id || undefined);
    }
    activeElementType(type);
    activeElementSecondaryUid(secondaryId);
  };

  useEffect(() => {
    /**
     * Set default tariff only when there is not selected tariff
     */
    if (selectedTariff === undefined && !isLoadingEVPricings) {
      if (pricingSegments?.length > 0) {
        /**
         * A managed Park Zone can be used to start a parking session,
         * therefore default tariff should be the first price scheme
         **/

        // SE zones might have default segment locked also, so we need to check that and also if customers unlocking is valid
        const isDefaultLocked = isSegmentLocked(defaultSegment, isUnlockValid);
        // If a tariff is already selected, check if exists in the current pricingSegments.
        // Select the default segment only if the selected tariff is unavailable.
        // AND only select it, if it's not locked
        const isSelectedTariffAvailable = selectedTariffAvailable(
          selectedTariff,
          pricingSegments
        );
        const { parkingMethods, commercialZoneView } =
          parkingZone?.parkZone || {};
        const parkAndCharge =
          !parkingMethods?.unmanagedParking && parkingMethods?.evCharging;

        if (!isSelectedTariffAvailable && !isDefaultLocked) {
          const previousUsedTariff = getRecentParkingWithSegment(
            commercialZoneView?.zoneCode || ''
          );
          // Check if previous used tariff is within the allowed tariffs of the selected park zone.
          const isPreviousUsedTariffAllowed = previousUsedTariff
            ? pricingSegments.find(
                (pricingSegment) =>
                  pricingSegment?.uid === previousUsedTariff.customerSegmentUid
              )
            : false;
          setTariff(
            parkAndCharge ? 'PARK_AND_CHARGE' : 'PARKING',
            isPreviousUsedTariffAllowed
              ? previousUsedTariff.customerSegmentName
              : defaultSegment?.title,
            isPreviousUsedTariffAllowed
              ? previousUsedTariff.customerSegmentUid
              : defaultSegment?.uid
          );
        }
      }
    }
  }, [
    defaultSegment,
    isUnlockValid,
    isLoadingEVPricings,
    pricingSegments,
    selectedTariff,
    parkingZone,
  ]);

  return {
    isLoadingEVTariffs: isLoadingEVPricings,
  };
};

export default useTariffs;
