import React from 'react';
import { ParkingPrice } from 'types/generatedSchemaTypes';
import PriceBreakDown from 'components/receipt/PriceBreakDown';
import { SalesOrderReceipt } from './SalesOrderReceiptClass';

const PermitPaymentInfo = ({
  salesOrder,
  isHistory,
}: {
  salesOrder: SalesOrderReceipt;
  isHistory: boolean;
}) => {
  const {
    countryCode,
    paymentState,
    orderLines,
    vatPercent,
    created,
    billingItems,
  } = salesOrder || {};

  const orderLine = orderLines?.[0];
  const isPaymentFailed =
    paymentState === 'FAILED_AUTHORISE' || paymentState === 'FAILED_COMMIT';

  const isHistoryCountry = isHistory
    ? salesOrder?.item?.countryCode
    : countryCode;

  const subMerchant = salesOrder?.subMerchant || null;

  let totalAmount = 0;
  let vatPercentage = 0;
  let vatAmount = 0;
  let permitFee = 0;
  if (orderLine) {
    vatPercentage = vatPercent ?? 0;
    vatAmount = (orderLine?.lineVatAmount || 0) / 100;
    totalAmount = (orderLine?.lineTotal || 0) / 100;
    permitFee = totalAmount - vatAmount;
  }

  const price = {
    __typename: 'ParkingPrice',
    breakdown: {
      parkingFee: {
        amountExclVAT: permitFee,
      },
      serviceFee: {
        amountExclVAT: 0,
      },
    },
    totalAmount: totalAmount,
    vatAmount: vatAmount,
    vatPercentage: vatPercentage,
  } as ParkingPrice;

  return (
    <PriceBreakDown
      paymentDate={created ? new Date(created) : undefined}
      price={billingItems || price}
      countryCode={isHistoryCountry || 'FI'}
      subMerchant={subMerchant}
      isPaymentFailed={isPaymentFailed}
      isCompacted
      feeType="permit.permitFee"
    />
  );
};

export default PermitPaymentInfo;
