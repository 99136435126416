import { Player } from '@lottiefiles/react-lottie-player';
import { isIOS } from '@notifee/react-native/dist/utils';
import { BaseText, Button, Headline1, IconMap, theme, Title } from 'aimo-ui';
import AuthManager from 'components/auth/AuthManager';
import BGView from 'components/common/BGView';
import LanguageButton from 'components/language/LanguageButton';
import AccountContext from 'context/AccountContext';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import useAfterAuthenticationRedirect from 'hooks/useAfterAuthenticationRedirect';
import useBackButton from 'hooks/useBackButton';
import useHandleAuthentication from 'hooks/useHandleAuthentication';
import useIsAccountRestricted from 'hooks/useIsAccountRestricted';
import LottieView from 'lottie-react-native';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, View } from 'react-native';
import Svg, { Circle, ClipPath, Defs, Rect } from 'react-native-svg';
import { isSignUpFlow } from 'states/common';
import { showLandingScreen } from 'states/persistInStorage';
import styled from 'styled-components/native';
import {
  ScreenContainer,
  ScreenWrapper,
  StyledImage,
  StyledWrapper,
} from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';
import { updateStateAndStorage } from 'utils/MMKVStorageUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const screenHeight = Dimensions.get('window').height;

const contentContainerStyle = { flexGrow: 1 };
const ContainerWrapper = styled.View`
  flex: 1;
  justify-content: space-between;
  position: relative;
`;
const VerticalCenterWrapper = styled.View`
  flex: 1;
  justify-content: center;
`;

const StyledImageBackground = styled.ImageBackground`
  flex-grow: 1;
`;

const LogoComponent = IconMap?.Logo_black;

const HeaderRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px 14px 0;
`;

const VerticalWrapper = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  z-index: 1;
`;

const StyledAnimationWrapper = styled.View<{
  animationContainerHeight: number;
}>`
  z-index: 2;
  margin-bottom: ${({ animationContainerHeight }) =>
    (animationContainerHeight / 4.5) * -1 + 'px'};
`;

const styles = StyleSheet.create({
  logo: {
    marginLeft: 20,
  },
});

const ScreenContainerV2 = styled(ScreenContainer)`
  height: 100%;
  width: 100%;
  justify-content: center;
`;

const LandingScreenV2Container = styled.View`
  height: ${isWeb ? '90%' : '100%'};
  width: ${isWeb ? '375px' : '100%'};
  ${isWeb &&
  `
    min-height: 690px; //With current contents vertical scrollbar disappears at this value
    max-height: 900px;
    max-width: 100%;
    border-radius: ${theme.borderRadius.allBig}; //This will be visible in the web version
    border: 2px solid ${theme.colors.gray100};
    overflow: hidden;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  `}
`;

const getCircleSvgStyle = (radius: number): any => ({
  position: 'absolute',
  bottom: (radius / 2) * -1,
  left: '50%',
  transform: [{ translateX: (radius / 2) * -1 }],
});

const landingScreenV2ConditionalBottomMargin = isIOS ? 30 : 0;

const LandingScreen = () => {
  const { t } = useTranslation();
  const { setReturnRedirectToAccount, isInRedirectProcess } =
    useAfterAuthenticationRedirect();
  const { loading: customerLoading } = useContext(AccountContext);
  const [signinLoading, setSigninLoading] = useState<boolean>(false);
  const [letsGoLoading, setLetsGoLoading] = useState<boolean>(false);
  const [scrollViewHeight, setScrollViewHeight] = useState(0);
  const [yellowCircleWrapperWidth, setYellowCircleWrapperWidth] = useState(0);
  const [animationContainerHeight, setAnimationContainerHeight] = useState(0);
  const containerRef = useRef<any>(null);
  const {
    flags: { onboarding_v2, use_email_verification },
  } = useContext(FeatureFlagsContext);
  const useOnboardingV2 = showFeatureFlag(onboarding_v2);
  const useEmailVerification = showFeatureFlag(use_email_verification);
  const { handleAuthMethod } = useHandleAuthentication();
  const { refetchFailedSessions } = useIsAccountRestricted();
  const { navigateToParkScreen } = useAfterAuthenticationRedirect();

  const callback = () => {
    setSigninLoading(false);
  };

  const handleSignIn = async () => {
    setSigninLoading(true);
    isSignUpFlow(false);
    !isInRedirectProcess() && setReturnRedirectToAccount();
    await AuthManager.signInAsync(callback);
    await refetchFailedSessions();
    updateStateAndStorage(showLandingScreen, 'showLandingScreen', false);
  };

  const handleSignUp = useCallback(async () => {
    isSignUpFlow(true);
    setReturnRedirectToAccount(false);
    await AuthManager.signUpAsync();
    updateStateAndStorage(showLandingScreen, 'showLandingScreen', false);
  }, [setReturnRedirectToAccount]);

  const handleSkipForNow = () => {
    updateStateAndStorage(showLandingScreen, 'showLandingScreen', false);
    if (isWeb) {
      navigateToParkScreen();
    }
  };

  const handleBackButton = () => {
    handleSkipForNow();
    return true;
  };

  const handleLetsGo = async () => {
    setLetsGoLoading(true);
    if (isWeb) {
      updateStateAndStorage(showLandingScreen, 'showLandingScreen', false);
    }
    await handleAuthMethod(useEmailVerification);
    updateStateAndStorage(showLandingScreen, 'showLandingScreen', false);
    setLetsGoLoading(false);
  };

  useBackButton(handleBackButton);

  return !useOnboardingV2 ? (
    <ScreenContainer
      background={theme.colors.lightBg.orange}
      mb={50}
      {...testIdentifiers('onboarding-landing-screen')}>
      <ScreenWrapper
        onLayout={(e) => setScrollViewHeight(e.nativeEvent.layout.height)}
        bounces={false}
        tabBarHeight={35}
        padding={0}
        contentContainerStyle={contentContainerStyle}>
        <ContainerWrapper ref={containerRef}>
          <VerticalCenterWrapper>
            <StyledWrapper
              maxWidth={350}
              center
              mb={theme.normalising.heightPixel(20)}>
              <StyledImage
                testID="onboarding-welcome-image"
                width={131}
                height={126}
                center
                source={require('assets/images/onboarding-welcome.png')}
              />
            </StyledWrapper>
            <StyledWrapper
              maxWidth={350}
              center
              mb={theme.normalising.heightPixel(30)}>
              <Title center {...testIdentifiers('landing-screen-title')}>
                {t('landingScreen.title')}
              </Title>
            </StyledWrapper>
          </VerticalCenterWrapper>
          <BGView
            noMargins
            paddings={{
              top: theme.normalising.heightPixel(30),
              bottom: isWeb
                ? screenHeight - 55 - containerRef?.current?.clientHeight
                : screenHeight - scrollViewHeight,
            }}
            bgColor={theme.colors.lightOrange}
            noShrink
            noFlex>
            <StyledWrapper
              maxWidth={350}
              center
              mb={theme.normalising.heightPixel(20)}>
              <BaseText testID="landing-screen-text" center>
                {t('landingScreen.text')}
              </BaseText>
            </StyledWrapper>
            <StyledWrapper
              center
              maxWidth={300}
              mb={theme.normalising.heightPixel(20)}>
              <Button
                {...testIdentifiers('landing-screen-sign-up-button')}
                text={t('account.createAccount')}
                size="l"
                buttonType="secondary"
                onPress={handleSignUp}
              />
            </StyledWrapper>
            <StyledWrapper
              center
              maxWidth={300}
              mb={theme.normalising.heightPixel(10)}>
              <Button
                loading={signinLoading || customerLoading}
                {...testIdentifiers('landing-screen-sign-in-button')}
                text={t('account.signIn')}
                size="l"
                buttonType="secondary"
                onPress={handleSignIn}
              />
            </StyledWrapper>
            <StyledWrapper
              center
              maxWidth={300}
              mb={theme.normalising.heightPixel(20)}>
              <Button
                {...testIdentifiers('landing-screen-skip-button')}
                text={t('onboarding.skipForNow')}
                size="l"
                buttonType="link"
                onPress={handleSkipForNow}
              />
            </StyledWrapper>
          </BGView>
        </ContainerWrapper>
      </ScreenWrapper>
    </ScreenContainer>
  ) : (
    <ScreenContainerV2
      background={theme.colors.gray50}
      mb={50}
      center
      {...testIdentifiers('onboarding-landing-screen-v2')}>
      <LandingScreenV2Container>
        <StyledImageBackground
          source={require('assets/images/map-like-background.png')}
          resizeMode="cover">
          <ScreenWrapper
            onLayout={(e) => {
              setScrollViewHeight(e.nativeEvent.layout.height);
            }}
            bounces={false}
            tabBarHeight={isWeb ? 0 : 35}
            padding={0}
            contentContainerStyle={contentContainerStyle}>
            <ContainerWrapper ref={containerRef}>
              <VerticalWrapper>
                <HeaderRow>
                  <LogoComponent width="55" height="28" style={styles.logo} />
                  <LanguageButton />
                </HeaderRow>
                <StyledWrapper
                  center
                  mb={0}
                  onLayout={(e) =>
                    setAnimationContainerHeight(e.nativeEvent.layout.height)
                  }>
                  <StyledAnimationWrapper
                    animationContainerHeight={animationContainerHeight}>
                    {isWeb ? (
                      <Player
                        src={require('assets/animations/aimo_intro_animation.json')}
                        autoplay
                        loop
                      />
                    ) : (
                      <LottieView
                        source={require('assets/animations/aimo_intro_animation.json')}
                        autoPlay
                        autoSize
                      />
                    )}
                  </StyledAnimationWrapper>
                  <View
                    //Wrapper needed to calculate the yellow circle's width and center it correctly
                    // via absolute positioning and resting the 50%
                    onLayout={(e) =>
                      setYellowCircleWrapperWidth(e.nativeEvent.layout.width)
                    }>
                    <Svg
                      width={yellowCircleWrapperWidth * 0.8}
                      height={yellowCircleWrapperWidth * 0.8}
                      viewBox="0 0 100 100"
                      style={getCircleSvgStyle(yellowCircleWrapperWidth * 0.8)}>
                      <Defs>
                        <ClipPath id="clip">
                          <Rect
                            x={0}
                            y={0}
                            width={100}
                            height={50}
                            fill="#000"
                          />
                        </ClipPath>
                      </Defs>
                      <Circle
                        cx={50}
                        cy={50}
                        r={50}
                        fill={theme.colors.lightBg.yellow}
                        clipPath="url(#clip)"
                      />
                    </Svg>
                  </View>
                </StyledWrapper>
              </VerticalWrapper>
              <BGView
                noRoundedCorners
                noMargins
                paddings={{
                  top: theme.normalising.heightPixel(
                    animationContainerHeight / 4.5
                  ),
                  bottom: isWeb
                    ? 30
                    : screenHeight +
                      landingScreenV2ConditionalBottomMargin -
                      scrollViewHeight,
                  left: 20,
                  right: 20,
                }}
                bgColor={theme.colors.white}
                noFlex
                noShrink>
                <StyledWrapper
                  maxWidth={350}
                  center
                  mb={theme.normalising.heightPixel(70)}>
                  <Headline1 center>
                    {t('landingScreen.onboardingV2Description')}
                  </Headline1>
                </StyledWrapper>

                <StyledWrapper center maxWidth={335} mb={10}>
                  <Button
                    loading={letsGoLoading || customerLoading}
                    {...testIdentifiers('landing-screen-lets-go-button')}
                    text={t('onboarding.letsGo')}
                    size="l"
                    buttonType="primary"
                    onPress={handleLetsGo}
                  />
                </StyledWrapper>

                <StyledWrapper center maxWidth={300} mb={0}>
                  <Button
                    {...testIdentifiers('landing-screen-skip-button')}
                    text={t('onboarding.skipForNow')}
                    size="m"
                    buttonType="link"
                    onPress={handleSkipForNow}
                  />
                </StyledWrapper>
              </BGView>
            </ContainerWrapper>
          </ScreenWrapper>
        </StyledImageBackground>
      </LandingScreenV2Container>
    </ScreenContainerV2>
  );
};

export default LandingScreen;
