import { useQuery } from '@apollo/client';
import ParkingContext from 'context/ParkingContext';
import { format, isBefore } from 'date-fns';
import { READ_PARKING_SESSIONS } from 'graphql/queries/readParkingSessions';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PredefinedTicket,
  Query,
  QueryReadParkingSessionsArgs,
  SessionType,
} from 'types/generatedSchemaTypes';
import { getTimeLeft } from 'utils/timeUtils';
import useIsLoggedIn from './useIsLoggedIn';

const useReadPurchasedPrepaidTickets = (
  fetchAll = false,
  bypassCache = false
) => {
  const { isLoggedIn } = useIsLoggedIn();
  const { t } = useTranslation();
  const { parkingZoneResult } = useContext(ParkingContext);
  const commercialZoneCode =
    parkingZoneResult?.data?.parkZone?.commercialZoneView?.zoneCode;
  const country = parkingZoneResult?.data?.parkZone?.countryCode;
  const {
    data,
    loading,
    error,
    refetch: refetchPrepaidTickets,
  } = useQuery<Query, QueryReadParkingSessionsArgs>(READ_PARKING_SESSIONS, {
    variables: {
      input: {
        parkingState: ['Started', 'NotStarted'],
        sessionType: ['PREPAID'],
        zoneCode: !fetchAll ? commercialZoneCode : undefined,
        countryCode: !fetchAll ? country : undefined,
      },
    },
    skip: (!fetchAll && !commercialZoneCode) || !isLoggedIn,
    fetchPolicy: bypassCache ? 'network-only' : 'cache-and-network',
    errorPolicy: 'all',
  });

  const prepaidNameFromDates = (startDate: string, endDate: string) => {
    const startDateInFuture = isBefore(new Date(), new Date(startDate));
    if (startDateInFuture) {
      const formatedDate = format(new Date(startDate), 'd.M HH:mm');
      return `${t('parking.prepaidTickets.validFrom', { date: formatedDate })}`;
    }
    const { timeLeft } = getTimeLeft(new Date(endDate), new Date(), true);
    return `${t('parking.prepaidTickets.validFor')} ${timeLeft}`;
  };

  const purchasedPrepaidTickets = (
    data?.readParkingSessions?.content || []
  ).map((parkingSession) => {
    if (parkingSession) {
      const purchasedTicket: PredefinedTicket = {
        sessionType: parkingSession.type as SessionType,
        sessionId: parkingSession.sessionId,
        type: 'FIXED_DURATION',
        price: parkingSession.price?.totalAmount,
        startTime: parkingSession.startDate?.toString() as string,
        parkZoneName:
          parkingSession?.parkZone?.commercialZoneView?.name ||
          parkingSession?.parkZone?.name,
        endTime: parkingSession.predefinedEndDate,
        name: prepaidNameFromDates(
          parkingSession.startDate?.toString() as string,
          parkingSession.predefinedEndDate as string
        ),
        accessDevice: parkingSession.accessDevice,
        reassignments: parkingSession.reassignments,
      };
      return purchasedTicket;
    }
  });

  return {
    data,
    purchasedPrepaidTickets,
    loading,
    error,
    refetchPrepaidTickets,
  };
};

export default useReadPurchasedPrepaidTickets;
