import React from 'react';
import BlockerMenuItem from './BlockerMenuItem';
import { useTranslation } from 'react-i18next';
import { BaseText, Headline3 } from 'aimo-ui';
import { useNavigation } from '@react-navigation/native';
import { AccountScreenNavigationProp } from 'types/navigation';
import { sheetModal } from 'states/common';
import { Text } from 'react-native';
import { Permit } from 'types/generatedSchemaTypes';

const PermitBlocker = ({ permitsData }: { permitsData: [Permit] }) => {
  const { navigate } = useNavigation<AccountScreenNavigationProp>();
  const { t } = useTranslation();
  const numberOfPermits = permitsData.length;

  const navigateTo = () => {
    sheetModal('');
    if (numberOfPermits > 1) {
      navigate('accountScreen', { screen: 'prepaidHistory' });
    } else if (permitsData && numberOfPermits > 0) {
      const permitID = permitsData[0].id;
      navigate('servicesScreen', {
        screen: 'permit',
        params: { permitId: permitID || '' },
      });
    }
  };

  const TextComponent = () => {
    if (numberOfPermits === 1) {
      return (
        <Text>
          <BaseText>{t('account.remove.permits_front_one')}</BaseText>
          <Headline3>{t('account.remove.permits_one')}</Headline3>
          <BaseText>{t('account.remove.permits_tail')}</BaseText>
        </Text>
      );
    }
    return (
      <Text>
        <BaseText>{t('account.remove.permits_front_other')}</BaseText>
        <Headline3>
          {t('account.remove.permits_other', { count: numberOfPermits })}
        </Headline3>
        <BaseText>{t('account.remove.permits_tail')}</BaseText>
      </Text>
    );
  };

  if (numberOfPermits > 0) {
    return (
      <BlockerMenuItem
        text={TextComponent}
        testID="account-remove-permit-blocker"
        onPress={navigateTo}
      />
    );
  }
  return <></>;
};

export default PermitBlocker;
