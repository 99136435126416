import { Headline3 } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import FeatureFlagsContext from 'context/FeatureFlagsContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { sheetModal } from 'states/common';
import { SheetDetailsWrapper, StyledWrapper } from 'styles/ContainerStyles';
import { isWeb } from 'utils/deviceUtils';
import { showFeatureFlag } from 'utils/featureFlagUtils';

export const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const {
    flags: { account_notification_settings_parking_ending_reminders },
  } = useContext(FeatureFlagsContext);
  const showAccountNotificationSettingsParkingEndingReminders = showFeatureFlag(
    account_notification_settings_parking_ending_reminders
  );

  return (
    <SheetDetailsWrapper pt={isWeb ? 60 : 30} pb={20}>
      {showAccountNotificationSettingsParkingEndingReminders ? (
        <>
          <StyledWrapper mb={10}>
            <Headline3 testID="parking-title">{t('account.parking')}</Headline3>
          </StyledWrapper>
          <StyledWrapper>
            <MenuItem
              menuLabel={t('account.parkingReminder')}
              testID="account-parking-reminder"
              withArrow={true}
              bold={false}
              onPress={() => sheetModal('setParkingReminder')}
            />
          </StyledWrapper>
        </>
      ) : (
        <></>
      )}
    </SheetDetailsWrapper>
  );
};

export default Notifications;
