import { gql } from '@apollo/client';

export const READ_CHARGING_SESSION = gql`
  query ReadChargingSession($sessionId: ID!) {
    readChargingSession(sessionId: $sessionId) {
      chargingSessionId
      transactionId
      errorMessage
      priceInCents
      countryCode
      startTime
      endTime
      paymentState
      parkingZoneUid
      licensePlate
      created
      cardId
      energyInWatts
      provider
      token
    }
  }
`;
