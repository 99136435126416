import { Headline3, theme } from 'aimo-ui';
import MenuItem from 'components/common/MenuItem';
import FlatListCustom from 'components/sheets/FlatListCustom';
import SheetLayout from 'components/sheets/SheetLayout';
import HslContext from 'context/HslContext';
import { HslTicketType } from 'hooks/useHslWizard';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnWithMargins } from 'styles/ContainerStyles';

const SelectTicketType = () => {
  const { t } = useTranslation();
  const {
    setCurrentScreen,
    setSelectedTicketType,
    ticketTypes,
    hasHslAuthentication,
  } = useContext(HslContext);

  const handleNavigateBack = () => {
    setCurrentScreen('');
  };

  const onItemClick = (item: HslTicketType) => {
    setSelectedTicketType(item);
    if (!hasHslAuthentication() && item.type === 'SEASON') {
      return setCurrentScreen('AUTHENTICATE');
    }
    setCurrentScreen('BUY_TICKET');
  };

  return (
    <SheetLayout
      onHeadlineButtonPress={handleNavigateBack}
      testIDName="hsl-select-ticket"
      headlineComponent={
        <Headline3 center>{t('hslTicket.selectType')}</Headline3>
      }>
      <FlatListCustom
        data={ticketTypes}
        testID="hsl-select-ticket-list"
        ItemSeparatorComponent={() => <ColumnWithMargins mb={10} />}
        renderItem={({ item }) => (
          <MenuItem
            onPress={() => onItemClick(item)}
            menuLabel={t(`hslTicket.types.${item.translationKey}.title`)}
            subtitle={t(`hslTicket.types.${item.translationKey}.subTitle`)}
            testID={`hsl-ticket-${item.translationKey}`}
            noBorder
            iconName={item.icon}
            background={theme.colors.gray50}
          />
        )}
      />
    </SheetLayout>
  );
};

export default SelectTicketType;
