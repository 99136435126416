import { useQuery, useReactiveVar } from '@apollo/client';
import { Meta, Spinner } from 'aimo-ui';
import { READ_PARK_ZONE_PRICE_ESTIMATE } from 'graphql/queries/getParkZonePriceEstimate';
import { t } from 'i18next';
import React from 'react';
import { activeElementType, selectedTariffId } from 'states/common';
import { PaymentType } from 'types/common';
import {
  CountryCode,
  Maybe,
  Query,
  QueryReadParkZonePriceEstimateArgs,
} from 'types/generatedSchemaTypes';
import { currencyFormatter, getCurrencyString } from 'utils/commonUtils';
import { getCustomerSegmentUid } from 'utils/parkUtils';
import { addHoursToDate } from 'utils/timeUtils';

type PriceEstimateTextProps = {
  textColor?: string;
  chargingPricePerKwh?: Maybe<number>;
  parkingZoneCountryCode: CountryCode;
  paymentType: PaymentType;
  subText?: string;
};

type ParkingPriceItemProps = {
  priceEstimateCallback?: ({
    serviceFee,
    total,
  }: {
    serviceFee: number;
    total: any;
  }) => void;
  parkingZoneUid: string;
  parkingZoneCountryCode: CountryCode;
  chargingPricePerKwh?: Maybe<number>;
  paymentType: PaymentType;
  textColor?: string;
  startDate: Date;
} & PriceEstimateTextProps;

const PriceEstimateText: React.FC<
  PriceEstimateTextProps & {
    totalWithoutServiceFee: number;
    serviceFee: number;
    hasPrice: boolean;
  }
> = ({
  chargingPricePerKwh,
  textColor,
  totalWithoutServiceFee,
  serviceFee,
  parkingZoneCountryCode,
  paymentType,
  hasPrice,
  subText = '',
}) => {
  const selectedType = useReactiveVar(activeElementType);
  const showServiceFee = () => {
    return serviceFee !== 0 && typeof serviceFee === 'number'
      ? `+ ${currencyFormatter(serviceFee, parkingZoneCountryCode)}`
      : '';
  };
  const getPriceEstimate = () => {
    if (selectedType === 'CHARGING') {
      return chargingPricePerKwh
        ? `${chargingPricePerKwh} ${getCurrencyString(
            parkingZoneCountryCode
          )} /kWh`
        : t('price.noPrice');
    }
    if (paymentType !== 'free') {
      return hasPrice
        ? `${subText}${currencyFormatter(
            totalWithoutServiceFee,
            parkingZoneCountryCode
          )}/h ${showServiceFee()}`
        : t('price.noTariff');
    }
    return `${currencyFormatter(0, parkingZoneCountryCode)}/h`;
  };
  const pricingInfo = getPriceEstimate();
  return (
    <Meta color={textColor} testID="price-estimate-hour">
      {pricingInfo}
    </Meta>
  );
};

const ParkingPriceEstimateInfo: React.FC<ParkingPriceItemProps> = ({
  priceEstimateCallback,
  parkingZoneUid,
  parkingZoneCountryCode,
  chargingPricePerKwh,
  paymentType,
  textColor,
  startDate,
  subText,
}: ParkingPriceItemProps) => {
  const selectedCustomerSegment = useReactiveVar(selectedTariffId);
  const customerSegmentUid = getCustomerSegmentUid(selectedCustomerSegment);
  const selectedType = useReactiveVar(activeElementType);

  const { data, loading } = useQuery<Query, QueryReadParkZonePriceEstimateArgs>(
    READ_PARK_ZONE_PRICE_ESTIMATE,
    {
      variables: {
        input: {
          parkingZoneUid,
          startDate,
          endDate: addHoursToDate(startDate, 1),
          customerSegmentUid,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip:
        !parkingZoneUid ||
        paymentType !== 'normal' ||
        selectedType === 'CHARGING',
      onCompleted: (priceData) => {
        priceEstimateCallback &&
          priceEstimateCallback({
            serviceFee:
              priceData?.readParkZonePriceEstimate?.breakdown?.serviceFee
                ?.amount || 0,
            total: priceData?.readParkZonePriceEstimate?.totalAmount,
          });
      },
    }
  );

  // TODO: Add when we have some endpoint for pooling pricing estimations
  // const { data: poolingData, loading: poolingLoading } = useQuery<
  //   Query,
  //   QueryReadParkZonePriceEstimateArgs
  // >(READ_PARK_ZONE_PRICE_ESTIMATE, {
  //   variables: {
  //     input: { parkingZoneUid, startDate, endDate },
  //   },
  //   fetchPolicy: 'cache-and-network',
  //   skip: !parkingZoneUid || !pooling || paymentType !== 'discount',
  // });

  const serviceFee =
    data?.readParkZonePriceEstimate?.breakdown?.serviceFee?.amount || 0;
  const total = data?.readParkZonePriceEstimate?.totalAmount;
  const hasPrice = typeof total === 'number';
  const totalWithoutServiceFee = (total || 0) - serviceFee;

  return loading ? (
    <Spinner small testID="price-estimate-loader" />
  ) : (
    <PriceEstimateText
      parkingZoneCountryCode={parkingZoneCountryCode}
      paymentType={paymentType}
      totalWithoutServiceFee={totalWithoutServiceFee}
      serviceFee={serviceFee}
      hasPrice={hasPrice}
      chargingPricePerKwh={chargingPricePerKwh}
      textColor={textColor}
      subText={subText}
    />
  );
};

export default ParkingPriceEstimateInfo;
