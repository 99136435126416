import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHeaderHeight } from '@react-navigation/elements';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { BottomButtonsLayout, theme } from 'aimo-ui';
import { ColumnWithMargins, ScreenWrapper } from 'styles/ContainerStyles';
import AccessPermitTicket from 'screens/permit/access/AccessPermitTicket';
import { ServicesNavProps, ServicesStackParamList } from 'types/navigation';
import AccessPermitButtons from 'screens/permit/access/AccessPermitButtons';
import { showToast } from 'components/common/CustomToast';
import { isWeb } from 'utils/deviceUtils';
import { selectedLicensePlatesPermitRedeem } from 'states/common';
import { gql, useMutation } from '@apollo/client';
import {
  LicensePlate,
  Mutation,
  MutationUpdatePermitArgs,
  UnifiedPermit,
} from 'types/generatedSchemaTypes';
import { UPDATE_PERMIT } from '@mutations/updatePermit';
import AccountContext from 'context/AccountContext';
import { isAccessPermitRedeemed } from 'utils/permitUtils';
import { updateCache } from 'utils/cacheUtils';

const ModalContentWrapper = ({ children }: { children: ReactNode }) => {
  const headerHeight = useHeaderHeight();

  return isWeb ? (
    <ScreenWrapper tabBarHeight={headerHeight}>{children}</ScreenWrapper>
  ) : (
    <ColumnWithMargins ml={20} mr={20}>
      {children}
    </ColumnWithMargins>
  );
};

const AccessPermitRedeem: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<ServicesNavProps>();
  const navigation = useNavigation();
  const {
    params: { permitId },
  } = useRoute<RouteProp<ServicesStackParamList, 'permitRedeem'>>();
  const {
    customer: { permits },
  } = useContext(AccountContext);
  const permit = permits?.find((p) => p?.id === permitId) as UnifiedPermit;
  const selectedLicensePlates = selectedLicensePlatesPermitRedeem();
  const [loadingComponent, setLoadingComponent] = React.useState(true);
  const [isAccessPermitRedeemedState, setIsAccessPermitRedeemedState] =
    useState(false);

  useEffect(() => {
    if (permit) {
      setIsAccessPermitRedeemedState(isAccessPermitRedeemed(permit));
    }
  }, [permit]);

  useEffect(() => {
    navigation.setOptions({
      title: isAccessPermitRedeemedState
        ? t('permit.access.updatePermit')
        : t('permit.access.redeemPermit'),
    });
  }, [t, navigation, isAccessPermitRedeemedState]);

  useEffect(() => {
    // On load, set up the selected license plates of the permit
    selectedLicensePlatesPermitRedeem(
      (permit?.registrationPlates || []) as LicensePlate[]
    );
    setLoadingComponent(false);
  }, [permit?.registrationPlates]);

  const [updatePermit, { loading }] = useMutation<
    Mutation,
    MutationUpdatePermitArgs
  >(UPDATE_PERMIT, {
    update: (cache, { data }) => {
      if (permitId) {
        const id = permitId;
        const { registrationPlates } = data?.updatePermit || {};
        updateCache(
          cache,
          { __typename: 'UnifiedPermit', id },
          {
            fragment: gql`
              fragment UnifiedPermitFragment on UnifiedPermit {
                registrationPlates
                isWaitingActivation
              }
            `,
            data: {
              registrationPlates,
              isWaitingActivation: isAccessPermitRedeemedState,
            },
          }
        );
      }
    },

    onCompleted: () => {
      if (isAccessPermitRedeemedState) {
        showToast('success.updatePermit', 'success');
      } else {
        showToast('success.permitRedeem', 'success');
      }

      navigate('servicesMain');
    },
    onError: () => showToast('error.permitRedeemFailed', 'error'),
  });

  const handleRedeem = async () => {
    await updatePermit({
      variables: {
        input: {
          permitId,
          permitActionType: 'PATCH',
          registrationPlates: selectedLicensePlates,
        },
      },
    });
  };

  if (!permit) {
    showToast(t('error.readPermit'), 'error');
    navigate('servicesMain');
    return <></>;
  }

  if (loadingComponent) {
    return <></>;
  }

  return (
    <BottomButtonsLayout
      contentBottom={
        <ColumnWithMargins
          mr={20}
          ml={20}
          mb={theme.normalising.heightPixel(30)}>
          <AccessPermitButtons
            handleRedeem={handleRedeem}
            isRedeemed={isAccessPermitRedeemedState}
            loading={loading}
            disabled={selectedLicensePlates === permit.registrationPlates}
          />
        </ColumnWithMargins>
      }>
      <ModalContentWrapper>
        <AccessPermitTicket permit={permit} />
      </ModalContentWrapper>
    </BottomButtonsLayout>
  );
};

export default AccessPermitRedeem;
