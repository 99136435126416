import useIsDesktop from 'hooks/useIsDesktop';
import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';
import { Shadow } from 'react-native-shadow-2';
import { isWeb } from 'utils/deviceUtils';

type ShadowProps = {
  children?: ReactNode;
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
});

const ShadowComponent = ({ children }: ShadowProps) => {
  const isDesktop = useIsDesktop();
  const defaultShadowDistance = isWeb && isDesktop ? 0 : 10;

  return (
    <Shadow
      sides={{ top: true, bottom: false, end: false, start: false }}
      distance={defaultShadowDistance}
      safeRender={true}
      style={styles.container}
      containerStyle={styles.container}>
      {children}
    </Shadow>
  );
};

export default ShadowComponent;
