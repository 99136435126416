import React from 'react';
import { BaseText, theme } from 'aimo-ui';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
import MenuItem from 'components/common/MenuItem';
import { StyledWrapper } from 'styles/ContainerStyles';
import { CountryCode } from 'types/generatedSchemaTypes';
import { RootNavProps } from 'types/navigation';
import { sheetModal } from 'states/common';
import { featureCountryCode } from 'states/persistInStorage';

const CountryOption = ({ countryCode }: { countryCode: CountryCode }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<RootNavProps>();

  const handlePress = () => {
    featureCountryCode(countryCode);
    sheetModal('');
    navigate('mainTabs', {
      screen: 'accountScreen',
      params: {
        screen: 'contactUs',
      },
    });
  };

  return (
    <MenuItem
      menuLabel={t(`account.appLocations.${countryCode}`)}
      withArrow
      iconName={`flag-${countryCode}`}
      onPress={handlePress}
    />
  );
};

const ContactUsSelectCountry = () => {
  const { t } = useTranslation();

  return (
    <ScrollView>
      <StyledWrapper maxWidth={350} center mb={30}>
        <BaseText center color={theme.colors.gray200}>
          {t('account.supportSelectCountry')}
        </BaseText>
      </StyledWrapper>
      <StyledWrapper mb={50}>
        <CountryOption countryCode="FI" />
        <CountryOption countryCode="SE" />
      </StyledWrapper>
    </ScrollView>
  );
};

export default ContactUsSelectCountry;
