import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Button, theme } from 'aimo-ui';
import ParkingSignInButton from 'components/park/ParkingSignInButton';
import {
  FooterButtonContainer,
  VerticalDistributedContainer,
} from 'components/sheets/SheetLayout';
import AccountContext from 'context/AccountContext';
import ParkingContext from 'context/ParkingContext';
import { differenceInMinutes } from 'date-fns';
import useAddPaymentMethod from 'hooks/useAddPaymentMethod';
import useCreateSession from 'hooks/useCreateSession';
import useHasParkingSession from 'hooks/useHasParkingSession';
import useIsDesktop from 'hooks/useIsDesktop';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useOngoingChargingSession from 'hooks/useOngoingChargingSession';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  accountRestricted,
  selectedOngoingSession,
  sheetModal,
} from 'states/common';
import { onboardingCompleted } from 'states/persistInStorage';
import { SimpleFullContainer } from 'styles/ContainerStyles';
import { RootNavProps } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import PaymentCardDropDown from '../detail/PaymentCardDropDown';
import { defineDisabled } from '../detail/StartParkingButton';
import VehicleSelectDropDown from '../detail/VehicleSelectDropDown';
import PreloadingWrapper from '../timeSelection/PreloadingWrapper';

const StartParkAndChargeButton: React.FC = (...props) => {
  const {
    parkingConditions: {
      endTime,
      vehicle,
      paymentMethod,
      parkingZoneUid,
      allowedTimes,
      isValidEndTime,
      companyBenefit,
    },
    selectedEvCharger,
    setCurrentScreen,
    currentScreen,
    setEndTime,
  } = useContext(ParkingContext);
  const {
    customer: { vehicles },
  } = useContext(AccountContext);

  const { isLoggedIn, loginLoading } = useIsLoggedIn();

  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const { navigate } = useNavigation<RootNavProps>();
  const {
    createParking,
    createCharging,
    createParkingLoading,
    createChargingLoading,
    parkingSuccess,
    chargingSuccess,
  } = useCreateSession({
    parkingZoneType: 'PARK_AND_CHARGE',
    onErrorCallback: () => setCurrentScreen('SHOW_DETAILS'),
  });
  const { ongoingChargingSession } = useOngoingChargingSession();
  const { addCard, loadingAddCard } = useAddPaymentMethod();

  const notConnected = selectedEvCharger?.evse.status !== 'OCCUPIED';

  const ongoingChargingChanged = useMemo(() => {
    return ongoingChargingSession;
  }, [ongoingChargingSession]);
  const prevSessions = useRef(ongoingChargingChanged);
  const chargingSessionChange = prevSessions.current !== ongoingChargingChanged;

  const ongoingSessionType = useReactiveVar(selectedOngoingSession);
  const isUserRestricted = useReactiveVar(accountRestricted);

  const { hasParkingSession } = useHasParkingSession({
    parkingZoneUid,
    vehicle,
  });
  const hasVehicles = !!(vehicles && vehicles.length > 0);

  const missingDetails = useMemo(
    () =>
      (!paymentMethod?.cardId && !companyBenefit?.benefitTargetUid) || !vehicle,
    [paymentMethod, companyBenefit, vehicle]
  );

  const startParkingChargingLoading = useMemo(
    () =>
      createParkingLoading ||
      createChargingLoading ||
      (chargingSuccess && !chargingSessionChange),
    [
      createParkingLoading,
      createChargingLoading,
      chargingSuccess,
      chargingSessionChange,
    ]
  );

  const minuteDif = useMemo(() => {
    if (endTime) {
      return differenceInMinutes(endTime, new Date()) + 1;
    }
  }, [endTime]);
  const minuteDifLessThanTwo = !!minuteDif && minuteDif < 2;

  const disabled =
    startParkingChargingLoading ||
    !selectedEvCharger ||
    (currentScreen === 'CHOOSE_END_TIME' &&
      defineDisabled({
        parkingZoneUid,
        allowedTimes,
        isValidEndTime,
      })) ||
    minuteDifLessThanTwo;

  const renderMarging = () => {
    if (ongoingSessionType !== '') {
      if (isWeb && !isDesktop) {
        return theme.normalising.heightPixel(30);
      }
      return theme.normalising.heightPixel(70);
    }
    return 0;
  };

  // If user switches to vehicle which has parking session, skip CHOOSE_END_TIME
  useEffect(() => {
    if (
      currentScreen === 'CHOOSE_END_TIME' &&
      hasParkingSession &&
      notConnected
    ) {
      setEndTime(undefined);
      return setCurrentScreen('CONNECT_CHARGER');
    }
  }, [
    hasParkingSession,
    currentScreen,
    setCurrentScreen,
    setEndTime,
    notConnected,
  ]);

  const handleContinue = async () => {
    const hasValidPaymentMethod = !!(
      paymentMethod?.cardId || companyBenefit?.memberGroupUid
    );

    if (isUserRestricted) {
      sheetModal('accountRestricted');
    } else if (!hasValidPaymentMethod && !vehicle && !onboardingCompleted()) {
      // Redirect to onboarding flow
      navigate('onboardingModal', {
        screen: 'welcome',
      });
    } else if (!hasValidPaymentMethod) {
      // Redirect to add payment method screen
      addCard();
    } else if (!vehicle) {
      handleNoVehicleSelected();
      // If user has no parking or end time, set current screen CHOOSE_END_TIME
    } else if (!hasParkingSession && (!endTime || minuteDifLessThanTwo)) {
      setEndTime(new Date());
      setCurrentScreen('CHOOSE_END_TIME');
      // If user has not connected the charger we wait for the charger to be connected
    } else if (notConnected) {
      setCurrentScreen('CONNECT_CHARGER');
    } else {
      parkingSuccess || hasParkingSession
        ? await createCharging()
        : await createParking();
    }
  };

  const handleNoVehicleSelected = () => {
    if (hasVehicles) {
      // Open select vehicle modal
      sheetModal('parkingVehicle');
    } else {
      // Redirect to add vehicle screen
      navigate('addVehicleParking');
    }
  };

  const buttonText = () => {
    if (!selectedEvCharger) {
      return t('evCharging.selectCharger');
    }
    if (!paymentMethod?.cardId && !companyBenefit?.benefitTargetUid) {
      return t('onboarding.addPaymentMethod');
    }
    if (!vehicle && !hasVehicles) {
      return t('onboarding.addVehicle');
    }
    if (!vehicle && hasVehicles) {
      return t('parking.parkingProcess.noVehicleSelected');
    }
    return t('button.start');
  };

  if (!isLoggedIn) {
    return (
      <ParkingSignInButton
        testID="park-zone-details-login"
        loading={loginLoading}
      />
    );
  }

  return (
    <FooterButtonContainer {...props} pb={15}>
      <VerticalDistributedContainer>
        <FooterButtonContainer pb={0}>
          <VehicleSelectDropDown />
          <PaymentCardDropDown />
        </FooterButtonContainer>
        <FooterButtonContainer pb={renderMarging()}>
          <SimpleFullContainer>
            <PreloadingWrapper
              firstItem={
                <Button
                  buttonType={missingDetails ? 'secondary' : 'primary'}
                  size="l"
                  loading={startParkingChargingLoading || loadingAddCard}
                  text={buttonText()}
                  testID="park-zone-start-park-and-charge-button"
                  disabled={disabled}
                  onPress={handleContinue}
                />
              }
              secondItem={
                <Button size="l" disabled loading text={buttonText()} />
              }
            />
          </SimpleFullContainer>
        </FooterButtonContainer>
      </VerticalDistributedContainer>
    </FooterButtonContainer>
  );
};

export default StartParkAndChargeButton;
