import { Meta, theme } from 'aimo-ui';
import QuickActionDirection from 'components/common/QuickActionDirection';
import Icon from 'components/icons/Icon';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import { t } from 'i18next';
import React from 'react';
import { Divider } from 'styles/CommonLayoutStyles';
import { ColumnWithMargins, RowSpaceBetween } from 'styles/ContainerStyles';
import { UnifiedPermit } from 'types/generatedSchemaTypes';
import { isSalesOrderFree } from 'utils/commonUtils';
import { makePoint } from 'utils/mapUtils';
import PermitParkingSpace from './PermitParkingSpace';
import RemainingValidityIndicator from './RemainingValidityIndicator';
import {
  SalesOrderReceipt,
  SalesOrderReceiptIntersection,
} from './SalesOrderReceiptClass';
import { PermitContentWrapper, RowContainer, RowMeta } from './styles';

type TicketRowProps = {
  title: string;
  titleColor?: string;
  content?: string;
  children?: React.ReactNode;
  testID?: string;
  divider?: boolean;
};

export const TicketRow = ({
  title,
  titleColor,
  content,
  testID,
  divider = true,
  children,
}: TicketRowProps) => {
  return (
    <>
      <RowSpaceBetween padding={5}>
        <ColumnWithMargins>
          <Meta color={titleColor ?? theme.colors.gray300}>{title}</Meta>
        </ColumnWithMargins>
        <ColumnWithMargins>
          {content && <Meta testID={testID}>{content}</Meta>}
          {children}
        </ColumnWithMargins>
      </RowSpaceBetween>
      {divider && <Divider />}
    </>
  );
};

const PermitTicketContent = ({ permit }: { permit: UnifiedPermit }) => {
  const salesOrder =
    permit.permitType === 'SALES_ORDER'
      ? new SalesOrderReceipt(permit as SalesOrderReceiptIntersection)
      : null;
  const {
    uid,
    orderLines,
    creditCardId,
    orderStatus,
    isWaiting,
    nextBillingDate,
    endDate,
    isInvoicing,
    isRecurring,
  } = salesOrder || {};

  const { pmc, registrationPlates, billingFrequency, parkingSpace } =
    orderLines?.[0] || {};
  const { parkZone, prices } = pmc || {};
  const paymentCard = useSelectedPaymentMethod(creditCardId || undefined);
  const creditCardNums = paymentCard?.lastNums;
  const licensePlateColor = registrationPlates?.[0]
    ? theme.colors.black
    : theme.colors.boost.red;
  const licensePlateText =
    registrationPlates?.[0]?.plateText ?? t('services.permitNoVehicle');
  const marker = parkZone?.address?.marker;
  const markerCoordinate = marker && makePoint(marker?.coordinates);

  const street = parkZone?.address?.street ?? t('parkingZone.noAddress');
  const number = parkZone?.address?.number || '';

  const freeSalesOrder = isSalesOrderFree(prices);

  return (
    <PermitContentWrapper>
      {permit.permitType === 'ACCESS' && (
        <TicketRow
          title={t('permit.access.availability')}
          divider={false}
          content={t('permit.access.spot', {
            count:
              permit.accessPermitPoolingGroupCombinedInfo?.freePoolingSpots ||
              0,
          })}
        />
      )}
      {salesOrder && (
        <>
          <TicketRow title={t('services.location')}>
            <RowContainer>
              <RowMeta testID={`permit-${uid}-location`}>
                {street}
                {street && ' ' + number}
              </RowMeta>
              <QuickActionDirection
                showIconLabel={false}
                markerCoordinate={markerCoordinate}
                alignEnd
              />
            </RowContainer>
          </TicketRow>
          <TicketRow
            title={t('services.permitType')}
            content={t(`permit.type.${billingFrequency}`)}
            testID={`permit-${uid}-type`}
          />
          <TicketRow
            title={t('services.permitVehicle')}
            testID={`permit-${uid}-vehicle`}>
            <Meta color={licensePlateColor} testID={`permit-${uid}-vehicle`}>
              {licensePlateText}
            </Meta>
          </TicketRow>
          <TicketRow
            title={t('longTermParking.parkingSpace')}
            content={parkingSpace?.name ? '' : t('longTermParking.any')}
            testID={`permit-${uid}-parking-space`}>
            <PermitParkingSpace
              textColor={theme.colors.black}
              parkingSpaceName={parkingSpace?.name ?? ''}
              spotMap={parkZone?.spotMap}
              meta
            />
          </TicketRow>
          <TicketRow
            title={t('services.billing')}
            testID={`permit-${uid}-billing`}
            content={t(
              `services.${isRecurring ? 'recurringPayment' : 'oneTimePayment'}`
            )}
          />
          {!freeSalesOrder && (
            <TicketRow
              title={t('services.paymentMethod')}
              content={
                creditCardNums && !isInvoicing
                  ? '*** ' + creditCardNums
                  : t('paymentMethods.invoicing')
              }
              testID={`permit-${uid}-payment-method`}
            />
          )}

          <TicketRow
            title={t('services.validity')}
            testID={`permit-${uid}-validity`}>
            <RowContainer>
              <RemainingValidityIndicator
                isRecurring={!!isRecurring}
                expirationDate={nextBillingDate?.toDateString()}
                permitStatus={orderStatus}
                permitId={uid ?? ''}
                isWaiting={isWaiting}
                predefinedEndDate={endDate}
              />
            </RowContainer>
          </TicketRow>
          <TicketRow
            titleColor={theme.colors.black}
            title={t('services.viewDetails')}
            divider={false}>
            <Icon
              name="Chevron_Right"
              size="s"
              testID={`permit-${uid}-view-details`}
            />
          </TicketRow>
        </>
      )}
    </PermitContentWrapper>
  );
};

export default PermitTicketContent;
