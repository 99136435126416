import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Headline3, Meta, theme } from 'aimo-ui';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import SheetLayout from 'components/sheets/SheetLayout';
import ParkingContext from 'context/ParkingContext';
import useBackButton from 'hooks/useBackButton';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  activeElementSecondaryUid,
  ongoingParkingStartChargingSheetExpanded,
  sheetModal,
} from 'states/common';
import { mapCoordinates } from 'states/map';
import { Divider } from 'styles/CommonLayoutStyles';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { IsFocusedProps, SetParamsProps } from 'types/navigation';
import { handleCloseOngoingParkingStartChargingSheet } from 'utils/chargingUtils';
import { handleElPress, makePoint } from 'utils/mapUtils';
import { OneHourParkingPriceEstimate } from '../OneHourParkingPriceEstimate';
import { Row } from '../styles/styles';
import SelectParkAndCharger from './SelectParkAndCharger';

export const SelectCharger: React.FC = () => {
  const { t } = useTranslation();

  const {
    parkingZoneResult: parkingZone,
    setCurrentScreen,
    isOngoingParking,
  } = useContext(ParkingContext);

  const {
    name = '',
    address,
    uid = '',
    commercialZoneView,
  } = parkingZone?.data?.parkZone || {};
  const countryCode = parkingZone?.data?.parkZone?.countryCode || 'FI';

  const { marker } = address || {};
  const ongoingParkingChargingSheetExp = useReactiveVar(
    ongoingParkingStartChargingSheetExpanded
  );

  const { setParams, isFocused }: SetParamsProps & IsFocusedProps =
    useNavigation();
  const isSheetModal = !!useReactiveVar(sheetModal);

  const onBackPress = () => {
    // Make sure onBackPress is not overwritten if modal is open
    if (!isFocused()) {
      return false;
    }
    if (isSheetModal) {
      sheetModal('');
    } else {
      setCurrentScreen('SHOW_DETAILS');
    }
    return true;
  };
  useBackButton(onBackPress);

  if (!parkingZone?.data?.parkZone?.uid) {
    setCurrentScreen('SEARCH');
    return <></>;
  }

  const markerCoordinate = marker && makePoint(marker?.coordinates);

  const handleNamePress = () => {
    if (markerCoordinate) {
      mapCoordinates(markerCoordinate);
    }
  };

  const handlePricePress = () => {
    sheetModal('parkingRates');
  };

  const handleSelectCharger = (chargingStationId: string) => {
    activeElementSecondaryUid(chargingStationId);
    setCurrentScreen('CONNECT_CHARGER');
  };

  const onClosePress = () => {
    if (isOngoingParking) {
      handleCloseOngoingParkingStartChargingSheet();
      setCurrentScreen('SEARCH');
    } else {
      handleElPress(uid, { latitude: 0, longitude: 0 }, setParams);
    }
  };

  return (
    <>
      <SheetLayout
        onHeadlinePress={handleNamePress}
        testIDName="select-charger-layout"
        withBackButton={true}
        onBackPress={() =>
          setCurrentScreen(
            isOngoingParking && ongoingParkingChargingSheetExp
              ? 'SELECT_TARIFF'
              : 'SHOW_DETAILS'
          )
        }
        onHeadlineButtonPress={onClosePress}
        headlineComponent={
          <Headline3
            center
            color={theme.colors.link}
            testID="parkzone-commercial-zone-code">
            {commercialZoneView?.zoneCode ?? ''}{' '}
            <Headline3 color={theme.colors.black}>{name}</Headline3>
          </Headline3>
        }>
        <OneHourParkingPriceEstimate
          parkingZoneUid={uid}
          parkingZoneCountryCode={countryCode}
          withLinkText="price.rates"
          onPricePress={handlePricePress}
        />
        <Row mb={4} mt={0}>
          <Divider marginVertical={0} />
        </Row>
        <>
          <ColumnWithMargins mt={15} mb={20}>
            <Meta color={theme.colors.gray200}>
              {t('evCharging.selectCharger')}
            </Meta>
          </ColumnWithMargins>
          <BottomSheetScrollViewCustom>
            <SelectParkAndCharger
              parkZone={parkingZone.data.parkZone}
              onChargerSelection={handleSelectCharger}
            />
          </BottomSheetScrollViewCustom>
        </>
      </SheetLayout>
    </>
  );
};

export default SelectCharger;
