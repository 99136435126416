import React, { useState } from 'react';
import styled from 'styled-components/native';
import Clipboard from '@react-native-clipboard/clipboard';
import { theme } from 'aimo-ui';

import { ColumnWithMargins } from 'styles/ContainerStyles';
import Icon from 'components/icons/Icon';

const Container = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CopyText = ({copyText, children}: {copyText: string, children: React.ReactNode}) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = async () => {
    copyText && Clipboard.setString(copyText);
    const copiedText = await Clipboard.getString();
    if (copiedText === copyText) {
      setCopied(true);
    }
  };

  return <Container onPress={handleCopy}>
    <ColumnWithMargins mr={8}>
      {children}
    </ColumnWithMargins>
      <Icon
        name={copied ? 'check' : 'Copy-Outlined'}
        color={copied ? theme.colors.black : 'none'}
      />
  </Container>;

};

export default CopyText;
