import { findBestLanguageTag } from 'react-native-localize';
import { Lang } from 'types/generatedSchemaTypes';

export const supportedLanguages: Lang[] = ['en', 'sv', 'fi', 'no'];
export const fallbackLanguage: Lang = 'en';
export const languageLabels: Record<Lang, string> = {
  en: 'English',
  sv: 'Svenska',
  fi: 'Suomi',
  no: 'Norsk',
};

const extractLanguageAbbreviation = (localeAbbreviation: string): string => {
  const languageAbbreviation =
    localeAbbreviation.search(/[-_]/g) !== -1
      ? localeAbbreviation.slice(0, 2)
      : localeAbbreviation;

  // Fallback Bokmål and Nynorsk as Norwegian
  if (languageAbbreviation === 'nb' || languageAbbreviation === 'nn') {
    return 'no';
  }

  return supportedLanguages.includes(languageAbbreviation as Lang)
    ? languageAbbreviation
    : fallbackLanguage;
};

export const getDeviceLanguage = (): string => {
  const preferredAppLanguage =
    findBestLanguageTag(supportedLanguages)?.languageTag;
  if (preferredAppLanguage) {
    return extractLanguageAbbreviation(preferredAppLanguage);
  }
  return fallbackLanguage;
};
