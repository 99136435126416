import React from 'react';
import { ToastProps } from 'react-native-toast-notifications/lib/typescript/toast';
import Toast from 'react-native-toast-notifications';
import { isAndroid, isIos, isWeb } from 'utils/deviceUtils';
import useIsDesktop from 'hooks/useIsDesktop';
import CustomToast, { toastDuration } from '../components/common/CustomToast';

const offsetTop = () => {
  if (isIos) {
    return 40;
  }
  return isAndroid ? 20 : 90;
};

const CustomToastProvider: React.FC = () => {
  const isDesktop = useIsDesktop();
  return (
    <Toast
      ref={(ref) => (global.toast = ref)}
      renderToast={(toastOptions: ToastProps) => CustomToast(toastOptions)}
      animationType="slide-in"
      placement={isWeb && !isDesktop ? 'bottom' : 'top'}
      duration={toastDuration.displayTime}
      animationDuration={toastDuration.animationTime}
      offsetTop={offsetTop()}
    />
  );
};

export default CustomToastProvider;
