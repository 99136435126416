import styled from 'styled-components/native';

export const ListContainer = styled.View<{ pb?: number; isFlex?: boolean }>`
  flex: ${(p) => (p.isFlex ? 1 : 0)};
  padding-top: ${(p) => p.pb ?? '10'}px;
  margin: ${(p) => (p.isFlex ? 0 : 10)}px 0px;
  min-height: 85px;
`;

export const ElementWrapper = styled.View`
  padding: 5px 15px;
`;
