import { useMutation, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseText, BottomButtonsLayout, Button, Meta, theme } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import MenuItem from 'components/common/MenuItem';
import { Validity } from 'screens/permit/ConfirmPurchasePermitModalContent';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';

import { CREATE_PARKING_SESSION } from 'graphql/mutations/createParkingSession';
import { READ_PARKING_SESSIONS } from 'graphql/queries/readParkingSessions';

import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import useSessionPaymentState from 'hooks/useSessionPaymentState';
import useSelectedCompanyBenefit from 'hooks/useSelectedCompanyBenefit';

import {
  confirmPrepaidTicketData,
  currentParkingSession,
  prepaidPurchaseCompleted,
  sheetModal,
} from 'states/common';
import { latestVehicleUsed } from 'states/persistInStorage';
import {
  Mutation,
  MutationCreateParkingSessionArgs,
  ParkingSessionType,
} from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';
import { updateStateAndStorage } from 'utils/MMKVStorageUtils';
import { testIdentifiers } from 'utils/testIdentifiers';

const PrepaidTicketConfirmModalContent: React.FC = () => {
  const prepaidPurchaseData = useReactiveVar(confirmPrepaidTicketData);
  const selectedPaymentMethod = useSelectedPaymentMethod();
  const { pending: isPaymentPending, paymentState } = useSessionPaymentState();
  const { t } = useTranslation();
  const serviceFeeAmount = useSelectedCompanyBenefit()?.transactionFee;

  const [createParking, { loading: createParkingLoading }] = useMutation<
    Mutation,
    MutationCreateParkingSessionArgs
  >(CREATE_PARKING_SESSION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: READ_PARKING_SESSIONS,
        variables: {
          input: {
            parkingState: ['Started'],
            sessionType: ['PREPAID'],
            parkingZoneUid: prepaidPurchaseData?.parkingZoneUid,
          },
        },
      },
    ],
    variables: {
      input: {
        type: prepaidPurchaseData?.type as ParkingSessionType,
        cardId: selectedPaymentMethod?.cardId || '',
        customerEmail: prepaidPurchaseData?.customerEmail || '',
        predefinedTicketUid: prepaidPurchaseData?.predefinedTicketUid || '',
        startDate: prepaidPurchaseData?.startDate,
        licensePlate: prepaidPurchaseData?.licensePlate,
        parkingZoneUid: prepaidPurchaseData?.parkingZoneUid || '',
        serviceFeeAmount,
      },
    },
    onCompleted: (data) => {
      currentParkingSession(data?.createParkingSession?.sessionId || '');
    },
    onError: (error) => {
      if (
        error.graphQLErrors.some(
          (e) => e.extensions?.code === 'PREPAID_TICKET_DUPLICATED'
        )
      ) {
        showToast(
          `${t('parking.prepaidTickets.hasValidTicket', {
            zone: prepaidPurchaseData?.parkzoneLocation,
            vehicle: prepaidPurchaseData?.licensePlate,
          })}`,
          'info'
        );
        return;
      }
      showToast('error.parkingStartedFailed', 'error');
    },
  });

  useEffect(() => {
    return () => {
      confirmPrepaidTicketData(undefined);
    };
  }, []);

  useEffect(() => {
    if (paymentState === 'Success') {
      const text = t('success.prepaidTicketPurchase', {
        zone: prepaidPurchaseData?.parkzoneLocation,
      });
      showToast(`${text}`, 'success');
      prepaidPurchaseCompleted(true);
      updateStateAndStorage(
        latestVehicleUsed,
        'latestVehicleUsed',
        prepaidPurchaseData?.licensePlate
      );
      currentParkingSession(null);
      sheetModal('');
    }
    if (paymentState === 'Terminated' || paymentState === 'Failed') {
      showToast('parking.receipt.paymentFailed', 'error');
      currentParkingSession(null);
      sheetModal('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentState]);

  const onButtonClick = () => {
    createParking();
  };
  return (
    <BottomButtonsLayout
      scrollable={false}
      contentBottom={
        <StyledWrapper mb={30}>
          <Button
            loading={createParkingLoading || isPaymentPending}
            text={t('button.buy')}
            {...testIdentifiers('prepaid-confirm-buy-button')}
            size="l"
            disabled={createParkingLoading || isPaymentPending}
            onPress={onButtonClick}
          />
        </StyledWrapper>
      }>
      <StyledWrapper center {...testIdentifiers('prepaid-info-meta')}>
        <ColumnWithMargins mt={30} mb={20}>
          <Meta number color={theme.colors.gray200}>
            <BaseText color={theme.colors.gray200}>
              {t('services.confirmPermitDescription')}
            </BaseText>
          </Meta>
        </ColumnWithMargins>
      </StyledWrapper>
      <ColumnWithMargins mr={20} ml={20}>
        <StyledWrapper
          mb={20}
          center={isWeb}
          maxWidth={isWeb ? 600 : undefined}>
          <MenuItem
            menuLabel={t('services.location')}
            color={theme.colors.gray300}
            paddingVertical={15}
            testID="prepaid-info-location"
            rightComponent={
              <BaseText {...testIdentifiers('prepaid-info-location-text')}>
                {prepaidPurchaseData?.parkzoneLocation}
              </BaseText>
            }
          />
          <MenuItem
            menuLabel={t('services.ticketType')}
            color={theme.colors.gray300}
            paddingVertical={15}
            testID="prepaid-info-type"
            rightComponent={
              <BaseText {...testIdentifiers('prepaid-info-type-text')}>
                {prepaidPurchaseData?.prepaidName}
              </BaseText>
            }
          />
          <MenuItem
            menuLabel={t('services.permitVehicle')}
            color={theme.colors.gray300}
            paddingVertical={15}
            testID="prepaid-info-vehicle"
            rightComponent={
              <BaseText {...testIdentifiers('prepaid-info-vehicle-text')}>
                {prepaidPurchaseData?.vehicleName
                  ? `${prepaidPurchaseData?.vehicleName} (${prepaidPurchaseData?.licensePlate})`
                  : prepaidPurchaseData?.licensePlate}
              </BaseText>
            }
          />
          <MenuItem
            menuLabel={t('services.paymentMethod')}
            color={theme.colors.gray300}
            paddingVertical={15}
            testID="prepaid-info-payment"
            rightComponent={
              <BaseText {...testIdentifiers('prepaid-info-payment-text')}>
                {t('payment.creditCardOnly')} ****{' '}
                {selectedPaymentMethod?.lastNums}
              </BaseText>
            }
          />
          <MenuItem
            color={theme.colors.gray300}
            testID="prepaid-info-valid-from"
            menuLabel={t('permit.starts')}
            paddingVertical={15}
            rightComponent={
              prepaidPurchaseData?.validFrom ? (
                <Validity
                  date={prepaidPurchaseData.validFrom}
                  testID="prepaid-info-valid-from-text"
                />
              ) : undefined
            }
          />
          <MenuItem
            color={theme.colors.gray300}
            testID="prepaid-info-valid-to"
            menuLabel={t('services.validity')}
            paddingVertical={15}
            noBorder
            rightComponent={
              prepaidPurchaseData?.validTo ? (
                <Validity
                  date={prepaidPurchaseData.validTo}
                  testID="prepaid-info-valid-to-text"
                />
              ) : undefined
            }
          />
        </StyledWrapper>
      </ColumnWithMargins>
    </BottomButtonsLayout>
  );
};

export default PrepaidTicketConfirmModalContent;
