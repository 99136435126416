import { theme } from 'aimo-ui';
import React from 'react';
import { Pressable } from 'react-native';
import Animated, {
  SharedValue,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel';
import styled from 'styled-components/native';
import { CenterRow } from 'styles/ContainerStyles';

export type itemProps<T> = {
  item: T;
  index: number;
};

type CustomCarouselProps<T> = {
  carouselData: T[];
  carouselItem: React.FC<itemProps<T>>;
  enabled: boolean;
  height: number;
  width: number;
  initIndex?: number;
  onSnapToItem?: (index: number) => void;
};

const CarouselContainer = styled.View<{ bottomSpaced: boolean }>`
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const PaginationItem = ({
  index,
  carouselIndex,
  onPressPagination,
}: {
  index: number;
  carouselIndex: SharedValue<number>;
  onPressPagination: (index: number) => void;
}) => {
  const animatedStyle = useAnimatedStyle(() => {
    return {
      marginHorizontal: 10,
      paddingBottom: 5,
      fontSize: 12,
      color: interpolateColor(
        carouselIndex.value,
        [index - 1, index, index + 1],
        [theme.colors.gray100, theme.colors.boost.green, theme.colors.gray100]
      ),
    };
  }, [carouselIndex]);

  return (
    <Pressable onPress={() => onPressPagination(index)}>
      <Animated.Text
        key={`spacer-dot-${index}'`}
        numberOfLines={1}
        style={[animatedStyle]}>
        {'\u2B24'}
      </Animated.Text>
    </Pressable>
  );
};

const CustomCarousel = <T extends any>({
  carouselData,
  carouselItem: CarouselItem,
  enabled,
  height,
  width,
  onSnapToItem,
  initIndex = 0,
}: CustomCarouselProps<T>) => {
  const ref = React.useRef<ICarouselInstance>(null);
  const carouselIndex = useSharedValue(initIndex);

  const handleSnap = (index: number) => {
    onSnapToItem?.(index);
  };

  const onPressPagination = (index: number) => {
    ref?.current?.scrollTo({
      index,
      animated: true,
    });
  };

  return (
    <CarouselContainer bottomSpaced>
      <Carousel
        ref={ref}
        width={width}
        height={height}
        loop={false}
        enabled={enabled}
        data={carouselData}
        defaultIndex={initIndex}
        onSnapToItem={handleSnap}
        onProgressChange={(_, absolut) => (carouselIndex.value = absolut)}
        renderItem={({ item, index }) => (
          <CarouselItem item={item} index={index} />
        )}
      />
      <CenterRow>
        {enabled &&
          carouselData.map((_, index) => (
            <PaginationItem
              onPressPagination={onPressPagination}
              key={`spacer-${index}`}
              carouselIndex={carouselIndex}
              index={index}
            />
          ))}
      </CenterRow>
    </CarouselContainer>
  );
};

export default CustomCarousel;
