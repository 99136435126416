import React from 'react';
import { StyledWrapper } from 'styles/ContainerStyles';
import VehicleColorSelector from 'components/account/vehicle/VehicleColorSelector';
import VehicleIconSelector from 'components/account/vehicle/VehicleIconSelector';
import VehicleNameInput from 'components/account/vehicle/VehicleNameInput';
import { Headline3 } from 'aimo-ui';
import { useTranslation } from 'react-i18next';

type VehicleFormExtrasProps = {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  icon: string;
  setIcon: React.Dispatch<React.SetStateAction<string>>;
  isNew?: boolean;
};

const VehicleFormExtras = ({
  name,
  setName,
  color,
  setColor,
  icon,
  setIcon,
  isNew,
}: VehicleFormExtrasProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isNew && (
        <StyledWrapper mb={20}>
          <Headline3>{t('vehicle.form.optionalDetails')}</Headline3>
        </StyledWrapper>
      )}
      <StyledWrapper mb={20}>
        <VehicleNameInput name={name} setName={setName} />
      </StyledWrapper>
      <StyledWrapper mb={20}>
        <VehicleColorSelector
          selectedColor={color}
          setSelectedColor={setColor}
        />
      </StyledWrapper>
      <StyledWrapper mb={20}>
        <VehicleIconSelector selectedIcon={icon} setSelectedIcon={setIcon} />
      </StyledWrapper>
    </>
  );
};

export default VehicleFormExtras;
