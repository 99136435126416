import { theme } from 'aimo-ui';
import DropDownButton from 'components/common/DropDownButton';
import Icon from 'components/icons/Icon';
import useAddPaymentMethod from 'hooks/useAddPaymentMethod';
import useGetMatchingAccessPermitForParkingZone from 'hooks/useGetMatchingAccessPermitForParkingZone';
import useSelectedCompanyBenefit from 'hooks/useSelectedCompanyBenefit';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { parkingPaymentCardId, sheetModal } from 'states/common';
import { CenterVerticalRow, ColumnWithMargins } from 'styles/ContainerStyles';
import { getCardLogo } from 'utils/paymentUtils';

const PaymentCardDropDown: React.FC<{
  showInfoIconIfNotSelected?: boolean;
  parkingZoneUid?: string;
  isAnpr?: boolean;
  isFreeSalesOrder?: boolean;
}> = ({
  showInfoIconIfNotSelected,
  parkingZoneUid,
  isAnpr,
  isFreeSalesOrder,
}) => {
  const { t } = useTranslation();
  const selectedCard = useSelectedPaymentMethod();
  const { companyBenefit } = useSelectedCompanyBenefit();
  const { companyPays, userPays, insideCompanyOutsideUserPays } =
    useGetMatchingAccessPermitForParkingZone(parkingZoneUid);

  const { addCard, loadingAddCard } = useAddPaymentMethod();

  let leftIcon = <></>;
  const handleOnPress = () => {
    if (!paymentMethod) {
      addCard();
    } else {
      sheetModal('parkingPaymentMethod');
    }
  };

  const paymentMethod = companyBenefit || selectedCard;
  const isCreditCard = paymentMethod?.__typename === 'Card';
  const text = isCreditCard
    ? paymentMethod.lastNums
    : companyBenefit?.name.split(' ')[0];
  const type = isCreditCard ? paymentMethod.lastNums : 'billing';
  const showInfoIcon = showInfoIconIfNotSelected && !paymentMethod;
  const showAnprCardInfo = isAnpr && (userPays || insideCompanyOutsideUserPays);

  useEffect(() => {
    if (companyPays || isAnpr) {
      parkingPaymentCardId(undefined);
    }
  }, [companyPays, isAnpr]);

  const handlePoolingPress = () => {
    if (isFreeSalesOrder) {
      return null;
    }
    if (companyPays) {
      sheetModal('noPaymentRequired');
    } else if (!paymentMethod) {
      addCard();
    } else {
      sheetModal('defaultPaymentMethod');
    }
  };

  const poolingText = () => {
    if (companyPays || isFreeSalesOrder) {
      return t('permit.access.paymentNotRequired');
    } else if (!paymentMethod) {
      return t('onboarding.addPaymentMethod');
    }
    return text ?? '';
  };

  const selectMethodText = () => {
    if (!paymentMethod) {
      return t('onboarding.addPaymentMethod');
    }
    return text ?? t('parking.parkingProcess.noCardSelected');
  };

  if (paymentMethod) {
    leftIcon = (
      <Icon
        testID={`credit-card-icon-${type}`}
        name={`${
          isCreditCard ? getCardLogo(selectedCard?.type) : 'Service-Filled'
        }`}
        size="l"
        color={isCreditCard ? undefined : theme.colors.boost.green}
      />
    );
  }

  if (companyPays || showAnprCardInfo || isFreeSalesOrder) {
    return (
      <DropDownButton
        testID="payment-card-dropdown"
        disabled={loadingAddCard}
        onPress={handlePoolingPress}
        text={poolingText()}
        leftItem={
          showAnprCardInfo &&
          paymentMethod && (
            <CenterVerticalRow>
              <ColumnWithMargins mr={5}>
                <Icon color={theme.colors.gray300} name="check" size="m" />
              </ColumnWithMargins>
              {leftIcon}
            </CenterVerticalRow>
          )
        }
        showInfoIcon={!!paymentMethod || companyPays || isFreeSalesOrder}
        infoIconColor={theme.colors.black}
        hideDownArrow={!!paymentMethod || companyPays || isFreeSalesOrder}
      />
    );
  }

  return (
    <DropDownButton
      testID="payment-card-dropdown"
      disabled={loadingAddCard}
      onPress={handleOnPress}
      text={selectMethodText()}
      leftItem={leftIcon}
      showInfoIcon={showInfoIcon}
    />
  );
};

export default PaymentCardDropDown;
