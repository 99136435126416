import { useReactiveVar } from '@apollo/client';
import { Button, FieldInput, Headline3, Meta, theme } from 'aimo-ui';
import { showToast } from 'components/common/CustomToast';
import SheetLayout from 'components/sheets/SheetLayout';
import HslContext from 'context/HslContext';
import useUpdateCustomerMutation from 'hooks/useUpdateCustomerMutation';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { country } from 'states/persistInStorage';
import { StyledWrapper } from 'styles/ContainerStyles';
import { isIos } from 'utils/deviceUtils';

const HslUpdatePhonenumberScreen: React.FC = () => {
  const [mobilePhone, setMobilePhone] = useState('');
  const countryCode = useReactiveVar(country);
  const { setCurrentScreen } = useContext(HslContext);
  const { t } = useTranslation();
  const callback = () => {
    showToast(t('success.phoneNumberUpdated'), 'success');
    setCurrentScreen('SELECT_TICKET');
  };
  const { updateCustomer, loading } = useUpdateCustomerMutation({ callback });
  const handleNavigateBack = () => {
    setCurrentScreen('');
  };

  const handleInternationalNumber = useCallback(() => {
    switch (countryCode) {
      case 'FI':
        return '+358';
      case 'SE':
        return '+46';
      case 'NO':
        return '+47';
      default:
        return '';
    }
  }, [countryCode]);
  useEffect(() => {
    if (mobilePhone === '' || !mobilePhone?.includes('+')) {
      setMobilePhone(handleInternationalNumber());
    }
  }, [mobilePhone, handleInternationalNumber]);

  const onPress = () => {
    updateCustomer({
      variables: {
        input: {
          mobilePhone,
        },
      },
    });
  };

  return (
    <SheetLayout
      onHeadlineButtonPress={handleNavigateBack}
      testIDName="hsl-update-phone-screen"
      headlineComponent={
        <Headline3 center>{t('hslTicket.missingPhoneTitle')}</Headline3>
      }>
      <StyledWrapper center mb={30}>
        <Meta center color={theme.colors.gray200}>
          {t('hslTicket.missingPhoneInfo')}
        </Meta>
      </StyledWrapper>
      <StyledWrapper mb={30}>
        <FieldInput
          title={t('account.mobilePhone')}
          value={mobilePhone}
          keyboardType="phone-pad"
          onChangeText={setMobilePhone}
          isSheet={isIos}
          testID="hsl-update-phone-number-field"
        />
      </StyledWrapper>
      <Button
        size="l"
        testID="hsl-update-phone-number-button"
        text={t('button.update')}
        buttonType="secondary"
        loading={loading}
        disabled={mobilePhone.length < 5 || loading}
        onPress={onPress}
      />
    </SheetLayout>
  );
};

export default HslUpdatePhonenumberScreen;
