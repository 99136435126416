import { itemProps, Spinner, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import { hoursToMinutes } from 'date-fns';
import { DurationSuggestion } from 'hooks/useSuggestedPricingEstimates';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { CountryCode } from 'types/generatedSchemaTypes';
import { currencyFormatter } from 'utils/commonUtils';
import {
  handlePrefixedStartorEndtime,
  minutesToHoursAndMinutes,
} from 'utils/timeUtils';
import { DurationLeafData } from './DurationQuickSelect';

const LeafContainer = styled.TouchableOpacity<{
  selected?: boolean;
  disabled?: boolean;
  noEndTime?: boolean;
}>`
  background: ${(p) =>
    p.selected || p.noEndTime ? theme.colors.boost.green : theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 60px;
  border: ${(p) =>
    !p.selected ? `2px solid ${theme.colors.boost.green}` : 'none'};
  opacity: ${(p) => (p.disabled ? 0.3 : 1)};
  align-items: center;
  margin: 5px 5px;
  width: 64px;
  height: 80px;
`;

const LeafText = styled.Text<{
  selected?: boolean;
  bold?: boolean;
  noEndTime?: boolean;
}>`
  font-family: ${(props) => props.theme.fontFamily.medium};
  font-size: ${(props) => props.theme.textStyles.meta.fontSize};
  color: ${(p) =>
    p.selected || p.noEndTime ? theme.colors.white : theme.colors.boost.green};
  font-weight: ${(p) => (p.bold ? 700 : 400)};
  text-align: center;
`;

const MarginTopView = styled.View`
  margin-top: 5px;
`;

const SetCustomTimeLeaf = ({
  onPress,
  testID,
  disabled,
  endTime,
}: {
  onPress: () => void;
  testID: string;
  disabled: boolean;
  endTime?: Date;
}) => {
  const { t } = useTranslation();
  return (
    <Leaf
      disabled={disabled}
      testID={`${testID}-set-custom`}
      onPress={onPress}
      noEndTime={endTime === undefined}
      topElement={
        <Icon
          name="addTime"
          size="s"
          color={
            endTime !== undefined
              ? theme.colors.boost.green
              : theme.colors.white
          }
        />
      }
      bottomElement={
        <MarginTopView>
          <LeafText noEndTime={endTime === undefined}>
            {t('parking.parkingProcess.set')}
          </LeafText>
        </MarginTopView>
      }
    />
  );
};

const renderDuringTime = (durationItem: DurationSuggestion) => {
  if (durationItem && durationItem.durationType === 'FIXED_TIME') {
    const currentTime = new Date();
    const itemStartTime = setHoursToDate(
      new Date(currentTime),
      durationItem.startTime as string
    );
    const itemEndTime = setHoursToDate(
      new Date(currentTime),
      durationItem.endTime as string
    );
    if (
      itemEndTime < itemStartTime ||
      itemEndTime.toDateString() === itemStartTime.toDateString()
    ) {
      itemEndTime.setDate(itemEndTime.getDate() + 1);
    }

    return currentTime > itemStartTime && currentTime < itemEndTime;
  }
  return true;
};

const setHoursToDate = (date: Date, timeString: string) => {
  if (timeString === undefined) {
    return date;
  }
  return handlePrefixedStartorEndtime(timeString, date);
};

const DurationQuickSelectItem = (
  { item, testID, onPress }: itemProps<DurationLeafData>,
  selectedIndex: number,
  countryCode: CountryCode,
  disabled: boolean,
  predefinedTicketsOnly: boolean,
  endTime: Date | undefined
) => {
  const handleOnPress = () => onPress && onPress(item);
  if (item === 'SET_BUTTON') {
    return (
      <SetCustomTimeLeaf
        testID={testID || ''}
        onPress={handleOnPress}
        disabled={disabled || predefinedTicketsOnly}
        endTime={endTime}
      />
    );
  }
  if (!renderDuringTime(item)) {
    return null;
  }
  const selected = item?.index === selectedIndex;

  const pricingInfo =
    typeof item.estimatedPrice === 'number' ? (
      `${currencyFormatter(item.estimatedPrice, countryCode)}`
    ) : item.estimatedPrice === 'loading' ? (
      <Spinner small />
    ) : (
      ''
    );

  const timeFormatting = (duration: number) => {
    if (item.timeUnit === 'MINUTES') {
      return `${item.duration} min`;
    }
    return minutesToHoursAndMinutes(hoursToMinutes(duration));
  };

  const durationQuickSelectItemText =
    item.durationType === 'FIXED_TIME'
      ? item.name
      : timeFormatting(item.duration);

  return (
    <Leaf
      testID={testID || ''}
      onPress={handleOnPress}
      selected={selected}
      disabled={disabled}
      topElement={
        <LeafText
          selected={selected}
          bold={true}
          testID={testID + '-title' || ''}
          numberOfLines={2}>
          {durationQuickSelectItemText}
        </LeafText>
      }
      bottomElement={
        <MarginTopView>
          <LeafText testID={`${testID}-estimate`} selected={selected}>
            {pricingInfo}
          </LeafText>
        </MarginTopView>
      }
    />
  );
};

const Leaf = ({
  onPress,
  topElement,
  bottomElement,
  selected,
  testID,
  disabled,
  noEndTime,
}: {
  onPress: () => void;
  topElement: ReactNode;
  bottomElement: ReactNode;
  selected?: boolean;
  testID: string;
  disabled: boolean;
  noEndTime?: boolean;
}) => {
  return (
    <LeafContainer
      disabled={disabled}
      testID={testID}
      onPress={onPress}
      selected={selected}
      noEndTime={noEndTime}>
      <View testID="leaf-top-element">{topElement}</View>
      <MarginTopView testID="leaf-bottom-element">
        {bottomElement}
      </MarginTopView>
    </LeafContainer>
  );
};

export default DurationQuickSelectItem;
