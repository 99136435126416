import { BaseText, Headline3, ListItem, Meta, theme } from 'aimo-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { ColumnWithMargins } from 'styles/ContainerStyles';
import { Divider } from 'styles/CommonLayoutStyles';
import { View } from 'react-native';
import { featureCountryCode } from 'states/persistInStorage';

type Data = {
  title: string;
  value: string;
};

const OpenHours: React.FC<{
  openHours?: { mondayToFriday: string; saturday: string; sunday: string };
}> = ({ openHours }) => {
  const { t } = useTranslation();
  const currentCountry = useReactiveVar(featureCountryCode);

  const data: Data[] = [
    {
      title: `${t('time.MONDAY')} - ${t('time.FRIDAY')}`,
      value: openHours?.mondayToFriday || '',
    },
    {
      title: t('time.SATURDAY'),
      value: openHours?.saturday || '',
    },
    {
      title: t('time.SUNDAY'),
      value: openHours?.sunday || '',
    },
  ];

  return (
    <>
      <Headline3>{t('account.contactUs.openHours.title')}</Headline3>
      <Meta color={theme.colors.gray200}>{t('account.contactUs.localTimes.base', {location: t(`account.contactUs.localTimes.${currentCountry}`)})}</Meta>
      <ColumnWithMargins mt={8}>
        {data.map((item, index) => (
          <View key={`open-hour--${item.title}`}>
            <ListItem
              item={item}
              indexValue={index}
              title={item.title}
              rightContentComponent={() => <BaseText>{item.value}</BaseText>}
              paddingVertical={0}
              marginVertical={10}
            />
            {index < data.length - 1 && <Divider marginVertical={0} />}
          </View>
        ))}
      </ColumnWithMargins>
    </>
  );
};

export default OpenHours;
