import { useReactiveVar } from '@apollo/client';
import AccountContext from 'context/AccountContext';
import { useContext } from 'react';
import { selectedCityPmc } from 'states/common';
import {
  CreatePermitApplicationInput,
  Customer,
  LegallAddress,
  Pmc,
} from 'types/generatedSchemaTypes';

const createApplicationInput = (
  customer: Customer,
  selectedPmc: Pmc,
  reasonText: string
): CreatePermitApplicationInput => {
  const { strongAuthentication } = customer;

  const omitTypeNameFromAddress = strongAuthentication?.addresses?.map(
    (address) => {
      const { streetName, streetNumber, city, countryCode, postalCode } =
        address as LegallAddress;
      return {
        streetName,
        streetNumber,
        postalCode,
        city,
        countryCode,
      };
    }
  );

  const applicationInput: CreatePermitApplicationInput = {
    applyReason: reasonText,
    created: new Date(),
    customerDetails: {
      contactEmail: customer?.contactEmail,
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      strongAuthentication: {
        addressUpdated: strongAuthentication?.addressUpdated,
        addresses: omitTypeNameFromAddress,
        authenticationDone: strongAuthentication?.authenticationDone,
        gedi: strongAuthentication?.gedi,
        validDriversLicense: strongAuthentication?.validDriversLicense,
        vendor: strongAuthentication?.vendor,
      },
      uid: customer?.uid,
    },
    customerGroupUid: selectedPmc?.customerGroupUids?.[0] as string,
    email: customer.contactEmail as string,
  };
  return applicationInput;
};

const useCreateApplicationInputFactory = (reasonText: string) => {
  const { customer } = useContext(AccountContext);
  const selectedPmc = useReactiveVar(selectedCityPmc);

  return createApplicationInput(customer, selectedPmc as Pmc, reasonText);
};

export default useCreateApplicationInputFactory;
