import OnboardingWelcome from 'components/onboarding/OnboardingWelcome';
import PushNotifications from 'components/onboarding/PushNotifications';
import OnboardingAddPaymentMethod from 'components/onboarding/OnboardingAddPaymentMethod';
import OnboardingAddVehicle from 'components/onboarding/OnboardingAddVehicle';

export {
  OnboardingAddPaymentMethod,
  OnboardingAddVehicle,
  OnboardingWelcome,
  PushNotifications,
};
