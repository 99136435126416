import { Keyboard, PermissionsAndroid, Platform } from 'react-native';
import {
  getBundleId,
  getManufacturerSync,
  getModel,
  hasDynamicIsland,
  hasNotch,
} from 'react-native-device-info';
import Geolocation from 'react-native-geolocation-service';
import { checkNotifications, PermissionStatus } from 'react-native-permissions';
import { PlatformType } from 'types/generatedSchemaTypes';

export const platformType: PlatformType =
  Platform.OS.toUpperCase() as PlatformType;

export const isAndroid: boolean = Platform.OS === 'android';
export const isIos: boolean = Platform.OS === 'ios';
export const isWeb: boolean = Platform.OS === 'web';
export const isIosMax: boolean = getModel().endsWith('Max');
export const isIosMini: boolean = getModel()
  .toLocaleLowerCase()
  .endsWith('mini');
export const hasNotchOrDynamicIsland = () => hasNotch() || hasDynamicIsland();
export const countMarginFromTop = (margin: number) =>
  hasNotchOrDynamicIsland() ? margin + 30 : margin;

export const appId = getBundleId();

export const checkNotificationsPermissions = async (
  permissionStatus: PermissionStatus = 'granted'
) => {
  if (isWeb) {
    return true;
  }
  let isEnabled = false;
  await checkNotifications()
    .then(({ status }) => {
      if (status === permissionStatus) {
        isEnabled = true;
      }
    })
    .catch(() => {
      return false;
    });
  return isEnabled;
};

export const checkLocationPermissions = async () => {
  let isEnabled = false;

  if (Platform.OS === 'ios') {
    const authStatus = await Geolocation.requestAuthorization('whenInUse');
    isEnabled = authStatus === 'granted';
  }

  if (Platform.OS === 'android') {
    const granted = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
    );
    isEnabled = granted === PermissionsAndroid.RESULTS.GRANTED;
  }

  return isEnabled;
};

Keyboard.addListener('keyboardDidShow', () => (keyboardOpen = true));
Keyboard.addListener('keyboardDidHide', () => (keyboardOpen = false));

let keyboardOpen = false;
export const getKeyboardOpen = () => keyboardOpen;

export const getDeviceOs = (deviceOs: string, manufacturer: string) => {
  if (deviceOs === 'android' && manufacturer === 'Huawei') {
    return 'huawei';
  }
  if (deviceOs === 'android') {
    return deviceOs;
  }
  if (deviceOs === 'ios') {
    return deviceOs;
  }
  return '';
};

export const deviceOs = getDeviceOs(Platform.OS, getManufacturerSync());
