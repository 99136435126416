import ParkingZoneDetailsInfo from 'components/park/ParkingZoneDetailsInfo';
import { format, isToday } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RowSpaceBetween } from 'styles/ContainerStyles';
import { Maybe, PredefinedTicket } from 'types/generatedSchemaTypes';
import { isValidNote } from 'utils/parkUtils';
import AnprDetails from '../detail/AnprDetails';
import Directions from '../Directions';
import ParkingSchedule from '../ParkingSchedule';
import ParkingZoneNotes from '../ParkingZoneNotes';
import TabContainer, { CommonTabProps } from './TabContainer';

type ParkingZoneDetailsTabProps = {
  allowedParkingTimes: {
    predefinedTickets: Maybe<PredefinedTicket>[];
    parkingAllowed?: boolean;
    pricingInfoLoading: boolean;
    parkingAllowedFrom: string;
    isLegacy: boolean;
  };
  showEVChargingPublicUnmanagedParking: boolean;
} & CommonTabProps;

const ParkingZoneDetailsTab: React.FC<ParkingZoneDetailsTabProps> = ({
  parkZone,
  allowedParkingTimes,
  showEVChargingPublicUnmanagedParking,
  isActiveTab,
}: ParkingZoneDetailsTabProps) => {
  const { t } = useTranslation();
  const { parkingFacility, parkingMethods, notes } = parkZone || {};
  const isAnpr = parkingMethods?.anpr === true;
  const isUnmanagedParking = parkingMethods?.unmanagedParking === true;

  const { parkingAllowed, pricingInfoLoading, parkingAllowedFrom, isLegacy } =
    allowedParkingTimes;

  const otherNotes = useMemo(
    () => notes?.filter((note) => isValidNote(note, 1, 2)),
    [notes]
  );

  const showParkingNotSupported =
    (isUnmanagedParking &&
      !showEVChargingPublicUnmanagedParking &&
      !pricingInfoLoading) ||
    isLegacy;

  const allowedFromText = () => {
    const allowedFromDate = new Date(parkingAllowedFrom);
    return t('parking.forbidden', {
      time: format(
        allowedFromDate,
        `${!isToday(allowedFromDate) ? 'd.M ' : ''}HH:mm`
      ),
    });
  };

  return (
    <TabContainer isActiveTab={isActiveTab}>
      <RowSpaceBetween noFlex padding={0}>
        <Directions address={parkZone?.address} name={parkZone?.name || ''} />
      </RowSpaceBetween>
      <RowSpaceBetween noFlex padding={0}>
        <ParkingSchedule parkingFacilityData={parkingFacility} />
      </RowSpaceBetween>
      {isAnpr && <AnprDetails />}
      {showParkingNotSupported && (
        <ParkingZoneDetailsInfo
          text="parking.notSupported"
          testID="park-zone-details-info-not-allow"
        />
      )}
      {!showParkingNotSupported && !parkingAllowed && !!parkingAllowedFrom && (
        <ParkingZoneDetailsInfo
          text={allowedFromText()}
          testID="park-zone-details-info-forbidden"
        />
      )}
      {otherNotes && otherNotes.length > 0 && (
        <ParkingZoneNotes
          testID="park-zone-other-information"
          headline="parking.otherInformation"
          parkZoneNotes={otherNotes}
        />
      )}
    </TabContainer>
  );
};

export default ParkingZoneDetailsTab;
