import { useEffect, useRef, useState } from 'react';

const useExecuteAfterStateChange = <T,>(
  afterStateUpdateFn: () => void,
  currentWatchValue: T
) => {
  const [isWatchActive, setWatchActive] = useState<boolean>(false);
  const initialWatchValue = useRef(currentWatchValue);
  useEffect(() => {
    if (isWatchActive && initialWatchValue.current !== currentWatchValue) {
      initialWatchValue.current = currentWatchValue;
      setWatchActive(false);
      afterStateUpdateFn();
    }
  }, [afterStateUpdateFn, currentWatchValue, isWatchActive]);

  return {
    setWatchActive,
  };
};
export default useExecuteAfterStateChange;
