import config from 'config/config';
import i18next from 'i18next';

import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend';

import HttpBackend, {
  HttpBackendOptions,
  RequestCallback,
} from 'i18next-http-backend';

import resourcesToBackend from 'i18next-resources-to-backend';

import 'intl-pluralrules';

import { initReactI18next } from 'react-i18next';
import { preferredLanguage } from 'states/persistInStorage';
import { Lang } from 'types/generatedSchemaTypes';

import {
  fallbackLanguage,
  getDeviceLanguage,
  supportedLanguages,
} from 'utils/languageUtils';

import en from './localizations/en.json';
import fi from './localizations/fi.json';
import no from './localizations/no.json';
import sv from './localizations/sv.json';

type ResourceType = {
  [key: string]: { translation: any };
};

const resources: ResourceType = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
  fi: {
    translation: fi,
  },
  no: {
    translation: no,
  },
};

const baseURL = (
  config.REACT_APP_HTTP_BACKEND_TRANSLATIONS_URL as string
).replace('.lng', '{{lng}}');
const allowedLanguages = ['en', 'sv'] as Lang[];

i18next
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    debug: false,
    react: { useSuspense: false },
    fallbackLng: fallbackLanguage,
    supportedLngs: supportedLanguages,
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },
    backend: {
      backends: [HttpBackend, resourcesToBackend(resources)],
      backendOptions: [
        {
          loadPath: `${baseURL}.json`,
          addPath: baseURL,
          request: (
            _: HttpBackendOptions,
            url: string,
            _2: any,
            callback: RequestCallback
          ) => {
            const lastUrlElement = url.split('/').pop();
            const fetchUrl = allowedLanguages.some((lng) =>
              lastUrlElement?.includes(lng)
            )
              ? url
              : _.loadPath?.toString().replace('{{lng}}', fallbackLanguage);
            fetch(fetchUrl ?? url, {
              headers: {
                pragma: 'no-cache',
                'cache-control': 'no-cache',
              },
            })
              .then((res) => res.json())
              .catch((e) => callback(e, { status: 500 } as any))
              .then((data) => {
                const lng =
                  lastUrlElement?.split('.').shift() ?? getDeviceLanguage();
                callback(null, {
                  data: mergeTranslations(data, resources[lng].translation),
                  status: 200,
                });
              });
          },
        },
        null,
      ],
    } as ChainedBackendOptions,
    initImmediate: false,
  });

let reloadRemote = true;

const setupLanguages = (
  showLangFI: boolean,
  showLangNO: boolean,
  loadBundledOnly: boolean
) => {
  reloadRemote = !loadBundledOnly;
  if (showLangFI && allowedLanguages.indexOf('fi') === -1) {
    allowedLanguages.push('fi');
  }
  if (showLangNO && allowedLanguages.indexOf('no') === -1) {
    allowedLanguages.push('no');
  }
  // If feature flag for remote translations is false use only bundler translations
  if (loadBundledOnly) {
    if (showLangFI) {
      i18next.addResourceBundle('fi', 'translation', fi, true, true);
    }
    if (showLangNO) {
      i18next.addResourceBundle('no', 'translation', no, true, true);
    }
    i18next.addResourceBundle('en', 'translation', en, true, true);
    i18next.addResourceBundle('sv', 'translation', sv, true, true);
  }

  reloadTranslations();
  setLanguage(preferredLanguage() || (getDeviceLanguage() as Lang));
};

const reloadTranslations = () => {
  if (reloadRemote) {
    i18next.reloadResources();
  }
};

/**
 * Merges two translation objects while preserving the nested properties of the first object.
 * For example if some key is missing in the AWS we use bundled key instead.
 * @param source - The first object to merge.
 * @param target - The second object to merge.
 * @returns A new object that is the result of merging `obj1` and `obj2`.
 */
const mergeTranslations = (source: any, target: any) => {
  const merged = { ...target };

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
        if (
          target.hasOwnProperty(key) &&
          typeof target[key] === 'object' &&
          !Array.isArray(target[key])
        ) {
          merged[key] = mergeTranslations(source[key], target[key]);
        } else {
          merged[key] = { ...source[key] };
        }
      } else {
        merged[key] = source[key];
      }
    }
  }
  return merged;
};

const getLanguage = () => {
  return i18next.language;
};

const setLanguage = (lng: Lang) => {
  preferredLanguage(lng);
  i18next.changeLanguage(lng);
};

const t = i18next.t.bind(i18next);

export {
  t,
  setupLanguages,
  reloadTranslations,
  getLanguage,
  setLanguage,
  i18next,
  allowedLanguages,
};
