import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Sheet from 'components/sheets/Sheet';
import React, { useState } from 'react';
import styled from 'styled-components/native';
import BottomSheetScrollViewCustom from 'components/common/BottomSheetScrollViewCustom';
import { ColumnWithMargins, StyledWrapper } from 'styles/ContainerStyles';
import { StreetParkingStackParamList } from 'types/navigation';
import { isWeb } from 'utils/deviceUtils';
import { BaseText, Button, Title, Toggle } from 'aimo-ui';
import CloseButton from 'components/common/CloseButton';
import MenuItem from 'components/common/MenuItem';
import { useTranslation } from 'react-i18next';
import { strongAuthFlow } from 'states/common';
import { MainWrapper } from 'styles/ModalStackContainerStyles';
import { Linking } from 'react-native';

const Container = styled(ColumnWithMargins)`
  flex: 1;
`;

const HeaderContainer = styled(StyledWrapper)`
  align-items: flex-end;
`;

const WebContainer = styled(StyledWrapper)`
  background-color: ${(p) => p.theme.colors.white};
  flex: 1;
  border-radius: 20px;
`;

const Content = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [cityTermsAccepted, setCityTermsAccepted] = useState(false);
  const { t } = useTranslation();
  const { navigate } =
    useNavigation<StackNavigationProp<StreetParkingStackParamList>>();

  const openUrl = (url: string) => {
    Linking.openURL(url);
  };
  return (
    <Container ml={10} mr={10} mt={15} testID="legal-address-strong-auth-info">
      <HeaderContainer mb={20}>
        <CloseButton
          testID="strong-auth-info-close"
          onPress={() => strongAuthFlow(false)}
        />
      </HeaderContainer>
      <StyledWrapper center testID={'legal-address-title'}>
        <Title>{t('legalAddressAuth.authTitle')}</Title>
      </StyledWrapper>
      <MainWrapper>
        <StyledWrapper
          center
          maxWidth={isWeb ? 450 : 327}
          testID="legal-address-info">
          <BaseText center>{t('legalAddressAuth.authInfo')}</BaseText>
        </StyledWrapper>
        <BottomSheetScrollViewCustom bottomThreshold={0}>
          <StyledWrapper center maxWidth={isWeb ? 450 : 360} mb={10}>
            <MenuItem
              testID="legal-address-terms"
              menuLabel={t('legalAddressAuth.terms')}
              onPress={() =>
                openUrl(
                  'https://www.aimopark.fi/en-gb/muut/terms-and-conditions/'
                )
              }
              withArrow
              arrowSize="s"
            />
            <MenuItem
              testID="legal-address-policy"
              menuLabel={t('legalAddressAuth.privacy')}
              onPress={() =>
                openUrl('https://www.aimopark.fi/fi-fi/tietosuojaseloste/')
              }
              withArrow
              arrowSize="s"
            />
            <MenuItem
              testID="legal-address-city-terms"
              menuLabel={t('legalAddressAuth.cityTerms')}
              onPress={() =>
                openUrl(
                  'https://www.turku.fi/asuminen-ja-ymparisto/liikenne/pysakointi/asukaspysakointi'
                )
              }
              withArrow
              arrowSize="s"
            />
          </StyledWrapper>
          <StyledWrapper center maxWidth={isWeb ? 450 : 360}>
            <MenuItem
              testID="legal-address-terms-confirm"
              menuLabel={t('legalAddressAuth.termsToggleTitle')}
              subtitle={t('legalAddressAuth.termsToggleMeta')}
              rightComponent={
                <Toggle
                  testID="legal-address-terms-confirm-toggle"
                  value={termsAccepted}
                  onValueChange={() => setTermsAccepted(!termsAccepted)}
                />
              }
            />
            <MenuItem
              testID="legal-address-city-terms-confirm"
              menuLabel={t('legalAddressAuth.cityTermsToggleTitle')}
              subtitle={t('legalAddressAuth.cityTermsToggleMeta')}
              rightComponent={
                <Toggle
                  testID="legal-address-city-terms-confirm-toggle"
                  value={cityTermsAccepted}
                  onValueChange={() => setCityTermsAccepted(!cityTermsAccepted)}
                />
              }
            />
          </StyledWrapper>
        </BottomSheetScrollViewCustom>
      </MainWrapper>
      <StyledWrapper center maxWidth={isWeb ? 450 : 370}>
        <Button
          testID="legal-address-continue"
          text={t('button.continue')}
          size="l"
          disabled={!termsAccepted || !cityTermsAccepted}
          onPress={() => navigate('authenticate')}
        />
      </StyledWrapper>
    </Container>
  );
};

const LegalAddressInfoScreen = () => {
  const snappingPoints = ['90%'];

  if (isWeb) {
    return (
      <WebContainer testID="legal-address-web" center maxWidth={600}>
        <Content />
      </WebContainer>
    );
  }

  return (
    <Sheet snappingPoints={snappingPoints} hideHandle showBackDropIndex={0}>
      <Content />
    </Sheet>
  );
};

export default LegalAddressInfoScreen;
