import useShortTermParkingWizard from 'hooks/useShortTermParkingWizard';
import React, { ReactNode } from 'react';
import ParkingContext from './ParkingContext';

const ParkingContextProvider = ({ children }: { children: ReactNode }) => {
  const wizard = useShortTermParkingWizard();
  return (
    <ParkingContext.Provider value={wizard}>
      <>{children}</>
    </ParkingContext.Provider>
  );
};

export default ParkingContextProvider;
