import React from 'react';
import styled from 'styled-components/native';
import Icon from 'components/icons/Icon';
import { theme } from 'aimo-ui';

const Row = styled.View`
  flex-direction: row;
  margin-bottom: 18px;
  padding-bottom: 19px;
  border-bottom-width: 1px;
  border-color: ${(props) => props.theme.colors.gray100};
  align-items: center;
`;

const IconWrapper = styled.View`
  margin-left: 10px;
`;

const TextWrapper = styled.Pressable`
  maxWidth: 90%;
`;

const BlockerMenuItem = ({
  text,
  onPress,
  testID,
}: {
  text: any;
  onPress?: () => void;
  testID: string;
}) => {
  return (
    <Row>
      <TextWrapper onPress={onPress} testID={testID}>
        {text}
      </TextWrapper>
      <IconWrapper>
        <Icon
          name="Chevron_Right"
          testID="dropdown-chevron"
          size="l"
          color={theme.colors.gray200}
        />
      </IconWrapper>
    </Row>
  );
};

export default BlockerMenuItem;
