import React, { useMemo } from 'react';
import { Marker } from '@react-google-maps/api';
import { markerSvg } from './WebMarkerSvgUtil';
import { markerIconsMap } from 'utils/mapUtils';

const activeSize = { height: 60, width: 60, x: 60 / 2, y: 60 - 4 };
const normalSize = { height: 50, width: 50, x: 50 / 2, y: 50 + 3 };
const zoneCodeSize = { height: 70, width: 70, x: 70 / 2, y: 70 + 3 };

const WebMarker = (props: any) => {
  const {
    iconString,
    activeIndex,
    onPress,
    testID,
    coordinate,
    clusterer,
    zoneCode,
    ...rest
  } = props;

  const iconUrl = useMemo(() => {
    if (zoneCode) {
      return (
        'data:image/svg+xml;charset=UTF-8,' +
        encodeURIComponent(
          markerSvg(iconString, activeIndex).replace('zoneCode', zoneCode)
        )
      );
    }
    return `/images/${markerIconsMap[iconString]}.svg`;
  }, [zoneCode, activeIndex, iconString]);

  const iconSize = useMemo(() => {
    if (zoneCode) {
      return zoneCodeSize;
    }
    return activeIndex ? activeSize : normalSize;
  }, [zoneCode, activeIndex]);

  const icon = {
    url: iconUrl,
    size: iconSize,
    scaledSize: activeIndex ? activeSize : normalSize,
    origin: { x: 0, y: activeIndex ? 0 : -4 },
    anchor: {
      x: activeIndex ? activeSize.x : normalSize.x,
      y: activeIndex ? activeSize.y : normalSize.y,
    },
  };

  return (
    <Marker
      {...rest}
      testid={testID}
      zIndex={activeIndex}
      clickable={true}
      onClick={onPress}
      icon={icon}
      clusterer={clusterer}
      cursor="pointer"
      position={{ lat: coordinate.latitude, lng: coordinate.longitude }}
    />
  );
};

export default WebMarker;
