import { useMutation } from '@apollo/client';
import { REMOVE_ACCOUNT } from '@mutations/removeAccount';
import { sheetModal } from 'states/common';
import { Mutation } from 'types/generatedSchemaTypes';

const useRemoveAccount = () => {
  const [removeAccount] = useMutation<Mutation>(REMOVE_ACCOUNT, {
    awaitRefetchQueries: true,
    onCompleted: () => {
      sheetModal('accountRemovalSuccess');
    },
    onError: () => sheetModal('accountRemovalFailed'),
  });
  return { removeAccount };
};

export default useRemoveAccount;
