import { useNavigation } from '@react-navigation/native';
import { BaseText, Button, theme } from 'aimo-ui';
import Icon from 'components/icons/Icon';
import useSelectedPaymentMethod from 'hooks/useSelectedPaymentMethod';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { sheetModal } from 'states/common';
import { StyledWrapper } from 'styles/ContainerStyles';
import { RootNavProps } from 'types/navigation';
import { getCardMaskedLastNums } from 'utils/paymentUtils';

const SettingsIcon = () => <Icon name={'Settings-Outlined'} />;

const DefaultPaymentMethodModalContent = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<RootNavProps>();
  const card = useSelectedPaymentMethod();
  const navigateToAccount = () => {
    sheetModal('');
    navigate('mainTabs', {
      screen: 'accountScreen',
      params: { screen: 'myPaymentMethods' },
    });
  };
  return (
    <StyledWrapper>
      <StyledWrapper>
        <BaseText color={theme.colors.gray200} center>
          {t('paymentMethods.defaultMethodInfo', {
            card: card
              ? `${card.type?.toUpperCase()} ${getCardMaskedLastNums(card)}`
              : '',
          })}
        </BaseText>
      </StyledWrapper>
      <StyledWrapper>
        <Button
          size="l"
          onPress={() => sheetModal('')}
          text={t('button.close')}
          testID="close-button"
        />
      </StyledWrapper>
      <StyledWrapper>
        <Button
          size="l"
          onPress={navigateToAccount}
          text={t('button.goToAccount')}
          testID="account-button"
          buttonType="tertiary"
          iconComponent={SettingsIcon}
          iconRight
        />
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default DefaultPaymentMethodModalContent;
