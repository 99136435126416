import { useQuery, useReactiveVar } from '@apollo/client';
import { Headline1, Spinner } from 'aimo-ui';
import AccountContext from 'context/AccountContext';
import { READ_HSL_TICKET_RENDER } from 'graphql/queries/readHslTicketRender';
import useDeviceTimeAuthentication from 'hooks/useDeviceTimeAuthentication';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getUniqueIdSync } from 'react-native-device-info';
import WebView from 'react-native-webview';
import { selectedHslTicket, sheetModal } from 'states/common';
import styled from 'styled-components/native';
import { StyledWrapper } from 'styles/ContainerStyles';
import {
  Query,
  QueryReadHslTicketRenderArgs,
} from 'types/generatedSchemaTypes';
import { isWeb } from 'utils/deviceUtils';

const RenderWrapper = styled.View`
  ${isWeb ? 'height: 75vh' : undefined};
`;

const Container = styled.View`
  height: 100%;
`;

const HslTicketRenderModalContent: React.FC = () => {
  const hslTicket = useReactiveVar(selectedHslTicket);
  const { t } = useTranslation();
  const {
    customer: { contactEmail, countryCode },
  } = useContext(AccountContext);

  const { data: authenticateData, loading: authenticateLoading } =
    useDeviceTimeAuthentication();

  const { data, loading } = useQuery<Query, QueryReadHslTicketRenderArgs>(
    READ_HSL_TICKET_RENDER,
    {
      variables: {
        input: {
          ticketId: hslTicket?.ticketId || '',
          deviceId: isWeb ? contactEmail : getUniqueIdSync(),
          locale: countryCode,
        },
      },
      skip: !hslTicket?.ticketId,
    }
  );
  const ticketRender = data?.readHslTicketRender?.ticket;

  useEffect(() => {
    if (
      !authenticateLoading &&
      typeof authenticateData?.authenticateDeviceTime?.valid === 'boolean' &&
      !authenticateData?.authenticateDeviceTime?.valid
    ) {
      sheetModal('invalidDeviceTime');
    }
  }, [authenticateData, authenticateLoading]);

  const pageLoader =
    authenticateLoading ||
    loading ||
    !authenticateData?.authenticateDeviceTime?.valid;

  return (
    <>
      <RenderWrapper>
        {pageLoader && (
          <StyledWrapper center>
            <Spinner />
          </StyledWrapper>
        )}
        {!ticketRender && !pageLoader && (
          <StyledWrapper maxWidth={350} center mb={20}>
            <Headline1 center testID="hsl-unavailable">
              {t('hslTicket.unavailable')}
            </Headline1>
          </StyledWrapper>
        )}
        {ticketRender && (
          <Container>
            <WebView
              testID={'hsl-ticket-render'}
              source={{ html: ticketRender }}
            />
          </Container>
        )}
      </RenderWrapper>
    </>
  );
};

export default HslTicketRenderModalContent;
